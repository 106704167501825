import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { GruaTecnicsFilter } from '../models/grua-tecnics-filter';
import { GruaTecnics } from '../models/grua-tecnics.model';
import { GruaTecnicsFilterService } from './grua-tecnics-filter.service';

const defaultSortParam = 'codi';
const defaultSortDirection = 'asc';

@Injectable()
export class GruaTecnicsService {
  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {}

  setGruaTecnics(gruaTecnics: GruaTecnics): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetGruaTecnics'], gruaTecnics);
    }
  }

  removeGruaTecnics(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteGruaTecnics'].replace('{secure_id}', secure_id), null);
    }
  }

  getGruaTecnics(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetGruaTecnic'].replace('{secure_id}', secure_id), null);
    }
  }

  generateExcel(filter: GruaTecnicsFilter, sortDir: string, sortField: string): Observable<any> {
    const start = 0;
    const pageSize = 1000;

    sortField = sortField === undefined ? defaultSortParam : sortField;
    sortDir = sortDir === '' ? defaultSortDirection : sortDir;
    const params = new GruaTecnicsFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

    if (this.authGuard.canActivate()) {
      return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlGruaTecnicsExcel'],  params );
    }
  }
}
