<div class="editorForm">
  <!-- header Detalle -->
  <div class="row">
    <!-- Cuadro izquierdo -->
    <div class="col-4">
      <!-- Fecha Inicio -->
      <div class="row">
        <app-form-date-edit id="texto-informativo-editor-dataIni" class="col-12" i18n-label label="Data Inici" [isReadOnly]="!this.currentEditing" [(value)]="this.currentTexto.fechaInicio"></app-form-date-edit>
      </div>
      <!-- fecha Fin -->
      <div class="row">
        <app-form-date-edit id="texto-informativo-editor-dataFi" class="col-12" i18n-label label="Data Fi" [isReadOnly]="!this.currentEditing" [(value)]="this.currentTexto.fechaFin"></app-form-date-edit>
      </div>
    </div>
    
    <!-- Cuadro derecho -->
    <div class="col-8">
      <!-- Primera fila -->
      <div class="row">
        <!-- Idioma -->
        <div class="col-3">
          <app-combo-box-input #comboSiglas i18n-label [label]="'Idioma'" [disabled]="!this.currentEditing" [showingOptions]="this.idiomaDictionary" 
                               (optionChanged)="this.siglaSeleccionada($event)" [(value)]="this.currentTexto.idioma">
          </app-combo-box-input>
        </div>
        <!-- Orden -->
        <div class="col-3">
          <app-form-text-edit id="texto-informativo-editor-ordre" [isReadOnly]="!this.currentEditing" [(value)]="this.currentTexto.orden" i18n-label label="Ordre"></app-form-text-edit>
        </div>
        <!-- Título -->
        <div class="col-6">
          <app-form-text-edit id="atexto-informativo-editor-titol" [isReadOnly]="!this.currentEditing" [(value)]="this.currentTexto.titulo" i18n-label label="Títol"></app-form-text-edit>
        </div>
      </div>
      <!-- Segunda fila (Descripcion) -->
      <div class="row">
        <app-form-text-edit id="atexto-informativo-editor-descripcio" [isReadOnly]="!this.currentEditing" class="col-12" [(value)]="this.currentTexto.descripcion" i18n-label label="Descripció">
        </app-form-text-edit>
      </div> 
    </div>
  </div>

  <div class="row">
    <app-html-editor class="col-12" [textoFormatado]="this.currentTexto.htmlValue" (textoFormatadoChanged)="this.textoFormatadoChanged($event)" 
      [isReadOnly]="!this.currentEditing"></app-html-editor>
  </div>
</div>
