import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { InfraccioService } from '../../services/infraccions-service.service';
import { FormService } from 'path-shared/services/form/form.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { InfraccioDetail } from '../../models/infraccioDetail.model';
import { LegislacioService } from '../../../legislacions/services/legislacions-service.service';
import { ZonesModel } from 'app/sections/ciutat/zones/models/zones.model';
import { FormMultiSelect } from 'path-shared/components/form/form-multi-select/model/form-multi-select.model';
import { ZonesService } from 'app/sections/ciutat/zones/services/zones.service';
import { StartupService } from 'path-shared/services/startup/startup.service';
import { Dictionary } from 'path-shared/models/dictionary';
import { CFG_TIPODENUNCIA } from 'path-models/CFG_TIPODENUNCIA';
import { BehaviorSubject } from 'rxjs';
import { SOM_DEN_INFRACCIONES_GRAVEDAD } from 'path-models/SOM_DEN_INFRACCIONES_GRAVEDAD';
import { CFG_TIPO_INFRACCION } from 'path-models/CFG_TIPO_INFRACCION';
@Component({
  selector: 'app-infraccions-editor',
  templateUrl: './infraccions-editor.component.html',
  styleUrls: ['./infraccions-editor.component.scss']
})
export class InfraccionsEditorComponent implements OnInit {
  secure_id: string;
  infraccio: InfraccioDetail;
  tipusLegislacions = [];

  tipusZB = [
    // { codi: '0', descripcio: this.i18n('Sense validació de ticket')},
    // { codi: '1', descripcio: this.i18n('Amb validació')},
    // { codi: '2', descripcio: this.i18n('Per excés de temps')},
    // { codi: '3', descripcio: this.i18n('Sense ticket')},
    { codi: '0', descripcio: '0 - Policia'},
    { codi: '1', descripcio: this.i18n('1 - Zona Blava')},
    { codi: '2', descripcio: this.i18n('2 - Ticket excedit')},
    { codi: '3', descripcio: this.i18n('3 - Sense ticket')},
  ];
  infraccionsDGT = [
    {codi: '0', descripcio: this.i18n('0 - Vehicle de baixa')},
    {codi: '1', descripcio: this.i18n('1 - Sense assegurança')},
    {codi: '2', descripcio: this.i18n('2 - Sense ITV')},
  ];
  tipusGrues = [];
  dialogData: ComponentDialog;
  closeButtonText: string;
  zones = [];
  tipusTipus = [];
  tipusGravetats = [];
  editable = false;
  @ViewChild('mainForm') private mainForm: ElementRef;

  constructor(  private dialog: MatDialog,
    private confirmationDialogService: ConfirmationDialogService,
    public auth: AuthorizationService,
    private infraccioService: InfraccioService,
    private i18n: I18n,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    public thisDialogRef: MatDialogRef<InfraccionsEditorComponent>,
    private formService: FormService,
    private legislacioService: LegislacioService,
    private zonesService: ZonesService,
    private startupService: StartupService,
    @Inject(MAT_DIALOG_DATA) public data,
    ) {
      this.infraccio = Builder.NewInfraccio();
      this.dialogData = data;
      this.editable = data.editable;
      this.secure_id = this.data.id;
      if (this.dialogData.isReadOnly) {
        this.closeButtonText = this.i18n('Tancar');
      } else {
        this.closeButtonText = this.i18n('Llista');
      }

      if (data.tipusLegislacions !== undefined) {
        this.tipusLegislacions = data.tipusLegislacions;
      } else {
        // si venim de denuncia el 'data' no ens passa aquesta informació i l'hem de carregar
        this.legislacioService.getAllForCombo().subscribe(result => {
          this.tipusLegislacions = result;
          this.tipusLegislacions.unshift({'codi' : '0', 'descripcio' : ''});
        });
      }

      this.tipusGrues = data.aliesGrues;
   }

   ngOnInit() {
    // this.getZones();
    // this.loadTipusInfraccio();
    // this.loadTipusGravetat();
    this.loadData();
    // if (this.secure_id !== null) {

    //   this.infraccioService.getInfraccio(this.secure_id).subscribe(data => {
    //     this.infraccio = data;
    //   });
    // }
  }

  loadData() {
    this.zonesService.getZones().subscribe(result => {
      const zones: Array<ZonesModel> = result;
      this.zones = [];
      if (zones !== null) {
        zones.forEach(zona => {
          if (this.infraccio !== undefined && this.infraccio !== null &&
            this.infraccio.zones !== undefined && this.infraccio.zones !== null &&
            this.infraccio.zones.find(x => x.item_id === zona.idZona)) {
            this.zones.unshift(new FormMultiSelect(zona.idZona, zona.descripcio));
          } else {
            this.zones.push(new FormMultiSelect(zona.idZona, zona.descripcio));
          }
        });
      }


      this.tipusTipus = [];
      this.infraccioService.GetInfraccioTipus().subscribe((tipusInfraccions: CFG_TIPO_INFRACCION[]) => {
        tipusInfraccions.forEach(tipusInfraccio => {
          this.tipusTipus.push({'codi': tipusInfraccio.CODI, 'descripcio': tipusInfraccio.DESCRIPCIO});
        });

        this.tipusGravetats = [];
        this.startupService.GetInfraccioTipusGravetat().forEach((gravetat: SOM_DEN_INFRACCIONES_GRAVEDAD) => {
          this.tipusGravetats.push({'codi': gravetat.CODI, 'descripcio': gravetat.DESCRIPCIO});
        });

        if (this.secure_id !== null) {

          this.infraccioService.getInfraccio(this.secure_id).subscribe(data => {
            this.infraccio = data;
          });
        }
      });

    });
  }

  save() {
    if (this.formService.allFieldsValid(this.mainForm)) {
      if (this.infraccio.idGrua != null && this.tipusGrues !== undefined && this.tipusGrues !== null && this.tipusGrues.length > 0 ) {

          this.infraccio.grua = this.tipusGrues.find(x => x.codi === this.infraccio.idGrua).descripcio;
      }
      this.infraccioService.setInfraccio(this.infraccio).subscribe(result => {
        this.actualizarCacheInfracciones(this.infraccio);
        this.toastr.info(this.i18n('Infracció guardada correctament'));
        this.thisDialogRef.close(true);
      });
    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
    }
  }

  cancel() {
    this.thisDialogRef.close(false);
  }

  remove() {
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol esborrar l\'infracció?'))
    .then((confirmed) => {
        if (confirmed) {
          this.infraccioService.removeInfraccio(this.secure_id).subscribe(result => {
            this.toastr.success(this.i18n('Infracció esborrada correctament'));
            this.dialog.closeAll();
            this.thisDialogRef.close(true);
          });
        }
      });
  }

  getZones() {
    this.zonesService.getZones().subscribe(result => {
      const zones: Array<ZonesModel> = result;
      this.zones = [];
      zones.forEach(zona => {

        if (this.infraccio !== undefined && this.infraccio !== null &&
          this.infraccio.zones !== undefined && this.infraccio.zones !== null &&
          this.infraccio.zones.find(x => x.item_id === zona.idZona)) {
          this.zones.unshift(new FormMultiSelect(zona.idZona, zona.descripcio));
        } else {
          this.zones.push(new FormMultiSelect(zona.idZona, zona.descripcio));
        }
      });
    });
  }

  loadTipusInfraccio() {
    this.tipusTipus = [];
    this.infraccioService.GetInfraccioTipus().subscribe((tipusInfraccions: CFG_TIPO_INFRACCION[]) => {
      tipusInfraccions.forEach(tipusInfraccio => {
        this.tipusTipus.push({'codi': tipusInfraccio.CODI, 'descripcio': tipusInfraccio.DESCRIPCIO});
      });
    });
  }

  loadTipusGravetat() {
    this.tipusGravetats = [];
    this.startupService.GetInfraccioTipusGravetat().forEach((gravetat: SOM_DEN_INFRACCIONES_GRAVEDAD) => {
      this.tipusGravetats.push({'codi': gravetat.CODI, 'descripcio': gravetat.DESCRIPCIO});
    });
  }

  actualizarCacheInfracciones(infraccio: InfraccioDetail) {
    let index: number;

    if (sessionStorage.getItem('infraccionsForCombo')) {
      const json = sessionStorage.getItem('infraccionsForCombo');
      const infraccionsList: any[] = JSON.parse(json.toString());

      if (infraccio.id !== undefined && infraccio.id !== null && infraccio.id !== '0') {
        index = infraccionsList.findIndex(x => x.secureId === infraccio.secureId);
        if (index !== -1) {
          infraccionsList[index].descCurta = infraccio.descLlarga;
          sessionStorage.setItem('infraccionsForCombo', JSON.stringify(infraccionsList));
        }
      } else {
        sessionStorage.removeItem('infraccionsForCombo');
      }
    }

    if (sessionStorage.getItem('infraccionsDenunciesCombo')) {
      const json = sessionStorage.getItem('infraccionsDenunciesCombo');
      const infraccionesDenunciesList: any[] = JSON.parse(json.toString());

      if (infraccio.id !== undefined && infraccio.id !== null && infraccio.id !== '0') {
        index = infraccionesDenunciesList.findIndex(x => x.codi === infraccio.id);
        if (index !== -1) {
          infraccionesDenunciesList[index].descripcio = infraccio.descLlarga;
          sessionStorage.setItem('infraccionsDenunciesCombo', JSON.stringify(infraccionesDenunciesList));
        }
      } else {
        sessionStorage.removeItem('infraccionsDenunciesCombo');
      }
    }
  }
}
