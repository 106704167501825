import { Component, OnInit, Inject, Input, ViewEncapsulation } from '@angular/core';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { SingleImageViewerComponent } from 'path-shared/components/photo-box/components/single-image-viewer/single-image-viewer.component';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GalleryComponent implements OnInit {
  @Input() images: string[];
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private router: Router,
    private i18n: I18n,
    private toastr: ToastrService,
    private not: NotificationService,
    public thisDialogRef: MatDialogRef<GalleryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
  ) {
    this.dataDialog = data;
    this.images = data.tag as string[];
  }

  ngOnInit() {
  }

  close() {
    this.thisDialogRef.close(false);
  }

  mostraOriginal(url: string) {
    if (url.endsWith('/S')) {
      url = url.replace('/S', '');
    }
    if (url.endsWith('/M')) {
      url = url.replace('/M', '');
    }
    if (url.endsWith('/L')) {
      url = url.replace('/L', '');
    }
    this.dialog.open(SingleImageViewerComponent,
      {
        width: '99%',
        closeOnNavigation: false,
        autoFocus: true,
        data: {
          src: url,
          name: this.dataDialog.title
        }
      }
    );
    
  }

}
