<app-dialog-header *ngIf="this.dataDialog.isDialog" [label]="this.dataDialog.title"></app-dialog-header>
<div class="row">
  <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
        <li *ngFor="let html of this.htmls; let i = index" data-target="#carouselExampleIndicators"></li>
    </ol>
    <div class="carousel-inner">
      <div *ngFor="let html of this.htmls; let i = index"  class="carousel-item" [ngClass]="[ i === 0 ? 'active' : '']" >
        <div [innerHTML]="sanitizeHTML(html)" style="background-color: white;"></div>
        <div style="text-align: center;"><button (click)="this.downloadPDF(html)">IMPRIMIR</button></div>
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev" style="background-color: #666666;">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only" i18n>Anterior</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next" style="background-color: #666666;">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only" i18n>Següent</span>
    </a>
  </div>
</div>
