import { Component, OnInit, AfterViewInit, OnChanges, ViewChild, Input, EventEmitter, Output, SimpleChanges, ElementRef } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { ExcelDownloadService } from 'path-shared/services/excel/excel-download.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { merge, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Builder } from 'path-shared/models/builder';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { Reflection } from 'path-shared/services/reflection/reflection';
import { SingleEditDialogData } from 'path-shared/components/dialogs/single-edit-dialog/single-edit-dialog-data.model';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { DisplayTextDialogComponent } from 'path-shared/components/dialogs/display-text-dialog/display-text-dialog.component';
import { XmlTreeViewerComponent } from 'app/sections/utilitats/dgt/components/xml-tree-viewer/xml-tree-viewer.component';
import { Bonos } from '../models/bonos.model';
import { BonosFilter } from '../models/bonos-filter';
import { BonosService } from '../services/bonos.service';
import { BonosFilterService } from '../services/bonos-filter.service';

@Component({
  selector: 'app-bonos-table',
  templateUrl: './bonos-table.component.html',
  styleUrls: ['./bonos-table.component.scss']
})
export class BonosTableComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('table') table: ElementRef;

  public data: Subject<Bonos[]>;
  dataSource: CustomDataSource<Bonos, BonosFilter>;
  highlightedRows = [];
  filter: BonosFilter = new BonosFilter('fechaFin', 'desc');
  filterPage: BonosFilter;
  displayedItems = [];
  allSelected = false;

  @Input() updatedFilterData: BonosFilter;
  @Input() selectButton: boolean;
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();
  @Output() itemClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() itemSelected: EventEmitter<string> = new EventEmitter<string>();

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['matricula', 'fechaInicio', 'fechaFin', 'usuarioBonoAsignado', 'usuarioCreacion', 'comercio', 'tiempo', 'importe', 'leido', 'tipoBono'];

  constructor(
    private bonosService: BonosService,
    private filterService: FilterService,
    private excel: ExcelDownloadService,
    private dialog: MatDialog,
    private i18n: I18n
    ) {
        this.data = new Subject<Bonos[]>();
    }

  ngOnInit() {

    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<Bonos, BonosFilter>(this.paginator, new BonosFilterService(), 'urlGetBonos', 'urlBonosPaginationCount');
    this.dataSource.subject.subscribe((data) => {
      this.data.next(data);
    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
        if (!this.selectButton) { this.filterService.saveFilter('bonos-filter', this.filter); }
      }
    });
    this.filter.paginator.init(this.sort, this.paginator);
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    } );
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.filter.updated()))
      .subscribe();
      this.load();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      this.update();
    }
  }

  update() {
    if (this.dataSource !== undefined) {
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
    }
  }

  load() {
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }


  generateExcel(): void {
    let filter = this.filter;
    if (this.updatedFilterData != null) {
      filter = this.updatedFilterData;
    }
    this.dialog.open(DownloadExcelDialogComponent, {
        closeOnNavigation: false,
        autoFocus: true,
        data: Builder.newDownloadExcelDialogData (this.bonosService, filter, this.i18n('Bonos'), this.sort.direction, this.sort.active)
    });
  }


}
