<div class="row justify-content-between">
  <div class="col">
    <mat-paginator #paginator
      [pageIndex]="0"
      [pageSize]="this.currentFilter.paginator.pageSize"
      [pageSizeOptions]="[20, 40, 60, 100]"
      (page)="paginatorChanged($event)">
    </mat-paginator>
  </div>
</div>

<div class="table-sdw is-fixed">

  <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.currentFilter.paginator.sortField" 
    [matSortDirection]="this.currentFilter.paginator.sortDir" class="is-header-sticky">

      <ng-container matColumnDef="tiquet">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Tiquet</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.tiquet}}</mat-cell>
        </ng-container>

      <ng-container matColumnDef="client">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Client</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.client}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Email</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.email}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="matricula">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Matrícula</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.matricula}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="persones">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Persones</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.personesReal}} / {{row.persones}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="personesAnulat">
        <mat-header-cell *matHeaderCellDef i18n>Anul·lats</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.personesAnulats}} / {{row.persones}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="zona">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Zona</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.zona}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="tarifa">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Tarifa</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.tarifa}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="horaCompra">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data/Hora compra</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.horaCompra}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="horaInici">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data/Hora inici</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.horaInici}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="horaFinal">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data/Hora final</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.horaFi}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="tipoVehiculo">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Vehicle</mat-header-cell>
        <mat-cell *matCellDef="let row">{{ this.getTipoVehiculo(row.tipoVehiculo) }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="btnAnular">
        <mat-header-cell *matHeaderCellDef i18n>Anul·lar</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button class="btn btn-outline-danger btn-sm" *ngIf="row.isReservaAnulable" (click)="this.anularReserva(row)">
            <i class="button-inline-elements fas fa-times"></i><span i18n>Anul·lar</span>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
    </mat-table>
</div>



