import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class DeltacarHistoricoFilter extends FilterData {
  matricula: string;
  dataInici: Date;
  dataFi: Date;
  estado: string;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.matricula = '';
    this.dataFi = null;
    this.dataInici = null;
    this.estado = '';

    this.labels['dataFi'] = this.i18n('Data Fi');
    this.labels['dataInici'] = this.i18n('Data Inici');
    this.labels['matricula'] = this.i18n('Matrícula');
    this.labels['estado'] = this.i18n('Estado');

    this.formatOutput['dataInici'] = 'shortDate';
    this.formatOutput['dataFi'] = 'shortDate';

  }
}
