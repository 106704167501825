import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
import { TipusEnviamentsFilter } from '../models/tipus-enviaments-filter';

const defaultSortParam = 'tipoEnvio';
const defaultSortDirection = 'asc';

@Injectable()
export class TipusEnviamentsFilterService extends CustomFilterService {
  public createFilterParams(filter: TipusEnviamentsFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {

    return new HttpParams()
    .append('tipoEnvio', filter.tipoEnvio)
    .append('descripcion', filter.descripcion)
    .append('bop', filter.bop !== null ? filter.bop.toString() : '')
    .append('producto', filter.producto)
    .append('cliente', filter.cliente)
    .append('codigoAdmision', filter.codigoAdmision)
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
