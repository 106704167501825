import { merge } from 'rxjs';
import { GestioResidentsService } from './../../services/gestio-residents.service';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { BonosResidentesFilter } from '../../models/bonos-residentes-filter';
import { BonosResidentes } from '../../models/bonos-residentes.model';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Builder } from 'path-shared/models/builder';
import { BonosResidentesFilterService } from '../../services/bonos-residentes-filter.service';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-bonos-residentes-table',
  templateUrl: './bonos-residentes-table.component.html',
  styleUrls: ['./bonos-residentes-table.component.scss']
})
export class BonosResidentesTableComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  data: BonosResidentes[];
  dataSource: CustomDataSource<BonosResidentes, BonosResidentesFilter>;
  highlightedRows = [];
  filter: BonosResidentesFilter = new BonosResidentesFilter('id', 'desc');
  filterPage: BonosResidentesFilter;
  displayedItems = [];

  @Input() updatedFilterData: BonosResidentesFilter;
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['id', 'matricula', 'fecha',  'importe', 'nombre', 'apellidos', 'dni', 'tipoBono', 'descZona', 'resultado', 'tipo_resultado', 'resultadoPago' ];

  constructor(
    private gestioResidentsService: GestioResidentsService,
    private filterService: FilterService,
    private dialog: MatDialog,
    private i18n: I18n
  ) {
  }

  ngOnInit() {
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<BonosResidentes, BonosResidentesFilter>(this.paginator, new BonosResidentesFilterService(), 'urlGetBonosResidentes', 'urlGetBonosResidentesPaginationCount');
    this.dataSource.subject.subscribe((data) => {


      // data.forEach(item => {
      //   this.gestioResidentsService.displayedItems[item.SECURE_ID] = {
      //     SECURE_ID: item.SECURE_ID,
      //     checked: false
      //   };
      // });

      this.displayedItems = this.gestioResidentsService.displayedItems;

    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
         this.filterService.saveFilter('bonos-filter', this.filter);
      }
    });
    // reset the paginator after sorting
    this.filterService.loadFilter('bonos-filter', this.filter);
    this.filter.paginator.init(this.sort, this.paginator);
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    } );
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.filter.updated()))
      .subscribe();
    this.load();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      this.update();
    }
  }

  update() {
    if (this.dataSource !== undefined) {
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
    }
  }

  load() {
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }


  generateExcel(): void {
    let filter = this.filter;
    if (this.updatedFilterData != null) {
      filter = this.updatedFilterData;
    }
    this.dialog.open(DownloadExcelDialogComponent, {
      closeOnNavigation: false,
      autoFocus: true,
      data: Builder.newDownloadExcelDialogData (this.gestioResidentsService, filter, this.i18n('BonosResidentes'), this.sort.direction, this.sort.active)
    });
  }

}
