<div class="row justify-content-between">
  <div class="col">
      <app-filter-selector [filter]="this.filter"></app-filter-selector>
  </div>
  <div class="col">
      <mat-paginator #paginator [pageIndex]="0" [pageSize]="this.filter.paginator.pageSize" [pageSizeOptions]="[20, 40, 60, 100]">
      </mat-paginator>
  </div>
</div>

<div class="table-sdw is-fixed">
  
  <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements"  class="is-header-sticky">

      <!-- exp Column -->
      <ng-container matColumnDef="exp">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Exp</mat-header-cell>
          <mat-cell *matCellDef="let row"><a (click)="onRowClicked(row)" class="is-link cursor detalle">{{row.exp}}</a></mat-cell>
      </ng-container>

      <!-- matricula Column -->
      <ng-container matColumnDef="matricula">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Matrícula</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.matricula }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="fechaPropuesta">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Fecha propuesta</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.fechaPropuesta | longDate }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="fechaDenuncia">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Fecha denuncia</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.fechaDenuncia | shortDate }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="textoAnulacion">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Texto anulación</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.textoAnulacion }}</mat-cell>
      </ng-container>

      <!-- data Column -->
      <ng-container matColumnDef="fechaAcceptacionDenegacion">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Fecha acceptacion/denegacion</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.fechaAcceptacionDenegacion | shortDate }}</mat-cell>
        </ng-container>

      <ng-container matColumnDef="aceptada">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Aceptada</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div *ngIf="row.fechaAcceptacionDenegacion !== undefined && row.fechaAcceptacionDenegacion !== null">
            <i *ngIf="row.aceptada" [class]="'fas fa-check-circle has-color-green'" ></i>
            <i *ngIf="!row.aceptada" [class]="'far fa-times-circle has-color-red'" ></i>
          </div>
        </mat-cell>
    </ng-container>
        
      <ng-container matColumnDef="usuarioAcceptacionDenegacion">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Usuario accep/deneg</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.usuarioAcceptacionDenegacion }}</mat-cell>
      </ng-container>

      <!-- comentarioUsuarioBackoffice Column -->
      <ng-container matColumnDef="comentarioUsuarioBackoffice">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Comentario backoffice</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.comentarioUsuarioBackoffice}}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
  </mat-table>
</div>
