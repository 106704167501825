import { Legislacio } from 'app/sections/configuracio/legislacions/Models/legislacio.model';
  import { I18n } from '@ngx-translate/i18n-polyfill';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { Infraccio } from '../../models/infraccio.model';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { InfraccioService } from '../../services/infraccions-service.service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { Builder } from 'path-shared/models/builder';
import { InfraccioFilter } from '../../models/infraccio-filter';
import { InfraccioFilterService } from '../../services/infraccions-filter-service';

@Component({
  selector: 'app-infraccions-table',
  templateUrl: './infraccions-table.component.html',
  styleUrls: ['./infraccions-table.component.scss']
})
export class InfraccionsTableComponent  implements OnInit, AfterViewInit, OnChanges  {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  data: Infraccio[];
  dataSource: CustomDataSource<Infraccio, InfraccioFilter>;
  filter: InfraccioFilter = new InfraccioFilter('id', 'asc');
  displayedItems = [];
  highlightedRows = [];
  public tipusLegislacions = Array();

  @Input() selectButton: boolean;
  @Input() updatedFilterData: InfraccioFilter;
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();
  @Output() itemClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() itemSelected: EventEmitter<any> = new EventEmitter<string>();

  displayedColumns = ['id', 'legislacio', 'article', 'apartat', 'opcio', 'import', 'fins', 'punts', 'obsoleta', 'inici', 'final', 'numFotosPda', 'descCurta'];

  constructor(
    private infracioService: InfraccioService,
    private filterService: FilterService,
    private dialog: MatDialog,
    private i18n: I18n) {
      this.selectButton = false;
    }

    ngOnInit() {

    if (this.selectButton) {
      this.displayedColumns = ['incorpora'].concat(this.displayedColumns);
    }

    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<Infraccio, InfraccioFilter>(this.paginator, new InfraccioFilterService(), 'urlGetInfraccions', 'urlGetCountInfraccions');
    this.dataSource.subject.subscribe((data) => {
      data.forEach(item => {
        this.displayedItems[item.secureId] = {
          id: item.secureId
        };
      });
    });

    if (!this.selectButton) { 
      this.filterService.loadFilter('infraccions-filter', this.filter); 
      // Por defecto no mostramos las obsoletas
      this.filter.obsoleta = false; 
      this.filter.updated();
    }

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
        if (!this.selectButton) { 
          this.filterService.saveFilter('infraccions-filter', this.filter); 
        }
      }
    });
    
    this.filter.paginator.init(this.sort, this.paginator);
  }
  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });

    this.paginator.page.subscribe(data => {
      this.paginator.pageIndex = data.pageIndex;
      this.paginator.pageSize = data.pageSize;
      this.load();
    });

    this.load();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      if ( this.filter.activeFilters.size !== 0) {
        this.paginator.pageIndex = 1;
        this.updatedFilterData.paginator.set(this.sort, this.paginator);
      }
      this.update();
      if ( this.filter.activeFilters.size !== 0) {
        this.paginator.pageIndex = 1;
        this.updatedFilterData.paginator.set(this.sort, this.paginator);
      }
    }
  }

  update() {
    if (this.dataSource !== undefined) {
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
    }
  }

  load() {

    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }


  onRowClicked(row) {

    this.itemClicked.next(row.secureId);
  }

  selectCurrent(row) {
    this.itemSelected.next({infraccio: row});
  }



  generateExcel(): void {
    /*let filter = this.filter;
    if (this.updatedFilterData != null) {
      filter = this.updatedFilterData;
    }
    this.dialog.open(DownloadExcelDialogComponent, {
        closeOnNavigation: false,
        autoFocus: true,
        data: Builder.newDownloadExcelDialogData (this.marquesVehiclesService, filter, this.i18n('Marques vehicles'), this.sort.direction, this.sort.active)
    });*/
  }

}
