import { LeyendaOpcionSeleccionada } from './../../models/leyenda-opcion-seleccionada';
import { OpcionesLeyenda } from './../../models/opciones-leyenda.model';
import { MapFeatures } from './../../models/map-features';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { TramitadorHistoricService } from 'path-denuncies-services/tramitador-historic.service';

@Component({
  selector: 'app-leyenda-map-component',
  templateUrl: './leyenda-map.component.html',
  styleUrls: ['./leyenda-map.component.scss']
})
export class LeyendaMapComponent implements OnInit {

  @Output() featureChanges: EventEmitter<LeyendaOpcionSeleccionada>;
  @Output() hoverVisivilityChange: EventEmitter<boolean>;

  hoverAlwaysActive: boolean;
  opciones: OpcionesLeyenda;

  constructor() {
    this.hoverAlwaysActive = false;
    this.opciones = new OpcionesLeyenda();
    this.featureChanges = new EventEmitter<LeyendaOpcionSeleccionada>();
    this.hoverVisivilityChange = new EventEmitter<boolean>();
  }

  ngOnInit() {
  }

  opcionModificada(opcion: string, valor: boolean) {
    if (opcion === MapFeatures.ACTIVATE_HOVER) {
      this.hoverVisivilityChange.emit(this.hoverAlwaysActive);
    } else if (opcion === MapFeatures.DENUNCIES) {
      if (valor) {
        // Si se activa denuncias (en general) activamos las de policia y las de zona azul.
        this.opciones.denunciasPolicia = true;
        this.opciones.denunciasZonaAzul = true;
      } else {
        this.opciones.denunciasPolicia = false;
        this.opciones.denunciasZonaAzul = false;
      }
      this.featureChanges.emit(new LeyendaOpcionSeleccionada(MapFeatures.DENUNCIES_POLICIA, valor));
      this.featureChanges.emit(new LeyendaOpcionSeleccionada(MapFeatures.DENUNCIES_ZONA_BLAVA, valor));
    } else if (opcion === MapFeatures.PROPOSTES_GRUA) {
      // Si se activan las propuestas de grua (en general) activamos las aceptadas, pendientes y rechazadas.
      if (valor) {
        this.opciones.propuestasGruaAceptadas = true;
        this.opciones.propuestasGruaPendientes = true;
        this.opciones.propuestasGruaRechazadas = true;
        this.opciones.propuestasGruaRetiradas = true;
      } else {
        this.opciones.propuestasGruaAceptadas = false;
        this.opciones.propuestasGruaPendientes = false;
        this.opciones.propuestasGruaRechazadas = false;
        this.opciones.propuestasGruaRetiradas = false;
      }
      this.featureChanges.emit(new LeyendaOpcionSeleccionada(MapFeatures.PROPOSTES_GRUA_ACCEPTADES, valor));
      this.featureChanges.emit(new LeyendaOpcionSeleccionada(MapFeatures.PROPOSTES_GRUA_PENDENTS, valor));
      this.featureChanges.emit(new LeyendaOpcionSeleccionada(MapFeatures.PROPOSTES_GRUA_REBUTJADES, valor));
      this.featureChanges.emit(new LeyendaOpcionSeleccionada(MapFeatures.PROPOSTES_GRUA_RETIRADES, valor));
    } else if (opcion === MapFeatures.OCUPACIO) {
      // Si se activa la ocupación (en general) activamos sólo la ocupacion general, pero desactivamos las dos si hace falta.
      if (valor) {
        this.opciones.ocupacionGeneral = true;
        this.featureChanges.emit(new LeyendaOpcionSeleccionada(MapFeatures.OCUPACIO_GENERAL, valor));
      } else if (!valor && this.opciones.ocupacionGeneral) {
        this.opciones.ocupacionGeneral = false;
        this.featureChanges.emit(new LeyendaOpcionSeleccionada(MapFeatures.OCUPACIO_GENERAL, valor));
      } else if (!valor && this.opciones.ocupacionCalor) {
        this.opciones.ocupacionCalor = false;
        this.featureChanges.emit(new LeyendaOpcionSeleccionada(MapFeatures.OCUPACIO_CALOR, valor));
      }
    } else if (opcion === MapFeatures.OCUPACIO_GENERAL) {
      if (valor) {
        this.opciones.ocupacionCalor = false;
        this.featureChanges.emit(new LeyendaOpcionSeleccionada(MapFeatures.OCUPACIO_CALOR, false));
      }
      this.featureChanges.emit(new LeyendaOpcionSeleccionada(opcion, valor));
    } else if (opcion === MapFeatures.OCUPACIO_CALOR) {
      if (valor) {
        this.opciones.ocupacionGeneral = false;
        this.featureChanges.emit(new LeyendaOpcionSeleccionada(MapFeatures.OCUPACIO_GENERAL, false));
      }
      this.featureChanges.emit(new LeyendaOpcionSeleccionada(opcion, valor));
    } else {
      // No es un caso especial, podemos tirar el emmit con los datos que nos han pasado.
      this.featureChanges.emit(new LeyendaOpcionSeleccionada(opcion, valor));
    }
  }

}
