import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { empty, timer } from 'rxjs';
import { ValidationService } from 'path-shared/services/validation/validation.service';

@Component({
  selector: 'app-form-currency-text-edit',
  templateUrl: './form-text-currency-edit.component.html',
  styleUrls: ['./form-text-currency-edit.component.scss']
})
export class FormTextCurrencyEditComponent implements OnInit, AfterViewInit {
  val: any;
  validVal = false;

  @Input() label: string;
  @Input() get value() {
    return this.val;
  } set value(val) {
    if (this.val !== val) {
      this.val = val;
      this.valid();
      if (this.validVal) {
        setTimeout(() => {
          this.valueChange.emit(this.val);
        }, 10);
      }
    }
  }

  @Output() valueChange = new EventEmitter();
  @Input() disabled: boolean;
  @Input() autofocus;
  @Input() required: boolean;
  @Input() isReadOnly: boolean;
  @Input() tabindex: number;

  @ViewChild('inputEdit') private elementRef: ElementRef;

  constructor(
    public validator: ValidationService
  ) {
    this.val = null;
    this.disabled = false;
    this.required = false;
    this.isReadOnly = false;
    this.tabindex = 0;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.autofocus !== undefined) {
      timer(500).subscribe(() => {
        this.elementRef.nativeElement.focus();
      });
    }
  }

  valid(): boolean {
    const ok =  !this.errorRequired() && !this.errorCurrency();
    this.validVal = ok;

    return ok;
  }

  isRequired(): boolean {
    return this.required !== false;
  }

  errorRequired(): boolean {
    return this.isRequired() && this.empty();
  }

  empty(): boolean {
    return (this.value === null || this.value === '');
  }
  errorCurrency(): boolean {
    return !this.validator.isNumeric(this.value) && !this.empty();
  }

  valueChangeCurrency(currency: any) {
    if (!this.validator.isEmpty(currency)) {
      currency = currency.replace('.','').replace(',', '.').replace('€','').trim();
    }

    if (this.validator.isNumeric(currency)) {
      return parseFloat(currency.replace(/[^0-9.-]+/g, '')).toFixed(2);
    } else {
      return currency;
    }
  }

}
