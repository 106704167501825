import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class GruaMotiusEntradaFilter extends FilterData {

  descripcio: string;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.descripcio = '';
    this.labels['descripcio'] = this.i18n('Motiu');

  }
}
