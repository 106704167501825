import { Component, OnInit, ViewChild, ElementRef, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatTableDataSource, MatSort } from '@angular/material';
import { ImagesService } from 'path-shared/services/images/images.service';
import { PhotoBoxViewerComponent } from 'path-shared/components/photo-box/components/photo-box-viewer/photo-box-viewer.component';
import { SingleImageViewerComponent } from 'path-shared/components/photo-box/components/single-image-viewer/single-image-viewer.component';
import { ToastrService } from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';

@Component({
    selector: 'app-grid-images',
    templateUrl: 'grid-images.component.html',
    styleUrls: ['grid-images.component.scss']
})
export class GridImagesComponent implements OnInit {

    displayedColumns = ['incorpora', 'NOMBRE_FICHERO', 'DataNew', 'UserNew', 'UBICACION_FICHERO'];
    dataSource = new MatTableDataSource();
    selectedItems = [];
    contenedor: string;
    @ViewChild('fileInput') fileInput: any;
    @ViewChild(MatSort) sort: MatSort;
    files = [];
    allSelected = false;
    items = [];


    constructor(
        private imagesService: ImagesService,
        private dialog: MatDialog,
        private toastr: ToastrService,
        private i18n: I18n,
        private confirmationDialogService: ConfirmationDialogService,
        @Inject(MAT_DIALOG_DATA) public data: string,
        ) {
            this.contenedor = data;
    }


    ngOnInit(): void {
        this.refrescar();
    }

    OpenImage(row) {
        const src = row.NOMBRE_FICHERO.toLowerCase().endsWith('.pdf') ? this.fileUrl(row.SOM_ID) : this.imageUrl(row.SOM_ID);
        this.dialog.open(SingleImageViewerComponent,
            {
              width: '60%',
              height: '60%',
              closeOnNavigation: false,
              autoFocus: true,
              data: {
                src: src,
                name: row.NOMBRE_FICHERO
              }
            });
    }

    imageUrl(somId, suffix = '') {
        const src = this.imagesService.getImageUrl(somId, suffix) + '?' + new Date().getTime();
        return src;
    }

    fileUrl(somId): string {

        const src = this.imagesService.getFileUrl(somId) + '?' + new Date().getTime();
        return src;

      }

    Afegir() {
        const event = new MouseEvent('click', {bubbles: false});
        this.fileInput.file.nativeElement.dispatchEvent(event);
    }

    fileReady(files) {
        this.files = files;
        this.processar();
    }

    rowIndex(row) {
        return this.items.findIndex(x => x.SOM_ID === row.SOM_ID);
    }

    numSelected() {
        return this.items.filter(x => x.checked === true).length;
    }

    refrescar() {
        this.imagesService.getImagesByContenedor(this.contenedor).subscribe(data => {
            this.dataSource = new MatTableDataSource(data);
            this.dataSource.sort = this.sort;
            this.items = data;
            this.items.forEach(elem => {
                elem.checked = false;
            });
        });
    }
    processar() {
        if (this.files === undefined || this.files === null) {
          this.toastr.warning(this.i18n('Cal seleccionar un fitxer per carregar!'));
        } else {
            const formData: FormData = new FormData();

            this.files.forEach(file => {
              formData.append('file', file, file.name);
            });

            const object = [this.contenedor, formData];

            this.imagesService.afegirImatges(formData, this.contenedor).subscribe(result => {
                this.refrescar();

                this.toastr.info(this.i18n('Imatge carregada correctament'));
            });

        }

    }

    SeleccionarTotes() {

        this.items.forEach(elem => {
            elem.checked = this.allSelected;
        });

    }

    Eliminar() {
        this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol eliminar les imatges seleccionades?'))
        .then((confirmed) => {
          if (confirmed) {
            this.items.forEach(element => {
              if (element.checked === true) {
                this.imagesService.deleteImage(element.SECURE_ID).subscribe(() => {
                    this.refrescar();
                });
              }
            });
          }
        });
    }


}
