<app-filter-header title="Filtrar tècnics" i18n-title  (apply)="this.apply()" (reset)="this.reset()" (dismiss)="this.dismiss()"></app-filter-header>
<div class="modal-body">
  <div class="row">
    <app-filter-text-edit id="grua-tecnics-filter-codi" class="col-sm-9" i18n-label label="Codi" [(value)]="filter.codi" contains></app-filter-text-edit>
  </div>
  <div class="row">
    <app-filter-text-edit id="grua-tecnics-filter-nom" class="col-sm-9" i18n-label label="Nom" [(value)]="filter.nom" contains></app-filter-text-edit>
  </div>
  <div class="row">
    <app-filter-text-edit id="grua-tecnics-filter-dni" class="col-sm-9" i18n-label label="DNI" [(value)]="filter.DNI" contains></app-filter-text-edit>
  </div>
</div>
