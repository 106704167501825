import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { DipositGruaFilter } from '../../Models/diposit-filter';
import { FormControl } from '@angular/forms';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { DipositFilterComponent } from '../diposit-filter/diposit-filter.component';
import { DipositEditorComponent } from '../diposit-editor/diposit-editor.component';
import { SortidesTableComponent } from '../sortides-table/sortides-table.component';

@Component({
  selector: 'app-sortides',
  templateUrl: './sortides.component.html',
  styleUrls: ['./sortides.component.scss']
})
export class SortidesComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  selected = new FormControl(0);
  filter: DipositGruaFilter = new DipositGruaFilter('', '');
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  @ViewChild('diposit') DipositTable: SortidesTableComponent;

  constructor( public authService: AuthorizationService,
    private not: NotificationService,
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private i18n: I18n) {
      this.filter = new DipositGruaFilter('', '');
    }

  ngOnInit() {
    this.authService.CheckUserPermission(this.authService.Pages.DipositGrua);
  }

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Dipòsit'));
    }
  }

  onFilterClicked() {
    const ref = this.dialog.open(DipositFilterComponent, {
      width: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.DipositTable.filter
      }
    });

    /*ref.componentInstance.notify.subscribe((filter: DipositFilterComponent) => {
      this.DipositTable.filter.set(filter);
      this.DipositTable.filter.updated();
      this.DipositTable.load();
    });*/
  }

  newDipositVehcile() {
      const ref = this.dialog.open(DipositEditorComponent, {
        width: '90%',
        height: '90%',
        closeOnNavigation: true,
        autoFocus: true,
        data: {
          'isDialog': true,
          'isReadOnly': false,
          'vehicleDiposit': null
        }
      });
      ref.afterClosed().subscribe(data => {
        this.refresh();
      });
  }
  refresh() {
    this.DipositTable.load();
  }
generateExcel() {
    this.DipositTable.generateExcel();
  }

}
