import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { ExcelGenerator } from 'path-shared/interfaces/excel-generator';
import { HttpClient, HttpRequest, HttpEventType, HttpResponse  } from '@angular/common/http';
import { Automatitzacio } from '../models/automatitzacio.model';

const defaultSortParam = 'codi';
const defaultSortDirection = 'desc';


@Injectable()
export class AutomatitzacioService {


  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {

  }

  getAutomatitzacions(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAutomatitzacions'],  null);
    }
  }


  setAutomatitzacions(automatitzacio: Automatitzacio): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetAutomatitzacions'], automatitzacio);
    }
  }

}
