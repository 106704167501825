import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class PropostesAnulacioFilter extends FilterData {
  matricula: string;
  exp: string;
  acceptada: boolean;
  dataAceptDengIni: Date;
  dataAceptDengFi: Date;
  dataDenunciaIni: Date;
  dataDenunciaFi: Date;
  dataPropIni: Date;
  dataPropFi: Date;


  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.matricula = null;
    this.exp = null;
    this.acceptada = null;
    this.dataAceptDengIni = null;
    this.dataAceptDengFi = null;
    this.dataDenunciaIni = null;
    this.dataDenunciaFi = null;
    this.dataPropIni= null;
    this.dataPropFi= null;

    

    this.labels['matricula'] = this.i18n('Matricula');
    this.labels['exp'] = this.i18n('Exp');
    this.labels['acceptada'] = this.i18n('Acceptada');
    this.labels['dataAceptDengIni'] = this.i18n('Fecha Acep/Deng inicio');
    this.labels['dataAceptDengFi'] = this.i18n('Fecha Acep/Deng fin');
    this.labels['dataDenunciaIni'] = this.i18n('Fecha denuncia inicio');
    this.labels['dataDenunciaFi'] = this.i18n('Fecha denuncia fin');
    this.labels['dataPropIni'] = this.i18n('Fecha propuesta inicio');
    this.labels['dataPropFi'] = this.i18n('Fecha propuesta fin');
  }
}
