<app-toolbar> 
  <app-tool-button text="Actualizar" i18n-text (click)="load();" color="has-color-blue" icon="fa-sync"></app-tool-button>
  <app-tool-button-permissions [permission]="this.auth.Pages.GestioResidents"></app-tool-button-permissions>
</app-toolbar>

<div class="col-2">
  <span>Selecciona semana</span>
  <app-form-date-edit [(value)]="dateSelected" (valueChange)="load()"></app-form-date-edit>

</div>

<div class="row cites" *ngIf="gestioResidentsCites!==null">
    <div  *ngFor="let dia of gestioResidentsCites.dies; let indice=index" [class]="indice===5 || indice===6? 'col-2 cites-cell-min cites-title':'col-2 cites-cell cites-title'">      
      {{dia.nom}}      
    </div>
    <div class="cites-cell" *ngFor="let dia of gestioResidentsCites.dies">
      <div *ngFor="let cita of dia.cites">
        <div *ngIf="cita !== null" class="cites-item">
          <div class="row">
            <div class="col-2"  ><span><b>Hora: </b></span></div>
            <div class="col-8">{{cita.hora}}
               <i *ngIf="cita.estat===1" class="fas fa-clock has-color-yellow"></i>
              <i *ngIf="cita.estat===2" class="fas fa-check-circle has-color-green"></i>
              <i *ngIf="cita.estat===3" class="fas fa-times-circle has-color-red"></i></div>
          </div>
          <div class="row">
            <div class="col-2" ><span><b>DNI: </b></span></div>
            <div class="col-9" style="max-inline-size: fit-content;">{{cita.DNI}}</div>
          </div>
          <div class="row">
            <div class="col-3" ><span><b i18n>Nombre: </b></span></div>
            <div class="col-8" style="max-inline-size: fit-content;">{{cita.nombre}}</div>
          </div>
          <div class="row">
            <div class="col-3 "style=";margin-right:1vh"><span><b i18n>Apellidos: </b></span></div>
            <div class="col-8" style="max-inline-size: fit-content;">{{cita.apellidos}}</div>
          </div>
          <div class="row">
            <div class="col-2" ><span><b i18n>Email: </b></span></div>
            <div class="col-9" style="max-inline-size: fit-content;">{{cita.email}}</div>
          </div>
          <div class="row">
            <div class="col-2" ><span><b i18n>Telèf: </b></span></div>
            <div class="col-9" style="max-inline-size: fit-content;">{{cita.telefono}}</div>
          </div>
        </div>
      </div>
    </div>
  
</div>




