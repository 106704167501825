<app-dialog-header label="Zones" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Editar" i18n-text (click)="this.dialogData.isReadOnly = false" color="has-color-green" icon="fa-edit" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Zonas)  || !this.dialogData.isReadOnly ||!this.publicarAvailable"></app-tool-button>
  <app-tool-button text="Guardar" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Zonas) || this.dialogData.isReadOnly || !this.publicarAvailable"></app-tool-button>
  <!-- <app-tool-button text="Eliminar" i18n-text (click)="this.remove();" color="has-color-red" icon="fa-trash-alt" [disabled]="!this.auth.UserCanDelete(this.auth.Pages.Festius) ||this.dialogData.isReadOnly"></app-tool-button> -->
  <app-tool-button [text]="this.closeButtonText" i18n-text (click)="this.cancel()" color="has-color-yellow" icon="fa-th-list"></app-tool-button>
</app-toolbar>
<app-dialog-body>
  <form #mainForm>
    <div class="row">
      <app-form-text-edit class="col-2" id="zones-aparcare-editor-codi" i18n-label label="Codi" [(value)]="this.zonaAparcare.codi" required disabled></app-form-text-edit>
      <app-form-text-edit class="col-3" id="zones-aparcare-editor-descripcio" i18n-label label="Descripció" [(value)]="this.zonaAparcare.descripcio" required [isReadOnly]="this.dialogData.isReadOnly"></app-form-text-edit>
      <app-form-combo-edit class="col-3" i18n-label label="Tarifa" [(value)]="this.zonaAparcare.idTarifa" [items]="this.tarifes" valueField="codi" textField="descripcio" required [isReadOnly]="this.dialogData.isReadOnly || !this.publicarAvailable"></app-form-combo-edit>
      <app-form-date-edit  class="col-2" id="zones-aparcare-editor-dataIni" i18n-label label="Data inici" [(value)]="this.zonaAparcare.dataInici" required [isReadOnly]="this.dialogData.isReadOnly"></app-form-date-edit>
      <app-form-date-edit class="col-2" id="zones-aparcare-editor-dataFi" i18n-label label="Data fi" [(value)]="this.zonaAparcare.dataFi" required [isReadOnly]="this.dialogData.isReadOnly"></app-form-date-edit>     

    </div>
  </form>
</app-dialog-body>