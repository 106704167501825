import { ValoraAnulacionComponent } from './../components/valora-anulacion/valora-anulacion.component';
import { AnulacionZonaAzulAutoFilterService } from './../service/anulacion-zona-azul-auto-filter.service';
import { AnulacionZonaAzulAuto } from './../models/anulacion-zona-azul-auto.model';
import { FileDownloader } from 'path-shared/services/http/fileDownloader';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import {Component, OnInit, ViewChild, AfterViewInit, EventEmitter, Input, Output, OnChanges, SimpleChanges} from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatCheckbox } from '@angular/material';
import {HttpSecureClient} from 'path-shared/services/http/httpSecureClient';
import {filter} from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService, Toast } from 'ngx-toastr';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { FormLiveStateType } from 'path-shared/components/form/form-live-state/form-live-state-type.model';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { DenunciesEditorComponent } from 'app/sections/denuncies/components/denuncies-editor/denuncies-editor.component';
import { AnulacionZonaAzulAutoFilter } from '../models/anulacion-zona-azul-auto-filter';
import { AnulacionZonaAzulAutoService } from 'app/sections/anulacion-zona-azul-auto/service/anulacion-zona-azul-auto.service';
import { Anulacions } from '../models/anulacions.model';
import { DisplayTextDialogComponent } from 'path-shared/components/dialogs/display-text-dialog/display-text-dialog.component';
import { ProgressBarComponent } from 'path-shared/components/progress-bar/progress-bar.component';


@Component({
  selector: 'app-anulacion-zona-azul-auto',
  templateUrl: './anulacion-zona-azul-auto.component.html',
  styleUrls: ['./anulacion-zona-azul-auto.component.scss']
})

export class AnulacionZonaAzulAutoComponent implements OnInit, AfterViewInit, OnChanges {
  public Types = FormLiveStateType;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('allCheck') private allCheck: MatCheckbox;
  @ViewChild('progressBar') private progressBar: ProgressBarComponent;

  dataSource: CustomDataSource<AnulacionZonaAzulAuto, AnulacionZonaAzulAutoFilter>;
  highlightedRows = [];
  filter: AnulacionZonaAzulAutoFilter = new AnulacionZonaAzulAutoFilter('Expedient', 'asc');
  filterPage: AnulacionZonaAzulAutoFilter;
  updatedFilterData: AnulacionZonaAzulAutoFilter = new AnulacionZonaAzulAutoFilter('', '');
  displayedItems = [];
  errorConsultandoParkeon = null;


  // Emits
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();

  /** Columns displayed in the table.*/
  displayedColumns = [ 'Check', 'Expedient', 'Processada', 'DataExp',
   'HoraExp', 'Anulacio', 'DataAnul', 'HoraAnul', 'Import', 'Maquina', 'DataProc', 'Resultat', 'action'];

  constructor(
    public auth: AuthorizationService,
    private dialog: MatDialog,
    private i18n: I18n,
    private toastr: ToastrService,
    private http: HttpSecureClient,
    private route: ActivatedRoute,
    private confirmationDialogService: ConfirmationDialogService,
    private anulacionZonaAzulAutoService: AnulacionZonaAzulAutoService,
    private router: Router,
    private filterService: FilterService,
    private not: NotificationService
    ) {
      this.displayedItems['checkedValues'] = 0;

    }

  ngOnInit() {
    // this.auth.UserCanSeePage(this.auth.Pages.AnulacioZBAuto);
    this.not.titleChange(this.i18n('Anul·lació automàtica'));
    this.paginator.pageSize = 99999;
    this.filterService.loadFilter('anulacion-zona-azul-auto-filter', this.filter);
    this.sort.active = this.filter.paginator.sortField;
    this.sort.disableClear = true;
    this.not.onErrorConsultaParkeonChange.pipe(
      filter(
        (ErrorConsulta) =>
        JSON.parse(ErrorConsulta).token===sessionStorage.getItem('access_token')
        )
      )
    .subscribe((value) => {
      this.errorConsultandoParkeon = JSON.parse(value).message;

      if (this.errorConsultandoParkeon !== null) {
        this.dialog.open(DisplayTextDialogComponent, {
          width: '45%',
          closeOnNavigation: false,
          autoFocus: true,
          data: {text: this.i18n('Error consultant a Parkeon: ' + this.errorConsultandoParkeon), label: this.i18n('Atenció')}
        });
        this.errorConsultandoParkeon = null;
      }
    });

    this.dataSource = CustomDataSource.create<AnulacionZonaAzulAuto, AnulacionZonaAzulAutoFilter>(this.paginator, new AnulacionZonaAzulAutoFilterService(), 'urlAnulacionZonaAzulAuto', 'urlAnulacionZonaAzulAutoCount');
    this.dataSource.subject.subscribe((data) => {

      data.forEach(item => {
        this.displayedItems[item.SomId] = {
          id : item.SomId,
          checked: false
        };
      });

    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.loadAnulAutoZBPage();
        this.filterService.saveFilter('anulacion-zona-azul-auto-filter', this.filter);
      }
    });

    this.not.onEstatAnulacioProcessadaChange.subscribe(value => {
      const item = this.dataSource.get('SomId', value.somId.toString());
      if (item !== undefined) {
        item.Resultat = value.resultado;
        item.DataProc = value.fechaProcesada;
        this.displayedItems[item.SomId].checked = false;
        item.Processada = true;
      }
    });

  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(data => {
      this.paginator.pageIndex = 0;
      this.dataSource.sort(this.sort.active, this.sort.direction.toString());
    });

  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      this.loadAnulAutoZBPage();
    }
  }

  consultar() {
    this.progressBar.reset();
    this.loadAnulAutoZBPage();
  }

  loadAnulAutoZBPage(loadFilter: boolean = true) {
    this.allCheck.checked = false;
    this.displayedItems['checkedItems'] = new Array();
    this.displayedItems['checkedValues'] = 0;
    if (loadFilter) {
      this.dataSource.loadFilter(this.filter);
    }
  }

  getCheckedValues() {
    return this.displayedItems['checkedValues'];
  }

  toggleAllCheckbox() {
    for (const item in this.displayedItems) {
      if (item !== 'checkedItems' && item !== 'checkedValues') {
        this.toggleItem(item);
      }
    }
  }

  toggleItem(id) {
    const itemStatus = this.displayedItems[id].checked = !this.displayedItems[id].checked;
    
    if (itemStatus) {
      this.displayedItems['checkedItems'].push(id);
      this.displayedItems['checkedValues'] = this.getCheckedValues() + 1;
    } else {
      this.displayedItems['checkedItems'].pop(id);
      this.displayedItems['checkedValues'] = this.getCheckedValues() - 1;
    }
  }

  // toggleItem(id) {
  //   if (!this.displayedItems[id].checked) {
  //     this.displayedItems['checkedItems'].push(parseInt(id, 10));
  //     this.displayedItems['checkedValues'] = this.getCheckedValues() + 1;
  //     this.displayedItems[id].checked = true;
  //   } else {
  //     const index = this.displayedItems['checkedItems'].indexOf(id);
  //     if (index >= 0) {
  //       this.displayedItems['checkedItems'].splice(index, 1);
  //     }

  //     this.displayedItems['checkedValues'] = this.getCheckedValues() - 1;
  //     this.displayedItems[id].checked = false;
  //   }
  // }

  selectedItemsLength(): boolean {
    return (this.getCheckedValues()) > 0;
  }

  selectCheckedItems() {
    return this.displayedItems['checkedValues'];
  }

  irHistorico(): void {
    this.router.navigate(['anulacion-zona-azul-auto-historic']);
  }

  Processar(): void {
    const dp = this.displayedItems;
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol processar les denuncies sel·leccionades?'))
    .then((confirmed) => {
      if (confirmed) {
        const anulacionsInfo = new Anulacions();
        anulacionsInfo.fechaInicio = this.filter.fechaInicio;
        anulacionsInfo.fechaFin = this.filter.fechaFin;
        anulacionsInfo.listAnulacions = dp['checkedValues'] > 0 ? dp['checkedItems'] : dp;
        this.toastr.success(this.i18n('Procés iniciat.'));
        this.anulacionZonaAzulAutoService.processar(anulacionsInfo).subscribe(result => {
          this.toastr.success(this.i18n('Procés realitzat'));
          if (this.dataSource.getData().findIndex(x => this.displayedItems[x.SomId].checked === true &&  x.Resultat !== 'OK' && x.Resultat !== null) !== -1 ) {
            this.dialog.open(DisplayTextDialogComponent, {
              width: '25%',
              closeOnNavigation: false,
              autoFocus: true,
              data: {text: this.i18n('S\'han trobat incidències aplicant les anul·lacions. Revisi els resultats.'), label: this.i18n('Atenció')}
            });
          } 
          this.loadAnulAutoZBPage(false);
        });
      }
    });
  }

  veureDenuncia(numDenuncia) {
    const data: ComponentDialog = Builder.newComponentDialog(true, true, numDenuncia);
      const ref = this.dialog.open(DenunciesEditorComponent, {
        width: '99%',
        height: '99%',
        maxHeight: '100vh',
        closeOnNavigation: false,
        autoFocus: true,
        data: data
      });
  }
  puedeValorarAnulacion(anulacion: AnulacionZonaAzulAuto): boolean {
    if (anulacion !== undefined && anulacion !== null
          && anulacion.Processada !== undefined && anulacion.Processada !== null && anulacion.Processada) {
            if (anulacion.Resultat !== undefined && anulacion.Resultat !== null && anulacion.Resultat !== '' && !anulacion.Resultat.startsWith('OK')) {
              return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
  }
  valorarAnulacion(anulacion: AnulacionZonaAzulAuto) {
    if (this.puedeValorarAnulacion(anulacion) ) {
      if (anulacion.Expedient !== undefined && anulacion.Expedient !== null && anulacion.Expedient !== '' ) {
           const ref = this.dialog.open(ValoraAnulacionComponent, {
              width: '90%',
              height: '90%',
              closeOnNavigation: true,
              autoFocus: true,
              data: {
                'isDialog': true,
                'isReadOnly': false,
                'anulacion': anulacion
              }
            });
            ref.afterClosed().subscribe(data => {
             // this.update();
             if (data !== undefined && data !== null &&  data.update && data.result !== undefined && data.result !== null) {
              const actualData = this.dataSource.getData();

                const index = actualData.findIndex(x => x.SomId === data.result.SomId );
                if (index !== -1) {
                  actualData[index].Resultat = data.result.Resultat;
                  this.dataSource.updateData(actualData);
                }

              // this.loadAnulAutoZBPage();
             }
            });
          } else {
            this.toastr.warning(this.i18n('Esta anulación no tiene expediente identificado'));
          }
      }
  }

}
