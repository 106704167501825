import { AfterViewInit, Component, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MatPaginator, MatSort } from '@angular/material';
import { Router } from '@angular/router';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { PropostesEstatCarregatsFilterService } from 'path-propostes-services/importacio-denuncies-historic-filter.service';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PropostesEstatCarregatsFilter } from '../../models/propostes-estat-carregats.filter';
import { PropostesEstatCarregats } from '../../models/propostes-estat-carregats.model';
import { PropostesEstatCarregatsFilterComponent } from '../../components/propostes-estat-carregats/propostes-estat-carregats-filter/propostes-estat-carregats-filter.component';

@Component({
    selector: 'app-propostes-estat-carregats',
    templateUrl: 'propostes-estat-carregats.component.html',
    styleUrls: ['propostes-estat-carregats.component.scss']
})
export class PropostesEstatCarregatsComponent implements OnInit, OnChanges, AfterViewInit {

    displayedColumns = ['nom', 'data', 'estat', 'error'];
    filter: PropostesEstatCarregatsFilter = new PropostesEstatCarregatsFilter('data', 'desc');
    @ViewChild (MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    dataSource: CustomDataSource<PropostesEstatCarregats, PropostesEstatCarregatsFilter>;
    updatedFilterData: PropostesEstatCarregatsFilter = new PropostesEstatCarregatsFilter('', '');


    constructor(
        private router: Router,
        private not: NotificationService,
        private i18n: I18n,
        private filterService: FilterService,
        private estatFilterDialogRef: MatDialogRef<PropostesEstatCarregatsFilterComponent>,
        private dialog: MatDialog
      ) {
      }

    ngOnInit() {
        // this.not.titleChange(this.i18n('Històric d\'importació de denúncies'));

        // this.dataSource = CustomDataSource.create<ImportacioDenunciesHistoric, ImportacioDenunciesHistoricFilter>(this.paginator, new ImportacioDenunciesHistoricFilterService(), 'urlImportacioDenunciesHistoric', 'urlImportacioDenunciesHistoricCount');
        // this.filter.filterUpdated.subscribe(updated => {
        //     if (updated) {
        //         this.dataSource.loadFilter(this.filter);
        //       this.filterService.saveFilter('importacio-filter', this.filter);
        //     }
        //   });
        //   this.filterService.loadFilter('importacio-filter', this.filter);

    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<PropostesEstatCarregats, PropostesEstatCarregatsFilter>(this.paginator, new PropostesEstatCarregatsFilterService(), 'urlImportacioDenunciesHistoric', 'urlImportacioDenunciesHistoricCount');

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
      }
    });

    this.filter.paginator.init(this.sort, this.paginator);

    }


    ngAfterViewInit() {
      // reset the paginator after sorting
      this.sort.sortChange.subscribe(() => {
        this.paginator.pageIndex = 0;
        this.filter.paginator.set(this.sort, this.paginator);
        this.filter.updated();
      });

      merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.filter.updated()))
      .subscribe();

      this.load();
    }

    ngOnChanges(changes: SimpleChanges) {
      if (this.updatedFilterData != null) {
        this.update();
      }
    }

    update() {
      if (this.dataSource !== undefined) {
        this.updatedFilterData.paginator.set(this.sort, this.paginator);
        this.dataSource.loadFilter(this.updatedFilterData);
      }
    }

    load() {
      this.filter.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.filter);
    }

    goImportador() {
        this.router.navigate(['denuncies/importacio']);
    }

    onFilterClicked() {
      this.estatFilterDialogRef = this.dialog.open(PropostesEstatCarregatsFilterComponent, {
        width: '90%',
        height: 'auto',
        closeOnNavigation: false,
        autoFocus: true,
        data: this.filter
      });
  
      this.estatFilterDialogRef.componentInstance.notify.subscribe((filter: PropostesEstatCarregatsFilter) => {
        this.filter.set(filter);
        this.load();
      });
    }
}
