import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
import { TipusTiquetsFilter } from '../models/tipus-tiquets-filter';



const defaultSortParam = 'codi';
const defaultSortDirection = 'asc';

@Injectable()
export class TipusTiquetsFilterService extends CustomFilterService {
  public createFilterParams(filter: TipusTiquetsFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {
    return new HttpParams()
    .append('codi', filter.codi === null ? '' : filter.codi.toString())
    .append('descripcio', filter.descripcio)
    .append('dataInici', filter.dataInici === null ? '' : filter.dataInici.toString())
    .append('dataFi', filter.dataFi === null ? '' : filter.dataFi.toString())
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
