import { Component, OnInit, ViewChild } from '@angular/core';
import { SomPolGruaFilter } from '../../Models/som-pol-grua-filter';
import { PendentsComponent } from '../pendents/pendents.component';
import { PropostaGruaDto } from '../../Models/proposta-grua.model';
import { GruaPendentsService } from '../../services/grua-pendents.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pendents-viewer',
  templateUrl: './pendents-viewer.component.html',
  styleUrls: ['./pendents-viewer.component.scss']
})
export class PendentsViewerComponent implements OnInit {

  CONST_ID_ALL: string = '-999';

  selectedAll = false;
  data: PropostaGruaDto[] = [];
  //dataSource: PropostesDataSource;
  filter: SomPolGruaFilter = new SomPolGruaFilter('', '');
  //filterPage: PaginationFilter;
  current: number;
  start: number;
  //tipusPropostes: CFG_TIPOPROPUESTA[];
  currentProposta: PropostaGruaDto;
  currentPage: number;
 // counters: KeyCounter[];
  //tipusCounters: KeyCounter[];
  totalPropostes: number;
  pageTitle: string;
  propostesPendentsAnterior = -1;
  novesPropostes = false;
  validacioError = [];
  agruparPerPais = false;
  timeout = null;
  private pageSize: number;
  imagesLoaded: Promise<boolean>;

  ordre = 'desc';
  @ViewChild('pendentsComponent') pendentsComponent: PendentsComponent;

  constructor(private gruaService: GruaPendentsService,
              private i18n: I18n,
              public auth: AuthorizationService,
              private router: Router, ) { 
                this.current = 0;
              }

  ngOnInit() {
    this.auth.CheckUserPermission(this.auth.Pages.GruaPendents);
    this.loadPropostesPendents();
  
  }
  loadPropostesPendents() {
    this.gruaService.getAll(this.ordre).subscribe(results => {
      this.data = results;
      if ( results !== undefined && results !== null ) {
        this.totalPropostes = this.data.length;
      }
      this.changeCurrent();
  });
  }
  veureLlistaPropostes(){
    this.router.navigateByUrl('/grua/pedents-list');
  } 

  previousProposta() {
    if (this.totalPropostes > 0) {
      if (this.current > 0) {
        this.current--;
      } else {
        if (this.currentPage > 0) {
          this.current = this.pageSize - 1;
          this.currentPage--;
          this.update(false);
        } else {
          this.current = 0;
        }
      }
      if (this.data.length > 0) {
        this.changeCurrent();
      }
    }
  }

  nextProposta() {
    if (this.current < this.sumaPropostes() - 1) {
        this.current++;
    } else {
      if ((this.currentPage + 1) * this.pageSize <= this.totalPropostes) {
        this.current = 0;
        this.currentPage++;
        this.update(false);
      }
    }
    if (this.data.length > 0) {
      this.changeCurrent();
    }
  }

  update(init) {
    if (init) {
      this.current = 0;
      this.currentPage = 0;
    }
    this.loadPropostesPendents();
    // this.getCounters();
    // this.getPaisosCounters();
    // this.getTipoPropostaCounters();
    // this.novesPropostes = false;
  }

  changeCurrent() {
    this.currentProposta  = Object.assign({} , this.data[this.current]);
    if ( this.pendentsComponent !== undefined && this.pendentsComponent !== null ){
      this.pendentsComponent.proposta = this.currentProposta;
      this.pendentsComponent.ngOnInit();
    }
    if (this.currentProposta !== undefined && this.currentProposta !== null 
      && this.currentProposta.codi !== undefined && this.currentProposta.codi !== null
      && this.currentProposta.codi !== '' && this.pendentsComponent !== undefined && this.pendentsComponent !== null) {
         this.pendentsComponent.loadImatgesByCodi(this.currentProposta.codi);
         //this.pendentsComponent.loadInfraccions();
        // this.pendentsComponent.loadAliasGrua();
      }
    // if (this.currentProposta.IMATGES == null) {
    //   this.propostesService
    //     .getImages(this.currentProposta.NUMDENUNCIA)
    //     .subscribe(images => {
    //       // Per evitar assignar un array buit si somDeamon encara no ha processat les imatges.
    //       if ( images.length !== this.currentProposta.NUMERO_IMATGES ) {
    //         setTimeout(() => {
    //           this.changeCurrent();
    //         }, 1500);
    //       } else {
    //         this.currentProposta.IMATGES = images;
    //         this.imagesLoaded = Promise.resolve(true);
    //       }
    //     });
    // } else {
    //   this.propostaViewer.updateImatges(this.currentProposta.IMATGES);
    // }
  }
  sumaPropostes(): number {
    let count = 0;
    if (this.data !== undefined && this.data !== null) {
      count = this.data.length;
    }
    return count;
  }

  getCurrentPropostaText() {
    if (this.totalPropostes > 0) {
      return this.current + 1 + ' ' + this.i18n('de') + ' ' + this.totalPropostes;
    } else {
      return this.current  + ' ' + this.i18n('de') + ' ' + this.totalPropostes;
    }
  }
  changeOrdre() {
    if (this.ordre === 'desc') {
      this.ordre = 'asc';
    } else {
      this.ordre = 'desc';
    }
    this.loadPropostesPendents();
  }
}
