import { TramosTiposFilter } from './../../models/tramos-tipos-filter';
import { Component, OnInit, EventEmitter, Output, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';

@Component({
  selector: 'app-tramos-tipos-filter',
  templateUrl: './tramos-tipos-filter.component.html',
  styleUrls: ['./tramos-tipos-filter.component.scss']
})
export class TramosTiposFilterComponent implements OnInit {
  filter: TramosTiposFilter;

  @Output() notify: EventEmitter<TramosTiposFilter> = new EventEmitter<TramosTiposFilter>();

  constructor( public thisDialogRef: MatDialogRef<TramosTiposFilterComponent>,
    private i18n: I18n,
    @Inject(MAT_DIALOG_DATA) public data) {
    this.filter = data.filter;
  }

  ngOnInit() {
  }

  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated();
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }

}
