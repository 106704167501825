import { Component, OnInit, Output, EventEmitter, Inject, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { InfraccioFilter } from '../../models/infraccio-filter';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-infraccions-filter',
  templateUrl: './infraccions-filter.component.html',
  styleUrls: ['./infraccions-filter.component.scss']
})
export class InfraccionsFilterComponent implements OnInit, OnDestroy {
  filter: InfraccioFilter;
  tipusLegislacions = [];
  @Output() notify: EventEmitter<InfraccioFilter> = new EventEmitter<InfraccioFilter>();
  subscription: Subscription;
  @ViewChild('mainForm') private mainForm: ElementRef;
  constructor(public thisDialogRef: MatDialogRef<InfraccionsFilterComponent>,
    private i18n: I18n,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    this.filter = data.filter;
    this.tipusLegislacions = data.tipusLegislacions;
   }

  ngOnInit() {
    this.subscription = this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.apply();
      }
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
      this.filter.updated();
      this.notify.emit(this.filter);
      this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }


}
