<app-dialog-header *ngIf="this.dataDialog.isDialog" label="Històric matrícules especials" i18n-label></app-dialog-header>

<app-toolbar>
  <app-tool-button text="Actualizar" i18n-text (click)="refresh();" color="has-color-blue" icon="fa-sync"></app-tool-button>
  <app-tool-button text="Filtre" i18n-text (click)="onFilterClicked();" color="has-color-orange" icon="fa-filter"></app-tool-button>
  <app-tool-button text="Excel" i18n-text (click)="generateExcel();" color="has-color-darkgreen" icon="fa-file-excel"></app-tool-button>
  <app-tool-button text="Matrícules" i18n-text (click)="viewMatricules();" color="has-color-blue" icon="fa-th-list"></app-tool-button>
  <app-tool-button-permissions [permission]="this.auth.Pages.Matricules"></app-tool-button-permissions>
  </app-toolbar>

<app-matricules-historic-table [selectButton]="this.dataDialog.isDialog && !this.dataDialog.isReadOnly" [updatedFilterData]="filterData" (notify)="onRowSelected($event)"></app-matricules-historic-table>
