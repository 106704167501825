import { AnulacionZonaAzulAuto } from './../../../anulacion-zona-azul-auto/models/anulacion-zona-azul-auto.model';
import { FileDownloader } from 'path-shared/services/http/fileDownloader';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { DenunciesEditorComponent } from './../denuncies-editor/denuncies-editor.component';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { FilterService } from './../../../../shared/services/filter/filter.service';
import { CustomDataSource } from './../../../../shared/services/datasource/custom.datasource';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { Component, OnInit, ViewChild, AfterViewInit, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatCheckbox } from '@angular/material';
import { Tramitador } from '../../models/tramitador.model';
import { Tramitacio } from '../../models/tramitador-tramitacio.model';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { TramitadorFilter } from '../../models/tramitador.filter';
import { TramitadorService } from 'path-denuncies-services/tramitador.service';
import { merge, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService, Toast } from 'ngx-toastr';
import { TramitadorFilterService } from 'path-denuncies-services/tramitador-filer.service';
import { InformacioTramitador } from '../../models/informacio-tramitador.model';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { FormLiveStateType } from 'path-shared/components/form/form-live-state/form-live-state-type.model';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { ReportsComponent } from 'path-shared/components/reports/reports.component';
import { DisplayTextDialogComponent } from 'path-shared/components/dialogs/display-text-dialog/display-text-dialog.component';
import { ParametersService } from 'path-shared/services/parameters/parameters.service';
import { FilesService } from 'path-shared/services/files/files.service';
import { ProgressBarComponent } from 'path-shared/components/progress-bar/progress-bar.component';
import { LegislacioService } from 'app/sections/configuracio/legislacions/services/legislacions-service.service';
import { FormMultiSelect } from 'path-shared/components/form/form-multi-select/model/form-multi-select.model';
import { TramitadorConfigurationComponent } from '../tramitador-configuration/tramitador-configuration.component';
import { TramitadorConfiguracionFiltro } from '../../models/tramitador-configuracion-filtro';
import { FiltersConfigurationService } from 'path-shared/services/FiltersConfiguration/filters-configuration.service';

@Component({
  selector: 'app-tramitador',
  templateUrl: './tramitador.component.html',
  styleUrls: ['./tramitador.component.scss']
})
export class TramitadorComponent implements OnInit, AfterViewInit, OnChanges {
  public Types = FormLiveStateType;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('allCheck') private allCheck: MatCheckbox;
  @ViewChild('progressBar') private progressBar: ProgressBarComponent;

  filterConfiguration = new TramitadorConfiguracionFiltro();
  origenes = [{ 'item_id': 0, 'item_text': this.i18n('Radar') }, { 'item_id': 1, 'item_text': this.i18n('Otros') }]
  matriculas = [{ 'item_id': true, 'item_text': this.i18n('Extranjeras') }, { 'item_id': false, 'item_text': this.i18n('Españolas') }]
  fotos = [{ 'item_id': 0, 'item_text': 'Si'},{ 'item_id': 1, 'item_text': 'No' }]

  dropdownSettings = {
    idField: 'item_id',
    textField: 'item_text',
    itemsShowLimit: 2,
    singleSelection: true
  };

  origenSelected = new Array();
  matriculaSelected = new Array();
  fotoSelected = new Array();

  dataSource: CustomDataSource<Tramitador, TramitadorFilter>;
  highlightedRows = [];
  filter: TramitadorFilter = new TramitadorFilter('data', 'asc');
  filterPage: TramitadorFilter;
  updatedFilterData: TramitadorFilter = new TramitadorFilter('', '');
  displayedItems = [];
  informacioTramitador: InformacioTramitador = new InformacioTramitador();
  reports = [];
  link = false;
  // Emits
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();

  /** Columns displayed in the table.*/
  displayedColumns = ['Check', 'Exp', 'NumDenuncia', 'tipus', 'Fecha', 'Hora', 'Import', 'Matricula', 'Response', 'estat'];
  tiposDenuncias = [];
  legislaciones = [];

  constructor(
    public auth: AuthorizationService,
    private dialog: MatDialog,
    private i18n: I18n,
    private toastr: ToastrService,
    private http: HttpSecureClient,
    private route: ActivatedRoute,
    private confirmationDialogService: ConfirmationDialogService,
    private tramitadorService: TramitadorService,
    private legislacioService: LegislacioService,
    private router: Router,
    private filterService: FilterService,
    private not: NotificationService,
    private parametersService: ParametersService,
    private filesService: FilesService,
    private filtersConfigurationService: FiltersConfigurationService
  ) {

    this.displayedItems['checkedValues'] = 0;

    this.tramitadorService.getReports(this.auth.Pages.Tramitador).subscribe(res => {
      this.reports = res;
    });
    this.tramitadorService.GetTiposDenuncias().subscribe(res => {
      this.tiposDenuncias = res;
    });
    this.legislacioService.getAllForCombo().subscribe(res => {
      this.legislaciones = res.map(s => new FormMultiSelect(s.codi, s.descripcio));
    });

    this.filtersConfigurationService.get<TramitadorConfiguracionFiltro>(this.filtersConfigurationService.Pantallas.TRAMITADOR).then(res=>{
      this.filterConfiguration = res;
    });
    this.getTramitadorConfiguracionFiltro();
  }

  getTramitadorConfiguracionFiltro(){
    this.filtersConfigurationService.get<TramitadorConfiguracionFiltro>(this.filtersConfigurationService.Pantallas.TRAMITADOR).then(res=>{
      this.filterConfiguration = res;
    });
  }

  ngOnInit() {
    this.link = this.parametersService.getParameter('ENLACE_FICHERO_EXPORT') === 'Y';
    this.auth.UserCanSeePage(this.auth.Pages.Tramitador);
    this.not.titleChange(this.i18n('Tramitador'));
    this.paginator.pageSize = 99999;
    this.filterService.loadFilter('tramitador-filter', this.filter);
    this.sort.active = this.filter.paginator.sortField;
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<Tramitador, TramitadorFilter>(this.paginator, new TramitadorFilterService(), 'urlTramitadorDenuncies', 'urlTramitadorCount');
    this.dataSource.subject.subscribe((data) => {
      this.displayedItems = [];
      this.displayedItems['checkedItems'] = new Array();
      this.displayedItems['checkedValues'] = 0;
      data.forEach(item => {
        this.displayedItems[item.Som_Id] = {
          id: item.Som_Id,
          checked: false
        };
      });
    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.loadTramitadorPage();
        this.filterService.saveFilter('tramitador-filter', this.filter);
      }
    });
    this.not.onTramitacioEstatChange.subscribe(value => {
      const item = this.dataSource.get('Som_Id', value.Som_Id);
      if (item !== null) {
        item.estat = value.State;
        item.Response = value.Response;
      }
    });
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(data => {
      this.paginator.pageIndex = 0;
      this.dataSource.sort(this.sort.active, this.sort.direction.toString());
    });

  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      this.loadTramitadorPage();
    }
  }

  toggleAllCheckbox() {
    for (const item in this.displayedItems) {
      if (item !== 'checkedItems' && item !== 'checkedValues') {
        this.toggleItem(item);
      }
    }
  }

  loadTramitadorPage() {
    this.FormatFilter();
    this.progressBar.reset();
    this.allCheck.checked = false;
    this.displayedItems['checkedItems'] = new Array();
    this.displayedItems['checkedValues'] = 0;
    this.dataSource.loadFilter(this.filter);
    this.loadTramitadorInfoPage();
  }

  FormatFilter() {
    //Origen
    if (this.origenSelected.length < 1 || this.origenSelected.length > 1) this.filter.origen = 2; //Todos
    else {
      this.filter.origen = this.origenSelected.map(m => m.item_id)[0];
    }

    //Matriculas
    if (this.matriculaSelected.length < 1 || this.matriculaSelected.length > 1) this.filter.matriculasExtrangeras = null; //Todos
    else {
      this.filter.matriculasExtrangeras = this.matriculaSelected.map(m => m.item_id)[0];
    }

    if (this.fotoSelected !== null) {

    }
  }

  loadTramitadorInfoPage() {
    this.tramitadorService.getTramitadorInfo(this.filter).subscribe(result => {
      this.informacioTramitador = result;
    });
  }

  getCheckedValues() {
    return this.displayedItems['checkedValues'];
  }

  toggleItem(id) {
    if (!this.displayedItems[id].checked) {
      this.displayedItems['checkedItems'].push(parseInt(id, 10));
      this.displayedItems['checkedValues'] = this.getCheckedValues() + 1;
      this.displayedItems[id].checked = true;
    } else {
      const index = this.displayedItems['checkedItems'].indexOf(parseInt(id));
      if (index >= 0) {
        this.displayedItems['checkedItems'].splice(index, 1);
      }

      this.displayedItems['checkedValues'] = this.getCheckedValues() - 1;
      this.displayedItems[id].checked = false;
    }
  }

  selectedItemsLength(): boolean {
    return (this.getCheckedValues()) > 0;
  }

  selectCheckedItems() {
    return this.displayedItems['checkedValues'];
  }

  irHistorico(): void {
    this.router.navigate(['denuncies/tramitador-historic']);
  }
  exportarDenuncias(): void {
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol exportar les denuncies sel·leccionades?'))
      .then((confirmed) => {
        if (confirmed) {
          const tramitacioInfo = new Tramitacio();
          tramitacioInfo.fechaInicio = this.filter.fechaInicio;
          tramitacioInfo.fechaFin = this.filter.fechaFin;
          tramitacioInfo.listDenuncies = this.displayedItems['checkedItems'];
          tramitacioInfo.matriculasExtrangeras = this.filter.matriculasExtrangeras;
          tramitacioInfo.incloureImatges = this.filter.fotos;
          this.toastr.success(this.i18n('Procés d\'exportació iniciat.'));
          this.tramitadorService.tramitarDenuncias(tramitacioInfo).subscribe(result => {
            if (result !== -1) {
              this.toastr.success(this.i18n('Tramitacio realitzada.'));
              if (this.dataSource.getData().findIndex(x => x.estat === 3) !== -1) {
                this.dialog.open(DisplayTextDialogComponent, {
                  width: '25%',
                  closeOnNavigation: false,
                  autoFocus: true,
                  data: { text: this.i18n('S\'han produït errors durant la tramitació. Revisi els resultats.'), label: this.i18n('Atenció') }
                });
              }
              this.tramitadorService.getMidaExportsAntiguos().subscribe(mida => {
                if (mida > 0) {
                  const formatedMida = this.thousands_separators(mida);
                  this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('S\'han trobat fitxers d\'exportació antics la suma dels quals ocupa ') + formatedMida + this.i18n(' bytes. Desitja eliminar-los per alliberar memòria del sistema?'))
                    .then((confirmed2) => {
                      if (confirmed2) {
                        // eliminar ficheros
                        this.tramitadorService.eliminarExportsAntiguos(result).subscribe(res => {
                          this.download(result);
                        });
                      } else {
                        this.download(result);
                      }
                    });
                } else {
                  this.download(result);
                }
              });

            } else {
              this.toastr.error(this.i18n('No es pot generar cap fitxer d\'exportacio'));
            }
            // this.dialog.closeAll();
          });
        }
      });
  }

  thousands_separators(num) {
    const num_parts = num.toString().split('.');
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return num_parts.join('.');
  }

  download(result) {
    if (this.link) {
      this.tramitadorService.GetRutaFitxer(result).subscribe(res => {
        const link = this.filesService.getExportFileVirtualUrl(res);
        if (link !== 'NotFound') {
          this.dialog.open(DisplayTextDialogComponent, {

            width: '25%',
            closeOnNavigation: false,
            autoFocus: true,
            data: { text: link, type: 'link', label: this.i18n('Descarregar fitxer d\'exportació') }
          });
        }

      });

    } else {
      this.tramitadorService.getFicheroExportacion(result as number).subscribe(file => {
        const name: number = Date.now();
        const contentType = file.headers.get('Content-Type');
        if (contentType !== null && contentType !== undefined) {
          FileDownloader.downLoadFile([file.body], contentType + ';charset=utf-8', name.toString());
        }
      });
    }
  }

  veureDenuncia(numDenuncia) {
    const data: ComponentDialog = Builder.newComponentDialog(true, true, numDenuncia);
    const ref = this.dialog.open(DenunciesEditorComponent, {
      width: '99%',
      height: '99%',
      maxHeight: '100vh',
      closeOnNavigation: false,
      autoFocus: true,
      data: data
    });
  }

  openConfiguration() {
    const ref = this.dialog.open(TramitadorConfigurationComponent, {
      data: this.filterConfiguration,
      closeOnNavigation: false,
      autoFocus: true,
    }).afterClosed().subscribe(res=>{
      if (res){
        this.getTramitadorConfiguracionFiltro();
      }
    });
  }

  //#region REPORTS

  impressio() {
    const ref = this.dialog.open(ReportsComponent, {
      width: '30%',
      height: '30%',
      closeOnNavigation: false,
      autoFocus: true,
      data: this.reports
    }).afterClosed().subscribe(report => {
      if (report !== '') {
        const listDenuncies: any[] = this.displayedItems['checkedItems'];
        this.tramitadorService.generateReport(this.filter, listDenuncies, report.id, this.filter.fotos).subscribe(file => {
          const name = report.nom;
          const contentType = file.headers.get('Content-Type');

          if (contentType !== null && contentType !== undefined) {
            FileDownloader.downLoadFile([file.body], contentType + ';charset=utf-8', name.toString());
          } else {
            this.toastr.error(this.i18n('No s\'ha pogut generar el fitxer'));
          }
        });
        
      }
    });
  }

  onItemSelect(item: any) {
    this.fotoSelected = item.item_id
    if (item.item_id == 0) {
      this.filter.fotos = true;
    }
    else if (item.item_id == 1) {
      this.filter.fotos = false;
    }
  }

  //#endregion

}
