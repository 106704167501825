import { SortDirection } from "@angular/material";
import { FilterData } from "path-shared/models/filter-data";

export class ActuacioAgentFilter extends FilterData {
    somId: number;
    agent: string;

    constructor(sortField: string, sortDir: SortDirection) {
        super(sortField, sortDir);
        this.somId = null;
        this.agent = null;
  
      this.labels['somId'] = this.i18n('Id');
      this.labels['agent'] = this.i18n('Agent');
    }
}