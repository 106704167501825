import { DataSource } from '@angular/cdk/table';
import { Component, OnInit, AfterViewInit, ViewChild, Inject, OnChanges, ElementRef } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { Router, ActivatedRoute } from '@angular/router';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { Reflection } from 'path-shared/services/reflection/reflection';
import { MapsAPILoader, LatLngBounds, AgmMap } from '@agm/core';
import { TipusMatriculesService } from 'app/sections/ciutat/tipus-matricules/services/tipus-matricules.service';
import { LoaderService } from 'path-shared/components/loader/loader.service';
import { ShortDatePipe } from 'path-shared/pipes/short-date-pipe';
import { LongDatePipe } from 'path-shared/pipes/long-date-pipe';
import { AgentsService } from 'app/sections/configuracio/agents/services/agents-service.service';
import { AparellService } from 'app/sections/configuracio/aparells/services/aparells-service.service';
import { ParametersService } from 'path-shared/services/parameters/parameters.service';
import { find } from 'rxjs/operators';
import { Parametros } from 'path-shared/models/parametros';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { CFG_PARAMETROS } from 'path-models/CFG_PARAMETROS';
import { BonosFilter } from './models/bonos-filter';
import { Bonos } from './models/bonos.model';
import { BonosTableComponent } from './components/bonos-table.component';
import { BonosService } from './services/bonos.service';

enum TipoBono {
  Normal = 'NORMAL',
  Temporal = 'TEMPORAL',
  Todos = ''
}

@Component({
  selector: 'app-bonos',
  templateUrl: './bonos.component.html',
  styleUrls: ['./bonos.component.scss']
})


export class BonosComponent implements OnInit, AfterViewInit {


  activeFilters: string[] = [];
  filterData: BonosFilter;
  somIdFoo: number;
  dataDialog: ComponentDialog = Builder.newComponentDialog();
  rows: Bonos[];
  /** Tipo de bono en booleano (0=NORMAL, 1=TEMPORAL, NULL=TODOS)*/
  bTipo: boolean;
  selectedRow: Bonos;

  @ViewChild(BonosTableComponent) bonosTable: BonosTableComponent;
  @ViewChild('AgmMap') agmMap: AgmMap;


  constructor(
    private authGuard: AuthGuardService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private router: Router,
    public i18n: I18n,
    private toastr: ToastrService,
    private not: NotificationService,
    public thisDialogRef: MatDialogRef<BonosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
      this.rows = [];
      this.filterData = new BonosFilter('fechaFin', 'desc');
    }

  ngOnInit() {

  }

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Bonos'));
    }
  }


  refresh() {
    this.rows = [];
    this.bonosTable.filter.set(this.filterData);
    this.bonosTable.load();

  }

  generateExcel() {
    this.bonosTable.generateExcel();
  }

  close() {
    this.thisDialogRef.close();
  }
  /**
   * Obtiene el tipo en forma de booleano al detectar cambio de value en el componente tri-state, y asigna en el filtro el correspondiente tipo de bono
   * @param tipo maybe booleano
   */
  GetTipoValueFromTriState(tipo: boolean) {
    switch (tipo) {
      case true:
        this.filterData.tipo = TipoBono.Normal;
        break;
      case false:
        this.filterData.tipo = TipoBono.Temporal;
        break;
      case null: case undefined:
        this.filterData.tipo = TipoBono.Todos;
        break;
    }
  }
}
