<div class="dashboard-showcase">

  <div class="container-fluid">
    <app-actuacionsmatricula-table-component></app-actuacionsmatricula-table-component>

        <div class="row">

            <app-dash-square class="col-lg-3"  *ngIf="this.dash.PropostesEnabled" title="Propostes pendents" i18n-title
              [text]="this.auth.UserCanSeePage(this.auth.Pages.Prepropostes) && !this.auth.IsSuperUser() ? this.dash.Prepropostes : this.dash.PropostesPendents" link="/propostes/1" linkLabel="Veure propostes pendents"
              i18n-linkLabel icon="fa fa-file-powerpoint" iconClass="primary"></app-dash-square>
  
            <app-dash-square class="col-lg-3" *ngIf="this.dash.DenunciesEnabled && this.auth.UserCanSeePage(this.auth.Pages.Tramitador)" title="Tramitador" i18n-title
              text="{{this.dash.DenunciesPendentsTramitar}}" link="/denuncies/tramitador"
              linkLabel="Veure tramitador pendent" i18n-linkLabel icon="fa fa-exclamation-triangle" iconClass="danger">
            </app-dash-square>
            <app-dash-square class="col-lg-3" *ngIf="this.dash.AutomatitzacioEnabled && this.auth.UserCanSeePage(this.auth.Pages.Automatitzacio)" title="Incidencias Automatización" i18n-title
              text="{{this.dash.AutomatitzacioErrors}}" link="/alarmes/automatitzacio"
              linkLabel="" i18n-linkLabel icon="fa fa-magic" iconClass="primary">
            </app-dash-square>
        </div>
        <div class="row" *ngIf="dash.EstatsFitxers.length > 0">

          <h5 *ngIf="dash.EstatsFitxers.length > 0" i18n style="padding-left: 15px;">Dispositius</h5>
        </div>
        <div class="row">
           <!-- <app-dash-square class="col-lg-12" title="Contador duplicados" i18n-title
              text="{{this.dash.ContadoresDuplicados}}" link="/contadoresDuplicados" onclick="openContadoresDuplicados()"
              linkLabel="Veure contadores dupl" i18n-linkLabel icon="fa fa-copy" iconClass="danger">
            </app-dash-square>-->

            <ng-container>
            
             
            
              <ng-container *ngFor="let device of dash.EstatsFitxers">
                <app-dash-input-files-status class="col-lg-3" [label]="'Entrada ' + device.nom" i18n-label
                  [icon]="device.CSSIConoDashboard" [pendingFiles]="device.pendents" [nom]="device.nom" [errorFiles]="device.incidencies" 
                  [contadoresDuplicados]="device.contadoresDuplicados" [pendingFilesImg]="device.pendentsImg">
                </app-dash-input-files-status>
              </ng-container>
            

          </ng-container>

        


      </div>

      <!-- Apartado gráficas -->
      <div class="row">
        
         <div class="col-lg-12 noPadding" *ngIf="this.tramitacionData !== undefined && this.tramitacionData !== null">
           <app-complete-dash-tramitacion [datos]="this.tramitacionData"></app-complete-dash-tramitacion>
         </div>
         
        
        <div class=" col-lg-6 noPadding"   *ngIf="this.dash.ReservasEnabled">
          <div class="card">
            <div class="card-header"  style="padding-left: 0px; padding-right: 0px;" i18n>Reserves</div>
          <div class="row">
            <div class="card-body col-6">
              <canvas baseChart style="max-height: 30vh;"
                [data]="reservasVehiculosChartData"
                [labels]="reservasVehiculosChartLabels"
                [options]="reservasVehiculosChartOptions"
                [chartType]="reservasVehiculosChartType"
                [colors]="reservasVehiculosChartColors">
              </canvas>
            </div>
            <div class="card-body col-6">
              <canvas baseChart style="max-height: 30vh;"
                [data]="reservasPersonasChartData"
                [labels]="reservasPersonasChartLabels"
                [options]="reservasPersonasChartOptions"
                [chartType]="reservasPersonasChartType"
                [colors]="reservasPersonasChartColors">
              </canvas>
            </div>
          </div>
          </div>
          
        </div>

        <!-- Gráfica Propuestas -->
        <div class="col-lg-6 noPadding" *ngIf="this.dash.PropostesEnabled">
          <div class="card">
            <div class="card-header" i18n>Propostes denúncies</div>
            <div class="card-body">
              <canvas baseChart style="max-height: 30vh;" [data]="pieChartData" [labels]="pieChartLabels" [chartType]="pieChartType"
                [options]="pieChartOptions"></canvas>
            </div>
          </div>
        </div>

        <!-- Gráfica Denuncias -->
        <div class="col-lg-6 noPadding" *ngIf="barChartLabels && barChartData">
          <div class="card">
            <div class="card-header" i18n>Denúncies</div>
            <div class="card-body">
              <canvas baseChart style="max-height: 30vh;"
                [datasets]="barChartData"
                [labels]="barChartLabels"
                [options]="barChartOptions"
                [legend]="barChartLegend"
                [chartType]="barChartType">
              </canvas>
            </div>
        </div>
        </div>

         <!-- Gráfica Reservas -->
         <div class="col-lg-6 noPadding" *ngIf="this.dash.GruaEnabled">
          <div class="card">
            <div class="card-header" i18n>Propostes Grua</div>
            <canvas baseChart style="max-height: 30vh;" [data]="propuestasGruaChartData" [labels]="propuestasGruaChartLabels" [chartType]="propuestasGruaChartType"
            [options]="pieChartOptions"></canvas>
          <!--<canvas baseChart style="max-height: 30vh;"
              [datasets]="propuestasGruaChartData"
              [labels]="propuestasGruaChartLabels"
              [options]="propuestasGruaChartOptions"
              [legend]="propuestasGruaChartLegend"
              [chartType]="propuestasGruaChartType">
          </canvas>-->
          </div>
        </div>
      </div>
  </div>
  

  <!-- <div class="container" style="display: flex; flex-direction: column; width: 100%;">
    <div class="input-container" style="padding: 10px; width: 100%; box-sizing: border-box;">
      <app-form-text-edit class="col-xl-2" i18n-label label="Matricula" [(value)]="this.matriculaFilter" (focusout)="matriculaFilterChange($event)"></app-form-text-edit>
    </div>
    <div class="row justify-content-between">
      <div class="col">
        <mat-paginator #paginator
          [pageIndex]="0"
          [pageSize]="this.filter.paginator.pageSize"
          [pageSizeOptions]="[20, 40, 60, 100]">
        </mat-paginator>
      </div>
    </div> -->



