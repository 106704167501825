import { DatePipe } from '@angular/common';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { MatriculesService } from './../../services/matricules.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthorizationService } from './../../../../../shared/services/authorization/authorization-service';
import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Matricula } from 'app/sections/ciutat/matricules/models/matricula.model';
import { TipusMatricula } from '../../../tipus-matricules/models/tipus-matricula.model';
import { MarquesVehicles } from '../../../../configuracio/marques-vehicles/models/marques-vehicles.model';
import { ToastrService } from 'ngx-toastr';
import { TipusMatriculesService } from 'app/sections/ciutat/tipus-matricules/services/tipus-matricules.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { FormService } from 'path-shared/services/form/form.service';
import { Builder } from 'path-shared/models/builder';
import { debug } from 'util';
import { ReportsComponent } from 'path-shared/components/reports/reports.component';
import { FileDownloader } from 'path-shared/services/http/fileDownloader';
import { AddMatricula } from '../../models/addMatricula.model';
import { FormMultiSelect } from 'path-shared/components/form/form-multi-select/model/form-multi-select.model';

@Component({
  selector: 'app-matricules-add',
  templateUrl: './matricules-add.component.html',
  styleUrls: ['./matricules-add.component.scss']
})
export class MatriculesAddComponent implements OnInit {
  secure_id: string;
  matricula: AddMatricula;
  noFinalDate: boolean;
  finalDate: Date;
  tipusMatricules: TipusMatricula[];
  marques: MarquesVehicles [];
  dialogData: ComponentDialog;
  closeButtonText: string;
  reports = [];

  allTipusMatricules = Array<FormMultiSelect>();
  @ViewChild('mainForm') private mainForm: ElementRef;

  constructor(
    private dialog: MatDialog,
    private confirmationDialogService: ConfirmationDialogService,
    public auth: AuthorizationService,
    private matriculesService: MatriculesService,
    private i18n: I18n,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    private tipusMatriculesService: TipusMatriculesService,
    public thisDialogRef: MatDialogRef<MatriculesAddComponent>,
    private formService: FormService,

    @Inject(MAT_DIALOG_DATA) public data,
    ) {
      this.matricula = Builder.newAddMatricula();
      this.noFinalDate = true;
      this.dialogData = data;
      this.marques = data.marques;
      this.tipusMatricules = data.tipusMatricules;
      // this.secure_id = this.dialogData.id;
      if (this.dialogData.isReadOnly) {
        this.closeButtonText = this.i18n('Tancar');
      } else {
        this.closeButtonText = this.i18n('Llista');
      }
      this.secure_id = data.secureId;
      this.matriculesService.getReports(this.auth.Pages.Matricules).subscribe( res => {
        this.reports = res;
      });
    }

  ngOnInit() {
    if (this.secure_id !== null) {
      this.matriculesService.getMatricula(this.secure_id).subscribe(data => {
        this.matricula = data;

        if (this.matricula.dataFi !== null) {
          this.finalDate = new Date(this.matricula.dataFi);
          const year = this.finalDate.getFullYear();
          this.noFinalDate =  year >= 2100;
        }
        this.getAllTipusMatricules();
      });
    } else {
      this.matricula.dataInici = new Date();
      this.matricula.hora = new Date().toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
      this.getAllTipusMatricules();
    }
  }

  models() {
    if (this.matricula.marca !== null) {
      if (this.marques.find(x => x.descripcio === this.matricula.marca) !== undefined) {
        return this.marques.find(x => x.descripcio === this.matricula.marca).modelos;
      }
    }
  }
  save() {

    if (this.formService.allFieldsValid(this.mainForm) && this.matricula.hora !== '') {
      if (this.noFinalDate) {
        this.matricula.dataFi = '4000-12-31';
      }

      this.matriculesService.createMatricula(this.matricula).subscribe(result => {
        this.toastr.info(this.i18n('Matricula guardada correctament'));
        this.thisDialogRef.close(true);
      });
    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
    }
  }

  cancel() {
    this.thisDialogRef.close(false);
  }


  noFinalCheckChangd(event) {
    // if (!event) {
    //   this.matricula.dataFi = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    // }
  }

  getAllTipusMatricules() {

    this.tipusMatriculesService.getAll().subscribe(result => {
      this.allTipusMatricules = [];
      result.forEach(element => {

        if (this.matricula.tiposMatriculas !== undefined && this.matricula.tiposMatriculas !== null && this.matricula.tiposMatriculas.find(x => x.item_id === element.codi.toString())) {
          this.allTipusMatricules.unshift(new FormMultiSelect(element.codi.toString(), element.descripcio));
        } else {
          this.allTipusMatricules.push(new FormMultiSelect(element.codi.toString(), element.descripcio));
        }
      });
    });
  }
}

