import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'transformData'
})
export class TransformDataPipe extends DatePipe  implements PipeTransform {

  transform(value: any, args?: any): any {
    let data = value.toString();
    return new Date(parseInt(data.substr(0, 4)), parseInt(data.substr(4, 2)) - 1, parseInt(data.substr(6, 2)))
  }

}
