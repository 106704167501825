import { Component, OnInit, AfterViewInit } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { NotificationService } from 'path-shared/services/notification/notification.service';

@Component({
  selector: 'app-tramitar-expedientspage',
  templateUrl: './tramitar-expedientspage.component.html',
  styleUrls: ['./tramitar-expedientspage.component.scss']
})
export class TramitarExpedientspageComponent implements OnInit, AfterViewInit {

  constructor(
    private i18n: I18n,
    private not: NotificationService,
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.not.titleChange(this.i18n('Tramitar'));
  }

}
