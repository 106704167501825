import { FilterService } from './../../../shared/services/filter/filter.service';
import { Injectable } from '@angular/core';
import { config } from 'path-shared/configuration/config';
import { TramitadorFilterService } from './tramitador-filer.service';
import { Observable } from 'rxjs';
import { TramitadorFilter } from '../models/tramitador.filter';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { api } from 'path-shared/configuration/urls';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { IFilterService } from 'path-shared/services/datasource/filter-service';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { Tramitacio } from '../models/tramitador-tramitacio.model';
import { TramitadorReport } from '../models/tramitador-report.model';
import { QueryReadType } from '@angular/core/src/render3/interfaces/query';

const defaultSortParam = '';
const defaultSortDirection = '';


@Injectable()
export class TramitadorService {
  

  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {
  }

  getTramitadorInfo(filter: TramitadorFilter): Observable<any> {
    if (this.authGuard.canActivate()) {
      const params = new TramitadorFilterService().createParamsFromFilter(filter);
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlTramitadorInfo'], params);
    }
  }

  tramitarDenuncias<T>(tramitacioInfo: Tramitacio): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlTramitadorTramitar'], tramitacioInfo);
    }
  }

  getFicheroExportacion(historicoId: number): Observable<HttpResponse<Blob>> {
    if (this.authGuard.canActivate()) {
      return this.http.getFileResponse(config.urls['apiUrl'] + api.endpoints['urlTramitadorGetExportacion'].replace('{idHistorico}', historicoId.toString()), null);
    }
  }

  GetRutaFitxer(historicoId: number): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlTramitadorGetRutaFichero'].replace('{idHistorico}', historicoId.toString()), null);
    }
  }

  GetTiposDenuncias(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlTramitadorGetTiposDenuncia'], null);
    }
  }

  getMidaExportsAntiguos(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlTramitadorGetMidaExportsAntiguos'], null);
    }
  }
  eliminarExportsAntiguos(id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlTramitadorEliminarExportsAntiguos'].replace('{id}', id), null);
    }
  }
  //#region REPORTS

  getReports(page: string): Observable<any> {
    if (this.authGuard.canActivate()) {
      page = page.replace('.', 'xXx');
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlReports'].replace('{page}', page), null);
    }   
  }

  generateReport(filter: TramitadorFilter, listDenuncies: any[], reportId: string, incloureImatges: boolean): Observable<any> {
    const reportInfo = new TramitadorReport();
    reportInfo.reportId = reportId;
    reportInfo.fechaInicio = filter.fechaInicio;
    reportInfo.fechaFin = filter.fechaFin;
    reportInfo.listDenuncies = listDenuncies;
    reportInfo.incloureImatges = incloureImatges;

    if (this.authGuard.canActivate()) {
      return this.http.postFileResponse(config.urls['apiUrl'] + api.endpoints['urlGenerateReportDenuncias'], reportInfo, new HttpParams());
    }
  }

  //#endregion
}
