import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class ImportacioDenunciaFilter extends FilterData {
  idImportacio: string;
  descImportacio: string;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.idImportacio = '';
    this.descImportacio = '';
  }
}
