export class NotificarInicioRequestDto {
    public useC60Double: boolean;
    public primeraDenuncia: boolean;

    constructor(
        useC60Double: boolean,
        primeraDenuncia: boolean
    ) {
        this.useC60Double = useC60Double;
        this.primeraDenuncia = primeraDenuncia;
    }

}
