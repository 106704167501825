import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class PersonaFilter extends FilterData {
  id: string;
  nomComplet: string;
  dni_pasp: string;
  nom_pare: string;
  nom_mare: string;
  data_naixament: Date;
  lloc_naixament: string;
  provincia: string;
  nacionalitat: string;
  telefon1: string;
  telefon2: string;
  buscat: boolean;
  antecedents: boolean;
  juridica: boolean;
  id_extern: string;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.id = '';
    this.nomComplet = '';
    this.dni_pasp = '';
    this.nom_pare = '';
    this.nom_mare = '';
    this.data_naixament = null;
    this.lloc_naixament = '';
    this.provincia = '';
    this.nacionalitat = '';
    this.telefon1 = '';
    this.telefon2 = '';
    this.buscat = null;
    this.antecedents = null;
    this.juridica = null;
    this.id_extern = '';


    this.labels['id'] = this.i18n('id');
    this.labels['nomComplet'] = this.i18n('Nom complet');
    this.labels['dni_pasp'] = this.i18n('DNI/PASP');
    this.labels['nom_pare'] = this.i18n('Nom pare');
    this.labels['nom_mare'] = this.i18n('Nom mare');
    this.labels['data_naixament'] = this.i18n('Data naixament');
    this.labels['lloc_naix'] = this.i18n('Lloc naixament');
    this.labels['provincia'] = this.i18n('Provincia');
    this.labels['nacionalitat'] = this.i18n('Nacionalitat');
    this.labels['telefon1'] = this.i18n('Telefono');
    this.labels['telefon2'] = this.i18n('Telefon 2');
    this.labels['buscado'] = this.i18n('Buscat');
    this.labels['antecedentes'] = this.i18n('Antecedents');
    this.labels['juridica'] = this.i18n('Juridica');
    this.labels['id_extern'] = this.i18n('Id extern');

  }
}
