import { Component, OnInit, Input, ViewChild, Inject, ChangeDetectorRef } from '@angular/core';
import { FitxerModel } from './fitxer.model';
import { DragScrollComponent } from 'ngx-drag-scroll/lib';
import { ComponentDialog } from '../../models/component-dialog.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource } from '@angular/material';
import { FileBoxService } from './file-box-service';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { FileViewerComponent } from './file-viewer/file-viewer.component';
import { SelectionModel } from '@angular/cdk/collections';
import { saveAs } from 'file-saver';

declare var require: any;

@Component({
  selector: 'app-file-box',
  templateUrl: './file-box.component.html',
  styleUrls: ['./file-box.component.scss']
})
export class FileBoxComponent implements OnInit {
  llistaFitxers: FitxerModel[];
  arrayImages:  Observable<string[]>;
  imatgeActual: any;
  pdfActual: any;
  somid: number;
  imageNames: string[] = [];
  images: FitxerModel[];
  numDenuncia: string;
  dict: { [som_id: number]: string; } = { };
  newDict: { [som_id: number]: any; } = { };
  reader: FileReader;
  contenedor: string;
  dataSource;
  selection = new SelectionModel<FitxerModel>(true, []);


  @Input() contenidor: string;
  @Input() height: string;

  @ViewChild('dragScroll', {read: DragScrollComponent}) ds: DragScrollComponent;

  leftNavDisabled = false;
  rightNavDisabled = false;
  dialogData: ComponentDialog;

  displayedColumns = ['CheckBox', 'NOMBRE_FICHERO'];
  viewerDialog: MatDialogRef<FileViewerComponent>;
  constructor(
    private fileService: FileBoxService,
    private domSanitizer: DomSanitizer,
    private changeDetectorRefs: ChangeDetectorRef,
    private confirmationDialogService: ConfirmationDialogService,
    private i18n: I18n,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
      this.dialogData = data;
      this.llistaFitxers = this.data.llistaFitxers;
      this.dataSource = new MatTableDataSource(this.llistaFitxers);
      this.contenedor = this.data.contenedor;
  }

  ngOnInit() {
    this.getImages();
    this.dataSource.data  =  this.llistaFitxers;
  }

  getImages() {

    if ( this.llistaFitxers != null && this.llistaFitxers.length > 0 ) {
      for ( let i = 0; i < this.llistaFitxers.length; i++ ) {
        const exts = this.getExtension(i); // this.llistaFitxers[i].NOMBRE_FICHERO.substr(this.llistaFitxers[i].NOMBRE_FICHERO.lastIndexOf('.') + 1).toLowerCase();
        if ( exts === 'jpg' || exts === 'jpeg' || exts === 'png') {
          const image = this.fileService.getImage(this.llistaFitxers[i].SOM_ID, 'L').subscribe(
            detailsdata => {
                      this.dict[this.llistaFitxers[i].SOM_ID] = this.buildImage(detailsdata);
                      this.newDict[this.llistaFitxers[i].SOM_ID] = detailsdata;
              if ( this.imatgeActual === undefined && this.llistaFitxers != null && this.llistaFitxers[0] != null && i === 0 ) {
                this.imatgeActual = this.dict[this.llistaFitxers[0].SOM_ID];
                this.somid =  this.llistaFitxers[0].SOM_ID;
              }
            });
        } else {
          const image = this.fileService.getPdf(this.llistaFitxers[i].SOM_ID).subscribe(
            detailsdata => {
              this.dict[this.llistaFitxers[i].SOM_ID] = this.buildPdf( detailsdata );
              this.newDict[this.llistaFitxers[i].SOM_ID] = detailsdata;
            });
        }
      }
    }
  }
getPdfUrl() {
  return this.pdfActual;
}
onRowClicked(row) {
  const idAct = this.llistaFitxers.findIndex(g => g.SOM_ID === row.SOM_ID);
  const exts = this.getExtension(idAct); // this.llistaFitxers[idAct].NOMBRE_FICHERO.substr(this.llistaFitxers[idAct].NOMBRE_FICHERO.lastIndexOf('.') + 1).toLowerCase();
  if ( exts === 'jpg' || exts === 'jpeg' || exts === 'png') {
    this.mostrarImatgeNew(idAct);
   } else {
    this.mostrarPdfNew(idAct);
  }
}

  moveLeft() {
   const idAct = this.llistaFitxers.findIndex(g => g.SOM_ID === this.somid);
   const exts = this.getExtension(idAct - 1); // this.llistaFitxers[idAct - 1].NOMBRE_FICHERO.substr(this.llistaFitxers[idAct - 1].NOMBRE_FICHERO.lastIndexOf('.') + 1).toLowerCase();
   if ( exts === 'jpg' || exts === 'jpeg' || exts === 'png') {
      this.mostrarImatgeNew(idAct - 1);
   } else {
    this.mostrarPdfNew(idAct - 1);
   }
  }

  moveRight() {
    const idAct = this.llistaFitxers.findIndex(g => g.SOM_ID === this.somid);
    const exts = this.getExtension( idAct + 1); // this.llistaFitxers[idAct + 1].NOMBRE_FICHERO.substr(this.llistaFitxers[idAct + 1].NOMBRE_FICHERO.lastIndexOf('.') + 1).toLowerCase();
    if ( exts === 'jpg' || exts === 'jpeg' || exts === 'png') {
      this.mostrarImatgeNew(idAct + 1);
     } else {
      this.mostrarPdfNew(idAct + 1);
    }
  }

  leftBoundStat(reachesLeftBound: boolean) {
    this.leftNavDisabled = reachesLeftBound;
  }

  rightBoundStat(reachesRightBound: boolean) {
    this.rightNavDisabled = reachesRightBound;
  }

  getScrollStyle() {
    const styles = {
      'min-height': this.height ? (this.height + 'px') : '300px'
    };
    return styles;
  }

  uploadFile(file) {
    const reader = new FileReader();
    const fitxer: File = file;
    return this.fileService.setFile(config.urls['apiUrl'] + api.endpoints['urlUpladFileAgent'], this.contenedor, file, fitxer.name).subscribe(data => {
      const  nouFitxer = data;
      if (this.llistaFitxers === null) {
        this.llistaFitxers = new Array<FitxerModel>();
      }
      this.llistaFitxers.push(nouFitxer);
      this.dataSource.data  =  this.llistaFitxers;
      const exts = nouFitxer.NOMBRE_FICHERO.substr(nouFitxer.NOMBRE_FICHERO.lastIndexOf('.') + 1).toLowerCase();
        if ( exts === 'jpg' || exts === 'jpeg' || exts === 'png') {
          const image = this.fileService.getImage(nouFitxer.SOM_ID, 'L').subscribe(
            detailsdata => {
              this.dict[nouFitxer.SOM_ID] = this.buildImage(detailsdata);
            });
        } else {
          const image = this.fileService.getPdf(nouFitxer.SOM_ID).subscribe(
            detailsdata => {
              this.dict[nouFitxer.SOM_ID] = this.buildPdf(detailsdata);
            });
        }
    });
  }

  fileReady(file) {
    const files = file;
    let i = 0;
    i++;
  }
  eliminarFitxers() {
    if (this.selection.selected.length !== null && this.selection.selected.length !== undefined && this.selection.selected.length > 0 ) {
      this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol esborrar el fitxers seleccionats ?'))
      .then((confirmed) => {
        if (confirmed) {
          this.selection.selected.forEach(element => {
            this.remove( element.SOM_ID);
          });
          this.selection.clear();
        }
      });
    }
  }
  remove(id: number) {
    const idAct = this.llistaFitxers.findIndex(g => g.SOM_ID === id);
    const fitxer = this.llistaFitxers[idAct];
          this.fileService.removeFile(fitxer.SECURE_ID).subscribe(data => {
            this.llistaFitxers.splice(idAct, 1);
            this.dataSource.data  =  this.llistaFitxers;
            if ( this.llistaFitxers.length > 0 ) {
              const exts = this.getExtension(idAct - 1); // this.llistaFitxers[idAct - 1].NOMBRE_FICHERO.substr(this.llistaFitxers[idAct - 1].NOMBRE_FICHERO.lastIndexOf('.') + 1);
              if ( exts === 'jpg' || exts === 'jpeg' || exts === 'png') {
                this.mostrarImatgeNew(idAct - 1);
              } else {
                this.mostrarPdfNew(idAct - 1);
              }
            } else {
              this.pdfActual = null;
              this.somid = null;
            }
          });
  }

  buildImage(detailsdata: any): any {
    return 'data:image/png;base64,' + btoa(new Uint8Array(detailsdata).reduce(function (datas, byte) {
      return datas + String.fromCharCode(byte);
    }, '')) ;
  }
  buildPdf(detailsdata: any): any {
    return 'data:image/pdf;base64,' + btoa(new Uint8Array(detailsdata).reduce(function (data, byte) {
      return data + String.fromCharCode(byte);
      }, '')) ;
  }

  getExtension(n: number): string {
    return this.llistaFitxers[n].NOMBRE_FICHERO.substr(this.llistaFitxers[n].NOMBRE_FICHERO.lastIndexOf('.') + 1).toLowerCase();
  }
  mostrarImatgeNew(n: number) {
    this.imatgeActual = this.dict[this.llistaFitxers[n].SOM_ID];
    this.somid =  this.llistaFitxers[n].SOM_ID;
    this.pdfActual = null;
    document.getElementById('pdfViewer').setAttribute('style', 'height: 100%; align-content: center; display: none;');
    document.getElementById('imatgeViewer').setAttribute('style', 'height: 100%; align-content: center;  display: inline;');
  }

  mostrarPdfNew(n: number) {
    this.pdfActual = this.dict[this.llistaFitxers[n].SOM_ID];
    this.somid =  this.llistaFitxers[n].SOM_ID;
    this.imatgeActual = null;
    document.getElementById('imatgeViewer').setAttribute('style', 'height: 100%; align-content: center; display: none;');
    document.getElementById('pdfViewer').setAttribute('style', ' overflow-y: scroll; align-content: center; height: 500px');
  }

  showImage() {
    if ( this.imatgeActual != null ) {
      const idAct = this.llistaFitxers.findIndex(g => g.SOM_ID === this.somid);
       const fitxer = this.llistaFitxers[idAct];
      this.viewerDialog = this.dialog.open(FileViewerComponent,
        {
          closeOnNavigation: false,
          autoFocus: true,
          maxWidth: '90%',
          data: { SomId: this.somid, NomFitxer: fitxer.NOMBRE_FICHERO }
        });
      }
    }

    // checkbox
    isAllSelected() {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }

     // checkbox
    masterToggle() {
      this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
    }

    download() {
      if (this.selection.selected.length !== null && this.selection.selected.length !== undefined && this.selection.selected.length > 0 ) {
        if (this.selection.selected.length === 1) {
          this.selection.selected.forEach(element => {
            const fitxer = this.newDict[element.SOM_ID];

            const byteArray = new Uint8Array(fitxer);
            const blob = new Blob([byteArray]);
                saveAs( blob , element.NOMBRE_FICHERO);
          });
        } else {
          const fecha = new Date();
          // alert("Día: "++"\nMes: "+()+"\nAño: "+fecha.getFullYear());
          let NomFitxer = this.i18n('Fitxers_') + ( fecha.getFullYear() + (fecha.getMonth() + 1).toString() + fecha.getDate().toString());
          NomFitxer += '_' + ( fecha.getHours().toString() + fecha.getMinutes().toString() + fecha.getSeconds().toString()  ) + '.zip';
          const JSZip = require( 'jszip' );
          const zip = new JSZip();
          this.selection.selected.forEach(element => {
            const idAct = this.llistaFitxers.findIndex(g => g.SOM_ID === element.SOM_ID);
            const str = this.newDict[element.SOM_ID];
            zip.file(element.NOMBRE_FICHERO, str, {binary: true});
          });
          zip.generateAsync({type: 'blob' })
          .then(function(content) {
              saveAs(content, NomFitxer);
          });
        }
      }
    }
}
