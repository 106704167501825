import { Injectable } from '@angular/core';
import { StartupService } from '../startup/startup.service';
import {Parametros } from '../../models/parametros';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { config } from 'path-shared/configuration/config';
import { AuthGuardService } from '../authentication/auth-guard.service';
import { api } from 'path-shared/configuration/urls';
import { CFG_PARAMETROS } from 'path-models/CFG_PARAMETROS';
import { ParameterDto } from './model/ParametroDto';
import { Reflection } from '../reflection/reflection';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ParametersService {


  constructor(
    private startupService: StartupService,
    private http: HttpSecureClient,
    private authGuard: AuthGuardService
    ) { }

    getParameter(name: string): string {
      let value = '';
      const param  = this.startupService.GetParametres().find(x => x.PARAMETRO === name);
      if (param !== undefined) {
        value = param.VALOR;
      }

      return value;
    }

    // Este parametro está a parte porque no se requiere authorization!!
    getDiasCaducidadPassword(idInstit): Observable<any> {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetDiasCaducidadPassword'].replace('{idInstit}', idInstit), null);

    }

    // Este parametro está a parte porque no se requiere authorization!!
    getForzarContraseñaSuperSegura(idInstit): Observable<any> {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetForzarContraseñaSuperSegura'].replace('{idInstit}', idInstit), null);

    }

    getPDAs(): Observable<any> {
      if (this.authGuard.canActivate()) {
        return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetPDAs'], null);
      }
    }

    setParameter(parametroActualizado: ParameterDto) {

      const json = sessionStorage.getItem('parameters');
      if (!Reflection.empty(json)) {
        const values: CFG_PARAMETROS[] = JSON.parse(json.toString());
        values.find(g => g.PARAMETRO === parametroActualizado.PARAMETRO).VALOR = parametroActualizado.VALOR;
        const jsonFinal = JSON.stringify(values);
        sessionStorage.setItem('parameters', jsonFinal);
      }
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetParametro'], parametroActualizado);
    }
}
