import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor() { }

  public isEmpty(val): boolean  {
    return (val === undefined || val === null || val.toString().length === 0);
  }

  public isNatural(val: string): boolean  {
    if (this.isEmpty(val)) {
      return false;
    } else {
      const regex = new RegExp(/^[0-9]+$/);
      return regex.test(val);
    }
  }

  public isInteger(val: string): boolean  {
    if (this.isEmpty(val)) {
      return false;
    } else {
      const regex = new RegExp(/^[-]{0,1}[0-9]+$/);
      return regex.test(val);
    }
  }

  public isDecimal(val: string): boolean  {
    if (this.isEmpty(val)) {
      return false;
    } else {
      const regex = new RegExp(/^[-]{0,1}[0-9]+[\.][0-9]*$/);
      return regex.test(val);
    }
  }

  public isNumeric(val: string): boolean  {
    return this.isInteger(val) || this.isDecimal(val);
  }

  public checkLength(val: string, maxLength: number): boolean  {
    if (this.isEmpty(val)) {
      return true;
    } else {
      return val.toString().length <= maxLength;
    }
  }

  public isBoolean(val): boolean {
    if (val === undefined || val === null) {
      return false;
    } else {
      const regex = new RegExp(/^(true)|(false)$/);
      return regex.test(val);
    }
  }

  public isMatricula(val): boolean {
    if (val === undefined || val === null) {
      return false;
    } else {
      const regex = new RegExp(/^[a-zA-Z0-9]*$/i);
      return regex.test(val);
    }
  }
}
