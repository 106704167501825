import { Observable, Subscription } from 'rxjs';
import { AlarmGroup } from 'path-shared/models/alarm-group';
import { UserPermissionsByPage } from './../../../../shared/models/userPermissionsByPage';
import { Usuari } from './../../../usuaris/models/usuari.model';
import { Component, OnInit, Output, EventEmitter, Inject, OnDestroy } from '@angular/core';
import { AlarmaFilter } from '../../models/alarma-filter';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Builder } from 'path-shared/models/builder';

@Component({
  selector: 'app-alarmes-filter',
  templateUrl: './alarmes-filter.component.html',
  styleUrls: ['./alarmes-filter.component.scss']
})
export class AlarmesFilterComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  filter: AlarmaFilter;
  alarmes = [];
  users: Usuari[];
  tipus: AlarmGroup[];

  @Output() notify: EventEmitter<AlarmaFilter> = new EventEmitter<AlarmaFilter>();

  constructor(
    public thisDialogRef: MatDialogRef<AlarmesFilterComponent>,
    private i18n: I18n,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    this.alarmes = [];
    this.alarmes.push({'codi': '', 'nom': ''});
  }

  ngOnInit() {
    this.users = [];
    this.users.push(Builder.newUsuari());
    this.data.users.forEach(user => {
      this.users.push(user);
    });
    this.tipus = [];
    this.tipus.push(Builder.newTipusAlarma());
    this.data.tipus.forEach(tip => {
      this.tipus.push(tip);
    });
    this.filter = this.data.filter;

    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.apply();
      }
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated();
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }

}
