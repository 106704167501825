<app-toolbar>
  <app-tool-button text="Consultar" i18n-text (click)="this.validaTicket(); "color="has-color-blue" icon="fa-sync" [disabled]="this.isFormularioLleno()">
  </app-tool-button>
  <app-tool-button-permissions [permission]="this.auth.Pages.ComprovacioTiquets"></app-tool-button-permissions>
  <app-tool-button text="Excel" i18n-text (click)="generateExcel();" color="has-color-darkgreen" icon="fa-file-excel"
    [disabled]="this.ELEMENT_DATA === undefined || this.ELEMENT_DATA === null || this.ELEMENT_DATA.length===0">
  </app-tool-button>
</app-toolbar>


<div class="row example-container">
  <!-- Formulario para datos de consulta -->
  <div class="col-12">
    <div class="row">
      <app-form-text-edit id="comrpovacio-tiquets-matricula" autofocus class="col-2" i18n-label label="Matrícula" [(value)]="filter.matricula" (click)="this.resetNumDenuncia()" (valueChange)="this.changedValues()"></app-form-text-edit>
      <app-form-date-edit id="comrpovacio-tiquets-data" class="col-2" i18n-label label="Data" [(value)]="filter.data" (click)="this.resetNumDenuncia()" (valueChange)="this.changedValues()"></app-form-date-edit>
      <app-form-text-edit id="comrpovacio-tiquets-numDenuncia" class="col-2" i18n-label label="Número denúncia" [(value)]="filter.numDenuncia"  (valueChange)="this.changedValues()" (click)="this.resetMatriculaData()" ></app-form-text-edit>
    </div>
  </div>

  <!-- Espacio destinado a mostrar el resultado -->
  <div class="col-12" >

    <!-- Mensaje de error si no se encuentran tickets. -->
    <div *ngIf="this.sinTickets() && !this.isFormularioLleno() && this.showResult">
      <div class="empty-case">
        <span class="empty-case__icon"><i class="fa fa-search"></i></span>
        <h4 class="empty-case__title" i18n>No hem trobat tickets!</h4>
        <p i18n>
          Sembla ser, que no hi ha cap ticket per la matrícula {{filter.matricula}} i la data  
          {{filter.data | date: 'dd/MM/yyyy' }}. Si us plau, torna a intentar-ho més tard.
        </p>
      </div>
    </div>  
  
    <!-- Tabla con los tickets por zona -->  
    <div class="col-12">
      <mat-table #table *ngIf="!this.sinTickets() && !this.isFormularioLleno() && this.ELEMENT_DATA !== undefined && 
                            this.ELEMENT_DATA !== null && this.ELEMENT_DATA.length > 0 && this.showResult" 
                            [dataSource]="this.ELEMENT_DATA" matSort aria-label="Elements" 
                            style="max-height: 500;"  matSort (matSortChange)="this.sortData($event)">

        <ng-container matColumnDef="ticketId">
          <th mat-header-cell *matHeaderCellDef i18n > Tiquet ID </th>
          <td mat-cell *matCellDef="let element"> {{element.ticketId}} </td>
        </ng-container>

        <ng-container matColumnDef="zonaDesc">
          <th mat-header-cell *matHeaderCellDef i18n> Zona </th>
          <td mat-cell *matCellDef="let element"> {{ element.zonaDescripcion }} </td>
        </ng-container>

        <ng-container matColumnDef="matricula">
          <th mat-header-cell *matHeaderCellDef i18n> Matricula </th>
          <td mat-cell *matCellDef="let element"> {{ element.matricula }} </td>
        </ng-container>
      
        <ng-container matColumnDef="minutosRestantes">
          <th mat-header-cell *matHeaderCellDef i18n> Minuts restants </th>
          <td mat-cell *matCellDef="let element"> {{element.minutosRestantes}} </td>
        </ng-container>
      
        <ng-container matColumnDef="tiquets">
          <th mat-header-cell *matHeaderCellDef i18n> Tiquets </th>
          <td mat-cell *matCellDef="let element">
            <mat-table *ngIf="element.listaTickets.length>0" #table2 [dataSource]="getdata(element.listaTickets)">
              <ng-container matColumnDef="minutosPagados">
                <th mat-header-cell *matHeaderCellDef  i18n> Minuts pagats </th>
                <td mat-cell *matCellDef="let element"> {{element.minutosPagados}} </td>
              </ng-container>
      
              <ng-container matColumnDef="cantidadPago">
                <th mat-header-cell *matHeaderCellDef i18n> Quantitat pagada </th>
                <td mat-cell *matCellDef="let element"> {{element.cantidadPago.replace(',','.') | currency:'EUR': 'symbol' }} </td>
              </ng-container>
      
              <ng-container matColumnDef="tiempoInicio">
                <th mat-header-cell *matHeaderCellDef i18n> Inici </th>
                <td mat-cell *matCellDef="let element"> {{element.tiempoInicio}} </td>
              </ng-container>
      
              <ng-container matColumnDef="tiempoFin">
                <th mat-header-cell *matHeaderCellDef i18n> Fi </th>
                <td mat-cell *matCellDef="let element" >
                  <span [ngClass]="element.minutosRestantes>0 ? 'onTime' : 'caducado'"> {{element.tiempoFin}}</span>
                </td>
              </ng-container>
      
              <ng-container matColumnDef="fabricanteTicket">
                <th mat-header-cell *matHeaderCellDef i18n> Fabricant </th>
                <td mat-cell *matCellDef="let element"> {{element.fabricanteTicket}} </td>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns2"></mat-header-row>
              <mat-row *matRowDef="let row2; columns: displayedColumns2;"></mat-row>
            </mat-table>
          </td>
        </ng-container>

        <!--<ng-container matColumnDef="idTarifa">
          <th mat-header-cell *matHeaderCellDef> idTarifa </th>
          <td mat-cell *matCellDef="let element"> {{element.idTarifa}} </td>
        </ng-container>-->

        <!--<ng-container matColumnDef="descripcionTarifa">
          <th mat-header-cell *matHeaderCellDef i18n> descripcionTarifa </th>
          <td mat-cell *matCellDef="let element"> {{element.descripcionTarifa}} </td>
        </ng-container>-->

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <!-- Group header -->
        <ng-container matColumnDef="groupHeader">
          <td colspan="999" mat-cell *matCellDef="let groupBy"><strong>Tiquet {{ groupBy.ticketId }}</strong></td>
        </ng-container>

        <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup"> </tr>
      </mat-table>
    </div>
  </div>
</div>