<app-dialog-header label="{{ this.i18n('Alegación') + '&nbsp;' + this.alegacio.EXP }}" i18n-label iconClass="has-color-orange"></app-dialog-header>

<app-toolbar>
  <app-tool-button text="Estimar" i18n-text (click)="this.guardar(true)" color="has-color-green" icon="fa-save"></app-tool-button>
  <app-tool-button text="Desestimar" i18n-text (click)="this.guardar(false)" color="has-color-red" icon="fa-save"></app-tool-button>
  <app-tool-button text="Alegación" i18n-text (click)="this.getFilePath()" color="has-color-blue" icon="fa-file-pdf" isFile="true"></app-tool-button>
</app-toolbar>

<div>
    <app-view-list-icon (listClick)="this.buscarAlegacions()" class="col-xl-6" hideView="true"></app-view-list-icon>
    <app-form-text-area required class="col form-text-area" i18n-label label="Resposta" [(value)]="this.situacio" resize="true" maxLength="5000" draggable="false"></app-form-text-area>
</div>