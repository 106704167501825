import { Component, OnInit, Input } from '@angular/core';
import { PermissionDialogComponent } from 'path-shared/components/permission-dialog/permission-dialog.component';
import { MatDialog } from '@angular/material';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';

@Component({
  selector: 'app-tool-button-permissions',
  templateUrl: './tool-button-permissions.component.html',
  styleUrls: ['./tool-button-permissions.component.scss']
})
export class ToolButtonPermissionsComponent implements OnInit {

  @Input() permission: string;
  constructor(
    public auth: AuthorizationService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
  }

  onPermissionClick() {
    const ref = this.dialog.open(PermissionDialogComponent,
      {
        width: '50%',
        minHeight: '70%',
        closeOnNavigation: false,
        autoFocus: true,
        data: this.permission,
      });
  }

}
