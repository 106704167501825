<div class="row justify-content-between">
  <div class="col">
      <app-filter-selector [filter]="this.filter"></app-filter-selector>
  </div>
  <div class="col">
      <!--<mat-paginator #paginator [pageIndex]="0" [pageSize]="this.filter.paginator.pageSize" [pageSizeOptions]="[20, 40, 60, 100]">
      </mat-paginator>-->
      <mat-paginator #paginator
      [pageIndex]="0"
      [pageSize]="this.filter.paginator.pageSize"
      [pageSizeOptions]="[20, 40, 60, 100]">
    </mat-paginator>
  </div>
</div>

<div class="table-sdw is-fixed">

  <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.filter.paginator.sortField" [matSortDirection]="this.filter.paginator.sortDir" class="is-header-sticky">

      <ng-container matColumnDef="incorpora" *ngIf="this.selectButton">
          <mat-header-cell *matHeaderCellDef title="Incorporar" i18n-title class="text-center"><i class="fa fa-plus-square has-color-grey"></i></mat-header-cell>
          <mat-cell *matCellDef="let row" class="text-center"><a (click)="this.selectCurrent(row.id, row.secure_id, row.nomComplet, row.dni_pasp, row.SECURE_ID_DOMICILI_ACTUAL, row.juridica)" title="Incorporar" i18n-title><i class="fa fa-plus-square has-color-aqua"></i></a></mat-cell>
      </ng-container>

      <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>ID</mat-header-cell>
          <mat-cell *matCellDef="let row"><a (click)="onRowClicked(row)" class="is-link cursor detalle">{{row.id}}</a></mat-cell>
      </ng-container>

      <ng-container matColumnDef="nomComplet">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Nom complet</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.nomComplet}}</mat-cell>
      </ng-container>

      <!--<ng-container matColumnDef="nomComplert">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Nom complet</mat-header-cell>
          <mat-cell *matCellDef="let row"><a (click)="onRowClicked(row)" class="is-link">{{row.nomComplert}}</a></mat-cell>
      </ng-container>-->

      <ng-container matColumnDef="dni_pasp">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>DNI/PASP</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.dni_pasp}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="nom_pare">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Nom pare</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.nom_pare}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="nom_mare">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Nom mare</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.nom_mare}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="data_naixament">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data naixament</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.data_naixament}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="lloc_naixament">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Lloc naixament</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.lloc_naixament}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="provincia">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Provincia</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.provincia}}</mat-cell>
    </ng-container>
      
      <ng-container matColumnDef="buscat">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Buscat</mat-header-cell>
          <mat-cell *matCellDef="let row">
              <mat-checkbox [checked]="row.buscat" [disabled]='true'></mat-checkbox>
          </mat-cell>
      </ng-container>
      <ng-container matColumnDef="antecedents">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Antecedents</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <mat-checkbox [checked]="row.antecedents" [disabled]='true'></mat-checkbox>
        </mat-cell>
    </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
  </mat-table>
</div> 