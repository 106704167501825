import { Component, OnInit, ViewChild, Input, SimpleChanges, AfterViewInit, OnChanges } from '@angular/core';
import { MatSort, MatPaginator, MatDialog, MatDialogRef } from '@angular/material';
import { DipositGruaFilter } from '../../Models/diposit-filter';
import { VehicleDipositDto } from '../../Models/diposit-vehicle.model';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { DipositGruaService } from '../../services/diposit-grua-service';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DipositEditorComponent } from '../diposit-editor/diposit-editor.component';
import { DipositGruaFilterService } from '../../services/diposit-grua-filter-service';
import { DenunciesService } from 'path-denuncies-services/denuncies.service';
import { DenunciesImagesComponent } from 'app/sections/denuncies/components/denuncies-editor/components/denuncies-images/denuncies-images.component';
import { PhotoBoxGruaComponent } from '../photo-box-grua/photo-box-grua.component';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { Builder } from 'path-shared/models/builder';
import { FormMultiSelect } from 'path-shared/components/form/form-multi-select/model/form-multi-select.model';


@Component({
  selector: 'app-diposit-table',
  templateUrl: './diposit-table.component.html',
  styleUrls: ['./diposit-table.component.scss']
})
export class DipositTableComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  diposits = [];
  filter: DipositGruaFilter = new DipositGruaFilter('idDipositVehicle', 'desc');
  dataSource: CustomDataSource<VehicleDipositDto, DipositGruaFilter>;
  highlightedRows = [];
  displayedItems = [];
  denunciesImages: any = [];
  denunciesImageDialogRef: MatDialogRef<DenunciesImagesComponent>;
  matriculaFilter: string;
  @Input() updatedFilterData: DipositGruaFilter;
  // @Output() notify: EventEmitter<string> = new EventEmitter<string>();
  displayedColumns = ['idDipositVehicle', 'idvehicleFK', 'dataEntrada', 'horaEntrada', 'dataSortida', 'horaSortida', 'importCobrat', 
  'motiuEntrada', 'llocRetirada', 'formapagament', 'tecnicGrua', 'imatges', 'numAlbara'];
  constructor(
    private dipositGruaService: DipositGruaService,
    private filterService: FilterService,
    private dialog: MatDialog,
    private i18n: I18n,
    private denunciesService: DenunciesService,
  ) { }

  ngOnInit() {
    this.loadDiposits();
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<VehicleDipositDto, DipositGruaFilter>(this.paginator, new DipositGruaFilterService(), 
    'urlGetSomPolDipositGruaStock', 'urlGetSomPolDipositGruaStockCount');
    this.dataSource.subject.subscribe((data) => {
      data.forEach(item => {
        this.displayedItems[item.secureId] = {
          id: item.secureId
        };
        // item.IMATGES = this.loadImatges(item);
      });
    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
      }
    });

    this.filter.paginator.init(this.sort, this.paginator);
  }

  loadDiposits() {
    this.dipositGruaService.getDipositsCombo().subscribe(result => {
      const diposits: Array<any> = Object.assign([], result);
      this.diposits = [];
      diposits.forEach(diposit => {
        this.diposits.push(new FormMultiSelect(diposit.idDiposit, diposit.carrer));

      });
    });
  }

  loadImatges(vehicleDiposit) {
    if ( vehicleDiposit !== undefined && vehicleDiposit !== null) {
      this.denunciesService.getDenunciaImagesNumDen(vehicleDiposit.iddenuncia).subscribe( images => {
        return images;
        });


      // this.propostesService
      // .getImages(this.vehicleDiposit.iddenuncia).subscribe(images => {
      //   if ( images !== undefined &&  images !== null && images.length > 0 ) {
      //     this.vehicleDiposit.IMATGES = images;
      //     this.pbg.updateImatges(images);
      //   } else {
      //     this.vehicleDiposit.IMATGES = [];
      //     this.pbg.updateImatges([]);
      //   }
      // });
    }
  }

  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(tap(() => this.filter.updated()))
    .subscribe();

    this.load();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      this.update();
    }
  }

  update() {
    if (this.dataSource !== undefined) {
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
    }
  }

  load() {
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }

  generateExcel(): void {

      const filter = this.filter;
      /*if (this.updatedFilterData != null) {
        filter = this.updatedFilterData;
      }*/
      this.dialog.open(DownloadExcelDialogComponent, {
          closeOnNavigation: false,
          autoFocus: true,
          data: Builder.newDownloadExcelDialogData (this.dipositGruaService, filter, this.i18n('Diposit'), this.sort.direction, this.sort.active)
      });
  }

  onRowClicked(row) {
    if (row !== undefined && row !== null) {
      if (row.iddenuncia !== undefined && row.iddenuncia !== null &&  row.iddenuncia !== '' ) {
        this.denunciesService.getDenunciaImagesNumDen(row.iddenuncia).subscribe( images => {
          row.IMATGES = images;
          const ref = this.dialog.open(DipositEditorComponent, {
            width: '90%',
            height: '90%',
            closeOnNavigation: true,
            autoFocus: true,
            data: {
              'isDialog': true,
              'isReadOnly': false,
              'vehicleDiposit': row
            }
          });
          ref.componentInstance.update.subscribe(res => {
            if (res) {
              this.update();
              this.dataSource.subject.subscribe(updatedRows => {
                const updatedRow = updatedRows.find(x => x.secureId === row.secureId);
                ref.componentInstance.vehicleDiposit = updatedRow;
              });
            }
          });
          ref.afterClosed().subscribe(data => {
            this.update();
          });
        });
      } else {
          row.IMATGES = [];
          const ref = this.dialog.open(DipositEditorComponent, {
            width: '90%',
            height: '90%',
            closeOnNavigation: true,
            autoFocus: true,
            data: {
              'isDialog': true,
              'isReadOnly': false,
              'vehicleDiposit': row
            }
          });
          ref.afterClosed().subscribe(data => {
            this.update();
          });
      }

    }
  }

  viewImages(numDenuncia, id) {

    this.denunciesService.getDenunciaImagesNumDen(numDenuncia).subscribe( images => {
      this.denunciesImages = images;
      this.denunciesImages.forEach(element => {
        element.NUM_DENUNCIA = numDenuncia;
      });
//        this.denunciesImageDialogRef = this.dialog.open(PhotoBoxGruaComponent,
      const dialogPhoto = this.dialog.open(PhotoBoxGruaComponent,
      {
        minWidth: '50%',
        maxWidth: '90%',
        maxHeight: '70%',
        closeOnNavigation: false,
        autoFocus: true,
        data: {'ficheros': images, 'numDenuncia': numDenuncia, 'isDialog': true},
      });
      this.denunciesImageDialogRef.componentInstance.somId = id;

      dialogPhoto.afterClosed().subscribe(() => {
        this.denunciesService.getDenunciaImages(numDenuncia).subscribe( newImages => {
          this.dataSource.getData().find(x => x.iddenuncia === numDenuncia).numImatges = newImages.length;
          this.denunciesImages = newImages;
          this.denunciesImages.forEach(element => {
            element.NUM_DENUNCIA = numDenuncia;
          });
        });
      });
    });
  }

  matriculaFilterChange(event: any) {
    if (this.matriculaFilter !== undefined && this.matriculaFilter !== null && this.matriculaFilter !== '' ) {
      this.filter.matricula = this.matriculaFilter;
      this.load();
    } else if (  this.matriculaFilter === '' && this.filter.matricula !== this.matriculaFilter) {
      this.filter.matricula = '';
      this.load();
    }
  }
}
