import { ExcelGenerator } from 'path-shared/interfaces/excel-generator';
import { Injectable, EventEmitter } from '@angular/core';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { api } from 'path-shared/configuration/urls';
import { config } from 'path-shared/configuration/config';
import { Observable } from 'rxjs';
import { inherits } from 'util';
import { HttpParams } from '@angular/common/http';
import { Persona } from '../models/persona.model';
import { Domicili } from '../models/domiciliDto';
import { PersonesFilterService } from './persones-filter-service';


const defaultSortParam = 'tipus';
const defaultSortDirection = 'asc';

@Injectable()
export class PersonesService implements ExcelGenerator {
    displayedItems = [];

    constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {
      this.displayedItems['checkedValues'] = 0;
      this.displayedItems['checkedItems'] = Array();
    }

    getPersona(secure_id): Observable<any> {
      if (this.authGuard.canActivate()) {
        return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetPersona'].replace('{secure_id}', secure_id), null);
      }
    }

    setPersona(persona: Persona) {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetPersona'], persona);
          }
    }
    getDomicilis(secure_id): Observable<any> {
      if (this.authGuard.canActivate()) {
        return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetDomicilis'].replace('{secure_id}', secure_id), null);
      }
    }

    setDomicili(domicli: Domicili) {
      if (this.authGuard.canActivate()) {
        return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetDomicili'], domicli);
      }
    }

    deleteDomicili(domicli: Domicili) {
      if (this.authGuard.canActivate()) {
        const url = config.urls['apiUrl'] + api.endpoints['urlDeleteDomicili'].replace('{secure_id}', domicli.secure_id);
        return this.http.delete(url, null);
      }

    }

    getVehiclesPropietat(secure_id): Observable<any> {
      if (this.authGuard.canActivate()) {
        return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetVehicles'].replace('{secure_id}', secure_id), null);
      }
    }

    findPersonaByDniNif(nif): Observable<any> {
      if (this.authGuard.canActivate()) {
        nif = nif.replace(/\./g, '$@');
        return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetPersonByNif'].replace('{nif}', nif), null);
      }
    }

    findEmpresaByCif(cif): Observable<any> {
      if (this.authGuard.canActivate()) {
        cif = cif.replace(/\./g, '$@');
        return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetEmpresaByCif'].replace('{cif}', cif), null);
      }
    }
   /* setMatricula(matricula: Matricula): Observable<any> {
      if (this.authGuard.canActivate()) {
        return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetMatricula'], matricula);
      }
    }
    getReports(page): Observable<any> {
      if (this.authGuard.canActivate()) {
        page = page.replace('.', 'xXx')
        return this.http.get(config.urls['apiUrl'] + api.endpoints['urlReports'].replace('{page}', page), null);
      }
    }

    generateReport(matriculaID, reportID): Observable<any> {
      const params = new HttpParams()
      .append('matricula', matriculaID)
      .append('report', reportID);

      if (this.authGuard.canActivate()) {
        return this.http.getFileResponse(config.urls['apiUrl'] + api.endpoints['urlGenerateReport'], params );
      }
    }*/

    deletePersona(secure_id): Observable<any> {
      if (this.authGuard.canActivate()) {
        return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeletePersona'].replace('{secure_id}', secure_id), null);
      }
    }

    generateExcel(filter: any, sortDir: string, sortField: string): Observable<any> {
      const start = 0;
      const pageSize = 1000;

      sortField = sortField === undefined ? defaultSortParam : sortField;
      sortDir = sortDir === '' ? defaultSortDirection : sortDir;
      const params = new PersonesFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

      if (this.authGuard.canActivate()) {
        return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlPersonesExcel'],  params );
      }
    }
/*
    loadMatricules(data: MatriculaLoader): Observable<any> {
      if (this.authGuard.canActivate()) {
        return this.http.post(config.urls['apiUrl'] + api.endpoints['urlMatriculesLoad'], data );
      }
    }*/

}
