import { Component, OnInit, ViewChild, Inject, AfterViewInit, OnChanges } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { PermissionDialogComponent } from 'path-shared/components/permission-dialog/permission-dialog.component';
import { MatriculaHistoricFilter } from '../../models/matricula-historic-filter';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { MatriculesHistoricTableComponent } from '../../components/matricules-historic-table/matricules-historic-table.component';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { Router } from '@angular/router';
import { MatriculesService } from '../../services/matricules.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { TipusMatriculesService } from 'app/sections/ciutat/tipus-matricules/services/tipus-matricules.service';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { MatriculesHistoricFilterComponent } from '../../components/matricules-historic-filter/matricules-historic-filter.component';

@Component({
  selector: 'app-matricules-historic',
  templateUrl: './matricules-historic.component.html',
  styleUrls: ['./matricules-historic.component.scss']
})
export class MatriculesHistoricComponent implements OnInit, AfterViewInit {
  permissionDialogRef: MatDialogRef<PermissionDialogComponent>;
  activeFilters: string[] = [];
  filterData: MatriculaHistoricFilter;
  somIdFoo: number;
  tipusMatricules = Array();
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  @ViewChild(MatriculesHistoricTableComponent) matriculesHistoricTable: MatriculesHistoricTableComponent;

  constructor(
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private router: Router,
    private matriculesService: MatriculesService,
    private i18n: I18n,
    private toastr: ToastrService,
    private tipusMatriculesService: TipusMatriculesService,
    private not: NotificationService,
    public thisDialogRef: MatDialogRef<MatriculesHistoricComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
    ) {
      this.filterData = new MatriculaHistoricFilter('', '');
      if (data !== undefined) {
        this.dataDialog = data;
      }
    }

  ngOnInit() {
    this.tipusMatriculesService.getAll().subscribe(result => {
      this.tipusMatricules = [];
      if (result !== null) {
        result.forEach(element => {
          this.tipusMatricules[element.codi] = element.descripcio;
        });
        this.matriculesHistoricTable.tipusMatricules = this.tipusMatricules;
      }
    });
  }

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Històric matrícules especials'));
    }
  }


  refresh() {
    this.matriculesHistoricTable.load();
  }

  onFilterClicked() {
    const ref = this.dialog.open(MatriculesHistoricFilterComponent, {
      width: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.matriculesHistoricTable.filter,
        'tipusMatricules': this.tipusMatricules
      }
    });

    ref.componentInstance.notify.subscribe((filter: MatriculaHistoricFilter) => {
      this.matriculesHistoricTable.filter.set(filter);
      this.matriculesHistoricTable.load();
    });
  }

  onRowSelected(somid) {
  }

  generateExcel() {
    this.matriculesHistoricTable.generateExcel();
  }

  viewMatricules() {
    this.router.navigate(['/ciutat/matricules']);
  }


}
