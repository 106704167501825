import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
import { MotiusNoNotificacioFilter } from '../models/motius-notificacio-filter';

const defaultSortParam = 'descripcio';
const defaultSortDirection = 'asc';

@Injectable()
export class MotiusNoNotificacioFilterService extends CustomFilterService {
  public createFilterParams(filter: MotiusNoNotificacioFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {

    return new HttpParams()
    .append('somId', (filter.somId ? filter.somId.toString() : ''))
    .append('descripcio', filter.descripcio)
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
