import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
import { BancCarreguesC60DetallFilter } from '../models/banc-carregues-c60-detall-filter';

const defaultSortParam = 'descripcio';
const defaultSortDirection = 'asc';

@Injectable()
export class BancCarreguesC60DetallFilterService extends CustomFilterService {
  public createFilterParams(filter: BancCarreguesC60DetallFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {

    return new HttpParams()
    .append('id', (filter.id ? filter.id.toString() : ''))
    .append('codiCarrega', filter.codiCarrega)
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
