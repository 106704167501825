import { Component, OnInit, ElementRef, ViewChild, Inject, Output, EventEmitter } from '@angular/core';
import { FormService } from 'path-shared/services/form/form.service';
import { ToastrService } from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AvisosConfService } from '../../services/avisosConf.service';
import { AvisosConf } from '../../models/avisos.model';
import { Builder } from 'path-shared/models/builder';


export class TodoItemNode {
  children: TodoItemNode[];
  item: AvisosConf;
}

@Component({
  selector: 'app-avisos-add-node',
  templateUrl: './avisos-add-node.component.html',
  styleUrls: ['./avisos-add-node.component.scss']
})
export class AvisosAddNodeComponent implements OnInit {

  @ViewChild('mainForm') private mainForm: ElementRef;
  avisConf: AvisosConf;
  parent: TodoItemNode;
  elements: any[] = [
    {id: 1, nom: this.i18n('Carpeta')},
    {id: 0, nom: this.i18n('Assumpte')}
  ];
  sino: any[] = [
    {id: true, nom: 'SI'},
    {id: false, nom: 'NO'}
  ];

  edit: boolean;
  nom: string;
  continuar = false;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: [TodoItemNode, boolean],
    private formService: FormService,
    private toastr: ToastrService,
    private i18n: I18n,
    public thisDialogRef: MatDialogRef<AvisosAddNodeComponent>,
    private avisosconfService: AvisosConfService,

  ) {

    this.avisConf = Builder.newAvis();
    this.edit = data[1];
    if (this.edit === false) {
      this.parent = data[0];
      this.avisConf.pareId = this.parent.item.id;
      this.avisConf.id = -1;
    } else {

      this.avisConf = data[0].item;
      this.nom = this.avisConf.nom;

    }

  }

  ngOnInit() {

  }

  save() {

    this.avisConf.nom = this.nom;
    if (this.formService.allFieldsValid(this.mainForm)) {
      if (this.edit ===  false) {  // new element
        this.avisosconfService.setAvis(this.avisConf).subscribe(result => {
          this.avisConf.id = result;
          const child = new TodoItemNode();
          child.item = this.avisConf;
          child.children = null;
          if (this.parent.children == null) {
            this.parent.children = [];
          }
          this.parent.children.push(child);
          // if (this.parent.item.children==null) this.parent.item.children = [];
          // this.parent.item.children.push(child.item);
          this.toastr.info(this.i18n('Element guardat correctament'));
          if (this.continuar !== true) {
            this.thisDialogRef.close(true);
          } else {
            this.nom = '';
            this.avisConf.id = null;
            this.avisConf.alarma = false;
            this.avisConf.mail = null;
            this.avisConf.text = null;
            }
        });
      } else {  // modify element

        this.avisosconfService.setAvis(this.avisConf).subscribe(result => {
          this.toastr.info(this.i18n('Element guardat correctament'));
          this.thisDialogRef.close(true);
        });

      }
    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
    }
  }
}
