<app-filter-header title="Filtrar alarmes" i18n-title  (apply)="this.apply()" (reset)="this.reset()" (dismiss)="this.dismiss()"></app-filter-header>
<div class="modal-body">
  <div class="row">
     <app-filter-text-edit id="alarmes-filter-text" autofocus class="col-sm-6" i18n-label label="Text" [(value)]="filter.text"></app-filter-text-edit>
     <app-form-date-edit id="alarmes-filter-data" class="col-sm-6" i18n-label label="Data" [(value)]="filter.data"></app-form-date-edit>
  </div>
  <div class="row">
      <app-form-combo-edit  class="col-sm-6" i18n-label label="Usuari" [(value)]="filter.IdUsuariACK"  [items]="this.users" valueField="UserID" textField="Name"></app-form-combo-edit>
      <app-form-combo-edit  class="col-sm-6" i18n-label label="Tipus" [(value)]="filter.idTipusAlarma"  [items]="this.tipus" valueField="idTipusAlarma" textField="tipusAlarma"></app-form-combo-edit>
  </div>
  <div class="row">
    <app-filter-text-edit id="alarmes-filter-matricula" autofocus class="col-sm-6" i18n-label label="Matricula" [(value)]="filter.matricula"></app-filter-text-edit>
    <!--<app-filter-text-edit autofocus class="col-sm-6" i18n-label label="Tradesegur Id" [(value)]="filter.text"></app-filter-text-edit>-->
    </div>
</div>
