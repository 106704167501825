import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
import { Reflection } from 'path-shared/services/reflection/reflection';
import { LocalizacionFilter } from '../models/localizacion-filter';

const defaultSortParam = 'infraccion';
const defaultSortDirection = 'asc';


@Injectable({
  providedIn: 'root'
})
export class LocalizacionFilterService  extends CustomFilterService {
  public createFilterParams(filter: LocalizacionFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {
    
    return new HttpParams()
    .append('vel_via', filter.vel_via === null ? null : filter.vel_via.toString())
    .append('infraccion', filter.infraccion  === null  ? null : filter.infraccion.toString())
    .append('propuesta', filter.propuesta   ?  filter.propuesta : '')
    .append('cod_aparat', filter.cod_aparat   ? filter.cod_aparat: '')
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
