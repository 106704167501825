import { TemporadaDto } from './../models/temporadaDto';
import { config } from 'path-shared/configuration/config';
import { Injectable } from '@angular/core';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { Observable } from 'rxjs/Observable';
import { api } from 'path-shared/configuration/urls';
import { ExcelGenerator } from 'path-shared/interfaces/excel-generator';
import { GenericFile } from 'path-shared/components/generic-file-displayer/model/generic-file';
import { TemporadaFilter } from '../models/temporada.filter';
import { TemporadaFilterService } from './temporada-filter.service';

const defaultSortParam = 'id';
const defaultSortDirection = 'desc';

@Injectable()
export class TemporadaService implements ExcelGenerator {
    displayedItems = [];

    constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {
        this.displayedItems['checkedValues'] = 0;
        this.displayedItems['checkedItems'] = Array();
    }

    getTemporada(secureId: string): Observable<any> {
        if (this.authGuard.canActivate()) {
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetTemporada'].replace('{secureId}', secureId), null);
        }
    }

    setTemporada(temporada: TemporadaDto): Observable<any> {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetTemporada'], temporada);
        }
    }

    deleteTemporada(secureId: string): Observable<any> {
        if (this.authGuard.canActivate()) {
            return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteTemporada'].replace('{secureId}', secureId), null);
        }
    }

    generateExcel(filter: TemporadaFilter, sortDir: string, sortField: string): Observable<any> {
        const start = 0;
        const pageSize = 1000;

        sortField = sortField === undefined ? defaultSortParam : sortField;
        sortDir = sortDir === '' ? defaultSortDirection : sortDir;
        const params = new TemporadaFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

        if (this.authGuard.canActivate()) {
          return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlTemporadaExcel'],  params );
        }
    }

    GetAllForCombo(): Observable<any>{
        let filter: TemporadaFilter;
        let sortDir = 'desc';
        let sortField= 'descripcion';
        const start = 0;
        const pageSize = 1000;
        filter = new TemporadaFilter('descripcion', 'desc');
        sortField = sortField === undefined ? defaultSortParam : sortField;
        sortDir = sortDir === '' ? defaultSortDirection : sortDir;
        const params = new TemporadaFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);
        if (this.authGuard.canActivate()) {
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetTemporadas'],  params );
          }
    }

    ClonarTemporada(temporada: TemporadaDto): Observable<any> {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlClonarTemporada'], temporada);
        }
    }

    getTramosByTemporadaCount(secureId: string): Observable<any> {
        if (this.authGuard.canActivate()) {
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetTramosByTemporadaCount'].replace('{secureId}', secureId), null);
        }
    }

}
