<app-dialog-header  label="Editar element" i18n-label></app-dialog-header>

<app-toolbar>
    <app-tool-button text="Guardar" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save"></app-tool-button>
  </app-toolbar>
  <app-dialog-body>
      <form #mainForm>
        
        <div class="form-group" style="width: 50%;" *ngIf="this.edit==false">
          <mat-form-field>
              <mat-select i18n-placeholder placeholder="Tipus element" [(value)]="this.avisConf.isFolder" required>
                  <mat-option value="1" i18n>CARPETA</mat-option>
                  <mat-option value="0" i18n>ASSUMPTE</mat-option>
              </mat-select>
          </mat-form-field>
        </div>
        <div style="width: 50%;">
          <app-form-text-edit id="avisos-editor-nom" name="nom"  i18n-label label="Nom" [(value)]="this.nom"  required></app-form-text-edit>
        </div>
        <div>
          <!-- <app-form-text-edit *ngIf="this.avisConf.isFolder==0" name="mail"  i18n-label label="Correus (separats per punt i coma ( ; ) )" [(value)]="this.avisConf.mail"></app-form-text-edit> -->
          <app-form-text-area *ngIf="this.avisConf.isFolder==0" name="mail"  i18n-label label="Correus (separats per punt i coma ( ; ) )" [(value)]="this.avisConf.mail"></app-form-text-area>
          
        </div>
        <div>
          <app-form-text-edit id="aparells-filter-text"  *ngIf="this.avisConf.isFolder==0" name="text"  i18n-label label="Text del correu" [(value)]="this.avisConf.text"></app-form-text-edit>
        </div>

        <div class="row"  *ngIf="this.avisConf.isFolder==0">
          <mat-form-field class="col-4">
              <mat-select i18n-placeholder placeholder="Genera alarma?" [(value)]="this.avisConf.alarma" required>
                  <mat-option [value]="false">NO</mat-option>
                  <mat-option [value]="true">SI</mat-option>
              </mat-select>
          </mat-form-field>
          <div class="row" *ngIf="this.avisConf.alarma===true">
            <app-form-check-edit class="col-4" label='Zona Azul' i18n-label [(value)]="this.avisConf.alarmas.zb"></app-form-check-edit>
            <app-form-check-edit class="col-4" label='Policia' i18n-label [(value)]="this.avisConf.alarmas.pol"></app-form-check-edit>
            <app-form-check-edit class="col-4" label='Grua' i18n-label [(value)]="this.avisConf.alarmas.gr"></app-form-check-edit>
          </div>          

        </div>
        <div>
          <app-form-check-edit *ngIf="this.avisConf.isFolder==0 && this.edit==false" i18n-label label="Continuar generant assumptes" [(value)]="this.continuar" ></app-form-check-edit>
        </div>
      </form>
    </app-dialog-body>
