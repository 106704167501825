import {Component, OnInit, Inject, ViewChild} from '@angular/core';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { Builder } from 'path-shared/models/builder';
import { BancCarreguesC60Filter } from '../models/banc-carregues-c60-filter';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { BancCarreguesC60TableComponent } from '../components/banc-carregues-c60-table/banc-carregues-c60-table.component';
import { BancCarreguesC60FilterComponent } from '../components/banc-carregues-c60-filter/banc-carregues-c60-filter.component';
import { BancCarreguesC60EditorComponent } from '../components/banc-carregues-c60-editor/banc-carregues-c60-editor.component';
import { BancCarreguesC60 } from '../models/banc-carregues-c60.model';

@Component({
  selector: 'app-banc-carregues-c60',
  templateUrl: './banc-carregues-c60.component.html',
  styleUrls: ['./banc-carregues-c60.component.scss']
})
export class BancCarreguesC60Component implements OnInit {

  filterData: BancCarreguesC60Filter;
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  @ViewChild(BancCarreguesC60TableComponent) bancCarreguesC60Table: BancCarreguesC60TableComponent;

  constructor(
    private dialog: MatDialog,
    public auth: AuthorizationService,
    public thisDialogRef: MatDialogRef<BancCarreguesC60Component>,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog
  ) {
      this.filterData = new BancCarreguesC60Filter('id', 'desc');
      if (data !== undefined) {
        this.dataDialog = data;
      }
  }

  ngOnInit() {
    this.auth.UserCanSeePage(this.auth.Pages.Banc);
    this.bancCarreguesC60Table.itemClicked.subscribe(row => {

      this.update(row);
    });
    this.bancCarreguesC60Table.itemSelected.subscribe(codi => {
      this.thisDialogRef.close(codi);
    });
  }

  //#region Toolbar buttons
  create() {
    this.update(null);
  }

  refresh() {
    this.bancCarreguesC60Table.load();
  }

  onFilterClicked() {
    const ref = this.dialog.open(BancCarreguesC60FilterComponent, {
      width: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.bancCarreguesC60Table.filter
      }
    });

    ref.componentInstance.notify.subscribe((filter: BancCarreguesC60Filter) => {
      this.bancCarreguesC60Table.filter.set(filter);
      this.bancCarreguesC60Table.load();
    });
  }

  generateExcel() {
    this.bancCarreguesC60Table.generateExcel();
  }
  //#endregion

  onRowSelected(somid) {
    this.update(somid);
  }

  update(row: BancCarreguesC60) {

    let readonly = (row !== null &&  row.situacio==='F');
    const data: ComponentDialog = Builder.newComponentDialog(true, readonly, row === undefined || row === null? null : row.secureId);
    
    const ref = this.dialog.open(BancCarreguesC60EditorComponent, {
      width: '70%',
      closeOnNavigation: false,
      autoFocus: true,
      data: data,      
    });

    ref.afterClosed().subscribe(updated => {
      if (updated) { this.bancCarreguesC60Table.load(); }
    });
  }

}
