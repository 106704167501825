import { ResultadCargaComponentComponent } from './../resultad-carga-component/resultad-carga-component.component';
import { DenunciaPagamentParcialComponent } from './../../../../denuncies/components/denuncia-pagament-parcial/denuncia-pagament-parcial.component';
import { DenunciaCobrarModel } from './../../../../denuncies/models/denuncia-cobrar.model';
import { C60FechaCobroComponent } from './../c60-fecha-cobro/c60-fecha-cobro.component';
import { Component, OnInit, AfterViewInit, OnChanges, ViewChild, Input, Output, EventEmitter, SimpleChanges, Inject, TemplateRef } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BancCarreguesC60 } from '../../models/banc-carregues-c60.model';
import { BancCarreguesC60Filter } from '../../models/banc-carregues-c60-filter';
import { BancCarreguesC60Detall } from '../../models/banc-carregues-c60-detall.model';
import { BancCarreguesC60DetallFilter } from '../../models/banc-carregues-c60-detall-filter';
import { BancCarreguesC60DetallFilterService } from '../../services/banc-carregues-c60-detall-filter.service';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { BancCarreguesC60Service } from '../../services/banc-carregues-c60.service';
import { BancCarreguesC60ResumDetall } from '../../models/banc-carregues-c60-resum-detall.model';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { Builder } from 'path-shared/models/builder';
import { RespuestaCargaC60Dto } from '../../models/RespuestaCargaC60Dto';
import { ProgressBarComponent } from 'path-shared/components/progress-bar/progress-bar.component';
import { LoaderService } from 'path-shared/components/loader/loader.service';
import { ComprovacionCargaFicheroC60 } from '../../models/ComprovacionCargaFicheroC60';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-banc-carregues-c60-detall-table',
  templateUrl: './banc-carregues-c60-detall-table.component.html',
  styleUrls: ['./banc-carregues-c60-detall-table.component.scss']
})
export class BancCarreguesC60DetallTableComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource: CustomDataSource<BancCarreguesC60Detall, BancCarreguesC60DetallFilter>;
  filter: BancCarreguesC60DetallFilter = new BancCarreguesC60DetallFilter('id', 'asc');
  displayedItems = [];
  highlightedRows = [];
  bancCarreguesC60DetallResum: BancCarreguesC60ResumDetall;
  cargaPadre: BancCarreguesC60;
  secureId: string;
  id: string;
  dialogData: ComponentDialog;
  porcentajeTramitados: number;
  YaProcesado: boolean;
  ningunCerrado: boolean = true;

  @Input() selectButton: boolean;
  @Input() updatedFilterData: BancCarreguesC60Filter;
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();
  @Output() itemClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() itemSelected: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('myDialog') myDialog: TemplateRef<any>;
  @ViewChild('progressBarDialog') progressBarDialog: TemplateRef<any>;
  @ViewChild('progressBar') private progressBar: ProgressBarComponent;
  displayedColumns = ['id', 'codiCarrega', 'expediente','situacion', 'recibo', 'fechaCobro', 'referenciaC60', 'entidad', 'oficina', 'importe', 'missatgeError'];
  private loaderService: LoaderService = LoaderService.getInstance();
  constructor(
    private bancCarreguesC60Service: BancCarreguesC60Service,
    private filterService: FilterService,
    private dialog: MatDialog,
    private i18n: I18n,
    public auth: AuthorizationService,
    private confirmationDialogService: ConfirmationDialogService,
    private toastr: ToastrService,
    public thisDialogRef: MatDialogRef<BancCarreguesC60DetallTableComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.secureId = data['secureId'];
    this.id = data['id'];
    this.dialogData = data;
    this.porcentajeTramitados = 0;
    this.cargaPadre = data['carga'];
  }

  ngOnInit() {
    this.YaProcesado = false;
    if (this.secureId !== null) {
      this.bancCarreguesC60Service.getBancCarregaDetallResumC60(this.secureId).subscribe((data: BancCarreguesC60ResumDetall) => {
        this.bancCarreguesC60DetallResum = data;
      });
    }
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<BancCarreguesC60Detall, BancCarreguesC60DetallFilter>(this.paginator, new BancCarreguesC60DetallFilterService(), 'urlGetBancCarregaDetallC60', 'urlGetBancCarregaDetallC60Count');
    this.dataSource.subject.subscribe((data) => {
      data.forEach(item => {
        this.displayedItems[item.secureId] = {
          id: item.secureId
        };
        if ((item.missatgeError !== null && item.missatgeError !== '') || (item.expediente !== undefined && item.expediente !== null)) {
          this.YaProcesado = true;
        }
        if (item.situacion === 'C') {
          this.ningunCerrado = false;
        }
      });
    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
        if (!this.selectButton) { this.filterService.saveFilter('banc-carregues-c60-detall-filter', this.filter); }
      }
    });

    if (!this.selectButton) { this.filterService.loadFilter('banc-carregues-c60-detall-filter', this.filter); }
    this.filter.paginator.init(this.sort, this.paginator);
  }

  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.filter.updated()))
      .subscribe();

    this.load();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      this.update();
    }
  }

  update() {
    if (this.dataSource !== undefined) {
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
    }
  }

  load() {
    this.filter.codiCarrega = this.id;
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }
  comprovarEstadoFichero() {

    this.dialog.getDialogById('procesCarga').close();
    this.bancCarreguesC60Service.comprovarEstadoFichero(this.cargaPadre).subscribe((x: ComprovacionCargaFicheroC60) => {
      const result = x;
      if (result !== undefined && result !== null && result.todosPasados !== undefined && result.todosPasados !== null && !result.todosPasados) {
        const ref = this.dialog.open(ResultadCargaComponentComponent, {
          width: '50%',
          maxHeight: '87%',
          closeOnNavigation: true,
          autoFocus: true,
          data: result
        });
      }
      this.YaProcesado = true;
    });
  }

  comprovarEstadoFicheroV2() {
    this.dialog.getDialogById('progressBarDialog').close();

    this.bancCarreguesC60Service.comprovarEstadoFichero(this.cargaPadre).subscribe((x: ComprovacionCargaFicheroC60) => {
      const result = x;
      if (result !== undefined && result !== null && result.todosPasados !== undefined && result.todosPasados !== null && !result.todosPasados) {
        const ref = this.dialog.open(ResultadCargaComponentComponent, {
          width: '70%',
          height: '50%',
          closeOnNavigation: true,
          autoFocus: true,
          data: result
        });
      }
      this.YaProcesado = true;
    });
  }

  finalitzar() {

    this.bancCarreguesC60Service.checkRegistrosErorres(this.cargaPadre).subscribe(x => {
      if (x !== undefined && x !== null && x > 0) {
        this.confirmationDialogService.confirm(this.i18n('Aceptar'), this.i18n('Hay registros con errores, seguro que quiere finalizar?'))
          .then((confirmed) => {
            if (confirmed) {
              this.bancCarreguesC60Service.FinalizarCargac60(this.cargaPadre).subscribe(() => {
                this.toastr.info(this.i18n('Finalizado correctamente'));
                this.dialog.closeAll();
              });
            }
          });
      } else if (x === 0) {
        this.bancCarreguesC60Service.FinalizarCargac60(this.cargaPadre).subscribe(() => {
          this.toastr.info(this.i18n('Finalizado correctamente'));
          this.dialog.closeAll();
        });
      }
    });
  }

  cancel() {
    this.filter.clear();
    this.thisDialogRef.close(false);
  }

  ProcessarV2() {
    let fechaCobro;
    const data: ComponentDialog = Builder.newComponentDialog(true, false);
    this.dialog.open(C60FechaCobroComponent, {
      width: '20%',
      closeOnNavigation: true,
      autoFocus: true,
      data: data
    }).afterClosed().subscribe(updated => {
      fechaCobro = updated;

      this.dialog.open(this.progressBarDialog, { disableClose: true, width: '35%', height: '20%', id: 'procesCarga', position: { bottom: '15%' } });

      this.bancCarreguesC60Service.procesarV2(this.secureId, fechaCobro).subscribe((resultadosCarga: RespuestaCargaC60Dto[]) => {
        resultadosCarga.forEach(resultadoCarga => {
          if (resultadoCarga.resultado === 2) {
            const ref = this.dialog.open(DenunciaPagamentParcialComponent, {
              width: '90%',
              height: '90%',
              closeOnNavigation: true,
              autoFocus: true,
              data: {
                'isDialog': true,
                'isReadOnly': false,
                'data': resultadoCarga
              }
            });
            ref.afterClosed().subscribe(() => {
              this.update();
            });
          } else {
            const indexRegCarga = this.dataSource.getData().findIndex(y => y.id === resultadoCarga.id);
            if (indexRegCarga !== undefined && indexRegCarga !== null && indexRegCarga !== -1) {
              // sino se encuentra es que no se muestra en pantalla!!
              this.dataSource.getData().find(y => y.id === resultadoCarga.id).expediente = resultadoCarga.exp;
              this.dataSource.getData().find(y => y.id === resultadoCarga.id).missatgeError = resultadoCarga.mensaje;
            }
          }
        });
        this.comprovarEstadoFichero();
      });
    });
  }
  processar() {
    const data: ComponentDialog = Builder.newComponentDialog(true, false);
    const ref = this.dialog.open(C60FechaCobroComponent, {
      width: '20%',
      closeOnNavigation: true,
      autoFocus: true,
      data: data
    });

    ref.afterClosed().subscribe(updated => {
      var cobro = Builder.newDenunciaCobrar();
      var elemento = Builder.newCargaProcesarDto();
      if (updated !== undefined && updated !== null) {
        cobro.fechaCobroByUser = updated;
      }
      elemento.cobro = cobro;
      this.bancCarreguesC60Service.getAllBancsCargas(this.secureId).subscribe(x => {
        this.dialog.open(this.myDialog, { disableClose: true, width: '25%', height: '10%', id: 'procesCarga', position: { bottom: '15%' } });
        var cargasPendientes = x;
        var counter = 0;
        cargasPendientes.forEach(element => {
          elemento.registro = element;
          this.loaderService.hide(true);
          this.bancCarreguesC60Service.procesarRegistro(elemento).subscribe((resultadoCarga: RespuestaCargaC60Dto) => {
            this.loaderService.hide(true);
            if (resultadoCarga.resultado === 2) {
              const ref = this.dialog.open(DenunciaPagamentParcialComponent, {
                width: '90%',
                height: '90%',
                closeOnNavigation: true,
                autoFocus: true,
                data: {
                  'isDialog': true,
                  'isReadOnly': false,
                  'data': resultadoCarga
                }
              });
              ref.afterClosed().subscribe(() => {
                this.update();
              });
            } else {
              const indexRegCarga = this.dataSource.getData().findIndex(y => y.id === resultadoCarga.id);
              if (indexRegCarga !== undefined && indexRegCarga !== null && indexRegCarga !== -1) {
                // sino se encuentra es que no se muestra en pantalla!!
                this.dataSource.getData().find(y => y.id === resultadoCarga.id).expediente = resultadoCarga.exp;
                this.dataSource.getData().find(y => y.id === resultadoCarga.id).missatgeError = resultadoCarga.mensaje;
              }
            }

            counter = counter + 1;
            this.porcentajeTramitados = (counter / cargasPendientes.length) * 100;
            if (counter === cargasPendientes.length) {
              this.comprovarEstadoFichero();
            }
          }, (err: Error) => {
            counter = counter + 1;
            this.porcentajeTramitados = (counter / cargasPendientes.length) * 100;
            if (counter === cargasPendientes.length) {
              this.comprovarEstadoFichero();
            }
          });

        });
      });
    });

  }


  recargarC60() {
    var elemento = Builder.newCargaProcesarDto();
    var cobro = Builder.newDenunciaCobrar();
    elemento.cobro = cobro;
    this.bancCarreguesC60Service.getAllBancsCargasParaReporcesar(this.secureId).subscribe(x => {
      if (x !== undefined && x !== null && x.length > 0) {
        this.dialog.open(this.myDialog, { disableClose: true, width: '25%', height: '10%', id: 'procesCarga', position: { bottom: '15%' } });
        var cargasPendientes = x;
        var counter = 0;
        cargasPendientes.forEach(element => {
          elemento.registro = element;
          // if (elemento.registro.referenciaC60 === '200000779238') {

          this.loaderService.hide(true);
          this.bancCarreguesC60Service.ReprocesarRegistro(elemento).subscribe((resultadoCarga: RespuestaCargaC60Dto) => {
            this.loaderService.hide(true);
            if (resultadoCarga.resultado === 2) {
              const ref = this.dialog.open(DenunciaPagamentParcialComponent, {
                width: '90%',
                height: '90%',
                closeOnNavigation: true,
                autoFocus: true,
                data: {
                  'isDialog': true,
                  'isReadOnly': false,
                  'data': resultadoCarga
                }
              });
              ref.afterClosed().subscribe(data => {
                this.update();
              });
            } else {
              const indexRegCarga = this.dataSource.getData().findIndex(y => y.id === resultadoCarga.id);
              if (indexRegCarga !== undefined && indexRegCarga !== null && indexRegCarga !== -1) {
                // sino se encuentra es que no se muestra en pantalla!!
                this.dataSource.getData().find(y => y.id === resultadoCarga.id).expediente = resultadoCarga.exp;
                this.dataSource.getData().find(y => y.id === resultadoCarga.id).missatgeError = resultadoCarga.mensaje;
              }
            }

            counter = counter + 1;
            this.porcentajeTramitados = (counter / cargasPendientes.length) * 100;
            if (counter === cargasPendientes.length) {
              this.comprovarEstadoFichero();
            }
          }, (err: Error) => {
            counter = counter + 1;
            this.porcentajeTramitados = (counter / cargasPendientes.length) * 100;
            if (counter === cargasPendientes.length) {
              this.comprovarEstadoFichero();
            }
          });
        });
      }

    });

  }

  cargarC60ExpCerrados() {
    var elemento = Builder.newCargaProcesarDto();
    var cobro = Builder.newDenunciaCobrar();
    elemento.cobro = cobro;
    this.bancCarreguesC60Service.getAllBancsCargasParaReporcesarCerrados(this.secureId).subscribe(x => {
      if (x !== undefined && x !== null && x.length > 0) {
        this.dialog.open(this.myDialog, { disableClose: true, width: '25%', height: '10%', id: 'procesCarga', position: { bottom: '15%' } });
        var cargasPendientes = x;
        var counter = 0;
        cargasPendientes.forEach(element => {
          elemento.registro = element;
          // if (elemento.registro.referenciaC60 === '200000779238') {

          this.loaderService.hide(true);
          this.bancCarreguesC60Service.ReprocesarCerradoRegistro(elemento).subscribe((resultadoCarga: RespuestaCargaC60Dto) => {
            this.loaderService.hide(true);
            if (resultadoCarga.resultado === 2) {
              const ref = this.dialog.open(DenunciaPagamentParcialComponent, {
                width: '90%',
                height: '90%',
                closeOnNavigation: true,
                autoFocus: true,
                data: {
                  'isDialog': true,
                  'isReadOnly': false,
                  'data': resultadoCarga
                }
              });
              ref.afterClosed().subscribe(data => {
                this.update();
              });
            } else {
              const indexRegCarga = this.dataSource.getData().findIndex(y => y.id === resultadoCarga.id);
              if (indexRegCarga !== undefined && indexRegCarga !== null && indexRegCarga !== -1) {
                // sino se encuentra es que no se muestra en pantalla!!
                this.dataSource.getData().find(y => y.id === resultadoCarga.id).expediente = resultadoCarga.exp;
                this.dataSource.getData().find(y => y.id === resultadoCarga.id).missatgeError = resultadoCarga.mensaje;
              }
            }

            counter = counter + 1;
            this.porcentajeTramitados = (counter / cargasPendientes.length) * 100;
            if (counter === cargasPendientes.length) {
              this.comprovarEstadoFichero();
            }
          }, (err: Error) => {
            counter = counter + 1;
            this.porcentajeTramitados = (counter / cargasPendientes.length) * 100;
            if (counter === cargasPendientes.length) {
              this.comprovarEstadoFichero();
            }
          });
        });
      }

    });

  }

}
