import { CookieService } from 'ngx-cookie-service';
import { AppModule } from './../../app.module';
import { AppComponent } from 'app/app.component';
import { IFilterData } from './i-filter-data';
import { PaginationFilter } from './pagination.filter';
import { FilterDataItem } from './filter-data-item';
import { Output, EventEmitter, Inject } from '@angular/core';
import { SortDirection } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { AppInjectorModule } from 'app/app-injector/app-injector.module';
import { FilterTypes } from './filter-types';
import { ShortDatePipe } from 'path-shared/pipes/short-date-pipe';
import { ValidationService } from 'path-shared/services/validation/validation.service';


export class FilterData implements IFilterData {

  @Output() filterUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() filterCleared: EventEmitter<boolean> = new EventEmitter<boolean>();
  public paginator: PaginationFilter = new PaginationFilter();
  protected i18n: I18n;

  private defaultSortField: string;
  private defaultSortDirection: SortDirection;

  activeFilters: Map<string, string>;
  public formatOutput: Map<string, string>;
  public labels: Map<string, string>;
  public notifyClear: boolean;

  constructor(sortField: string, sortDir: SortDirection) {
    this.defaultSortField = sortField;
    this.defaultSortDirection = sortDir;
    this.formatOutput = new Map();
    this.labels = new Map();
    this.activeFilters = new Map();
    this.paginator.sortField = sortField;
    this.paginator.sortDir = sortDir;
    this.i18n = AppInjectorModule.injector.get(I18n);
    this.notifyClear = false;
  }

  public static validProperty(prop) {
    if (prop === 'paginator' || prop === 'activeFilters' || prop === 'filterUpdated' || prop === 'labels'
    || prop === 'formatOutput' || prop === 'i18n' || prop === 'cookie' || prop === 'defaultPageSize' || prop === 'cookie' || prop === 'defaultSortField' || prop === 'defaultSortDirection' || prop === 'notifyClear' || prop === 'filterCleared') {
      return false;
    } else {
      return true;
    }
  }

  active() {
    if (this.activeFilters.size > 0) {
      return true;
    } else {
      return false;
    }
  }

  updated(emit: boolean = true) {
    for (const property of Object.getOwnPropertyNames(this)) {
      const value = this[property];
      if (FilterData.validProperty(property)) {
        if (value !== null && value !== undefined && value !== '') {
          this.activeFilters.set(property, value);
        } else {
          this.activeFilters.delete(property);
        }
      }
    }

    if (emit) {
      this.filterUpdated.emit(true);
    } else {
      this.filterUpdated.emit(false);
    }
  }
  cleared(emit: boolean = true) {
    for (const property of Object.getOwnPropertyNames(this)) {
      const value = this[property];
      if (FilterData.validProperty(property)) {
        if (value !== null && value !== undefined && value !== '') {
          this.activeFilters.set(property, value);
        } else {
          this.activeFilters.delete(property);
        }
      }
    }

    if (emit) {
      this.filterCleared.emit(true);
    }
  }

  set(filter) {
    for (const property of Object.getOwnPropertyNames(this)) {
      if (FilterData.validProperty(property)) {
        if (filter[property] !== undefined) {
          this[property] = filter[property];
        }
      }
    }
    if (filter.labels !== null) {
      this.labels = filter.labels;
    }
    if (filter.formatOutput !== null) {
      this.formatOutput = filter.formatOutput;
    }
  }

  clear() {
    for (const property of Object.getOwnPropertyNames(this)) {
      if (FilterData.validProperty(property)) {
        this[property] = this.clearValue(property);
      }
    }
    this.activeFilters.clear();
    /*this.paginator.sortField = this.defaultSortField;
    this.paginator.sortDir = this.defaultSortDirection;*/
    if (this.notifyClear !== undefined && this.notifyClear !== null && this.notifyClear) {
      this.cleared();
    } else {
      this.updated();
    }

  }

  filterItems() {
    const items = [];

    for (const property of Object.getOwnPropertyNames(this)) {
      if (FilterData.validProperty(property)) {
        items.push(new FilterDataItem(property, this[property]));
      }
    }

    return items;
  }

  remove(property) {
    this[property] = this.clearValue(property);
    this.updated();
  }

  private clearValue(property) {
    if (typeof this[property] === 'string') {
      return '';
    } else {
      return null;
    }
  }

  public toJSON(): string {
    return JSON.stringify({
      'filter': this.filterItems(),
      'paginator': JSON.stringify(this.paginator, this.replacer)
    });
  }

  replacer(key, val) {
    if (FilterData.validProperty(key))  {
      return val;
    } else {
      return null;
    }

  }

  fromJSON(json: string) {
    if (json !== null && json !== undefined && json.length > 0 && json !== 'undefined') {
      const data = JSON.parse(json);
      if (data['filter'] !== undefined) {
        this.fromDataItems(data['filter']);
      }
      if (data['paginator'] !== undefined) {
        this.paginator.setData(JSON.parse(data['paginator']), true);
      }
      this.updated(false);
    }
  }

  private fromDataItems(data: FilterDataItem[]) {
    if (data !== null && data !== undefined) {
      for (let n = 0; n < data.length; n++) {
        const element = data[n];
        if (element !== undefined) {
          this[element.property] = element.value;
        }
      }
    }
  }

  public getLabel(property: string) {
    let label = property;
    if (this.labels !== null) {
      const val = this.labels[property];
      if (val !== '') {
        label = val;
      }
    }
    return label;
  }

  public getValue(property: string) {
    let val = this[property];
    if (this.formatOutput !== null && val !== null && val !== '') {
      switch (this.formatOutput[property]) {
        case 'shortDate':
          val = (new ShortDatePipe(this.i18n)).transform(val);
          break;
        case 'multiSelect':
          val = val.map(s => s.item_text).toString();
          break;
      }
    }
    return val;
  }
}
