<app-toolbar>  
  <app-tool-button-permissions [permission]="this.auth.Pages.Estadistiques && this.auth.Pages.Reserves"></app-tool-button-permissions>
</app-toolbar>

<div class="row justify-content-around">
  <app-form-date-edit id="estadistiques-reserves-aparcare-data" (valueChange)="onDateChange()" [view]="'month'" class="col-4" i18n-label label="Data" [(value)]="dateSelected"></app-form-date-edit>
</div>

<div class="text-center">
  <h5>{{ title }}</h5>
</div>

<div class="row" *ngIf="barChartLabels && barChartData">
  <div class="col-1"></div>
  <div class="col-10">
    <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
      [legend]="barChartLegend" [chartType]="barChartType">
    </canvas>
  </div>
  <div class="col-1"></div>
</div>