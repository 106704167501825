import { I18n } from '@ngx-translate/i18n-polyfill';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { MatriculaHistoric } from '../../models/matricula-historic.model';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { MatriculaHistoricFilter } from '../../models/matricula-historic-filter';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { MatriculesService } from '../../services/matricules.service';
import { MatriculaHistoricFilterService } from '../../services/matricules-historic-filter.service';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Reflection } from 'path-shared/services/reflection/reflection';
import { Builder } from 'path-shared/models/builder';

@Component({
  selector: 'app-matricules-historic-table',
  templateUrl: './matricules-historic-table.component.html',
  styleUrls: ['./matricules-historic-table.component.scss']
})
export class MatriculesHistoricTableComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  data: MatriculaHistoric[];
  dataSource: CustomDataSource<MatriculaHistoric, MatriculaHistoricFilter>;
  highlightedRows = [];
  filter: MatriculaHistoricFilter = new MatriculaHistoricFilter('dataCreacio', 'desc');
  filterPage: MatriculaHistoricFilter;
  displayedItems = [];
  public tipusMatricules = Array();

  @Input() selectButton: boolean;
  @Input() updatedFilterData: MatriculaHistoricFilter;
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();
  @Output() itemClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() itemSelected: EventEmitter<string> = new EventEmitter<string>();

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['tipus', 'matricula', 'marca', 'model', 'observacions', 'dataInici', 'dataFi', 'dataCreacio'];

  constructor(
    private matriculesService: MatriculesService,
    private filterService: FilterService,
    private dialog: MatDialog,
    private i18n: I18n
  ) {
  }

  ngOnInit() {
    if (this.selectButton) {
      this.displayedColumns = ['incorpora'].concat(this.displayedColumns);
    }
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<MatriculaHistoric, MatriculaHistoricFilter>(this.paginator, new MatriculaHistoricFilterService(), 'urlGetMatriculaHistoric', 'urlGetMatriculaHistoricCount');
    this.dataSource.subject.subscribe((data) => {
        data.forEach(item => {
          this.matriculesService.displayedItems[item.SECURE_ID] = {
            id: item.SECURE_ID,
            checked: false
          };
        });

      this.displayedItems = this.matriculesService.displayedItems;
    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
        if (!this.selectButton) { this.filterService.saveFilter('matricules-historic-filter', this.filter); }
      }
    });

    if (!this.selectButton) { this.filterService.loadFilter('matricules-historic-filter', this.filter); }
    this.filter.paginator.init(this.sort, this.paginator);
  }

  ngAfterViewInit() {
      // reset the paginator after sorting
      this.sort.sortChange.subscribe(() => {
        this.paginator.pageIndex = 0;
        this.filter.paginator.set(this.sort, this.paginator);
        this.filter.updated();
      } );
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.filter.updated()))
      .subscribe();
    this.load();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      this.update();
    }
  }

  update() {
    if (this.dataSource !== undefined) {
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
    }
  }

  load() {
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }

  onRowClicked(row) {
    this.itemClicked.next(row.SECURE_ID);
  }

  selectCurrent(codi) {
    this.itemSelected.next(codi);
  }

  getTipusMatricula(id) {
    if (id === null || id === undefined) {
      return '';
    } else if (Reflection.empty(this.tipusMatricules[id])) {
      return this.i18n('desconegut');
    } else {
      return this.tipusMatricules[id];
    }
  }

  generateExcel(): void {
    let filter = this.filter;
    if (this.updatedFilterData != null) {
      filter = this.updatedFilterData;
    }
    this.dialog.open(DownloadExcelDialogComponent, {
      closeOnNavigation: false,
      autoFocus: true,
      data: Builder.newDownloadExcelDialogData (this.matriculesService, filter, this.i18n('HistoricMatricules'), this.sort.direction, this.sort.active)
    });
  }

}
