import { Component, OnInit, Output, Input, EventEmitter, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-form-file-upload',
  templateUrl: './form-file-upload.component.html',
  styleUrls: ['./form-file-upload.component.scss']
})
export class FormFileUploadComponent implements OnInit {

  data: ArrayBuffer;
  size = 0;

  @Input() label: string;
  @Input() labelHidden: boolean;
  @Input() name: string;
  @Output() fileReady: EventEmitter<File> = new EventEmitter<File>();

  @ViewChild('uploadFileInput') input: ElementRef;

  constructor() {
   this.labelHidden = false;
  }

  ngOnInit() {
  }

  upload() {
    this.input.nativeElement.click();
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      const file: File = event.target.files[0];
      this.fileReady.next(file);
      this.name = file.name;
      this.size = file.size;
    }
  }
}
