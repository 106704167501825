import { I18n } from '@ngx-translate/i18n-polyfill';
import { Component, OnInit, Input, Inject, OnDestroy } from '@angular/core';
import { Proposta } from 'app/sections/propostes/models/proposta.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { PropostesService } from 'path-propostes-services/propostes.service';

@Component({
  selector: 'app-proposta-dialog',
  templateUrl: './proposta-dialog.component.html',
  styleUrls: ['./proposta-dialog.component.scss']
})
export class PropostaDialogComponent implements OnInit, OnDestroy {
  recarregar: boolean;
  imagesLoaded: Promise<boolean>;
  constructor(
    private propostesService: PropostesService,
    private thisDialogRef: MatDialogRef<PropostaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public proposta: Proposta,
    private i18n: I18n
  ) {
    this.recarregar = false;
  }

  ngOnInit() {
    if (this.proposta.IMATGES == null || this.proposta.IMATGES === undefined) {
      this.propostesService
        .getImagesByNumDenInstit(this.proposta.NUMDENUNCIA)
        .subscribe(images => {
          this.proposta.IMATGES = images;
          this.imagesLoaded = Promise.resolve(true);
        });
    } else {
      this.imagesLoaded = Promise.resolve(true);
    }
  }

  ngOnDestroy() {
    this.thisDialogRef.close(this.proposta);
  }

  cancel() {
      this.thisDialogRef.close(false);
  }

  update(init) {
    this.thisDialogRef.close(true);
  }



  getTitle() {
    return this.i18n('Veure proposta') + ' ' + this.proposta.NUMDENUNCIA;
  }


}
