import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpSecureClient } from '../../../shared/services/http/httpSecureClient';
import { AuthGuardService } from '../../../shared/services/authentication/auth-guard.service';
import { api } from '../../../shared/configuration/urls';
import { config } from 'path-shared/configuration/config';
import { TiquetsFilter } from '../models/tiquets-filter';
import { TiquetsFilterService } from './tiquets-filter.service';

const defaultSortParam = 'id';
const defaultSortDirection = 'desc';

@Injectable()
export class TiquetsAnulacionesService {
  constructor(
    private http: HttpSecureClient,
    private authGuard: AuthGuardService
  ) {}

  getAnulacionesLeyenda(filter: TiquetsFilter): Observable<any> {
    if (this.authGuard.canActivate()) {
      const start = 0;
      const pageSize = 1000;
      const params = new TiquetsFilterService().createFilterParams(filter, start, pageSize, defaultSortParam, defaultSortDirection);

      const url = config.urls['apiUrl'] + api.endpoints['urlGetAnulacionsLeyenda'];
      return this.http.get(url, params);
    }
  }

  generateExcel(filter: TiquetsFilter, sortDir: string, sortField: string): Observable<any> {
    const start = 0;
    const pageSize = 1000;

    sortField = sortField === undefined ? defaultSortParam : sortField;
    sortDir = sortDir === '' ? defaultSortDirection : sortDir;
    const params = new TiquetsFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

    if (this.authGuard.canActivate()) {
      return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlTiquetsAnulacionesExcel'],  params );
    }
  }
}
