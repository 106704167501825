

<mat-table #table [dataSource]="dataSource" matSort aria-label="Elements"  class="is-header-sticky">

  
    <ng-container matColumnDef="numdenuncia">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Número denuncia</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.numDenuncia}}</mat-cell>
      </ng-container>

    <!-- Matricula Column -->
    <ng-container matColumnDef="exp">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Expediente</mat-header-cell>
      <mat-cell *matCellDef="let row"><a (click)="getExpedients(row.exp, row.numDenuncia)" class="is-link cursor detalle">{{row.exp}}</a></mat-cell>
    </ng-container>
    <ng-container matColumnDef="data">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Fecha</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.data}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="hora">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Hora</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.hora}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="recibo">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Recibo</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.recibo}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="mensaje">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Mensaje</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.mensaje}}</mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
  </mat-table>

