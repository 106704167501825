import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActuacioIncidencia } from '../../models/actuacioIncidencia';
import { ActuacioIncidenciaFilter } from '../../models/actuacioFilter-filter';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { MatDialog, MatDialogRef, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { MaquinasService } from '../../services/maquinas.service';
import { ImagesService } from 'path-shared/services/images/images.service';
import { IncidenciesImagesComponent } from '../incidencies-images/incidencies-images.component';

@Component({
  selector: 'app-actuacions-table',
  templateUrl: './actuacions-table.component.html',
  styleUrls: ['./actuacions-table.component.scss']
})
export class ActuacionsTableComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  incidenciesImagesDialogRef: MatDialogRef<IncidenciesImagesComponent>;
  displayedItems = [];
  displayedColumns = ['somId', 'DataInici', 'DataFi', 'Agent', 'Observacions', 'imatges'];

  images: any = [];
  incidenciaId = null;
  dataSource = null;
  init = true;
  filter = new ActuacioIncidenciaFilter('somId', 'asc');
  
  @Input() get inputIncidenciaId() {
    return this.incidenciaId;
  } set inputIncidenciaId(val) {
    this.incidenciaId = val;
    this.buscar();
  }

  constructor(
    private maquinesService: MaquinasService,
    private imagesService: ImagesService,
    public auth: AuthorizationService,
    private dialog: MatDialog,
    private filterService: FilterService,
    private i18n: I18n,) {  
  }

  ngOnInit() {
  }

  ngOnChanges(changes) {
    this.buscar();
  }

  buscar() {
    if (this.incidenciaId != null) {
      this.maquinesService.getActuacionsIncidencies(this.incidenciaId).subscribe(res => {
        this.dataSource = new MatTableDataSource(res);
        this.dataSource.sort = this.sort;
        this.init = false;
      });
  
      // this.dataSource.subject.subscribe((data) => {
      //   data.forEach(item => {
      //     this.displayedItems[item.somId] = {
      //       id: item.somId
      //     };
      //   });
      // });
    }
  }

  buscarByRow(row) {
    this.maquinesService.getActuacionsIncidencies(row.somId).subscribe(res => {
      this.dataSource = new MatTableDataSource(res);
      this.dataSource.sort = this.sort;
      this.init = false;
    });

    this.dataSource.subject.subscribe((data) => {
      data.forEach(item => {
        this.displayedItems[item.somId] = {
          id: item.somId
        };
      });
    });

    return this.displayedItems;
  }

  viewImages(contenedor) {
    // afegirImatgesWithContenedor
    this.imagesService.getImagesByContenedor(contenedor).subscribe(images => {
      let img=[];
      img.push(images[0]);
      this.images = images;
      this.images.forEach(element => {
        element.CONTENEDOR = contenedor;
      });

      this.incidenciesImagesDialogRef = this.dialog.open(IncidenciesImagesComponent,
      {
        minWidth: '50%',
        maxWidth: '90%',
        maxHeight: '70%',
        closeOnNavigation: false,
        autoFocus: true,
        data: {'ficheros': images, 'contenedor': contenedor, origen: 'INCI_ACTU'},
      });
      this.incidenciesImagesDialogRef.componentInstance.somId = contenedor;

      this.incidenciesImagesDialogRef.afterClosed().subscribe(() => {
        this.imagesService.getImagesByContenedor(contenedor).subscribe( newImages => {
          this.dataSource.getData().find(x => x.somId === contenedor).imatges = newImages.length;
          this.images = newImages;
          this.images.forEach(element => {
            element.CONTENEDOR = contenedor;
          });
        });
      });
    });
  }
}
