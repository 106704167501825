import { GestioResidentsService } from './../../services/gestio-residents.service';
import { BonosResidentesTableComponent } from './../bonos-residentes-table/bonos-residentes-table.component';
import { GalleryComponent } from './../../../../../shared/components/gallery/gallery/gallery.component';
import { Component, OnInit, AfterViewInit, OnChanges, ViewChild, Input, EventEmitter, Output, SimpleChanges, ElementRef, Inject } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { ExcelDownloadService } from 'path-shared/services/excel/excel-download.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { merge, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Builder } from 'path-shared/models/builder';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { Reflection } from 'path-shared/services/reflection/reflection';
import { SingleEditDialogData } from 'path-shared/components/dialogs/single-edit-dialog/single-edit-dialog-data.model';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { DisplayTextDialogComponent } from 'path-shared/components/dialogs/display-text-dialog/display-text-dialog.component';
import { XmlTreeViewerComponent } from 'app/sections/utilitats/dgt/components/xml-tree-viewer/xml-tree-viewer.component';
import { ToastrService } from 'ngx-toastr';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { Router } from '@angular/router';
import { BonosResidentesFilter } from '../../models/bonos-residentes-filter';
import { PermissionDialogComponent } from 'path-shared/components/permission-dialog/permission-dialog.component';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { BonosResidentsFilterComponent } from '../bonos-residents-filter/bonos-residents-filter.component';

@Component({
  selector: 'app-gestio-residents-table',
  templateUrl: './gestio-residents.component.html',
  styleUrls: ['./gestio-residents.component.scss']
})
export class GestioResidentsComponent implements OnInit, AfterViewInit {
  permissionDialogRef: MatDialogRef<PermissionDialogComponent>;
  filterData: BonosResidentesFilter;
  dataDialog: ComponentDialog = Builder.newComponentDialog();
  @ViewChild(BonosResidentesTableComponent) bonosResidentesTableComponent: BonosResidentesTableComponent;

  constructor(
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private router: Router,
    private gestioResidentsService: GestioResidentsService,
    private i18n: I18n,
    private toastr: ToastrService,
    public thisDialogRef: MatDialogRef<GestioResidentsComponent>,
    private not: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
    ) {
      this.filterData = new BonosResidentesFilter('', '');
      if (data !== undefined) {
        this.dataDialog = data;
      }
    }

  ngOnInit() {
    this.auth.UserCanSeePage(this.auth.Pages.TipusMatricules);

  }

  ngAfterViewInit(): void {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Bonos residentes'));
    }
  }

  refresh() {
    this.bonosResidentesTableComponent.load();
  }

  onFilterClicked() {
    const ref = this.dialog.open(BonosResidentsFilterComponent, {
      width: '50%',
      height: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.bonosResidentesTableComponent.filter
      }
    });

    ref.componentInstance.notify.subscribe((filter: BonosResidentesFilter) => {
      this.bonosResidentesTableComponent.filter.set(filter);
      this.bonosResidentesTableComponent.load();
    });
  }


  generateExcel() {
    this.bonosResidentesTableComponent.generateExcel();
  }

}
