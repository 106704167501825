
<div class="row" style="width: 100%;">
  <div class="col-xl-12" style="padding-left: 15px; overflow-y: scroll;overflow-x: auto;height: 500px;">

    <mat-table #table [dataSource]="this.dataSource" aria-label="Elements" class=" table" style="width: 100%;">
                  
      <ng-container matColumnDef="expedient">
        <mat-header-cell *matHeaderCellDef>Expediente</mat-header-cell>                  
        <mat-cell class="cCell" *matCellDef="let element"> {{element.expedient}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="data">
        <mat-header-cell *matHeaderCellDef>Fecha</mat-header-cell>                  
        <mat-cell class="cCell" *matCellDef="let element"> {{element.data | shortDate }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="hora">
        <mat-header-cell *matHeaderCellDef>Hora</mat-header-cell>                  
        <mat-cell class="cCell" *matCellDef="let element"> {{element.hora}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="import">
        <mat-header-cell *matHeaderCellDef>Importe</mat-header-cell>                  
        <mat-cell class="cCell" *matCellDef="let element"> {{element.import}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="lloc">
        <mat-header-cell *matHeaderCellDef>Lugar</mat-header-cell>                  
        <mat-cell class="cCell" *matCellDef="let element"> {{element.lloc}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="llei">
        <mat-header-cell *matHeaderCellDef>Ley</mat-header-cell>                  
        <mat-cell class="cCell" *matCellDef="let element"> {{element.llei}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="article">
        <mat-header-cell *matHeaderCellDef>Articulo</mat-header-cell>                  
        <mat-cell class="cCell" *matCellDef="let element"> {{element.article}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="tipusAnuncio">
        <mat-header-cell *matHeaderCellDef>Anuncio</mat-header-cell>                  
        <mat-cell class="cCell" *matCellDef="let element"> {{this.getTipolabel(element.tipoAnuncio)}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="infraccio">
        <mat-header-cell *matHeaderCellDef>Infracción</mat-header-cell>                  
        <mat-cell class="cCell" *matCellDef="let element"> {{element.infraccio}} </mat-cell>
      </ng-container>


      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" ></mat-header-row>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </mat-table>
    </div>
  </div>