<app-toolbar>
  <app-tool-button text="Configuración" i18n-text (click)="openConfiguration();" color="has-color-grey" icon="fa-cog" [disabled]="!this.auth.IsSuperUser()"></app-tool-button>
  <app-tool-button text="Històric" i18n-text (click)="irHistorico();" color="has-color-orange" icon="fa-history" ></app-tool-button>
  <app-tool-button text="Exportar" i18n-text (click)="exportarDenuncias();" color="has-color-green" icon="fa-file-export" [disabled]="( filterConfiguration.matricula &&  matriculaSelected.length !==1)||!selectCheckedItems() || !this.auth.UserCanWrite(this.auth.Pages.Tramitador)"></app-tool-button>
  <app-tool-button text="Impressió" i18n-text (click)="this.impressio();" color="has-color-blue" icon="fa-print" *ngIf="reports.length > 0" [disabled]="!selectCheckedItems() || !this.auth.UserCanWrite(this.auth.Pages.Tramitador)"></app-tool-button>
  <app-tool-button-permissions [permission]="this.auth.Pages.Tramitador"></app-tool-button-permissions>
</app-toolbar>


<div class="row options d-flex">
  <div class="p-1">
    <app-form-date-edit id="tramitador-desde" i18n-label label="Desde" [(value)]="filter.fechaInicio" type="calendar"></app-form-date-edit>
  </div>
  <div class="p-1">
    <app-form-date-edit id="tramitador-hasta" i18n-label label="Fins" [(value)]="filter.fechaFin" type="calendar"></app-form-date-edit>
  </div>
  <div *ngIf="filterConfiguration.tipo" class="col-2" style="max-width: 20vh;">
    <ng-label i18n>Tipo de denuncia</ng-label>
    <app-form-multi-select  [data]="this.tiposDenuncias" [(selectedValues)]="this.filter.tipos" i18n-tituloMultiSelect tituloMultiSelect="Tipo"></app-form-multi-select>
  </div>
  <div *ngIf="filterConfiguration.legislacion" class="col-2" style="max-width: 20vh;">
    <ng-label>Legislación</ng-label>
    <app-form-multi-select  [data]="this.legislaciones" [(selectedValues)]="this.filter.legislaciones" i18n-tituloMultiSelect tituloMultiSelect="Legislación"></app-form-multi-select>
  </div>
  <div *ngIf="filterConfiguration.origen" class="col-2" style="max-width: 20vh;" >
    <ng-label>Origen</ng-label>
    <app-form-multi-select [data]="this.origenes" [(selectedValues)]="this.origenSelected" i18n-tituloMultiSelect tituloMultiSelect="Origen"></app-form-multi-select>
    <!-- <app-form-combo-edit [disableInput]="true" label="Origen" i18n-label [(value)]="this.filter.origen" [items]="origenes" valueField="codi" textField="descripcio"></app-form-combo-edit> -->
  </div>
  <div *ngIf="filterConfiguration.matricula" class="col-2" style="max-width: 20vh;">
    <ng-label>Tipo de matrícula</ng-label>
    <app-form-multi-select [data]="this.matriculas" [(selectedValues)]="this.matriculaSelected" i18n-tituloMultiSelect tituloMultiSelect="Matrículas"></app-form-multi-select>
    <!-- <app-form-tri-state  i18n-label i18n-labelSi labelSi="Extrangeras" i18n-labelNo labelNo="Españolas" i18n-labelTodos labelTodos="Totes" [(value)]="filter.matriculasExtrangeras"></app-form-tri-state> -->
  </div>
  <div *ngIf="filterConfiguration.fotos" class="col-2" style="max-width: 20vh;">
    <ng-label>Incluir Imágenes</ng-label>
    <ng-multiselect-dropdown [data]="this.fotos" (onSelect)="this.onItemSelect($event)" [settings]="this.dropdownSettings" [placeholder]="'Incluir fotos'"></ng-multiselect-dropdown>
  </div>
  
  <div class="p-1">
    <button class="btn btn-primary" style="vertical-align: bottom;" (click)="loadTramitadorPage()" i18n>Consultar</button>
  </div>
  <div class="p-1">
      <span i18n>Total Denuncies</span>: {{this.paginator.length}}
      <br><span i18n>Total Imatges</span>: {{this.informacioTramitador.countImages}}
  </div>
  <div class="p-1">
      <span i18n>Seleccionades</span>: {{ this.selectCheckedItems() }}
  </div>
  <div class="col-3" style="margin-top: -3vh;">
    <app-progress-bar #progressBar class="col-12" pantalla="Tramitador"></app-progress-bar>
  </div>
</div>

<mat-paginator #paginator hidden="true"></mat-paginator>

<div style="height:72vh" class="table-sdw is-fixed">
  <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" matSortActive="data" matSortDirection="asc" class="is-header-sticky">

    <!-- Check Column -->
    <ng-container matColumnDef="Check">
      <mat-header-cell *matHeaderCellDef class="is-checkbox">
          <mat-checkbox #allCheck (change)="toggleAllCheckbox($event)"></mat-checkbox>
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <mat-checkbox (change)="toggleItem(row.Som_Id)" [checked]="displayedItems[row.Som_Id].checked"></mat-checkbox>
      </mat-cell>
    </ng-container>

    <!-- Id Column -->
    <ng-container matColumnDef="Exp">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Expedient</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.Exp}}</mat-cell>
    </ng-container>

    <!-- Estats Column -->
    <ng-container matColumnDef="NumDenuncia">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Núm. Denúncia</mat-header-cell>
      <mat-cell *matCellDef="let row"><a (click)="this.veureDenuncia(row.Som_Id)" class="is-link">{{row.NumDenuncia}}</a></mat-cell>
    </ng-container>

    <!-- Tipo Column -->
    <ng-container matColumnDef="tipus">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Tipo</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.tipus}}</mat-cell>
    </ng-container>

    <!-- Matricula Column -->
    <ng-container matColumnDef="Fecha">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.Fecha | shortDate}}</mat-cell>
    </ng-container>

    <!-- Expedient Column -->
    <ng-container matColumnDef="Hora">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Hora</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.Hora}}</mat-cell>
    </ng-container>

    <!-- Bulleti Column -->
    <ng-container matColumnDef="Import">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Import</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.Import}}</mat-cell>
    </ng-container>

    <!-- Data Column -->
    <ng-container matColumnDef="Matricula">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Matrícula</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.Matricula}}</mat-cell>
    </ng-container>

    <!-- Hora Column -->
    <ng-container matColumnDef="Response">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Resultat</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.Response}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="estat">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Estat</mat-header-cell>
      <mat-cell *matCellDef="let row"><app-form-live-state [state]="row.estat"></app-form-live-state></mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
  </mat-table>
</div>
