import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormService } from 'path-shared/services/form/form.service';
import { ToastrService } from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { UsuarisService } from '../../services/usuaris.service';
import { Usuari } from '../../models/usuari.model';

@Component({
  selector: 'app-2fa',
  templateUrl: './2fa.component.html',
  styleUrls: ['./2fa.component.scss']
})
export class TwoFactorAuthenticationComponent implements OnInit {


  usuari: Usuari;
  secret: string;
  otp: string;
  qr: string = null;

  @ViewChild('mainForm') private mainForm: ElementRef;
  constructor(
    private formService: FormService,
    private toastr: ToastrService,
    private i18n: I18n,
    private userService: UsuarisService
  ) {
      this.usuari = userService.getCurrentUser();
  }

  ngOnInit() {
    if (this.usuari.twoFactorAuthentication) {
      this.userService.Get2faSecret().subscribe(res => {
        this.secret = res.secret;
        this.qr = res.secretQR;

      });
    }
  }

  // digestMessage(message) {
  //   // const msgUint8 = new TextEncoder().encode(message);                           // encode as (utf-8) Uint8Array
  //   // const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);           // hash the message
  //   // const hashArray = Array.from(new Uint8Array(hashBuffer));                     // convert buffer to byte array
  //   // const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
  //   const hashHex = btoa(message);
  //   return hashHex;
  // }


  EnableTwoFactorAuthentication() {
    this.userService.SetEnabled2FA(true).subscribe(() => {
      this.userService.Get2faSecret().subscribe(res => {
        this.secret = res.secret;
        this.qr = res.secretQR;
        this.usuari.twoFactorAuthentication = true;
        this.userService.setCurrentUser(this.usuari);
        this.toastr.info(this.i18n('Autentificació de doble factor activada'));

      });
    });
  }

  DisableTwoFactorAuthentication() {
    this.userService.SetEnabled2FA(false).subscribe(() => {
      this.usuari.twoFactorAuthentication = false;
      this.userService.setCurrentUser(this.usuari);
      this.toastr.info(this.i18n('Autentificació de doble factor desactivada'));
    });
  }
}
