import { Component, OnInit, ViewChild, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { DenunciesEditorComponent } from 'app/sections/denuncies/components/denuncies-editor/denuncies-editor.component';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Pendents } from '../../../models/pendents.models';
import { Builder } from 'path-shared/models/builder';
import { TramitarService } from '../../../services/tramitar.service';
import { PendentsFilter } from '../../../models/pendents-filter.models';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { PendentsFilterService } from '../../../services/pendents-Filter.service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-tablependents',
  templateUrl: './tablependents.component.html',
  styleUrls: ['./tablependents.component.scss']
})
export class TablependentsComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
    data: Pendents[];
    dataSource: CustomDataSource<Pendents, PendentsFilter>;
    displayedItems = [];
    highlightedRows = [];
    filter: PendentsFilter = new PendentsFilter('EXP', 'asc');
    @Output() notify: EventEmitter<number> = new EventEmitter<number>();
    /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
    displayedColumns = ['EXP', 'Matricula', 'Nom', 'Fecha_Caducidad', 'DataNoti', 'DataNotiPropuesta', 'DataNotiReso', 'DataNotiReposicio'];

  constructor(private dialog: MatDialog,
              private tramitarService: TramitarService,
              private filterService: FilterService) {
  }

  ngOnInit() {
    this.loadInici();
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(tap(() => this.filter.updated()))
    .subscribe();

    this.load();
  }

  load() {
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }

  loadInici() {
    this.dataSource = CustomDataSource.create<Pendents, PendentsFilter>(this.paginator, new PendentsFilterService(), 'urlPendents', 'urlInfoPendents');
    
    this.dataSource.subject.subscribe((data) => {            
      data.forEach(item => {
        this.displayedItems[item.EXP] = {
          id: item.EXP
        };
      });
    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
        // if (!this.selectButton) { this.filterService.saveFilter('tipus-vehicles-filter', this.filter); }
      }
    });
  }

  onRowClicked(row: Pendents) {
      const data: ComponentDialog = Builder.newComponentDialog(true, true, row.SomIdDenuncia);
        const ref = this.dialog.open(DenunciesEditorComponent, {
          width: '80%',
          height: '80%',
          maxHeight: '100vh',
          closeOnNavigation: false,
          autoFocus: true,
          data: data
        });
}

isOld(date): boolean {
    if (date === undefined || date === null) { return false; }
    const today = new Date();
    const fecha = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();
    return date < fecha;
}

isOldDataNoti(row): boolean {
  if (row === undefined || row === null) { return false; }
  if (row.DataNoti !== undefined && row.DataNoti !== null && row.DataNoti !== '') {
      if ((row.AcusNoti === undefined || row.AcusNoti === null) && (row.RechNoti === undefined || row.RechNoti === null)) { return true; }
  } else {
    return false;
  }
}

isOldDataNotiPropuesta(row): boolean {
  if (row === undefined || row === null) { return false; }
  if (row.DataNotiPropuesta !== undefined && row.DataNotiPropuesta !== null && row.DataNotiPropuesta !== '') {
      if ((row.AcusNotiPropuesta === undefined || row.AcusNotiPropuesta === null) && (row.RechNotiPropuesta === undefined || row.RechNotiPropuesta === null)) { return true; }
  } else {
    return false;
  }
}

isOldDataNotiReso(row): boolean {
  if (row === undefined || row === null) { return false; }
  if (row.DataNotiReso !== undefined && row.DataNotiReso !== null && row.DataNotiReso !== '') {
      if ((row.AcusNotiReso === undefined || row.AcusNotiReso === null) && (row.RechNotiReso === undefined || row.RechNotiReso === null)) { return true; }
  } else {
    return false;
  }
}

isOldDataNotiReposicio(row): boolean {
  if (row === undefined || row === null) { return false; }
  if (row.DataNotiReposicio !== undefined && row.DataNotiReposicio !== null && row.DataNotiReposicio !== '') {
      if ((row.AcusNotiReposicio === undefined || row.AcusNotiReposicio === null) && (row.RechNotiReposicio === undefined || row.RechNotiReposicio === null)) { return true; }
  } else {
    return false;
  }
}

}
