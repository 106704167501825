import { Component, OnInit, Input, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { SOM_FICHEROS } from 'path-models/SOM_FICHEROS';
import { DragScrollComponent } from 'ngx-drag-scroll/lib';
import { ComponentDialog } from '../../../../../shared/models/component-dialog.model';
import { Proposta } from 'app/sections/propostes/models/proposta.model';
import { Instituciones } from 'path-shared/models/instituciones';
import { ParametersService } from 'path-shared/services/parameters/parameters.service';
import { MatDialogRef } from '@angular/material';
import { ImagesService } from 'path-shared/services/images/images.service';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { PropostaEstats } from 'app/sections/propostes/models/proposta-estats.model';

@Component({
  selector: 'app-photo-box-gallery',
  templateUrl: './photo-box-gallery.component.html',
  styleUrls: ['./photo-box-gallery.component.scss']
})
export class PhotoBoxGalleryComponent implements OnInit, OnDestroy {
  @Input() numDenuncia: string;
  @Input() imatges: SOM_FICHEROS[];
  @Input() height: string;
  @Input() proposta: Proposta;
  @Input() botoMatricula: boolean;
  @Input() allowSelect: boolean;
  @Input() magnifierRight: number;
  @Input() magnifierTop: number;
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();
  @Output() update: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() showLupa: boolean = false;
  selectedImages = [];
  @ViewChild('dragScroll', { read: DragScrollComponent }) ds: DragScrollComponent;

  leftNavDisabled = false;
  rightNavDisabled = false;

  dialogData: ComponentDialog;
  validarFotosProposta;
  enviarFotosDenuncia;
  imageDiv: string;
  drawList: any[] = [];
  hasImages = null;
  minTimeLoad = false;

  constructor(
    private parametersService: ParametersService,
    public thisDialogRef: MatDialogRef<PhotoBoxGalleryComponent>,
    private imagesService: ImagesService,
    private i18n: I18n,
    private confirmationDialogService: ConfirmationDialogService,

  ) {
    const inst = sessionStorage.getItem('currentInst');
    if (inst !== '' && inst !== undefined && inst !== 'undefined') {
      const institution: Instituciones = JSON.parse(inst);
      this.validarFotosProposta = institution.ValidarFotosProposta;
      this.enviarFotosDenuncia = this.parametersService.getParameter('PASAR_FOTOS_DENUNCIA') === 'Y';
    }
  }

  ngOnInit() {
    if (this.imatges !== undefined && this.imatges !== null) {
      this.hasImages = true;
      let i = 0;
      this.imatges.forEach(imatge => {
        //   if(i===0){
        if (this.enviarFotosDenuncia && !this.validarFotosProposta && this.proposta !== undefined) {
          this.selectedImages.push({ imatge, checked: true, validat: false });

        } else {
          this.selectedImages.push({ imatge, checked: false, validat: false });
        }
        // }
        // i=i+1;
      });
    } else {
      this.hasImages = false;
    }

    setTimeout(() => {
      this.minTimeLoad = true;
    },
      5000);
  }

  loadImages(images: any) {
    this.selectedImages = [];
    images.forEach(imatge => {
      this.selectedImages.push({ imatge, checked: false, validat: false });
    });
  }

  moveLeft() {
    if (this.ds !== undefined && this.imatges.length > 0) {
      this.ds.moveLeft();
    }
  }

  moveRight() {
    if (this.ds !== undefined && this.imatges.length > 0) {
      this.ds.moveRight();
    }
  }

  leftBoundStat(reachesLeftBound: boolean) {
    this.leftNavDisabled = reachesLeftBound;
  }

  rightBoundStat(reachesRightBound: boolean) {
    this.rightNavDisabled = reachesRightBound;
  }

  getScrollStyle() {
    const styles = {
      'min-height': this.height ? (this.height + 'px') : '300px'
    };
    return styles;
  }

  Toggle(element: any) {
    this.notify.emit(this.selectedImages.filter(x => x.checked === true).length);
  }

  updateImatges(imatges: SOM_FICHEROS[]) {
    this.selectedImages = [];
    imatges.forEach(imatge => {
      this.selectedImages.push({ imatge, checked: true });
    });
    if (imatges.length > 0) {
      this.hasImages = true;
    }
  }
  updated(data) {
    this.selectedImages = Object.assign([], data);
  }

  ngOnDestroy() {
    if (this.thisDialogRef.constructor.name === 'MatDialogRef') {
      this.thisDialogRef.close();
    }
  }

  getImageDiv(imatgeSomId: string): string {
    return 'imageDiv_' + imatgeSomId;
  }

  removeImage(element) {
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol eliminar la imatge?'))
      .then((confirmed) => {
        if (confirmed) {
          this.imagesService.deleteImage(element.imatge.SECURE_ID).subscribe(() => {
            const index = this.selectedImages.findIndex(x => x.imatge.SECURE_ID === element.imatge.SECURE_ID);
            this.imatges.splice(index, 1);
            this.selectedImages.splice(index, 1);
          });
        }
      });


  }

  pendentValidacio() {
    if (this.proposta === undefined || this.proposta === null) {
      return false;
    } else {
      return this.proposta.proposta === PropostaEstats.PENDENT_VALIDACIO;
    }
  }

  OCR(element) {
    this.confirmationDialogService.confirm(this.i18n('Confirme'),
      this.i18n('Aplicando esta acción, la matrícula de la propuesta será rellenada autómaticamente por el sistema de reconocimiento óptico de carácteres. Desea Continuar?')).then(confirm => {
        if (confirm) {
          this.imagesService.pasarOCR(this.proposta.idAparell, element.imatge.SOM_ID).subscribe(res => {
            this.update.emit(true);
          });
        }
      });
  }
  usarLupaChange(e: any) {
    this.showLupa = !this.showLupa;
  }
}

