import { TramitadorService } from 'path-denuncies-services/tramitador.service';
import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';
import { FormMultiSelect } from 'path-shared/components/form/form-multi-select/model/form-multi-select.model';

enum Origenes{
  Radar,
  Otros,
  Todos
};
export class TramitadorFilter extends FilterData {
  fechaInicio: Date;
  fechaFin: Date;
  tipos: Array<FormMultiSelect>;
  legislaciones: Array<FormMultiSelect>;
  origen: Origenes;
  matriculasExtrangeras: boolean;
  fotos: boolean;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.tipos = new Array<FormMultiSelect>();
    this.legislaciones = new Array<FormMultiSelect>();
    this.origen = Origenes.Todos;
    const date = new Date();
    const dateCurrentDay = new Date(date.getTime());

    const datePastWeek = new Date(date.getTime());
    datePastWeek.setDate(date.getDate() - 7);

    this.fechaInicio = datePastWeek;
    this.fechaFin = dateCurrentDay;
    this.matriculasExtrangeras= null;
    this.fotos = null;
  }
}
