import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class AcuseFilter extends FilterData {

    // Basic info
    expedient: number
    tipus: string
    resultat: number
    dataInici: Date
    dataFinal: Date
    nomComplet: string
    numDenuncia: string

    constructor(sortField: string, sortDir: SortDirection) {
      super(sortField, sortDir);
        this.expedient = null;
        this.tipus = ''
        this.resultat = 0
        this.dataInici = null
        this.dataFinal = null
        this.nomComplet = ''
        this.numDenuncia = ''

        this.labels['expedient'] = this.i18n('Expedient');
        this.labels['tipus'] = this.i18n('Tipus');
        this.labels['resultat'] = this.i18n('Resultat');
        this.labels['dataInici'] = this.i18n('DataInici');
        this.labels['dataFinal'] = this.i18n('DataFinal');
        this.labels['nomComplet'] = this.i18n('NomComplet');  
        this.labels['numDenuncia'] = this.i18n('NumDenuncia');     
    }
}