import { Component, OnInit, Inject, ElementRef, ViewChild, OnDestroy, ViewChildren, AfterViewInit } from '@angular/core';
import { Terminal } from '../models/terminal.model';
import { ComunicacioPdaService } from '../services/comunicacio-pda.service';
import { TerminalsMissatge } from '../models/terminals-missatge.model';
import { ToastrService } from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { FileDownloader } from 'path-shared/services/http/fileDownloader';
import { Subscription } from 'rxjs';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { Tab } from 'path-shared/components/form/form-tab-chat-dynamic/model/tab.model';
import { FormTabChatDynamicComponent } from 'path-shared/components/form/form-tab-chat-dynamic/form-tab-chat-dynamic.component';
import { Builder } from 'path-shared/models/builder';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { FormFileMultipleUploadComponent } from 'path-shared/components/form/form-file-multiple-upload/form-file-multiple-upload.component';

@Component({
    selector: 'app-comunicacio-pda-page',
    templateUrl: './comunicacio-pda-page.component.html',
    styleUrls: ['./comunicacio-pda-page.component.scss']
  })

  export class ComunicacioPdaComponent implements OnInit, OnDestroy, AfterViewInit {

    terminalsData: Array<Terminal>;
    terminalsMissatgeData: TerminalsMissatge;
    displayedColumns = ['check', 'codiPda'];
    highlightedRows = [];
    displayedItems = [];
    tabsList: Array<Tab>;
    label: string;

    destinataris: string;
    cosMissatge: string;

    private files: Set<File> = new Set();

    dataDialog: ComponentDialog = Builder.newComponentDialog();
    @ViewChild(FormTabChatDynamicComponent) formTabChatDynamicComponent: FormTabChatDynamicComponent;
    @ViewChild(FormFileMultipleUploadComponent) fitxer: FormFileMultipleUploadComponent;

    private pdasPendentsLlegirSubscription: Subscription;

    constructor(
      private comunicacioPdaService: ComunicacioPdaService,
      private not: NotificationService,
      private toastr: ToastrService,
      private i18n: I18n,
      public auth: AuthorizationService
    ) {
      this.tabsList = new Array<Tab>();
      this.displayedItems = [];
      this.displayedItems['checkedItems'] = new Array<number>();
      this.displayedItems['checkedValues'] = 0;
      this.label = 'PDA';
    }

    ngOnInit() {
      this.getPdas();
      this.notificacioPdasPendentsLlegir();

    }

    ngAfterViewInit() {
      if (!this.dataDialog.isDialog) {
        this.not.titleChange(this.i18n('Missatgeria'));
      }
    }

    ngOnDestroy(): void {
      this.pdasPendentsLlegirSubscription.unsubscribe(); // Notificacions
    }

    rowSelected(event: any) {
      if (event.checked) {
        alert('rowSelected');
      }
    }

    fileReady(files) {
      this.files = files;
    }

    enviar(event: any) {
      const terminalsChatEnviar: string[] = [];
      this.displayedItems['checkedItems'].forEach(element => {
        terminalsChatEnviar.push(element.toString());
      });

      if (terminalsChatEnviar.length !== 0) {
        // Per la llista d'elements checked, enviar cosMissatge per guardar-lo
        this.terminalsMissatgeData = new TerminalsMissatge(terminalsChatEnviar, this.cosMissatge);
        // El back guarda el nou missatge un cop guardat
        this.comunicacioPdaService.setMissatgeTerminals(this.terminalsMissatgeData).subscribe(chatsIdCreat => {
          // Netegem el cos del missatge
          this.cosMissatge = '';
          // Si tot va bé, guardem el fitxer associat
          this.processarFitxers(chatsIdCreat, terminalsChatEnviar);

          this.fitxer.fileInfo = '';
          this.files = new Set();
        });
      } else {
        this.toastr.info(this.i18n('Selecciona mínim una Pda a la que enviar el missatge'));
      }
    }

    getPdas() {
      // Ens recuperem les pda's de la institució
      this.comunicacioPdaService.getAllTerminals().subscribe(terminals => {
        this.terminalsData = terminals;
        if (terminals !== null) {
          terminals.forEach(item => {
            this.displayedItems[item.idTerminal] = {
              id : item.idTerminal,
              tabName: item.nameTerminal,
              checked: false
            };
          });
          const pdasPendentsLlegir = this.terminalsData.filter(f => f.pendentsLlegir > 0);
          pdasPendentsLlegir.forEach(t => {
            this.obrirPestanya(t.idTerminal);
          });
        }
      });
    }

    toggleAllCheckbox() {
      for (const item in this.displayedItems) {
        if (item !== 'checkedItems' && item !== 'checkedValues') {
          this.toggleItem(item, true);
        }
      }
    }

    getCheckedValues() {
      return this.displayedItems['checkedValues'];
    }

    obrirPestanya(id: any) {
      // Actualitzem la llista de tabs (per si s'ha tancat algún)
      const terminalsChatOpen = this.formTabChatDynamicComponent.getTabsId();
      const tabExists = terminalsChatOpen.indexOf(id);
      if (tabExists === -1) {
        terminalsChatOpen.push(this.displayedItems[id].id);
        this.getTerminalsChat(new Array<string>(id));
      }

    }

    toggleItem(id: any, toggleAll?: boolean) {
      if (!this.displayedItems[id].checked) {
        this.displayedItems['checkedItems'].push(id);
        this.displayedItems['checkedValues'] = this.getCheckedValues() + 1;
        this.displayedItems[id].checked = true;
        // this.obrirPestanya(id);

        if ((toggleAll === undefined || toggleAll === false) && this.displayedItems['checkedItems'].length >= 1) {
          this.obrirPestanya(id);
        }
      } else {
        const index = this.displayedItems['checkedItems'].indexOf(id);
        if (index >= 0) {
          this.displayedItems['checkedItems'].splice(index, 1);
        }

        this.displayedItems['checkedValues'] = this.getCheckedValues() - 1;
        this.displayedItems[id].checked = false;
      }
    }

    getTerminalsChat(terminalsChatOpen: Array<string>) {
      this.comunicacioPdaService.getTerminalsChat(terminalsChatOpen).subscribe(result => {
        // retornem el contingut dels tabs demanats a la llista de terminalsChatOpen
        result.forEach(chatTerminal => {
          const index = this.tabsList.findIndex( x => x.id === chatTerminal.id );
          if ( index === -1 ) {
            this.tabsList.push(chatTerminal);
          }
        });
        this.formTabChatDynamicComponent.selectLastTab();
        this.setMissatgesTerminalsLlegits(terminalsChatOpen);
      });
    }

    recuperaMissatgesAntics(tabActual: Tab) {
      const idTabActual = new Array<string>(tabActual.id);
      const ultimIdChat = tabActual.contingut[tabActual.contingut.length - 1].idContingut;
      this.comunicacioPdaService.getTerminalsChat(idTabActual, ultimIdChat.toString()).subscribe(result => {
        // Només ens retornarà 1 tab (el que li hem demanat per recuperar més missatges enrere)
        // la llista de continguts que ens torna, la hem d'afegir al que ja teniem
        const tabActualitzar: Tab = result[0];
        const tabActualIndex = this.tabsList.findIndex( x => x.id === tabActualitzar.id );

        if (tabActualitzar.contingut.length === 0) {
          this.toastr.info(this.i18n('No hi ha més missatges per mostrar'));
        } else {
          tabActualitzar.contingut.forEach( element => {
            this.tabsList[tabActualIndex].contingut.push(element);
          });
        }
      });
    }

    recuperaMissatgesNous(tabActual: Tab) {
      this.comunicacioPdaService.getTerminalChatMissatgesNous(tabActual.id).subscribe(result => {
        const tabActualitzar: Tab = result;
        const tabActualIndex = this.tabsList.findIndex( x => x.id === tabActualitzar.id );

        tabActualitzar.contingut.forEach(element => {
          this.tabsList[tabActualIndex].contingut.unshift(element);
        });

        this.setMissatgesTerminalsLlegits(new Array(tabActual.id));
      });
    }

    processarFitxers(chatsIdCreat: number, terminalsChatEnviar: string[]) {
        const formData: FormData = new FormData();

        if (this.files.size > 0) {
          this.files.forEach(file => {
            formData.append('file', file, file.name);
          });

          formData.append('chatsIdCreat', chatsIdCreat.toString());

          this.comunicacioPdaService.uploadFitxers(formData).subscribe(result => {
            this.toastr.info(this.i18n('Fitxers carregats correctament'));
            this.repintarContingutTabs(terminalsChatEnviar);
          });
        } else {
          this.repintarContingutTabs(terminalsChatEnviar);
        }
    }

    getFitxer(idFitxer: number) {

      this.comunicacioPdaService.getFitxer(idFitxer).subscribe(file => {
        const name: number = Date.now();
        const contentType = file.headers.get('Content-Type');
        if (contentType !== null && contentType !== undefined) {
            FileDownloader.downLoadFile([file.body],  contentType + ';charset=utf-8', name.toString());
        }
      });
    }

    repintarContingutTabs(terminalsChatEnviar: string[]) {
      // Tornem a demanar els continguts del chat dels diferents terminals per repintar
      this.comunicacioPdaService.getTerminalsChat(terminalsChatEnviar).subscribe( result => {
        const tabs: Array<Tab> = result;
        tabs.forEach(chatTerminal => {
          // Si no tenim la pestanya -> La obrim
          // Si ja la tenim -> actualitzem el seu contingut
          const indexTab = this.tabsList.findIndex( x => x.id === chatTerminal.id );
          if (indexTab === -1) {
            this.tabsList.push(chatTerminal);
          } else {
            this.tabsList[indexTab].contingut = Object.assign(chatTerminal.contingut);
          }
        });
        this.formTabChatDynamicComponent.selectLastTab();

        this.setMissatgesTerminalsLlegits(terminalsChatEnviar);
      });
    }

    setMissatgesTerminalsLlegits(terminals: string[]) {
      this.comunicacioPdaService.setMissatgesTerminalsLlegits(terminals).subscribe( () => {
        terminals.forEach(terminal => {
          const index = this.terminalsData.findIndex(x => x.idTerminal === terminal);
          this.terminalsData[index].pendentsLlegir = 0;
        });
        this.actualitzaBotoTabIdMissatgesNous(terminals);
      });
    }

    notificacioPdasPendentsLlegir() {
      this.pdasPendentsLlegirSubscription = this.not.onPdasPendentsLlegirChange.subscribe(result => {
        const pdasPendentsLlegir: Array<Terminal> = JSON.parse(result.data);
        let hiHaCanvis = false;
        let i = 0;
        if (pdasPendentsLlegir.length > 0) {
          do {
            if (pdasPendentsLlegir[i].idTerminal !== this.terminalsData[i].idTerminal) {
                  hiHaCanvis = true;
            } else {
              if (pdasPendentsLlegir[i].pendentsLlegir !== this.terminalsData[i].pendentsLlegir) {
                hiHaCanvis = true;
              }
            }

            i++;
          } while (!hiHaCanvis && i < pdasPendentsLlegir.length);
        }

        if (this.terminalsData !== undefined && this.terminalsData !== null
            && !hiHaCanvis && i < this.terminalsData.length && this.terminalsData[i].pendentsLlegir > 0) {
          hiHaCanvis = true;
        }

        if (hiHaCanvis) {
          this.getPdas();
          this.actualitzaBotoTabMissatgesNous(pdasPendentsLlegir);
        }
      });
    }

    actualitzaBotoTabMissatgesNous(pdasPendentsLlegir: Array<Terminal>) {
      // Per les pestanyes que tenim obertes, mirar si s'ha d'activar el botó de "recuperar missatges nous" del component tab-chat
      const terminalsChatOpen = this.formTabChatDynamicComponent.getTabs();
      terminalsChatOpen.forEach(terminalChatOpen => {
        const index = pdasPendentsLlegir.findIndex(x => x.idTerminal === terminalChatOpen.id);
        if (index !== -1) {
          terminalChatOpen.pendentsLlegir = pdasPendentsLlegir[index].pendentsLlegir;
        } else {
          terminalChatOpen.pendentsLlegir = 0;
        }
      });
    }

    actualitzaBotoTabIdMissatgesNous(pdasIdLlegits: Array<string>) {
      // Per les pestanyes que tenim obertes, mirar si s'ha d'activar el botó de "recuperar missatges nous" del component tab-chat
      const terminalsChatOpen = this.formTabChatDynamicComponent.getTabs();
      pdasIdLlegits.forEach(pdaIdLlegit => {
        // Actualitza el botó de recupera nous del tab
        const indexTab = terminalsChatOpen.findIndex(x => x.id === pdaIdLlegit);
        if (indexTab !== -1) {
          terminalsChatOpen[indexTab].pendentsLlegir = 0;
        }
      });
    }

  }
