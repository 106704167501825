import { BoeService } from './../../../../services/boe.service';
import { ConfirmacionBOEFilter } from './../../../../models/confirmacion-boe-filter.model';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort } from '@angular/material'; 
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ConfirmacionBoeFilterService } from '../../../../services/confirmacion-boe-filter.service';
import { ConfirmacionBoeDto } from '../../../../models/confirmacion-boe.model';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfirmacionDetalleBoeFilterService } from '../../../../services/confirmacion-detalle-boe-filter.service';
import { ConfirmacionDetalleBOEFilter } from '../../../../models/confirmacion-detalle-boe-filter.model';
import { DetalleConfirmacionBoeDto } from '../../../../models/confirmacion-boe-detalle.model';


@Component({
  selector: 'app-rows-detalle-confirmacion',
  templateUrl: './rows-detalle-confirmacion.component.html',
  styleUrls: ['./rows-detalle-confirmacion.component.scss']
})
export class RowsDetalleConfirmacionComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  filter: ConfirmacionDetalleBOEFilter = new ConfirmacionDetalleBOEFilter('expediente', 'desc');
  dataSource: CustomDataSource<DetalleConfirmacionBoeDto, ConfirmacionDetalleBOEFilter>;
  highlightedRows = [];
  displayedItems = [];
  currentSecureId='';
  @Input() updatedFilterData: ConfirmacionBOEFilter;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  displayedColumns = ['expediente','nombre'];
  @Input() detalle: ConfirmacionBoeDto;
  constructor(
    private boeService: BoeService,
    private filterService: FilterService,
    private dialog: MatDialog,
    private i18n: I18n,
  ) { }

ngOnInit() {
  this.sort.disableClear = true;
  this.dataSource = CustomDataSource.create<DetalleConfirmacionBoeDto, ConfirmacionDetalleBOEFilter>(this.paginator, new ConfirmacionDetalleBoeFilterService(), 'urlGetDetailConfirmacion', 'urlGetDetailConfirmacionCount');
  this.dataSource.subject.subscribe((data) => {
    data.forEach(item => {
      this.displayedItems[item.expediente] = {
        id: item.expediente
      };
      // item.IMATGES = this.loadImatges(item);
    });
  });

  this.filter.filterUpdated.subscribe(updated => {
    if (updated) {
      this.load();
    }
  });

  this.filter.paginator.init(this.sort, this.paginator);
}
ngOnChanges(changes: SimpleChanges) {
  if (this.updatedFilterData != null) {
    this.update();
  }
}
ngAfterViewInit() {
  // reset the paginator after sorting
  this.sort.sortChange.subscribe(() => {
    this.paginator.pageIndex = 0;
    this.filter.paginator.set(this.sort, this.paginator);
    this.filter.updated();
  });

  merge(this.sort.sortChange, this.paginator.page)
  .pipe(tap(() => this.filter.updated()))
  .subscribe();

  this.load();
}
update() {
  if (this.dataSource !== undefined) {
    this.updatedFilterData.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.updatedFilterData);
  }
}
load() {
  let self= this;
  if(self.detalle !== undefined &&  self.detalle !== null 
    && self.detalle.secureId !== undefined &&  self.detalle.secureId !== null
    && self.detalle.secureId !== '' ){
      this.filter.secureId = self.detalle.secureId;
      this.filter.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.filter);
  }

  
}


}
