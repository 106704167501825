import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { config } from 'path-shared/configuration/config';
import { ReportService } from 'path-shared/services/report/report.service';

@Component({
  selector: 'app-report-displayer',
  templateUrl: './report-displayer.component.html',
  styleUrls: ['./report-displayer.component.scss']
})
export class ReportDisplayerComponent implements OnInit {
  URL_REPORT = 'urlGenerateReportDenunciaTramitacioSDW';
  src:string;
  constructor(private reportService: ReportService,private sanitizer:DomSanitizer) { }

  ngOnInit() {
   this.loadPdf();
  }

  loadPdf(){
    fetch(`${config.urls['apiUrl'].replace('{hostname}', window.location.hostname)}/getTestPdf`)
    .then((response)=>response.blob())
    .then((myBlob)=>{
        const objectUrl= URL.createObjectURL(myBlob);
        this.src=objectUrl;
        //this.sanitizer.bypassSecurityTrustUrl(objectUrl);
    }).catch((error)=>{
      console.log(error);
    });

  }

}

