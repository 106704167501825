import { ShortDatePipe } from './../../../../shared/pipes/short-date-pipe';
import { find } from 'rxjs/operators';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { ComprovacioTiquetsService } from '../services/comprovacioTiquets.service';
import { ResultadoValidacion } from '../models/resultado-validacion.model';
import { MatTableDataSource, Sort } from '@angular/material';
import { FileDownloader } from 'path-shared/services/http/fileDownloader';
import { FormatDatePipe } from 'path-shared/pipes/format-date-pipe';
import { DatePipe } from '@angular/common';
import { ComprovacioTiquetDto } from '../models/comprovacio-tiquet.dto';

export class ComprovacioTiquetFilter {
  matricula: string;
  data: Date;
  numDenuncia: string;
  constructor() {
    this.matricula = null;
    this.data = new Date();
    this.numDenuncia = null;
  }
}
export interface GroupBy {
  ticketId: string;
  isGroupBy: boolean;
}


@Component({
  selector: 'app-comprovacio-tiquets',
  templateUrl: './comprovacio-tiquets.component.html',
  styleUrls: ['./comprovacio-tiquets.component.scss']
})
export class ComprovacioTiquetsComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['ticketId', 'zonaDesc', 'matricula', 'minutosRestantes', 'tiquets'];
  displayedColumns2: string[] = ['minutosPagados', 'cantidadPago', 'tiempoInicio', 'tiempoFin', 'fabricanteTicket']
  showResult: boolean;
  filter = new ComprovacioTiquetFilter();
  informacionValidacion: ComprovacioTiquetDto;
  fechaActual: Date;
  consultaRealizada = false;

  ELEMENT_DATA: (ResultadoValidacion | GroupBy)[];

  constructor(
    private not: NotificationService,
    private i18n: I18n,
    public auth: AuthorizationService,
    private comprovacioTiquetService: ComprovacioTiquetsService
  ) { }

  /* #region  COMPONENT LIFECYCLE */

  ngOnInit(): void {
    this.fechaActual = new Date();
    this.showResult = false;
  }

  ngAfterViewInit(): void {
    this.not.titleChange(this.i18n('Comprovació Tiquets'));
  }

  /* #endregion */

  validaTicket(): void {
    this.comprovacioTiquetService.Get(this.filter).subscribe((data: ComprovacioTiquetDto) => {
      // Hemos recibido la informacion.
      this.informacionValidacion = data;
      // Nos quedamos con los resultados con ticket.
      this.informacionValidacion.result = this.informacionValidacion.result.filter(r => r.resultado.listaTickets !== null && r.resultado.listaTickets.length > 0);
      this.ELEMENT_DATA = new Array<GroupBy | ResultadoValidacion>();

      let registrosTemporales = [];
      // Iteramos el resultado por cada zona concreta.
      this.informacionValidacion.result.forEach(element => {
        // Agrupamos por zona.
        const registro: ResultadoValidacion =  element.resultado;
        registro.zonaDescripcion = element.aliasZona;
        registrosTemporales.push(registro);
      });

      // Ordenamos por fecha.
      registrosTemporales = this.sortByFechaFin(registrosTemporales);
      registrosTemporales.forEach(element => {
        const gr: GroupBy = { ticketId: element.tiketId, isGroupBy: true };
        if (this.ELEMENT_DATA === undefined || this.ELEMENT_DATA === null || this.ELEMENT_DATA.findIndex((x: GroupBy) => x.ticketId === gr.ticketId) === -1) {
          this.ELEMENT_DATA.push(gr);
        }
        this.ELEMENT_DATA.push(element);
      });
      this.showResult = true;
      this.consultaRealizada = true;
    });
  }
  resetMatriculaData() {
    if (this.filter.data !== undefined || this.filter.data !== null ) {
      this.filter.data = null;
    }
    if (this.filter.matricula !== undefined || this.filter.matricula !== null ) {
      this.filter.matricula = null;
    }
  }
  resetNumDenuncia() {
    if (this.filter.numDenuncia !== undefined || this.filter.numDenuncia !== null ) {
      this.filter.numDenuncia = null;
    }
  }
  isFormularioLleno() {
    return ((this.filter.matricula === null || this.filter.data === null) && this.filter.numDenuncia === null);
  }

  changedValues() {
    this.showResult = false;
  }

  sinTickets(): boolean {
    return (this.informacionValidacion === undefined || this.informacionValidacion === null ||
            this.informacionValidacion.result.length === 0) && this.consultaRealizada;
  }

  sortByFechaFin(registros): any {
    return registros.sort((a, b) => b.listaTickets[0].tiempoFin.localeCompare(a.listaTickets[0].tiempoFin));
  }

  sortData(sort: Sort) {
    const data = this.ELEMENT_DATA.slice();
    if (!sort.active || sort.direction === '') {
      this.ELEMENT_DATA = data;
      return;
    }
    this.ELEMENT_DATA = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'zona':
          if (a.isGroupBy !== undefined && a.isGroupBy !== null && b.isGroupBy !== undefined && b.isGroupBy !== null) {
            this.compare(a.ticketId, b.ticketId, isAsc)
          } else if (a.isGroupBy !== undefined && a.isGroupBy !== null) {
            return 1;
          } else if (b.isGroupBy !== undefined && b.isGroupBy !== null) {
            return 2;
          }
          return this.compare(a.zonaDescripcion, b.zonaDescripcion, isAsc);
        case 'minutosRest': return this.compare(a.minutosRestantes, b.minutosRestantes, isAsc);
        case 'minutosPagados': return this.compare(a.listaTickets[0].minutosPagados, b.listaTickets[0].minutosPagados, isAsc);
        case 'ticketId':
          if (a.isGroupBy !== undefined && a.isGroupBy !== null && b.isGroupBy !== undefined && b.isGroupBy !== null) {
            this.compare(a.ticketId, b.ticketId, isAsc);
          } else if (a.isGroupBy !== undefined && a.isGroupBy !== null) {
            return 1;
          } else if (b.isGroupBy !== undefined && b.isGroupBy !== null) {
            return 2;
          }
          return this.compare(a.ticketId, b.ticketId, isAsc);
        case 'cantidadPago': return this.compare(a.listaTickets[0].cantidadPago, b.listaTickets[0].cantidadPago, isAsc);
        case 'tiempoInicio': return this.compare(a.listaTickets[0].tiempoInicio, b.listaTickets[0].tiempoInicio, isAsc);
        case 'tiempoFin':
          return this.compare(a.listaTickets[0].tiempoFin, b.listaTickets[0].tiempoFin, isAsc);
        case 'fabricanteTicket': return this.compare(a.listaTickets[0].fabricanteTicket, b.listaTickets[0].fabricanteTicket, isAsc);
        // case 'protein': return compare(a.protein, b.protein, isAsc);
        default: return 0;
      }
    });
  }

  compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  isGroup(index, item): boolean {
    return item.isGroupBy;
  }

  isOnTime(fecha: Date): boolean {
    return fecha <= new Date();
  }

  array_move(old_index, new_index) {
    if (new_index >= this.ELEMENT_DATA.length) {
      let k = new_index - this.ELEMENT_DATA.length + 1;
      while (k--) {
        this.ELEMENT_DATA.push(undefined);
      }
    }
    this.ELEMENT_DATA.splice(new_index, 0, this.ELEMENT_DATA.splice(old_index, 1)[0]);
  }

  getdata(data)
  {
    return new MatTableDataSource<any>(data);
  }

  generateExcel() {

    let rows: any[] = [];
    this.ELEMENT_DATA.forEach((x: any) => {
      if (x.isGroupBy === undefined || x.isGroupBy === null || x.isGroupBy === false) {
        const fila = {
          ticketId: x.ticketId,
          zonaDescripcion: x.zonaDescripcion,
          minutosRestantes: x.minutosRestantes,
          minutosPagados: x.listaTickets[0].minutosPagados,
          cantidadPago: x.listaTickets[0].cantidadPago,
          tiempoInicio: x.listaTickets[0].tiempoInicio,
          tiempoFin: x.listaTickets[0].tiempoFin
        };
        rows.push(fila);
      }
    });

    this.comprovacioTiquetService.GenerateExcel(rows).subscribe(data => {
      if (data !== undefined && data !== null) {
        var datePipe = new DatePipe('es-ES');
        const date = datePipe.transform(new Date(), 'dd/MM/yyyy');
        let fileName = 'Tiquets_' + this.filter.matricula;
        // if (this.addDate) {
        //   fileName += '-' + date;
        // }
        fileName += '.xlsx';
        FileDownloader.downLoadFile([data], data.type + ';charset=utf-8', fileName);
        //  else {
        //    this.toastr.error(this.i18n('No s\'ha pogut generar el fitxer'));
        //  }
        //FileDownloader.downLoadFile([data], 'application/vnd.ms-excel;charset=utf-8', fileName);
      }
    });
  }
}
