import { Injectable, ElementRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor() { }

  public allFieldsValid(form): boolean {
    return form.nativeElement.getElementsByClassName('ng-invalid').length === 0;
  }
}
