import { I18n } from '@ngx-translate/i18n-polyfill';
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe extends DatePipe implements PipeTransform {
  constructor(private i18n: I18n) {
    super('ca');
  }

  transform(value: any, includeHours?: any): any {
    if (value === null) {
      return '';
    } else {
      let formato = 'dd/MM/yyyy';
      const date = new Date(value);
      let test = value.toString().substr(0, 4) + '/'
       + value.toString().substr( 4 , 2) + '/' + value.toString().substr(6, 2);
      if (includeHours !== undefined && includeHours === true) {
        test += ' ' + value.toString().substr(8, 2) + ':' + value.toString().substr(10, 2) + ':' + value.toString().substr(12, 2);
        formato = 'dd/MM/yyyy HH:mm:ss';
      }
      return super.transform(test ,  formato);
    }

  }
}
