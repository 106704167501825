import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-permisos-page',
  templateUrl: './permisos-page.component.html'
})
export class PermisosPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
