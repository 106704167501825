export class ZonaAparcare {
  id: number;
  codi: number;
  descripcio: string;
  dataInici: Date;
  dataFi: Date;
  idTarifa: number;
  secureID: string;

  constructor() {
    this.id = null,
    this.codi = null;
    this.descripcio = '',
    this.dataFi = null,
    this.dataInici = null;
    this.idTarifa = null;

  }
}
