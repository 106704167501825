<div *ngIf="!isTextarea" class="row align-middle">
  <div *ngIf="!this.labelHidden" class="col-sm-4">{{this.label}}</div>
  <div class="col-sm-3"><button class="btn btn-primary" (click)="this.upload()" i18n>Seleccionar fitxer</button></div>
  <div class="col-sm-9" [hidden]="this.size == 0">{{ this.fileInfo }}</div>
  <input type="file" multiple class="form-control-file" (change)="this.onFileChange($event)" #uploadFileInput hidden style="width: 0px"/>
</div>

<div *ngIf="isTextarea">
  <div class="row fixed-height">
    <div class="col-sm-9">
      <textarea autosize #inputEdit matInput class="fixed-height-scroll" type="text" [placeholder]="this.labelFitxer" [(ngModel)]="this.fileInfo" [disabled]="true"></textarea>
    </div>
    <div class="col-sm-3 align-self-end">
        <button class="btn btn-primary" (click)="this.upload()" i18n>Seleccionar fitxers</button>
        <input type="file" multiple class="form-control-file" (change)="this.onFileChange($event)" #uploadFileInput hidden style="width: 0px"/>
    </div>
  </div>
  <div i18n>Número de fitxers: {{this.numFitxers}}</div>
</div>