<app-toolbar>
  
  <app-tool-button text="Actualitzar" i18n-text (click)="this.update(true);" color="has-color-blue" icon="fa-sync" badge="new" [badgeActive]="this.novesPropostes" badgeColor='badge-warning'></app-tool-button>
  <!--<app-tool-button text="Filtre" id="filtreDropDown" propagate=true i18n-text color="has-color-yellow" icon="fa-filter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></app-tool-button>-->
  <app-tool-button text="Llista" i18n-text (click)="veureLlistaPropostes();" color="has-color-yellow" icon="fa-th-list"></app-tool-button>
  <app-tool-button *ngIf="ordre==='asc'" text="Antigues primer" i18n-text (click)="changeOrdre();" color="has-color-blue" icon="fa-sort-down"></app-tool-button>
  <app-tool-button *ngIf="ordre==='desc'" text="Noves primer" i18n-text (click)="changeOrdre();" color="has-color-blue" icon="fa-sort-up"></app-tool-button>  
  <app-tool-button-permissions [permission]="this.auth.Pages.Propostes"></app-tool-button-permissions>
  <app-tool-button text="Anterior" i18n-text (click)="previousProposta();" color="has-color-aqua" icon="fa-chevron-left" [disabled]="!this.current"></app-tool-button>
  <app-tool-button [text]="getCurrentPropostaText()" disabled="true"></app-tool-button>
  <app-tool-button text="Següent" i18n-text (click)="nextProposta();" color="has-color-aqua" icon="fa-chevron-right" [disabled]="this.current+1 == this.totalPropostes" ></app-tool-button>  
</app-toolbar>
<div class="row justify-content-left h100">

<div class="col-12 h100" style="width: 100%;">
    <div [hidden]="this.sumaPropostes() === 0">
      
      <app-pendents #pendentsComponent *ngIf="this.sumaPropostes() != 0" [proposta]="this.currentProposta" (refreshComponent)="this.update(true);"></app-pendents>
    </div>

    <div [hidden]="this.sumaPropostes() > 0">
      <div class="empty-case">
        <span class="empty-case__icon"><i class="fa fa-search"></i></span>
        <h4 class="empty-case__title" i18n>No hem trobat propostes!</h4>
        <p i18n>Sembla ser, que encara no hi ha cap proposta publicada. Si us plau, torna a intentar-ho més tard.</p>
      </div>
    </div>
</div>
</div>
