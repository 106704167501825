
  import { SOM_FICHEROS } from 'path-models/SOM_FICHEROS';
  import { Component, OnInit, Inject, SimpleChanges, Input, ViewChild, ElementRef } from '@angular/core';
  import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
  import { PhotoBoxGalleryComponent } from 'path-shared/components/photo-box/components/photo-box-gallery/photo-box-gallery.component';
  import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
  import { I18n } from '@ngx-translate/i18n-polyfill';
  import { Toast, ToastrService } from 'ngx-toastr';
import { DenunciesImagesComponent } from 'app/sections/denuncies/components/denuncies-editor/components/denuncies-images/denuncies-images.component';
import { DenunciesService } from 'path-denuncies-services/denuncies.service';
import { ImagesService } from 'path-shared/services/images/images.service';
import { DenunciaImagesModel } from 'app/sections/denuncies/models/denuncia-images.model';

@Component({
  selector: 'app-photo-box-grua',
  templateUrl: './photo-box-grua.component.html',
  styleUrls: ['./photo-box-grua.component.scss']
})
export class PhotoBoxGruaComponent implements OnInit {
  arrayImages: string[] = [];
  somid: number;
  imageNames: string[] = [];
  imageContainer: DenunciaImagesModel[];
  images: SOM_FICHEROS[];
  //numDenuncia: any;
  files = [];
  numSelected = 0;
  //isDialog = false;
  @Input() imagesInput: SOM_FICHEROS[];
  @Input() isDialog: boolean;
  @Input() contenedor: string;
  @Input() numDenuncia: string;
  @ViewChild('photoBoxGallery') photoBoxGallery: PhotoBoxGalleryComponent;
  @ViewChild('fileInput') fileInput: any;

  
    constructor(public thisDialogRef: MatDialogRef<DenunciesImagesComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private denunciesService: DenunciesService, private imagesService: ImagesService,
      private confirmationDialogService: ConfirmationDialogService,
      private toastr: ToastrService,
      private i18n: I18n) {
        
          this.images = data['ficheros'];
          this.numDenuncia = data['numDenuncia'];
          this.isDialog = data['isDialog'];
          this.contenedor = data['contenedor'];
          // images.forEach(element => { this.arrayImages.push(element.FICHERO); });
      }

  ngOnInit() {
    
      if(this.imagesInput !== undefined && this.imagesInput !== null &&  this.imagesInput !== []){
        this.images = this.imagesInput;
      }else{
        this.toastr.error(this.i18n('Atenció! Aquesta proposta no té imatges!'));
      }
    }

    getImage(imageName: string) {

      const index = this.imageNames.indexOf(imageName);

      if (index === -1) {
        const image = this.denunciesService.getImage(this.somid, imageName).subscribe(
          detailsdata => {
            if(detailsdata === undefined || detailsdata === null || detailsdata === [] ){
              this.toastr.error(this.i18n('Atenció! Aquesta proposta no té imatges!'));
            }
            this.thisDialogRef.componentInstance.imageContainer = detailsdata;
            this.imageContainer.forEach(element => {
              this.arrayImages.push(element.FICHERO);
              this.imageNames.push(imageName);
            });
          });
      }
    }

    Afegir() {
      const event = new MouseEvent('click', {bubbles: false});
      this.fileInput.file.nativeElement.dispatchEvent(event);
    }

    fileReady(files) {
      this.files = files;
      this.processar();
    }

    processar() {
      if (this.files === undefined || this.files === null) {
        this.toastr.warning(this.i18n('Cal seleccionar un fitxer per carregar!'));
      } else {
          const formData: FormData = new FormData();
          this.files.forEach(file => {
            formData.append('file', file, file.name);
          });

          const object = [this.numDenuncia, formData];
          if(this.contenedor !== undefined && this.contenedor !== null && this.contenedor !=='' ){
            this.denunciesService.afegirImatgesGruaWithContenedor(formData, this.numDenuncia, this.contenedor).subscribe(result => {
              this.denunciesService.getImagesWithContenedor(this.contenedor,this.numDenuncia).subscribe( images => {
                this.images = images;
                this.images.forEach(element => {
                  element.NUMDENUNCIA = this.numDenuncia;
                });
                this.photoBoxGallery.loadImages(this.images);
            });
              this.toastr.info(this.i18n('Imatges carregada correctament'));
            });
          } else {
            this.denunciesService.afegirImatgesGrua(formData, this.numDenuncia).subscribe(result => {
                    this.denunciesService.getDenunciaImagesNumDen(this.numDenuncia).subscribe( images => {
                      this.images = images;
                      this.images.forEach(element => {
                        element.NUMDENUNCIA = this.numDenuncia;
                      });
                      this.photoBoxGallery.loadImages(this.images);
                  });
                    this.toastr.info(this.i18n('Imatges carregada correctament'));
                  });
          }
      }
    }

    getChildNumSelected(numSelected: number): void {
      this.numSelected = numSelected;
      if (this.numSelected === this.photoBoxGallery.selectedImages.length) {
        this.numSelected = 0;
      }
    }

    Eliminar() {
      this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol eliminar les imatges seleccionades?'))
      .then((confirmed) => {
        if (confirmed) {
          this.photoBoxGallery.selectedImages.forEach(element => {
            if (element.checked === true) {
              this.denunciesService.deleteImage(element.imatge.SECURE_ID).subscribe(() => {
                const index: number = this.photoBoxGallery.selectedImages.indexOf(element);
                if (index !== -1) {
                  this.photoBoxGallery.selectedImages.splice(index, 1);
                }
              });
            }
          });
        }
      });
    }
  }
