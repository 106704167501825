import { Component, OnInit, Output, Input, EventEmitter, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-form-file-multiple-upload',
  templateUrl: './form-file-multiple-upload.component.html',
  styleUrls: ['./form-file-multiple-upload.component.scss']
})
export class FormFileMultipleUploadComponent implements OnInit {

  data: ArrayBuffer;
  size = 0;
  fileInfo = '';
  numFitxers = 0;

  @Input() label: string;
  @Input() labelFitxer: string;
  @Input() labelHidden: boolean;
  @Input() name: string;
  @Input() isTextarea: boolean;
  @Output() fileReady: EventEmitter<any> = new EventEmitter<any>();

  // @ViewChild('uploadFileInput') input: ElementRef;
  @ViewChild('uploadFileInput') file;
  public files: Set<File> = new Set();

  constructor() {
    this.labelHidden = false;
    this.isTextarea = false;
  }

  ngOnInit() {
  }

  upload() {
    this.file.nativeElement.click();
  }

  onFileChange(event) {
    this.files = new Set();
    this.fileInfo = '';
    if (event.target.files && event.target.files.length) {
      for (let i = 0; i < event.target.files.length; i++) {
        const file: File = event.target.files[i];
        this.size = this.size + file.size;
        if (this.fileInfo.length === 0) {
          this.fileInfo = this.fileInfo + file.name + ' (' + file.size + ')';
        } else {
          this.fileInfo = this.fileInfo + ', ' + file.name + ' (' + file.size + ')';
        }
        this.files.add(file);
      }
      this.numFitxers = this.files.size;
      this.fileReady.emit(this.files);

    }
  }
}
