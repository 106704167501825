import { TextosPredefinidosEditorComponent } from './../../components/textos-predefinidos-editor/textos-predefinidos-editor.component';
import { TextosPredefinidosTableComponent } from './../../components/textos-predefinidos-table/textos-predefinidos-table.component';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MotiusDesestimarFilter } from 'app/sections/configuracio/motius-desestimar/models/motius-desestimar-filter';
import { PermissionDialogComponent } from 'path-shared/components/permission-dialog/permission-dialog.component';
import { Builder } from 'path-shared/models/builder';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { TextosPredefinidosFilterComponent } from '../../components/textos-predefinidos-filter/textos-predefinidos-filter.component';

@Component({
  selector: 'app-textos-predefinidos',
  templateUrl: './textos-predefinidos.component.html',
  styleUrls: ['./textos-predefinidos.component.scss']
})
export class TextosPredefinidosComponent  implements OnInit, AfterViewInit {

  permissionDialogRef: MatDialogRef<PermissionDialogComponent>;
  activeFilters: string[] = [];
  filterData: MotiusDesestimarFilter;
  somIdFoo: number;
  tipusMatricules = Array();
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  @ViewChild(TextosPredefinidosTableComponent) textosPredefindosTable: TextosPredefinidosTableComponent;

  constructor(
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private i18n: I18n,
    private not: NotificationService,
    public thisDialogRef: MatDialogRef<TextosPredefinidosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
    ) {
      this.filterData = new MotiusDesestimarFilter('id', 'desc');
      if (data !== undefined) {
        this.dataDialog = data;
      }
    }

  ngOnInit(): void {
    this.auth.UserCanSeePage(this.auth.Pages.MotiusDesestimar);
    this.textosPredefindosTable.itemClicked.subscribe(secure_id => {
      this.update(secure_id);
    });
    this.textosPredefindosTable.itemSelected.subscribe(codi => {
      this.thisDialogRef.close(codi);
    });
  }

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Motius Desestimar'));
    }
  }

  edit() {
    const idSeleccionat: string = this.textosPredefindosTable.displayedItems['checkedItems'][0];
    this.update(idSeleccionat);
  }

  create() {
    this.update(null);
  }

  update(secure_id: string) {
    const data: ComponentDialog = Builder.newComponentDialog(true, false, secure_id);

    const ref = this.dialog.open(TextosPredefinidosEditorComponent, {
      width: '70%',
      closeOnNavigation: false,
      autoFocus: true,
      data: data
    });

    ref.afterClosed().subscribe(updated => {
      if (updated) { this.textosPredefindosTable.load(); }
    });
  }

  refresh() {
    this.textosPredefindosTable.load();
  }

  onFilterClicked() {
    const ref = this.dialog.open(TextosPredefinidosFilterComponent, {
      width: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.textosPredefindosTable.filter
      }
    });

    ref.componentInstance.notify.subscribe((filter: MotiusDesestimarFilter) => {
      this.textosPredefindosTable.filter.set(filter);
      this.textosPredefindosTable.load();
    });
  }

  onRowSelected(somid) {
    this.update(somid);
  }

  generateExcel() {
    this.textosPredefindosTable.generateExcel();
  }

}
