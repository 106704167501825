import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ValidationService } from 'path-shared/services/validation/validation.service';

@Component({
  selector: 'app-form-check-edit',
  templateUrl: './form-check-edit.component.html',
  styleUrls: ['./form-check-edit.component.scss']
})
export class FormCheckEditComponent implements OnInit {
  val: boolean;

  @Input() label: string;
  @Input() get value() {
    return this.val;
  } set value(val) {
    this.val = val;
    this.valueChange.emit(this.val);
  }
  @Output() valueChange = new EventEmitter();

  @Output() clicked = new EventEmitter();
  @Input() required: boolean;
  @Input() disabled: boolean;


  constructor(
    private validation: ValidationService
  ) {
    this.required = false;
  }

  ngOnInit() {
  }

  valid(): boolean {
    if (this.isRequired()) {
      return this.validation.isBoolean(this.value);
    } else {
      return true;
    }

  }

  isRequired() {
    return this.required !== false;
  }

  errorRequired() {
    return this.isRequired() && this.validation.isEmpty(this.value);
  }


 onClick(event) {
  this.clicked.emit(this.value);
 }


}
