import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { ImportacioDenunciaFilter } from '../../models/importacio-denuncia-filter';
import { MatDialog, MatDialogRef } from '@angular/material';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ImportacioDenunciaService } from 'path-denuncies-services/importacioDenuncia.service';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { ToastrService } from 'ngx-toastr';
import { FormService } from 'path-shared/services/form/form.service';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { Router } from '@angular/router';
import { LoaderService } from 'path-shared/components/loader/loader.service';
import { ProgressBarComponent } from 'path-shared/components/progress-bar/progress-bar.component';
import { ProgressBar } from 'path-shared/components/progress-bar/progress-bar.model';
import { DisplayTextDialogComponent } from 'path-shared/components/dialogs/display-text-dialog/display-text-dialog.component';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-importacio-denuncies',
    templateUrl: './importacio-denuncies.component.html',
    styleUrls: ['./importacio-denuncies.component.scss']
  })

export class ImportacioDenunciesComponent implements OnInit, AfterViewInit {
  private files: File[] = [];
  importacioDenuncia = [];
  selectedValue = '';
  filterData: ImportacioDenunciaFilter;
  dataDialog: ComponentDialog = Builder.newComponentDialog();
  contador = 0;
  ficherosRepetidos = [];
  recursioFinalitzada: Observable<boolean>;
  @ViewChild('mainForm') private mainForm: ElementRef;
  @ViewChild('progressBar') private progressBar: ProgressBarComponent;

  constructor(
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private i18n: I18n,
    private importacioDenunciaService: ImportacioDenunciaService,
    public thisDialogRef: MatDialogRef<ImportacioDenunciesComponent>,
    private toastr: ToastrService,
    private formService: FormService,
    private not: NotificationService,
    private router: Router,
    private loaderService: LoaderService,
    ) {

    }

  ngOnInit() {
    this.importacioDenuncia.push({'idImportacio': '', 'descImportacio': ''});
    this.importacioDenunciaService.getAll().subscribe(result => {
      if (result !== null) {
        result.forEach(element => {
          this.importacioDenuncia.push({'idImportacio': element.idImportacio, 'descImportacio': element.descImportacio});
        });
      }
    });
  }
  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Importació'));
    }
  }

  fileReady(files) {
    this.files = Array.from(files);
  }

  processar() {
    if (this.files === undefined || this.files === null) {
      this.toastr.warning(this.i18n('Cal seleccionar un fitxer per carregar!'));
    } else if (this.selectedValue === '') {
      this.toastr.warning(this.i18n('Cal seleccionar un format d\'importació!'));
    } else {
      if (this.formService.allFieldsValid(this.mainForm)) {
        this.ficherosRepetidos =[];
        this.loaderService.show(true);
        this.RecursiveSend(this.files[0]);
      } else {
        this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
        if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
          this.mainForm.nativeElement.classList.add('displayErrors');
        }
      }
    }
  }

  irHistorico() {
    this.router.navigate(['denuncies/importacio-denuncies-historic']);
  }

  RecursiveSend(file) {

    if (file !== undefined) {
      const formData: FormData = new FormData();
      formData.append('file', file, file.name);
      this.importacioDenunciaService.loadImportacioDenuncia(formData, this.selectedValue).subscribe((res: string[]) => {
        // Ahora el método devuelve una lista con los ficheros repetidos
        res.forEach(file=>{
          this.ficherosRepetidos.push(file)
        });

        this.contador++;
        const progressBarUpdatedValue = new ProgressBar('importacioDenuncia', Math.round((this.contador / this.files.length) * 100), this.contador + ' de ' + this.files.length);
        this.progressBar.next(progressBarUpdatedValue);
        this.RecursiveSend(this.files[this.contador]);
      });
    } 
    else {
      this.loaderService.hide(true);
      this.toastr.info(this.i18n('Importació denuncia carregada correctament'));
      if (this.ficherosRepetidos.length>0) {
        this.dialog.open(DisplayTextDialogComponent, {
          width: '45%',
          closeOnNavigation: false,
          autoFocus: true,
          data: {text: this.i18n('Los siguientes ficheros ya se encontraban en el sistema: ' + this.ficherosRepetidos), label: this.i18n('Atenció')}
        });
      }
      this.importacioDenunciaService.processarLlista(this.selectedValue).subscribe(
        (res) => {
          this.toastr.show(res)
        },
        (err) => {
          this.toastr.error(err)
        }
      )
    }
  }
}
