import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Observable, Subscription } from 'rxjs-compat';
import { BonosResidentesFilter } from '../../models/bonos-residentes-filter';
import { GestioResidentsService } from '../../services/gestio-residents.service';

@Component({
  selector: 'app-bonos-residents-filter',
  templateUrl: './bonos-residents-filter.component.html',
  styleUrls: ['./bonos-residents-filter.component.scss']
})
export class BonosResidentsFilterComponent implements OnInit, OnDestroy {

  subscription: Subscription;
  filter: BonosResidentesFilter;
  info;
  pagoLabels = [];
  @Output() notify: EventEmitter<BonosResidentesFilter> = new EventEmitter<BonosResidentesFilter>();

  constructor(
    public thisDialogRef: MatDialogRef<BonosResidentsFilterComponent>,
    private i18n: I18n,
    private gestioResidentsService: GestioResidentsService,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    this.filter = data.filter;
  }

  ngOnInit() {
    this.pagoLabels = [this.i18n('Correcto'), this.i18n('Incorrecto')];
    this.gestioResidentsService.getBonosFilterInfo().subscribe(res => {
      this.info = res;
    });
    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.apply();
      }
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated();
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }
}
