<mat-card>
  <mat-card-title>
    <div class="row">
      <div class="col-6" style="text-align: center;"><Span i18n>Capes</Span></div>
      <div class="col-6">
        <label style="font-size: small;" i18n>
          <input type="checkbox" [(ngModel)]="this.hoverAlwaysActive" 
            (ngModelChange)="this.opcionModificada('HOVER_ACTIVE', true)"> Mostrar Informació
        </label>
      </div>
    </div>
  </mat-card-title>
  <mat-card-content>
    <mat-list>

      <mat-list-item class="row"> 
        <div class="container-icono"><i class="fa fa-bell icono" style="color: #fc0303;"></i>&nbsp;</div>
        <label i18n>
          <input type="checkbox" [(ngModel)]="this.opciones.alarmasSistema" 
            (ngModelChange)="this.opcionModificada('ALARMES_SISTEMA', this.opciones.alarmasSistema)"> Alarmes Sistema
        </label>
      </mat-list-item>
      
      <mat-list-item class="row"> 
        <div class="container-icono"><i class="fa fa-bell icono" style="color: #f803fc"></i>&nbsp;</div>
        <label i18n>
          <input type="checkbox" [(ngModel)]="this.opciones.alarmasSmartphones"
            (ngModelChange)="this.opcionModificada('ALARMES_SMARTPHONE', this.opciones.alarmasSmartphones)"> Alarmes Smartphones
        </label>
      </mat-list-item>

      <mat-list-item class="row"> 
        <div class="container-icono"><i class="fa fa-mobile icono" style="color: #0CC0EC;"></i>&nbsp;</div>
        <label i18n>
          <input type="checkbox" [(ngModel)]="this.opciones.trackingPda" 
            (ngModelChange)="this.opcionModificada('TRACKING_PDA', this.opciones.trackingPda)"> Tracking Smartphones
        </label>
      </mat-list-item>

      <mat-list-item class="row"> 
        <div class="container-icono"><i class="fa fa-truck-pickup icono" style="color: #06C832;"></i>&nbsp;</div>
        <label i18n>
          <input type="checkbox" [(ngModel)]="this.opciones.trackingGrua"
            (ngModelChange)="this.opcionModificada('TRACKING_GRUA', this.opciones.trackingGrua)"> Tracking Grua
        </label>
      </mat-list-item>

      <mat-list-item class="row"> 
        <div class="container-icono"><i class="fa fa-pencil-ruler icono" style="color: #939A9C;"></i>&nbsp;</div>
        <label i18n>
          <input type="checkbox" [(ngModel)]="this.opciones.propuestasGrua"
            (ngModelChange)="this.opcionModificada('PROPOSTES_GRUA', this.opciones.propuestasGrua)"> Propostes Grua
        </label>
      </mat-list-item>

      <mat-list-item class="row sub-element" *ngIf="this.opciones.propuestasGrua"> 
        <div class="container-icono"><i class="fa fa-pencil-ruler icono" style="color: #E0870F;"></i>&nbsp;</div>
        <label i18n>
          <input type="checkbox" [(ngModel)]="this.opciones.propuestasGruaPendientes" 
            (ngModelChange)="this.opcionModificada('PROPOSTES_GRUA_PENDENTS', this.opciones.propuestasGruaPendientes)"> Pendents
        </label>
      </mat-list-item>
      
      <mat-list-item class="row sub-element" *ngIf="this.opciones.propuestasGrua"> 
        <div class="container-icono"><i class="fa fa-pencil-ruler icono" style="color: #4BCA11;"></i>&nbsp;</div>
        <label i18n>
          <input type="checkbox" [(ngModel)]="this.opciones.propuestasGruaAceptadas" 
            (ngModelChange)="this.opcionModificada('PROPOSTES_GRUA_ACCEPTADES', this.opciones.propuestasGruaAceptadas)"> Acceptades
        </label>
      </mat-list-item>

      <mat-list-item class="row sub-element" *ngIf="this.opciones.propuestasGrua"> 
        <div class="container-icono"><i class="fa fa-pencil-ruler icono" style="color: #C4190B;"></i>&nbsp;</div>
        <label i18n>
          <input type="checkbox" [(ngModel)]="this.opciones.propuestasGruaRechazadas" 
            (ngModelChange)="this.opcionModificada('PROPOSTES_GRUA_REBUTJADES', this.opciones.propuestasGruaRechazadas)"> Rebutjades
        </label>
      </mat-list-item>

      <mat-list-item class="row sub-element" *ngIf="this.opciones.propuestasGrua"> 
        <div class="container-icono"><i class="fa fa-pencil-ruler icono" style="color: #0B8EC4;"></i>&nbsp;</div>
        <label i18n>
          <input type="checkbox" [(ngModel)]="this.opciones.propuestasGruaRetiradas" 
            (ngModelChange)="this.opcionModificada('PROPOSTES_GRUA_RETIRADES', this.opciones.propuestasGruaRetiradas)"> Retirades
        </label>
      </mat-list-item>

      <mat-list-item class="row"> 
        <div class="container-icono"><i class="fa fa-book-open icono" style="color: #939A9C;"></i>&nbsp;</div>
        <label i18n>
          <input type="checkbox" [(ngModel)]="this.opciones.denuncias" 
            (ngModelChange)="this.opcionModificada('DENUNCIES', this.opciones.denuncias)"> Denúncies
        </label>
      </mat-list-item>

      <mat-list-item class="row sub-element" *ngIf="this.opciones.denuncias"> 
        <div class="container-icono"><i class="fa fa-book-open icono" style="color: #109CF7;"></i>&nbsp;</div>
        <label i18n>
          <input type="checkbox" [(ngModel)]="this.opciones.denunciasZonaAzul" 
            (ngModelChange)="this.opcionModificada('DENUNCIES_ZONA_BLAVA', this.opciones.denunciasZonaAzul)"> Denúncies Zona Blava
        </label>
      </mat-list-item>
      
      <mat-list-item class="row sub-element" *ngIf="this.opciones.denuncias"> 
        <div class="container-icono"><i class="fa fa-book-open icono" style="color: #F77C10;"></i>&nbsp;</div>
        <label i18n>
          <input type="checkbox" [(ngModel)]="this.opciones.denunciasPolicia" 
            (ngModelChange)="this.opcionModificada('DENUNCIES_POLICIA', this.opciones.denunciasPolicia)"> Denúncies Policia
        </label>
      </mat-list-item>


      <!-- <mat-list-item class="row"> 
        <div class="container-icono"><i class="fa fa-cogs icono" style="color: #62676e;"></i>&nbsp;</div>
        <label i18n>
          <input type="checkbox" [(ngModel)]="this.opciones.maquinas"
            (ngModelChange)="this.opcionModificada('MAQUINES', this.opciones.maquinas)"> Màquines
        </label>
      </mat-list-item> -->
      <mat-list-item class="row"> 
        <div class="container-icono"><i class="fa fa-chart-pie icono" style="color: #939A9C;"></i>&nbsp;</div>
        <label i18n>
          <input type="checkbox" [(ngModel)]="this.opciones.ocupacion"
            (ngModelChange)="this.opcionModificada('OCUPACIO', this.opciones.ocupacion)"> Ocupació Estacionament
        </label>
      </mat-list-item> 

      <mat-list-item class="row sub-element" *ngIf="this.opciones.ocupacion"> 
        <div class="container-icono"><i class="fa fa-chart-pie icono" style="color: #625be3;"></i>&nbsp;</div>
        <label i18n>
          <input type="checkbox" [(ngModel)]="this.opciones.ocupacionGeneral" 
            (ngModelChange)="this.opcionModificada('OCUPACIO_GENERAL', this.opciones.ocupacionGeneral)"> Mapa Base
        </label>
      </mat-list-item>

      <mat-list-item class="row sub-element" *ngIf="this.opciones.ocupacion"> 
        <div class="container-icono"><i class="fa fa-chart-pie icono" style="color: #5D6ABC;"></i>&nbsp;</div>
        <label i18n>
          <input type="checkbox" [(ngModel)]="this.opciones.ocupacionCalor" 
            (ngModelChange)="this.opcionModificada('OCUPACIO_CALOR', this.opciones.ocupacionCalor)"> mapa de Calor
        </label>
      </mat-list-item>
      <!--<mat-list-item class="row"> 
        <div class="container-icono"><i class="fa fa-users icono" style="color: #a3846a;"></i>&nbsp;</div>
        <label i18n>
          <input type="checkbox" [(ngModel)]="this.opciones.ocupacionParking"
            (ngModelChange)="this.opcionModificada('OCUPACIO_PARKING', this.opciones.ocupacionParking)"> Ocupació Parkings
        </label>
      </mat-list-item>-->
      
      
      <!--<mat-list-item class="row"> 
        <div class="container-icono"><i class="fa fa-map-marker icono" style="color: #004cff;"></i>&nbsp;</div>
        <label i18n>
          <input type="checkbox" [(ngModel)]="this.opciones.senalesVerticales"
            (ngModelChange)="this.opcionModificada('SENYALS_VERTICALS', this.opciones.senalesVerticales)"> Senyals Verticals
        </label>
      </mat-list-item> -->
    </mat-list>
  </mat-card-content>
</mat-card>
