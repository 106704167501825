<div class="row row-cols-1 row-cols-md-3 g-4" style="margin-top: 2vh;">

  <!-- ANTES -->
  <div class="col" style="max-width: fit-content;">
    <div class="alert alert-info" *ngIf="desperfectos.DesperfectosAntes.length===0" i18n>
      No se ha encontrado ficheros de la categoría: Antes
    </div>
    <div class="card h-100" *ngIf="desperfectos.DesperfectosAntes.length>0">
      <div class="card-img-top">
        <div id="carouselBefore" class="carousel slide" data-ride="carousel" data-interval="false">
          <ol class="carousel-indicators" *ngIf="desperfectos.DesperfectosAntes.length>1">
            <li name="carousel-antes-selector"
              *ngFor="let desperfectoAntes of desperfectos.DesperfectosAntes; let i = index" type="button"
              data-target="#carouselBefore" attr.data-slide-to={{i}} class="slide-indicator"
              [ngClass]="i == 0 ? 'active':''">
            </li>
          </ol>
          <div class="carousel-inner" style="height: 35vh; max-width: 52vh;">
            <div name="carousel-antes" class="carousel-item"
              *ngFor="let desperfectoAntes of desperfectos.DesperfectosAntes; let i=index"
              [ngClass]="i == 0 ? 'active':''">
              <!-- <img [src]="getImage(desperfectoAntes.SomIdSomFicheros)" class="d-block w-100"> -->
              <app-photo-box [nom]="desperfectoAntes.NombreFichero" [somId]="desperfectoAntes.SomIdSomFicheros"
                class="d-block w-100">
              </app-photo-box>
            </div>
          </div>

          <span *ngIf="desperfectos.DesperfectosAntes.length>1">
            <a class="carousel-control-prev" type="button" data-target="#carouselBefore" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only" i18n>Anterior</span>
            </a>

            <a class="carousel-control-next" type="button" data-target="#carouselBefore" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only" i18n>Siguiente</span>
            </a>
          </span>


        </div>
      </div>
      <div class="card-body">
        <h5 class="card-title" style="display: inline; margin-right: 1vh;" i18n>Antes</h5>
        <i *ngIf="!editComentarioAntes" class="fas fa-pen click-icon" (click)="editComentarioAntes=!editComentarioAntes"
          i18n-title title="Modificar"></i>
        <i *ngIf="editComentarioAntes" class="fas fa-save click-icon" i18n-title title="Guardar"
          (click)="this.saveComentario('BEFORE')" style="margin-right:1vh;"></i>
        <i *ngIf="editComentarioAntes" class="fas fa-times click-icon" (click)="cancelComentario('BEFORE')" i18n-title
          title="Cancelar"></i>

        <p class="card-text">
          <app-form-text-area [(value)]="comentarioAntes" [disabled]="!editComentarioAntes"></app-form-text-area>
          <button class="btn btn-primary" (click)="afegir('BEFORE')">
            <span i18n>Añadir ficheros</span>&nbsp;
            <i class="fas fa-file-image"></i>
          </button>
        </p>
      </div>
    </div>
  </div>

  <!-- DESPUES -->

  <div class="col" style="max-width: fit-content;">
    <div class="alert alert-info" *ngIf="desperfectos.DesperfectosDespues.length===0" i18n>
      No se ha encontrado ficheros de la categoría: Después
    </div>
    <div class="card h-100" *ngIf="desperfectos.DesperfectosDespues.length>0">
      <div class="card-img-top">
        <div id="carouselAfter" class="carousel slide" data-ride="carousel" data-interval="false">
          <ol class="carousel-indicators" *ngIf="desperfectos.DesperfectosDespues.length>1">
            <li name="carousel-despues-selector"
              *ngFor="let desperfectoDespues of desperfectos.DesperfectosDespues; let i = index" type="button"
              data-target="#carouselAfter" attr.data-slide-to={{i}} class="slide-indicator"
              [ngClass]="i == 0 ? 'active':''">
            </li>
          </ol>
          <div class="carousel-inner" style="height: 35vh;max-width: 52vh;">
            <div name="carousel-despues" class="carousel-item" [ngClass]="i == 0 ? 'active':''"
              *ngFor="let desperfectoDespues of desperfectos.DesperfectosDespues; let i=index">
              <!-- <img [src]="getImage(desperfectosDespues.SomIdSomFicheros)" class="d-block w-100"> -->
              <app-photo-box [nom]="desperfectoDespues.NombreFichero" [somId]="desperfectoDespues.SomIdSomFicheros"
                class="d-block w-100">
              </app-photo-box>
            </div>
          </div>
          <span *ngIf="desperfectos.DesperfectosDespues.length>1">
            <a class="carousel-control-prev" type="button" data-target="#carouselAfter" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only" i18n>Anterior</span>
            </a>

            <a class="carousel-control-next" type="button" data-target="#carouselAfter" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only" i18n>Siguiente</span>
            </a>
          </span>


        </div>
      </div>
      <div class="card-body">
        <h5 class="card-title" i18n style="display: inline; margin-right: 1vh;">Después</h5>
        <i *ngIf="!editComentarioDespues" class="fas fa-pen click-icon"
          (click)="editComentarioDespues=!editComentarioDespues" i18n-title title="Modificar"></i>
        <i *ngIf="editComentarioDespues" class="fas fa-save click-icon" (click)="this.saveComentario('AFTER')"
          i18n-title title="Guardar" style="margin-right:1vh;"></i>
        <i *ngIf="editComentarioDespues" class="fas fa-times click-icon" (click)="cancelComentario('AFTER')" i18n-title
          title="Cancelar"></i>
        <p class="card-text">
          <app-form-text-area [(value)]="comentarioDespues" [disabled]="!editComentarioDespues"></app-form-text-area>
          <button class="btn btn-primary" (click)="afegir('AFTER')">
            <span i18n>Añadir ficheros</span>&nbsp;
            <i class="fas fa-file-image"></i>
          </button>
        </p>
      </div>
    </div>
  </div>

  <!-- OTROS -->

  <div class="col" style="max-width: fit-content;">
    <div class="alert alert-info" *ngIf="desperfectos.DesperfectosOtros.length===0" i18n>
      No se ha encontrado ficheros de la categoría: Otros
    </div>
    <div class="card h-100" *ngIf="desperfectos.DesperfectosOtros.length>0">
      <div class="card-img-top">
        <div id="carouselOthers" class="carousel slide" data-ride="carousel" data-interval="false">
          <ol class="carousel-indicators" *ngIf="desperfectos.DesperfectosOtros.length>1">
            <li name="carousel-otros-selector" [ngClass]="i == 0 ? 'active':''"
              *ngFor="let desperfectosOtros of desperfectos.DesperfectosOtros; let i = index" type="button"
              data-target="#carouselOthers" attr.data-slide-to={{i}} class="slide-indicator">
            </li>
          </ol>
          <div class="carousel-inner" style="height: 35vh;max-width: 52vh;">
            <div name="carousel-otros" class="carousel-item" [ngClass]="i == 0 ? 'active':''"
              *ngFor="let desperfectoOtros of desperfectos.DesperfectosOtros; let i=index">
              <!-- <img [src]="getImage(desperfectosOtros.SomIdSomFicheros)" class="d-block w-100"> -->
              <app-photo-box [nom]="desperfectoOtros.NombreFichero" [somId]="desperfectoOtros.SomIdSomFicheros"
                class="d-block w-100">
              </app-photo-box>
            </div>
          </div>

          <span *ngIf="desperfectos.DesperfectosOtros.length>1">
            <a class="carousel-control-prev" type="button" data-target="#carouselOthers" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only" i18n>Anterior</span>
            </a>

            <a class="carousel-control-next" type="button" data-target="#carouselOthers" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only" i18n>Siguiente</span>
            </a>
          </span>

        </div>
      </div>
      <div class="card-body">
        <h5 class="card-title" i18n style="display: inline; margin-right: 1vh;">Otros</h5>

        <i *ngIf="!editComentarioOtros" class="fas fa-pen click-icon" (click)="editComentarioOtros=!editComentarioOtros"
          i18n-title title="Modificar"></i>
        <i *ngIf="editComentarioOtros" class="fas fa-save click-icon" (click)="this.saveComentario('OPTIONAL')" i18n-title
          title="Guardar" style="margin-right:1vh;"></i>
        <i *ngIf="editComentarioOtros" class="fas fa-times click-icon" (click)="cancelComentario('OPTIONAL')" i18n-title
          title="Cancelar"></i>

        <p class="card-text">
          <app-form-text-area [(value)]="comentarioOtros" [disabled]="!editComentarioOtros"></app-form-text-area>
          <button class="btn btn-primary" (click)="afegir('OPTIONAL')">
            <span i18n>Añadir ficheros</span>&nbsp;
            <i class="fas fa-file-image"></i>
          </button>
        </p>
      </div>
    </div>
  </div>
  <!-- HIDDEN -->
  <app-form-file-multiple-upload label="Fitxer" i18n-label labelHidden="true" (fileReady)="fileReady($event)" hidden #fileInput></app-form-file-multiple-upload>
  
</div>
