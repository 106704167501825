import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { HttpResponse } from '@angular/common/http';
import { AvisosConf } from '../models/avisos.model';

@Injectable()
export class AvisosConfService {
  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {}

  getAvisos(): Observable<any> {
    if (this.authGuard.canActivate()) {
      let url = config.urls['apiUrl'];
      url = url + api.endpoints['AvisosTipus'];
      console.log (url);
      return this.http.get(url,  null);
    }
  }
  setAvis( avisConf : AvisosConf) : Observable<any> {
    return this.http.post(config.urls['apiUrl'] + api.endpoints['AvisosTipus'],  avisConf);
  }

  deleteAvis( avisConf : AvisosConf) : Observable<any> {
    return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteAvisosTipus'].replace('{idAvis}', avisConf.id.toString()),  null);
  }
}
