<div class="form-group">
  <div class="d-flex justify-content-between">
      <div class="flex-grow-1">
        <mat-form-field>
          <mat-label>{{this.label}}</mat-label>
          <input id={{id}} #inputEdit matInput type="this.type" [placeholder]="this.label" [(ngModel)]="this.inputVal" [disabled]="this.disabled" [required]="this.isRequired()" (keyup.enter)="onEnter()">
        </mat-form-field>
      </div>
      <div class="">
        <app-filter-type [(input)]="this.inputVal" [(value)]="this.value" [defaultComparsion]="defaultComparsion" [isNumber]="this.number" ></app-filter-type>
      </div>
    </div>
  <div [hidden]="this.valid()" class="text-danger alert-field-errors">
      <div [hidden]="!this.errorRequired()" i18n>Aquest camp és obligatori!</div>
      <div [hidden]="!this.errorNumber()" i18n>Aquest camp ha de ser un número vàlid!</div>
      <div [hidden]="!this.errorLength()"><span i18n>La longitud màxima és </span>{{ this.maxLength}}!</div>
      <div [hidden]="!this.errorMatricula()"><span i18n>La matrícula només pot contenir números i lletres sense espais i símbols!</span></div>
  </div>
</div>

