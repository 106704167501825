import { MessagingService } from '../messaging/messaging.service';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { MatPaginator } from '@angular/material';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { config } from 'path-shared/configuration/config';
import { catchError, finalize } from 'rxjs/operators';
import { IFilterService } from './filter-service';
import { AppInjectorModule } from 'app/app-injector/app-injector.module';
import { api } from 'path-shared/configuration/urls';
import { FilterData } from 'path-shared/models/filter-data';


/// T -> tipus de dades , F -> tipus del filtre

export class CustomDataSource<T, F> extends DataSource<T> {
  public subject = new BehaviorSubject<T[]>([]);
  public countSubject = new BehaviorSubject<number>(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  private http: HttpSecureClient;
  private msg: MessagingService;
  loading = false;

  constructor(private paginator: MatPaginator, private filterService: IFilterService,
    private urlData: string, private urlCount: string,
    ) {
    super();
    this.http = AppInjectorModule.injector.get(HttpSecureClient);
    this.msg = AppInjectorModule.injector.get(MessagingService);
  }

  public static create<T, F>(paginator: MatPaginator, filterService: IFilterService, endPointData: string,  endPointCount: string): CustomDataSource<T, F> {
      console.log(paginator)
      console.log(filterService)
      console.log(config.urls['apiUrl'] + api.endpoints[endPointData])
      console.log(config.urls['apiUrl'] + api.endpoints[endPointCount])
      return new CustomDataSource(paginator, filterService, config.urls['apiUrl'] + api.endpoints[endPointData], config.urls['apiUrl'] + api.endpoints[endPointCount]);
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<T[]> {
    return this.subject.asObservable();
    // return this.getPagedData(this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() {
    this.subject.complete();
    this.loadingSubject.complete();
  }

  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */
  load(
    filter: F,
    pageIndex: number,
    pageSize: number,
    sortField: string,
    sortDir: string,
    noRecordsFoundText?: string
  ) {
    if (!this.loading) {
      this.loading = true;
      this.loadingSubject.next(true);
      const start = pageIndex * pageSize;
      const params = this.filterService.createFilterParams(
        filter,
        start,
        pageSize,
        sortField,
        sortDir
      );
      this.http.get(this.urlCount, params)
        .subscribe(x => {
          (this.paginator.length = x as number);
          this.countSubject.next(this.paginator.length);
        });

      this.http.get(this.urlData, params)
        .pipe(
          catchError(() => {
            this.loading = false;
            return of([]);
          }),
          finalize(() => {
            this.loadingSubject.next(false);
          })
        )
        .subscribe(items => {
          if (items === null || ((items as T[]).length === 0)) {
            items = [];
            this.msg.noRecordsFound(noRecordsFoundText);
          }
          this.subject.next(items as T[]);
          this.loading = false;
        });
      }
  }

  loadFilter(filter: FilterData, noRecordsFoundText?: string) {
    if (!this.loading) {
      this.loading = true;
      this.loadingSubject.next(true);
      const params = this.filterService.createParamsFromFilter(filter);
      this.http.get(this.urlData, params)
        .pipe(
          catchError(() => {
            this.loading = false;
            return of([]);
          }),
          finalize(() => {
            this.loadingSubject.next(false);
            this.loading = false;
          })
        )
        .subscribe(items => {
          if (items === null || ((items as T[]).length === 0)) {
            items = [];
            this.msg.noRecordsFound(noRecordsFoundText);
          }
          this.subject.next(items as T[]);
          this.loading = false;
        });
        
      this.http.get(this.urlCount, params)
        .subscribe(x => { 
          this.paginator.length = x as number;
          // this.loading = false;
          this.countSubject.next(this.paginator.length);
        });
    }
  }

  getData() {
    return this.subject.value;
  }

  updateData(items: any) {
    this.subject.next(items);
  }

  sort(field, direction) {
    const data = this.subject.value.sort((a, b) => {
      if (a[field] === b[field]) {
        return 0;
      } else {
        return this.compare(a[field], b[field], direction);
      }
    });

    this.subject.next(data);
  }

  set(findField: string, findValue: any, Field: string, Value: any): any {
    const item = this.subject.value.find(x => x[findField] === findValue);
    if (item != null) {
      item[Field] = Value;
    }
  }

  get(findField: string, findValue: any) {
    return this.subject.value.find(x => x[findField] === findValue);
  }

  setUrlData(urlData: string) {
    this.urlData = urlData;
  }

  setUrlCount(urlCount: string) {
    this.urlCount = urlCount;
  }


  private compare(a, b, direction) {
    if (direction === 'desc') {
      if (b === undefined) {
        return -1;
      } else {
        return a > b ? -1 : 1;
      }
    } else {
      if (a === undefined) {
        return -1;
      } else {
        return a < b ? -1 : 1;
      }
    }
  }
}
