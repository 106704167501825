<div class="row justify-content-between" [hidden]="true">
  <div class="col"><app-filter-selector [filter]="this.filter"></app-filter-selector></div>
  <div class="col">
    <mat-paginator #paginator
      [pageIndex]="0"
      [pageSize]="this.filter.paginator.pageSize"
      [pageSizeOptions]="[20, 40, 60, 100]">
    </mat-paginator>
  </div>
</div>
<app-dialog-header label="Tramitar Ejecutivas" i18n-label></app-dialog-header>
<app-dialog-body>
  <app-toolbar>
    <app-tool-button text="Reports" i18n-text (click)="Impressio();" color="has-color-green" icon="fas fa-print" ></app-tool-button>
    <app-tool-button text="Traspasar" i18n-text (click)="Traspassar();" color="has-color-blue" icon="fa-file-export"></app-tool-button>    
  </app-toolbar>
<div class="table-sdw is-fixed">
  <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" matSortActive="{{this.sort.active}}" matSortDirection="{{this.sort.direction}}" class="is-header-sticky">

    <ng-container matColumnDef="Check">
      <mat-header-cell *matHeaderCellDef class="is-checkbox"> <mat-checkbox [(ngModel)]="this.allSelected" (change)="toggleAllCheckbox()"></mat-checkbox> </mat-header-cell>
      <mat-cell *matCellDef="let row">  <mat-checkbox  #checkbox [(ngModel)]="row.Check" (click)="SelectedRow(row)" ></mat-checkbox> </mat-cell>
    </ng-container>

      <!-- Matricula Column -->
      <ng-container matColumnDef="EXP">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Expediente</mat-header-cell>
          <mat-cell *matCellDef="let row"><a *ngIf="row.SomIdDenuncia!==''" (click)="onRowClicked(row)" class="is-link">{{row.EXP}}</a> <span *ngIf="row.SomIdDenuncia===''">{{row.EXP}}</span> </mat-cell>
      </ng-container>

      <!-- Expedient Column -->
      <ng-container matColumnDef="Matricula">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Núm. Denuncia</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.Matricula}}</mat-cell>
      </ng-container>
     
     <!-- Data Column -->
     <ng-container matColumnDef="PERSONA">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Nombre</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.Nom}}</mat-cell>
  </ng-container>  

<!-- Data Column -->
<ng-container matColumnDef="Resultat">
  <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Resultado</mat-header-cell>
  <mat-cell *matCellDef="let row" >{{row.Mensaje}}</mat-cell>
</ng-container>
 
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>        
      <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset"></mat-row>
  </mat-table>
</div>
</app-dialog-body>

