import { ColorsFilterComponent } from './../../components/colors-filter/colors-filter.component';
import { Component, OnInit, Inject, AfterViewInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ColorFilter } from '../../models/color-filter';
import { Builder } from 'path-shared/models/builder';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { PermissionDialogComponent } from 'path-shared/components/permission-dialog/permission-dialog.component';
import { ColorsTableComponent } from '../../components/colors-table/colors-table.component';
import { ColorsEditorComponent } from '../../components/colors-editor/colors-editor.component';

@Component({
  selector: 'app-colors',
  templateUrl: './colors.component.html',
  styleUrls: ['./colors.component.scss']
})
export class ColorsComponent implements OnInit, AfterViewInit {

  permissionDialogRef: MatDialogRef<PermissionDialogComponent>;
  activeFilters: string[] = [];
  filterData: ColorFilter;
  somIdFoo: number;
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  @ViewChild(ColorsTableComponent) colorsTable: ColorsTableComponent;

  constructor(
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private i18n: I18n,
    private not: NotificationService,
    public thisDialogRef: MatDialogRef<ColorsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
    ) {
      this.filterData = new ColorFilter('id', 'desc');
      if (data !== undefined) {
        this.dataDialog = data;
      }
    }

  ngOnInit(): void {
    this.auth.UserCanSeePage(this.auth.Pages.Colors);
    this.colorsTable.itemClicked.subscribe(secure_id => {
      this.update(secure_id);
    });
    this.colorsTable.itemSelected.subscribe(codi => {
      this.thisDialogRef.close(codi);
    });
  }

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Colors'));
    }
  }

  edit() {
    const idSeleccionat: string = this.colorsTable.displayedItems['checkedItems'][0];
    this.update(idSeleccionat);
  }

  create() {
    this.update(null);
  }

  update(secure_id: string) {
    const data: ComponentDialog = Builder.newComponentDialog(true, false, secure_id);

    const ref = this.dialog.open(ColorsEditorComponent, {
      width: '70%',
      closeOnNavigation: false,
      autoFocus: true,
      data: data
    });

    ref.afterClosed().subscribe(updated => {
      if (updated) { this.colorsTable.load(); }
    });
  }

  refresh() {
    this.colorsTable.load();
  }

  onFilterClicked() {
    const ref = this.dialog.open(ColorsFilterComponent, {
      width: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.colorsTable.filter
      }
    });

    ref.componentInstance.notify.subscribe((filter: ColorFilter) => {
      this.colorsTable.filter.set(filter);
      this.colorsTable.load();
    });
  }

  onRowSelected(somid) {
    this.update(somid);
  }

  generateExcel() {
    this.colorsTable.generateExcel();
  }

}
