import { SortDirection } from "@angular/material";
import { FilterData } from "path-shared/models/filter-data";

export class AlegacioFilter extends FilterData {
    EXP: string;
    NUMDENUNCIA: string;
    INFRACCIO: string;
    DATAPLEC: Date;
    AGENTE: string;
    TIPO: string;
    codUser: number;
    constructor(sortField: string, sortDir: SortDirection) {
        super(sortField, sortDir);
        this.EXP = '';
        this.NUMDENUNCIA = '';
        this.INFRACCIO = null;
        this.DATAPLEC = null;
        this.AGENTE = '';
        this.TIPO = '';
        this.codUser = null;
    }
}