<div class="row justify-content-between">
  <div class="col"><app-filter-selector [filter]="this.filter"></app-filter-selector></div>
  <div class="col">
    <mat-paginator #paginator
      [pageIndex]="0"
      [pageSize]="this.filter.paginator.pageSize"
      [pageSizeOptions]="[20, 40, 60, 100]">
    </mat-paginator>
  </div>
</div>

<div class="table-sdw is-fixed">

  <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.filter.paginator.sortField" [matSortDirection]="this.filter.paginator.sortDir" class="is-header-sticky">


      <ng-container matColumnDef="codi">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Codi</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.codi}}</mat-cell>
        </ng-container>

      <ng-container matColumnDef="descripcio">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Descripcio</mat-header-cell>
        <mat-cell *matCellDef="let row"><a (click)="onRowClicked(row)" class="is-link">{{row.descripcio}}</a></mat-cell>
      </ng-container>

      <ng-container matColumnDef="dataInici">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data inici</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.dataInici | shortDate}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="dataFi">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data fi</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.dataFi | shortDate}}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
    </mat-table>
</div>

