<app-filter-header title="Filtrar Avisos" i18n-title  (apply)="this.apply()" (reset)="this.reset()" (dismiss)="this.dismiss()"></app-filter-header>
<div class="modal-body">

    <!-- DATA AVÍS -->
    <div class="row justify-content-md-center">
        <div class="col-sm-1 data">
            <span i18n class="badge badge-secondary text-mute ">Data avis</span>
        </div>
        <div class="col-sm-3">

            <mat-form-field>
                <input matInput i18n-placeholder [matDatepicker]="fechaAvisoInicio" [ngModel]="filter.fechaAvisoInicio" (ngModelChange)="filter.fechaAvisoInicio = $event">
  
                <mat-datepicker-toggle [for]="fechaAvisoInicio"></mat-datepicker-toggle>
                <mat-datepicker #fechaAvisoInicio></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col-sm-3">
            <mat-form-field>
                <input matInput i18n-placeholder [matDatepicker]="fechaAvisoFin" [ngModel]="filter.fechaAvisoFin" (ngModelChange)="filter.fechaAvisoFin = $event">
  
                <mat-datepicker-toggle [for]="fechaAvisoFin"></mat-datepicker-toggle>
                <mat-datepicker #fechaAvisoFin></mat-datepicker>
            </mat-form-field>
        </div>
    </div>

    <!-- Combo AGENT i PDA -->
    <div class="row justify-content-md-center">
      <app-form-combo-edit label="Agent" i18n-label class="col-sm-2" [items]="this.agents" [(value)]="filter.agent" valueField="codi" textField="codi"></app-form-combo-edit>
      <!-- <app-form-combo-edit class="col-md-2" label="PDA" i18n-label class="col-sm-4" [items]="this.pdas" [(value)]="filter.pda" valueField="id" textField="id"></app-form-combo-edit> -->
      <div class="col-sm-2">
          <mat-form-field>
              <mat-label i18n>PDA</mat-label>
              <input matInput placeholder="Placeholder" [value]="filter.pda" (input)="filter.pda=$event.target.value">
          </mat-form-field>
      </div>
    </div>

    <!-- Llistat de tots els avisos -->
    <div class="row">
        <div class="col-md-12">
            <div class="card" style="max-height: 30vh; ">
                <div I18n class="card-header text-center">
                    <small class="title" i18n>Tipus Avís</small>
                </div>
                <div>
                    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
                            <button mat-icon-button disabled></button>
                            <div *ngIf="node.item.isFolder==1">
                                {{node.item.nom}}
                            </div>
                            <div *ngIf="node.item.isFolder!=1">
                                <mat-checkbox class="checklist-leaf-node"
                                    (change)="ToggleAviso(node.item)">{{node.item.nom}}</mat-checkbox>
                            </div>
                        </mat-tree-node>
                        
                        <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
                            <button mat-icon-button matTreeNodeToggle
                                    [attr.aria-label]="'Toggle ' + node.item.nom">
                                <mat-icon class="mat-icon-rtl-mirror">
                                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                </mat-icon>
                            </button>

                            <div *ngIf="node.item.isFolder==1">
                                {{node.item.nom}}
                            </div>
                            <div *ngIf="node.item.isFolder!=1">
                                <mat-checkbox (change)="ToggleAviso(node.item)">{{node.item.nom}}</mat-checkbox>
                            </div>
                        </mat-tree-node>
                      </mat-tree>
                </div>
            </div>
        </div>
  </div>

</div>

