import { PropostesAnulacioFilterComponent } from './../../components/propostes-anulacio-filter/propostes-anulacio-filter.component';
import { PropostesAnulacioTableComponent } from './../../components/propostes-anulacio-table/propostes-anulacio-table.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { PropostesAnulacioFilter } from '../../models/propostes-anulacio-filter';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { I18n } from '@ngx-translate/i18n-polyfill';

@Component({
  selector: 'app-propostes-anulacio',
  templateUrl: './propostes-anulacio.component.html',
  styleUrls: ['./propostes-anulacio.component.scss']
})
export class PropostesAnulacioComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  filter: PropostesAnulacioFilter = new PropostesAnulacioFilter('', '');
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  @ViewChild('table') tablePropostes: PropostesAnulacioTableComponent;
  constructor( public authService: AuthorizationService,
    private not: NotificationService,
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private i18n: I18n) {
      this.filter = new PropostesAnulacioFilter('', '');
    }
  ngOnInit() {
    this.authService.CheckUserPermission(this.authService.Pages.PropuestasAnulacionZB);
  }

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Propuesta anulación'));
    }
  }

  onFilterClicked() {
    const ref = this.dialog.open(PropostesAnulacioFilterComponent, {
      width: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.tablePropostes.filter
      }
    });

  }

  
  refresh() {
    this.tablePropostes.load();
  }

}
