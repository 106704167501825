<app-filter-header title="Filtrar tipus vehicles" i18n-title (apply)="this.apply()" (reset)="this.reset()" (dismiss)="this.dismiss()"></app-filter-header>
<div class="modal-body">
  <div class="row col">
    <app-form-text-edit id="tipus-vehicles-filter-codi" class="col-sm-3" i18n-label label="Codi" [(value)]="this.filter.id" number></app-form-text-edit>
    <app-filter-text-edit id="tipus-vehicles-filter-descripcio" class="col-sm-9" i18n-label label="Descripció" [(value)]="this.filter.descripcio" contains></app-filter-text-edit>
  </div>
  <div class="row col">
    <app-form-tri-state label="Matricula obligatòria" i18n-label labelSi="Si" i18n-label labelNo = "No" i18n-labelTodos labelTodos="Tots" [(value)]="this.filter.matriculaObligatoria"></app-form-tri-state>
  </div>
</div>
