import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { LegislacioFilter } from '../models/legislacio-filter';
import { HttpParams } from '@angular/common/http';
import { Reflection } from 'path-shared/services/reflection/reflection';

const defaultSortParam = 'codi';
const defaultSortDirection = 'asc';

@Injectable()
export class LegislacionsFilterService extends CustomFilterService {
  public createFilterParams(filter: LegislacioFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {

    return new HttpParams()
    .append('codi', filter.codi)
    .append('descripcio', filter.descripcio)
    .append('obsoleta', Reflection.empty(filter.obsoleta) ? '' : filter.obsoleta.toString())
    .append('trafic', Reflection.empty(filter.trafic) ? '' : filter.trafic.toString())
    .append('mesesSLInici', Reflection.empty(filter.mesesSLInici) ? '' : filter.mesesSLInici.toString())
    .append('mesesSLFi', Reflection.empty(filter.mesesSLFi) ? '' : filter.mesesSLFi.toString())
    .append('mesesSGInici', Reflection.empty(filter.mesesSGInici) ? '' : filter.mesesSGInici.toString())
    .append('mesesSGFi', Reflection.empty(filter.mesesSGFi) ? '' : filter.mesesSGFi.toString())
    .append('mesesSMGInici', Reflection.empty(filter.mesesSMGInici) ? '' : filter.mesesSMGInici.toString())
    .append('mesesSMGFi', Reflection.empty(filter.mesesSMGFi) ? '' : filter.mesesSMGFi.toString())
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
