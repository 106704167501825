import { GoogleMapsService } from 'path-shared/services/startup/google-maps.service';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { AlarmGroup } from 'path-shared/models/alarm-group';
import { Component, OnInit, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { PermissionDialogComponent } from 'path-shared/components/permission-dialog/permission-dialog.component';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { Router, ActivatedRoute } from '@angular/router';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { AlarmaFilter } from '../../models/alarma-filter';
import { AlarmesService } from '../../services/alarmes.service';
import { AlarmesFilterComponent } from '../../components/alarmes-filter/alarmes-filter.component';
import { AlarmesTableComponent } from '../../components/alarmes-table/alarmes-table.component';
import { Usuari } from 'app/sections/usuaris/models/usuari.model';
import { PermissionService } from 'path-shared/components/permission-dialog/permission.service';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { Reflection } from 'path-shared/services/reflection/reflection';

@Component({
  selector: 'app-alarmes-historic',
  templateUrl: './alarmes.component.html',
  styleUrls: ['./alarmes.component.scss']
})
export class AlarmesComponent implements OnInit, AfterViewInit {
  permissionDialogRef: MatDialogRef<PermissionDialogComponent>;
  activeFilters: string[] = [];
  filterData: AlarmaFilter;
  somIdFoo: number;
  dataDialog: ComponentDialog = Builder.newComponentDialog();
  private users: Usuari[];
  private tipusAlarmes: AlarmGroup[];
  private timeout = null;


  @ViewChild(AlarmesTableComponent) alarmesTable: AlarmesTableComponent;

  constructor(
    private googleMapsService: GoogleMapsService,
    private authGuard: AuthGuardService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private router: Router,
    private alarmesService: AlarmesService,
    private i18n: I18n,
    private toastr: ToastrService,
    private not: NotificationService,
    public thisDialogRef: MatDialogRef<AlarmesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
    private permissionService: PermissionService,
    private confirmationDialogService: ConfirmationDialogService,
    ) {
      this.filterData = new AlarmaFilter('id','desc');
      if (data !== undefined) {
        this.dataDialog = data;
      }
      this.permissionService.getUsers().subscribe(users => {
        this.users = users;
      });
      this.alarmesService.getTipus().subscribe(tipus => {
        this.tipusAlarmes = tipus;
      });
    }

  ngOnInit() {
    console.log('alarmes init');
  }

  ngAfterViewInit() {
    console.log('alarmes after view init');
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Alarmes'));
    } else {
      this.filterData.id = +this.dataDialog.id;
      this.filterData.updated(true);
    }

    this.route.params.subscribe(params => {
      if (!Reflection.empty(params.id)) {
        if (this.timeout !== null) {
          clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
          if (params.id === 'automatitzacio') {
            this.filterData.dades = 'AUTOMATITZACIO';
            this.alarmesTable.updatedFilterData.dades = 'AUTOMATITZACIO';
          } else {
            this.filterData.idTipusAlarma = params.id;
            // this.alarmesTable.updatedFilterData.idTipusAlarma = params.id;
          }
          this.filterData.updated(true);
          this.timeout = null;

        }, 50);
      } else {
        this.alarmesTable.load();
      }
    });
  }


  refresh() {
    this.alarmesTable.update();
  }

  onFilterClicked() {
    const ref = this.dialog.open(AlarmesFilterComponent, {
      width: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.alarmesTable.updatedFilterData,
        'users': this.users,
        'tipus': this.tipusAlarmes
      }
    });

    ref.componentInstance.notify.subscribe((filter: AlarmaFilter) => {
      this.alarmesTable.updatedFilterData.set(filter);
      this.alarmesTable.load();
    });
  }

  selectCheckedItems() {
    return this.alarmesService.displayedItems['checkedValues'];
  }

  onRowSelected(somid) {
  }

  generateExcel() {
    this.alarmesTable.generateExcel();
  }

  close() {
    this.thisDialogRef.close();
  }

  acceptAll() {
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol confirmar totes les alarmes pendents?'))
    .then((confirmed) => {
      if (confirmed) {
        this.alarmesService.ackAlarms(this.alarmesTable.data).subscribe(() => {
          this.refresh();
        });
      }
    });
  }

  irHistorico(): void {
    this.router.navigate(['alarmes/alarmes-historic']);
  }

}
