import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { TipusMatriculaFilterService } from './tipus-matricules-filter.service';
import { TipusMatriculaFilter } from '../models/tipus-matricula-filter';
import { TipusMatricula } from '../models/tipus-matricula.model';
import { ExcelGenerator } from 'path-shared/interfaces/excel-generator';

const defaultSortParam = 'descripcio';
const defaultSortDirection = 'asc';


@Injectable()
export class TipusMatriculesService implements ExcelGenerator {
  displayedItems = [];

  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {
    this.displayedItems['checkedValues'] = 0;
    this.displayedItems['checkedItems'] = Array();
  }

  getAll(): Observable<any> {
    if (this.authGuard.canActivate()) {
      const params = new TipusMatriculaFilterService().createFilterParams(new TipusMatriculaFilter('descripcio', 'asc'), 0, 99999, 'descripcio', 'asc');
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetTipusMatricules'],  params);
    }
  }

  getTipusMatricula(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetTipusMatricula'].replace('{secure_id}', secure_id), null);
    }
  }

  setTipusMatricula(tipusMatricula: TipusMatricula): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetTipusMatricula'], tipusMatricula);
    }
  }

  deleteTipusMatricula(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteTipusMatricula'].replace('{secure_id}', secure_id), null);
    }
  }

  getTipusMatriculaByInstitution(institutionId): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetTipusMatriculaByInstitutuion'].replace('{id}', institutionId), null);
    }
  }

  generateExcel(filter: TipusMatriculaFilter, sortDir: string, sortField: string): Observable<any> {
    const start = 0;
    const pageSize = 1000;

    sortField = sortField === undefined ? defaultSortParam : sortField;
    sortDir = sortDir === '' ? defaultSortDirection : sortDir;
    const params = new TipusMatriculaFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

    if (this.authGuard.canActivate()) {
      return this.http.getFile(config.urls['apiUrl'].replace('{hostname}', window.location.hostname) + api.endpoints['urlTipusMatriculesExcel'],  params );
    }
  }
}
