
import { Injectable } from '@angular/core';

import { HttpParams } from '@angular/common/http';
import { AvisosFilter } from './avisos-filter';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { Reflection } from 'path-shared/services/reflection/reflection';


const defaultSortParam = 'data';
const defaultSortDirection = 'asc';

@Injectable()
export class AvisosFilterService extends CustomFilterService {

  public createFilterParams(filter: AvisosFilter, start: number, pageSize: number, sortField: string, sortDir: string) {
    const dateMin = new Date(1900, 0, 0);
    const dateMax = new Date(2100, 0, 0);
    return new HttpParams()
    .append('id', filter.id === null ? '' : filter.id.toString())
    .append('idTipusAvis', filter.idTipusAvis === null ? '' : filter.idTipusAvis.toString())
    .append('DataInici', filter.fechaAvisoInicio === null ? dateMin.toDateString() : typeof(filter.fechaAvisoInicio) === 'string' ?
    filter.fechaAvisoInicio : filter.fechaAvisoInicio.toDateString())
    .append('DataFi', filter.fechaAvisoFin === null ? dateMax.toDateString() : typeof(filter.fechaAvisoFin) === 'string' ?
    filter.fechaAvisoFin : filter.fechaAvisoFin.toDateString())
    .append('Carpetes', filter.carpetes)
    .append('Agent', filter.agent)
    .append('PDA', filter.pda)
    .append('IdTipusAvisList', filter.idTipusAvisList)
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
