import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Observable } from 'rxjs';
import { TramitadorHistoricFilter } from '../models/tramitador-historic.filter';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { Expediente } from '../models/expediente.model';
import { PaginationFilter } from 'path-shared/models/pagination.filter';
import { ExpedienteFilter } from '../models/expediente.filter';
import { ExpedienteFilterService } from './expediente-filter.service';

const defaultSortParam = '';
const defaultSortDirection = '';


@Injectable()
export class TramitadorHistoricService {

  displayedItems = [];

  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {
    this.displayedItems['checkedValues'] = 0;
    this.displayedItems['checkedItems'] = Array();
  }

  getTramitacioDetail(idTramitacio: number, filter: ExpedienteFilter, paginator: PaginationFilter): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlTramitadorHistoricDetall'].replace('{id}', idTramitacio.toString()), new ExpedienteFilterService().createFilterParams(filter, paginator.pageNumber, paginator.pageSize, paginator.sortField, paginator.sortDir));
    }
  }

  getTramitacioDetailCount(idTramitacio: number, filter: ExpedienteFilter, paginator: PaginationFilter): any {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlTramitadorHistoricDetallCount'].replace('{id}', idTramitacio.toString()), new ExpedienteFilterService().createFilterParams(filter, paginator.pageNumber, paginator.pageSize, paginator.sortField, paginator.sortDir));
    }
  }

  anularTramitadorHistoric(idTramitacio: number) {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlTramitadorHistoricAnular'].replace('{id}', idTramitacio.toString()), null);
    }
  }

}
