import { Observable } from 'rxjs/Observable';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-upload-tool-button-component',
  templateUrl: './upload-tool-button-component.component.html',
  styleUrls: ['./upload-tool-button-component.component.scss']
})
export class UploadToolButtonComponentComponent implements OnInit {

  @Output() public uploadFile: EventEmitter<File> = new EventEmitter();
  @Input()
  set disabled(dis: boolean) {
    this.disabledValue = dis;
    this.color = this.colorValue;
  }
  get disabled() {
    return this.disabledValue;
  }
  @Input() text: string;
  @Input()
    set color(col: string) {
      this.colorValue = col;
      this.colorClass = 'actions-bar__button-icon';
      if (this.colorValue !== undefined) {
        if (!this.disabled) {
          this.colorClass += ' ' + this.colorValue;
        } else {
          this.colorClass += ' has-color-gray';
        }
      }
    } get color() {
      return this.colorValue;
    }
  @Input() icon: string;
  @Input() badge: string;
  @Input() badgeActive: boolean;
  @Input() badgeColor: string;
  @Input() propagate: boolean;
  @Input() isFile: boolean;

  disabledValue: boolean;
  colorClass: string;
  iconClass = 'fa fa-question';
  badgeClass: string;
  colorValue: string;
  tipuCamp: string;

  constructor() {
    this.propagate = false;
    this.badgeColor = 'badge-primary';
    this.badge = '';
    this.badgeActive = false;
    this.isFile = false;
   }

  ngOnInit() {
    console.log('upload file')
    this.badgeClass = 'badge badge-pill badge-pill-floating-right ';
    if (this.iconClass !== undefined) {
      this.iconClass = 'fa ' + this.icon;
    }
    if (this.isFile === null || this.isFile === undefined || this.isFile === false) {
      this.tipuCamp = 'hidden';
    } else {
      this.tipuCamp = 'file';
    }
  }


  fileReady(event) {
    
    if (!this.disabled) {
      this.uploadFile.emit(event);
    }
  }
  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      const file: File = event.target.files[0];
      this.uploadFile.next(file);
      //this.name = file.name;
      //this.size = file.size;
    }
  }
}
