import { I18n } from '@ngx-translate/i18n-polyfill';
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';
import { ToastrService } from 'ngx-toastr';


@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(
    public auth: AuthService,
    public router: Router,
    private toastr: ToastrService,
    private i18n: I18n
  ) {}
  canActivate(): boolean {
    if (!this.auth.isAuthenticated()) {
      if (!this.router.isActive('/login', false) && !this.router.isActive('/', true) && !this.router.isActive('/autoLogin', false) ) {
        this.toastr.error(this.i18n('Cal iniciar sessió per accedir a aquesta pàgina'), this.i18n('Atenció'), {
          closeButton: true
        });
      }
      if (!this.router.isActive('/login', false) && !this.router.isActive('/autoLogin', false)  ) {
        this.router.navigate(['login']);
      }
      if (this.router.isActive('/autoLogin', false)) {
        return true;
      }
      return false;
    }
    return true;
  }

  userLogged() {
    return this.auth.isAuthenticated();
  }

  userLoginCompleted() {
    const usr = sessionStorage.getItem('usuari');
    if (usr === null || usr === undefined) {
      return false;
    } else if (usr === 'null') {
      return false;
    } else {
      return true;
    }
  }

}
