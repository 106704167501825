import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { AliesGrua } from '../Models/alies-grua.model';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { AliesGruaFilter } from '../Models/alies-grua-filter';
import { AliesGruaFilterService } from './alies-grua-filter-service';

const defaultSortParam = 'codi';
const defaultSortDirection = 'desc';


@Injectable()
export class AliesGruaService {

  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {}

  getAllForCombo(): Observable<any> {
    if (this.authGuard.canActivate()) {
      const params = new AliesGruaFilterService().createFilterParams(new AliesGruaFilter ('descricpcio', 'asc'), 0, 99999, 'descripcio', 'asc');

      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAliesGruaForCombo'], params);
    }
  }

  setGruaAlies(aliesGrua: AliesGrua): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetGruaAlies'], aliesGrua);
    }
  }

  removeGruaAlies(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteGruaAlies'].replace('{secure_id}', secure_id), null);
    }
  }

  getGruaAlies(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetGruaAlies'].replace('{secure_id}', secure_id), null);
    }
  }

  generateExcel(filter: AliesGruaFilter, sortDir: string, sortField: string): Observable<any> {
    const start = 0;
    const pageSize = 1000;

    sortField = sortField === undefined ? defaultSortParam : sortField;
    sortDir = sortDir === '' ? defaultSortDirection : sortDir;
    const params = new AliesGruaFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

    if (this.authGuard.canActivate()) {
      return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlGruaAliesExcel'],  params );
    }
  }
}
