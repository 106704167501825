import { Component, OnInit, AfterViewInit, OnChanges, ViewChild, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { Builder } from 'path-shared/models/builder';
import { RecaudacioFilter } from '../../models/recaudacio-filter';
import { Recaudacio } from '../../models/recaudacio.model';
import { RecaudacioFilterService } from '../../services/recaudacio-filter.service';
import { RecaudacioService } from '../../services/recaudacio.service';
import { DateUtils } from 'path-shared/utils/date-utils';

@Component({
  selector: 'app-recaudacio-table',
  templateUrl: './recaudacio-table.component.html',
  styleUrls: ['./recaudacio-table.component.scss']
})
export class RecaudacioTableComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  data: Recaudacio[];
  dataSource: CustomDataSource<Recaudacio, RecaudacioFilter>;
  filter: RecaudacioFilter = new RecaudacioFilter('id', 'asc');
  displayedItems = [];
  highlightedRows = [];

  @Input() selectButton: boolean;
  @Input() updatedFilterData: RecaudacioFilter;
  @Output() itemClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() itemSelected: EventEmitter<string> = new EventEmitter<string>();

  displayedColumns = ['id', 'numColecta', 'fecha', 'maquina', 'zona', 'acumuladoMoneda'];

  constructor(
    private recaudacioService: RecaudacioService,
    private filterService: FilterService,
    private dialog: MatDialog,
    private i18n: I18n,
    private dateUtils: DateUtils
  ) {
  }

  ngOnInit() {
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<Recaudacio, RecaudacioFilter>(this.paginator, new RecaudacioFilterService(), 'urlGetRecaudacions', 'urlGetRecaudacionsCount');
    this.dataSource.subject.subscribe((data) => {
      data.forEach(item => {
        this.displayedItems[item.secure_id] = {
          id: item.secure_id
        };
      });
    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
        if (!this.selectButton) { this.filterService.saveFilter('recaudacio-filter', this.filter); }
      }
    });

    if (!this.selectButton) {
      this.normalizeFilterDates();

      this.filterService.loadFilter('recaudacio-filter', this.filter);
    }
    this.filter.paginator.init(this.sort, this.paginator);
  }

  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(tap(() => this.filter.updated()))
    .subscribe();

    this.load();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      this.update();
    }
  }

  update() {
    if (this.dataSource !== undefined) {
      this.normalizeFilterDates();

      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
    }
  }

  load() {
    this.normalizeFilterDates();

    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }

  onRowClicked(row) {
    this.itemClicked.next(row.secure_id);
  }

  generateExcel(): void {
    let filter = this.filter;
    if (this.updatedFilterData != null) {
      filter = this.updatedFilterData;
    }
    this.dialog.open(DownloadExcelDialogComponent, {
        closeOnNavigation: false,
        autoFocus: true,
        data: Builder.newDownloadExcelDialogData (this.recaudacioService, filter, this.i18n('Recaptació'), this.sort.direction, this.sort.active)
    });
  }

  normalizeFilterDates() {
    this.filter.fechaInicio = this.dateUtils.stringToDate(this.filter.fechaInicio);
    this.filter.fechaFin = this.dateUtils.stringToDate(this.filter.fechaFin);
  }

}
