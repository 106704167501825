import { FormService } from '../../../../../shared/services/form/form.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { StartupService } from '../../../../../shared/services/startup/startup.service';
import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { Proposta } from '../../../models/proposta.model';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { CFG_PAISES } from 'path-models/CFG_PAISES';
import { PropostesService } from 'path-propostes-services/propostes.service';
import { Reflection } from 'path-shared/services/reflection/reflection';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { PropostaDialogComponent } from '../../propostes-list/proposta-dialog/proposta-dialog.component';
import { PropostaReject } from '../../../models/proposta-reject.model';
import { Observable, BehaviorSubject } from 'rxjs';
import { Parametros } from 'path-shared/models/parametros';
import { ParametersService } from 'path-shared/services/parameters/parameters.service';

@Component({
  selector: 'app-propostes-editor',
  templateUrl: './propostes-editor.component.html',
  styleUrls: ['./propostes-editor.component.scss']
})
export class PropostesEditorComponent implements OnInit {
  paisos: CFG_PAISES[];
  copia: Proposta;
  relacionades: Proposta[];
  vehicles = [];
  private desestimar: BehaviorSubject<boolean>;

  @ViewChild('mainForm') private mainForm: ElementRef;

  constructor(
    private i18n: I18n,
    private toastr: ToastrService,
    private startupService: StartupService,
    private propostesService: PropostesService,
    public thisDialogRef: MatDialogRef<PropostesEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Proposta,
    private formService: FormService,
    private confirmationDialogService: ConfirmationDialogService,
    private dialog: MatDialog,
    private parametersService: ParametersService
  ) {
    this.desestimar = new BehaviorSubject<boolean>(false);
    this.relacionades = [];
    this.copia = Reflection.deepCopy<Proposta>(this.data);
    // detectar ESC
    this.thisDialogRef.afterClosed().subscribe(val => {
      if (val === undefined) {
        Reflection.copy(this.copia, this.data);
      }
    });
    this.propostesService.getPropostesRelacionades(this.data).subscribe(relacionades => {
      this.relacionades = relacionades;
    });
    const parameterValue = this.parametersService.getParameter(Parametros.PAIS_DEFECTO_PROPUESTAS);
    if (parameterValue !== null && parameterValue !== undefined && parameterValue !== '' && parameterValue !== 'N'
        && (this.copia.pais === undefined || this.copia.pais === null || this.copia.pais === '') ) {
        this.copia.pais = parameterValue ;
      }
  }


  public getdesestimar(): Observable<boolean> {
    return this.desestimar.asObservable();
}
  ngOnInit() {
    this.paisos = this.startupService.GetPaisos().filter(x => x.SIGLA !== null);
    this.vehicles = this.startupService.GetTipusVehicles();
  }

  disabledSaveButton() {
    return this.copia.MATRICULA === this.data.MATRICULA && this.copia.pais === this.data.pais && this.copia.VEHICLE === this.data.VEHICLE;
  }

  save() {
    if (this.formService.allFieldsValid(this.mainForm)) {
      if (this.relacionades.length > 0) {
        this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol guardar la matrícula i desestimar la resta de propostes?'))
        .then((confirmed) => {
          if (confirmed) {
            this.confirm();
          }
        });
      } else  {
        this.confirm();
      }
    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
    }
  }

  private confirm() {
    this.propostesService.ValidarMatriculaAutorizada(this.copia.MATRICULA, this.copia.SECURE_ID).subscribe(autorizada => {
      if (autorizada) {
        this.confirmationDialogService.confirm(this.i18n('Atenció'), this.i18n('La nova matricula pertany a una llista autoritzada. \nSi accepta el canvi es desestimarà la denúncia de forma automàtica'))
        .then((confirmed) => {
          if (confirmed) {
            const propostaData: PropostaReject = {
              ID_MOTIU_DESESTIMAR_PROPOSTA: 99,
              TEXT_DESESTIMAR_PROPOSTA: 'Matrícula desestimada automàticament (Està en llista autoritzada)',
              propostes: []
            };
            this.propostesService.saveProposta(this.copia).subscribe(result => {
              this.data.pais = this.copia.pais;
              this.data.MATRICULA = this.copia.MATRICULA;
              this.data.VEHICLE = this.copia.VEHICLE;
              this.propostesService.rejectProposta(this.copia.SECURE_ID, propostaData).subscribe(() => {
                this.toastr.info(this.i18n('Proposta desestimada de forma automàtica'));
                this.data.proposta = 2;
                this.thisDialogRef.close(true);

              });
            });

          }
        });
      } else {
        this.propostesService.saveProposta(this.copia).subscribe(result => {
          this.data.pais = this.copia.pais;
          this.data.MATRICULA = this.copia.MATRICULA;
          this.data.VEHICLE = this.copia.VEHICLE;
          this.toastr.info(this.i18n('Proposta modificada correctament'));
          this.thisDialogRef.close(true);
        });
      }
    });

  }

  cancel() {
    // Reflection.copy(this.copia, this.data);
    this.thisDialogRef.close(false);
  }

  getTitle() {
    return this.i18n('Editar proposta') + ' ' + this.data.NUMDENUNCIA;
  }

  veurePropostaRelacionada(row) {
      const ref = this.dialog.open(PropostaDialogComponent, {
        width: '95%',
        height: '95%',
        closeOnNavigation: false,
        autoFocus: true,
        data: row
      });
  }

}
