import { Injectable } from '@angular/core';
import { HttpSecureClient } from '../http/httpSecureClient';
import { api } from '../../configuration/urls';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { HttpResponse, HttpParams } from '@angular/common/http';
import { AuthGuardService } from '../authentication/auth-guard.service';

@Injectable()
export class FilesService {
  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {}

  public getFile(ruta: string): Observable<HttpResponse<Blob>> {
    const url = config.urls['apiUrl'] + api.endpoints['UrlGetFileRuta'];
    return this.http.getFileResponse(url, new HttpParams().append('rutaFichero', ruta));
  }

  public getFileSomId(somid: number): Observable<any> {
    return this.http.getPdf(config.urls['apiUrl'] + api.endpoints['urlGetFile'].replace('{somId}', somid.toString()), null);
  }

  public getExportFileVirtualUrl(rutaFichero): string {
    let url;
    if (rutaFichero.includes('exportaciones')) {
      const rutaVirtual = '/exportaciones' + rutaFichero.split('exportaciones').pop();
      url = config.urls['identityUrl'].replace('{hostname}', window.location.hostname) + rutaVirtual;
    } else if (rutaFichero.includes('Plantillas')) {
      url = config.urls['identityUrl'].replace('{hostname}', window.location.hostname) + rutaFichero;
    } else {
      url = 'NotFound';
    }
    return url;
  }

  public uploadFile(data: FormData, isPrimeras: boolean): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlUploadFileAlegacions'] + '/' + isPrimeras, data)
    }
  }
}
