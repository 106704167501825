import { HttpParams } from '@angular/common/http';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';

const defaultSortParam = 'rangoSuperior';
const defaultSortDirection = 'desc';

export class EscalaOcupacionFilterService extends CustomFilterService {

    public createFilterParams(filter: any, start: number, pageSize: number, sortField: string, sortDir: string): HttpParams {
        return new HttpParams()
        .append('color', filter.color ? filter.color : '')
        .append('rangoSuperior', filter.rangoSuperior ? filter.rangoSuperior : '')
        .append('start', start.toString())
        .append('size', pageSize.toString())
        .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
        .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    }
}
