<div style="overflow: visible;">
  <ng-img-magnifier *ngIf="this.showLupa"  class="img-zoom-container"
    [thumbImage]="this.zoomImageSrc" [fullImage]="this.previewImageSrc"
    [lensWidth]="70" [lensHeight]="70"
    [resultWidth]="50" [resultHeight]="50"
    [right]="this.magnifierRight" [top]="this.magnifierTop"
    >
    Navegador no compatible
  </ng-img-magnifier>
</div>

<div *ngIf="!this.showLupa">
  <div style="padding: 2px;">
    <!-- dualidad entre imagen normal (denuncia) y imagen svg -->
    <div [id]="this.imageDiv" (click)="showImage()">
        <div *ngIf="!video && !this.downloadable && this.proposta === undefined">
            <img [src]="this.imageSource"  (title)="this.nom">
        </div>
    </div>

    <div *ngIf="!video && this.downloadable">
        <a *ngIf="!this.pdf"[href]="this.fileSource" target="_blank"><img [src]="this.imageSource" (title)="this.nom"></a>
        <div class="pdf" style="text-align: center;">
            <!-- <embed *ngIf="this.pdf" [atrr.src]="this.fileSource" type="application/pdf" width="420px" height="350px"> -->
            <div *ngIf="innerHtml"
                [innerHTML]="innerHtml">
            </div>
            <a *ngIf="this.pdf"[href]="this.fileSource" target="_blank"i18n><br>Obrir PDF en una pestanya nova</a>
        </div>
    </div>

    <div *ngIf="video">
        <video controls>
            <source [src]="this.fileSource" type="video/mp4">
                Navegador no compatible
        </video>
    </div>
</div>

</div>

    