import { Component, OnInit, Inject, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { SingleEditDialogData } from './single-edit-dialog-data.model';
import { timer } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-single-edit-dialog',
  templateUrl: './single-edit-dialog.component.html',
  styleUrls: ['./single-edit-dialog.component.scss']
})
export class SingleEditDialogComponent implements OnInit {
  @Input() title: string;
  @Input() text: string;

  options: SingleEditDialogData;
  oldValue: string;
  @ViewChild('textfield') textField: ElementRef;

  constructor(
    public thisDialogRef: MatDialogRef<SingleEditDialogComponent>,
    private i18n: I18n,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: SingleEditDialogData,
    ) {
      this.options = data;
      this.oldValue = this.options.value;
    }

  ngOnInit() {
    this.focus();
  }

  private focus() {
    timer(500).subscribe(done => this.textField.nativeElement.focus());
  }

  acceptValue() {
    if (this.options.value !== this.oldValue) {
      let ok = true;
      if (this.options.mask !== null) {
        ok = this.options.mask.test(this.options.value);
        if (!ok) {
          this.toastr.warning(this.options.maskError);
          this.focus();
        }
      }

      if (ok) {
        this.thisDialogRef.close(this.options.value);
      }
    } else {
      this.close();
    }
  }

  close() {
    this.thisDialogRef.close();
  }

}
