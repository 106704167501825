import { I18n } from '@ngx-translate/i18n-polyfill';
import { UsuarisService } from './../../usuaris/services/usuaris.service';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { throwError, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import * as querystring from 'querystring';
import { LoginModel } from '../models/login.model';

import { api } from '../../../shared/configuration/urls';
import { ToastrService } from 'ngx-toastr';
import { StartupService } from '../../../shared/services/startup/startup.service';
import { Instituciones } from 'path-shared/models/instituciones';
import { config } from 'path-shared/configuration/config';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class LoginService {
  loginFinished: Subject<boolean>;
  public wongInstitutionDetected = new EventEmitter();

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private toastr: ToastrService,
    private i18n: I18n
  ) {
    this.loginFinished = new Subject<boolean>();
    this.loginFinished.next(false);
  }

  getInstitucions(): Observable<Instituciones[]> {
    const httpHeaders = new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    });
    return this.http.get<Instituciones[]>(
      config.urls['apiUrl'].replace('{hostname}', window.location.hostname) +
        api.endpoints['urlInstituciones'],
      {
        headers: httpHeaders
      }
    );
  }

  getInstitucio(id: string): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    });
    return this.http.get<Instituciones>(
      config.urls['apiUrl'].replace('{hostname}', window.location.hostname) +
        api.endpoints['urlInstitucion'].replace('{id}', id),
      {
        headers: httpHeaders
      }
    ).catch((e: any) =>  { this.handleError(e); return new Observable<Instituciones>(); });
  }

  private handleError(error: any) {
    switch (error.status) {
      case 404:
        this.toastr.error(this.i18n('Institució incorrecte!'));
        this.wongInstitutionDetected.emit(true);
        break;
      default:
      this.toastr.error(this.i18n('No hi ha connexió o s\'ha produït un error en el servidor!'));
    }
  }

  // public login(): void { }
  public login(login: LoginModel): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*'
    });

    const body = querystring.stringify(login);

    return this.http
      .post<any>(
        config.urls['identityUrl'].replace(
          '{hostname}',
          window.location.hostname
        ) + api.endpoints['token'],
        body,
        {
          headers: httpHeaders
        }
      )
      .pipe(
        map((res: any) => {
          sessionStorage.setItem('access_token', res.access_token);
          localStorage.setItem('access_token', res.access_token);
          const dt = new Date().getTime() + res.expires_in * 1000;
          sessionStorage.setItem('expiration_date', dt.toString());
          sessionStorage.setItem('IdInstitucion', login.institution);
          localStorage.setItem('IdInstitucion', login.institution);
          this.cookieService.set('InstitucioSeleccionada', login.institution);
          return true;
        }),
        catchError(err => {
          console.log(err);
          let errorMessage = this.i18n('Usuari o clau incorrectes!');
          if (err.error.error_description !== null) {
            errorMessage = err.error.error_description;
          }

          if (err.status === 0) {
            errorMessage = this.i18n('El servidor està desconnectat!');
          }

          this.toastr.error(errorMessage, 'Oops!', { closeButton: true });

          return throwError(err);
        })
      );
  }
  setCredentials(token: string, expires_in: any, instit: string): boolean {
    try {
      sessionStorage.setItem('access_token', token);
      localStorage.setItem('access_token', token);
      const dt = new Date().getTime() + expires_in * 1000;
      sessionStorage.setItem('expiration_date', dt.toString());
      sessionStorage.setItem('IdInstitucion', instit);
      localStorage.setItem('IdInstitucion', instit);
      this.cookieService.set('InstitucioSeleccionada', instit);
      return true;
    } catch (error) {
      console.error(error);
      return false;
      // expected output: ReferenceError: nonExistentFunction is not defined
      // Note - error messages will vary depending on browser
    }


  }
  loginComplete(): any {
    this.loginFinished.next(true);
  }

}
