import { Component, OnInit, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { PermissionDialogComponent } from 'path-shared/components/permission-dialog/permission-dialog.component';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { VehiclesTableComponent } from '../../components/vehicles-table/vehicles-table.component';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { VehicleFilter } from '../../models/vehicle-filter';
import { VehiclesFilterComponent } from '../../components/vehicles-filter/vehicles-filter.component';
import { VehiclesEditorComponent } from '../../components/vehicles-editor/vehicles-editor.component';

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.scss']
})
export class VehiclesComponent implements OnInit, AfterViewInit {

  permissionDialogRef: MatDialogRef<PermissionDialogComponent>;
  activeFilters: string[] = [];
  filterData: VehicleFilter;
  somIdFoo: number;
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  @ViewChild(VehiclesTableComponent) vehiclesTable: VehiclesTableComponent;

  constructor(
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private i18n: I18n,
    private not: NotificationService,
    public thisDialogRef: MatDialogRef<VehiclesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
  ) {
    this.filterData = new VehicleFilter('', '');
      if (data !== undefined) {
        this.dataDialog = data;

      }
  }


  ngOnInit() {
    this.vehiclesTable.itemClicked.subscribe(secure_id => {
      this.update(secure_id);
    });
    this.vehiclesTable.itemSelected.subscribe(codi => {
      this.thisDialogRef.close(codi);
    });

  }

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Vehicles'));
    }
  }
  create() {
    this.update(null);
  }

  update(secure_id: string) {
    const data: ComponentDialog = Builder.newComponentDialog(true, false, secure_id);
    const ref = this.dialog.open(VehiclesEditorComponent, {
      width: '40%',
      closeOnNavigation: false,
      autoFocus: true,
      data: data
    });


    ref.afterClosed().subscribe(updated => {
      if (updated) { this.vehiclesTable.load(); }
    });
  }

  refresh() {
    this.vehiclesTable.load();
  }

  onFilterClicked() {
    const ref = this.dialog.open(VehiclesFilterComponent, {
    width: '50%',
    closeOnNavigation: false,
    autoFocus: true,
    data: {
      'filter': this.vehiclesTable.filter
    }
  });

  ref.componentInstance.notify.subscribe((filter: VehicleFilter) => {
    this.vehiclesTable.filter.set(filter);
    this.vehiclesTable.load();
  });
}

onRowSelected(somid) {
  this.update(somid);
}

generateExcel() {
  this.vehiclesTable.generateExcel();
}

}
