export class Tancaments {
    EXP: string;
    DataExecut: Date;
    Ofici: Date;
    Tancat: boolean;
    Fecha_Prescripcion: Date;
    Fecha_Caducidad: Date;
    DataCobra: Date;
    DataAnul: Date;
    NumDenuncia: String;
    Data: Date;
    Matricula: String;
    ImportEuros: number;
    Id_Instit: string;
    SomIdDenuncia: string;
    Check: boolean;
    Resposta: string;

    constructor() {
      this.EXP = '';
      this.DataExecut = null;
      this.Ofici = null;
      this.Tancat = false;
      this.Fecha_Prescripcion = null;
      this.Fecha_Caducidad = null;
      this.DataCobra = null;
      this.NumDenuncia = '';
      this.Data = null;
      this.Matricula = '';
      this.ImportEuros = 0;
      this.Id_Instit = '';
      this.SomIdDenuncia = '';
      this.Check = false;
      this.Resposta = '';
    }
  }
  
  