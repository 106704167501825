import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class AnulacionZonaAzulAutoFilter extends FilterData {
  fechaInicio: Date;
  fechaFin: Date;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);

    const date = new Date();
    const dateCurrentDay = new Date(date.getTime());

    const datePastWeek = new Date(date.getTime());
    datePastWeek.setDate(date.getDate() - 7);

    this.fechaInicio = datePastWeek;
    this.fechaFin = dateCurrentDay;
  }
}
