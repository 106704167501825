<div class="card">
  <div class="card-body">
    <h5 class="card-title">{{this.title}}</h5>
    <mat-radio-group [(ngModel)]="this.value">
      <mat-radio-button *ngFor="let key of this.labels.length | serie" [value]="this.values[key]" style="padding-left:20px; padding-right:20px; ">
        {{this.labels[key]}}
      </mat-radio-button>
    </mat-radio-group>
  </div>
</div>



