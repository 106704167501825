<app-dialog-header *ngIf="this.dataDialog.isDialog" label="Propostes" i18n-label></app-dialog-header>
<app-toolbar>
    <app-tool-button text="Actualitzar" i18n-text (click)="updatePropostes();" color="has-color-blue" icon="fa-sync"></app-tool-button>
    <app-tool-button text="Filtre" i18n-text (click)="onFilterClicked();" color="has-color-orange" icon="fa-filter" [hidden]="this.hideFilter"></app-tool-button>
    <app-tool-button text="Excel" i18n-text (click)="generateExcel();" color="has-color-darkgreen" icon="fa-file-excel"></app-tool-button>
    <app-tool-button text="Classificar" i18n-text (click)="classificarPropostes();" color="has-color-yellow" icon="fa-clock" [hidden]="this.hideFilter"></app-tool-button>
    <app-tool-button text="Nova operació" i18n-text (click)="NovaOperacio();" color="has-color-purple" icon="fa-plus-circle"  [disabled]="(selectCheckedItems() >= 1 ? false : true) || !this.auth.UserCanWrite(this.auth.Pages.Propostes)"></app-tool-button>
    <app-tool-button-permissions [permission]="this.auth.Pages.Propostes" [hidden]="this.hideFilter"></app-tool-button-permissions>
</app-toolbar>

<app-propostes-table [updatedFilterData]="filterData" (notify)="onRowSelected($event)"></app-propostes-table>
