import { Component, OnInit, ViewChild, Input, Output, EventEmitter, SimpleChanges, AfterViewInit, OnChanges } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { TipusTiquetsFilter } from '../../models/tipus-tiquets-filter';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TipusTiquets } from '../../models/tipus-tiquets.model';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { TipusTiquetsService } from '../../services/tipus-tiquets.service';
import { TipusTiquetsFilterService } from '../../services/tipus-tiquets-filter.service';

@Component({
  selector: 'app-tipus-tiquets-table',
  templateUrl: './tipus-tiquets-table.component.html',
  styleUrls: ['./tipus-tiquets-table.component.scss']
})
export class TipusTiquetsTableComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  data: TipusTiquets[];
  dataSource: CustomDataSource<TipusTiquets, TipusTiquetsFilter>;
  filter: TipusTiquetsFilter = new TipusTiquetsFilter('codi', 'asc');
  displayedItems = [];
  highlightedRows = [];

  @Input() selectButton: boolean;
  @Input() updatedFilterData: TipusTiquetsFilter;
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();
  @Output() itemClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() itemSelected: EventEmitter<string> = new EventEmitter<string>();

  displayedColumns = ['codi', 'descripcio', 'dataInici', 'dataFi'];

  constructor(
    private festiusService: TipusTiquetsService,
    private filterService: FilterService,
    private dialog: MatDialog,
    private i18n: I18n
  ) {
  }

  ngOnInit() {
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<TipusTiquets, TipusTiquetsFilter>(this.paginator, new TipusTiquetsFilterService(), 'urlGetTipusTiquets', 'urlGetTipusTiquetsCount');
    this.dataSource.subject.subscribe((data) => {
      data.forEach(item => {
        this.displayedItems[item.secureID] = {
          id: item.secureID
        };
      });
    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
        if (!this.selectButton) { this.filterService.saveFilter('tipusTiquets-filter', this.filter); }
      }
    });

    if (!this.selectButton) { this.filterService.loadFilter('tipusTiquets-filter', this.filter); }
    this.filter.paginator.init(this.sort, this.paginator);
  }

  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(tap(() => this.filter.updated()))
    .subscribe();

    this.load();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      this.update();
    }
  }

  update() {
    if (this.dataSource !== undefined) {
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
    }
  }

  load() {
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }

  onRowClicked(row) {
    this.itemClicked.next(row.secureID);
  }


}
