import { InfraccioService } from './../../../configuracio/Infraccions/services/infraccions-service.service';
import { ZonesModel } from './../../../ciutat/zones/models/zones.model';
import { ZonesService } from './../../../ciutat/zones/services/zones.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { ZB_ZONAS } from 'path-models/ZB_ZONAS';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { Builder } from 'path-shared/models/builder';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { InstitucionsService } from 'path-shared/services/institucions/institucions.service';
import { EstadisticaDto } from '../../models/estadisticaDto';
import { FiltroTiempoEstadisticas } from '../../models/filtro-tiempo.model';
import { EstadistiquesService } from '../../services/estadistiques.service';
import { AgentsService } from 'app/sections/configuracio/agents/services/agents-service.service';

@Component({
  selector: 'app-estadisticas-denuncias',
  templateUrl: './estadisticas-denuncias.component.html',
  styleUrls: ['./estadisticas-denuncias.component.scss']
})
export class EstadisticasDenunciasComponent implements OnInit {
  //config grafico
  public barChartOptions: ChartOptions = {
    responsive: true,
  };
  colors = [
    { // ventas
      backgroundColor: 'rgba(0, 204, 102, 0.8)'
    },
    { // devol
      backgroundColor: 'rgba(255, 99, 132, 0.8)'
    },
    { // anul
      backgroundColor: 'rgba(54, 162, 235, 0.8)'
    }
  ]
  colorsDevol = [
    { // devol
      backgroundColor: 'rgba(255, 99, 132, 0.8)'
    }
  ]
  colorsAnul = [
    { // anul
      backgroundColor: 'rgba(54, 162, 235, 0.8)'
    }
  ]
  public barChartType: ChartType = 'bar';
  barChartData: ChartDataSets[];
  barChartDataMultiLine: ChartDataSets[];
  barChartLabels: any;
  public dataRecived: boolean = false;
  public dateSelected: Date = new Date();
  //public tipos = [{label:FiltroAparcareTipo.Ventas},{label:FiltroAparcareTipo.Anulaciones},{label:FiltroAparcareTipo.Devoluciones}, {label:FiltroAparcareTipo.Todos}];
  //public tipoSelected:string =FiltroAparcareTipo.Ventas;
  public showMultiLine: boolean = false;
  public tipoConsultado: string;
  public tiposTemporizacion = [{ label: FiltroTiempoEstadisticas.Anual }, { label: FiltroTiempoEstadisticas.Mensual }, { label: FiltroTiempoEstadisticas.Semanal }, { label: FiltroTiempoEstadisticas.Diario }];
  public tipoTemporizacionSelected: string = FiltroTiempoEstadisticas.Mensual;
  //public allTipos= FiltroAparcareTipo;
  public datos: EstadisticaDto = { chartDataSets: [], chartLabels: [], chartColors: [] }
  public isZonaAzul: boolean = false;
  public zonaSelected: string;
  public zonas: ZonesModel[];
  public infraccionesDenuncies: any[];
  public infraccionSelected: string;

  agents = [];
  public agenteSelected: string;

  anuladaItems = [];
  public anuladaSelected: boolean;
  infraccionesDenunciesAll: any[];
  constructor(private estadistiquesService: EstadistiquesService,
    private institutionService: InstitucionsService,
    private authService: AuthorizationService,
    private zonasService: ZonesService,
    private infraccionsService: InfraccioService,
    private agentsService: AgentsService,
    private dialog: MatDialog,
    private i18n: I18n,
    public auth: AuthorizationService,) {
    this.barChartLabels = [];
    this.barChartData = [];
    this.barChartDataMultiLine = [];

    this.isZonaAzul = this.authService.CheckUserRole(this.authService.Roles.ZonaBlava);
    this.anuladaItems.push({ value: true, text: 'Sí' });
    this.anuladaItems.push({ value: false, text: 'No' });
    this.zonasService.getAllZonas().subscribe(x => {

      if (x.some(z => z.ID_ZONA_PADRE !== null)) {
        this.zonas = x.filter(zona => zona.ID_ZONA_PADRE !== null);
      }
      else {
        this.zonas = x;
      }
      this.zonas.unshift(Builder.newZona())

    });
    this.infraccionsService.getInfraccionsDenunciesCombo().subscribe(result => {
      this.infraccionesDenuncies = result;
      this.infraccionesDenunciesAll = this.infraccionesDenuncies;
    });

    this.agentsService.getAllCombo().subscribe(result => {
      this.agents = [];
      if (result !== null) {
        let i: number;
        i = 0;
        result.forEach(element => {
          this.agents[i] = element;
          i++;
        });
      }
      this.agents.unshift({ 'codi': '' });
    });
  }

  ngOnInit() {
    //this.reload();
  }
  reload() {
    let filter = this.getFilter();
    this.estadistiquesService.getEstadistiquesDenuncias(filter).subscribe(x => {

      this.dataRecived = true;
      this.datos = x;
      this.barChartData = [];
      Object.assign([], this.barChartData);
      this.barChartDataMultiLine = [];
      Object.assign([], this.barChartDataMultiLine);
      if (!this.barChartLabels) {
        this.barChartLabels = [];
      }
      this.barChartLabels.length = 0;
      this.barChartLabels.push(...Object.values(this.datos.chartLabels.barChartLabels));

      this.barChartData.length = 0;
      this.barChartDataMultiLine.length = 0;
      if (x.chartDataSets.length > 1) {
        this.showMultiLine = true;
        x.chartDataSets.forEach(element => {
          this.barChartDataMultiLine.push({
            data: element.data, label: element.label
          });
        });
      } else {
        this.showMultiLine = false;
        this.barChartData.push({ data: x.chartDataSets[0].data, label: x.chartDataSets[0].label }
        );
      }

    });
  }
  canSelectZona(): boolean {
    // if(this.tipoSelected === FiltroAparcareTipo.Ventas){
    return true;
    // }else{
    //   this.zonaSelected='';
    //   return false;
    // }
  }
  onDateChange(): void {

    this.reload();
  }

  getFilter(): any {
    let filter = { fecha: this.dateSelected, tipoTemporizacion: this.tipoTemporizacionSelected, zonaSecureId: null, infraccion: null, agente: null, anulada: null };
    if (this.zonaSelected !== undefined && this.zonaSelected !== null && this.zonaSelected !== '') {
      filter.zonaSecureId = this.zonaSelected;
    }
    if (this.infraccionSelected !== undefined && this.infraccionSelected !== null && this.infraccionSelected !== '') {
      filter.infraccion = this.infraccionSelected;
    }
    if (this.agenteSelected !== undefined && this.agenteSelected !== null && this.agenteSelected !== '') {
      filter.agente = this.agenteSelected;
    }

    if (this.anuladaSelected !== undefined && this.anuladaSelected !== null) {
      filter.anulada = this.anuladaSelected;
    }
    return filter;
  }

  generateExcel(): void {
    let filter = this.getFilter();
    this.dialog.open(DownloadExcelDialogComponent, {
      closeOnNavigation: false,
      autoFocus: true,
      data: Builder.newDownloadExcelDialogDataGeneral(this.estadistiquesService, filter, this.i18n('Denuncias'))
    });
  }
  restartFilter() {
    this.zonaSelected = '';
    this.infraccionSelected = '';
    this.agenteSelected = '';
    this.anuladaSelected = null;
  }

  filterInfracciones() {
    if (this.zonaSelected !== undefined) {
      const zonaCleaned = this.zonaSelected.split('|')[0];
      this.infraccionesDenuncies = this.infraccionesDenunciesAll.filter(x => x.additionalData === zonaCleaned || x.additionalData === null)
    } else {
      this.infraccionesDenuncies = this.infraccionesDenunciesAll;
    }
  }
}
