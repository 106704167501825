import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class AnulAutoZBHistoricFilter extends FilterData {


  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);

  }
}
