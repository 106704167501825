<app-toolbar>
  <app-tool-button text="Consultar" i18n-text (click)="this.filtrar()" color="has-color-blue" icon="fa-sync"></app-tool-button>
  <app-tool-button text="Publicar" i18n-text (click)="this.publicar()" color="has-color-green" icon="fa-file-export"></app-tool-button>
  <!-- <app-tool-button-permissions [permission]="this.auth.Pages.Tramitador"></app-tool-button-permissions> -->
</app-toolbar>

  <!-- <div class="row">
    <app-form-text-edit id="boe-general-ruta" class="col-xl-4" i18n-label label="Ruta"
    [(value)]="this.value.rutaP12"  [isReadOnly]="false" ></app-form-text-edit>

    <app-form-text-edit id="boe-general-dir3Code" class="col-xl-4" i18n-label label="Codigo DIR3"
    [(value)]="this.value.dir3Code"  [isReadOnly]="false" ></app-form-text-edit>
    
    <app-form-text-edit id="boe-general-dir3Nombre" class="col-xl-4" i18n-label label="Nombre DIR3"
    [(value)]="this.value.dir3Nombre"  [isReadOnly]="false" ></app-form-text-edit>
  </div>-->
<div style="display: flex; max-width: fit-content; justify-content: space-between;">
  <div class="row" style="justify-content: space-between;">
    <div style="display: flex; flex-direction: column; align-items: flex-start; max-width: fit-content; padding-left: 3vh;">
      <ng-label i18n style="align-self: center;">Tipo de envío</ng-label>
      <ng-multiselect-dropdown style="width: 200px!important;" [data]="this.dropdownEnvios" (onSelect)="this.onDropdownItemSelect($event)" [settings]="this.dropdownSettings" [placeholder]="'Tipo de envío'"></ng-multiselect-dropdown>
    </div>

    <div style="display: flex; flex-direction: column; align-items: flex-start; max-width: fit-content;">
      <ng-label i18n style="align-self: center;">Exportar a </ng-label>
      <mat-radio-group [(ngModel)]="this.value.tipusExportacion" style="padding-right: 3vh; padding-left: 3vh;">
        <mat-radio-button [value]="1" style="margin-top: 2vh;">
          <label i18n>XML&nbsp; &nbsp; &nbsp;</label>
        </mat-radio-button>
        <mat-radio-button [value]="2">
          <label i18n>WEB&nbsp; &nbsp; &nbsp;</label>
        </mat-radio-button>
        <mat-radio-button [value]="3">
          <label i18n>CSV&nbsp; &nbsp; &nbsp;</label>
        </mat-radio-button>
      </mat-radio-group>
    </div> 
  </div>      

  <div style="display: flex; flex-direction: column;">   
    <span *ngIf="this.valueResumen !== undefined && this.valueResumen !== null && this.value.envioDenuncias">            
      <span i18n>Denuncias: {{this.valueResumen.numDenuncias }}</span>
    </span>         
    <span *ngIf="this.valueResumen !== undefined && this.valueResumen !== null && (this.value.envioAlegaciones || this.value.envioRecursoAlegaciones)">            
      <span i18n>Alegaciones: {{this.valueResumen.numAlegaciones }}</span>
    </span>            
    <span *ngIf="this.valueResumen !== undefined && this.valueResumen !== null && this.value.envioResoluciones">            
      <span i18n>Recurso resoluciones: {{this.valueResumen.numResoluciones }}</span>
    </span>          
    <span *ngIf="this.valueResumen !== undefined && this.valueResumen !== null && this.value.envioSancionesFirmes">            
      <span i18n>Sanciones firmes: {{this.valueResumen.numSancionesFirmes }}</span>
    </span>
  </div>

</div>
<div style="display: flex; ">
  <app-form-date-edit id="filter-envio-boe-fechaPub" i18n-label label="Fecha publicación" [(value)]="this.value.fechaPub" style="padding-right: 3vh; padding-left: 3vh; min-width: fit-content;"></app-form-date-edit>  
  <app-form-date-edit id="filter-envio-boe-fechaEdicto" i18n-label label="Fecha edicto" [(value)]="this.value.fechaEdicto" style="padding-right: 3vh; padding-left: 3vh; min-width: fit-content;"></app-form-date-edit> 
</div>