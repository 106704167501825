<app-dialog-header *ngIf="this.dataDialog.isDialog" [label]="this.dataDialog.title"></app-dialog-header>
<!--<app-toolbar>
  <app-tool-button text="Tancar" i18n-text (click)="this.close();" color="has-color-red" icon="fa-times"></app-tool-button>
</app-toolbar>
-->
<div class="row">
    <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
            <li *ngFor="let image of this.images; let i = index" data-target="#carouselExampleIndicators"></li>
        </ol>
        <div class="carousel-inner">
          <div *ngFor="let image of this.images; let i = index"  class="carousel-item" [ngClass]="[ i === 0 ? 'active' : '']" >
            <img class="d-block" [src]="image" (click)="mostraOriginal(image)" >
          </div>
        </div>
        <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only" i18n>Anterior</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only" i18n>Següent</span>
        </a>
      </div>
</div>
