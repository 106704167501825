import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { DeteccioMatriculaFilter } from '../models/deteccio-matricula-filter';
import { HttpParams } from '@angular/common/http';
import { Reflection } from 'path-shared/services/reflection/reflection';

const defaultSortParam = 'data';
const defaultSortDirection = 'desc';

@Injectable({
  providedIn: 'root'
})
export class DeteccioMatriculesFilterService  extends CustomFilterService {
  public createFilterParams(filter: DeteccioMatriculaFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {
    return new HttpParams()
    .append('matricula', filter.matricula ? filter.matricula : '')
    .append('dataInici', Reflection.empty(filter.dataInici) ? null : filter.dataInici.toJSON())
    .append('dataFi', Reflection.empty(filter.dataFi) ? null : filter.dataFi.toJSON())
    .append('idTipusMatricula', Reflection.hasValue(filter.idTipusMatricula) ? filter.idTipusMatricula.toString() : null)
    .append('any', filter.any ? filter.any.toString() : null)
    .append('mes', filter.mes ? filter.mes.toString() : null)
    .append('dia', filter.dia ? filter.dia.toString() : null)
    .append('agent', filter.agent)
    .append('PDA', filter.PDA)
    .append('aparell', filter.aparell)
    .append('comprovacio', filter.comprovacio)
    .append('idDenuncia', filter.idDenuncia ? filter.idDenuncia.toString() : null)
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir)
    ;
  }

  public createLoadParams(content: string) {
    return new HttpParams()
    .append('content', content ? content : '')
    ;
  }


}
