import { FilterData } from './../../../models/filter-data';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-filter-selector',
  templateUrl: './filter-selector.component.html',
  styleUrls: ['./filter-selector.component.scss']
})
export class FilterSelectorComponent implements OnInit {
  @Input() filter: FilterData;
  @Input() exclude: string[];

  items = [];

  constructor() {
  }

  ngOnInit() {
    if (this.filter !== undefined) {
      this.filter.filterUpdated.subscribe(() => {
        this.items = this.filter.filterItems();
      });
      this.items = this.filter.filterItems();
    }
  }

  private validProperty(property) {
    if (this.exclude !== undefined) {
      if (this.exclude.indexOf(property) === -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  valid(tag): boolean {
    if (!this.validProperty(tag.property)) {
      return false;
    } else {
      return tag.value !== null && tag.value !== undefined && tag.value !== '';
    }
  }

  getLabel(tag) {
    return this.filter.getLabel(tag);
  }

  getValue(tag) {
    return this.filter.getValue(tag);
  }

  remove(tag) {
    this.filter.remove(tag);
  }
}
