<app-toolbar>  
  <app-tool-button-permissions [permission]="this.auth.Pages.Estadistiques"></app-tool-button-permissions>
  <app-tool-button text="Excel" i18n-text (click)="generateExcel();" color="has-color-darkgreen" icon="fa-file-excel"></app-tool-button>
  <app-tool-button text="Reiniciar filtros" i18n-text (click)="this.restartFilter();" color="has-color-blue" icon="fa-sync" badge="new" ></app-tool-button>
</app-toolbar>
<div class="text-center">
  <h5  i18n-label>Estadísticas denuncias</h5>
</div>
<div class="row justify-content-around">
  <app-form-date-edit id="estadistiques-mensuals-data" [view]="'year'" class="col-2" i18n-label label="Fecha final período" [(value)]="dateSelected"></app-form-date-edit>
  <!-- <app-form-combo-edit  class="col-2" i18n-label label="Información" [(value)]="tipoSelected"
    [items]="tipos" valueField="label" textField="label"></app-form-combo-edit> -->
    <app-form-combo-edit class="col-2" i18n-label label="Agrupación" [(value)]="tipoTemporizacionSelected"
    [items]="tiposTemporizacion" valueField="label" textField="label"></app-form-combo-edit>
    <app-form-combo-edit class="col-2" *ngIf="isZonaAzul" i18n-label label="Zona" [isReadOnly]="!this.canSelectZona()" [(value)]="zonaSelected"
    [items]="zonas" valueField="SECURE_ID" textField="descripcio" (valueChange)="filterInfracciones()"></app-form-combo-edit>
    <app-form-combo-edit class="col-2" i18n-label label="Infracción"  [(value)]="infraccionSelected"
    [items]="infraccionesDenuncies" valueField="codi" textField="descripcio"></app-form-combo-edit>
    <app-form-combo-edit class="col-2" i18n-label label="Agente"  [(value)]="agenteSelected"
    [items]="agents" valueField="codi" textField="codi"></app-form-combo-edit>
    <app-form-combo-edit class="col-2" i18n-label label="Anuladas"  [(value)]="anuladaSelected"
    [items]="anuladaItems" valueField="value" textField="text"></app-form-combo-edit>
    
<button class="btn btn-primary button-not-allowed btn-lg col-2" type="button" (click)="this.reload()">
  <i class="fas fa-sync-alt"></i> &nbsp;<span i18n>Consultar</span>  &nbsp;
</button>
</div>


<div class="row" *ngIf="barChartLabels && barChartData && !showMultiLine">
  <div class="col-10">
    <canvas *ngIf="dataRecived" baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
    [legend]="true" [chartType]="barChartType" [colors]="this.colors">
    </canvas>
  </div>
</div>

<div class="row" *ngIf="barChartLabels && barChartData && showMultiLine">
  <div class="col-10">
    <canvas *ngIf="dataRecived" baseChart [datasets]="barChartDataMultiLine" [labels]="barChartLabels" [options]="barChartOptions"
    [legend]="true" [chartType]="barChartType" [colors]="this.colors" >
    </canvas>
  </div>
</div>
