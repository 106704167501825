import { Injectable } from "@angular/core";
import { SortDirection } from "@angular/material";
import { FilterData } from "path-shared/models/filter-data";

export class ZbRutasFilter extends FilterData {
    somId: number;
    nombre: string;
    descripcion: string;
    constructor(sort: string, order: SortDirection) {
        super(sort, order);
        this.somId = null;
        this.nombre = null;
        this.descripcion = null;

        this.labels['somId']=this.i18n('somId');
        this.labels['nombre']=this.i18n('Nombre');
        this.labels['description']=this.i18n('Descripcion');
    }
}