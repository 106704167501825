import { Component, OnInit, EventEmitter, Output, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Observable, Subscription } from 'rxjs';
import { TarifasGruaFilter } from '../../models/tarifa-grua-filter';

@Component({
  selector: 'app-tarifas-grua-filter',
  templateUrl: './tarifas-grua-filter.component.html',
  styleUrls: ['./tarifas-grua-filter.component.scss']
})
export class TarifasGruaFilterComponent implements OnInit, OnDestroy {
  subscription: Subscription;
 
  filter: TarifasGruaFilter;

  @Output() notify: EventEmitter<TarifasGruaFilter> = new EventEmitter<TarifasGruaFilter>();
  constructor(public thisDialogRef: MatDialogRef<TarifasGruaFilterComponent>,
    private i18n: I18n,
    @Inject(MAT_DIALOG_DATA) public data) {
    this.filter = data.filter; }
    ngOnInit() {
      this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
        if (e.key === 'Enter') {
          this.apply();
        }
      });
    }
    ngOnDestroy(): void {
      this.subscription.unsubscribe();
    }
    reset() {
      this.filter.clear();
      this.apply();
    }
  
    apply() {
      this.filter.updated();
      this.notify.emit(this.filter);
      this.thisDialogRef.close();
    }
  
    dismiss() {
      this.thisDialogRef.close();
    }

}
