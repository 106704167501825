import { Aparell } from './../models/aparell.model';
import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { AparellsFilterService } from './aparells-filter-service';
import { AparellFilter } from '../models/aparell-filter';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { LocalizacionFilter } from '../models/localizacion-filter';
import { LocalizacionFilterService } from './localizacion-filter.service';
import { map } from 'rxjs/operators';
import { LocalizacionModel } from '../models/localizacionModel';

const defaultSortParam = 'codi';
const defaultSortDirection = 'desc';

@Injectable({
  providedIn: 'root'
})
export class LocalizacionService {

  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {}


  addLocalizacion(data: LocalizacionModel): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetLocalizacion'], data)
      .pipe(
        map((e => {
          return e;
        }))
      );
    }
  }

  getLocalizacionPropuestas(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetLocalizacionPropuestas'], null);
    }
  }

  getLocalizacion(filter: LocalizacionFilter, sortDir: string, sortField: string): Observable<any> {
    const start = 0;
    const pageSize = 1000;
    sortField = sortField === undefined ? defaultSortParam : sortField;
    sortDir = sortDir === '' ? defaultSortDirection : sortDir;
    const params = new LocalizacionFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetLocalizacion'],  params )
      .pipe(
        map((e) => {
          return e;
        })
      );
    }
  }

  getLocalizacionesAparell(idAparell: string): Observable<any> {
    if (this.authGuard.canActivate()) {
      idAparell = idAparell.replace('/', '$');
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetLocalizacionesAparell'].replace('{idAparell}', idAparell),  null )
      .pipe(
        map((e) => {
          return e;
        })
      );
    }
  }

  removeLocalizacion(id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteLocalizacion'].replace('{id}', id), null);
    }
  }

  setActiu(id, actiu): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlLocalizacionSetActiu'].replace('{id}', id), actiu ? 1 : 0);
    }
  }

}
