import { ClonarTemporadaComponent } from './../../components/clonar-temporada/clonar-temporada.component';
import { TemporadaFilter } from './../../models/temporada.filter';
import { TemporadasTableComponent } from './../../components/temporadas-table/temporadas-table.component';
import { Component, OnInit, Inject, AfterViewInit, ViewChild } from '@angular/core';
import { PermissionDialogComponent } from 'path-shared/components/permission-dialog/permission-dialog.component';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { TemporadasEditorComponent } from '../../components/temporadas-editor/temporadas-editor.component';
import { TemporadasFilterComponent } from '../../components/temporadas-filter/temporadas-filter.component';

@Component({
  selector: 'app-temporada-page',
  templateUrl: './temporada-page.component.html',
  styleUrls: ['./temporada-page.component.scss']
})
export class TemporadaPageComponent implements OnInit {

  permissionDialogRef: MatDialogRef<PermissionDialogComponent>;
  activeFilters: string[] = [];
  filterData: TemporadaFilter;
  somIdFoo: number;
  dataDialog: ComponentDialog = Builder.newComponentDialog();
  secureIdTemporada: string;
  @ViewChild(TemporadasTableComponent) temporadasTable: TemporadasTableComponent;
  constructor(
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private i18n: I18n,
    private not: NotificationService,
    public thisDialogRef: MatDialogRef<TemporadaPageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
  ) {
    this.filterData = new TemporadaFilter('id', 'desc');
    if (data !== undefined) {
      this.dataDialog = data;
    }
    this.secureIdTemporada = '';
  }

 

  ngOnInit() {
    this.auth.UserCanSeePage(this.auth.Pages.Temporada);
    this.temporadasTable.itemClicked.subscribe(secure_id => {
      this.update(secure_id);
    });
    this.temporadasTable.itemSelected.subscribe(codi => {
      this.thisDialogRef.close(codi);
    });
  }

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Temporadas'));
    }
  }

  edit() {
    const idSeleccionat: string = this.temporadasTable.displayedItems['checkedItems'][0];
    this.update(idSeleccionat);
  }

  create() {
    this.update(null);
  }

  update(secure_id: string) {
    const data: ComponentDialog = Builder.newComponentDialog(true, false, secure_id);

    const ref = this.dialog.open(TemporadasEditorComponent, {
      width: '70%',
      height: '70vh',
      closeOnNavigation: false,
      autoFocus: true,
      data: data
    });

    ref.afterClosed().subscribe(updated => {
      if (updated) { this.temporadasTable.load(); }
    });
  }

  refresh() {
    this.temporadasTable.load();
  }

  onFilterClicked() {
    const ref = this.dialog.open(TemporadasFilterComponent, {
      width: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.temporadasTable.filter
      }
    });

    ref.componentInstance.notify.subscribe((filter: TemporadaFilter) => {
      this.temporadasTable.filter.set(filter);
      this.temporadasTable.load();
    });
  }

  onRowSelected(somid) {
    this.update(somid);
  }

  generateExcel() {
    this.temporadasTable.generateExcel();
  }

  onSelectedTemporada(secureIdTemporada) {
    this.secureIdTemporada = secureIdTemporada;
  }

  clonarTemporada() {
    if ( this.secureIdTemporada !== undefined &&  this.secureIdTemporada !== null &&  this.secureIdTemporada !== '') {
      const data: ComponentDialog = Builder.newComponentDialog(true, false, this.secureIdTemporada);

      const ref = this.dialog.open(ClonarTemporadaComponent, {
        width: '70%',
        closeOnNavigation: false,
        autoFocus: true,
        data: data
      });

      ref.afterClosed().subscribe(updated => {
        if (updated) { this.temporadasTable.load(); }
      });
    }
  }

}
