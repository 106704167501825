import { DenunciaDetailModel } from './../../../../models/denuncia-detail.model';
import { Component, OnInit, Input } from '@angular/core';
import { XDENUNCIES } from 'path-models/XDENUNCIES';

@Component({
  selector: 'app-dades-expedient',
  templateUrl: './dades-expedient.component.html'
})
export class DadesExpedientComponent implements OnInit {
    @Input() data: DenunciaDetailModel;
    @Input() isReadOnly: boolean;

    constructor() {}

  ngOnInit() {

  }
}
