import { Component, OnInit, Input } from '@angular/core';
import { DenunciaDetailModel } from 'app/sections/denuncies/models/denuncia-detail.model';
import { StartupService } from 'path-shared/services/startup/startup.service';

@Component({
  selector: 'app-denunciant',
  templateUrl: './denunciant.component.html'
})
export class DenunciantComponent implements OnInit {

  @Input() data: DenunciaDetailModel = null;
  @Input() isReadOnly: boolean;

  tipusDenunciants;
  constructor(
    private startupService: StartupService
  ) {
    this.tipusDenunciants = startupService.GetTiposDenunciante();
   }

  ngOnInit() {
  }

}
