import { TramitadorFilter } from '../models/tramitador.filter';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';


const defaultSortParam = '';
const defaultSortDirection = '';

@Injectable()
export class TramitadorFilterService extends CustomFilterService {

  public createFilterParams(filter: TramitadorFilter, start: number, pageSize: number, sortField: string, sortDir: string): HttpParams {

    /*clean hours*/
    filter.fechaInicio.setHours(0, 0, 0);
    filter.fechaFin.setHours(23, 59, 59);

    return new HttpParams()
    .append('fechaInicio', filter.fechaInicio.toJSON())
    .append('fechaFin', filter.fechaFin.toJSON())
    .append('tipos', JSON.stringify(filter.tipos))
    .append('legislaciones', JSON.stringify(filter.legislaciones))
    .append('origen', filter.origen.toString())
    .append('matriculasExtrangeras', this.getTristateValue(filter.matriculasExtrangeras))
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir)
    ;
  }

  public createParamsFromSelectedItems<T>(selectedItems: T[]): HttpParams {
    return new HttpParams().append('listaId', JSON.stringify(selectedItems));
  }
  getTristateValue(value) {
    if (value === null) {
      return '';
    } else {
      return value.toString();
    }
  }
}
