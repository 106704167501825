import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'money'
})
export class MoneyPipe implements PipeTransform {
  private decimalpPipe;

  constructor(
    @Inject(LOCALE_ID) protected localeId: string
    ) {
      this.decimalpPipe = new DecimalPipe(this.localeId);
  }

  transform(value: any, args?: any): any {
    if (args === null || args === undefined) {
      return this.decimalpPipe.transform(value, '0.2').toString() + '€';
    }
  }
}
