<app-dialog-header label="Denuncies" i18n-label *ngIf="this.dataDialog.isDialog"></app-dialog-header>

<div class="row justify-content-between">
    <app-form-text-edit *ngIf="!this.dataDialog.isDialog" id="denuncies-table-exp" class="col-xl-2" i18n-label label="Expedient || Matricula" [(value)]="this.expedienteFilter" (focusout)="expedienteFilterChange($event)"></app-form-text-edit>
    <div class="col">
        <app-filter-selector [filter]="this.filter"></app-filter-selector>
    </div>
    <div class="col">
        <mat-paginator #paginator [pageIndex]="0" [pageSize]="this.filter.paginator.pageSize" [pageSizeOptions]="[20, 40, 60, 100]">
        </mat-paginator>
    </div>
</div>


<div class="table-sdw is-fixed">
    <!-- <div class="row"> -->
        <!-- <app-form-text-edit id="denuncies-table-exp" class="col-xl-2" i18n-label label="Expedient | Matricula" [(value)]="this.expedienteFilter" (focusout)="expedienteFilterChange($event)"></app-form-text-edit> -->
      <!-- </div> -->
      
    <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" matSortActive="{{this.sort.active}}" matSortDirection="{{this.sort.direction}}" class="is-header-sticky">

        <!-- Check Column -->
        <ng-container matColumnDef="Check">
            <mat-header-cell *matHeaderCellDef class="is-checkbox">
                <mat-checkbox (change)="toggleAllCheckbox()"></mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
                <mat-checkbox [(ngModel)]="displayedItems[row.somid].checked" [disabled]="true" ></mat-checkbox>
            </mat-cell>
        </ng-container>

        <!-- Estats Column -->
        <ng-container matColumnDef="estats">
            <mat-header-cell *matHeaderCellDef i18n>Estats</mat-header-cell>
            <mat-cell *matCellDef="let row" class="text-center">
                <app-denuncies-estat [denuncia]="row"></app-denuncies-estat>
            </mat-cell>
        </ng-container>


        <!-- Matricula Column -->
        <ng-container matColumnDef="matricula">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Matricula</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.matricula}}</mat-cell>
        </ng-container>

        <!-- Expedient Column -->
        <ng-container matColumnDef="expedient">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Expedient</mat-header-cell>
            <mat-cell *matCellDef="let row"><a (click)="getExpedients(row.expedient, row.bulleti)" class="is-link">{{row.expedient}}</a></mat-cell>
        </ng-container>

        <!-- Bulleti Column -->
        <ng-container matColumnDef="bulleti">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Bulleti</mat-header-cell>
            <mat-cell *matCellDef="let row"><a (click)="onRowClicked(row)" class="is-link">{{row.bulleti}}</a></mat-cell>
        </ng-container>

        <!-- Imatges Column -->
        <ng-container matColumnDef="imatges">
            <mat-header-cell *matHeaderCellDef mat-sort-header><i class="fa fa-images" text="Imatges" i18n-text></i></mat-header-cell>
            <mat-cell *matCellDef="let row">
                <div *ngIf="row.imatges > 0">
                    <a (click)="this.viewImages(row.bulleti, row.somid)" class="is-link">
                        <i class="imatges-n fa fa-images"></i> {{row.imatges}}
                    </a>
                </div>
                <div *ngIf="row.imatges === 0">
                    <i class="imatges-0 fa fa-images"></i>
                </div>
            </mat-cell>
        </ng-container>

        <!-- Data Column -->
        <ng-container matColumnDef="data">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.data | shortDate}}</mat-cell>
        </ng-container>

        <!-- Hora Column -->
        <ng-container matColumnDef="hora">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Hora</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.hora}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="agent">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Agent</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.agent}}</mat-cell>
        </ng-container>

        <!-- DataAnul Column -->
        <ng-container matColumnDef="dataAnul">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data Anul.</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.dataAnul | shortDate }}</mat-cell>
        </ng-container>

        <!-- DataTrans Column -->
        <ng-container matColumnDef="dataTrans">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data Trasp.</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.dataTrans | shortDate}}</mat-cell>
        </ng-container>

        <!-- DataTrans Column -->
        <ng-container matColumnDef="dataRecep">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data Recep.</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.dataRecep | shortDate}}</mat-cell>
        </ng-container>

        <!-- DataCobra Column -->
        <ng-container matColumnDef="dataCobra">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data Cobr.</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.dataCobra | shortDate}}</mat-cell>
        </ng-container>

        <!-- Lloc Column -->
        <ng-container matColumnDef="lloc">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Lloc</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.lloc}}</mat-cell>
        </ng-container>

        <!-- Import Column -->
        <ng-container matColumnDef="import">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Import</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.import}}€</mat-cell>
        </ng-container>

        <!-- DataLimitDte Column -->
        <ng-container matColumnDef="dataLimitDte">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data Limit Dte.</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.dataLimitDte | date:'shortDate' }}</mat-cell>
        </ng-container>

        <!-- ImpDesc Column -->
        <ng-container matColumnDef="impDesc">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Imp. Desc.</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.impDesc}}€</mat-cell>
        </ng-container>

        <!-- Operacio Column -->
        <ng-container matColumnDef="codAnul">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Cod. Anul.</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.codAnul}}</mat-cell>
        </ng-container>

        <!-- Infractor Column -->
        <ng-container matColumnDef="infractor">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Infractor</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.infractor}}</mat-cell>
        </ng-container>

        <!-- llei Column -->
        <ng-container matColumnDef="llei">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Ord.</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.llei}}</mat-cell>
        </ng-container>

        <!-- article Column -->
        <ng-container matColumnDef="article">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Art.</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.article}}</mat-cell>
        </ng-container>

        <!-- descinf Column -->
        <ng-container matColumnDef="descInf">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Desc. Inf.</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.descInf}}</mat-cell>
        </ng-container>

        <!-- Zona Column -->
        <ng-container matColumnDef="zona">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Zona</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.descZona}}</mat-cell>
        </ng-container>

         <!-- Motiu anulacio Column -->
         <ng-container matColumnDef="motiu_anulacio">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Motiu anulació</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.motiuAnul}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="userAnul">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>User anulació</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.userAnul}}</mat-cell>
        </ng-container>
        <!-- Aparat Column -->
        <ng-container matColumnDef="aparato">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Aparato</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.aparato}}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" [ngClass]="displayedItems[row.somid].checked ? 'is-active' : ''"  (click)="toggleItem(row.somid)"></mat-row>
    </mat-table>
</div>