import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';


export class EnviosPagedRequestDto extends FilterData {
    idEnvio: number;
    tipoEnvio: string;
    fechaCreacionInicio: Date;
    fechaCreacionFin: Date;
    usuarioCreacion: string;
    fechaEnvioInicio: Date;
    fechaEnvioFin: Date;
    usuarioEnvio: string;
    pdfBOE: string;
    fechaNotificacionBOEInicio: Date;
    fechaNotificacionBOEFin: Date;
    usuarioBOE: string;
    numeroPublicacionBOE: string;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.idEnvio = null;
    this.tipoEnvio ='';
    this.fechaCreacionInicio = null;
    this.fechaCreacionFin = null;
    this.usuarioCreacion ='';
    this.fechaEnvioInicio = null;
    this.fechaEnvioFin = null;
    this.usuarioEnvio = '';
    this.pdfBOE = '';
    this.fechaNotificacionBOEInicio = null;
    this.fechaNotificacionBOEFin = null;
    this.numeroPublicacionBOE = '';

    this.labels['idEnvio'] = this.i18n('ID Envio');
    this.labels['tipoEnvio'] = this.i18n('Tipo envio');
    this.labels['fechaCreacionInicio'] = this.i18n('Fecha creación inicio');
    this.labels['fechaCreacionFin'] = this.i18n('Fecha creación fin');
    this.labels['usuarioCreacion'] = this.i18n('Usuario creación');
    this.labels['fechaEnvioInicio'] = this.i18n('Fecha envio incio');
    this.labels['fechaEnvioFin'] = this.i18n('Fecha envio fin');
    this.labels['usuarioEnvio'] = this.i18n('Usuario envio');
    this.labels['pdfBOE'] = this.i18n('PDF BOE');
    this.labels['fechaNotificacionBOEInicio'] = this.i18n('Fecha notificación BOE inicio');
    this.labels['fechaNotificacionBOEFin'] = this.i18n('Fecha notificación BOE fin');
    this.labels['numeroPublicacionBOE'] = this.i18n('Número publicación BOE');

    this.formatOutput['fechaCreacion'] = 'shortDate';
    this.formatOutput['fechaEnvio'] = 'shortDate';
  }

}
