<div class="matricula">
    <div class="pais" [ngClass]="[ this.validPais() ? '' :  'empty']">{{this.pais}}</div>

    <div class="dropdown sugPais" [ngClass]="[ this.validPais() ? '' :  'empty']" *ngIf="this.activarSugerencias && this.sugPais != null">
        <button class="btn btn-secondary dropdown-toggle button" type="button" id="dropdownMenuPais" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuPais">
          <button *ngFor="let option of sugPais" class="dropdown-item" type="button" (click)="setPais(option)">{{option}}</button>
        </div>
    </div>

    <div class="nummatricula">{{this.matricula}}</div>
    
    <div class="dropdown" *ngIf="this.activarSugerencias && this.sugMatricula != null && this.sugMatricula != '' && (this.matricula === null || this.matricula === '')">
      <button class="btn btn-secondary dropdown-toggle button" type="button" id="dropdownMenuMatricula" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="seleccionar mr-2" i18n>Seleccioni</span>
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuMatricula">
        <button *ngFor="let option of sugMatricula" class="dropdown-item" type="button" (click)="setMatricula(option)">{{option}}</button>
      </div>
    </div>    
    
</div>
<!--<div style="display: inline;" *ngIf="this.vehicle" [ngClass]="[ this.vehicle != null ? '' :  'empty']"> &nbsp; {{this.vehicles[this.vehicle-1].descripcio}}</div>-->
<!--<div style="display: inline;" *ngIf="this.marca !== ''" [ngClass]="[ this.vehicle != null ? '' :  'empty']"> &nbsp; {{this.marca}}</div>
<div style="display: inline;" *ngIf="this.model !== ''" [ngClass]="[ this.vehicle != null ? '' :  'empty']"> &nbsp; {{this.model}}</div>-->



