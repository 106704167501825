<div class="row">
  <div class="col-xl-5">
    <div class="row">
      <div class="col-xl-6">
        <app-form-date-edit id="dades-expedient-notificada" i18n-label label="Notificada en mà"
          [(value)]="data.notificadoEnMano" type="calendar" [isReadOnly]="this.isReadOnly"></app-form-date-edit>
        <app-form-date-edit id="dades-expedient-data" i18n-label label="Data anul·lació" [(value)]="data.fechaAnulacion"
          type="calendar" [isReadOnly]="this.isReadOnly"></app-form-date-edit>
          <app-form-text-edit id="dades-expedient-import" i18n-label label="Import cobrat" [(value)]="data.importeCobrado"
        [isReadOnly]="this.isReadOnly"></app-form-text-edit>
      </div>

      <div class="col-xl-6">
        <app-form-text-edit id="dades-expedient-motiuNoNotificacio" i18n-label label="Motivo no notificación" [(value)]="data.motiuNoNotificacio"
         [isReadOnly]="true"></app-form-text-edit>
        <app-form-date-edit id="dades-expedient-dataTrasp" i18n-label label="Data traspas"
          [(value)]="data.fechaTraspaso" type="calendar" [isReadOnly]="this.isReadOnly"></app-form-date-edit>
          <app-form-date-edit id="dades-expedient-dataCobr" i18n-label label="Data cobrament" [(value)]="data.fechaCobro"
        type="calendar" [isReadOnly]="this.isReadOnly"></app-form-date-edit>
      </div>
    </div>
  </div>

  <div class="col-xl-7">
    <div class="row">
      <div class="col-xl-2 form-label" i18n>Recàrreg</div>
      <app-form-text-edit id="dades-expedient-porcRec" class="col-xl-5" i18n-label label="%"
        [(value)]="data.porcentajeRecargo" [isReadOnly]="this.isReadOnly"></app-form-text-edit>
      <app-form-text-edit id="dades-expedient-impRec" class="col-xl-5" i18n-label label="€"
        [(value)]="data.importeRecargo" [isReadOnly]="this.isReadOnly"></app-form-text-edit>
    </div>

    <div class="row">
      <div class="col-sm-2 form-label" i18n>Interessos</div>
      <app-form-text-edit id="dades-expedient-impInt" class="col-xl-4" i18n-label label="€"
        [(value)]="data.importeIntereses" [isReadOnly]="this.isReadOnly"></app-form-text-edit>
      <app-form-date-edit id="dades-expedient-dataIntIni" class="col-xl-3" i18n-label label="Inici"
        [(value)]="data.fechaInicioIntereses" type="calendar" [isReadOnly]="this.isReadOnly"></app-form-date-edit>
      <app-form-date-edit id="dades-expedient-dataIntFi" class="col-xl-3" i18n-label label="Fi"
        [(value)]="data.fechaFinIntereses" type="calendar" [isReadOnly]="this.isReadOnly"></app-form-date-edit>
    </div>

    <div class="row">
      <div class="col-sm-2 form-label" i18n>Costos</div>
      <app-form-text-edit id="dades-expedient-impCost" class="col-xl-4" i18n-label label="€"
        [(value)]="data.importeCostas" [isReadOnly]="this.isReadOnly"></app-form-text-edit>
      <div class="col-sm-2 form-label" i18n>Origen</div>
      <app-form-text-edit id="dades-expedient-origen" class="col-xl-4" i18n-label label="Origen"
        [(value)]="data.origenDenuncia" [isReadOnly]="this.isReadOnly"></app-form-text-edit>
    </div>
  </div>
</div>