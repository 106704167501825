import { ExcelGenerator } from 'path-shared/interfaces/excel-generator';
import { Injectable, EventEmitter } from '@angular/core';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { api } from 'path-shared/configuration/urls';
import { config } from 'path-shared/configuration/config';
import { Observable } from 'rxjs';
import { inherits } from 'util';
import { HttpParams } from '@angular/common/http';
import { VehicleResumen } from '../models/vehicleDto';
import { VehiclesFilterService } from './vehicles-filter.service';
import { VehicleFilter } from '../models/vehicle-filter';



const defaultSortParam = 'MATRICULA';
const defaultSortDirection = 'asc';

@Injectable()
export class VehiclesService implements ExcelGenerator {
    displayedItems = [];

    constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {
      this.displayedItems['checkedValues'] = 0;
      this.displayedItems['checkedItems'] = Array();
    }

    getVehicle(matricula): Observable<any> {
      if (this.authGuard.canActivate()) {
        return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetVehicle'].replace('{matricula}', matricula), null);
      }
    }

    getVehiclesByMatricula(matricula: string): Observable<any> {
      if (this.authGuard.canActivate()) {
        const start = 0;
        const pageSize = 1000;
        const filter = new VehicleFilter('id', 'asc');
        filter.matricula = matricula;
        const params = new VehiclesFilterService().createFilterParams(filter, start, pageSize, defaultSortParam, defaultSortDirection);

        return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetVehicles'], params);
      }
    }

    setVehicle(persona: VehicleResumen) {
      if (this.authGuard.canActivate()) {
          return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetVehicle'], persona);
        }
    }

    getVehiclesPropietat(secure_id): Observable<any> {
      if (this.authGuard.canActivate()) {
        return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetVehicles'].replace('{secure_id}', secure_id), null);
      }
    }

    findPersonaByDniNif(nif): Observable<any> {
      if (this.authGuard.canActivate()) {
        nif = nif.replace(/\./g, '$@');
        return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetPersonByNif'].replace('{nif}', nif), null);
      }
    }

    deleteVehicle(matricula): Observable<any> {
      if (this.authGuard.canActivate()) {
        return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteVehicle'].replace('{matricula}', matricula), null);
      }
    }

    getMarquesResumen(): Observable<any> {
      if (this.authGuard.canActivate()) {
        return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetMarcasResumen'], null);
      }
    }

    getMarquesCombo(): Observable<any> {
      if (this.authGuard.canActivate()) {
        return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetMarcasCombo'], null);
      }
    }

    getModelosCombo(): Observable<any> {
      if (this.authGuard.canActivate()) {
        return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetModelosCombo'], null);
      }
    }

    getColoresCombo(): Observable<any> {
      if (this.authGuard.canActivate()) {
        return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetColoresCombo'], null);
      }
    }

    getAllTipusVehciles(): Observable<any> {
      if (this.authGuard.canActivate()) {
        return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetTipusVehiclesCombo'], null);
      }
    }
    getPaisesVehciles(): Observable<any> {
      if (this.authGuard.canActivate()) {
        return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetPaisesCombo'], null);
      }
    }
    goDgt(): Observable<any> {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetDgt'], null);
    }

    generateExcel(filter: any, sortDir: string, sortField: string): Observable<any> {
      const start = 0;
      const pageSize = 1000;

      sortField = sortField === undefined ? defaultSortParam : sortField;
      sortDir = sortDir === '' ? defaultSortDirection : sortDir;
      const params = new VehiclesFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

      if (this.authGuard.canActivate()) {
        return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlVehiclesExcel'],  params );
      }
    }

    checkExistByMatricula(matricula: string) {
      if (this.authGuard.canActivate()) {
        matricula = matricula.replace(/\./g, '$@');
        return this.http.get(config.urls['apiUrl'] + api.endpoints['urlcheckExistByMatricula'].replace('{matricula}', matricula), null);
      }
    }

}
