<app-filter-header title="Filtrar propostes" i18n-title  (apply)="this.apply()" (reset)="this.reset()" (dismiss)="this.dismiss()"></app-filter-header>
<div class="modal-body">
  <div class="row">
    <app-form-combo-edit label="Estat" i18n-label class="col-sm-4" [items]="this.estados" [(value)]="filter.proposta" textField="text" valueField="value"></app-form-combo-edit>
    <app-filter-text-edit id="propostes-filter-numDen" autofocus class="col-sm-4" i18n-label label="Número Denúncia" [(value)]="filter.numeroDenuncia"></app-filter-text-edit>
    <app-filter-text-edit id="propostes-filter-matricula" class="col-sm-4" i18n-label label="Matricula" [(value)]="filter.matricula"></app-filter-text-edit>
  </div>
  <div class="row">
    <app-form-date-edit id="propostes-filter-dataIni" class="col-sm-4" i18n-label label="Data Inicial" id="fechaInfraccionInicio" [(value)]="filter.fechaInfraccionInicio" type="calendar" ></app-form-date-edit>
    <app-form-date-edit id="propostes-filter-dataFi" class="col-sm-4" i18n-label label="Data Final" id="fechaInfraccionInicio" [(value)]="filter.fechaInfraccionFin" type="calendar" ></app-form-date-edit>
    <app-form-combo-edit label="Aparell" i18n-label class="col-sm-4" [items]="this.aparells" [(value)]="filter.aparell" textField="descripcion" valueField="id"></app-form-combo-edit>

  </div>
  <div class="row">
      <app-form-combo-edit label="País" i18n-label class="col-sm-4" [items]="this.paisos" [(value)]="filter.pais" textField="text" valueField="value"></app-form-combo-edit>
      <!-- <app-filter-text-edit class="col-sm-4" i18n-label label="Lloc" [(value)]="filter.carrer"></app-filter-text-edit> -->
      <app-form-combo-edit class="col-4" i18n-label label="Lloc" [items]="this.carrers" [(value)]="filter.carrer" valueField="nomCarrer" textField="nomCarrer"></app-form-combo-edit>

      <app-filter-text-edit id="propostes-filter-infraccio" class="col-sm-4" i18n-label label="Infracció" [(value)]="filter.infraccion"></app-filter-text-edit>
  </div>
</div>
