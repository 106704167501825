import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class PuntosDgtFilter extends FilterData {
  EXP: string;
  remesa: string;
  fechaInicio:Date;
  fechaFin:Date;


  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.EXP = '';
    this.remesa = '';
    this.fechaInicio = null;
    this.fechaFin = null;

    this.labels['EXP'] = this.i18n('Expediente');
    this.labels['remesa'] = this.i18n('Remesa');
    this.labels['fechaInicio'] = this.i18n('Fecha inicio');
    this.labels['fechaFin'] = this.i18n('Fecha fin');

  }
}
