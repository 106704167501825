<app-toolbar>
  <app-tool-button text="Actualitzar"  i18n-text (click)="loadAlegacionesPage();" color="has-color-blue" icon="fa-sync"></app-tool-button>
  <app-tool-button text="Filtre" i18n-text (click)="onFilterClicked();" color="has-color-orange" icon="fa-filter"></app-tool-button>
</app-toolbar>
<app-dialog-header label="Respondre alegacions" [isCloseButton]="false" i18n-label></app-dialog-header>

<div class="row justify-content-between">
  <div class="col">
      <app-filter-selector [filter]="this.filter"></app-filter-selector>
  </div>
  <div class="col">
      <mat-paginator #paginator [pageIndex]="0" [pageSize]="this.filter.paginator.pageSize" [pageSizeOptions]="[20, 40, 60, 100]">
      </mat-paginator>
  </div>
</div>

<div class="table-sdw is-fixed">
  <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" matSortActive="{{this.sort.active}}" matSortDirection="{{this.sort.direction}}" class="is-header-sticky">

    <!-- button Column -->
    <ng-container matColumnDef="button">
      <mat-header-cell *matHeaderCellDef matSortDisabled i18n></mat-header-cell>
      <mat-cell *matCellDef="let row">
        <button type="button" class="btn btn-danger" [disabled]="!this.auth.IsSuperUser() && !(IsAgenteAutorizadoResponder)" (click)="respondreAlegacio(row)" i18n> 
          Respondre alegació
        </button>
      </mat-cell>
    </ng-container>

    <!-- fitxer Column -->
    <ng-container matColumnDef="fitxer">
      <mat-header-cell *matHeaderCellDef matSortDisabled i18n>Fitxer</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div style="display: flex; height: 100%">
          <button class="mdc-icon-button material-icons" (click)="this.getFilePath(row.FICHERO_SOM_ID)">
            <div class="mdc-icon-button__ripple"></div>
            insert_drive_file
          </button>
        </div>
      </mat-cell>
    </ng-container>

    <!-- exp Column -->
    <ng-container matColumnDef="exp">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Expediente</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.EXP}}</mat-cell>
    </ng-container>

    <!-- exp Column -->
    <ng-container matColumnDef="tipo">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Tipo</mat-header-cell>
      <mat-cell *matCellDef="let row">{{this.parseTipo(row.TIPO)}}</mat-cell>
    </ng-container>

    <!-- numdenuncia Column -->
    <ng-container matColumnDef="numdenuncia">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Número denuncia</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.NUMDENUNCIA}}</mat-cell>
    </ng-container>

    <!-- dataplec Column -->
    <ng-container matColumnDef="dataplec">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Dataplec</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.DATAPLEC.substring(0,10)}}</mat-cell>
    </ng-container>

    <!-- agent Column -->
    <ng-container matColumnDef="agent">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Agente</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.AGENTE}}</mat-cell>
    </ng-container>

    <!-- infracció Column -->
    <ng-container matColumnDef="infraccio">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Infracció</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.INFRACCIO}}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset"></mat-row>
  </mat-table>
</div>
