<app-filter-header title="Filtrar Senyals Verticals" i18n-title 
  (apply)="this.apply()" (reset)="this.reset()" (dismiss)="this.dismiss()">
</app-filter-header>
<div class="modal-body">
  <app-filter-text-edit id="senales-verticales-filter-id" class="row" i18n-label label="Codi" [number]="true" [(value)]="filter.id"></app-filter-text-edit>
  <app-filter-text-edit id="senales-verticales-filter-nom" class="row" i18n-label label="Nom" [(value)]="filter.nombreSenal"></app-filter-text-edit>
  <app-combo-box-input class="row" style="width: 50%;" #comboTipoSenal [label]="'Tipus Senyal'" [showingOptions]="this.tipoSenalesDictionary.value" 
    (optionChanged)="this.senalSeleccionada($event)">
  </app-combo-box-input>
</div>
