import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { OperacionesModel } from 'app/sections/denuncies/models/operaciones.model';
import { MotiuAnulacioModel } from 'app/sections/denuncies/models/motiu-anulacio.model';
import { TipoOperacionsModel } from 'app/sections/denuncies/models/tipo-operacions.model';
import { PropostesService } from 'path-propostes-services/propostes.service';
import { PropostaReject } from 'app/sections/propostes/models/proposta-reject.model';

@Component({
    selector: 'app-nova-operacio-propostes',
    templateUrl: 'nova-operacio-propostes.component.html',
    styleUrls: ['nova-operacio-propostes.component.scss']
})
export class NovaOperacioPropostesComponent {

    displayedColumns = [ 'Desc'];
    highlightedRows = [];
    cod = '';
    motiv = '';
    desc = '';
    obs = '';
    exp = '';
    operacio: OperacionesModel;
    motivosDesestimar: any[];
    tipoOps: TipoOperacionsModel[];
    propostes: any;
    showMotivosDesestimar: boolean;
    showDescripcion: boolean;
    selectedMotivoDesestimar: number;

    constructor (
        private thisDialogRef: MatDialogRef<NovaOperacioPropostesComponent>,
        @Inject(MAT_DIALOG_DATA) public tOps: any,
        private toastr: ToastrService,
        public i18n: I18n,
        private confirmationDialogService: ConfirmationDialogService,
        private propostesService: PropostesService
        ) {
            this.tipoOps = [{'COD_OPE': 'DESESTIMA_PROP', 'DESC_OPE': this.i18n(' DESESTIMAR PROPOSTA'), 'BUTTON': ''},
            {'COD_OPE': 'ANUL_DESESTIMA_PROP', 'DESC_OPE': this.i18n(' ANUL·LAR DESESTIMACIÓ DE PROPOSTA'), 'BUTTON': ''}];
            this.motivosDesestimar = tOps.motius;
            this.propostes = tOps.data;
            this.showMotivosDesestimar = false;
            this.showDescripcion = true;
    }


    onRowClicked(row) {
      this.cod = row.COD_OPE;
      this.desc = row.DESC_OPE;
      this.obs = '';

      this.showMotivosDesestimar = row.COD_OPE === 'DESESTIMA_PROP' ? true : false;
      this.showDescripcion = !this.showMotivosDesestimar;
    }

    executeOperation() {
        if (this.cod === '') {
            this.toastr.error(this.i18n('Has de seleccionar una operació!'));
            return;

        }
        if (this.cod === 'DESESTIMA_PROP') {
            if (this.selectedMotivoDesestimar === undefined) {
            this.toastr.error(this.i18n('Has de seleccionar un motiu per desestimar'));
            } else {
            this.OperacionDesestimarPropuesta();
            }
        }
        if (this.cod === 'ANUL_DESESTIMA_PROP') {
            this.OperacionAnularDesestimarPropuesta();

        }
    }

    // Operacion desestimar propuesta

    OperacionDesestimarPropuesta() {
        this.confirmationDialogService.confirm(this.i18n('Avis'), this.i18n('Es desestimaran ') + this.propostes.filter(x => x!==undefined && (x.proposta === 1 || x.proposta === 4)).length + this.i18n( ' propostes pendents pel motiu: ') + this.motivosDesestimar.find(x => x.ID === +this.selectedMotivoDesestimar).DESCRIPCIO)
        .then((confirmed) => {
            if (confirmed) {
                const propostaData: PropostaReject = {
                    ID_MOTIU_DESESTIMAR_PROPOSTA: this.selectedMotivoDesestimar,
                    TEXT_DESESTIMAR_PROPOSTA: this.obs,
                    propostes: this.propostes.filter(x => x !== undefined && (x.proposta === 1 || x.proposta === 4)).map(x => x.SECURE_ID)
                  };

                this.propostesService.rejectPropostes(propostaData).subscribe(() => {
                    this.toastr.info(this.i18n('S\'han desestimat ') + this.propostes.filter(x => x !== undefined && (x.proposta === 1 || x.proposta === 4)).length + this.i18n(' propostes'));
                    this.thisDialogRef.close(true);
                });
            }
          })
        .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));

    }
    OperacionAnularDesestimarPropuesta() {
        this.confirmationDialogService.confirm(this.i18n('Avis'), this.i18n('S\'anul·larà la desestimació de ') + this.propostes.filter(x => x !== undefined && x.proposta === 2).length + this.i18n(' propostes'))
        .then((confirmed) => {
            const propostes = this.propostes.filter(x => x !== undefined && x.proposta === 2).map(x => x.SECURE_ID);
            this.propostesService.anulDesestPropostes(propostes).subscribe(() => {
                this.toastr.info(this.i18n('S\'ha anul·lat la desestimació de ') + this.propostes.filter(x => x!==undefined && x.proposta === 2).length + this.i18n(' propostes'));
                this.thisDialogRef.close(true);
            });
        }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));

    }

}
