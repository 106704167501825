<app-dialog-header label="Permisos" i18n-label></app-dialog-header>
<app-dialog-body>
    <div class="permission-editor">
        <div class="form-group row">
            <label class="col-sm-2" for="permissionEditor" i18n>Assignat</label>
            <div class="col-sm-10">
                <span *ngIf="!mostrar" class="grup" i18n>seleccionar un grup</span>
                <select id="permissionEditor" class="form-control" [(ngModel)]="this.selectedItemCode" (click)="cambiar()" (change)="this.assigneeSelected($event)">
          <optgroup label="Grups" i18n-label>
            <option *ngFor="let group of this.groups" value="{{  this.generateCode(group.SOM_ID, '0') }}" type="0">{{ group.DESCGRUPO }}</option>
          </optgroup>
          <optgroup label="Usuaris" i18n-label>
              <option *ngFor="let user of this.users" value="{{ this.generateCode(user.UserID, '1')}}" type="1">{{ user.Name }}</option>
          </optgroup>
        </select>
            </div>
        </div>
        <div class="row">
            <mat-checkbox [(ngModel)]="this.item.Read" i18n>Llegir</mat-checkbox>
        </div>
        <div class="row">
            <mat-checkbox [(ngModel)]="this.item.Create" i18n>Crear</mat-checkbox>
        </div>
        <div class="row">
            <mat-checkbox [(ngModel)]="this.item.Update" i18n>Modificar</mat-checkbox>
        </div>
        <div class="row">
            <mat-checkbox [(ngModel)]="this.item.Delete" i18n>Esborrar</mat-checkbox>
        </div>
    </div>
</app-dialog-body>
<app-dialog-footer>
    <button type="button" class="btn btn-outline-primary" (click)="this.accept()" i18n>Acceptar</button>
    <button type="button" class="btn btn-outline-danger" (click)="this.cancel()" cancel i18n>Cancel·lar</button>
</app-dialog-footer>