import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { NotificacionsDenunciaFilter } from '../models/NotificacionDenuncia/notificacions-denuncia-filter';


const defaultSortParam = 'element';
const defaultSortDirection = 'asc';

@Injectable()
export class NotificacionsDenunciesFilterService extends CustomFilterService {
  public createFilterParams(filter: NotificacionsDenunciaFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {

    return new HttpParams()
    .append('exp', (filter.element ? filter.element.toString() : ''))
    .append('judicial', filter.judicial ? filter.judicial.toString() : '')
    .append('dataIni', filter.dataIni ? filter.dataIni.toJSON() : '')
    .append('dataFi', filter.dataFi ? filter.dataFi.toJSON() : '')
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
