import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Festiu } from '../../models/festiu.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { ColorsService } from 'app/sections/configuracio/colors/services/colors.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { ColorsEditorComponent } from 'app/sections/configuracio/colors/components/colors-editor/colors-editor.component';
import { FormService } from 'path-shared/services/form/form.service';
import { FestiusService } from '../../services/festius.service';
import { Builder } from 'path-shared/models/builder';

@Component({
  selector: 'app-festius-editor',
  templateUrl: './festius-editor.component.html',
  styleUrls: ['./festius-editor.component.scss']
})
export class FestiusEditorComponent implements OnInit {

  secure_id: string;
  festiu: Festiu;
  dialogData: ComponentDialog;
  closeButtonText: string;

  @ViewChild('mainForm') private mainForm: ElementRef;

  constructor(
    private dialog: MatDialog,
    private confirmationDialogService: ConfirmationDialogService,
    public auth: AuthorizationService,
    private festiusService: FestiusService,
    private i18n: I18n,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    public thisDialogRef: MatDialogRef<FestiusEditorComponent>,
    private formService: FormService,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
    ) {
      this.festiu = Builder.newFestiu();
      this.dialogData = data;
      this.secure_id = this.dialogData.id;
      if (this.dialogData.isReadOnly) {
        this.closeButtonText = this.i18n('Tancar');
      } else {
        this.closeButtonText = this.i18n('Llista');
      }
   }

  ngOnInit() {
    if (this.secure_id !== null) {
      this.festiusService.getFestiu(this.secure_id).subscribe(data => {
        this.festiu = data;
      });
    }
  }

  save() {
    if (this.formService.allFieldsValid(this.mainForm)) {

      this.festiusService.setFestiu(this.festiu).subscribe(result => {
        this.toastr.info(this.i18n('Festiu guardat correctament'));
        this.thisDialogRef.close(true);
      });
    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
    }
  }

  cancel() {
    this.thisDialogRef.close(false);
  }

  remove() {
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol esborrar el dia festiu?'))
    .then((confirmed) => {
        if (confirmed) {
          this.festiusService.removeFestiu(this.secure_id).subscribe(result => {
            this.toastr.success(this.i18n('Festiu esborrat correctament'));
            this.dialog.closeAll();
            this.thisDialogRef.close(true);
          });
        }
      });
  }


}
