import { Component, OnInit, EventEmitter, Output, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { RecaudacioFilter } from '../../models/recaudacio-filter';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Dictionary } from 'path-shared/models/dictionary';
import { ComboBoxInputComponent } from 'path-shared/components/form/combo-box-input/combo-box-input.component';
import { ZB_MAQUINES_PARKEON } from 'path-models/ZB_MAQUINES_PARKEON';

@Component({
  selector: 'app-recaudacio-filter',
  templateUrl: './recaudacio-filter.component.html',
  styleUrls: ['./recaudacio-filter.component.scss']
})
export class RecaudacioFilterComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  filter: RecaudacioFilter;
  zonesAux = [];
  maquinesParkeonAux: ZB_MAQUINES_PARKEON[] = [];

  zonesComboDictionary: BehaviorSubject<Array<Dictionary>>;
  maquinesParkeonComboDictionary: BehaviorSubject<Array<Dictionary>>;

  @ViewChild('comboZones') comboZones: ComboBoxInputComponent;
  @ViewChild('comboMaquinesParkeon') comboMaquinesParkeon: ComboBoxInputComponent;

  @Output() notify: EventEmitter<RecaudacioFilter> = new EventEmitter<RecaudacioFilter>();

  constructor(
    public thisDialogRef: MatDialogRef<RecaudacioFilterComponent>,
    private i18n: I18n,
    @Inject(MAT_DIALOG_DATA) public data) {
      this.zonesComboDictionary = new BehaviorSubject(new Array<Dictionary>());
      this.maquinesParkeonComboDictionary = new BehaviorSubject(new Array<Dictionary>());

      this.filter = data.filter;
      this.zonesAux = this.data.zones;
      this.maquinesParkeonAux = this.data.maquinesParkeon;
  }

  ngOnInit() {
    this.loadZones();
    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.apply();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated();
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }

  loadZones() {
    const zonesComboAux = new Array<Dictionary>();
    zonesComboAux.push(new Dictionary(null, ''));

    this.zonesAux.forEach(zona => {
      zonesComboAux.push(new Dictionary(zona.descripcio, zona.descripcio));
    });
    this.zonesComboDictionary.next(zonesComboAux);

    if (this.filter.zonaDesc !== undefined && this.filter.zonaDesc != null) {
      this.comboZones.setSelect(this.filter.zonaDesc);
      this.zonaSeleccionado(this.filter.zonaDesc);
    }
  }

  zonaSeleccionado(zonaDesc: string) {
    this.filter.zonaDesc = zonaDesc;
    const zonaSeleccionadaIndex = this.zonesAux.findIndex(x => x.descripcio === zonaDesc);
    const zonaSeleccionada = this.zonesAux[zonaSeleccionadaIndex];

    //Calculem el combo dependent de maquines
    const maquinesComboAux = new Array<Dictionary>();
    if (zonaSeleccionada !== null && zonaSeleccionada !== undefined) {
      maquinesComboAux.push(new Dictionary(null, ''));

      this.maquinesParkeonAux.filter(x => x.ZB_ZONAS_FK === zonaSeleccionada.id).forEach(maquina => {
        maquinesComboAux.push(new Dictionary(maquina.CODI_MAQUINA, maquina.CODI_MAQUINA));
      });
    }
    this.maquinesParkeonComboDictionary.next(maquinesComboAux);

    if (this.filter.maquinaCodi !== null && this.filter.maquinaCodi !== undefined) {
      this.comboMaquinesParkeon.setSelect(this.filter.maquinaCodi);
    }
  }

  maquinaParkeonSeleccionado(idMaquina: string) {
    this.filter.maquinaCodi = idMaquina;
  }

}
