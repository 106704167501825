import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class TramosTiposFilter extends FilterData {
  SomId: number;
  descripcio: string;


  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.SomId = null;
    this.descripcio = '';

    this.labels['SomId'] = this.i18n('Id');
    this.labels['descripcio'] = this.i18n('Descripció');
  }
}
