import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { SOM_CARAS } from 'path-models/SOM_CARAS';


@Injectable()
export class PhotoBoxService {

  constructor() { }

  margenExtra(matriculasCrop: SOM_CARAS[], pixels: number): SOM_CARAS[] {
    matriculasCrop.forEach(matriculaCrop => {
      matriculaCrop.TOP_IMAGE = (matriculaCrop.TOP_IMAGE - pixels) < 0 ? 0 : (matriculaCrop.TOP_IMAGE - pixels);
      matriculaCrop.LEFT_IMAGE = (matriculaCrop.LEFT_IMAGE - pixels) < 0 ? 0 : (matriculaCrop.LEFT_IMAGE - pixels);
      matriculaCrop.HEIGHT_IMAGE = matriculaCrop.HEIGHT_IMAGE + (pixels * 2);
      matriculaCrop.WIDTH_IMAGE = matriculaCrop.WIDTH_IMAGE + (pixels * 2);
    });

    return matriculasCrop;
  }

}
