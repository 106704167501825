import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { ConfirmacionBOEFilter } from '../models/confirmacion-boe-filter.model';
import { Reflection } from 'path-shared/services/reflection/reflection';

const defaultSortParam = 'codi';
const defaultSortDirection = 'asc';

@Injectable()
export class ConfirmacionBoeFilterService extends CustomFilterService {
  public createFilterParams(filter: ConfirmacionBOEFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {
if (filter.fechaCreacionFinal !== undefined && filter.fechaCreacionFinal !== null) {
  filter.fechaCreacionFinal.setHours(23, 59, 59);
}
if (filter.fechaEdictoFinal !== undefined && filter.fechaEdictoFinal !== null) {
  filter.fechaEdictoFinal.setHours(23, 59, 59);
}
    return new HttpParams()
    .append('lote', (filter.lote ? filter.lote.toString() : ''))
    .append('fechaCreacionInici',  Reflection.empty(filter.fechaCreacionInici) ? null :  typeof(filter.fechaCreacionInici) === 'string' ?
    filter.fechaCreacionInici : filter.fechaCreacionInici.toDateString())
    .append('fechaCreacionFinal',  Reflection.empty(filter.fechaCreacionFinal) ? null :  typeof(filter.fechaCreacionFinal) === 'string' ?
    filter.fechaCreacionFinal : filter.fechaCreacionFinal.toDateString())
    .append('fechaCreacionInici',  Reflection.empty(filter.fechaCreacionInici) ? null :  typeof(filter.fechaCreacionInici) === 'string' ?
    filter.fechaCreacionInici : filter.fechaCreacionInici.toDateString())
    .append('fechaEdictoFinal',  Reflection.empty(filter.fechaEdictoFinal) ? null :  typeof(filter.fechaEdictoFinal) === 'string' ?
    filter.fechaEdictoFinal : filter.fechaEdictoFinal.toDateString())
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
