import { FormLiveStateType } from './form-live-state-type.model';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-form-live-state',
  templateUrl: './form-live-state.component.html',
  styleUrls: ['./form-live-state.component.scss']
})
export class FormLiveStateComponent implements OnInit {
  public Types = FormLiveStateType;

  @Input() state: FormLiveStateType;

  constructor() { }

  ngOnInit() {
  }

}
