<app-toolbar>
  <app-tool-button text="Exportador" i18n-text (click)="this.goTramitador()" color="has-color-yellow" icon="fa-th-list"></app-tool-button>
  <app-tool-button-permissions [permission]="this.auth.Pages.Tramitador"></app-tool-button-permissions>
</app-toolbar>


<div class="row">
  <!-- #region TABLA PRINCIPAL -->

  <div class="col-sm-7 ">
    <div class="row" class="text-center"><h4 i18n>Històric</h4></div>
    <div class="d-flex justify-content-between">
      <div class="flex-grow-1"><app-filter-selector [filter]="this.filter"></app-filter-selector></div>
      <div class="">
        <mat-paginator #paginator
          [pageIndex]="0"
          [pageSize]="this.filter.paginator.pageSize"
          [pageSizeOptions]="[20, 40, 60, 100]">
        </mat-paginator>
      </div>
    </div>
    <div class="row" style="margin-left: 4px;">
      <div class="table-sdw is-fixed">
        <mat-table #table="matSort" [dataSource]="dataSource" matSort aria-label="Elements" matSortActive="Fecha" matSortDirection="desc" class="is_header_sticky">
          <ng-container matColumnDef="Operacion">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Operació</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.operacio}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="Fecha">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.Fecha | longDate}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="FechaInicio">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data inicial</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.FechaInicio | shortDate}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="FechaFin">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data final</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.FechaFin | shortDate}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="Usuario">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Usuari</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.Usuario}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="NumeroExpedientes">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Expedients</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.NumeroExpedientes}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="NumeroFotos">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Imatges</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.NumeroFotos}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Descarregar">
            <mat-header-cell *matHeaderCellDef i18n>Descarregar</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <a *ngIf="row.RutaFichero!=null" class="btn btn-xs btn-primary" title="Descarregar tràmit" i18n-title [href]="this.filesService.getExportFileVirtualUrl(row.RutaFichero)" target="_blank">
                <i class="fa fa-download"></i>
              </a>
              <a *ngIf="row.RutaFichero==null" class="btn btn-xs btn-primary disabled" title="Descarregar tràmit" i18n-title>
                <i class="fa fa-download"></i>
              </a>
              
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Botons">
            <mat-header-cell *matHeaderCellDef i18n>Anul·lar</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <button class="btn btn-xs btn-danger" title="Anul·lar tràmit"  i18n-title [disabled]="anularDisabled(row)" (click)="anularTramit(row)">
                <i class="fa fa-window-close"></i>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="UsuarioAnulador">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Usuari Anul.</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.UsuariAnulador}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="FechaAnulacion">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data Anul.</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.FechaAnulacion | shortDate }}</mat-cell>
          </ng-container>

          

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let i = index; let row; columns: displayedColumns;" class="has-iconset" (click)="this.loadDetallPage(row.Som_Id)"
            [ngClass]="this.selectedItem === row.Som_Id ? 'is-active' : ''"></mat-row>
        </mat-table>
        </div>
    </div>
  </div>

<!-- #endregion -->

<!-- #region TABLA DETALLE HISTORICO -->

<div class="col-sm-5">
  <div class="row" class="text-center"><h4 i18n>Detall</h4></div>
  <div class="row">
    <div class="d-flex justify-content-between">
      <div class="flex-grow-1">&nbsp;</div>
      <div class="">
        <mat-paginator #paginatorDetail
          [pageIndex]="0"
          [pageSize]="this.filter.paginator.pageSize"
          [pageSizeOptions]="[20, 40, 100]">
        </mat-paginator>
      </div>
    </div>
  </div>
  <div class="row">
      <div class="table-sdw is-fixed">
      <mat-table #tableDetail="matSort" [dataSource]="this.detailedDataSource" matSort aria-label="Elements" matSortActive="Expediente" matSortDirection="asc" class="is_header_sticky" style="margin-left: 10px; overflow-y: scroll">
        <ng-container matColumnDef="Expediente">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Expedient</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.Expediente}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="NumeroDenuncia">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>N. Denúncia</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.NumeroDenuncia}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Fecha">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.Fecha | shortDate}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="Hora">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Hora</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.Hora}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Importe">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Import</mat-header-cell>
          <mat-cell *matCellDef="let row" class="text-right">{{row.Importe | money}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Matricula">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Matrícula</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.Matricula}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Resultado">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Resultat</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.Resultado}}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsDetail"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsDetail;" class="has-iconset" [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
      </mat-table>
    </div>
  </div>
</div>

<!-- #endregion -->

</div>
