import { Component, OnInit, ViewChild, ElementRef, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatTableDataSource } from '@angular/material';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { AparellService } from '../../services/aparells-service.service';
import { FormService } from 'path-shared/services/form/form.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { AparellDetail } from '../../models/aparellDetail.model';
import { LocalizacionService } from '../../services/localizacion.service';
import { LocalizacionFilter } from '../../models/localizacion-filter';
import { LocalizacionModel } from '../../models/localizacionModel';
import { Aparell } from '../../models/aparell.model';
import { CalleEditorComponent } from 'app/sections/configuracio/carrers/components/calle-editor/calle-editor.component';
import { Reflection } from 'path-shared/services/reflection/reflection';
import { CarrersComponent } from 'app/sections/configuracio/carrers/pages/carrers/carrers.component';
import { InfraccioComponent } from 'app/sections/configuracio/Infraccions/pages/infraccio.component';
import { AparellTipus } from 'app/sections/configuracio/aparells/models/aparellTipus.model';
import { PropostaTipus } from 'app/sections/propostes/models/proposta-tipus.model';
import { PropostesService } from 'path-propostes-services/propostes.service';
import { ParametersService } from 'path-shared/services/parameters/parameters.service';
import { GridImagesComponent } from 'path-shared/components/grid-images/grid-images/grid-images.component';
import { FormMultiSelect, FormMultiSelectExtra } from 'path-shared/components/form/form-multi-select/model/form-multi-select.model';
import { TipusMatricula } from 'app/sections/ciutat/tipus-matricules/models/tipus-matricula.model';
import { Alarma } from 'app/sections/alarmes/models/alarma.model';
import { AlarmesService } from 'app/sections/alarmes/services/alarmes.service';
import { Sector } from '../../models/sector';
import { CarrersService } from 'app/sections/configuracio/carrers/services/carrers-service.service';
import { ModalMultiSelectComponent } from 'path-shared/components/modal-multi-select/modal-multi-select.component';
import { isNull, isNullOrUndefined } from 'util';
import { InfraccioService } from 'app/sections/configuracio/Infraccions/services/infraccions-service.service';

declare var $;

enum MetodeDeltaCar {
  INFRACCION = 'INFRACCION',
  CALLE = 'CALLE',
  PDA = 'PDA',
  SECTOR = 'SECTOR'
}

@Component({
  selector: 'app-aparells-editor',
  templateUrl: './aparells-editor.component.html',
  styleUrls: ['./aparells-editor.component.scss']
})

export class AparellsEditorComponent implements OnInit {

  enumMetodeDeltaCar = MetodeDeltaCar;
  secure_id: string;
  aparell: AparellDetail;
  dialogData: ComponentDialog;
  closeButtonText: string;
  aparellsTipus = Array<AparellTipus>();
  filter: LocalizacionFilter = new LocalizacionFilter('infraccion', 'asc');
  dataSource: MatTableDataSource<LocalizacionModel> = new MatTableDataSource();
  dataSourceAlarmes: MatTableDataSource<Alarma> = new MatTableDataSource();
  dataSourceCopia: MatTableDataSource<LocalizacionModel> = new MatTableDataSource();
  isDeltaCar = false;
  tipusRadar = Array<any>();
  localizacionPropuestas = Array<any>();
  clearFrom = false;
  localizacionAdd = new LocalizacionModel;
  sinDataDC = true;
  anadirSectorGuardat = null;

  RTAI = { infraccio: null, infraccioDesc: '', PDA: null, aparellID: '' };
  RTAC = { idCarrer: null, carrerDesc: '', PDA: null, aparellID: '', carrerSiglas: '' };

  PDAs = [];
  formsLocalizaciones;
  formsSectores;

  carrer: any = 1;
  nomCarrer: string;
  deltaCarTipus = [{ 'id': 'POL', 'descripcio': this.i18n('Policia') }, { 'id': 'ZB', 'descripcio': this.i18n('Zona Blava') }];
  deltaCarZBMetodes = [{ 'id': MetodeDeltaCar.CALLE, 'descripcio': this.i18n('Calles') },
  { 'id': MetodeDeltaCar.INFRACCION, 'descripcio': this.i18n('Infracciones') },
  { 'id': MetodeDeltaCar.PDA, 'descripcio': this.i18n('PDA') },
  { 'id': MetodeDeltaCar.SECTOR, 'descripcio': this.i18n('Sectores') }];
  deltaCarPOLMetodes = [{ 'id': MetodeDeltaCar.INFRACCION, 'descripcio': this.i18n('Infracciones') },
  { 'id': MetodeDeltaCar.PDA, 'descripcio': this.i18n('PDA') }];

  infraccion: any = 1;
  iprdescor: string;

  localizacion: LocalizacionModel;
  sinData = false;
  mostrarPanel = false;
  calles = new Array<FormMultiSelectExtra>();
  callesSinPDA = new Array<FormMultiSelectExtra>();
  infracciones = new Array<FormMultiSelect>();
  infraccionesSinPDA = new Array<FormMultiSelect>();

  llistesBlanques = [];
  @ViewChild('mainForm') private mainForm: ElementRef;
  @ViewChild('formAvanzada') private formAvanzada: ElementRef;

  @Input() isReadOnly: boolean;
  @ViewChild('forma') private forma: ElementRef;
  @ViewChild('formDC') private formDC: ElementRef;
  displayedColumns = ['rangoFechas', 'velocitatVia', 'proposta', 'infraccio', 'nomCarrer', 'numCarrer', 'accions', 'actiu'];
  displayedColumnsAlarmes = ['estat', 'text', 'data', 'usuari', 'botons'];

  displayedColumnsDC = ['carrer', 'PDA', 'accions'];
  constructor(
    private dialog: MatDialog,
    private confirmationDialogService: ConfirmationDialogService,
    public auth: AuthorizationService,
    private aparellService: AparellService,
    private alarmesService: AlarmesService,
    public i18n: I18n,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    public thisDialogRef: MatDialogRef<AparellsEditorComponent>,
    private formService: FormService,
    private _localizacion: LocalizacionService,
    private parameterService: ParametersService,
    private carrersService: CarrersService,
    private infraccionsService: InfraccioService,

    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
  ) {
    this.aparell = Builder.newAparell();
    this.dialogData = data;
    this.secure_id = this.dialogData.id;
    this.isReadOnly = true;
    this.aparellsTipus = <Array<AparellTipus>>data.tag['aparellTipus'];
    this.tipusRadar = data.tag['tipusRadar'];
    this.localizacionPropuestas = data.tag['localizacionPropuestas'];
    if (this.dialogData.isReadOnly) {
      this.closeButtonText = this.i18n('Tancar');
    } else {
      this.closeButtonText = this.i18n('Llista');
    }
  }

  numAparato: any;
  ngOnInit() {
    this.getLlistesBlanques();
    if (this.secure_id !== null) {
      // this.getLocalizacion
      this.aparellService.getAparell(this.secure_id).subscribe(data => {
        this.aparell = data;
        this.tipusRadarSelected();
        this.getCalles(this.aparell);
        this.getInfracciones(this.aparell);
        this.llistesBlanques.forEach(listaBlanca => {
          if (this.aparell.llistesBlanques.find(x => x.item_id === listaBlanca.item_id)) {
            this.llistesBlanques = this.llistesBlanques.filter(x => x.item_id !== listaBlanca.item_id);
            this.llistesBlanques.unshift(new FormMultiSelect(listaBlanca.item_id, listaBlanca.item_text));
          }
        });
        this.numAparato = this.aparell.id;
        this.localizacionAdd.cod_aparat = this.aparell.id;
        // if (this.aparell.idTipus !== 5) {
        //   this.mostrarPanel = true;
        // }
        this.loadAlarmes();
        this.getLocalizacionesAparell();
      });
    } else {
      const data = [new LocalizacionModel()];
      this.dataSource.data = data;
      this.mostrarPanel = false;

    }

    // Carreguem els agents per a passar-les al dialog
    this.parameterService.getPDAs().subscribe(result => {
      this.PDAs = [];
      if (result !== null) {
        let i: number;
        i = 0;
        result.forEach(element => {
          this.PDAs[i] = { id: element };
          i++;
        });
      }
      // this.PDAs.unshift({'id': ''});
    });

    $(function () {
      $('[data-toggle="popover"]').popover();
    });
  }

  TieneDataDeltaCar(): Boolean {
    const tieneDataZB = this.aparell.deltaCarTipus === 'ZB' && 
    (this.aparell.deltaCarZBMetode === MetodeDeltaCar.INFRACCION && this.aparell.RTAI !== undefined && this.aparell.RTAI.length !== 0
      || this.aparell.deltaCarZBMetode === MetodeDeltaCar.CALLE && this.aparell.RTAC !== undefined && this.aparell.RTAC.length !== 0);
      
    const tieneDataPol = this.aparell.deltaCarTipus === 'POL' && this.aparell.RTAI !== undefined && this.aparell.RTAI.length !== 0;
    return tieneDataPol || tieneDataZB;
  }

  save() {
    if (this.formService.allFieldsValid(this.mainForm)) {

      this.aparellService.setAparell(this.aparell).subscribe(result => {

        this.toastr.success(this.i18n('Aparell guardat correctament'));
        // this.thisDialogRef.close(true);
      });
    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
    }
  }

  cancel() {
    this.thisDialogRef.close(false);
  }

  remove() {
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol esborrar l\'aparell?'))
      .then((confirmed) => {
        if (confirmed) {
          this.aparellService.removeAparell(this.secure_id).subscribe(result => {
            this.toastr.success(this.i18n('Aparell esborrat correctament'));
            this.dialog.closeAll();
            this.thisDialogRef.close(true);
          });
        }
      });
  }

  seleccionarCarrer(row = null) {

    this.isReadOnly = false;
    const data: ComponentDialog = Builder.newComponentDialog(true, this.isReadOnly, null);
    const ref = this.dialog.open(CarrersComponent, {
      width: '90%',
      closeOnNavigation: false,
      autoFocus: true,
      data: data
    });
    ref.afterClosed().subscribe(res => {
      this.isReadOnly = true;
      if (res.nomCarrer !== undefined && res.nomCarrer !== null && res.nomCarrer !== '') {
        row.cod_calle = res.secure_id;
        row.calleDesc = res.nomCarrer;
      }
    });

  }

  seleccionarInfraccion(row) {
    this.isReadOnly = false;
    const data: ComponentDialog = Builder.newComponentDialog(true, this.isReadOnly, null);
    const ref = this.dialog.open(InfraccioComponent, {
      width: '90%',
      closeOnNavigation: false,
      autoFocus: true,
      data: data
    });
    ref.afterClosed().subscribe(res => {
      this.isReadOnly = true;
      if (res.infraccio.secureId !== undefined && res.infraccio.secureId !== null && res.infraccio.secureId !== '') {
        row.infraccion = res.infraccio.secureId;
        row.infraccionDesc = res.infraccio.descCurta;
        this.RTAI.infraccio = res.infraccio.id;
        this.RTAI.infraccioDesc = res.infraccio.descCurta;
      }
    });

  }

  getLocalizacion() {
    this._localizacion.getLocalizacion(this.filter, 'ASC', 'infraccion')
      .subscribe(data => {
        console.log(data);

        this.localizacion = data;
        if (data.length === 0) {
          this.sinData = true;

        } else {
          this.sinData = false;
          this.dataSource.data = data;
        }

      });
  }

  seleccionarActiu(row) {

    if (row.SOM_ID !== undefined) {
      // enviamos !actiu porque aun no ha saltado el evento del checkbox
      this._localizacion.setActiu(row.SOM_ID, !row.actiu).subscribe(() => {
        this.getLocalizacionesAparell();
      });
    }
  }

  getLocalizacionesAparell() {
    this._localizacion.getLocalizacionesAparell(this.localizacionAdd.cod_aparat)
      .subscribe(data => {
        this.localizacion = data;
        if (data.length === 0) {
          this.sinData = true;
        } else {
          this.sinData = false;
        }
        data.push(new LocalizacionModel());
        this.dataSource.data = data;
        this.dataSourceCopia.data = JSON.parse(JSON.stringify(data));
        this.formsLocalizaciones = document.getElementsByName('rowSubmit');
      });
  }

  addLocalizacion() {
    // overlap
    let i = 0;
    let overlap = false;


    while (!overlap && i < this.dataSource.data.length) {
      overlap = this.dateRangeOverlaps(this.localizacionAdd.rango_fechas[0], this.localizacionAdd.rango_fechas[1],
        this.dataSource.data[i].rango_fechas[0], this.dataSource.data[i].rango_fechas[1]);
      i++;
    }


    if (overlap) {
      this.toastr.info(this.i18n('Els rangs de dates es solapen!'));
    } else if (this.localizacionAdd.infraccion === null || this.localizacionAdd.cod_calle === null) {
      this.toastr.error(this.i18n('Hi ha errors en el formulari!'));
    } else if (this.formService.allFieldsValid(this.forma)) {

      this._localizacion.addLocalizacion(this.localizacionAdd)
        .subscribe(data => {
          this.clearFrom = true;
          if (data === 1) {
            this.toastr.success(this.i18n('localització guardada correctament'));
            this.getLocalizacionesAparell();
            this.nomCarrer = '';
            this.carrer = '';
            this.infraccion = '';
            this.iprdescor = '';

          } else {
            this.toastr.error(this.i18n('Hi ha errors en el formulari!'));
          }
          setTimeout(() => { this.clearFrom = false; }, 100);
        });
    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.forma.nativeElement.classList.contains('displayErrors')) {
        this.forma.nativeElement.classList.add('displayErrors');
      }
    }
  }

  removeLocalizacion(som_id: number) {

    if (this.isNull(this.secure_id)) {
      this.toastr.warning(this.i18n('No se puede eliminar una localización sin haber guardado la información básica antes.'), this.i18n('Atención! Acción requerida'));
      return;
    }
    // return alert(som_id)
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol esborrar la localització ?'))
      .then((confirmed) => {
        if (confirmed) {
          this._localizacion.removeLocalizacion(som_id).subscribe(result => {
            this.toastr.success(this.i18n('Localització esborrat correctament'));
            this.getLocalizacionesAparell();
          });
        }
      });
  }

  addRTAI() {

    if (this.formService.allFieldsValid(this.formDC)) {

      this.RTAI.aparellID = this.aparell.id;
      this.aparellService.addRTAI(this.RTAI)
        .subscribe(data => {
          this.clearFrom = true;
          this.toastr.success(this.i18n('Infracció guardada correctament'));
          this.aparellService.getAparell(this.secure_id).subscribe(res => {
            this.aparell = res;
            this.aparell.idTipus = this.aparell.idTipusDeltaCar;
            this.RTAI.infraccioDesc = null;
            this.RTAI.infraccio = null;
            this.RTAI.PDA = null;
            this.sinDataDC = false;
          });

          setTimeout(() => { this.clearFrom = false; }, 100);
        });
    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.forma.nativeElement.classList.contains('displayErrors')) {
        this.forma.nativeElement.classList.add('displayErrors');
      }
    }

  }

  saveRTAI() {
      if (this.aparell.deltaCarZBMetode === MetodeDeltaCar.INFRACCION) {
        this.aparellService.addRTAI(this.RTAI).subscribe(data => {
          this.toastr.success(this.i18n('Infracció guardada correctament'));
          let pdaSeleccionada = this.aparell.DeltaCarPDA
          let metodeSeleccionat = this.aparell.deltaCarZBMetode
          this.aparellService.getAparell(this.secure_id).subscribe(res => {
            this.aparell = res;
            this.aparell.idTipus = this.aparell.idTipusDeltaCar;
            this.aparell.DeltaCarPDA = pdaSeleccionada //mantenim la pda que hi havia seleccionada després de recuperar l'aparell actualitzat de la bdd
            this.aparell.deltaCarZBMetode = metodeSeleccionat;
          });
          this.infraccionesSinPDA.splice(this.infraccionesSinPDA.findIndex(infraccion => infraccion.item_id == this.RTAI.infraccio), 1) //actualitzem la vista de la llista de carrers sense pda assignada manualment
        });
      }
  }
  
  removeRTAI(row) {
    this.aparellService.removeRTAI(row).subscribe(result => {
      this.toastr.success(this.i18n('Assignació esborrada correctament'));
      let infraccioSeleccionada = row.item_id
      let pdaSeleccionada = this.aparell.DeltaCarPDA
      let metodeSeleccionat = this.aparell.deltaCarZBMetode
      this.aparellService.getAparell(this.secure_id).subscribe(data => {
        this.aparell = data;
        this.aparell.idTipus = this.aparell.idTipusDeltaCar;
        this.aparell.DeltaCarPDA = pdaSeleccionada
        this.aparell.deltaCarZBMetode = metodeSeleccionat;

        if (this.aparell.RTAI.length === 0) {
          this.sinDataDC = true;
        }
        //actualitzem les llistes de la vista
        this.infraccionesSinPDA = this.infracciones.filter(infraccion => 
            this.aparell.RTAI.every(obj => obj.infraccio != infraccion.item_id) && infraccion.item_id != infraccioSeleccionada)
        //this.getInfracciones(this.aparell);
      });

    });
  }

  saveRTAC () {
    if (this.aparell.deltaCarZBMetode === MetodeDeltaCar.CALLE) {
      this.aparellService.addRTAC(this.RTAC).subscribe(data => {
        this.toastr.success(this.i18n('Carrer guardat correctament'));
        let pdaSeleccionada = this.aparell.DeltaCarPDA
        let metodeSeleccionat = this.aparell.deltaCarZBMetode
        this.aparellService.getAparell(this.secure_id).subscribe(res => {
          this.aparell = res;
          this.aparell.DeltaCarPDA = pdaSeleccionada //mantenim la pda que hi havia seleccionada després de recuperar l'aparell actualitzat de la bdd
        });
        this.callesSinPDA.splice(this.callesSinPDA.findIndex(calle => calle.item_id == this.RTAC.idCarrer), 1) //actualitzem la vista de la llista de carrers sense pda assignada manualment
        this.aparell.deltaCarZBMetode = metodeSeleccionat;
      });
    }
  }

  removeRTAC(row) {
    this.aparellService.removeRTAC(row).subscribe(result => {
      this.toastr.success(this.i18n('Assignació esborrada correctament'));
      let pdaSeleccionada = this.aparell.DeltaCarPDA
      this.aparellService.getAparell(this.secure_id).subscribe(data => {
        this.aparell = data;
        this.aparell.idTipus = this.aparell.idTipusDeltaCar;
        this.aparell.DeltaCarPDA = pdaSeleccionada

        if (this.aparell.RTAC.length === 0) {
          this.sinDataDC = true;
        }
        this.getCalles(this.aparell);
      });
    });
    
  }

  updateLocalizacion(localizacion: LocalizacionModel, index) {
    if (this.isNull(this.secure_id)) {
      this.toastr.warning(this.i18n('No se puede guardar una localización sin haber guardado la información básica antes.'), this.i18n('Atención! Acción requerida'));
      return;
    }
    if (this.formsLocalizaciones[index].getElementsByClassName('ng-invalid').length === 0) {

    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.formsLocalizaciones[index].classList.contains('displayErrors')) {
        this.formsLocalizaciones[index].classList.add('displayErrors');
      }
      return;
    }


    let i = 0;
    let iguals = true;
    let overlap = false;

    // // validar si s'ha canviat alguna data
    // while (iguals && i < this.dataSource.data.length) {
    //   iguals = this.dataSource.data[i].rango_fechas[0] === this.dataSourceCopia.data[i].rango_fechas[0];
    //   iguals = this.dataSource.data[i].rango_fechas[1] === this.dataSourceCopia.data[i].rango_fechas[1];
    //   i++;
    // }
    iguals = false;
    if (iguals) {
      this.toastr.info(this.i18n('No hi ha canvis per guardar'));
    } else {
      // validar que no es solapin els rangs
      i = 0;
      while (!overlap && i < this.dataSource.data.length - 1) {
        if (this.dataSource.data[i + 1].rango_fechas !== undefined && this.dataSource.data[i + 1].SOM_ID !== undefined) {
          overlap = this.dateRangeOverlaps(this.dataSource.data[i].rango_fechas[0], this.dataSource.data[i].rango_fechas[1],
            this.dataSource.data[i + 1].rango_fechas[0], this.dataSource.data[i + 1].rango_fechas[1]);
        }
        i++;
      }

      if (!overlap) {
        // crida a guardar al servidor!
        localizacion.cod_aparat = this.aparell.id;
        this._localizacion.addLocalizacion(localizacion)
          .subscribe(data => {
            this.clearFrom = true;
            if (data === 1) {
              this.toastr.success(this.i18n('localització guardada correctament'));
              this.getLocalizacionesAparell();
              this.nomCarrer = '';
              this.carrer = '';
              this.infraccion = '';
              this.iprdescor = '';

            } else {
              this.toastr.error(this.i18n('Hi ha errors en el formulari!'));
            }
            setTimeout(() => { this.clearFrom = false; }, 100);
          });
      } else {
        this.toastr.info(this.i18n('Els rangs de dates es solapen!'));
      }
    }
  }

  tipusRadarSelected() {
    if (this.aparell.idTipus !== null) {
      // DELTA_CAR - Delta Car
      if (this.aparell.idTipus === this.aparell.idTipusDeltaCar || this.aparell.idTipus === 5) {
        this.isDeltaCar = true;
        this.mostrarPanel = false;
        if (isNullOrUndefined(this.aparell.deltaCarTipus)) {
          this.aparell.deltaCarTipus = 'POL';
          this.aparell.deltaCarZBMetode = this.enumMetodeDeltaCar.INFRACCION;
        }
      } else {
        this.mostrarPanel = true;
        this.isDeltaCar = false;
        // this.aparell.minutsPassada = null;
        // this.aparell.doblePassada = 0;
      }
    }

  }

  dateRangeOverlaps(a_start, a_end, b_start, b_end) {
    a_start = this.stringToDate(a_start);
    a_end = this.stringToDate(a_end);
    b_start = this.stringToDate(b_start);
    b_end = this.stringToDate(b_end);

    if (a_start <= b_start && b_start <= a_end) { return true; } // b starts in a
    if (a_start <= b_end && b_end <= a_end) { return true; } // b ends in a
    if (b_start < a_start && a_end < b_end) { return true; } // a in b

    return false;
  }

  isDate(value) {
    return value instanceof Date;
  }

  stringToDate(value) {
    if (!this.isDate(value)) {
      value = new Date(value);
    }
    return value;
  }

  openCertificatsGrid() {
    this.dialog.open(GridImagesComponent, {
      width: '60%',
      closeOnNavigation: false,
      autoFocus: true,
      data: this.aparell.somId,
    });
  }

  getLlistesBlanques() {
    this.aparellService.getListasBlancas().subscribe(result => {
      const listasBlancas: Array<TipusMatricula> = result;
      this.llistesBlanques = [];
      listasBlancas.forEach(listaBlanca => {
        this.llistesBlanques.push(new FormMultiSelect(listaBlanca.codi, listaBlanca.descripcio));
      });
    });
  }

  getCalles(aparell) {
    this.carrersService.getAllForCombo().subscribe(result => {
      this.calles = [];
      this.callesSinPDA = [];

      result.forEach(calle => {
        this.calles.push(new FormMultiSelectExtra(calle.somId, calle.nomCarrer, calle.sigles));
      });

      this.callesSinPDA = this.calles.filter(calle => 
        aparell.RTAC.every(obj => obj.idCarrer != calle.item_id)
      );
    });
  }

  getInfracciones(aparell) {
    this.infraccionsService.getAllForCombo().subscribe(result => {
      this.infracciones = [];
      this.infraccionesSinPDA = [];

      result.forEach(infraccio => {
        this.infracciones.push(new FormMultiSelect(infraccio.id, infraccio.descCombo));
      });

      this.infraccionesSinPDA = this.infracciones.filter(infraccion => 
        aparell.RTAI.every(obj => obj.infraccio != infraccion.item_id)
      );
    });
  }

  acceptada(alarma: Alarma) {
    return !Reflection.empty(alarma.usuari);
  }

  checkAlarm(secure_id) {
    this.alarmesService.ackAlarma(secure_id).subscribe(result => {
      this.loadAlarmes();
    });
  }

  loadAlarmes() {
    this.alarmesService.getByAparell(this.secure_id).subscribe(res => {
      this.dataSourceAlarmes = new MatTableDataSource(res);

    });

  }

  isNull(valor) {
    return valor === null || valor === undefined || valor === '';
  }

  setActiu(fechas, row: LocalizacionModel) {
    if (row !== undefined && fechas !== undefined && typeof fechas[0].getMonth === 'function') {
      row.actiu = fechas[0] <= new Date() && new Date() <= fechas[1];

    }
  }

  dataSourceDeltaCar() {
    let datasource;
    if (this.aparell.deltaCarTipus === 'POL') {
      datasource = this.aparell.RTAI;
    } else if (this.aparell.deltaCarTipus === 'ZB' && this.aparell.deltaCarZBMetode === this.enumMetodeDeltaCar.CALLE) {
      datasource = this.aparell.RTAC;
      this.displayedColumnsDC = ['carrer', 'PDA', 'accions'];
    } else {
      datasource = this.aparell.RTAI;
      this.displayedColumnsDC = ['infraccio', 'PDA', 'accions'];
    }

    return datasource;
  }

  getLabelAdvancedSettings() {
    let label = '';
    if (this.aparell.deltaCarTipus === 'POL' || this.aparell.deltaCarZBMetode === this.enumMetodeDeltaCar.INFRACCION) {
      label = this.i18n('Infracciones');
    } else if (this.aparell.deltaCarTipus !== 'POL' && this.aparell.deltaCarZBMetode === this.enumMetodeDeltaCar.CALLE) {
      label = this.i18n('Calles');
    } else if (this.aparell.deltaCarTipus !== 'POL' && this.aparell.deltaCarZBMetode === this.enumMetodeDeltaCar.SECTOR) {
      label = this.i18n('Sectores');
    }
    return label;
  }

  addSector() {
    this.anadirSectorGuardat = true;
    this.aparell.sectores.push(new Sector(this.aparell.id));
  }

  openModalCalles(sector: Sector) {

    // Solo mostraremos las calles que no esten en otros sectores
    let usedCalles = [];
    this.aparell.sectores.filter(x => x.id !== sector.id).forEach(sector => {
      sector.calles.forEach(calle => {
        usedCalles.push(calle.item_id);
      })
    });
    let availableCalles = this.calles.filter(x => !usedCalles.includes(x.item_id));

    const ref = this.dialog.open(ModalMultiSelectComponent, {
      width: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      data: { title: this.i18n('Calles'), multiSelectData: availableCalles, selectedValues: sector.calles }
    });
    ref.afterClosed().subscribe(res => {
      if (res !== undefined) {
        sector.calles = res;
      }
    });
  }

  saveSector(index: number) {
    this.anadirSectorGuardat = null;
    this.formsSectores = document.getElementsByName('sector');

    if (this.formsSectores[index].getElementsByClassName('ng-invalid').length === 0) {
      this.aparellService.saveSector(this.aparell.sectores[index]).subscribe((res: number) => {
        this.aparell.sectores[index].id = res;
        this.toastr.success(this.i18n("Sector guardado correctamente"));

      });
    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.formsSectores[index].classList.contains('displayErrors')) {
        this.formsSectores[index].classList.add('displayErrors');
      }
    }
  }

  deleteSector(index: number) {
    this.anadirSectorGuardat = null;
    this.formsSectores = document.getElementsByName('sector');

    if (this.aparell.sectores[index].id === null) {
      this.aparell.sectores.splice(index, 1);
    } else {
      this.aparellService.deleteSector(this.aparell.sectores[index].id).subscribe();
      this.aparell.sectores.splice(index, 1);
    }
    this.toastr.success(this.i18n("Sector eliminado correctamente"));

  }

  mostrarConfiguracionAvanzada(): Boolean {
    let result = !isNull(this.aparell.idTipus) && this.aparell.idTipus !== 6 && this.aparell.idTipus !== 7
      && this.aparell.deltaCarZBMetode !== MetodeDeltaCar.PDA;

    return result;
  }

  assignarPdaToCalle(carrer) {
    this.RTAC.idCarrer = carrer.item_id
    this.RTAC.carrerDesc = carrer.item_text
    this.RTAC.carrerSiglas = carrer.item_text_2
    this.RTAC.PDA = this.aparell.DeltaCarPDA
    this.RTAC.aparellID = this.aparell.id

    this.saveRTAC()
  }
  assignarPdaToInfraccio(infraccio) {
    this.RTAI.infraccio = infraccio.item_id
    this.RTAI.infraccioDesc = infraccio.item_text
    this.RTAI.PDA = this.aparell.DeltaCarPDA
    this.RTAI.aparellID = this.aparell.id

    this.saveRTAI()
  }
}
