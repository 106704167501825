import { ZonesAparcareService } from './../../zones-aparcare/services/zones-aparcare.service';
import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Observable, Subscription } from 'rxjs';
import { TiquetsFilter } from '../../models/tiquets-filter';
import { ZonaAparcare } from '../../zones-aparcare/models/zona-aparcare.model';

@Component({
  selector: 'app-tiquets-filter',
  templateUrl: './tiquets-filter.component.html',
  styleUrls: ['./tiquets-filter.component.scss']
})
export class TiquetsFilterComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  typeLabels = ['Data ticket', 'Data Banc'];
  typeValues = [true, false];
  tipusData: boolean;

  filter: TiquetsFilter;
  fechaHoraActual: Date;
  public zonas: ZonaAparcare[];
  public secureIdZona:'';
  @Output() notify: EventEmitter<TiquetsFilter> = new EventEmitter<TiquetsFilter>();

  @ViewChild('mainForm') private mainForm: ElementRef;

  constructor(
    public thisDialogRef: MatDialogRef<TiquetsFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private zonesAparcareService: ZonesAparcareService
  ) {
    this.filter = data.filter;

    if (this.filter.fechaTicketInicio !== null || this.filter.fechaTicketFin !== null) {
      this.tipusData = true;
    } else if (this.filter.fechaBancoInicio !== null || this.filter.fechaBancoFin !== null) {
      this.tipusData = false;
    } else if (this.tipusData === null || this.tipusData === undefined) {
      this.tipusData = true;
    }

    this.fechaHoraActual = new Date();
    console.log(this.fechaHoraActual);
    this.zonesAparcareService.getAllZonas().subscribe(x =>{
      this.zonas=x;
    });
  }

  ngOnInit() {
    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.apply();
      }
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated();
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }

  resetDates(tipusData: boolean) {
    if (tipusData) {
      this.filter.fechaTicketInicio = null;
      this.filter.fechaTicketFin = null;
    } else {
      this.filter.fechaBancoInicio = null;
      this.filter.fechaBancoFin = null;
    }
  }
  onZonaChange(){
    this.filter.zonaSecureId= this.secureIdZona;
  }

}
