import { DipositEditorGeneralComponent } from '../diposit-editor-general/diposit-editor-general.component';
import { Builder } from 'path-shared/models/builder';
import { VehicleDipositDto } from '../../Models/diposit-vehicle.model';
import { Component, OnInit, Inject, ViewChild, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatTabGroup } from '@angular/material';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { PropostesService } from 'path-propostes-services/propostes.service';
import { PhotoBoxGalleryComponent } from 'path-shared/components/photo-box/components/photo-box-gallery/photo-box-gallery.component';
import { DipositGruaService } from '../../services/diposit-grua-service';
import { ToastrService } from 'ngx-toastr';
import { ReportsComponent } from 'path-shared/components/reports/reports.component';
import { FileDownloader } from 'path-shared/services/http/fileDownloader';
import { DenunciesImagesComponent } from 'app/sections/denuncies/components/denuncies-editor/components/denuncies-images/denuncies-images.component';
import { DenunciesService } from 'path-denuncies-services/denuncies.service';
import { BehaviorSubject } from 'rxjs-compat';

@Component({
  selector: 'app-desplaçats-editor',
  templateUrl: './desplaçats-editor.component.html',
  styleUrls: ['./desplaçats-editor.component.scss']
})
export class DesplaçatsEditorComponent {
  dialogData: ComponentDialog;
  closeButtonText: string;
  public vehicleDiposit: VehicleDipositDto;
  reports = [];

  update = new BehaviorSubject<boolean>(false);

  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  @ViewChild('pbg') pbg: DenunciesImagesComponent;
  @ViewChild('general') general: DipositEditorGeneralComponent;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private i18n: I18n,
    private propostesService: PropostesService, // utilizamos els servicio de propuestas para obtener imagenes
    private denunciesService: DenunciesService,
    private dipositService: DipositGruaService,
    private toastr: ToastrService,
    public auth: AuthorizationService,
    private dialog: MatDialog,
    public thisDialogRef: MatDialogRef<DesplaçatsEditorComponent>) {
    if (data.vehicleDiposit === null) {
      this.vehicleDiposit = Builder.newDipositGrua();
    } else {
      this.vehicleDiposit = data.vehicleDiposit;
      this.dipositService.getReports(this.auth.Pages.DipositGrua).subscribe(res => {
        this.reports = res;
      });
    }
    this.dialogData = data;
    if (this.dialogData.isReadOnly) {
      this.closeButtonText = this.i18n('Tancar');
    } else {
      this.closeButtonText = this.i18n('Llista');
    }
  }


  save() {
    let msg = '';
    if (this.vehicleDiposit.idVehicleFk === undefined || this.vehicleDiposit.idVehicleFk === null || this.vehicleDiposit.idVehicleFk === '') {
      msg = this.i18n('S\'ha d\'introduir una matrícula. ');
    }

    if (this.vehicleDiposit.idVehicleFk === undefined || this.vehicleDiposit.idVehicleFk === null || this.vehicleDiposit.idVehicleFk === '') {
      msg = msg + this.i18n('S\'ha de seleccionar un dipòsit.');
    }


    if (msg !== '') {
      this.toastr.warning(this.i18n('ATENCIÓ: ') + msg);
    } else {
      this.general.setTxtTipusVehiclePreventSave();
      this.dipositService.setDipositVehicle(this.vehicleDiposit).subscribe(result => {
        this.toastr.info(this.i18n('Registre guardat correctament'));
        this.thisDialogRef.close(true);
      });
    }
  }

  cancel() {
    this.thisDialogRef.close(false);
  }

  loadImatges() {
    if (this.vehicleDiposit !== undefined && this.vehicleDiposit !== null) {
      this.denunciesService.getDenunciaImagesNumDen(this.vehicleDiposit.iddenuncia).subscribe(images => {
        this.vehicleDiposit.IMATGES = images;
      });
    }
  }


}
