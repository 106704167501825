import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';


export class AvisosFilter extends FilterData {

  id: number;
  idTipusAvis: number;
  data: Date;
  fechaAvisoInicio: Date;
  fechaAvisoFin: Date;
  agent: string;
  pda: string;
  carpetes: string;
  idTipusAvisList: string;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.id = null;
    this.idTipusAvis = null;
    this.data = null;
    this.fechaAvisoInicio = null;
    this.fechaAvisoFin = null;
    this.agent = '';
    this.pda = '';
    this.carpetes = '';
    this.idTipusAvisList = '';

    this.labels['id'] = this.i18n('Id');
    this.labels['idTipusAvis'] = this.i18n('IdTipusAvis');
    this.labels['data'] = this.i18n('Data');
    this.labels['fechaAvisoInicio'] = this.i18n('Data Inici');
    this.labels['fechaAvisoFin'] = this.i18n('Data Fi');
    this.labels['agent'] = this.i18n('Agent');
    this.labels['pda'] = this.i18n('PDA');
    this.labels['carpetes'] = this.i18n('Assumpte');
    this.labels['idTipusAvisList'] = this.i18n('Tipus avís');

    this.formatOutput['fechaAvisoInicio'] = 'shortDate';
    this.formatOutput['fechaAvisoFin'] = 'shortDate';
  }

}
