import { Component, Inject, OnInit } from '@angular/core';
import { ActuacioMatricula } from '../../models/actuacioMatricula.model';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { TipusActuacio } from 'app/sections/agents/actuacions-agents/models/TipusActuacio.model';
import { ThrowStmt } from '@angular/compiler';
import { Instituciones } from 'path-shared/models/instituciones';

@Component({
  selector: 'app-actuacions-mapa-component',
  templateUrl: './actuacions-mapa-component.component.html',
  styleUrls: ['./actuacions-mapa-component.component.scss']
})
export class ActuacionsMapaComponentComponent implements OnInit {
  public styleMap = [
    {
      "featureType": "administrative",
      "elementType": "geometry",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "transit",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    }
  ];
  public currentLocation: {
    latitude: number,
    longitude: number
  };
  zoomMapa: number;
  markersArray:  Array<Marker>;
  map: any;
  displayedItems: ActuacioMatricula[] = [];
  somId: number;
  matricula: string;
  latitud: number;
  longitud: number;
  tipuActuacioIcon: TipusActuacio;
  fecha: Date;

  constructor(
    public thisDialogRef: MatDialogRef<ActuacionsMapaComponentComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.somId = data.somId;
    this.matricula = data.matricula;
    this.latitud = data.latitud;
    this.longitud = data.longitud;
    this.data = data.fecha;
    this.tipuActuacioIcon=data.tipuActuacioIcon;
    const inst = sessionStorage.getItem('currentInst');
    if (inst !== '' && inst !== undefined && inst !== 'undefined') {
      const institution: Instituciones = JSON.parse(inst);
      this.currentLocation = {latitude: institution.Latitud, longitude: institution.Longitud};
    }
    
    this.zoomMapa = 8;
  }

  ngOnInit() {
    this.zoomMapa = 8;
  }
  mapReady(map: any) {
    this.markersArray = [];
    this.map = map;
    this.loadMarker(this.latitud,this.longitud,this.somId,this.tipuActuacioIcon)
    // this.loadMarkers();
  }
  loadMarker(LAT, LON, codi, tipuActuacioIcon) {
    if (LAT != null && LON != null) {
      const marker: Marker = {
        lat: LAT,
        lng: LON,
        id: codi,
        label: codi.toString(),
        iconPath: tipuActuacioIcon
      };
      if (this.markersArray.find(x => x.id == marker.id) !== undefined) {
        this.markersArray = this.markersArray.filter(x => x.id != codi)
      }
      else {
        this.markersArray.push(marker);
      }
      
      var bounds = new google.maps.LatLngBounds();
      this.markersArray.forEach(customMarker => {
        bounds.extend(new google.maps.LatLng(customMarker.lat, customMarker.lng));
      })
      this.map.fitBounds(bounds)
    }
  }
}

interface Marker {
  lat: number;
  lng: number;
  label?: string;
  id?:string;
  iconPath?: string;
}