import { SortDirection } from "@angular/material";
import { FilterData } from "path-shared/models/filter-data";

export class ConfirmacionBOEFilter  extends FilterData {
  fechaCreacionInici: Date;
  fechaCreacionFinal: Date;
  fechaEdictoInici: Date;
  fechaEdictoFinal: Date;
  lote: number;
  
constructor(sortField: string, sortDir: SortDirection) {
  super(sortField, sortDir);
  this.fechaCreacionInici = null;
  this.fechaCreacionFinal = null;
  this.fechaEdictoInici = null;
  this.fechaEdictoFinal = null;
  this.lote = null;

  this.labels['fechaCreacionInici'] = this.i18n('Fecha creación inicio');
  this.labels['fechaCreacionFinal'] = this.i18n('Fecha creación final');
  this.labels['fechaEdictoInici'] = this.i18n('Fecha edicto inicio');
  this.labels['fechaEdictoFinal'] = this.i18n('Fecha edicto final');
  this.labels['lote'] = this.i18n('Lote');

  this.formatOutput['fechaCreacionInici'] = 'shortDate';
  this.formatOutput['fechaCreacionFinal'] = 'shortDate';
  this.formatOutput['fechaEdictoInici'] = 'shortDate';
  this.formatOutput['fechaEdictoFinal'] = 'shortDate';
}

}
