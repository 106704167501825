<app-dialog-header label="Detalle envio" i18n-label></app-dialog-header>
<app-toolbar>
  <!-- <app-tool-button text="Editar" i18n-text (click)="this.dialogData.isReadOnly = false" color="has-color-green" icon="fa-edit" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.DipositGrua) || !this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Guardar" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.DipositGrua) || this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Eliminar" i18n-text (click)="this.remove();" color="has-color-red" icon="fa-trash-alt" [disabled]="!this.auth.UserCanDelete(this.auth.Pages.DipositGrua) || this.dialogData.isReadOnly"></app-tool-button> -->
  <!-- <app-tool-button text="Impressió" i18n-text (click)="this.impressio();" color="has-color-blue" icon="fa-print" *ngIf="reports.length > 0" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.DipositGrua)"></app-tool-button> -->
  <app-tool-button text="Finalizar" i18n-text (click)="this.finalizarEnvio();" [disabled]="this.envio.fechaEnvio !== undefined && this.envio.fechaEnvio !== null" color="has-color-green" icon="fa-plus-square"></app-tool-button>
  <app-tool-button text="Informes" i18n-text (click)="this.mostrarInformes();" color="has-color-orange" icon="fa-edit"></app-tool-button>
  <app-tool-button text="Deshacer" i18n-text (click)="this.askDeshacer();" [disabled]="this.envio.fechaEnvio !== undefined && this.envio.fechaEnvio !== null" color="has-color-red" icon="fa-undo"></app-tool-button>
  <app-tool-button [text]="this.closeButtonText" i18n-text (click)="this.cancel()" color="has-color-yellow" icon="fa-th-list"></app-tool-button>
</app-toolbar>
<div class="row">
  <div class="row" style="padding-top: 2%;padding-left: 15px;">
    <div class="clearfix hidden-xs hidden-sm"> </div>
    <app-form-text-edit id="envio-editor-general-codi" class="col-xl-2" i18n-label label="Codi" [(value)]="this.envio.codi"  [isReadOnly]="true" ></app-form-text-edit>
    <app-form-text-edit id="envio-editor-general-tipoEnvio" class="col-xl-2" i18n-label label="Tipo envio" [(value)]="this.envio.tipoEnvio"  [isReadOnly]="true" ></app-form-text-edit>
  
    <app-form-date-edit id="envio-editor-general-fechaCreacion" i18n-label label="Fecha creación" class="col-sm-2"   [isReadOnly]="true"  [(value)]="this.envio.fechaCreacion"></app-form-date-edit>  
    <app-form-text-edit id="envio-editor-general-usuarioCreacion" class="col-xl-2" i18n-label label="Usuario creación" [(value)]="this.envio.usuarioCreacion"  [isReadOnly]="true" ></app-form-text-edit>
    
    <app-form-date-edit id="envio-editor-general-fechaEnvio" i18n-label label="Fecha envio" class="col-sm-2"   [isReadOnly]="true"  [(value)]="this.envio.fechaEnvio"></app-form-date-edit>  
    <app-form-text-edit id="envio-editor-general-usuarioEnvio" class="col-xl-2" i18n-label label="Usuario envio" [(value)]="this.envio.usuarioEnvio"  [isReadOnly]="true" ></app-form-text-edit>

    <app-form-text-edit id="envio-editor-general-pdfBoe" class="col-xl-2" i18n-label label="PDF BOE" [(value)]="this.envio.pdfBoe"  [isReadOnly]="true" ></app-form-text-edit>
    <app-form-date-edit id="envio-editor-general-fechaNotiBoe" i18n-label label="Fecha notificación BOE" class="col-sm-2"   [isReadOnly]="true" [(value)]="this.envio.fechaNotiBoe"></app-form-date-edit>  

    <app-form-text-edit id="envio-editor-general-usuarioBOE" class="col-xl-2" i18n-label label="Usuario BOE-TEU" [(value)]="this.envio.usuarioBOE"  [isReadOnly]="true" ></app-form-text-edit>
    <app-form-text-edit id="envio-editor-general-pubBOE" class="col-xl-2" i18n-label label="Nº publicación BOE-TEU" [(value)]="this.envio.pubBOE"  [isReadOnly]="true" ></app-form-text-edit>
  </div>
  <div class="row" style="width: 100%;">
    <div class="col-xl-6" style="padding-left: 15px; overflow-y: scroll;overflow-x: auto;height: 800px;">
      <mat-table #table [dataSource]="this.envioRows" matSort aria-label="Elements" class=" table" style="width: 100%;">
        
        <ng-container matColumnDef="expediente">
          <mat-header-cell *matHeaderCellDef>Elemento</mat-header-cell>                  
          <mat-cell class="cCell" *matCellDef="let element"> {{element.expediente}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="nombre">
          <mat-header-cell *matHeaderCellDef>Nombre</mat-header-cell>                  
          <mat-cell class="cCell" *matCellDef="let element"> {{element.nombre}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="juridica">
          <mat-header-cell *matHeaderCellDef>Jurídica</mat-header-cell>                  
          <mat-cell class="cCell" *matCellDef="let element">  
            <app-form-check-edit [disabled]="true" [(value)]="element.juridica"></app-form-check-edit> 
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="aceptacion">
          <mat-header-cell *matHeaderCellDef>Aceptacion</mat-header-cell>                  
          <mat-cell class="cCell" *matCellDef="let element">  
            <app-form-check-edit [disabled]="true" [(value)]="element.aceptacion"></app-form-check-edit> 
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="poblacion">
          <mat-header-cell *matHeaderCellDef>Población</mat-header-cell>                  
          <mat-cell class="cCell" *matCellDef="let element"> {{element.poblacion}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="cp">
          <mat-header-cell *matHeaderCellDef>CP</mat-header-cell>                  
          <mat-cell class="cCell" *matCellDef="let element"> {{element.cp}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="fechaRespuesta">
          <mat-header-cell *matHeaderCellDef>Fecha respuesta</mat-header-cell>                  
          <mat-cell class="cCell" *matCellDef="let element"> {{element.fechaRespuesta | shortDate}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="resultado">
          <mat-header-cell *matHeaderCellDef>Resultado</mat-header-cell>                  
          <mat-cell class="cCell" *matCellDef="let element"> {{element.resultado}} </mat-cell>
        </ng-container>

        
    
        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" ></mat-header-row>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"  [ngClass]="(this.currentRow !== undefined && this.currentRow.secureId === row.secureId) ? 'is-active' : ''" (click)="viewDetail(row)"></tr>
      </mat-table>
    </div>

    <div class="col-xl-6" style="padding-top: 2%;padding-right: 30px;">
      
        <div *ngIf="!(this.currentRow !== undefined && this.currentRow !== null)" >
          <div class="row">
            <div class="col-xl-12">
              <div class="alert alert-secondary " role="alert">
                <span i18n>Seleccione una linia para ver el detalle</span>
              </div>
            </div>
          </div>
          </div>
        <div *ngIf="this.currentRow !== undefined && this.currentRow !== null" >
          <div class="row">
          <app-form-text-edit class="col-xl-4" i18n-label label="Notificación" [(value)]="this.currentRow.idNotificacion"  [isReadOnly]="true" ></app-form-text-edit>
          <app-form-text-edit class="col-xl-4" i18n-label label="Envio" [(value)]="this.envio.idEnvio"  [isReadOnly]="true" ></app-form-text-edit>
          <div class="col-xl-4" >
            <app-form-text-edit  i18n-label label="Expediente" [(value)]="this.currentRow.expediente"  [isReadOnly]="true" ></app-form-text-edit>
            <app-view-list-icon (viewClick)="viewExpedient()" hideList="true"></app-view-list-icon>
          </div>
          <app-form-text-edit class="col-xl-4" i18n-label label="Tipo" [(value)]="this.envio.tipoEnvio"  [isReadOnly]="true" ></app-form-text-edit>
          <app-form-date-edit class="col-xl-4" i18n-label label="Fecha envio" [(value)]="this.envio.fechaEnvio"  [isReadOnly]="true" ></app-form-date-edit>
          <app-form-check-edit  class="col-xl-4" [disabled]="true" label="Aceptación" [(value)]="this.currentRow.aceptacion"></app-form-check-edit> 


          <app-form-date-edit i18n-label label="Fecha cobro" class="col-xl-4"   [isReadOnly]="true"  [(value)]="this.currentRow.fechaCobro"></app-form-date-edit>  
          <app-form-text-edit class="col-xl-4" i18n-label label="Importe cobrado" [(value)]="this.currentRow.importeCobro"  [isReadOnly]="true" ></app-form-text-edit>
          <app-form-date-edit i18n-label label="Fecha anulación" class="col-xl-4"   [isReadOnly]="true"  [(value)]="this.currentRow.fechaAnul"></app-form-date-edit>  
          
          <app-form-date-edit i18n-label label="Fecha descuento" class="col-xl-6"   [isReadOnly]="true"  [(value)]="this.currentRow.fechaDescuento"></app-form-date-edit> 
          <app-form-date-edit i18n-label label="Importe descuento" class="col-xl-6"   [isReadOnly]="true"  [(value)]="this.currentRow.importeDescuento"></app-form-date-edit>

          <app-form-date-edit i18n-label label="Fecha sin descuento" class="col-xl-6"   [isReadOnly]="true"  [(value)]="this.currentRow.fechaSinDescuento"></app-form-date-edit> 
          <app-form-date-edit i18n-label label="Importe sin descuento" class="col-xl-6"   [isReadOnly]="true"  [(value)]="this.currentRow.importeSinDescuento"></app-form-date-edit>
          

          <!--sicer-->
          <app-form-date-edit i18n-label label="Fecha respuesta" class="col-xl-4"   [isReadOnly]="true"  [(value)]="this.currentRow.fechaRespuesta"></app-form-date-edit>  
          <app-form-date-edit i18n-label label="Fecha resultado" class="col-xl-4"   [isReadOnly]="true"  [(value)]="this.currentRow.fechaResultado"></app-form-date-edit>  
          <app-form-text-edit class="col-xl-4" i18n-label label="Usuario resultado" [(value)]="this.currentRow.usuarioResultado"  [isReadOnly]="true" ></app-form-text-edit>
          
          <app-form-text-edit class="col-xl-12" i18n-label label="Resultado" [(value)]="this.currentRow.resultado"  [isReadOnly]="true" ></app-form-text-edit>

          <app-form-date-edit i18n-label label="Fecha anulación" class="col-xl-6"   [isReadOnly]="true"  [(value)]="this.currentRow.fechaAnul"></app-form-date-edit>
          <app-form-text-edit class="col-xl-6" i18n-label label="Usuario resultado" [(value)]="this.currentRow.motivo"  [isReadOnly]="true" ></app-form-text-edit>
          
          <app-form-text-edit class="col-xl-8" i18n-label label="Fichero resultado" [(value)]="this.currentRow.ficheroResultante"  [isReadOnly]="true" ></app-form-text-edit>
          <app-form-date-edit i18n-label label="Fecha img" class="col-xl-4"   [isReadOnly]="true"  [(value)]="this.currentRow.fechaImg"></app-form-date-edit>

          <app-form-text-edit class="col-xl-12" i18n-label label="Nombre fichero" [(value)]="this.currentRow.nombreFichero"  [isReadOnly]="true" ></app-form-text-edit>

          <app-form-text-edit class="col-xl-12" i18n-label label="Nombre img. acuse" [(value)]="this.currentRow.nombreImgAcuse"  [isReadOnly]="true" ></app-form-text-edit>

          <app-form-text-edit class="col-xl-12" i18n-label label="Usuario carga imagen" [(value)]="this.currentRow.usuarioCargaImagen"  [isReadOnly]="true" ></app-form-text-edit>

          <app-form-text-edit class="col-xl-12" i18n-label label="Usuario anulación" [(value)]="this.currentRow.usuarioAnulacion"  [isReadOnly]="true" ></app-form-text-edit>

          <app-form-text-edit class="col-xl-12" i18n-label label="Msg error" [(value)]="this.currentRow.msgError"  [isReadOnly]="true" ></app-form-text-edit>
        </div>
      </div>
    </div>
  </div>         
  
</div>