<div class="row justify-content-between">
  <div class="col">
      <app-filter-selector [filter]="this.filter"></app-filter-selector>
  </div>
  <div class="col">
      <mat-paginator #paginator [pageIndex]="0" [pageSize]="this.filter.paginator.pageSize" [pageSizeOptions]="[20, 40, 60, 100]">
      </mat-paginator>
  </div>
</div>

<div class="table-sdw is-fixed">
  
  <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements"  class="is-header-sticky">

      <!-- codi Column -->
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Codi</mat-header-cell>
        <mat-cell *matCellDef="let row"><a (click)="onRowClicked(row)" class="is-link cursor detalle">{{row.id}}</a></mat-cell>

      </ng-container>

      <ng-container matColumnDef="matricula">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Matrícula</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.matricula }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="dataFinalitzacio">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.dataFinalitzacio | longDate }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="marca">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Marca</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.marca }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="modelo">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Model</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.modelo }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="descCalle">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Carrer</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.descCalle }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="numero">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Número</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.numero }}</mat-cell>
      </ng-container>       

      <!-- Imatges Column -->
      <ng-container matColumnDef="imatges">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Imatges &nbsp;<i class="fa fa-images" text="Imatges" i18n-text></i></mat-header-cell>
          <mat-cell *matCellDef="let row">
              <div *ngIf="row.numImatges > 0">
                  <a (click)="this.viewImages(row.idDenuncia, row.somid)" class="is-link">
                      <i class="imatges-n fa fa-images"></i> {{row.numImatges}}
                  </a>
              </div>
              <div *ngIf="row.numImatges === 0">
                  <i class="imatges-0 fa fa-images"></i>
              </div>
          </mat-cell>
      </ng-container>    

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
  </mat-table>
</div>
