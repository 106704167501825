import { MaquinasFilter } from './../../models/maquina-filter';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, SimpleChanges, ViewChildren, QueryList } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatTooltip } from '@angular/material';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { Builder } from 'path-shared/models/builder';
import { Maquina } from '../../models/maquina.model';
import { MaquinasService } from '../../services/maquinas.service';
import { MaquinasFilterService } from '../../services/maquinas-filter.service';
import { Instituciones } from 'path-shared/models/instituciones';
import { ParametersService } from 'path-shared/services/parameters/parameters.service';
import { Parametros } from 'path-shared/models/parametros';
import { MaquinaIncidencia } from '../../models/maquinaIncidencia';
import { ToastrService } from 'ngx-toastr';
import { IncidenciesComponent } from '../incidencies/incidencies.component';


declare var google: any;

@Component({
  selector: 'app-maquinas-table',
  templateUrl: './maquinas-table.component.html',
  styleUrls: ['./maquinas-table.component.scss']
})
export class MaquinasTableComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChildren('toolTip') tooltips: QueryList<MatTooltip>;

  public styleMap = [
    {
      "featureType": "administrative",
      "elementType": "geometry",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "transit",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    }
  ];
  data: Maquina[];
  dataSource: CustomDataSource<Maquina, MaquinasFilter>;
  filter: MaquinasFilter = new MaquinasFilter('CODI_MAQUINA', 'asc');
  displayedItems = [];
  highlightedRows = [];
  public currentLocation: {
    latitude: number,
    longitude: number
  };
  map: any;
  zoomMapa: number;

  markersArray: Array<any>;
  @Input() selectButton: boolean;
  @Input() updatedFilterData: MaquinasFilter;
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();
  @Output() itemClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() itemSelected: EventEmitter<string> = new EventEmitter<string>();

  displayedColumns = ['mapa', 'idZBMaquinaParkeon', 'CODI_MAQUINA', 'descripcion', 'Marca', 'Model' , 'temporada', 'incidencies', 'comprovarEstat'];

  constructor(  private maquinesService: MaquinasService,
    private toastr: ToastrService,
    private filterService: FilterService,
    private dialog: MatDialog,
    private i18n: I18n,
    private parametersService: ParametersService) {
    const inst = sessionStorage.getItem('currentInst');
    if (inst !== '' && inst !== undefined && inst !== 'undefined') {
      const institution: Instituciones = JSON.parse(inst);
      this.currentLocation = {latitude: institution.Latitud, longitude: institution.Longitud};
    }
    this.zoomMapa = 15;
  }

  ngOnInit() {
    const zoom = this.parametersService.getParameter(Parametros.GPSZOMINICIAL);
    if ( zoom !== undefined && zoom !== null ) {
      this.zoomMapa = Number.parseInt(this.parametersService.getParameter(Parametros.GPSZOMINICIAL));
    }
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<Maquina, MaquinasFilter>(this.paginator, new MaquinasFilterService(), 'urlGetMaquinas', 'urlGetMaquinasCount');
    this.dataSource.subject.subscribe((data) => {
      data.forEach(item => {
        this.displayedItems[item.secureId] = {
          id: item.secureId
        };
      });
    });
    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
        if (!this.selectButton) { this.filterService.saveFilter('maquines-filter', this.filter); }
      }
    });

    if (!this.selectButton) { this.filterService.loadFilter('maquines-filter', this.filter); }
      this.filter.paginator.init(this.sort, this.paginator);
  }

  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(tap(() => this.filter.updated()))
    .subscribe();

    this.load();
  }

  OnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      this.update();
    }
  }

  update() {
    if (this.dataSource !== undefined) {
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
      // this.loadMarkersMaquinas();
    }
  }

  load() {
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);

  }

  onRowClicked(row) {
    this.itemClicked.next(row.secureId);
  }

  generateExcel(): void {
    let filter = this.filter;
    if (this.updatedFilterData != null) {
      filter = this.updatedFilterData;
    }
    this.dialog.open(DownloadExcelDialogComponent, {
        closeOnNavigation: false,
        autoFocus: true,
        data: Builder.newDownloadExcelDialogData (this.maquinesService, filter, this.i18n('Maquines'), this.sort.direction, this.sort.active)
    });
  }

  mapReady(map: any) {
    this.map = map;
    this.loadMarkersMaquinas();
  }
  loadMarkersMaquina(position) {
    if (this.markersArray !== undefined && this.markersArray !== null) {
      this.markersArray.forEach(oldMarker => {
        oldMarker.setMap(null);
      });
    }
    this.markersArray = new Array();
    const marker = new google.maps.Marker ({
      map: this.map,
      label: {
        fontFamily: "'Font Awesome 5 Free'",
        text: '\uf540',
        color: '#497eba',
        fontWeight: '900',
        fontSize: 'xx-large'
      },
      icon: ' ',
      clickable: false,
      draggable: false,
      position: new google.maps.LatLng(position.lat, position.lng),
    });
    this.markersArray.push(marker);
  }

  loadMarkersMaquinas() {
    if (this.markersArray !== undefined && this.markersArray !== null) {
      this.markersArray.forEach(oldMarker => {
        oldMarker.setMap(null);
      });
    }
    this.markersArray = new Array();
    this.dataSource.getData().forEach(x => {
      const marker = new google.maps.Marker ({
        map: this.map,
        label: {
          fontFamily: "'Font Awesome 5 Free'",
          text: '\uf540',
          color: '#497eba',
          fontWeight: '900',
          fontSize: 'xx-large'
        },
        icon: ' ',
        clickable: false,
        draggable: false,
        position: new google.maps.LatLng(x.position.lat, x.position.lng),
      });
      this.markersArray.push(marker);
    });
  }

  showIncidencies(incidencies: MaquinaIncidencia[]) {
    let html;
    html = incidencies.filter(y => y.RESOLTA == false).map(x => x.INCIDENCIA).join(String.fromCharCode(10))
    
    return html;
  }

  comprovarIncidenciesMaquina(codi: number) {
    this.maquinesService.getIncidenciesMaquina(codi).subscribe((data) => {
      this.dataSource.set('codi', codi, 'incidencies', data)
      this.toastr.success(this.i18n('Incidències actualitzades correctament'))
    });
  }

  openIncidenciesPage(codi: number) {
    this.dialog.open(IncidenciesComponent, {
      width: '90%',
      height: 'auto',
      id: 'MaquinaIncidenciesDialog',
      closeOnNavigation: false,
      autoFocus: true,
      data: { 'codiMaquina': codi.toString() }
    });

    this.dialog.getDialogById('MaquinaIncidenciesDialog').afterClosed().subscribe(res => this.load());
  }

  isIncidenciesTooltip(row: Maquina) {
    return row.incidencies.filter(x=>x.RESOLTA==false).length === 0
  }

}
