import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { MarquesVehiclesFilter } from '../models/marques-vehicles-filter';
import { HttpParams } from '@angular/common/http';

const defaultSortParam = 'id';
const defaultSortDirection = 'asc';

@Injectable()
export class MarquesVehiclesFilterService extends CustomFilterService {
  public createFilterParams(filter: MarquesVehiclesFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {

    return new HttpParams()
    .append('id', filter.id)
    .append('descripcion', filter.descripcion)
    .append('referenciaIntegracion', filter.referenciaIntegracion)
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
