import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class MatriculaHistoricFilter extends FilterData {
  tipus: string;
  matricula: string;
  marca: string;
  model: string;
  observacions: string;
  dataInici: Date;
  dataFi: Date;
  dataCreacio: Date;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.dataFi = null;
    this.dataInici = null;
    this.dataCreacio = null;
    this.marca = '';
    this.matricula = '';
    this.model = '';
    this.observacions = '';
    this.tipus = '';

    this.labels['dataFi'] = this.i18n('Data Fi');
    this.labels['dataInici'] = this.i18n('Data Inici');
    this.labels['dataCreacio'] = this.i18n('Data Creació');
    this.labels['marca'] = this.i18n('Marca');
    this.labels['matricula'] = this.i18n('Matrícula');
    this.labels['model'] = this.i18n('Model');
    this.labels['observacions'] = this.i18n('Observacions');
    this.labels['tipus'] = this.i18n('Tipus');

    this.formatOutput['dataInici'] = 'shortDate';
    this.formatOutput['dataFi'] = 'shortDate';
    this.formatOutput['dataCreacio'] = 'shortDate';

  }
}
