import { Component, OnInit, Output, EventEmitter, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Observable, Subscription} from 'rxjs';
import { TarifesFilter } from '../../models/tarifes-filter';
import { TarifesService } from '../../services/tarifes.service';

@Component({
  selector: 'app-tarifes-filter',
  templateUrl: './tarifes-filter.component.html',
  styleUrls: ['./tarifes-filter.component.scss']
})
export class TarifesFilterComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  filter: TarifesFilter;
  zones: null;
  residents: null;
  tipusTiquets: null;
  @Output() notify: EventEmitter<TarifesFilter> = new EventEmitter<TarifesFilter>();

  constructor(
    public thisDialogRef: MatDialogRef<TarifesFilterComponent>,
    private i18n: I18n,
    private tarifesService: TarifesService,
    @Inject(MAT_DIALOG_DATA) public data) {
    this.filter = data.filter;
  }

  ngOnInit() {
    this.tarifesService.GetData().subscribe(res => {
      this.zones = res.zones;
      this.residents = res.residents;
      this.tipusTiquets = res.tipusTiquets;

    });
    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.apply();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated();
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }

}
