<app-dialog-header label="Respostes Notificacions" i18n-label></app-dialog-header>

<app-dialog-body>
  
  
  <div class="table-sdw is-fixed">
    <mat-table #table [dataSource]="dataSource" aria-label="Elements" class="is-header-sticky">
        <ng-container matColumnDef="dataResultat">
          <mat-header-cell *matHeaderCellDef i18n>Data resultat</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.dataResultat |shortDate}}</mat-cell>
          </ng-container>
  
        <ng-container matColumnDef="tipus">
          <mat-header-cell *matHeaderCellDef i18n>Tipus</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.tipus}}</mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="resultat">
          <mat-header-cell *matHeaderCellDef i18n>Resultat</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.resultat}}</mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="descResultat">
          <mat-header-cell *matHeaderCellDef i18n>Descripció resultat</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.descResultat}}</mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="acceptada">
          <mat-header-cell *matHeaderCellDef i18n>Acceptada</mat-header-cell>
          <mat-cell *matCellDef="let row"><mat-checkbox [checked]="row.acceptada" [disabled]='true'></mat-checkbox></mat-cell>
        </ng-container>

        <ng-container matColumnDef="refExt">
          <mat-header-cell *matHeaderCellDef i18n>Ref. Externa</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.refExt}}</mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="observacions">
          <mat-header-cell *matHeaderCellDef i18n>Observacions</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.observacions}}</mat-cell>
        </ng-container>
        
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset"></mat-row>
      </mat-table>
  </div>
</app-dialog-body>