<app-toolbar> 
  <app-tool-button text="Filtre" i18n-text (click)="onFilterClicked();" color="has-color-orange" icon="fa-filter"></app-tool-button>
  <app-tool-button text="Excel" i18n-text (click)="generateExcel();" color="has-color-darkgreen" icon="fa-file-excel"></app-tool-button>
  <app-tool-button-permissions [permission]="this.auth.Pages.Avisos"></app-tool-button-permissions>
</app-toolbar>


<br/>
<div class="row">
        <div *ngIf="this.mapaActiu" class="col-sm-4 map-icon" style="min-height: 50vh; max-height: 50vh">
          
          
          <agm-map *ngIf="this.mapaActiu" [latitude]="currentLocation.latitude" [longitude]="currentLocation.longitude"  (mapReady)="onMapReady($event)" [zoom]="zoom">
            <ng-container *ngFor="let avis of avisos">
                <ng-container *ngIf=" avis.Selected">
                  <agm-marker  [latitude]="avis.latitud" [longitude]="avis.longitud"  [iconUrl]="avis.ColorHexa">
                  </agm-marker>
                </ng-container>
              </ng-container>
          </agm-map>
        </div>
        
        <div class="col-sm-4">
          <div class="row" style="margin:10px; max-height: 20vh; overflow: auto;">
            <mat-table [dataSource]="dataSource2" aria-label="Elements" class="is-header-sticky">
             
                    <ng-container matColumnDef="nomFitxer">
                      <mat-header-cell *matHeaderCellDef  i18n>Fitxers</mat-header-cell>
                      
                      <mat-cell *matCellDef="let row" ><a class="is-link">{{row.m_Item2}}</a></mat-cell>
                    </ng-container>
          
                    <mat-header-row *matHeaderRowDef="displayedColumns2"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns2;" (click)="getFitxer(row)" class="has-iconset"></mat-row>
                    <!--[ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"-->
            </mat-table>
          </div>
        </div>

      <div class="col-lg-8" style="padding-right: 10px; padding-left: 30px;">
        <div class="row justify-content-between">
          <div class="col">
              <app-filter-selector [filter]="this.filter"></app-filter-selector>
          </div>
          <div class="col">
              <mat-paginator [hidden]="false" #paginator [pageIndex]="0" [pageSize]="this.filter.paginator.pageSize" [pageSizeOptions]="[10, 20, 40, 60]">
              </mat-paginator>
          </div>
        </div>
        
          <div class="row " style="max-height: 70vh; overflow: auto;">
                
            <mat-table #table [dataSource]="dataSource" matSort matSortDisableClear aria-label="Elements" [matSortActive]="this.filter.paginator.sortField" [matSortDirection]="this.filter.paginator.sortDir" class="is-header-sticky">
                  <ng-container matColumnDef="incorpora" *ngIf="this.selectButton"> 
                    <mat-header-cell fxFlex="10%" *matHeaderCellDef title="Incorporar" i18n-title class="text-center"><mat-checkbox #checkbox [(ngModel)]="this.allSelected" (change)="SeleccionarTotes()" ></mat-checkbox></mat-header-cell>
                    <mat-cell fxFlex="10%" *matCellDef="let row"  ><mat-checkbox #checkbox [(ngModel)]="row.Selected" (click)="ClickCheckbox(row)"></mat-checkbox></mat-cell>
                  </ng-container>
            
                  <ng-container matColumnDef="carpeta" >
                    <mat-header-cell *matHeaderCellDef i18n>Carpeta</mat-header-cell>
                    <mat-cell *matCellDef="let row">{{row.carpeta}}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="descTipus">
                    <mat-header-cell *matHeaderCellDef i18n>Desc. Tipus</mat-header-cell>
                    <mat-cell *matCellDef="let row">{{row.descTipus}}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="data">
                    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data</mat-header-cell>
                    <mat-cell *matCellDef="let row">{{row.data}} </mat-cell>
                  </ng-container> 
                  <ng-container matColumnDef="hora">
                    <mat-header-cell *matHeaderCellDef i18n>Hora</mat-header-cell>
                    <mat-cell *matCellDef="let row">{{row.hora}}</mat-cell>
                  </ng-container> 
                  <ng-container matColumnDef="observacio">
                    <mat-header-cell  *matHeaderCellDef i18n > Observacions </mat-header-cell>
                    <mat-cell *matCellDef="let row" >{{row.observacio}}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="observacio2">
                    <mat-header-cell  *matHeaderCellDef i18n > Observacions (2) </mat-header-cell>
                    <mat-cell *matCellDef="let row" >{{row.observacio2}}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="agent">
                    <mat-header-cell  *matHeaderCellDef mat-sort-header i18n > Agent </mat-header-cell>
                    <mat-cell *matCellDef="let row" >{{row.agent}}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="PDA">
                    <mat-header-cell  *matHeaderCellDef mat-sort-header i18n > PDA </mat-header-cell>
                    <mat-cell *matCellDef="let row" >{{row.PDA}}</mat-cell>
                  </ng-container>
        
                  <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="SeleccionarFilaAux(row)" [ngClass]="{'is-active': row.Selected }"></mat-row>
            </mat-table>
          </div>
                   
      </div>
      
</div>


