<form #mainForm>
  <div class ="border border-secondary fondo" style="padding:10px;">
    <div class="row">
      <app-form-text-edit [(value)]="this.dniInfractor" id="infor-infractor-dni" class="col-9" i18n-label label="DNI/PASP"   (change)="this.reset()" required></app-form-text-edit>
      <app-view-list-icon hideView="true" style="right: 15px;" class="col-3" (listClick)="this.consultarConductor()" ></app-view-list-icon>
    </div>
    
    <div class="row">
      <app-form-text-edit id="infor-infractor-nom" class="col-xl-12" i18n-label label="Nom" [(value)]="this.dto.nom" disabled required></app-form-text-edit>
    </div>
    
    <div class="row">
      <app-combo-box-input #comboDomPropietari [(value)]="this.sidDomiciliInfractor" style="width: 80%; padding-left: 2%;" i18n-label label="Domicili" [showingOptions]="this.domicilisInfractorDictionary.value"
          (optionChanged)="this.domicilioConductorSeleccionado($event)" required>
      </app-combo-box-input>
    </div>
  </div>
</form>
