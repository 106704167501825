export class FormMultiSelect {
  item_id: number;
  item_text: string;

  constructor(item_id: number, item_text: string) {
    this.item_id = item_id;
    this.item_text = item_text;
  }
}

export class FormMultiSelectExtra {
  item_id: number;
  item_text: string;
  item_text_2: string;

  constructor(item_id: number, item_text: string, item_text_2: string) {
    this.item_id = item_id;
    this.item_text = item_text;
    this.item_text_2 = item_text_2;
  }
}
