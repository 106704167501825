<app-toolbar>  
  <app-tool-button text="Actualizar" i18n-text (click)="consultar();" color="has-color-blue" icon="fa-sync"></app-tool-button>
  <app-tool-button-permissions [permission]="this.auth.Pages.IMPORTAR_MATRICULES"></app-tool-button-permissions>
  <app-tool-button text="Filtre" i18n-text (click)="onFilterClicked();" color="has-color-orange" icon="fa-filter"></app-tool-button>
</app-toolbar>

<div class="row justify-content-between">
  <app-form-text-edit class="col-xl-2" i18n-label label="Expedient || Matricula"
    [(value)]="this.expedienteFilter"></app-form-text-edit>

  <div class="col">
    <app-filter-selector [filter]="this.filter"></app-filter-selector>
  </div>
  
  <div class="col">
    <mat-paginator #paginator [pageIndex]="0" [pageSize]="this.filter.paginator.pageSize" [pageSizeOptions]="[20, 40, 60, 100]">
    </mat-paginator>
  </div>
</div>
  
<div class="row">
  <div class="col-xl-9">
    <div class="table-sdw is-fixed">
      <mat-table matSort #table [dataSource]="this.dataSource" matSortActive="{{this.sort.active}}" matSortDirection="{{this.sort.direction}}" aria-label="Elements" class="is-header-sticky">

          <!-- Check Column -->
          <ng-container matColumnDef="Check">
            <div >
              <mat-header-cell *matHeaderCellDef class="is-checkbox">
                <mat-checkbox (change)="toggleAllCheckbox()"></mat-checkbox>
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <mat-checkbox [(ngModel)]="displayedItems[row.exp].isChecked"></mat-checkbox>
              </mat-cell>
            </div>
          </ng-container>

          <!-- exp Column -->
          <ng-container matColumnDef="exp">
              <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Expediente</mat-header-cell>
              <mat-cell *matCellDef="let row"><a (click)="onRowClicked(row)" class="is-link cursor detalle">{{row.exp}}</a></mat-cell>
          </ng-container>
      
          <!-- matricula Column -->
          <ng-container matColumnDef="matricula">
              <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Matricula</mat-header-cell>
              <mat-cell *matCellDef="let row">{{row.matricula }}</mat-cell>
          </ng-container>

          <!-- DGT_ERROR_MATRICULA_DATA Column -->
          <ng-container matColumnDef="DGT_ERROR_MATRICULA_DATA">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Última data de càrrega</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.DGT_ERROR_MATRICULA_DATA | shortDate }}</mat-cell>
        </ng-container>
      
          <!-- infTras Column -->
          <ng-container matColumnDef="infTras">
              <mat-header-cell *matHeaderCellDef i18n>Información traspaso</mat-header-cell>
              <mat-cell *matCellDef="let row">{{row.infoTraspaso}}</mat-cell>
          </ng-container>

          <!-- DGT_ERROR_MATRICULA_TEXT Column -->
          <ng-container matColumnDef="DGT_ERROR_MATRICULA_TEXT">
            <mat-header-cell *matHeaderCellDef i18n>Error</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.DGT_ERROR_MATRICULA_TEXT}}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" ></mat-row>
      </mat-table>
    </div>
  </div>
  <div class="col-xl-3 center-block"> 

    <app-empty-row></app-empty-row>

    <div class="row card"> 
      <div class="card-header"  style="padding-left: 0px; padding-right: 0px;" i18n> &nbsp; DGT</div>
      <span>
        <div class="text-center padding-bottom-box padding-top-box">
          <button class="btn btn-success button-not-allowed btn-lg col-10" type="button" (click)="this.cargarDgt()">
            <img class="navbar-brand-full" src="assets/images/dgt6.png"> &nbsp;<span i18n>Cargar matriculas seleccionadas</span>  &nbsp;
          </button>
        </div>  
      </span>
      <span>
        <div class="text-center padding-bottom-box padding-top-box">
          <button class="btn btn-success button-not-allowed btn-lg col-10" type="button" (click)="this.cargarDgtTotes()">
            <img class="navbar-brand-full" src="assets/images/dgt6.png"> &nbsp;<span i18n>Cargar total de matrículas</span>  &nbsp;
          </button>
        </div>  
      </span>
    </div>  
    <app-empty-row></app-empty-row>

    <div class="row card"> 
      <div class="card-header"  style="padding-left: 0px; padding-right: 0px;" i18n>&nbsp; Gestión tributaria</div>
      <span >
        <div  class="text-center padding-bottom-box padding-top-box">
          <button class="btn btn-success button-not-allowed btn-lg col-10" type="button" (click)="this.cargarGestTributaria()">
            <i class="fas fa-search-dollar"></i> &nbsp;<span i18n>Cargar matriculas</span>  &nbsp;
          </button>
        </div>  
      </span>
    </div>

    <app-empty-row></app-empty-row>
    
    <div class="row card"> 
      <div class="card-header"  style="padding-left: 0px; padding-right: 0px;" i18n>&nbsp; Xaloc</div>
      <span >
        <div  class="text-center padding-bottom-box padding-top-box">
        <button class="btn btn-success button-not-allowed btn-lg col-10 "  type="button" (click)="this.cargarXaloc()">
          <i class="fas fa-upload"></i> &nbsp;<span i18n>Cargar matriculas</span>  &nbsp;
        </button>
        
        <app-empty-row></app-empty-row>

        <button class="btn btn-success button-not-allowed btn-lg col-10" type="button" (click)="this.exportXaloc()">
          <i class="fas fa-download"></i> &nbsp;<span i18n>Exportar matriculas</span>  &nbsp;
        </button>
      </div>
      
          
      </span>
    </div>
   
</div>
  
</div>
