import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { FilesService } from 'path-shared/services/files/files.service';
import { AlegacionsService } from '../../services/alegacions.service';
import { Alegacio } from '../../models/alegaciones.model';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { TextosPredefinidosComponent } from 'app/sections/configuracio/textos-predefinidos/pages/textos-predefinidos/textos-predefinidos.component';
import { CFG_TEXTOS_PREDEFINIDOS } from 'path-models/CFG_TEXTOS_PREDEFINIDOS';
import { OperacionAlegacionDto } from 'app/sections/denuncies/models/operacionAlegacionDto';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-respondre-alegacions',
  templateUrl: './respondre-alegacions.component.html',
  styleUrls: ['./respondre-alegacions.component.scss']
})
export class RespondreAlegacionsComponent implements OnInit {

  dto = new OperacionAlegacionDto();

  alegacio: Alegacio;
  situacio: string = '';

  typeLabels = ['Estimada', 'Desestimada'];
  typeValues = [true, false];

  constructor(
    public i18n: I18n,
    public thisDialogRef: MatDialogRef<RespondreAlegacionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Alegacio,
    private filesService: FilesService,
    private toastr: ToastrService,
    private alegacionsService: AlegacionsService,
    private dialog: MatDialog,
  ) { 
    this.alegacio = data;
  }

  ngOnInit() {
  }

  public decline() {
    this.thisDialogRef.close(false);
  }

  public accept() {
    this.thisDialogRef.close(true);
  }

  public dismiss() {
    this.thisDialogRef.close(false);
  }


  getFilePath() {
    this.filesService.getFileSomId(this.alegacio.FICHERO_SOM_ID).subscribe(
      result=>{ 
        const objectUrl=URL.createObjectURL(result);
        window.open(objectUrl);},
      error => this.toastr.error(error),
      ()=> console.log('completed'));
  }

  guardar(resultat: boolean) {
    if (resultat != null && this.situacio != null) {
      this.alegacionsService.respondreAlegacions(
          this.alegacio.EXP, 
          resultat,
          this.situacio,
          this.alegacio.TIPO
        ).subscribe(
          {
            next : () => this.accept()
          }
      );
    }
  }

  buscarAlegacions(){
     // Carraguem la taula filtrada per dni
     const data: ComponentDialog = Builder.newComponentDialog(true, false, '', null);
     const ref = this.dialog.open(TextosPredefinidosComponent, {
       width: '80%',
       height: '90%',
       closeOnNavigation: false,
       autoFocus: true,
       data: data
     });

     ref.afterClosed().subscribe(async resp => {
      var texto: CFG_TEXTOS_PREDEFINIDOS = resp;
      if(this.situacio !== undefined && this.situacio !== null && this.situacio !== ''){
        this.situacio =  this.situacio +' '+texto.DESCRIPCION;
      }else{
        this.situacio = texto.DESCRIPCION;
      }
      
     });
  }

}
