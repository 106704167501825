import { Component, OnInit, Inject, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { getMatIconFailedToSanitizeUrlError, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NullAstVisitor } from '@angular/compiler';
import { ToastrService } from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ShortDatePipe } from 'path-shared/pipes/short-date-pipe';
import { PersonesService } from 'app/sections/ciutat/persones/services/persones.service';
import { PersonaFilter } from 'app/sections/ciutat/persones/models/persona-filter';
import { Persona } from 'app/sections/ciutat/persones/models/persona.model';
import { Builder } from 'path-shared/models/builder';
import { PersonesComponent } from 'app/sections/ciutat/persones/pages/persones/persones.component';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { PersonesEditorComponent } from 'app/sections/ciutat/persones/components/persones-editor/persones-editor.component';
import { Dictionary } from 'path-shared/models/dictionary';
import { BehaviorSubject } from 'rxjs-compat';
import { Reflection } from 'path-shared/services/reflection/reflection';
import { ComboBoxInputComponent } from 'path-shared/components/form/combo-box-input/combo-box-input.component';
import { FormService } from 'path-shared/services/form/form.service';
import { OperacionInforInfractorDto } from 'app/sections/denuncies/models/operacionInforInfractorDto.model';


@Component({
  selector: 'app-carta-pago-fechas',
  templateUrl: './carta-pago-fechas.component.html',
  styleUrls: ['./carta-pago-fechas.component.scss']
})
export class CartaPagoFechasComponent implements OnInit {

  fecha: Date;

  constructor(
    private dialogRef: MatDialogRef<CartaPagoFechasComponent>,
  ) {
  }

  ngOnInit() {

  }

  Continuar() {
    this.dialogRef.close(this.fecha);
  }

}
