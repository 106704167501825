import { Injectable } from '@angular/core';
import { ZoneFilter } from '../models/zones-filtar';
import { HttpParams } from '@angular/common/http';
import { Reflection } from 'path-shared/services/reflection/reflection';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';


const defaultSortParam = 'descripcio';
const defaultSortDirection = 'desc';


@Injectable({
  providedIn: 'root'
})
export class ZonesFilterService extends CustomFilterService {

  public createFilterParams(filter: ZoneFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {
  return new HttpParams()
    .append('idZona', filter.idZona ? filter.idZona : '')
    .append('descripcio', filter.descripcio ? filter.descripcio : '')
    .append('fechaInici', filter.fechaInici === null ? null : filter.fechaInici.toString())
    .append('fechaFin', filter.fechaFin === null ? null : filter.fechaFin.toString())
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }

}
