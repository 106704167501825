
import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { BancIncidenciesRequestDto } from '../models/banc-inicidencies-requestDto';
import { BancCarreguesIncidenciesFilterService } from './banc-carregues-incidencies-filter.service';

const defaultSortParam = 'id';
const defaultSortDirection = 'desc';

@Injectable()
export class BancCarreguesIncidenciesService {
  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {}

  loadData(filter: any, sortDir: string, sortField: string): Observable<any> {
    if (this.authGuard.canActivate()) {
      const start = 0;
      const pageSize = 1000;

      sortField = sortField === undefined ? defaultSortParam : sortField;
      sortDir = sortDir === '' ? defaultSortDirection : sortDir;
      const params = new BancCarreguesIncidenciesFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetIncidenciasBancCarregaC60'], params);
    }
  }

  // generateExcel(filter: BancCarreguesC60Filter, sortDir: string, sortField: string): Observable<any> {
  //   const start = 0;
  //   const pageSize = 1000;

  //   sortField = sortField === undefined ? defaultSortParam : sortField;
  //   sortDir = sortDir === '' ? defaultSortDirection : sortDir;
  //   const params = new BancCarreguesC60FilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

  //   if (this.authGuard.canActivate()) {
  //     return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlBancCarreguesC60Excel'],  params );
  //   }
  // }


}
