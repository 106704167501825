import { TramoTipo } from './../../models/tramo-tipo.model';
import { Component, OnInit, ViewChild, ElementRef, Inject, ViewContainerRef } from '@angular/core';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { FormService } from 'path-shared/services/form/form.service';
import { Builder } from 'path-shared/models/builder';
import { TramosTiposService } from '../../services/tramos-tipos.service';
import { ColorPickerService, Cmyk } from 'ngx-color-picker';

//import { ColorEvent } from 'ngx-color';

@Component({
  selector: 'app-tramos-tipos-editor',
  templateUrl: './tramos-tipos-editor.component.html',
  styleUrls: ['./tramos-tipos-editor.component.scss']
})
export class TramosTiposEditorComponent implements OnInit {

  secure_id: string;
  tramoTipo: TramoTipo;
  dialogData: ComponentDialog;
  closeButtonText: string;
 
  public color1: string = '#2889e9';


  @ViewChild('mainForm') private mainForm: ElementRef;
  constructor(
    private dialog: MatDialog,
    private confirmationDialogService: ConfirmationDialogService,
    public auth: AuthorizationService,
    private tramosTiposService: TramosTiposService,
    private i18n: I18n,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    public thisDialogRef: MatDialogRef<TramosTiposEditorComponent>,
    private formService: FormService,
    public vcRef: ViewContainerRef,
    private cpService: ColorPickerService,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
  ) { 
    this.tramoTipo = Builder.newTramoTipo();
    this.dialogData = data;
    this.secure_id = this.dialogData.id;
    if (this.dialogData.isReadOnly) {
      this.closeButtonText = this.i18n('Tancar');
    } else {
      this.closeButtonText = this.i18n('Llista');
    }
  }


  public onChangeColor(color: string): Cmyk {
    const hsva = this.cpService.stringToHsva(color);
    this.tramoTipo.color = color;
    const rgba = this.cpService.hsvaToRgba(hsva);

    return this.cpService.rgbaToCmyk(rgba);
  }
  ngOnInit() {
    if (this.secure_id !== null) {
      this.tramosTiposService.getTramoTipo(this.secure_id).subscribe(data => {
        this.tramoTipo = data;
        if ( this.tramoTipo !== undefined && this.tramoTipo !== null ) {
          if ( this.tramoTipo.color !== undefined && this.tramoTipo.color !== null && this.tramoTipo.color !== ''){
            this.color1 = this.tramoTipo.color;
          }
          if ( this.tramoTipo.id_logica !== undefined && this.tramoTipo.id_logica !== null ){
            //SI TIENE LOGICA ESPECIAL NO SE PUEDE EDITAR
            this.dialogData.isReadOnly = true;
          }
        }
      });
    }
  }

  save() {
    if (this.formService.allFieldsValid(this.mainForm)) {

      this.tramosTiposService.setTramoTipo(this.tramoTipo).subscribe(result => {
        this.toastr.info(this.i18n('Tipus de tram guardat correctament'));
        this.thisDialogRef.close(true);
      });
    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
    }
  }

  cancel() {
    this.thisDialogRef.close(false);
  }

  remove() {
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol esborrar el tipus?'))
    .then((confirmed) => {
        if (confirmed) {
          this.tramosTiposService.removeTramoTipo(this.secure_id).subscribe(result => {
            this.toastr.success(this.i18n('Tipus de tram esborrat correctament'));
            this.dialog.closeAll();
            this.thisDialogRef.close(true);
          });
        }
      });
  }

}
