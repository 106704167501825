import { ToastrModule, ToastrService } from 'ngx-toastr';
import { EscalaOcupacion } from './../../model/escala-ocupacion';
import { BehaviorSubject } from 'rxjs';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource } from '@angular/material';
import { EscalaOcupacionTableComponent } from './../../components/escala-ocupacion-table/escala-ocupacion-table.component';
import { EscalaOcupacionEditorComponent } from './../../components/escala-ocupacion-editor/escala-ocupacion-editor.component';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Component, OnInit, AfterViewInit, Inject, ViewChild } from '@angular/core';
import { PermissionDialogComponent } from 'path-shared/components/permission-dialog/permission-dialog.component';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { Builder } from 'path-shared/models/builder';
import { EscalaOcupacionFilter } from '../../model/escala-ocupacion.filter';
import { EscalaOcupacionService } from '../../services/escala-ocupacion.service';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-escala-ocupacion-page',
  templateUrl: './escala-ocupacion-page.component.html',
  styleUrls: ['./escala-ocupacion-page.component.scss']
})
export class EscalaOcupacionPageComponent implements OnInit, AfterViewInit {

  permissionDIalogRef: MatDialogRef<PermissionDialogComponent>;
  activeFilters: string[] = [];
  filterData: EscalaOcupacionFilter;
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  currentEscala: EscalaOcupacion;
  editing: boolean = false;
  creating: boolean = false;

  coloresBase: BehaviorSubject<Array<string>>;

  @ViewChild('escalaOcupacionEditor') editorComponent: EscalaOcupacionEditorComponent;
  @ViewChild('escalaOcupacionTable') tableComponent: EscalaOcupacionTableComponent;

  constructor(
    private toastr: ToastrService,
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private escalaOcupacionService: EscalaOcupacionService,
    private i18n: I18n,
    private not: NotificationService,
    public thisDialogRef: MatDialogRef<EscalaOcupacionPageComponent>,
    private confirmationDialogService: ConfirmationDialogService,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog
  ) {
    this.filterData = new EscalaOcupacionFilter('rangoSuperior', 'desc');
    if (data !== undefined && data !== null) {
      this.dataDialog = data;
    }

    this.coloresBase = new BehaviorSubject([]);
  }

  /* #region  COMPONENT LIFECYCLE */

  ngOnInit(): void {
    this.auth.UserCanSeePage(this.auth.Pages.EscalaOcupacion);

    // Descargamos los colores de escala ocupacion.
    this.escalaOcupacionService.getColoresEscala().subscribe((result: Array<string>) => {
      // Recibimos la lista de strings que representan los codigos hexa de cada color.
      if (result.length > 0) {
        this.coloresBase.next(result);
      }
    });
  }

  ngAfterViewInit(): void {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Escala Ocupació'));
    }
  }

  /* #endregion */

  /* #region  EVENTS */

  onRowSelected(row: EscalaOcupacion): void {
    if (row !== undefined) {
      this.currentEscala = Object.assign({}, row);
    } else {
      this.currentEscala = undefined;
    }
  }

  edit(): void {
    this.editing = true;
  }

  create(): void {
    this.creating = true;
    this.currentEscala = Builder.newEscalaOcupacion();
    // Debemos indicar a la tabla que queremos deseleccionar los elementos seleccionados.
    this.tableComponent.resetSelection();
  }

  refresh(): void {
    this.confirmationDialogService.confirm(this.i18n('Acceptar'), this.i18n('Segur que vols reiniciar a la configuració anterior?'))
    .then((confirmed) => {
      if (confirmed) {
        this.clear();
      }
    });
  }

  cancel(): void {
    this.creating = false;
    this.editing = false;
    this.currentEscala = undefined;
  }

  clear(): void {
    this.tableComponent.load();
    this.tableComponent.resetSelection();
    this.creating = false;
    this.editing = false;
    this.currentEscala = undefined;
    this.editorComponent.isError = false;
  }

  softClear(): void {
    // Limpia el editor, asi como las selecciones actuales pero no descarga de nuevo los rangos.
    this.tableComponent.resetSelection();
    this.creating = false;
    this.editing = false;
    this.currentEscala = undefined;
    this.editorComponent.isError = false;
  }

  saveRange(): void {
    if (this.currentEscala.rangoSuperior !== undefined && this.currentEscala.rangoSuperior !== null &&
        this.currentEscala.color !== undefined && this.currentEscala.color !== null && this.currentEscala.color !== '') {
      // Podemos guardar el elemento, ya que tiene los valores necesarios.
      const rangosActuales = this.tableComponent.dataSource.getData();
      if (rangosActuales !== undefined) {
        if (rangosActuales.find(r => r.id === this.currentEscala.id) !== undefined) {
          // Es un rango existente, lo actualizamos.
          const updatePosition = rangosActuales.findIndex(x => x.id === this.currentEscala.id);
          rangosActuales[updatePosition] = this.currentEscala;
          // Actualizamos los rangos que son anexos.
          if (rangosActuales.length > updatePosition + 1) {
            // Podemos actualizar el rango siguente.
            this.currentEscala.rangoInferior = Number(rangosActuales[updatePosition + 1].rangoSuperior) + 1;
          } else {
            this.currentEscala.rangoInferior = 0;
          }
          if (updatePosition > 0) {
            // Obtenemos el rango inferior a partir del superior anterior.
            rangosActuales[updatePosition - 1].rangoInferior = Number(this.currentEscala.rangoSuperior) + 1;
          }
        } else {
          // Es un nuevo rango. Buscamos la posicion para insertar de forma ordenada.
          let found = false;
          let i = 0;
          while (i < rangosActuales.length && !found) {
            if (rangosActuales[i].rangoSuperior < this.currentEscala.rangoSuperior) {
              found = true;
            } else {
              i++;
            }
          }
          this.currentEscala.id = this.generateTempId();
          rangosActuales.splice(i, 0, this.currentEscala);
          // Actualizamos los rangos que son anexos.
          if (rangosActuales.length > i + 1) {
            // Podemos actualizar el rango siguente.
            this.currentEscala.rangoInferior = Number(rangosActuales[i + 1].rangoSuperior) + 1;
          } else {
            this.currentEscala.rangoInferior = 0;
          }
          if (i > 0) {
            // Obtenemos el rango inferior a partir del superior anterior.
            rangosActuales[i - 1].rangoInferior = Number(this.currentEscala.rangoSuperior) + 1;
          }
        }
        this.tableComponent.dataSource.updateData(rangosActuales);
        this.softClear();
      }
    } else {
      // No podemos guardar el elemento, falta algun campo por llenar.
      this.editorComponent.showFillFieldsMessage();
    }
  }

  generateTempId(): number {
    let randValue: number;
    let found = true;
    while (randValue === undefined || found) {
      randValue = Math.floor(Math.random() * 1000) + 1;
      found = this.tableComponent.dataSource.getData().findIndex(d => d.id === randValue) !== -1;
    }
    return randValue;
  }

  saveAll(): void {
    this.escalaOcupacionService.setEscalaOcupacionConfiguracion(this.tableComponent.dataSource.getData()).subscribe(result => {
      // Hemos guardado la configuracion. Actualizamos toda la pantalla.
      this.clear();
    });
  }

  remove(): void {
    const rangosActuales = this.tableComponent.dataSource.getData();
    if (rangosActuales !== undefined) {
      const rangoEliminarPosicion = rangosActuales.findIndex(r => r.rangoSuperior === this.currentEscala.rangoSuperior);
      if (rangoEliminarPosicion !== -1) {
        rangosActuales.splice(rangoEliminarPosicion, 1);
        if (rangosActuales.length > rangoEliminarPosicion) {
          rangosActuales[rangoEliminarPosicion - 1].rangoInferior = Number(rangosActuales[rangoEliminarPosicion].rangoSuperior) + 1;
        } else if (rangosActuales.length === rangoEliminarPosicion) {
          rangosActuales[rangoEliminarPosicion - 1].rangoInferior = 0;
        }
      }
      this.tableComponent.dataSource.updateData(rangosActuales);
    }
    this.softClear();
  }

  /* #endregion */
}
