import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';
import { NumberSymbol } from '@angular/common';

export class LegislacioFilter extends FilterData {
  codi: string;
  descripcio: string;
  obsoleta: boolean;
  trafic: boolean;
  mesesSLInici: number;
  mesesSLFi: number;
  mesesSGInici: number;
  mesesSGFi: number;
  mesesSMGInici: number;
  mesesSMGFi: number;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.codi = '';
    this.descripcio = '';
    this.obsoleta = null;
    this.trafic = null;
    this.mesesSLInici = null;
    this.mesesSLFi = null;
    this.mesesSGInici = null;
    this.mesesSGFi = null;
    this.mesesSMGInici = null;
    this.mesesSMGFi = null;

    this.labels['codi'] = this.i18n('Codi');
    this.labels['descripcio'] = this.i18n('Descripció');
    this.labels['obsoleta'] = this.i18n('Obsoleta');
    this.labels['trafic'] = this.i18n('Trànsit');
    this.labels['mesesSLInici'] = this.i18n('Mesos SL inici');
    this.labels['mesesSLFi'] = this.i18n('Mesos SL fi');
    this.labels['mesesSGInici'] = this.i18n('Mesos SG inici');
    this.labels['mesesSGFi'] = this.i18n('Mesos SG fi');
    this.labels['mesesSMGInici'] = this.i18n('Mesos SMG inici');
    this.labels['mesesSMGFi'] = this.i18n('Mesos SMG fi');
  }
}
