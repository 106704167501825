import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Builder } from 'path-shared/models/builder';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { InfoPendents } from '../../models/info-pendents.model';
import { TramitarService } from '../../services/tramitar.service';
import { TablependentsComponent } from 'app/sections/tramitar/expedients/components/pendents/tablependents/tablependents.component';
import { ToastrService } from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';

@Component({
  selector: 'app-pendentsexpedients',
  templateUrl: './pendents.component.html',
  styleUrls: ['./pendents.component.scss']
})
export class PendentsExpedientsComponent implements OnInit {

  infoPendents: InfoPendents = new InfoPendents();
  constructor(private dialog: MatDialog,
    private tramitarService: TramitarService,
    private i18n: I18n,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.loadInici();
  }

  loadInici() {
    this.tramitarService.getInfoPendents().subscribe(x => {
      if (x !== undefined && x !== null) {
        this.infoPendents = x;
      }
    });
  }

  OpenPopUp() {
    if (this.infoPendents.Pendents > 0) {
    const data: ComponentDialog = Builder.newComponentDialog(true, true);
      const ref = this.dialog.open(TablependentsComponent, {
        width: '70%',
        height: '50%',
        maxHeight: '50vh',
        closeOnNavigation: false,
        autoFocus: true,
        data: data
      });
    } else {
      this.toastr.info(this.i18n('No se han encontrado expedientes pendientes de respuesta'));      
    }
  }
}
