<div class="tags" [hidden]="!this.filter.active()">
  <button
    *ngFor="let tag of items"
    class="btn btn-xs btn-outline-warning"
    type="button"
    (click)="this.remove(tag.property)"
    [hidden]="!this.valid(tag)"
  >
    {{ this.getLabel(tag.property) }}: {{ this.getValue(tag.property) }} <i class="fa fa-times"></i>
  </button>
  <button
  class="btn btn-xs btn-outline-warning"
  type="button"
  (click)="this.filter.clear()"
  [hidden]="!this.filter.active()"
  ><i class="fa fa-broom"></i></button>
</div>
