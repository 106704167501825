import { GruaPendentsService } from './../../services/grua-pendents.service';
import { AliesGruaService } from './../../services/alies-grua.service';
import { PropostaGruaDto } from './../../Models/proposta-grua.model';
import { Component, OnInit, Inject, ViewChild, Input, AfterViewInit, EventEmitter, Output, ComponentRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { Instituciones } from 'path-shared/models/instituciones';
import { ParametersService } from 'path-shared/services/parameters/parameters.service';
import { Parametros } from 'path-shared/models/parametros';
import { InfraccioService } from 'app/sections/configuracio/Infraccions/services/infraccions-service.service';
import { Dictionary } from 'path-shared/models/dictionary';
import { ComboBoxInputAutocompleteComponent } from 'path-shared/components/form/combo-box-input-autocomplete/combo-box-input-autocomplete.component';
import { PropostesService } from 'path-propostes-services/propostes.service';
import { PhotoBoxGalleryComponent } from 'path-shared/components/photo-box/components/photo-box-gallery/photo-box-gallery.component';
import { AgentsService } from 'app/sections/configuracio/agents/services/agents-service.service';
import { ToastrService } from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { CFG_PARAMETROS } from 'path-models/CFG_PARAMETROS';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { GoogleMapsService } from 'path-shared/services/startup/google-maps.service';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { VehiclesComponent } from 'app/sections/ciutat/vehicles/pages/vehicles/vehicles.component';
import { VehiclesService } from 'app/sections/ciutat/vehicles/services/vehicles.service';
import { DenunciesEditorComponent } from 'app/sections/denuncies/components/denuncies-editor/denuncies-editor.component';
import { BehaviorSubject } from 'rxjs';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-pendents',
  templateUrl: './pendents.component.html',
  styleUrls: ['./pendents.component.scss']
})
export class PendentsComponent implements OnInit, AfterViewInit {
  public allowEdit: boolean;
  public isEditMode: boolean;
  public currentLocation: {
    latitude: number,
    longitude: number
  };
  public zoom: number;
  public latitudMarker: number;
  public longitudMarker: number;
  public isProposta: boolean;
  public isDialog: boolean;
  aliasGruaList = [];
  infraccionsList = [];
  // agentsList = [];
  // motiusDesestimarList = [];
  closeButtonText: string;
  aliasGruaDictionary: Dictionary[];
  // valorAutoCompleteAliasGrua;

  sData: string;
  infraccionsDictionary: Dictionary[];
  // valorAutoCompleteInfraccions;

  agentsDictionary: Dictionary[];
  // valorAutoCompleteAgent;

  motiusDesestimarDictionary: Dictionary[];
  // valorAutoCompleteMotiusDesestimar;
  @Output() refreshComponent: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('pbg') pbg: PhotoBoxGalleryComponent;
  // @ViewChild('comboAliasGrua') comboAliasGrua: ComboBoxInputAutocompleteComponent;
  // @ViewChild('comboInfraccions') comboInfraccions: ComboBoxInputAutocompleteComponent;
  // @ViewChild('comboAgent') comboAgent: ComboBoxInputAutocompleteComponent;
  // @ViewChild('comboMotiuDesestimar') comboMotiuDesestimar: ComboBoxInputAutocompleteComponent;
  // public proposta: PropostaGruaDto;
  @Input() proposta: PropostaGruaDto;
  private componentRef: ComponentRef<any>;

  dataDialog: ComponentDialog = Builder.newComponentDialog();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private parametersService: ParametersService,
    private aliesGruaSerevice: AliesGruaService,
    private infraccioService: InfraccioService,
    private propostesService: PropostesService, // utilizamos els servicio de propuestas para obtener imagenes
    private agentsService: AgentsService,
    private gruaSerevice: GruaPendentsService,
    private vehiculoService: VehiclesService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private i18n: I18n,
    private authGuard: AuthGuardService,
    private googleMapsService: GoogleMapsService,
    public auth: AuthorizationService,
    public thisDialogRef: MatDialogRef<PendentsComponent>,
    private confirmationDialogService: ConfirmationDialogService,
    private not: NotificationService,

  ) {
    // carreguem les dades per centrar el mapa
    const inst = sessionStorage.getItem('currentInst');
    if (inst !== '' && inst !== undefined && inst !== 'undefined') {
      const institution: Instituciones = JSON.parse(inst);
      this.currentLocation = { latitude: institution.Latitud, longitude: institution.Longitud };
    }
    // this.valorAutoCompleteAliasGrua = new BehaviorSubject('');
    // this.valorAutoCompleteInfraccions = new BehaviorSubject('');
    // this.valorAutoCompleteAgent = new BehaviorSubject('');
    // this.valorAutoCompleteMotiusDesestimar = new BehaviorSubject('');
    this.isProposta = false;

    if (data !== undefined && data !== null && Object.keys(data).length > 0) {
      if (data.propostaGrua !== undefined && data.propostaGrua !== null) {
        
        this.proposta = data.propostaGrua;
        this.isDialog = true;
      }
      this.latitudMarker = this.currentLocation.latitude;
      this.longitudMarker = this.currentLocation.longitude;
      this.isProposta = data.isProposta;
    } else {
      this.isProposta = true;
      this.isDialog = false;
    }
    this.allowEdit = false;
    this.isEditMode = false;
    if (this.auth.UserCanWrite(this.auth.Pages.TramitadesGrua)) {
      this.allowEdit = true;
      this.closeButtonText = this.i18n('Llista');
    }
    this.getParameters();
    this.loadAgents();
    this.loadMotiusDesestimacio();
    this.loadAliasGrua();
  }

  ngOnInit() {
    this.infraccionsDictionary = new Array<Dictionary>();
    this.sData = this.proposta.data.toString().replace('T', ' ');
    //this.proposta.aliasGrua = null; // reseteamos combo 
    
    this.loadInfraccions();

  }

  ngAfterViewInit(): void {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Pendents'));
    }
    this.loadImatges();
  }
  loadImatges() {

    if (this.proposta !== undefined && this.proposta !== null) {
      this.latitudMarker = this.proposta.latitud;
      this.longitudMarker = this.proposta.longitud;
      this.propostesService
        .getImages(this.proposta.codi).subscribe(images => {
          if (images !== undefined && images !== null && images.length > 0) {
            this.proposta.IMATGES = images;
            this.pbg.updateImatges(images);
          } else {
            this.toastr.warning(this.i18n('Atenció! Aquesta proposta no té imatges!'));
            this.proposta.IMATGES = [];
            this.pbg.updateImatges([]);
          }
        });
    }
  }

  loadImatgesByCodi(codi) {
    this.propostesService
      .getImages(codi).subscribe(images => {
        if (images !== undefined && images !== null && images.length > 0) {
          this.proposta.IMATGES = images;
          this.pbg.updateImatges(images);
        } else {
          this.toastr.warning(this.i18n('Atenció! Aquesta proposta no té imatges!'));
          this.proposta.IMATGES = [];
          this.pbg.updateImatges([]);
        }
      });
  }
  // setDefalutValuesCombosAgent() {
  //   // agent
  //   if (this.proposta.idAgentFk !== undefined && this.proposta.idAgentFk !== null && this.proposta.idAgentFk !== ''
  //    && this.agentsDictionary.length > 0 ) {
  //     // const agentSelected = this.agentsList.find(x => x.codi === this.proposta.idAgentFk);
  //     this.comboAgent.setForcedValueComb(this.proposta.idAgentFk);
  //   } else {
  //     this.comboAgent.resetCombo();
  //   }
  // }

  // setDefalutValuesCombosMotius() {
  //   // agent
  //   if (this.proposta.idMotiuDesestimada !== undefined && this.proposta.idMotiuDesestimada !== null && this.proposta.idMotiuDesestimada !== ''
  //    && this.motiusDesestimarDictionary.length > 0 ) {
  //      
  //     this.comboMotiuDesestimar.setForcedValueComb(this.proposta.idMotiuDesestimada);
  //   } else {
  //     this.comboMotiuDesestimar.resetCombo();
  //   }
  // }
  // setDefaultValueComboAliasGrua() {
  //   if (this.proposta.idInfraccioFK !== undefined && this.proposta.idInfraccioFK !== null && this.proposta.idInfraccioFK !== ''
  //   && this.infraccionsDictionary.length > 0 ) {
  //    const infraccioSelected = this.infraccionsList.find(x => x.id === this.proposta.idInfraccioFK);
  //    if (infraccioSelected !== undefined && infraccioSelected !== null &&  infraccioSelected.aliasGrua !== undefined
  //     &&  infraccioSelected.aliasGrua !== null && this.aliasGruaList !== undefined
  //     && this.aliasGruaList !== null && this.aliasGruaList.length > 0 ) {
  //     const aliasGruaSelected = this.aliasGruaList.find(x => x.codi.toString() === infraccioSelected.aliasGrua);
  //     if (aliasGruaSelected !== undefined && aliasGruaSelected !== null ) {
  //       // this.valorAutoCompleteAliasGrua = aliasGruaSelected.descripcio;
  //       // this.valorAutoCompleteAliasGrua =  new BehaviorSubject(aliasGruaSelected.descripcio);
  //       this.comboAliasGrua.setForcedValueComb(aliasGruaSelected.secureId.toString());
  //       // this.comboAliasGrua.selectedOption(aliasGruaSelected.secureId);
  //     } else {
  //       this.comboAliasGrua.resetCombo();
  //     }
  //    } else {
  //     this.comboAliasGrua.resetCombo();
  //   }
  //  } else {
  //    this.comboAliasGrua.resetCombo();
  //  }
  // }
  // setDefalutValuesCombosInfraccio() {
  //   // infraccio
  //   if (this.proposta.idInfraccioFK !== undefined && this.proposta.idInfraccioFK !== null && this.proposta.idInfraccioFK !== ''
  //    && this.infraccionsDictionary.length > 0 ) {
  //     const infraccioSelected = this.infraccionsList.find(x => x.id === this.proposta.idInfraccioFK);
  //     if (infraccioSelected !== undefined && infraccioSelected !== null ) {
  //       let labelInfr = '';
  //       if (infraccioSelected.article !== undefined && infraccioSelected.article !== null && infraccioSelected.article !== '' ) {
  //         labelInfr = labelInfr + infraccioSelected.article;
  //       }
  //       if (infraccioSelected.apartat !== undefined && infraccioSelected.apartat !== null && infraccioSelected.apartat !== '' ) {
  //         labelInfr = labelInfr + ' ' + infraccioSelected.apartat;
  //       }
  //       if (infraccioSelected.opcio !== undefined && infraccioSelected.opcio !== null && infraccioSelected.opcio !== '' ) {
  //         labelInfr = labelInfr + ' ' + infraccioSelected.opcio;
  //       }
  //       if (infraccioSelected.descCurta !== undefined && infraccioSelected.descCurta !== null && infraccioSelected.descCurta !== '' ) {
  //         labelInfr = labelInfr + ' ' + infraccioSelected.descCurta;
  //       }
  //        this.valorAutoCompleteInfraccions = new BehaviorSubject(labelInfr);
  //       // this.comboInfraccions.selectedOption(infraccioSelected.descCurta);
  //       this.comboInfraccions.setForcedValueComb(infraccioSelected.secureId);
  //     }
  //   } else {
  //     this.comboInfraccions.resetCombo();
  //   }
  // }

  loadAliasGrua() {
    this.aliasGruaDictionary = new Array<Dictionary>();
    this.aliesGruaSerevice.getAllForCombo().subscribe(x => {
      this.aliasGruaList = x;
      x.forEach(element => {
        this.aliasGruaDictionary.push(new Dictionary(element.secureId.toString(), element.descripcio));
      });
    });
  }

  setAliasGrua() {
    if (!this.isNullOrEmpty(this.proposta.idAliasGrua)) {
      //const infraccioSelected = this.infraccionsList.find(x => x.id == this.proposta.idInfraccioFK);
     // if (!this.isNullOrEmpty(infraccioSelected) && !this.isNullOrEmpty(infraccioSelected.aliasGrua) && !this.isNullOrEmpty(this.aliasGruaList)) {
        const aliasGruaSelected = this.aliasGruaList.find(x => x.codi.toString() === this.proposta.idAliasGrua.toString());
        if (!this.isNullOrEmpty(aliasGruaSelected)) {
          this.proposta.aliasGrua = aliasGruaSelected.secureId;
        }
      //}
    }
  }


  loadInfraccions() {
    this.infraccionsDictionary = new Array<Dictionary>();
    this.infraccioService.getAllForCombo().subscribe(x => {
      this.infraccionsList = x;
      x.forEach(element => {
        let labelInfr = '';
        if (element.article !== undefined && element.article !== null && element.article !== '') {
          labelInfr = labelInfr + element.article;
        }
        if (element.apartat !== undefined && element.apartat !== null && element.apartat !== '') {
          labelInfr = labelInfr + ' ' + element.apartat;
        }
        if (element.opcio !== undefined && element.opcio !== null && element.opcio !== '') {
          labelInfr = labelInfr + ' ' + element.opcio;
        }
        if (element.descCurta !== undefined && element.descCurta !== null && element.descCurta !== '') {
          labelInfr = labelInfr + ' ' + element.descCurta;
        }
        this.infraccionsDictionary.push(new Dictionary(element.id.toString(), labelInfr));
      });
      // this.setDefalutValuesCombosInfraccio();
      this.proposta.idInfraccioFK = this.proposta.idInfraccioFK!==null ? this.proposta.idInfraccioFK.toString():null;
      this.setAliasGrua();
    });
  }



  loadAgents() {
    this.agentsDictionary = new Array<Dictionary>();
    this.agentsService.getAllCombo().subscribe(res => {
      const agentsFiltered = res.filter(x => x.agentGrua === false && x.zonaBlava === false);
      // this.agentsList = agentsFiltered;
      agentsFiltered.forEach(element => {
        this.agentsDictionary.push(new Dictionary(element.codi, element.codi));
      });
      // this.setDefalutValuesCombosAgent();
    });
  }
  loadMotiusDesestimacio() {
    this.motiusDesestimarDictionary = new Array<Dictionary>();
    this.gruaSerevice.getAllMotiusDesestimar().subscribe(x => {
      // this.motiusDesestimarList = x;
      x.forEach(element => {
        this.motiusDesestimarDictionary.push(new Dictionary(element.somId, element.motiu));
      });
      // this.setDefalutValuesCombosMotius();
    });
  }

  getParameters() {

    this.zoom = Number.parseInt(this.parametersService.getParameter(Parametros.GPSZOMINICIAL));
  }

  disabled(): boolean {
    return (this.isNullOrEmpty(this.proposta.idAgentFk) || this.isNullOrEmpty(this.proposta.idMotiuDesestimada_fk));

  }
  disabledButtonAccept(): boolean {
    
    return this.isNullOrEmpty(this.proposta.idAgentFk) || 
    (this.isNullOrEmpty(this.proposta.idInfraccioFK) && !this.isNullOrEmpty(this.proposta.implicaDenuncia) && this.proposta.implicaDenuncia === true)
    || (this.isNullOrEmpty(this.proposta.idAliasGrua) && this.proposta.implicaDenuncia === true);
  }

  private isNullOrEmpty(value): boolean {
    return value === undefined || value === null || value === '';
  }

  rejectProposta() {
    this.gruaSerevice.rejectPropostaGrua(this.proposta).subscribe(result => {
      if (result !== undefined && result !== null) {
        if (result === 1) {
          this.toastr.success(this.i18n('La proposta s\'ha acceptat i s\'ha creat la denúncia!'));
          this.dialog.closeAll();
          this.refreshComponent.emit(true);
        } else if (result === 2) {
          this.toastr.success(this.i18n('La proposta s\'ha acceptat i no s\'ha creat la denúncia!'));
          this.dialog.closeAll();
          this.refreshComponent.emit(true);
        } else if (result === 3) {
          this.toastr.success(this.i18n('La proposta s\'ha rebutjat!'));
          this.dialog.closeAll();
          this.refreshComponent.emit(true);
        }
      }
    });
  }
  acceptProposta() {
    this.gruaSerevice.acceptPropostaGrua(this.proposta).subscribe(result => {
      if (result !== undefined && result !== null) {
        if (result === 1) {
          this.toastr.success(this.i18n('La proposta s\'ha acceptat i s\'ha creat la denúncia!'));
          this.dialog.closeAll();
          this.refreshComponent.emit(true);
        } else if (result === 2) {
          this.toastr.success(this.i18n('La proposta s\'ha acceptat i no s\'ha creat la denúncia!'));
          this.dialog.closeAll();
          this.refreshComponent.emit(true);
        } else if (result === 3) {
          this.toastr.success(this.i18n('La proposta s\'ha rebutjat!'));
          this.dialog.closeAll();
          this.refreshComponent.emit(true);
        }
      }
    });
  }

  aliasGruaSeleccionado(idAliasGrua: string) {

    if (idAliasGrua !== undefined) { // Al inicializar el componente llega undefined
      const aliasSelected = this.aliasGruaList.find(x => x.secureId === idAliasGrua);
      this.proposta.idAliasGrua = Number.parseFloat(idAliasGrua);
      if (aliasSelected!==undefined){

        this.infraccionsDictionary = new Array<Dictionary>();
        const infraccionsFiltrades = this.infraccionsList.filter(x => x.aliasGrua === aliasSelected.codi.toString());

        infraccionsFiltrades.forEach(element => {
          let labelInfr = '';
          if (element.article !== undefined && element.article !== null && element.article !== '') {
            labelInfr = labelInfr + element.article;
          }
          if (element.apartat !== undefined && element.apartat !== null && element.apartat !== '') {
            labelInfr = labelInfr + ' ' + element.apartat;
          }
          if (element.opcio !== undefined && element.opcio !== null && element.opcio !== '') {
            labelInfr = labelInfr + ' ' + element.opcio;
          }
          if (element.descCurta !== undefined && element.descCurta !== null && element.descCurta !== '') {
            labelInfr = labelInfr + ' ' + element.descCurta;            
          };
          this.infraccionsDictionary.push(new Dictionary(element.id.toString(), labelInfr));
        });

        // reset del combo si hemos cambiado de alias grua
        const infraccioSelected = this.infraccionsList.find(x => x.id == this.proposta.idInfraccioFK);
        if (infraccioSelected.aliasGrua !== aliasSelected.codi.toString()) {
          this.proposta.idInfraccioFK = null;
        }
      }
      
    }

  }

  changeAlias() {

  }

  // infraccionsSeleccionado(idInfraccio: string) {
  //   if (idInfraccio !== undefined && idInfraccio !== null && idInfraccio !== '') {
  //     const infraccioSelected = this.infraccionsList.find(x => x.secureId === idInfraccio);
  //     this.proposta.infraccion = infraccioSelected;
  //     this.proposta.idInfraccioFK = infraccioSelected.id;
  //   } else {
  //     this.proposta.idInfraccioFK = '';
  //   }
  // }

  // agentSeleccionado(secureIdAgent: string) {
  //   const agentSelected = this.agentsList.find(x => x.SECURE_ID === secureIdAgent);
  //   this.proposta.agent = agentSelected;
  //   this.proposta.idAgentFk = agentSelected.codi;
  // }

  // motiuDesestimarSeleccionado(secureIdMotiu: string) {
  //   const motiuSelected = this.motiusDesestimarList.find(x => x.secureId === secureIdMotiu);
  //   this.proposta.idMotiuDesestimada = motiuSelected.somId;
  // }

  save() {
    this.gruaSerevice.SaveRegistroGrua(this.proposta).subscribe(result => {
      // if (result !== undefined && result !== null ) {
      this.thisDialogRef.close(true);
      // }
    });

  }
  cancel() {
    this.thisDialogRef.close(false);
  }

  remove() {
    this.confirmationDialogService.confirm(this.i18n('Eliminar registre'), this.i18n('El registre serà eliminat i no es podrà recuperar. Segur que vol eliminar-lo?'))
      .then((confirmed) => {
        console.log('User confirmed:', confirmed);
        if (confirmed) {
          const saved = this.gruaSerevice.removePropostaGrua(this.proposta).subscribe(() => {
            this.toastr.info(this.i18n('Registre eliminat'));
            this.thisDialogRef.close(true);
          });
        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }
  consultarVehicle() {
    if (this.proposta.idVehiclesFK !== undefined && this.proposta.idVehiclesFK !== null && this.proposta.idVehiclesFK !== '') {
      this.vehiculoService.checkExistByMatricula(this.proposta.idVehiclesFK).subscribe(x => {
        if (x === undefined || x === null || x === false) {
          this.toastr.warning(this.i18n('ATENCIÓ: Vehicle no existent, primer s\' ha de donar d\'alta o seleccionar un existent!'));
          this.proposta.idVehiclesFK = '';
          this.seleccionarVehicle();
        }
      });
    }
  }

  seleccionarVehicle() {
    const data: ComponentDialog = Builder.newComponentDialog(true);
    const ref = this.dialog.open(VehiclesComponent, {
      width: '80%',
      closeOnNavigation: false,
      autoFocus: true,
      data: data
    });

    ref.afterClosed().subscribe(res => {
      if (res.matricula !== undefined && res.matricula !== null && res.matricula !== '') {
        // this.denunciaDetail.secure_id_vehicle = res.secure_id;
        this.proposta.idVehiclesFK = res.matricula;
      }
    });
  }

  veureDenuncia() {
    if (this.proposta.somIdDenuncia !== undefined && this.proposta.somIdDenuncia !== null) {
      const data: ComponentDialog = Builder.newComponentDialog(true, true, this.proposta.somIdDenuncia.toString());
      const ref = this.dialog.open(DenunciesEditorComponent, {
        width: '99%',
        height: '99%',
        maxHeight: '100vh',
        closeOnNavigation: false,
        autoFocus: true,
        data: data
      });
    }
  }
}
