import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { EstadistiquesService } from '../../services/estadistiques.service';
import { Aparell } from 'app/sections/configuracio/aparells/models/aparell.model';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { DispositiuEstadistiques } from '../../models/dispositiu-estadistica.model';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';

@Component({
  selector: 'app-estadistiques-setmanals',
  templateUrl: './estadistiques-setmanals.component.html',
  styleUrls: ['./estadistiques-setmanals.component.scss']
})
export class EstadistiquesSetmanalsComponent implements OnInit, AfterViewInit {

  private d = new Date();
  public aparells: {value: string, label: string}[];
  public dateSelected: Date = new Date(this.d.setDate( this.d.getDate() - 7));
  public aparellSelected: string;
  public title: string;
  dataDialog: ComponentDialog = Builder.newComponentDialog();


  // Chart
  barChartData: ChartDataSets[];
  barChartLabels: string[];
  barChartOptions: ChartOptions;
  barChartLegend: any;
  barChartType: ChartType;

  @ViewChild(BaseChartDirective) chart: BaseChartDirective;

  constructor(
    private estadistiquesService: EstadistiquesService,
    private i18n: I18n,
    public auth: AuthorizationService,
    private not: NotificationService
  ) { }

  ngOnInit() {
    this.createChart();
  }

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Estadístiques setmanals'));
    }
  }

  getAparells(): void {
    if ( this.dateSelected ) {

      this.estadistiquesService.getAparells( this.dateSelected ).subscribe(
        res => {
          this.aparells = [];
          this.aparells.push({label: this.i18n('Tots'), value: '0'});
          res.forEach(i => {
            let label = '';
            if (i.modelo !== null) {
              label += i.modelo + ' - ';
            }
            if (i.marca !== null) {
              label += i.marca;
            }
            if (i.calle !== null) {
              label += ' - ' + i.calle;
            }
            this.aparells.push({ label: label, value: i.id});
          });
          if ( this.aparellSelected === '0' ) {
            this.reload( this.aparellSelected );
          } else {
            if ( this.aparells.length > 0 ) {
              this.aparellSelected = this.aparells[0].value;
            }
          }
        }
      );

    }
  }

  reload( v: string ): void {
    if ( v && this.dateSelected ) {
      this.estadistiquesService.getEstadistiquesSetmanals( v, this.dateSelected).subscribe(
        res => {
          if ( !this.barChartLabels ) {
            this.barChartLabels = [];
          }
          this.barChartLabels.length = 0;
          this.barChartLabels.push(...Object.values( res.ValorsX ));
          this.barChartData = [];
          const dataNumInfraccions = [];
          const dataVelMitjana = [];
          // tslint:disable-next-line: forin
          for ( const key in res.Valors ) {
            dataNumInfraccions.push( res.Valors[key].NumInfraccions );
            dataVelMitjana.push( res.Valors[key].VelocitatMitjana );
          }
          this.barChartData.push({
            data: dataNumInfraccions, label: this.i18n('Num. infraccions')
          });
          this.barChartData.push({
            data: dataVelMitjana, label: this.i18n('Vel. mitjana')
          });
          this.title =
            dataNumInfraccions.reduce( ( a, b ) => a + b ) +
            dataVelMitjana.reduce( ( a, b ) => a + b ) > 0 ? res.Titol :
            this.i18n( 'SETMANA SENSE DADES' );
        }
      );
    }
  }

  createChart() {
    this.barChartType = 'bar';
    this.barChartLegend = true;
    this.barChartOptions = {
      responsive: true,
      scales: { xAxes: [ { ticks: { autoSkip: false } } ], yAxes: [{}] },
      plugins: {
        datalabels: {
          anchor: 'end',
          align: 'end',
        }
      }
    };
  }

  onDateChange(): void {
    this.getAparells();
  }

}
