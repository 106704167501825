import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef, Inject, OnDestroy, Injectable } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { AvisosFilter } from '../../models/avisos-filter';
import { AgentsService } from 'app/sections/configuracio/agents/services/agents-service.service';
import { AvisosService } from '../../services/avisos.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import { AvisosConf } from 'app/sections/configuracio/avisos/models/avisos.model';
import { LoaderService } from 'path-shared/components/loader/loader.service';

/**
 * Node for to-do item
 */
 export class TodoItemNode {
  children: TodoItemNode[];
  item: AvisosConf;
}

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
  item: AvisosConf;
  level: number;
  expandable: boolean;
}

@Injectable()
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<TodoItemNode[]>([]);

  get data(): TodoItemNode[] { return this.dataChange.value; }

  constructor(
    private avisosService: AvisosService,
    private loaderService: LoaderService
  ) {
    this.loaderService.show(true);
    this.initialize();
  }

  initialize() {
    // Carreguem carpetes per a passar-les al dialog
    this.avisosService.getCarpetes().subscribe(result => {
      const avisos: AvisosConf[] = Object.assign(result);
      const data = this.buildFileTree(avisos, 0);

      // Notify the change.
      this.dataChange.next(data);

      this.loaderService.hide(true);
    },
    error => {
      this.loaderService.hide(true);
    });
  }

   buildFileTree(obj: AvisosConf[], level: number): TodoItemNode[] {
    return Object.keys(obj).reduce<TodoItemNode[]>((accumulator, key) => {
      const value = obj[key].children;
      const node = new TodoItemNode();
      node.item = new AvisosConf(obj[key].id, obj[key].nom, '', obj[key].pareId, obj[key].isFolder, '', false);

      if (value != null) {
        if (typeof value === 'object') {
          node.children = this.buildFileTree(value, level + 1);
        } else {
          node.item = value;
        }
      }

      return accumulator.concat(node);
    }, []);
  }
}

@Component({
  selector: 'app-avisos-filter',
  templateUrl: './avisos-filter.component.html',
  styleUrls: ['./avisos-filter.component.scss'],
  providers: [ChecklistDatabase]
})
export class AvisosFilterComponent implements OnInit, OnDestroy {
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: TodoItemFlatNode | null = null;

  /** The new item's name */
  newItemName = '';

  treeControl: FlatTreeControl<TodoItemFlatNode>;

  treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;

  dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);

  subscription: Subscription;
  filter: AvisosFilter;

  agents = [];

  assumptesArray = [];

  @Output() notify: EventEmitter<AvisosFilter> = new EventEmitter<AvisosFilter>();

  @ViewChild('mainForm') private mainForm: ElementRef;

  constructor(
    public thisDialogRef: MatDialogRef<AvisosFilterComponent>,
    private agentsService: AgentsService,
    private i18n: I18n,
    private _database: ChecklistDatabase,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    this.filter = data.filter;

    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
    this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    _database.dataChange.subscribe(dataResult => {
      this.dataSource.data = dataResult;
    });
  }

  ngOnInit() {
    // Carreguem els agents per a passar-les al dialog
    this.agentsService.getAllCombo().subscribe(result => {
      this.agents = [];
      if (result !== null) {
        let i: number;
        i = 0;
        result.forEach(element => {
          this.agents[i] = element;
          i++;
        });
      }
      this.agents.unshift({'codi': ''});
    });

    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.apply();
      }
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ToggleAviso(value) {
    if (value.checked === false) {
      this.assumptesArray.push(value.id);
      this.filter.idTipusAvisList = this.assumptesArray.toString();
      value.checked = true;
    } else {
      this.assumptesArray.splice(value.id);
      this.filter.idTipusAvisList = this.assumptesArray.toString();
      value.checked = false;
    }

  }

  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated();
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }

  //#region MatTree functions

  getLevel = (node: TodoItemFlatNode) => node.level;

  isExpandable = (node: TodoItemFlatNode) => node.expandable;

  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;

  hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.item === null;

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
   transformer = (node: TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.item ? existingNode : new TodoItemFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  /* Get the parent node of a node */
  getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  //#endregion
}
