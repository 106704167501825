<app-dialog-header label="Imatges" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Afegir" (click)="Afegir();" i18n-text color="has-color-green" icon="fa-plus-square">
  </app-tool-button>
  <app-form-file-multiple-upload label="Fitxer" i18n-label labelHidden="true" (fileReady)="fileReady($event)" hidden
    #fileInput></app-form-file-multiple-upload>

  <app-tool-button [disabled]="this.numSelected==0" text="Eliminar" i18n-text (click)="Eliminar()" color="has-color-red"
    icon="fa-trash"></app-tool-button>
</app-toolbar>
<app-dialog-body>
  <app-photo-box-gallery (notify)="getChildNumSelected($event)" #photoBoxGallery [numDenuncia]="this.contenedor"
    [imatges]="this.images" [showLupa]="false" [magnifierRight]="25" [magnifierTop]="100"></app-photo-box-gallery>
</app-dialog-body>