import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class GruaMotiusDesestimarFilter extends FilterData {
  id: number;
  motiu: string;
  SHOW_PDA: boolean;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.motiu = '';
    this.labels['motiu'] = this.i18n('Motiu');
    this.SHOW_PDA=false;

  }
}
