import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { TipusMatriculaFilter } from '../models/tipus-matricula-filter';
import { HttpParams } from '@angular/common/http';
import { PaginationFilter } from 'path-shared/models/pagination.filter';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';

const defaultSortParam = 'descripcio';
const defaultSortDirection = 'asc';

@Injectable()
export class TipusMatriculaFilterService extends CustomFilterService {
  public createFilterParams(filter: TipusMatriculaFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {

    return new HttpParams()
    .append('codi', filter.codi ? filter.codi : '')
    .append('descripcio', filter.descripcio ? filter.descripcio : '')
    .append('codInsti', filter.codInsti ? filter.codInsti : '')
    .append('denunciaPOL', this.getTristateValue(filter.denunciaPOL))
    .append('denunciaZB', this.getTristateValue(filter.denunciaZB))
    .append('generarAlarma', this.getTristateValue(filter.generarAlarma))
    .append('guardarLOG', this.getTristateValue(filter.guardarLOG))
    .append('reincidents', this.getTristateValue(filter.reincidents))
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
