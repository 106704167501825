import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { Router } from '@angular/router';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { ZoneFilter } from '../models/zones-filtar';
import { ToolButtonPermissionsComponent } from 'path-shared/components/toolbar/tool-button-permissions/tool-button-permissions.component';
import { ZonesTableComponent } from '../components/zones-table/zones-table.component';
import { Builder } from 'path-shared/models/builder';
import { PermissionDialogComponent } from 'path-shared/components/permission-dialog/permission-dialog.component';
import { ZonesEditarComponent } from '../components/zones-editar/zones-editar.component';
import { TiketService } from '../services/tiket-service.';
import { ZonesFilterComponent } from '../components/zones-filter/zones-filter.component';



@Component({
  selector: 'app-zones',
  templateUrl: './zones.component.html',
  styleUrls: ['./zones.component.scss']
})
export class ZonesComponent implements OnInit, AfterViewInit {

  permissionDialogRef: MatDialogRef<PermissionDialogComponent>;
  activeFilters: string[] = [];
  filterData: ZoneFilter;
  somIdFoo: number;
  tipo_tiket = [];
  dataDialog: ComponentDialog = Builder.newComponentDialog();


  @ViewChild(ZonesTableComponent) zonesTable: ZonesTableComponent;

  constructor(
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private tiketServices: TiketService,
    private router: Router,
    private i18n: I18n,
    private toastr: ToastrService,
    private not: NotificationService,

    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
    ) {

      this.filterData = new ZoneFilter('', '');
      if (data !== undefined) {
        this.dataDialog = data;
      }
    }

  ngOnInit() {
    this.getTipoTiket();
  }

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Zones'));
    }
  }

  edit() {
    const idSeleccionat: string = this.tiketServices.displayedItems['checkedItems'][0];
    this.update(idSeleccionat);
  }

  create() {
    this.update(null);
  }

  update(secure_id: string) {
    const data: ComponentDialog = Builder.newComponentDialog(true, false, secure_id);
    const ref = this.dialog.open(ZonesEditarComponent, {
      width: '70%',
      height: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'data': data
      }
    });

    ref.afterClosed().subscribe(updated => {
      if (updated) { this.zonesTable.load(); }
    });

  }

  refresh() {
    this.zonesTable.load();
  }


  onFilterClicked() {
    const ref = this.dialog.open(ZonesFilterComponent, {
      width: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.zonesTable.filter,
       
      }
    });

    ref.componentInstance.notify.subscribe((filter: ZoneFilter) => {
      this.zonesTable.filter.set(filter);
      this.zonesTable.load();
    });
  }

  onRowSelected(somid) {
    this.update(somid);
  }

  generateExcel() {
    this.zonesTable.generateExcel();
  }

  getTipoTiket() {
    this.tiketServices.getAll().subscribe(tiket => {
      this.tipo_tiket = tiket;
      
    });
 }

}
