import { Component, OnInit, Input } from '@angular/core';
import { Denuncia } from '../../models/denuncia.model';

declare var $;

@Component({
  selector: 'app-denuncies-estat',
  templateUrl: './denuncies-estat.component.html',
  styleUrls: ['./denuncies-estat.component.scss']
})
export class DenunciesEstatComponent implements OnInit {
  @Input() denuncia: Denuncia;
  constructor() { }

  ngOnInit() {
    $(document).ready(function() {

      $('[data-toggle="popover"]').popover();

  });
  }

  cancelat() {
    if (this.denuncia !== undefined) {
      return (this.denuncia.dataAnul != null && this.denuncia.dataAnul !== undefined);
    } else {
      return false;
    }
  }

  tancat() {
    if (this.denuncia !== undefined) {
      return this.denuncia.tancat;
    } else {
      return false;
    }
  }

  executat() {
    if (this.denuncia !== undefined) {
      return this.denuncia.executat;
    } else {
      return false;
    }
  }

  traspassat() {
    if (this.denuncia !== undefined) {
      return (this.denuncia.dataTrans != null && this.denuncia.dataTrans !== undefined);
    } else {
      return false;
    }
  }

  cobratTotal() {
    if (this.denuncia !== undefined) {
      return this.denuncia.cobroTotal;
    } else {
      return false;
    }
  }

  cobratParcial() {
    if (this.denuncia !== undefined && this.denuncia.importCobrat > 0) {
      return this.denuncia.cobroParcial;
    } else {
      return false;
    }
  }

}
