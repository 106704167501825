import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {

  @Output() ficheroCargado: EventEmitter<any> = new EventEmitter(undefined);

  constructor(private thisDialogRef: MatDialogRef<FileUploaderComponent>) { }

  ngOnInit() {
  }

  fileChanged(e) {
    this.thisDialogRef.close(e.target.files[0]);
  }

}
