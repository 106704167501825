<app-dialog-header label="Alarma" i18n-label></app-dialog-header>

<app-dialog-body>

  <br/>
  <br/>
  
    <div class="row my-auto" style="align-items: center;">
        <i class="fa fa-exclamation-triangle fa-7x has-color-red"></i>
          <br/>
        <p class="text-justify"><b i18n>PDA: </b>&nbsp;{{this.dialogData.Text }} &nbsp; </p>
        <br/>
        <br/>
        <p ><b i18n>Agent: </b>&nbsp;{{this.dialogData.codigoAgente }}</p>
        <br/>
        <br/>
        
        <p class="text-justify"><b i18n>&nbsp; Data: </b>&nbsp;{{this.dialogData.Data | longDate}}</p>
    </div>
    <div  class="row d-flex align-items-end flex-column" >
      <div class=" mt-auto p-2" >
          <button class="btn btn-success offset-md-1" type="button" (click)="this.ViewDetall()" >
              <i class="fa fa-search-location"></i>&nbsp;<span i18n>Veure</span>  
            </button>
      </div>
    </div>

    

</app-dialog-body>