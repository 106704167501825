import { Component, OnInit, Inject, ViewChild, ElementRef, HostListener, HostBinding, OnDestroy, Renderer2} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSliderChange, MatSlider } from '@angular/material';
import { ImagesService } from '../../../../../shared/services/images/images.service';
import { SOM_CARAS } from 'path-models/SOM_CARAS';
import { Point } from 'path-shared/models/point';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { SingleImageViewerComponent } from 'path-shared/components/photo-box/components/single-image-viewer/single-image-viewer.component';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { PropostesEditorComponent } from 'app/sections/propostes/components/propostes-classificar/propostes-editor/propostes-editor.component';
import { Proposta } from 'app/sections/propostes/models/proposta.model';
import { SugerenciaMatricula } from 'app/sections/propostes/models/SugerenciaMatricula';
import { PhotoBoxService } from '../../photo-box.service';
import { PropostesService } from 'path-propostes-services/propostes.service';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { PropostaReject } from 'app/sections/propostes/models/proposta-reject.model';


declare const SVG: any;
let draw;



@Component({
  selector: 'app-photo-box-viewer',
  templateUrl: './photo-box-viewer.component.html'
})
export class PhotoBoxViewerComponent implements OnInit, OnDestroy {

  scale = 1;
  cares: SOM_CARAS[] = [];
  selectedCara: SOM_CARAS;
  offsset: object;
  selectedElement: SVGElement;
  offset: Point;
  pol: any;
  numDenuncia: string;
  somId: string;
  name: string;
  width: Observable<number>;
  nextSomId: number;
  auto: boolean;
  isElementSelected = false;
  spinnerTitle: string;
  image: any;
  viewerDialog: MatDialogRef<SingleImageViewerComponent>;
  proposta: Proposta;
  botoMatricula: boolean;
  llistaImatges: any[];
  mostrarCaras = false;
  addRectangleOnClick = false;
  DEFAULT_CARA_WIDTH = 100;
  DEFAULT_CARA_HEIGHT = 100;
  image_ratio = 1;
  FACE_GUTTER_FACTOR = 1.1;
  indexImatgeNew: number;
  group;
  croping = false;
  cropCara = new SOM_CARAS();
  widthBrillantor: string;
  sugerenciaMatricula: boolean;


  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('slider') slider: MatSlider;

  @HostListener('document:keydown', ['$event']) handleKeyboardDownEvent(event: KeyboardEvent) {
    event.stopPropagation();
    this.keyDown(event.key);
  }
  @HostListener('document:keyup', ['$event']) handleKeyboardUpEvent(event: KeyboardEvent) {
    event.stopPropagation();
    this.keyUp(event.key);
  }

  @HostListener('window:mousewheel', ['$event']) onMousewheel(event) {
    event.stopPropagation();
    this.mouseWheel(event);
  }


  constructor(
    public thisDialogRef: MatDialogRef<PhotoBoxViewerComponent>,
    private imagesService: ImagesService,
    private toastr: ToastrService,
    private imageDialog: MatDialogRef<SingleImageViewerComponent>,
    private dialog: MatDialog,
    private i18n: I18n,
    private photoBoxService: PhotoBoxService,
    private propostesService: PropostesService,
    private confirmationDialogService: ConfirmationDialogService,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {
    this.width = new Observable<number>();
    this.nextSomId = -1;
    this.selectedElement = null;
    this.auto = false;
    this.sugerenciaMatricula = false;

  }

  ngOnInit() {
    this.numDenuncia = this.data['NumDenuncia'];
    this.somId = this.data['SomId'];
    this.name = this.data['Name'];
    this.proposta = this.data['Proposta'];
    this.botoMatricula = this.data['botoMatricula'];
    this.llistaImatges = this.data['llistaImatges'];
    this.indexImatgeNew = this.llistaImatges.findIndex(g => g.imatge.SOM_ID === parseInt(this.somId, 10));
    draw = SVG('canvas');
    draw.on('click', this.drawClick.bind(this));
    draw.on('mousemove', this.mouseMove.bind(this));
    this.cropCara.brightness = 1;

    this.initImage();
  }

  ngOnDestroy() {
    return this.thisDialogRef.close(this.llistaImatges);
  }

  initImage() {
    this.loadBackgroundImage();

    this.scale = 1;
    this.group.scale(this.scale);
    draw.attr('height', 650 * this.scale);
    draw.attr('width', 910 * this.scale);
    draw.attr('filter', 'brightness(1)');
    this.slider.value = 5;
    this.isElementSelected = false;
    this.selectedElement = null;
  }

  loadBackgroundImage(): Observable<any> {
    draw.each(function(i, children) {
      if (this.type === 'image') {
        this.remove();
      }
    });
    this.group = draw.group(this.image = draw
      .image(this.imageUrl(this.somId, 'L'))
      .loaded(
        function(loader) {

          draw.size('100%', '630');
          draw.viewbox({ x: 0, y: 0, width: loader.width, height: loader.height });
          this.width = parseInt(this.image.bbox().w, 10);
          this.obtenirCares(this.somId);
          this.image_ratio = this.image.rbox().w / this.image.bbox().w;

          if (this.proposta !== undefined) {
            this.marcarMatriculesTrobades();
          }

        }.bind(this)
      ));

    return this.width;
  }

  imageUrl(somId, suffix = '') {
    const src = this.imagesService.getImageUrl(somId, suffix) + '?' + new Date().getTime();
    return src;
  }

  obtenirCares(somId): any {
    draw.each(function(i, children) {
      if (this.type !== 'image') {
        this.draggable(false);
        this.resize('stop');
        this.remove();
      }
    });
    return this.imagesService
      .getImageFaces(somId, this.width)
      .subscribe(response => {
        this.cares = response;
        this.cares.forEach(cara => {
          this.addRectangle(cara);
        });
        if (!this.auto && this.cares.length === 0) {
          //  this.toastr.info(this.i18n('No s\'ha trobat cap cara!'));

           if (!this.sugerenciaMatricula) {
            this.mostrarCaras = false;
           } else {
            this.mostrarCaras = true;
           }

        } else {
          this.mostrarCaras = true;
        }
        if (this.mostrarCaras) {
          draw.select('rect').each(function() {this.show(); });
        } else {
          draw.select('rect').each(function() {this.hide(); });
        }
        this.auto = false;
      });
  }

  addRectangle(cara: SOM_CARAS) {

    const rect = draw
      .rect(cara.WIDTH_IMAGE, cara.HEIGHT_IMAGE)
      .selectize()
      .resize();

    rect.attr({
      stroke: 'red',
      fill: 'red',
      'stroke-width': '3',
      'fill-opacity': '0.5'
    });
    rect.data('cara', cara);
    let x = cara.LEFT_IMAGE + cara.WIDTH_IMAGE <= this.image.bbox().w - 1 ? cara.LEFT_IMAGE : cara.LEFT_IMAGE - cara.WIDTH_IMAGE;
    let y = cara.TOP_IMAGE + cara.HEIGHT_IMAGE <= this.image.bbox().h - 1 ? cara.TOP_IMAGE : cara.TOP_IMAGE - cara.HEIGHT_IMAGE;
    x = x < 0 ? 0 : x;
    y = y < 0 ? 0 : y;
    rect.move(x, y);
    rect.draggable({minX: 0, minY: 0, maxX: this.image.bbox().w, maxY: this.image.bbox().h});
    rect.on('resizedone', this.saveResizeData.bind(this, rect, cara));
    rect.on('dragend', this.saveResizeData.bind(this, rect, cara));
    rect.on('click', this.selectCurrent.bind(this));
  }

  selectCurrent(evt) {
    console.log('selectCurrent: ' + evt.target);


    if (this.selectedElement !== evt.target) {
      if (this.selectedElement != null) {
        this.selectedElement.setAttribute('stroke', 'red');
      }
      this.selectedElement = evt.target;
      this.selectedElement.setAttribute('stroke', 'green');

      this.isElementSelected = true;

    }
  }

  saveResizeData(rect, cara) {
    cara.TOP_IMAGE = parseInt(rect.attr('y'), 10);
    cara.LEFT_IMAGE = parseInt(rect.attr('x'), 10);
    cara.WIDTH_IMAGE = parseInt(rect.attr('width'), 10);
    cara.HEIGHT_IMAGE = parseInt(rect.attr('height'), 10);

  }

  saveCares() {
    console.log(JSON.stringify(this.cares));

    this.imagesService
      .setImageFaces(this.somId, this.cares, this.width)
      .subscribe(response => {
        this.initImage();

      });
  }

  findCara(somId) {
    return this.cares.find(x => x.SOM_ID === somId);
  }

  removeCara(cara) {
    const index: number = this.cares.indexOf(cara);
    if (index !== -1) {
        this.cares.splice(index, 1);
    }
  }

  newCara(left = 0, top = 0) {
    if (left < 0) { left = 0; }
    if (top < 0) { top = 0; }
    const cara = new SOM_CARAS();
    cara.LEFT_IMAGE = left;
    cara.TOP_IMAGE = top;
    cara.WIDTH_IMAGE = this.DEFAULT_CARA_WIDTH;
    cara.HEIGHT_IMAGE = this.DEFAULT_CARA_HEIGHT;
    cara.SOM_ID = this.nextSomId--;
    this.cares.push(cara);
    this.addRectangle(cara);
  }

  getRect(id): any {
    let found = null;
    let n = 0;
    while (n < draw.children().length && found == null) {
      const node = draw.get(n);
      if (node.node.id === id) {
        found = node;
      }
      n++;
    }
    return found;
  }

  removeRectangles() {
    draw.select('g').each(function() {this.remove(); });
    draw.select('rect').each(function() {this.remove(); });
    this.selectedElement = null;
    this.selectedCara = null;
  }

  deleteSelectedCara() {
    if (this.selectedElement == null) {
     this.toastr.warning(this.i18n('Selecciona una cara per esborrar'));
    } else {
      const rect =  this.getRect(this.selectedElement.id);
      const id = rect.node.instance.data('cara').SOM_ID;
      this.removeCara(this.findCara(id));

      this.removeRectangles();
      this.cares.forEach(cara => {
        this.addRectangle(cara);
      });

      this.isElementSelected = false;

    }
  }

  mostrarOriginal(evt = null, x = 0, y = 0) {
    if (evt === null || evt.target === this.image.node) {
      this.imageDialog = this.dialog.open(SingleImageViewerComponent,
      {
        width: '90%',
        height: '90%',
        closeOnNavigation: false,
        autoFocus: true,
        data: {
          src: this.imageUrl(this.somId),
          name: this.name,
          x: x, y: y, width: this.width,
          Proposta: this.proposta, botoMatricula: this.botoMatricula,
          somId: this.somId,
          llistaImatges: this.llistaImatges
        }
      });

      this.imageDialog.afterClosed().subscribe(updated => {
        // Al tancar component en modal de veure imatge original
        if (this.proposta !== undefined && this.proposta.MATRICULA !== '' && this.proposta.MATRICULA !== null && this.proposta.MATRICULA !== undefined) {
          this.matriculesRequadrarPixelar(this.proposta.MATRICULA);
        } else {
          this.marcarMatriculesTrobades();
        }
      });
    }

  }

  undo(somId): any {
    return this.imagesService
      .setImageUndo(somId)
      .subscribe(response => {
        this.initImage();
      });
  }

  toggleMostrarCaras() {
    this.mostrarCaras = ! this.mostrarCaras;
    if (this.mostrarCaras) {
      draw.select('rect').each(function() {this.show(); });
    } else {
      draw.select('rect').each(function() {this.hide(); });
    }
  }

  colorRequadresButton(): string {
    return 'color-caras-' + this.mostrarCaras.toString();
  }

  caraBelow(x, y) {
    let detected = false;
    this.cares.forEach(cara => {
      if (this.containsPoint(x, y, cara)) {
        detected = true;
      }
    });
    return detected;
  }

  private containsPoint(x, y, cara: SOM_CARAS) {
    return cara.LEFT_IMAGE / this.FACE_GUTTER_FACTOR <= x && x <= cara.LEFT_IMAGE + cara.WIDTH_IMAGE * this.FACE_GUTTER_FACTOR &&
               cara.TOP_IMAGE / this.FACE_GUTTER_FACTOR <= y && y <= cara.TOP_IMAGE + cara.HEIGHT_IMAGE * this.FACE_GUTTER_FACTOR;
  }

  drawClick(event) {
    setTimeout(() => {
      const offsetx = (this.image.bbox().w - this.image.rbox().w) / 2;
      const x = (event.layerX - offsetx) / this.image_ratio;
      const y = event.layerY / this.image_ratio;
      if (!this.addRectangleOnClick) {
        if (this.caraBelow(x, y)) {

        } else {
          this.mostrarOriginal(event, x, y);
        }
      } else {
        this.newCara(x - (this.DEFAULT_CARA_WIDTH / 2), y - (this.DEFAULT_CARA_HEIGHT / 2));
      }
    });
  }

  keyDown(key) {
    if (key === 'Control') {
      if (!this.addRectangleOnClick) {
        setTimeout(() => {
          this.addRectangleOnClick = true;
        }, 10);
      }
    }
  }

  keyUp(key) {
    if (key === 'Control') {
      if (this.addRectangleOnClick) {
        setTimeout(() => {
          this.addRectangleOnClick = false;
        }, 10);
      }
    }
  }

  getAddButtonColor() {
    if (this.addRectangleOnClick) {
      return 'has-color-yellow';
    } else {
      return 'has-color-aqua';
    }
  }

  mouseMove(event: MouseEvent) {
    // console.log(event.layerX + " " + event.layerY);

  }

  saveMatricula() {
    const ref = this.dialog.open(PropostesEditorComponent, {
                  closeOnNavigation: false,
                  autoFocus: true,
                  maxWidth: '50%',
                  maxHeight: '30%',
                  data: this.proposta,
                });

    ref.afterClosed().subscribe(updated => {
      // Al tancar component en modal per editar país/matrícula/vehícle
      if (this.proposta !== undefined && this.proposta.MATRICULA !== '' && this.proposta.MATRICULA !== null && this.proposta.MATRICULA !== undefined) {
        this.matriculesRequadrarPixelar(this.proposta.MATRICULA);
      } else {
        this.marcarMatriculesTrobades();
      }
    });
  }

  setPais(pais) {
    this.proposta.pais = pais;
  }

  setMatricula(matricula) {
    this.proposta.MATRICULA = matricula;

    this.propostesService.ValidarMatriculaAutorizada(matricula,this.proposta.SECURE_ID).subscribe(autorizada => {
      if (autorizada) {
        this.confirmationDialogService.confirm(this.i18n('Atenció'), this.i18n('La nova matricula pertany a una llista autoritzada. \nSi accepta el canvi es desestimarà la denúncia de forma automàtica'))
        .then((confirmed) => {
          if (confirmed) {
            const propostaData: PropostaReject = {
              ID_MOTIU_DESESTIMAR_PROPOSTA: 99,
              TEXT_DESESTIMAR_PROPOSTA: 'Matrícula desestimada automàticament (Està en llista autoritzada)',
              propostes: []
            };
            this.propostesService.saveProposta(this.proposta).subscribe(result => {
              this.propostesService.rejectProposta(this.proposta.SECURE_ID, propostaData).subscribe(() => {
                this.toastr.info(this.i18n('Proposta desestimada de forma automàtica'));
                this.proposta.proposta = 2;                

              });
            });

          }
        });
      } else {
        this.propostesService.saveProposta(this.proposta).subscribe(result => {
          this.toastr.info(this.i18n('Proposta modificada correctament'));     
          
           // Combo component matrícula
          if (this.proposta !== undefined && this.proposta.IMATGES !== undefined) {
            this.matriculesRequadrarPixelar(this.proposta.MATRICULA, true, true);
            }
        });
       
      }
    });
  }

  moveLeft() {
    const idAct = this.llistaImatges.findIndex(g => g.imatge.SOM_ID === parseInt(this.somId, 10));
    this.mostrarImatgeNew(idAct - 1);
    this.auto = false;
    this.loadBackgroundImage();
  }

  moveRight() {
    const idAct = this.llistaImatges.findIndex(g => g.imatge.SOM_ID === parseInt(this.somId, 10));
    this.mostrarImatgeNew(idAct + 1);
    this.auto = false;
    this.loadBackgroundImage();
  }

  mostrarImatgeNew(n: number) {
    this.indexImatgeNew = n;
    this.somId = this.llistaImatges[n].imatge.SOM_ID.toString();
    this.name = this.llistaImatges[n].imatge.NOMBRE_FICHERO.toString();
  }

  rotar() {
    this.imagesService.rotateImage(this.somId).subscribe(() => {
      this.initImage();
    });
  }


  mouseWheel(event) {
    event.preventDefault();
    if (event.wheelDelta > 0) {
      if (this.scale < 2) {
        this.scale += 0.1;
      }

    } else if (event.wheelDelta < 0) {
      if (this.scale > 0.5) {
        this.scale -= 0.1;
      }
    }

    this.group.scale(this.scale);
    draw.attr('height', 630 * this.scale);
    draw.attr('width', 930 * this.scale);
    this.canvas.nativeElement.scrollTop = 9999;
    this.canvas.nativeElement.scrollLeft = 9999;
    this.canvas.nativeElement.scrollTop /= 2;
    this.canvas.nativeElement.scrollLeft /= 2;

  }

  BrightnessChanged(event: MatSliderChange) {
    draw.attr('filter', 'brightness(' + (event.value * 20) / 100 + ')');
    this.cropCara.brightness = (event.value * 20) / 100;
  }

  Crop() {
    this.croping = true;

    this.cropCara.LEFT_IMAGE = 0;
    this.cropCara.TOP_IMAGE = 0;
    this.cropCara.WIDTH_IMAGE = this.DEFAULT_CARA_WIDTH;
    this.cropCara.HEIGHT_IMAGE = this.DEFAULT_CARA_HEIGHT;
    this.cropCara.SOM_ID = this.nextSomId--;
    this.addCropRectangle(this.cropCara);
  }

  addCropRectangle(cara) {

    const rect = draw
      .rect(cara.WIDTH_IMAGE, cara.HEIGHT_IMAGE)
      .selectize()
      .resize();

    rect.attr({
      stroke: 'blue',
      fill: 'blue',
      'stroke-width': '3',
      'fill-opacity': '0.5'
    });
    rect.data('crop', cara);
    rect.move(cara.LEFT_IMAGE, cara.TOP_IMAGE);
    rect.draggable({minX: 0, minY: 0, maxX: this.image.bbox().w, maxY: this.image.bbox().h});
    rect.on('resizedone', this.saveResizeData.bind(this, rect, cara));
    rect.on('dragend', this.saveResizeData.bind(this, rect, cara));
  }

  CancelCrop() {
    this.croping = false;
    this.removeRectangles();
    this.cares.forEach(cara => {
      this.addRectangle(cara);
    });
  }

  ConfirmCrop() {
    const cropData: SOM_CARAS[] = [];
    cropData.push(this.cropCara);
    this.imagesService.cropImage(this.somId, cropData).subscribe( data => {
      this.llistaImatges.push({'imatge': data, 'checked' : true});
      this.somId = data.SOM_ID;
      this.name = data.NOMBRE_FICHERO;
      const nouId = this.llistaImatges.findIndex(g => g.imatge.SOM_ID === parseInt(this.somId, 10));

      this.mostrarImatgeNew(nouId);
      this.initImage();
      this.croping = false;
    });
  }

  addBrightness(value) {
    if (this.slider.value + value >= this.slider.min && this.slider.value + value <= this.slider.max) {
      this.slider.value += value;
      draw.attr('filter', 'brightness(' + (this.slider.value * 20) / 100 + ')');
      this.cropCara.brightness = (this.slider.value * 20) / 100;
    }
  }

  getWidthBrillantor() {
    const styles = {
      'width': this.proposta !== undefined ? '60%' : '25%'
    };
    return styles;
  }

  //#region Tractar matrícules trobades per l'OCR

  /*
    Mètode que a partir de la informació guardada per l'OCR, ens envia al back on estàn les matrícules:
      - isPixelate = true
          Ens pixela aquelles matrícula que no coincideixin amb la que tenim informada.
      - isCrop = true
          Fa una nova imatge retallant la matrícula que tenim informada
  */
  matriculesRequadrarPixelar(matricula: string, isPixelate: boolean = false, isCrop: boolean = false) {
    const matriculasImageData: SOM_CARAS[] = [];
    let top;
    let left;
    let width;
    let height;
    let auxMatriculaImage;

    this.proposta.MATRICULA = matricula;

    // Només pixelem en cas de tenir més d'una matrícula i només el 1er cop que escollim
    if (isPixelate && this.proposta.sugMatriculesCoordenades.length > 1) {
      this.proposta.sugMatriculesCoordenades.forEach(sugMatriculaCoordenada => {
        top = sugMatriculaCoordenada.coordenadas[0];
        left = sugMatriculaCoordenada.coordenadas[1];
        width = sugMatriculaCoordenada.coordenadas[2];
        height = sugMatriculaCoordenada.coordenadas[3];

        auxMatriculaImage = new SOM_CARAS();
        auxMatriculaImage.TOP_IMAGE = top;
        auxMatriculaImage.LEFT_IMAGE = left;
        auxMatriculaImage.WIDTH_IMAGE = width - left;
        auxMatriculaImage.HEIGHT_IMAGE = height - top;
        auxMatriculaImage.brightness = this.cropCara.brightness;
        auxMatriculaImage.matricula = sugMatriculaCoordenada.matricula;

        matriculasImageData.push(auxMatriculaImage);
      });

      if (matricula === '' || matricula === null || matricula === undefined) {
        matricula = '-1';
      }
      
      const OCRImages = this.proposta.IMATGES.filter(x => x.SUGMATRICULA !== null);
      if (OCRImages.length === 1) {
        const somId = OCRImages[0].SOM_ID.toString();
        this.auxMatriculaRequadrarPixelar(somId, isCrop, matricula, matriculasImageData);
      } else if (OCRImages.length > 1) {

        this.auxMatriculaRequadrarPixelarTodas(isCrop, matricula, matriculasImageData);
      } else {
        this.proposta.IMATGES.forEach(imatge => {
          const somId = imatge.SOM_ID.toString();
          this.auxMatriculaRequadrarPixelar(somId, isCrop, matricula, matriculasImageData);
        });
      }
    }

  }
  auxMatriculaRequadrarPixelarTodas(isCrop, matricula, matriculasImageData) {
    this.imagesService.setTodasMatriculasImage(this.proposta.NUMDENUNCIA, matricula).subscribe(() => {
      let indexImagen = 0;
      this.proposta.IMATGES.forEach(imatge => {
        const somId = imatge.SOM_ID.toString();
        // Refresquem la imatge
        const imageSource = this.imageUrl(somId, 'M');
        const drawAux = SVG.get('#draw_imageDiv_' + somId);
        drawAux.image(imageSource);
        //this.marcarMatriculesTrobades(drawAux);
        // this.marcarMatriculesTrobadesNormal(drawAux, imatge.SOM_ID);
        if (isCrop && indexImagen === 0) {
          let matriculasCrop: SOM_CARAS[] = [];

          let auxMatriculaImage;
          let currentSugIndex = imatge.sugMatriculesCoordenades.findIndex(x => x.matricula === matricula);
          if (currentSugIndex !== -1) {
            let currentSug = imatge.sugMatriculesCoordenades[currentSugIndex];

            auxMatriculaImage = new SOM_CARAS();
            auxMatriculaImage.TOP_IMAGE = currentSug.coordenadas[0];//top;
            auxMatriculaImage.LEFT_IMAGE = currentSug.coordenadas[1];//left;
            auxMatriculaImage.WIDTH_IMAGE = currentSug.coordenadas[2] - currentSug.coordenadas[1]; //width - left;
            auxMatriculaImage.HEIGHT_IMAGE = currentSug.coordenadas[3] - currentSug.coordenadas[0]; //  height - top;
            auxMatriculaImage.brightness = 1;
            auxMatriculaImage.matricula = currentSug.matricula;

            matriculasCrop.push(auxMatriculaImage);
            // Tornem a posar brillantor a 1 perquè ja ens l'haurà aplicat al pixelat i així no queda tan clara la imatge.
            matriculasCrop[0].brightness = 1;
            if (matriculasCrop.length === 1) {
              this.cropMatriculaSeleccionada(matriculasCrop);
              indexImagen = indexImagen + 1;//solo augmentamos indicie si se hace crop
            }
          }
        }
      });
    });
  }
  auxMatriculaRequadrarPixelar(somId, isCrop, matricula, matriculasImageData) {
    this.imagesService.setMatriculasImage(this.proposta.NUMDENUNCIA, somId, matricula, matriculasImageData).subscribe(() => {
      // Refresquem la imatge
      this.initImage();
      const imageSource = this.imageUrl(somId, 'M');
      const drawAux = SVG.get('#draw_imageDiv_' + somId);
      drawAux.image(imageSource);
      this.marcarMatriculesTrobades();

      if (isCrop) {
        const matriculasCrop: SOM_CARAS[] = matriculasImageData.filter(x => x.matricula === matricula);
        // Tornem a posar brillantor a 1 perquè ja ens l'haurà aplicat al pixelat i així no queda tan clara la imatge.
        matriculasCrop[0].brightness = 1;
        if (matriculasCrop.length === 1) {
          this.cropMatriculaSeleccionada(matriculasCrop);
        }
      }
    });
  }

  /*
    A partir de la informació que ens ha tornat l'OCR, enquadrem les matricules:
      - Vermell: totes les matrícules si encara no en tenim cap d'informada
      - Taronja: aquelles matrícules que no coincideixen amb la que tenim informada
      - Verd: aquella matrícula que coincideix amb la que tenim informada
  */
  marcarMatriculesTrobades() {
    let colorMatricula = 'red';
    let strokeWidth: number;

    const OCRImages = this.proposta.IMATGES.filter(x => x.SUGMATRICULA !== null);
    if (OCRImages.length === 1 && OCRImages[0].NOMBRE_FICHERO === this.name) {
      this.auxMarcarMatricula(colorMatricula, strokeWidth);
    } else if (OCRImages.length === 0) {
      this.auxMarcarMatricula(colorMatricula, strokeWidth);
    }


  }

  auxMarcarMatricula(colorMatricula, strokeWidth) {
    this.proposta.sugMatriculesCoordenades.forEach((sugMatriculaCoordenada: SugerenciaMatricula) => {
      const top = sugMatriculaCoordenada.coordenadas[0];
      const left = sugMatriculaCoordenada.coordenadas[1];
      const width = sugMatriculaCoordenada.coordenadas[2] - sugMatriculaCoordenada.coordenadas[1];
      const height = sugMatriculaCoordenada.coordenadas[3] - sugMatriculaCoordenada.coordenadas[0];

      if (this.proposta.MATRICULA !== undefined && this.proposta.MATRICULA !== null && this.proposta.MATRICULA !== '') {
        if (sugMatriculaCoordenada.matricula === this.proposta.MATRICULA) {
          colorMatricula = 'green';
        } else {
          colorMatricula = 'orange';
        }
      } else {
        colorMatricula = 'red';
      }

      // Si tenim un rectangle d'area petita, li baixem el gruix de la linia
      if ((width * height) < 1500) {
        strokeWidth = 3;
      } else {
        strokeWidth = 4;
      }

      const group = draw.group();
      const polyline = group.polyline([[0, 0], [0, height], [width, height], [width, 0], [0, 0]])
                            .fill('none').stroke({ color: colorMatricula, width: strokeWidth });

      group.move(left, top);
      polyline.scale(1.5); // Afegim marges a les coordenades inicials que ens passa l'OCR

      if (this.proposta.MATRICULA === '' || this.proposta.MATRICULA === null || this.proposta.MATRICULA === undefined) {
        const rect = group.rect(width, height);
        rect.attr({'fill-opacity': '0%', 'style': 'cursor: pointer'});
        rect.scale(1.5);

        rect.on('click', this.rectangleClick.bind(this, sugMatriculaCoordenada.matricula));
        this.sugerenciaMatricula = true;
      } else {
        this.sugerenciaMatricula = false;
      }
    });
    
  }

  /*
    Mètode que fa la crida al back per fer el crop d'una matrícula
  */
  cropMatriculaSeleccionada(matriculasCrop: SOM_CARAS[]) {
    matriculasCrop = this.photoBoxService.margenExtra(matriculasCrop, 10);
    this.imagesService.cropImage(this.somId, matriculasCrop).subscribe( data => {
      this.llistaImatges.push({'imatge': data, 'checked' : true});
      // this.somId = data.SOM_ID;
      // this.name = data.NOMBRE_FICHERO;
      const nouId = this.llistaImatges.findIndex(g => g.imatge.SOM_ID === parseInt(data.SOM_ID, 10));
    });
  }

  /*
    Mètode que cridem en l'acció de click del rectangle transparent que envolta el requadre de la matrícula
  */
  rectangleClick(matricula: string) {
    console.log('rectangleClick matricula: ' + matricula);
    this.matriculesRequadrarPixelar(matricula, true, true);
  }

  //#endregion

  forceDownload() {
    const url = this.imageUrl(this.somId, 'L');
    let name = this.name;
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function(){
        var urlCreator = window.URL;
        var imageUrl = urlCreator.createObjectURL(this.response);
        var tag = document.createElement('a');
        tag.href = imageUrl;
        tag.download = name;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
    }
    xhr.send();
  }
     
}

