<app-toolbar>  
  <app-tool-button text="Actualizar" i18n-text (click)="refresh();" color="has-color-blue" icon="fa-sync"></app-tool-button>
  <app-tool-button text="Filtre" i18n-text (click)="onFilterClicked();" color="has-color-orange" icon="fa-filter"></app-tool-button>
  <app-tool-button text="Excel" i18n-text (click)="generateExcel();" color="has-color-darkgreen" icon="fa-file-excel"></app-tool-button>
  <app-tool-button-permissions [permission]="this.authService.Pages.Reserves"></app-tool-button-permissions>

  <div class="card-body" class="card w-auto mt-2 float-right" style="margin-right: 16px;">
    <h5 class="card-header1 px-4" i18n>Resum reserves {{ this.filter.fechaInicio | shortDate }}</h5>
    <div class="row px-4 d-block" >
        <span i18n class="mr-4">Places ocupades cotxe: </span>
        <span class="float-right">{{ this.resumenEstadoActual.CochesOcupados }}/{{ this.resumenEstadoActual.CochesLibres + this.resumenEstadoActual.CochesOcupados }}</span>
    </div>
    <div class="row px-4 d-block">
        <span i18n class="mr-4">Places ocupades moto: </span>
        <span class="float-right">{{ this.resumenEstadoActual.MotosOcupados }}/{{ this.resumenEstadoActual.MotosLibres + this.resumenEstadoActual.MotosOcupados }}</span>
    </div>
    <div class="row px-4 d-block">
        <span i18n class="mr-4">Places ocupades persones: </span>
        <span class="float-right">{{ this.resumenEstadoActual.PersonasOcupados }}/{{ this.resumenEstadoActual.PersonasLibres + this.resumenEstadoActual.PersonasOcupados }}</span>
    </div>
    <div class="row px-4 d-block">
      <span i18n class="mr-4">Places ocupades persones real: </span>
      <span class="float-right">{{ this.resumenEstadoActual.PersonasRealesOcupados }}/{{ this.resumenEstadoActual.PersonasLibres + this.resumenEstadoActual.PersonasOcupados }}</span>
  </div>
  </div>

</app-toolbar>

<div class="row"><app-filter-selector [filter]="this.filter" exclude="['tipoVehiculo']"></app-filter-selector></div>

<mat-tab-group [selectedIndex]="selected.value" (selectedIndexChange)="this.selected.setValue($event)">
  <mat-tab>
        <ng-template mat-tab-label> 
            <span i18n matBadge="{{this.currentCotxes}}" matBadgeOverlap="false">Cotxes</span>
        </ng-template>
        <app-reserves-cotxes-table #reservesCotxes [sharedFilter]="this.filter" (currentCount)="this.setBadgeCount($event, 2)"></app-reserves-cotxes-table>
  </mat-tab>
  <mat-tab>
        <ng-template mat-tab-label> 
            <span i18n matBadge="{{this.currentMotos}}" matBadgeOverlap="false">Motos</span>
        </ng-template>
        <app-reserves-motos-table #reservesMotos [sharedFilter]="this.filter" (currentCount)="this.setBadgeCount($event, 3)"></app-reserves-motos-table>
  </mat-tab>
  
  <mat-tab>
        <ng-template mat-tab-label> 
            <span i18n matBadge="{{this.currentPersones}}" matBadgeOverlap="false">Persones</span>
        </ng-template>
        <app-reserves-persones-table #reservesPersones [sharedFilter]="this.filter" (currentCount)="this.setBadgeCount($event, 1)"></app-reserves-persones-table>
  </mat-tab>
</mat-tab-group>