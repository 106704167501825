import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { config } from 'path-shared/configuration/config';

@Injectable({
  providedIn: 'root'
})
export class TestService {

  constructor(private http: HttpSecureClient) { }

  getPages(): Observable<any> {
    return this.http.get(config.urls['apiUrl'] + '/pages/77821', null);
  }
}
