import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-dgt-filter',
  templateUrl: './dgt-filter.component.html',
  styleUrls: ['./dgt-filter.component.scss']
})
export class DgtFilterComponent implements OnInit {
  public propietari: boolean;
  public conductor: boolean;
  public filtreSeleccionat: string;
  constructor(
    public dialogRef: MatDialogRef<DgtFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, ) {
      this.conductor = false;
      this.propietari = false;
      if (data !== undefined && data !== null ) {
        this.conductor = data.conductor;
        this.propietari = data.propietari;
      }
      this.filtreSeleccionat = '1';
     }

  ngOnInit() {
    this.filtreSeleccionat = '1';
  }
  filtrar() {
    this.dialogRef.close({filtre: this.filtreSeleccionat});
  }
  cancelar() {
    this.dialogRef.close();
  }
}
