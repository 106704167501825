
import { Dictionary } from 'path-shared/models/dictionary';
import { Component, OnInit, ViewChild, ElementRef, Inject, ViewContainerRef } from '@angular/core';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { FormService } from 'path-shared/services/form/form.service';
import { Builder } from 'path-shared/models/builder';
import { TramosService } from '../../services/tramos.service';
import { Tramo } from '../../models/tramo.model';
import { Instituciones } from 'path-shared/models/instituciones';
import { BehaviorSubject } from 'rxjs';
import { ZonesService } from 'app/sections/ciutat/zones/services/zones.service';
import { ZonesEditarComponent } from 'app/sections/ciutat/zones/components/zones-editar/zones-editar.component';
import { QrViewerComponent } from 'path-shared/components/helpers/qr-viewer/qr-viewer.component';
import { AutocompleteComboComponent } from 'path-shared/components/form/autocomplete-combo/autocomplete-combo.component';
import { TemporadaService } from 'app/sections/zona-blava/estacionament/temporada/services/temporada.service';
declare const google: any;
@Component({
  selector: 'app-tramos-editor',
  templateUrl: './tramos-editor.component.html',
  styleUrls: ['./tramos-editor.component.scss']
})
export class TramosEditorComponent implements OnInit {

  public styleMap = [
    {
      "featureType": "administrative",
      "elementType": "geometry",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "transit",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    }
  ];


  allTramos: Tramo[];


  all_overlays = [];
  selectedShape: any;
  drawingManager: any;
  colorPoligon: BehaviorSubject<string>;
  polygon: any;
  options: any = {
    lat: 33.5362475,
    lng: -111.9267386,
    zoom: 10,
    fillColor: '#DC143C',
    draggable: true,
    editable: true,
    visible: true
  };
  managerOptions = {
    drawingControl: true,
    drawingControlOptions: {
      drawingModes: ['polygon']
    },
    polygonOptions: {
      draggable: true,
      editable: true
    },
    drawingMode: "polygon"
  };


  secure_id: string;
  tramo: Tramo;
  dialogData: ComponentDialog;
  closeButtonText: string;
  public currentLocation: {
    latitude: number,
    longitude: number
  };

  returnObject: boolean;
  tiposTramosDictionary: Dictionary[];
  valorAutoCompleteTiposTramos;
  tipoTramoList = [];

  temporadasDictionary: Dictionary[];
  valorAutoCompleteTemporadas;
  temporadasList = [];

  dictColors: Dictionary[];
  @ViewChild('comboTipoTramo') comboTipoTramo: AutocompleteComboComponent;
  @ViewChild('comboTemporada') comboTemporada: AutocompleteComboComponent;

  @ViewChild('mainForm') private mainForm: ElementRef;
  constructor(
    private dialog: MatDialog,
    private confirmationDialogService: ConfirmationDialogService,
    public auth: AuthorizationService,
    private tramosService: TramosService,
    private i18n: I18n,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    public thisDialogRef: MatDialogRef<TramosEditorComponent>,
    private formService: FormService,
    public vcRef: ViewContainerRef,
    //private tramosTiposService: TramosTiposService,
    private zonesServices: ZonesService,
    private temporadasService: TemporadaService,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
  ) {
    const inst = sessionStorage.getItem('currentInst');
    if (inst !== '' && inst !== undefined && inst !== 'undefined') {
      const institution: Instituciones = JSON.parse(inst);
      this.currentLocation = {latitude: institution.Latitud, longitude: institution.Longitud};
    }
    this.valorAutoCompleteTiposTramos = '';
    this.valorAutoCompleteTemporadas= '';
    this.tramo = Builder.newTramo();
    const info: any = data;
    this.returnObject = false;
    if (info.isMaquina !== undefined && info.isMaquina !== null) {
      this.dialogData = info.data;
      this.returnObject = info.isMaquina;
    } else {
      this.dialogData = data;
    }

    this.secure_id = this.dialogData.id;
    if (this.dialogData.isReadOnly) {
      this.closeButtonText = this.i18n('Tancar');
    } else {
      this.closeButtonText = this.i18n('Llista');
    }
    this.colorPoligon = new BehaviorSubject<string>('#DC143C');
  }

  ngOnInit() {
    if (this.secure_id !== null) {
      this.tramosService.getTramo(this.secure_id).subscribe(data => {
        this.tramo = data;
        this.loadTipoTramos();
        this.loadTodosTramos();
      });
    }else{
      this.loadTipoTramos();
      this.loadTodosTramos();
    }
  }
  
  loadTodosTramos(){
    if(this.tramo !== undefined && this.tramo !== null && this.tramo.secureIdTemporada !== undefined &&  this.tramo.secureIdTemporada !== null && this.tramo.secureIdTemporada !== '' ){
      this.tramosService.getAllTramosByTemporada(this.tramo.secureIdTemporada).subscribe( data =>{
        let tempTramos: Tramo[] = data;
        if ( this.tramo.secureId !== undefined && this.tramo.secureId !== null) {
          const index: number = tempTramos.findIndex(x => x.secureId === this.tramo.secureId  );
          if (index !== -1) {
              tempTramos.splice(index, 1);
            }
            tempTramos.forEach(element => {
                element.strokeColor ='#000000';
            });
          this.allTramos =  Object.assign([], tempTramos);
        }else{
          this.allTramos =  Object.assign([], tempTramos);
        }
      });
    }
  }
  loadTipoTramos(setDefaultValue: boolean = true){
      this.zonesServices.getAllZonas().subscribe(x =>{
        this.tiposTramosDictionary = new Array<Dictionary>();
        this.dictColors = new Array<Dictionary>();
        this.tipoTramoList = x;
        x.forEach(element => {
          this.tiposTramosDictionary.push(new Dictionary(element.SECURE_ID.toString(), element.descripcio));
          this.dictColors.push(new Dictionary(element.SECURE_ID.toString(), element.color));
        });
        if (setDefaultValue) {
          this.setDefaultValueTipoTramo();
        }else{
          this.tipoTramoSeleccionado(this.tramo.secureIdZona);
        }
        this.loadTemporadas();
      });
  }

  loadTemporadas(){
    this.temporadasService.GetAllForCombo().subscribe(result =>{
      if (result !== null) {
        this.temporadasList = result;
        this.temporadasDictionary = new Array<Dictionary>();
        result.forEach(element => {
          if(element.secureId !== undefined && element.secureId !== null &&
            element.descripcion !== undefined && element.descripcion !== null ){
              if(this.tramo.secureIdZona !==undefined && this.tramo.secureIdZona !==null && this.tramo.secureIdZona !== '' ){
                const selectedZona = this.tipoTramoList.find(y => y.SECURE_ID === this.tramo.secureIdZona);
                if(selectedZona !== undefined && selectedZona !== null){
                  selectedZona.temporada.forEach(elementTemporadas => {
                    if(elementTemporadas.secureId === element.secureId){
                      this.temporadasDictionary.push(new Dictionary( element.secureId, element.descripcion));
                    }
                  });
                }
              }
              
            }
        });
        if(this.tramo.secureIdTemporada !== null && this.tramo.secureIdTemporada  !== undefined ){
            this.temporadaSeleccionado(this.tramo.secureIdTemporada);
            const temporadaSelcted = this.temporadasDictionary.find(x => x.key === this.tramo.secureIdTemporada.toString());
            this.comboTemporada.setForcedValueComb(temporadaSelcted.value);
        }
      }
    });
  }
  setDefaultValueTipoTramo() {

    if(this.tramo.secureIdZona !== undefined && this.tramo.secureIdZona !== null && this.tramo.secureIdZona !== ''
     && this.tiposTramosDictionary.length > 0 ) {
      const tipoTramoSelcted = this.tiposTramosDictionary.find(x => x.key === this.tramo.secureIdZona.toString());
      this.comboTipoTramo.setForcedValueComb(tipoTramoSelcted.value);
      if(tipoTramoSelcted !== undefined && tipoTramoSelcted !== null){
        const colorValue= this.tipoTramoList.find(y => y.SECURE_ID.toString() === this.tramo.secureIdZona ).color;
        this.colorPoligon = new BehaviorSubject<string>('');
        this.colorPoligon.next(colorValue);
        if (this.selectedShape!== undefined && this.selectedShape !== null) {
          this.selectedShape.set('strokeColor', colorValue);
          this.selectedShape.set('fillColor', colorValue);
          var polylineOptions = this.drawingManager.get('polygonOptions');
          polylineOptions.strokeColor = this.getColor();
          this.drawingManager.set('polygonOptions', polylineOptions);
        }
      }
    }
  }

  save() {
    if (this.formService.allFieldsValid(this.mainForm) && !this.checkCoverOtherTramos()) {

      this.tramosService.setTramo(this.tramo).subscribe(result => {
        if(result === undefined || result === null ){
          this.toastr.info(this.i18n('Ocupació guardada correctament'));
          if (this.returnObject) {
            this.thisDialogRef.close(this.tramo);
          } else {
            this.thisDialogRef.close(true);
          }
        }else{
          this.toastr.warning(this.i18n('Error, hi han poligons que s\'estan solapant!'));
          this.allTramos.forEach(element => {
            if(element.Som_id === result.Som_id){
              element.strokeColor = '#DC143C';
            }else{
              element.strokeColor ='#000000';
            }
          });
        }
      });
    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
    }
  }
  checkCoverOtherTramos(): boolean{
    let result = false;
    
    return result;
  }
  cancel() {
    this.thisDialogRef.close(false);
  }

  remove() {
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol esborrar el tipus?'))
    .then((confirmed) => {
        if (confirmed) {
          this.tramosService.removeTramo(this.secure_id).subscribe(result => {
            this.toastr.success(this.i18n('Ocupació esborrada correctament'));
            this.dialog.closeAll();
            this.thisDialogRef.close(true);
          });
        }
      });
  }
  openQr(){
    let xmlData='';
    let tramoqr = Builder.newTramoQr();
    tramoqr.PoligonoSomId = this.tramo.Som_id.toString();
    tramoqr.ZonaSomId = this.tramo.zona.SOM_ID.toString();
   // xmlData='<temporada>'+this.tramo.temporada.Som_id+'</temporada>'+'<zona>'+this.tramo.zona.SOM_ID+'</zona>'+'<poligon>'+this.tramo.Som_id+'</poligon>';
   xmlData = JSON.stringify(tramoqr); 
    this.dialog.open(QrViewerComponent,
      {
        minWidth: '30%',
        maxWidth: '90%',
        maxHeight: '70%',
        closeOnNavigation: false,
        autoFocus: true,
        data: {'qrData':xmlData ,'fileName': this.tramo.descripcion, 'isDialog': true},
      });
  }
  temporadaSeleccionado(idTemporada: string) {
    this.tramo.secureIdTemporada = idTemporada;
    this.loadTodosTramos();
  }
  tipoTramoSeleccionado(idTipoTramo: string) {
    this.tramo.secureIdZona = idTipoTramo;
    if (idTipoTramo !== undefined && idTipoTramo !== null && idTipoTramo !== ''){
      const colorValue = this.tipoTramoList.find(y => y.SECURE_ID.toString() === this.tramo.secureIdZona ).color;
      this.tramo.zona = this.tipoTramoList.find(y => y.SECURE_ID.toString() === this.tramo.secureIdZona );
        this.colorPoligon = new BehaviorSubject<string>('');
        this.colorPoligon.next(colorValue);
        if (this.selectedShape!== undefined && this.selectedShape !== null) {
          this.selectedShape.set('strokeColor', colorValue);
          this.selectedShape.set('fillColor', colorValue);
          var polylineOptions = this.drawingManager.get('polygonOptions');
          polylineOptions.strokeColor = this.getColor();
          this.drawingManager.set('polygonOptions', polylineOptions);
        }
        this.tramo.secureIdTemporada = null;
        this.comboTemporada.resetCombo();
        this.loadTemporadas();
    }
  }
  deleteSelectedShape() {
    if (this.selectedShape !== undefined && this.selectedShape !== null) {
      this.selectedShape.setMap(null);
      this.selectedShape = null;
      var polylineOptions = this.drawingManager.get('polygonOptions');
      polylineOptions.strokeColor = this.getColor();
      this.drawingManager.set('polygonOptions', polylineOptions);
      this.drawingManager.setDrawingMode('polygon');
      
    }
  }
  onMapReady(map) {
    this.initDrawingManager(map);
  }

  initDrawingManager(map: any) {
    
    let self= this;
    const options = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: ["polygon"]
      },
      polygonOptions: {
        draggable: true,
        editable: true,
        strokeColor: self.getColor(),
        fillColor : self.getColor()
      },
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
    };
    this.drawingManager = new google.maps.drawing.DrawingManager(options);
    this.drawingManager.setMap(map);

    if (self.secure_id !== null) {
      this.tramosService.getTramo(self.secure_id).subscribe(data => {
        self.tramo = data;
        if(self.tramo !== undefined && self.tramo !== null && self.tramo.poligon !== undefined && self.tramo.poligon !== null){

          this.drawingManager.setDrawingMode(null);

          var poligonoInicial = new google.maps.Polygon({
            paths: self.tramo.poligon,
            draggable: true,
            editable: true,
            strokeColor: self.getColor(),
            fillColor : self.getColor()
          });
          if(self.tramo.initialPosition !== undefined && self.tramo.initialPosition !== null){
            self.currentLocation.latitude = self.tramo.initialPosition.lat;
            self.currentLocation.longitude = self.tramo.initialPosition.lng;
          }
          poligonoInicial.setMap(map);
          var newShape = poligonoInicial;
          newShape.type = poligonoInicial.type;
          self.selectedShape = newShape;
          google.maps.event.addListener(poligonoInicial, 'dragend', function() {
            const len = poligonoInicial.getPath().getLength();
            const polyArrayLatLng = [];
            for (let i = 0; i < len; i++) {
                const vertex = poligonoInicial.getPath().getAt(i);
                const vertexLatLng = { lat: vertex.lat(), lng: vertex.lng() };
                polyArrayLatLng.push(vertexLatLng);
            }
            self.tramo.poligon =  polyArrayLatLng;
          });
          google.maps.event.addListener(poligonoInicial, 'click', function() {
            const len = poligonoInicial.getPath().getLength();
            const polyArrayLatLng = [];
            for (let i = 0; i < len; i++) {
                const vertex = poligonoInicial.getPath().getAt(i);
                const vertexLatLng = { lat: vertex.lat(), lng: vertex.lng() };
                polyArrayLatLng.push(vertexLatLng);
            }
            self.tramo.poligon =  polyArrayLatLng;
          });
          google.maps.event.addListener(poligonoInicial.getPath(), 'set_at', function(){
            const len = poligonoInicial.getPath().getLength();
            const polyArrayLatLng = [];
            for (let i = 0; i < len; i++) {
                const vertex = poligonoInicial.getPath().getAt(i);
                const vertexLatLng = { lat: vertex.lat(), lng: vertex.lng() };
                polyArrayLatLng.push(vertexLatLng);
            }
            self.tramo.poligon =  polyArrayLatLng;
          });

          google.maps.event.addListener(poligonoInicial.getPath(), 'polygonComplete', function () {
            const len = poligonoInicial.getPath().getLength();
            const polyArrayLatLng = [];
            for (let i = 0; i < len; i++) {
                const vertex = poligonoInicial.getPath().getAt(i);
                const vertexLatLng = { lat: vertex.lat(), lng: vertex.lng() };
                polyArrayLatLng.push(vertexLatLng);
            }
            self.tramo.poligon =  polyArrayLatLng;
        });
        const paths = poligonoInicial.getPaths();
        for (let p = 0; p < paths.getLength(); p++) {
          google.maps.event.addListener(
            paths.getAt(p),
            'set_at',
            () => {
              if (!poligonoInicial.drag) {
                const len = poligonoInicial.getPath().getLength();
                const polyArrayLatLng = [];
                for (let i = 0; i < len; i++) {
                    const vertex = poligonoInicial.getPath().getAt(i);
                    const vertexLatLng = { lat: vertex.lat(), lng: vertex.lng() };
                    polyArrayLatLng.push(vertexLatLng);
                }
                self.tramo.poligon =  polyArrayLatLng;
              }
            }
          );
          google.maps.event.addListener(
            paths.getAt(p),
            'insert_at',
            () => {
            const len = poligonoInicial.getPath().getLength();
            const polyArrayLatLng = [];
            for (let i = 0; i < len; i++) {
                const vertex = poligonoInicial.getPath().getAt(i);
                const vertexLatLng = { lat: vertex.lat(), lng: vertex.lng() };
                polyArrayLatLng.push(vertexLatLng);
            }
            self.tramo.poligon =  polyArrayLatLng;
            }
          );
          google.maps.event.addListener(
            paths.getAt(p),
            'remove_at',
            () => {
            const len = poligonoInicial.getPath().getLength();
            const polyArrayLatLng = [];
            for (let i = 0; i < len; i++) {
                const vertex = poligonoInicial.getPath().getAt(i);
                const vertexLatLng = { lat: vertex.lat(), lng: vertex.lng() };
                polyArrayLatLng.push(vertexLatLng);
            }
            self.tramo.poligon =  polyArrayLatLng;
            }
          );
        }
        }
      });
    }
    google.maps.event.addListener(this.drawingManager, 'overlaycomplete', function(e) {
      if (e !== undefined) {
          if (e.type === google.maps.drawing.OverlayType.POLYGON) {
            var newShape = e.overlay;
            newShape.type = e.type;
            newShape.set('strokeColor', self.getColor());
            newShape.set('fillColor', self.getColor());
            self.selectedShape = newShape;
            self.drawingManager.setDrawingMode(null);
              // var paths = e.overlay.getPaths();
              // var paths2 = new google.maps.Data.Polygon([e.overlay.getPath().getArray()]);
              const len = e.overlay.getPath().getLength();
              const polyArrayLatLng = [];
              for (let i = 0; i < len; i++) {
                  const vertex = e.overlay.getPath().getAt(i);
                  const vertexLatLng = { lat: vertex.lat(), lng: vertex.lng() };
                  polyArrayLatLng.push(vertexLatLng);
              }
              self.tramo.poligon =  polyArrayLatLng;
              google.maps.event.addListener(e.overlay, 'dragend', function() {
              self.drawingManager.setDrawingMode(null);
                const len = e.overlay.getPath().getLength();
                const polyArrayLatLng = [];
                for (let i = 0; i < len; i++) {
                    const vertex = e.overlay.getPath().getAt(i);
                    const vertexLatLng = { lat: vertex.lat(), lng: vertex.lng() };
                    polyArrayLatLng.push(vertexLatLng);
                }
                self.tramo.poligon =  polyArrayLatLng;
              });

              google.maps.event.addListener(e.overlay, 'click', function() {
              self.drawingManager.setDrawingMode(null);
                const len = e.overlay.getPath().getLength();
                const polyArrayLatLng = [];
                for (let i = 0; i < len; i++) {
                    const vertex = e.overlay.getPath().getAt(i);
                    const vertexLatLng = { lat: vertex.lat(), lng: vertex.lng() };
                    polyArrayLatLng.push(vertexLatLng);
                }
                self.tramo.poligon =  polyArrayLatLng;
              });

              google.maps.event.addListener(e.overlay.getPath(), 'set_at', function(){
              self.drawingManager.setDrawingMode(null);
                const len = e.overlay.getPath().getLength();
                const polyArrayLatLng = [];
                for (let i = 0; i < len; i++) {
                    const vertex = e.overlay.getPath().getAt(i);
                    const vertexLatLng = { lat: vertex.lat(), lng: vertex.lng() };
                    polyArrayLatLng.push(vertexLatLng);
                }
                self.tramo.poligon =  polyArrayLatLng;
              });

              const paths = e.overlay.getPaths();
        for (let p = 0; p < paths.getLength(); p++) {
          google.maps.event.addListener(
            paths.getAt(p),
            'set_at',
            () => {
              if (!e.overlay.drag) {
                const len = e.overlay.getPath().getLength();
                const polyArrayLatLng = [];
                for (let i = 0; i < len; i++) {
                    const vertex = e.overlay.getPath().getAt(i);
                    const vertexLatLng = { lat: vertex.lat(), lng: vertex.lng() };
                    polyArrayLatLng.push(vertexLatLng);
                }
                self.tramo.poligon =  polyArrayLatLng;
              }
            }
          );
          google.maps.event.addListener(
            paths.getAt(p),
            'insert_at',
            () => {
            const len = e.overlay.getPath().getLength();
            const polyArrayLatLng = [];
            for (let i = 0; i < len; i++) {
                const vertex = e.overlay.getPath().getAt(i);
                const vertexLatLng = { lat: vertex.lat(), lng: vertex.lng() };
                polyArrayLatLng.push(vertexLatLng);
            }
            self.tramo.poligon =  polyArrayLatLng;
            }
          );
          google.maps.event.addListener(
            paths.getAt(p),
            'remove_at',
            () => {
            const len = e.overlay.getPath().getLength();
            const polyArrayLatLng = [];
            for (let i = 0; i < len; i++) {
                const vertex = e.overlay.getPath().getAt(i);
                const vertexLatLng = { lat: vertex.lat(), lng: vertex.lng() };
                polyArrayLatLng.push(vertexLatLng);
            }
            self.tramo.poligon =  polyArrayLatLng;
            }
          );
        }
        }
      }
    });
  }


 getColor() {
    return this.colorPoligon.value;
  }

  viewZona(){
    const data: ComponentDialog = Builder.newComponentDialog(true, false, this.tramo.secureIdZona);
    const ref = this.dialog.open(ZonesEditarComponent, {
      width: '90%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'data': data
      }
    });
    ref.afterClosed().subscribe(res => {
      if (res !== undefined && res !== null && res === true) {
        this.loadTipoTramos(false);
      }
    });
  }
}
