import { TemporadaService } from './../../services/temporada.service';
import { TemporadaDto } from './../../models/temporadaDto';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';

import { FilterService } from 'path-shared/services/filter/filter.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { Builder } from 'path-shared/models/builder';
import { TemporadaFilter } from '../../models/temporada.filter';
import { TemporadaFilterService } from '../../services/temporada-filter.service';

@Component({
  selector: 'app-temporadas-table',
  templateUrl: './temporadas-table.component.html',
  styleUrls: ['./temporadas-table.component.scss']
})
export class TemporadasTableComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  data: TemporadaDto[];
  dataSource: CustomDataSource<TemporadaDto, TemporadaFilter>;
  filter: TemporadaFilter = new TemporadaFilter('id', 'asc');
  displayedItems = [];
  highlightedRows = [];

  @Input() selectButton: boolean;
  @Input() updatedFilterData: TemporadaFilter;
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();
  @Output() selectedTemporada: EventEmitter<string> = new EventEmitter<string>();
  @Output() itemClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() itemSelected: EventEmitter<string> = new EventEmitter<string>();

  displayedColumns = ['incorpora','Som_id', 'descripcion', 'ANO_MES_DIA_INICIO', 'ANO_MES_DIA_FIN', 'ACTIVO' ];
  selectedSecureId: string;
  constructor(  private temporadasService: TemporadaService,
    private filterService: FilterService,
    private dialog: MatDialog,
    private i18n: I18n) {
      this.selectedSecureId='';
    }

    ngOnInit() {
      this.sort.disableClear = true;
      this.dataSource = CustomDataSource.create<TemporadaDto, TemporadaFilter>(this.paginator, new TemporadaFilterService(), 'urlGetTemporadas', 'urlGetTemporadasCount');
      this.dataSource.subject.subscribe((data) => {
        data.forEach(item => {
          this.displayedItems[item.secureId] = {
            id: item.secureId
          };
        });
      });
      this.filter.filterUpdated.subscribe(updated => {
        if (updated) {
          this.load();
          if (!this.selectButton) { this.filterService.saveFilter('temporadas-filter', this.filter); }
        }
      });

    if (!this.selectButton) { this.filterService.loadFilter('temporadas-filter', this.filter); }
      this.filter.paginator.init(this.sort, this.paginator);
    }

    ngAfterViewInit() {
      // reset the paginator after sorting
      this.sort.sortChange.subscribe(() => {
        this.paginator.pageIndex = 0;
        this.filter.paginator.set(this.sort, this.paginator);
        this.filter.updated();
      });

      merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.filter.updated()))
      .subscribe();

      this.load();
    }
    ngOnChanges(changes: SimpleChanges) {
      if (this.updatedFilterData != null) {
        this.update();
      }
    }

    update() {
      if (this.dataSource !== undefined) {
        this.updatedFilterData.paginator.set(this.sort, this.paginator);
        this.dataSource.loadFilter(this.updatedFilterData);
      }
    }

    load() {
      this.filter.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.filter);
    }

    onRowClicked(row) {
      this.itemClicked.next(row.secureId);
    }

    generateExcel(): void {
      let filter = this.filter;
      if (this.updatedFilterData != null) {
        filter = this.updatedFilterData;
      }
      this.dialog.open(DownloadExcelDialogComponent, {
          closeOnNavigation: false,
          autoFocus: true,
          data: Builder.newDownloadExcelDialogData (this.temporadasService, filter, this.i18n('Temporades'), this.sort.direction, this.sort.active)
      });
    }

    ClickCheckbox(row: TemporadaDto) {
      if ( this.selectedSecureId === row.secureId) {
        this.selectedSecureId = '';
      } else {
        this.selectedSecureId = row.secureId;
      }
      this.selectedTemporada.next(this.selectedSecureId);
    }


}
