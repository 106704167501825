<app-filter-header title="Filtrar propostes" i18n-title  (apply)="this.apply()" (reset)="this.reset()" (dismiss)="this.dismiss()"></app-filter-header>
<div class="modal-body">
  <div class="row">
    <app-form-combo-edit label="Estat" i18n-label class="col-sm-4" [items]="this.estados" [(value)]="filter.estat" textField="text" valueField="value"></app-form-combo-edit>
  </div>
  <div class="row">
    <app-form-date-edit id="propostes-filter-dataIni" class="col-sm-4" i18n-label label="Data Inicial" id="fechaInfraccionInicio" [(value)]="this.filter.fechaInicioDisplay" type="calendar" ></app-form-date-edit>
    <app-form-date-edit id="propostes-filter-dataFi" class="col-sm-4" i18n-label label="Data Final" id="fechaInfraccionInicio" [(value)]="this.filter.fechaFinDisplay" type="calendar" ></app-form-date-edit>
  </div>
</div>
