import { Component, OnInit, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { Notificacio } from '../models/notificacio.model';
import { NotificacionsFilter } from '../models/notificacions-filter';
import { NotificacionsFilterService } from '../services/notificacions-filter.service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NotificacionsEditorComponent } from './notificacions-editor/notificacions-editor.component';
import { NotificacionsFilterComponent } from './notificacions-filter/notificacions-filter.component';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { DenunciesImagesComponent } from 'app/sections/denuncies/components/denuncies-editor/components/denuncies-images/denuncies-images.component';

@Component({
  selector: 'app-notificacions',
  templateUrl: './notificacions.component.html'
})
export class NotificacionsComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource: CustomDataSource<Notificacio, NotificacionsFilter>;
  filter: NotificacionsFilter = new NotificacionsFilter('element', 'asc');
  state: any;
  displayedColumns = ['element', 'nom', 'acceptacio', 'poblacio', 'cp', 'dataResp', 'resultat'];
  constructor(
    private dialog: MatDialog,
    public auth: AuthorizationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if(data !== undefined && data !== null){
      if(data.element !== undefined && data.element !== null){
        this.filter.element = data.element;
      }
    }
    
   }

  ngOnInit() {
    
      this.sort.disableClear = true;
      this.dataSource = CustomDataSource.create<Notificacio, NotificacionsFilter>(this.paginator, new NotificacionsFilterService(), 'urlGetNotificacions', 'urlGetNotificacionsCount');
      this.filter.paginator.init(this.sort, this.paginator);

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
      }
    });
    


    
  }

  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(tap(() => this.filter.updated()))
    .subscribe();

    this.load();
  }

  load() {
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }

  onRowClicked(row: Notificacio) {

    const DialogRef = this.dialog.open(NotificacionsEditorComponent, {
      width: '45%',
      height: '80%',
      closeOnNavigation: false,
      autoFocus: true,
      data: row
    });
  }

  onFilterClicked() {
    const DialogRef = this.dialog.open(NotificacionsFilterComponent, {
      width: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      data: this.filter
    });

    DialogRef.componentInstance.notify.subscribe((filter: NotificacionsFilter) => {
      this.filter.set(filter);
      this.load();
    });
  }

  setFilter(tipus) {
    if (tipus === true) {
      this.filter.tipus = 'SICER';
    } else if (tipus === false) {
      this.filter.tipus = 'DEV';
    } else {
      this.filter.tipus = null;
    }
    this.filter.updated(true);
  }

}
