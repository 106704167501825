<app-dialog-header label="Matrícula especial" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Editar" i18n-text (click)="this.dialogData.isReadOnly = false" color="has-color-green" icon="fa-edit" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Matricules) || !this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Guardar" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Matricules) ||this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Eliminar" i18n-text (click)="this.remove();" color="has-color-red" icon="fa-trash-alt" [disabled]="!this.auth.UserCanDelete(this.auth.Pages.Matricules) || this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button [text]="this.closeButtonText" i18n-text (click)="this.cancel()" color="has-color-yellow" icon="fa-th-list"></app-tool-button>
  <app-tool-button text="Impressió" i18n-text (click)="this.impressio();" color="has-color-blue" icon="fa-print" *ngIf="reports.length>0 && this.secure_id !== null"></app-tool-button>
  
</app-toolbar>
<app-dialog-body>
  <form #mainForm>
    <div class="row">
      <app-form-text-edit id="matricules-editor-matricula" class="col-sm-4" i18n-label label="Matrícula" [(value)]="this.matricula.matricula" required matricula maxLength="15"></app-form-text-edit>
      <app-form-combo-edit class="col-sm-6" i18n-label label="Tipus" [items]="this.tipusMatricules" [(value)]="this.matricula.tipus" valueField="codi" textField="descripcio" required></app-form-combo-edit>
    </div>
    <div class="row">
      <app-form-combo-edit class="col-sm-4" i18n-label label="Marca" [items]="this.marques" [(value)]="this.matricula.marca" valueField="descripcio" textField="descripcio"></app-form-combo-edit>
      <app-form-combo-edit class="col-sm-4" i18n-label label="Model" [items]="this.models()" [(value)]="this.matricula.model" valueField="nombre" textField="nombre" [isReadOnly]="this.matricula.marca===null"></app-form-combo-edit>

    </div>
    <div class="row">
      <app-form-date-edit id="matricules-editor-dataIni" class="col-sm-4" i18n-label label="Data Inici" [(value)]="this.matricula.dataInici" type="calendar" required></app-form-date-edit>
      <app-form-date-edit id="matricules-editor-dataFi" class="col-sm-4" i18n-label label="Data Fi" [(value)]="this.matricula.dataFi" type="calendar" [hidden]="this.noFinalDate"></app-form-date-edit>
      <app-form-check-edit class="col-sm-4" i18n-label label="Sense data final" [(value)]="this.noFinalDate" (valueChange)="noFinalCheckChangd($event)"></app-form-check-edit>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <mat-form-field>
          <input type="time" matInput i18n-placeholder placeholder="Hora" [value]="this.matricula.hora" (input)="this.matricula.hora=$event.target.value" required>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <app-form-text-edit id="matricules-editor-observacions" class="col-sm" i18n-label label="Observacions" [(value)]="this.matricula.observacions"  maxLength="250"></app-form-text-edit>
    </div>
  </form>
</app-dialog-body>
