<app-dialog-header label="Canvis de versió" i18n-label></app-dialog-header>
<app-dialog-body>
    <div class="released-notes">
        <div *ngFor="let releasedNote of releasedNotesList;" class="border fondo" style="padding: 1vh;">
            <div class="d-flex justify-content-between" style="padding:1vh;">
                <h5>{{this.releasedNote.FechaHora | longDate}}</h5>
                <a class="navbar-brand" href="#">
                    <img class="navbar-brand-full row" style="padding-top: -10xp;" src="assets/images/logo.png" width="140"  alt="SomDenWeb">
                    <span class="version-text row" style="float: right; padding: 0.25vh;"> {{this.releasedNote.Version}} </span>
                </a>
                
            </div>  
            <p style="padding:0vh 01vh 1vh 1vh;" innerHTML="{{this.releasedNote.Descripcion}}"></p>
        </div>
    </div>
    <div class="d-flex justify-content-between" style="padding:1vh;">
        <app-form-check-edit label="No tornar a mostrar" i18n-label [(value)]="this.leido"></app-form-check-edit>
        <button type="button" class="col-1 btn btn-primary pull-right" (click)="Close()" i18n>D'acord</button>
    </div>
</app-dialog-body>
