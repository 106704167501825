<div class="row justify-content-between">
  <div class="col">
      <app-filter-selector [filter]="this.filter"></app-filter-selector>
  </div>
  <div class="col">
      <mat-paginator #paginator [pageIndex]="0" [pageSize]="this.filter.paginator.pageSize" [pageSizeOptions]="[20, 40, 60, 100]">
      </mat-paginator>
  </div>
</div>

<div class="table-sdw is-fixed">

  <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements"  class="is-header-sticky">

    
        <ng-container matColumnDef="incorpora" *ngIf="this.selectButton">
            <mat-header-cell *matHeaderCellDef title="Incorporar" i18n-title class="text-center"><i class="fa fa-plus-square has-color-grey"></i></mat-header-cell>
            <mat-cell *matCellDef="let row" class="text-center"><a (click)="this.selectCurrent(row)" title="Incorporar" i18n-title><i class="fa fa-plus-square has-color-aqua"></i></a></mat-cell>
        </ng-container>

        <!-- codi Column -->
        <ng-container matColumnDef="codi">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Codi</mat-header-cell>
            <mat-cell *matCellDef="let row"><a (click)="onRowClicked(row)" class="is-link cursor detalle">{{row.codi}}</a></mat-cell>
        </ng-container>

        <!-- dataPropostaPda Column -->
        <ng-container matColumnDef="dataPropostaPda">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data proposta PDA</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.dataPropostaPda | shortDate }}</mat-cell>
        </ng-container>

        <!-- data Column -->
        <ng-container matColumnDef="data">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.data | shortDate }}</mat-cell>
            </ng-container>

            <!-- fechaAcepDeneg Column -->
        <ng-container matColumnDef="fechaAcepDeneg">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data reso.</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.fechaAcepDeneg | shortDate }}</mat-cell>
        </ng-container>
        
        <!-- motiu finalitzacio Column -->
        <ng-container matColumnDef="motiuFinalitzacio">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Motiu finalització</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.motiuFinalitzacio }}</mat-cell>
        </ng-container>

        <!-- dataFinalitzacio Column -->
        <ng-container matColumnDef="dataFinalitzacio">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data finalització</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.dataFinalitzacio | shortDate }}</mat-cell>
        </ng-container>

        <!-- idVehiclesFK Column -->
        <ng-container matColumnDef="idVehiclesFK">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Vehicle</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.idVehiclesFK}}</mat-cell>
        </ng-container>

        <!-- idAgentFk Column -->
        <ng-container matColumnDef="idAgentFk">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Agent</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.idAgentFk}}</mat-cell>
        </ng-container>

        <!-- idCalleFK Column -->
        <!-- <ng-container matColumnDef="idCalleFK">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Carrer(fk)</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.idCalleFK}}</mat-cell>
        </ng-container>-->

        <!-- numCarrer Column -->
        <ng-container matColumnDef="numCarrer">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Núm carrer</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.numCarrer}}</mat-cell>
        </ng-container>

        <!-- nombreCalle Column -->
        <ng-container matColumnDef="nombreCalle">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Nom carrer</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.nombreCalle}}</mat-cell>
        </ng-container>

        <!-- valida Column -->
        <ng-container matColumnDef="valida">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Vàlida</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <i *ngIf="row.valida" [class]="'fas fa-check-circle has-color-green'" ></i>
                <i *ngIf="!row.valida" [class]="'far fa-times-circle has-color-red'" ></i>
                <!--<mat-checkbox [checked]="row.valida" [disabled]='true'></mat-checkbox>-->
            </mat-cell>
        </ng-container>

        <!-- valida Column -->
        <ng-container matColumnDef="implicaDenuncia">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Implica denúncia</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <mat-checkbox [checked]="row.implicaDenuncia" [disabled]='true'></mat-checkbox>
            </mat-cell>
        </ng-container>
        
        <!-- observacions Column -->
        <ng-container matColumnDef="observacions">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Observacions</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.observacions}}</mat-cell>
        </ng-container>

        <!-- idInfraccioFK Column -->
        <ng-container matColumnDef="idInfraccioFK">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n> Infracció</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.infraccion?.descCurta}}</mat-cell>
        </ng-container>

        <!-- numFotosPda column -->
        <ng-container matColumnDef="numFotos">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Núm fotos pda</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.numFotos}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="numPDA">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Núm PDA</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.numPDA}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="origen">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Origen</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.origen}}</mat-cell>
        </ng-container>
    

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
  </mat-table>
</div>
