import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { GruaMotiusDesestimarFilter } from '../models/grua-motius-desestimar-filter';
import { MotiusDestimacioGrua } from 'app/sections/grua/Models/motius-desestimacio.model';
import { GruaMotiusDesestimarFilterService } from './grua-motius-desestimar-filter.service';

const defaultSortParam = 'motiu';
const defaultSortDirection = 'asc';

@Injectable()
export class GruaMotiusDesestimarService {
  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {}

  setGruaMotiusDesestimar(gruaMotiusDesestimar: MotiusDestimacioGrua): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetGruaMotiusDesestimar'], gruaMotiusDesestimar);
    }
  }

  removeGruaMotiusDesestimar(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteGruaMotiusDesestimar'].replace('{secure_id}', secure_id), null);
    }
  }

  getGruaMotiusDesestimar(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetGruaMotiuDesestimar'].replace('{secure_id}', secure_id), null);
    }
  }

  generateExcel(filter: GruaMotiusDesestimarFilter, sortDir: string, sortField: string): Observable<any> {
    const start = 0;
    const pageSize = 1000;

    sortField = sortField === undefined ? defaultSortParam : sortField;
    sortDir = sortDir === '' ? defaultSortDirection : sortDir;
    const params = new GruaMotiusDesestimarFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

    if (this.authGuard.canActivate()) {
      return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlGruaMotiusDesestimarExcel'],  params );
    }
  }
}
