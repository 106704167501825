import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { GruaMotiusEntradaTableComponent } from './components/grua-motius-entrada-table/grua-motius-entrada-table.component';
import { GruaMotiusEntradaFilter } from './models/grua-motius-entrada-filter';
import { GruaMotiusEntradaEditorComponent } from './components/grua-motius-entrada-editor/grua-motius-entrada-editor.component';
import { GruaMotiusEntradaFilterComponent } from './components/grua-motius-entrada-filter/grua-motius-entrada-filter.component';

@Component({
    selector: 'app-grua-motius-entrada',
    templateUrl: 'grua-motius-entrada.component.html',
    styleUrls: ['grua-motius-entrada.component.scss']
})
export class GruaMotiusEntradaComponent implements OnInit, AfterViewInit {
    filterData: GruaMotiusEntradaFilter;
    @ViewChild(GruaMotiusEntradaTableComponent) gruaMotiusEntradaTable: GruaMotiusEntradaTableComponent;

    constructor(
      private dialog: MatDialog,
      public auth: AuthorizationService,
      private i18n: I18n,
      private not: NotificationService
      ) {
        this.filterData = new GruaMotiusEntradaFilter('descripcio', 'asc');
      }

    ngOnInit(): void {
      this.auth.UserCanSeePage(this.auth.Pages.GruaMotiusEntrada);
      this.gruaMotiusEntradaTable.itemClicked.subscribe(secure_id => {
        this.update(secure_id);
      });
    }

    ngAfterViewInit() {
      this.not.titleChange(this.i18n('Motius entrada'));
    }

    edit() {
      const idSeleccionat: string = this.gruaMotiusEntradaTable.displayedItems['checkedItems'][0];
      this.update(idSeleccionat);
    }

    create() {
      this.update(null);
    }

    update(secure_id: string) {
      const data: ComponentDialog = Builder.newComponentDialog(true, false, secure_id);

      const ref = this.dialog.open(GruaMotiusEntradaEditorComponent, {
        width: '40%',
        closeOnNavigation: false,
        autoFocus: true,
        data: data
      });

      ref.afterClosed().subscribe(updated => {
        if (updated) { this.gruaMotiusEntradaTable.load(); }
      });
    }

    refresh() {
      this.gruaMotiusEntradaTable.load();
    }

    onFilterClicked() {
      const ref = this.dialog.open(GruaMotiusEntradaFilterComponent, {
        width: '40%',
        closeOnNavigation: false,
        autoFocus: true,
        data: {
          'filter': this.gruaMotiusEntradaTable.filter
        }
      });

      ref.componentInstance.notify.subscribe((filter: GruaMotiusEntradaFilter) => {
        this.gruaMotiusEntradaTable.filter.set(filter);
        this.gruaMotiusEntradaTable.load();
      });
    }

    onRowSelected(somid) {
      this.update(somid);
    }

    generateExcel() {
      this.gruaMotiusEntradaTable.generateExcel();
    }
}
