import { SortDirection } from "@angular/material";
import { FilterData } from "path-shared/models/filter-data";

export class ConfirmacionDetalleBOEFilter  extends FilterData {
  secureId: string;
  expediente: string;

  
constructor(sortField: string, sortDir: SortDirection) {
  super(sortField, sortDir);
  this.secureId = '';
  this.expediente='';

  this.labels['secureId'] = this.i18n('secureId');
  
  this.labels['expediente'] = this.i18n('expediente');
 
}

}
