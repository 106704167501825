import { NotificationService } from 'path-shared/services/notification/notification.service';

import { DashInputFilesStatusComponent } from './../../../dash/components/dash-input-files-status/dash-input-files-status.component';
import { CustomDataSource } from './../../../../shared/services/datasource/custom.datasource';
import { Component, OnInit, ViewChild, EventEmitter, AfterViewInit, OnChanges, SimpleChanges, Output } from '@angular/core';
import { MatSort, MatPaginator, MatPaginatorBase } from '@angular/material';
import { Router } from '@angular/router';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TramitadorHistoricService } from 'path-denuncies-services/tramitador-historic.service';
import { TramitadorHistoricFilterService } from 'path-denuncies-services/tramitador-historic-filter.service';
import { ExpedienteService } from 'path-denuncies-services/expediente.service';
import { api } from 'path-shared/configuration/urls';
import { config } from 'path-shared/configuration/config';
import { TramitadorHistoric } from 'app/sections/denuncies/models/tramitador-historic.model';
import { TramitadorHistoricFilter } from 'app/sections/denuncies/models/tramitador-historic.filter';
import { Expediente } from 'app/sections/denuncies/models/expediente.model';
import { ExpedienteFilter } from 'app/sections/denuncies/models/expediente.filter';
import { ExpedienteFilterService } from 'path-denuncies-services/expediente-filter.service';
import { AnulAutoZBHistoric } from '../../models/anulacion-zona-azul-auto-historic.model';
import { AnulAutoZBHistoricFilter } from '../../models/anulacion-zona-azul-auto-historic.filter';
import { AnulAutoZBHistoricFilterService } from '../../service/anulacion-zona-azul-auto-historic-filter.service';

@Component({
  selector: 'app-anulacion-zona-azul-auto-historic',
  templateUrl: './anulacion-zona-azul-auto-historic.component.html',
  styleUrls: ['./anulacion-zona-azul-auto-historic.component.scss']
})
export class AnulacionZonaAzulAutoHistoricComponent implements OnInit, AfterViewInit, OnChanges {


  @ViewChild ('paginator') paginator: MatPaginator;
  @ViewChild ('paginatorDetail') paginatorDetail: MatPaginator;
  @ViewChild ('table') sort: MatSort;
  @ViewChild ('tableDetail') sortDetail: MatSort;

  // Atributos lista principal
  dataSource: CustomDataSource<AnulAutoZBHistoric, AnulAutoZBHistoricFilter>;
  filter: AnulAutoZBHistoricFilter = new AnulAutoZBHistoricFilter('Fecha', 'asc');
  updatedFilterData: AnulAutoZBHistoricFilter = new AnulAutoZBHistoricFilter('', '');
  filterPage: AnulAutoZBHistoricFilter;
  displayedItems = [];
  highlightedRows = [];
  selectedItem = -1;

  // Atributos lista detalle
  detailedDataSource: CustomDataSource<Expediente, ExpedienteFilter>;
  detallFilter: ExpedienteFilter = new ExpedienteFilter('Parquimetre', 'asc');
  displayedDetailItems = [];


  // Emits
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();

  displayedColumns = ['Operacion', 'Fecha', 'FechaInicio', 'FechaFin', 'Usuario'];
  displayedColumnsDetail = ['Parquimetre', 'Anul', 'CodAnul', 'Msg', 'DataCons', 'Data', 'Hora', 'Import', 'Processada', 'ExpedientAnul'];

  constructor(
    private i18n: I18n,
    private toastr: ToastrService,
    private tramitadorHistoricService: TramitadorHistoricService,
    private expedienteService: ExpedienteService,
    private expedienteFilterService: ExpedienteFilterService,
    private router: Router,
    private filterService: FilterService,
    private not: NotificationService
  ) {
  }

// Region COMPONENT LIFECYCLE

  ngOnInit() {
    this.sort.disableClear = true;
    this.sortDetail.disableClear = true;
    this.not.titleChange(this.i18n('Històric anul·lacions automàtiques'));
    this.inicializarPrincipal();
    this.inicializarDetalle();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });

    this.sortDetail.sortChange.subscribe(() => {
      this.paginatorDetail.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });

    merge(this.sort.sortChange, this.paginator.page).pipe(tap(() => this.loadAnulAutoZBHistoricPage()))
      .subscribe();

      merge(this.sortDetail.sortChange, this.paginatorDetail.page).pipe(tap(() => this.loadDetallPage(this.selectedItem)))
      .subscribe();

    this.loadAnulAutoZBHistoricPage();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      this.loadAnulAutoZBHistoricPage();
    }
  }

// Endregion

// Region INICIALIZACION

  inicializarPrincipal() {
    this.filter.paginator.init(this.sort, this.paginator);
    this.filterService.loadFilter('anulAutoZB-historic-filter', this.filter);
    this.sort.active = this.filter.paginator.sortField;

    this.dataSource = CustomDataSource.create<AnulAutoZBHistoric, AnulAutoZBHistoricFilter>(this.paginator, new AnulAutoZBHistoricFilterService(), 'urlAnulAutoZBHistoric', 'urlAnulAutoZBHistoricCount');
    this.dataSource.subject.subscribe((data) => {
      data.forEach(item => {
        this.displayedItems[item.Som_Id] = {
          id: item.Som_Id
        };
      });
    });
    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.loadAnulAutoZBHistoricPage();
        this.filterService.saveFilter('anulAutoZB-historic-filter', this.filter);
      }
    });
  }

  inicializarDetalle() {
    this.detailedDataSource = new CustomDataSource(this.paginatorDetail, new ExpedienteFilterService(), '', '');
    this.detallFilter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.loadDetallPage(this.selectedItem);
        this.filterService.saveFilter('tramitador-historic-detail-filter', this.detallFilter);
      }
    });
  }

// Endregion

// Region EVENTOS

loadAnulAutoZBHistoricPage() {

  this.filter.paginator.set(this.sort, this.paginator);
  this.dataSource.loadFilter(this.filter);
}

goProcessador(): void {
  this.router.navigate(['anulacion-zona-azul-auto']);
}

loadDetallPage(som_id: number) {
  if (this.selectedItem !== som_id) {
    this.selectedItem = som_id;
    this.paginatorDetail.pageIndex = 0;
  }
  const urlData = config.urls['apiUrl'] + api.endpoints['urlAnulacionsHistoricDetall'].replace('{id}', som_id.toString());
  const urlCount = config.urls['apiUrl'] + api.endpoints['urlAnulacionsHistoricDetallCount'].replace('{id}', som_id.toString());
  this.detailedDataSource.setUrlData(urlData);
  this.detailedDataSource.setUrlCount(urlCount);
  this.detallFilter.paginator.set(this.sortDetail, this.paginatorDetail);
  this.detailedDataSource.loadFilter(this.detallFilter);
}

// Endregion

}
