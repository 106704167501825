<app-dialog-header *ngIf="this.dataDialog.isDialog" label="Escala Ocupació" i18n-label></app-dialog-header>

<app-toolbar>
  <app-tool-button text="Editar" [disabled]="this.currentEscala === undefined || this.creating || this.editing" 
    i18n-text (click)="edit();" color="has-color-orange" icon="fa-edit">
  </app-tool-button>
  <app-tool-button text="Nou" [disabled]="this.creating || this.editing" i18n-text (click)="create();" color="has-color-green" 
    icon="fa-plus-square" [disabled]="!this.auth.UserCanCreate(this.auth.Pages.EscalaOcupacion)">
  </app-tool-button>
  <app-tool-button text="Cancelar" i18n-text (click)="cancel();" color="has-color-red" icon="fa-times"
                   [disabled]="!(this.currentEscala !== undefined && (this.editing || this.creating))">
  </app-tool-button>
  <app-tool-button text="Guardar rang" i18n-text (click)="saveRange();" color="has-color-green" icon="fa-save" 
                   [disabled]="!(this.currentEscala !== undefined && (this.editing || this.creating))">
  </app-tool-button>
  <app-tool-button text="Eliminar rang" i18n-text (click)="remove();" color="has-color-red" icon="fa-trash-alt" 
                   [disabled]="this.currentEscala === undefined || this.creating || this.editing"></app-tool-button>
  <app-tool-button text="Reiniciar configuració" i18n-text (click)="refresh();" color="has-color-blue" icon="fa-sync" 
    [disabled]="this.creating || this.editing">
  </app-tool-button>
  <app-tool-button text="Guardar configuració" i18n-text (click)="saveAll();" color="has-color-green" icon="fa-save" 
                   [disabled]="(this.currentEscala !== undefined && (this.editing || this.creating))">
  </app-tool-button>
  <app-tool-button-permissions [permission]="this.auth.Pages.EscalaOcupacion"></app-tool-button-permissions>
</app-toolbar>

<div class="row" style="align-items: center; margin-top: 5%;">
  <div class="col-6" style="border-color: grey; border:groove">
    <span *ngIf="this.editing"><h5 class="text-center">{{"Editant Rang"}}</h5></span>
    <span *ngIf="this.creating"><h5 class="text-center">{{"Creant Nou Rang"}}</h5></span>
    <app-escala-ocupacion-editor #escalaOcupacionEditor class="col-6" style="margin-top: 5%;" 
                  [currentEscala]="this.currentEscala" [currentEditing]="this.editing || this.creating" [coloresBase]="this.coloresBase.value">
    </app-escala-ocupacion-editor>
  </div>
  <div class="col-1"></div>
  <app-escala-ocupacion-table #escalaOcupacionTable class="col-5" [updatedFilterData]="this.filterData" (itemSelected)="this.onRowSelected($event)">
  </app-escala-ocupacion-table>
</div>
