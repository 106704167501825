import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-permisos',
  templateUrl: './permisos.component.html'
})
export class PermisosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
