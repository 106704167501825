import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { PersonesService } from 'app/sections/ciutat/persones/services/persones.service';
import { PersonaFilter } from 'app/sections/ciutat/persones/models/persona-filter';
import { Persona } from 'app/sections/ciutat/persones/models/persona.model';
import { Builder } from 'path-shared/models/builder';
import { PersonesComponent } from 'app/sections/ciutat/persones/pages/persones/persones.component';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { PersonesEditorComponent } from 'app/sections/ciutat/persones/components/persones-editor/persones-editor.component';
import { Dictionary } from 'path-shared/models/dictionary';
import { BehaviorSubject } from 'rxjs-compat';
import { ComboBoxInputComponent } from 'path-shared/components/form/combo-box-input/combo-box-input.component';
import { OperacionInforInfractorDto } from 'app/sections/denuncies/models/operacionInforInfractorDto.model';
import { FormService } from 'path-shared/services/form/form.service';

@Component({
  selector: 'app-infor-infractor',
  templateUrl: './infor-infractor.component.html'
})
export class InforInfractorComponent implements OnInit {

  dto = new OperacionInforInfractorDto();
  dniInfractor = null;
  sidInfractor = '';
  juridica = false;
  sidDomiciliInfractor = '';
  domicilisInfractorDictionary: BehaviorSubject<Array<Dictionary>>;
  @ViewChild('comboDomPropietari') comboDomPropietari: ComboBoxInputComponent;
  @ViewChild('mainForm') private mainForm: ElementRef;


  constructor(
    private personesService: PersonesService,
    private dialog: MatDialog,
    private confirmationDialogService: ConfirmationDialogService,
    private i18n: I18n,
    private toastr: ToastrService,
    private formService: FormService,


  ) {
  }

  ngOnInit() {
    this.domicilisInfractorDictionary = new BehaviorSubject(new Array<Dictionary>());

  }

  consultarConductor() {
    if (this.dniInfractor !== undefined
      && this.dniInfractor !== null
      && this.dniInfractor !==  '') {
        this.personesService.findPersonaByDniNif(this.dniInfractor).subscribe( (x: Persona[])  => {
          if ( x !== undefined && x !== null && x.length > 0 ) {
             if (x.length === 1) {
              this.sidInfractor = x[0].secure_id;
              this.dto.idInfractor = x[0].id.toString();
              this.dto.nom = x[0].nomComplet;
              this.dniInfractor = x[0].dni_pasp;
              this.juridica = x[0].juridica;
              this.sidDomiciliInfractor = x[0].SECURE_ID_DOMICILI_ACTUAL;
              // : nomComplet, dni_pasp: dni_pasp
              this.getDomicilis(this.sidInfractor, false, x[0].SECURE_ID_DOMICILI_ACTUAL);
              this.dto.idDomicili = this.sidDomiciliInfractor.split('|')[0];
             } else if (x.length  > 1 ) {
              const filter: PersonaFilter = new PersonaFilter('id', 'asc');
              filter.dni_pasp = this.dniInfractor;
              // this.filterService.saveFilter('persona-filter', filter);
              // Carraguem la taula filtrada per dni
              const data = Builder.newComponentDialog(true, false, '', filter);
              const ref = this.dialog.open(PersonesComponent, {
                width: '80%',
                height: '90%',
                closeOnNavigation: false,
                autoFocus: true,
                data: data
              });

              ref.afterClosed().subscribe(async data => {
                if (data.codi !== undefined && data.codi !== null && data.codi !== '') {
                  this.sidInfractor = data.secure_id;
                  this.dto.idInfractor = data.codi;
                  this.dto.nom = data.nomComplet;
                  this.dniInfractor = data.dni_pasp;
                  this.juridica = data.juridica;
                  this.sidDomiciliInfractor = data.secureIdDom;
                  // : nomComplet, dni_pasp: dni_pasp
                  this.getDomicilis(this.sidInfractor, false, data.secureIdDom);
                  this.dto.idDomicili = this.sidDomiciliInfractor.split('|')[0];


                }
              });

            }

          } else {
           // Obrim dialeg per crear persona
              this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('No s\ha trobat cap persona amb aquest DNI o Passaport. Vols crear-la ara?'), this.i18n('Si'), this.i18n('No'))
              .then((confirmed) => {
                  if (confirmed) {
                    const data = Builder.newComponentDialog(true, false, null);
                    const ref = this.dialog.open(PersonesEditorComponent, {
                      width: '80%',
                      height: '90%',
                      closeOnNavigation: false,
                      autoFocus: true,
                      data:  {  isDialog: true,
                        isReadOnly: false,
                        id: null,
                        filter: null,
                        dni: this.dniInfractor}
                    });

                    ref.afterClosed().subscribe(async data => {
                      if (data.codi !== undefined && data.codi !== null && data.codi !== '') {
                        this.sidInfractor = data.secure_id;
                        this.dto.nom = data.nomComplet;
                        this.dto.idInfractor = data.codi;
                        this.dniInfractor = data.dni_pasp;
                        this.juridica = data.juridica;
                        this.getDomicilis(this.sidInfractor, false, data.secureIdDom);
                        this.sidDomiciliInfractor = data.secureIdDom;
                        this.dto.idDomicili = this.sidDomiciliInfractor.split('|')[0];


                      }
                    });

                  } else {
                    this.dto.nom = '';
                    this.domicilisInfractorDictionary =  new BehaviorSubject(new Array<Dictionary>());
                  }
                });
          }
        });
      } else {
        const data = Builder.newComponentDialog(true, false, null);
        const ref = this.dialog.open(PersonesComponent, {
          width: '80%',
          height: '90%',
          closeOnNavigation: false,
          autoFocus: true,
          data: data,
          disableClose: true
        });

        ref.afterClosed().subscribe(async data => {

          if (data.codi !== undefined && data.codi !== null && data.codi !== '') {
            this.sidInfractor = data.secure_id;
            this.dto.nom = data.nomComplet;
            this.dto.idInfractor = data.codi;
            this.dniInfractor = data.dni_pasp;
            this.sidDomiciliInfractor = data.secureIdDom;
            this.juridica = data.juridica;
            this.getDomicilis(this.sidInfractor, false, data.secureIdDom);
            this.dto.idDomicili = this.sidDomiciliInfractor.split('|')[0];

          }
        });
      }

  }

  reset() {
    // this.dniInfractor = null;
    this.sidDomiciliInfractor = null;
    this.sidInfractor = null;
    this.dto.nom = null;
    this.dto.idInfractor = null;
    this.dto.idDomicili = null;
    this.comboDomPropietari.resetShowingOptions();

  }

//   seleccionarConductor(secureId = null) {
//     if (secureId !== null) {

//   } else {
//     if (Reflection.empty(this.sidInfractor)) {
//       const data = Builder.newComponentDialog(true, false, secureId);
//       const ref = this.dialog.open(PersonesComponent, {
//         width: '80%',
//         height: '90%',
//         closeOnNavigation: false,
//         autoFocus: true,
//         data: data,
//         disableClose: true
//       });

//       ref.afterClosed().subscribe(async data => {
//         if (data.codi !== undefined && data.codi !== null && data.codi !== '') {
//           this.sidInfractor = data.secure_id;
//           this.dto.nom = data.nomComplet;
//           this.dto.idInfractor = data.codi;
//           this.dniInfractor = data.dni_pasp;
//           this.sidDomiciliInfractor = data.secureIdDom;
//           this.getDomicilis(this.sidInfractor, false, data.secureIdDom);
//           this.dto.idDomicili = this.sidDomiciliInfractor.split('|')[0];

//         }
//       });
//     } else {
//       const data = Builder.newComponentDialog(true, false, secureId);
//       const ref = this.dialog.open(PersonesComponent, {
//         width: '80%',
//         height: '90%',
//         closeOnNavigation: false,
//         autoFocus: true,
//         data: data,
//         disableClose: true
//       });

//       ref.afterClosed().subscribe(async data => {
//         if (data.codi !== undefined && data.codi !== null && data.codi !== '') {
//           this.sidInfractor = data.secure_id;
//           this.dto.nom = data.nomComplet;
//           this.dto.idInfractor = data.codi;
//           this.dniInfractor = data.dni_pasp;
//           this.sidDomiciliInfractor = data.secureIdDom;
//           this.getDomicilis(this.sidInfractor, false, data.secureIdDom);
//           this.dto.idDomicili = this.sidDomiciliInfractor.split('|')[0];

//         }
//       });
//     }
//   }
// }

  getDomicilis(secureIdPersona, propietari, secureIdDom) {
    this.personesService.getDomicilis(secureIdPersona).subscribe(dom => {
      this.comboDomPropietari.resetShowingOptions();

      if (dom === undefined || dom === null || dom.length === 0) {
        this.domicilisInfractorDictionary =  new BehaviorSubject(new Array<Dictionary>());
      } else {
        // this.domicilisConductor = dom;
        // this.comboDomConductor.resetCombo();

        this.domicilisInfractorDictionary =  new BehaviorSubject(new Array<Dictionary>());
        const dict = new Array<Dictionary>();
            dom.forEach(element => {
              dict.push(new Dictionary(element.secure_id, element.domicili + ' (' + element.codiPostal + ' - ' + element.localitat + ')'));
            });
            this.domicilisInfractorDictionary.next(dict);
            if (secureIdDom !== undefined) {
              this.comboDomPropietari.markAsTouched();
              this.comboDomPropietari.setSelect(secureIdDom);
            }
          }

    });
  }

  domicilioConductorSeleccionado(secure_id: string) {
    this.sidDomiciliInfractor = secure_id;
  }

  public correcte(): boolean {
    if (!this.formService.allFieldsValid(this.mainForm)) {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
      return false;
    } else if (this.juridica) {
      this.toastr.warning(this.i18n('No se puede seleccionar una persona jurídica como infractor'));
      return false;
    } else {
      return true;
    }

  }

}
