import { Infraccio } from './../../../configuracio/Infraccions/models/infraccio.model';
import { ComponentDialog } from './../../../../shared/models/component-dialog.model';
import { OperacionsComponent } from './components/operacions/operacions.component';
import { DenunciesImagesComponent } from '../denuncies-editor/components/denuncies-images/denuncies-images.component';
import { NovaOperacioComponent } from './components/nova-operacio/nova-operacio.component';
import { CarrersComponent } from '../../../configuracio/carrers/pages/carrers/carrers.component';
import { ExpedientsComponent } from '../../../expedients/expedients.component';
import { ConfirmationDialogService } from '../../../../shared/components/confirmation-dialog/confirmation-dialog.service';
import { DenunciesService } from 'path-denuncies-services/denuncies.service';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { AgentsComponent } from 'app/sections/configuracio/agents/pages/agents/agents.component';
import { AgentsEditorComponent } from 'app/sections/configuracio/agents/components/agents-editor/agents-editor.component';
import { Builder } from 'path-shared/models/builder';
import { Reflection } from 'path-shared/services/reflection/reflection';
import { OperacionesModel } from '../../models/operaciones.model';
import { CalleEditorComponent } from 'app/sections/configuracio/carrers/components/calle-editor/calle-editor.component';
import { InfraccionsEditorComponent } from 'app/sections/configuracio/Infraccions/components/infraccions-editor/infraccions-editor.component';
import { InfraccioComponent } from 'app/sections/configuracio/Infraccions/pages/infraccio.component';
import { PersonesEditorComponent } from 'app/sections/ciutat/persones/components/persones-editor/persones-editor.component';
import { PersonesComponent } from 'app/sections/ciutat/persones/pages/persones/persones.component';
import { PersonesService } from 'app/sections/ciutat/persones/services/persones.service';
import { Persona } from 'app/sections/ciutat/persones/models/persona.model';
import { PersonaFilter } from 'app/sections/ciutat/persones/models/persona-filter';
import { Dictionary } from 'path-shared/models/dictionary';
import { ComboBoxInputComponent } from 'path-shared/components/form/combo-box-input/combo-box-input.component';
import { ZonesService } from 'app/sections/ciutat/zones/services/zones.service';
import { ZonesModel } from 'app/sections/ciutat/zones/models/zones.model';
import { VehiclesEditorComponent } from 'app/sections/ciutat/vehicles/components/vehicles-editor/vehicles-editor.component';
import { VehiclesComponent } from 'app/sections/ciutat/vehicles/pages/vehicles/vehicles.component';
import { XmlTreeViewerComponent } from 'app/sections/utilitats/dgt/components/xml-tree-viewer/xml-tree-viewer.component';
import { DgtFilterComponent } from 'app/sections/utilitats/dgt/components/dgt-filter/dgt-filter.component';
import { NotificacionsComponent } from 'app/sections/notificacions/components/notificacions.component';
import { CFG_TIPODENUNCIA } from 'path-models/CFG_TIPODENUNCIA';
import { StartupService } from 'path-shared/services/startup/startup.service';
import { InfraccioService } from 'app/sections/configuracio/Infraccions/services/infraccions-service.service';
import { SOM_DEN_INFRACCIONES_GRAVEDAD } from 'path-models/SOM_DEN_INFRACCIONES_GRAVEDAD';
import { DenunciaDetailModel } from '../../models/denuncia-detail.model';
import { FormService } from 'path-shared/services/form/form.service';
import { DenunciaCobrarComponent } from './components/denuncia-cobrar/denuncia-cobrar.component';
import { ExpedientsModel } from 'app/sections/expedients/models/expedients.model';
import { DenunciaExpedientModel } from '../../models/denuncia-expedient.model';
import { DenunciesCobrarService } from 'path-denuncies-services/denuncies-cobrar.service';
import { ComboBoxInputAutocompleteComponent } from 'path-shared/components/form/combo-box-input-autocomplete/combo-box-input-autocomplete.component';
import { CarrersService } from 'app/sections/configuracio/carrers/services/carrers-service.service';
import { ReportsComponent } from 'path-shared/components/reports/reports.component';
import { FileDownloader } from 'path-shared/services/http/fileDownloader';
import { ReportService } from 'path-shared/services/report/report.service';
import { MapComponent } from 'app/sections/livemap/components/map/map.component';
import { CustomDataMarker } from 'app/sections/livemap/models/custom-data-marker';
import { DenunciesTableComponent } from '../denuncies-table/denuncies-table.component';
import { DenunciaFilter } from '../../models/denuncia.filter';
import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-denuncies-editor',
  templateUrl: './denuncies-editor.component.html'
})

export class DenunciesEditorComponent implements OnInit {

  reports = [];
  URL_REPORT = 'urlGenerateReportPDF';
  URL_REPORT_CARTAPAGO='urlGenerateReportCartaPago';
  denunciesImages: any = [];
  denunciesImageDialogRef: MatDialogRef<DenunciesImagesComponent>;
  denunciesNovaOperacioRef: MatDialogRef<NovaOperacioComponent>;
  denunciesCobrarRef: MatDialogRef<DenunciaCobrarComponent>;
  expedientsDialog: MatDialogRef<ExpedientsComponent>;
  secure_id_domiciliConductor = '';
  mapDisabled = true;

  @ViewChild('mainForm') private mainForm: ElementRef;
  @ViewChild(OperacionsComponent) operaciones: OperacionsComponent;
  @ViewChild('comboDomConductor') comboDomConductor: ComboBoxInputComponent;
  @ViewChild('comboDomPropietari') comboDomPropietari: ComboBoxInputComponent;
  @ViewChild('comboDomTutorLegal') comboDomTutorLegal: ComboBoxInputComponent;
  @ViewChild('comboZonesCarrer') comboZonesCarrer: ComboBoxInputComponent;
  @ViewChild('comboTipusDenuncia') comboTipusDenuncia: ComboBoxInputComponent;
  @ViewChild('comboTipusGravetat') comboTipusGravetat: ComboBoxInputComponent;


  @Input() isReadOnly: boolean;
  // Emits
  @Output() deleted: EventEmitter<string> = new EventEmitter<string>();
  @Output() updated: EventEmitter<string> = new EventEmitter<string>();

  denunciaDetail: DenunciaDetailModel;
  denunciaDetailOld: DenunciaDetailModel;
  tipoOperacions: any;
  id: number;
  imatgesCount = 0;
  dialogData: ComponentDialog;
  timeStartAt: any;
  tipusDenunciesDictionary: BehaviorSubject<Array<Dictionary>>;
  tipusGravetatDictionary: BehaviorSubject<Array<Dictionary>>;
  zonesComboDictionary: BehaviorSubject<Array<Dictionary>>;
  domicilisConductor = Array();
  domicilisConductorDictionary: BehaviorSubject<Array<Dictionary>>;
  domicilisPropietariDictionary: BehaviorSubject<Array<Dictionary>>;
  domicilisTutorLegalDictionary: BehaviorSubject<Array<Dictionary>>;
  domicilisPropietari = Array();
  domicilisTutorLegal = Array();
  diesDescompte: number;
  hasGruaAgent = false;

  // autocomplete combo Infracciones
  infracciones: Infraccio[];
  infraccionesDictionary: Array<Dictionary>;
  valorAutoCompleteInfraccionesDictionary;
  @ViewChild('comboInfraccion') comboInfracciones: ComboBoxInputAutocompleteComponent;

  callesDictionary: Array<Dictionary>;
  valorAutoCompleteCalleDictionary;
  @ViewChild('comboCalle') comboCalle: ComboBoxInputAutocompleteComponent;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private confirmationDialogService: ConfirmationDialogService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private denunciesService: DenunciesService,
    private personesService: PersonesService,
    private zonesService: ZonesService,
    private startupService: StartupService,
    private denunciesCobrarService: DenunciesCobrarService,
    private formService: FormService,
    public auth: AuthorizationService,
    private i18n: I18n,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
    public thisDialogRef: MatDialogRef<DenunciesEditorComponent>,
    private carrersService: CarrersService,
    private infraccionsService: InfraccioService,
    private reportService: ReportService,
  ) {
    if (this.data !== undefined && this.data.id !== undefined) {
      this.dialogData = data;
      this.id = parseInt(this.dialogData.id, 10);
    } else {
      this.id = this.route.snapshot.params.id;
    }
    this.isReadOnly = true;
    this.denunciaDetail = Builder.newDenunciaDetail();
    this.reportService.getReportsHTML(true).subscribe(res => {
      this.reports = res;
    });
    this.denunciesService.hasGruaAgent().subscribe(res => {
      this.hasGruaAgent = res;
    });
  }

  ngOnInit() {
    this.refrescar();
  }

  refrescar() {
    this.valorAutoCompleteCalleDictionary = new BehaviorSubject('');
    this.valorAutoCompleteInfraccionesDictionary = new BehaviorSubject('');

    this.tipusDenunciesDictionary = new BehaviorSubject(new Array<Dictionary>());
    this.tipusGravetatDictionary = new BehaviorSubject(new Array<Dictionary>());
    this.domicilisConductorDictionary = new BehaviorSubject(new Array<Dictionary>());
    this.domicilisTutorLegalDictionary = new BehaviorSubject(new Array<Dictionary>());
    this.domicilisPropietariDictionary = new BehaviorSubject(new Array<Dictionary>());
    this.zonesComboDictionary = new BehaviorSubject(new Array<Dictionary>());

    this.getDenunciaDetall();
    // Aquest l'estem guardant al sessionStorage
    this.loadDiesDescompte();
  }

  private getDenunciaDetall() {
    this.isReadOnly = true;
    const denuncia = this.denunciesService.getDenunciaDetails(this.id).subscribe(detailsdata => {
      if (detailsdata.hora !== undefined && detailsdata.hora != null) {
        const arrayHora = detailsdata.hora.split(':');
        if (arrayHora.length === 2) {
          this.timeStartAt = new Date();
          this.timeStartAt.setHours(arrayHora[0], arrayHora[1]);
        }
      }

      this.denunciaDetail = detailsdata;

      if ((this.denunciaDetail.latitud != '0' && this.denunciaDetail.longitud != '0')
        && this.denunciaDetail.latitud !== null && this.denunciaDetail.longitud !== null) {
        this.mapDisabled = false;
      }
      // this.imatgesCount = detailsdata.SOM_FICHEROS.length;
      this.operaciones.dataSource.data = this.denunciaDetail.Operaciones;

      this.denunciesService.getDenunciaImagesBySomId(this.id.toString()).subscribe(images => {

        this.denunciesImages = images;
        this.imatgesCount = images.length;
        this.denunciesImages.forEach(element => {
          element.NUM_DENUNCIA = this.denunciaDetail.numeroDenuncia;
        });
      });

      this.loadDomiciliosActuales();

      this.loadZonesCarrer(this.denunciaDetail.secure_id_carrer);

      // Aquest l'estem guardant al sessionStorage i quan anem al manteniment de infraccions, actualitzem la caché
      this.loadInfracciones();
      // Aquest l'estem guardant al sessionStorage i quan anem al manteniment de carrers, actualitzem la caché
      this.loadCalles();
      // Aquest l'estem guardant al sessionStorage
      this.loadTipusDenuncies();
      // Aquest l'estem guardant al sessionStorage
      this.loadTipusGravetat();
    });
  }

  loadTipusDenuncies() {
    const tipusDenunciaAux = new Array<Dictionary>();
    this.startupService.GetTiposDenuncia().forEach((tipusDenuncia: CFG_TIPODENUNCIA) => {
      tipusDenunciaAux.push(new Dictionary(tipusDenuncia.CODI, tipusDenuncia.DESCRIPCIO));
    });

    this.tipusDenunciesDictionary.next(tipusDenunciaAux);
    this.comboTipusDenuncia.setSelect(this.denunciaDetail.tipusDenuncia);
  }

  loadTipusGravetat() {
    const tipusGravetatAux = new Array<Dictionary>();
    this.startupService.GetInfraccioTipusGravetat().forEach((gravetat: SOM_DEN_INFRACCIONES_GRAVEDAD) => {
      tipusGravetatAux.push(new Dictionary(gravetat.CODI, gravetat.DESCRIPCIO));
    });
    this.tipusGravetatDictionary.next(tipusGravetatAux);
    // this.comboTipusGravetat.markAsTouched();
    this.comboTipusGravetat.setSelect(this.denunciaDetail.qualificacio);
  }

  loadDiesDescompte() {
    this.diesDescompte = this.startupService.GetInfraccioDiesDescompte();
  }

  loadCalles() {
    this.callesDictionary = new Array<Dictionary>();
    let json: string;
    let carrers: any[];

    if (sessionStorage.getItem('carrersForCombo')) {
      json = sessionStorage.getItem('carrersForCombo');
      carrers = JSON.parse(json.toString());
      this.setCalles(carrers);
      this.setDefaultValueCalle();
    } else {
      this.carrersService.getAllForCombo().subscribe(carrersForCombo => {
        json = JSON.stringify(carrersForCombo);
        sessionStorage.setItem('carrersForCombo', json);
        this.setCalles(carrersForCombo);
        this.setDefaultValueCalle();
      });
    }
  }

  setCalles(carrers: any[]) {
    carrers.forEach(carrer => {
      if (carrer.SECURE_ID !== undefined && carrer.SECURE_ID !== null &&
        carrer.nomCarrer !== undefined && carrer.nomCarrer !== null) {
        this.callesDictionary.push(new Dictionary(carrer.SECURE_ID, carrer.nomCarrer));
      }
    });
  }

  loadInfracciones() {
    this.infraccionesDictionary = new Array<Dictionary>();
    let json: string;
    let infracciones: any[];

    if (sessionStorage.getItem('infraccionsForCombo')) {
      json = sessionStorage.getItem('infraccionsForCombo');
      infracciones = JSON.parse(json.toString());
      this.setInfracciones(infracciones);
      this.setDefaultValueInfraccion();
    } else {
      this.infraccionsService.getAllForCombo().subscribe(infraccionesForCombo => {
        json = JSON.stringify(infraccionesForCombo);
        sessionStorage.setItem('infraccionsForCombo', json);
        this.setInfracciones(infraccionesForCombo);
        this.setDefaultValueInfraccion();
      });
    }
  }

  setInfracciones(infracciones: any[]) {
    infracciones.forEach(infraccion => {
      if (infraccion.secureId !== undefined && infraccion.secureId !== null &&
        infraccion.descCurta !== undefined && infraccion.descCurta !== null) {
        this.infraccionesDictionary.push(new Dictionary(infraccion.secureId, infraccion.descCurta));
      }
    });

    this.infracciones = infracciones;
  }

  setDefaultValueCalle() {
    if (this.denunciaDetail.secure_id_carrer !== undefined && this.denunciaDetail.secure_id_carrer !== null && this.callesDictionary.length > 0) {
      const calleSelected = this.callesDictionary.find(x => x.key === this.denunciaDetail.secure_id_carrer.toString());
      this.comboCalle.setForcedValueComb(calleSelected.value);
    }
  }
  setDefaultValueInfraccion() {
    if (this.denunciaDetail.secure_id_infraccio !== undefined && this.denunciaDetail.secure_id_infraccio !== null && this.infraccionesDictionary.length > 0) {
      const infraccioSelected = this.infraccionesDictionary.find(x => x.key === this.denunciaDetail.secure_id_infraccio.toString());
      if (this.comboInfracciones !== undefined) {
        this.comboInfracciones.setForcedValueComb(infraccioSelected.value);
      }
    }
  }
  loadZonesCarrer(secure_id_carrer: string) {
    this.zonesService.getZonesByCarrer(secure_id_carrer).subscribe((zones: ZonesModel[]) => {
      const zonesComboAux = new Array<Dictionary>();
      zones.forEach(zona => {
        zonesComboAux.push(new Dictionary(zona.SECURE_ID, zona.descripcio));
      });

      this.zonesComboDictionary.next(zonesComboAux);
      if (this.denunciaDetail.secure_id_zona !== undefined && this.denunciaDetail.secure_id_zona != null) {
        this.comboZonesCarrer.setSelect(this.denunciaDetail.secure_id_zona);
      }
    });
  }

  onImagesClick() {
    this.denunciesImageDialogRef = this.dialog.open(DenunciesImagesComponent,
      {
        minWidth: '70%',
        minHeight: '90%',
        closeOnNavigation: false,
        autoFocus: true,
        data: { 'ficheros': this.denunciesImages, 'numDenuncia': this.denunciaDetail.numeroDenuncia },
      });

    this.denunciesImageDialogRef.componentInstance.somId = this.id;

    this.denunciesImageDialogRef.afterClosed().subscribe(() => {
      this.denunciesService.getDenunciaImagesBySomId(this.id.toString()).subscribe(images => {

        this.denunciesImages = images;
        this.imatgesCount = images.length;
        this.denunciesImages.forEach(element => {
          element.NUM_DENUNCIA = this.denunciaDetail.numeroDenuncia;
        });
      });
    });

  }

  onMakeGridEditable() {
    this.isReadOnly = false;
    this.denunciaDetailOld = Object.assign({}, this.denunciaDetail);
    // this.loadCalles();
    // this.loadInfracciones();
    // // Aquest l'estem guardant al sessionStorage
    // this.loadTipusDenuncies();
    // // Aquest l'estem guardant al sessionStorage
    // this.loadTipusGravetat();
  }

  onMakeGridNonEditable(close = false): void {
    this.isReadOnly = true;

    if (this.dialogData === undefined) {
      if (close) {
        this.router.navigate(['denuncies']);
      }
    } else {
      this.cancel();
    }
  }

  saveDenuncia() {
    if (this.formService.allFieldsValid(this.mainForm)) {
      this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Vol actualitzar la denúncia?'))
        .then((confirmed) => {
          if (confirmed) {
            const dataJson = JSON.stringify(this.denunciaDetail);
            const saved = this.denunciesService.saveDenuncia(this.denunciaDetail.secure_id_denuncia, dataJson).subscribe(() => {
              this.toastr.success(this.i18n('Denúncia actualizada'));
              this.dialog.closeAll();
              // propago un evento para avisar al datasource de que he eliminado un registro
              this.updated.emit(this.denunciaDetail.secure_id_denuncia);
              this.onMakeGridNonEditable();
            });
          }
        })
        .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
    }
  }

  removeDenuncia() {
    this.confirmationDialogService.confirm(this.i18n('Eliminar denúncia'), this.i18n('La denúncia serà eliminada i no es podrà recuperar. Segur que vol eliminar la denúncia?'))
      .then((confirmed) => {
        console.log('User confirmed:', confirmed);
        if (confirmed) {
          const dataJson = JSON.stringify(this.denunciaDetail);
          const saved = this.denunciesService.removeDenuncia(this.id).subscribe(() => {
            this.toastr.info(this.i18n('Denúncia eliminada'));
            this.dialog.closeAll();
            // propago un evento para avisar al datasource de que he eliminado un registro
            this.deleted.emit(this.denunciaDetail.secure_id_denuncia);
            this.router.navigate(['denuncies']);

          });
        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  novaOperacio() {
    const tipoOpe = this.denunciesService.getTipoOperacions().subscribe(novaOperacio => {
      this.denunciesNovaOperacioRef = this.dialog.open(NovaOperacioComponent,
        {
          height: '80%',
          width: '80%',
          closeOnNavigation: false,
          autoFocus: true,
          data: {
            'operacions': novaOperacio,
            'data': this.denunciaDetail,
            'operaciones': this.operaciones,
            'isMassiva': false
          },
        });

      this.denunciesNovaOperacioRef.componentInstance.exp = this.denunciaDetail.expedient;
      this.denunciesNovaOperacioRef.componentInstance.newOperation.subscribe((data: OperacionesModel) => {
        this.operaciones.addOperation(data);
        this.refrescar();
      });
    });
  }

  cobrarDenuncia() {
    this.denunciesService.getExpedient(this.denunciaDetail.expedient).subscribe((expedientsData: ExpedientsModel) => {
      this.denunciesService.getSomExpedient(this.denunciaDetail.expedient).subscribe((denunciaExpedient: DenunciaExpedientModel) => {
        this.denunciesCobrarService.cobrarDenuncia(this.denunciaDetail, expedientsData, denunciaExpedient).subscribe(res => {
          this.getDenunciaDetall();
        });
      });
    });
  }

  getExpedients() {
    this.denunciesService.getExpedient(this.denunciaDetail.expedient).subscribe(expedientsData => {
      this.expedientsDialog = this.dialog.open(ExpedientsComponent,
        {
          height: '80%',
          width: '52%',
          closeOnNavigation: false,
          autoFocus: true,
          data: { 'expedient': expedientsData, 'numDenuncia': this.denunciaDetail.numeroDenuncia, 'imatges': this.denunciesImages }

        });
    });
  }
  calleSeleccionado(secureIdCalle: string) {
    this.denunciaDetail.secure_id_carrer = secureIdCalle;
    this.denunciaDetail.carrer = this.callesDictionary.find(x => x.key === secureIdCalle).value;
    // Carreguem les zones associades al carrer sel·leccionat
    this.denunciaDetail.secure_id_zona = null;
    this.denunciaDetail.descZona = null;
    this.loadZonesCarrer(this.denunciaDetail.secure_id_carrer);
  }
  seleccionarCarrer(current_id = null) {
    if (current_id !== null) {
      const data: ComponentDialog = Builder.newComponentDialog(true, this.isReadOnly, current_id);
      const dataAux = <any>data;
      dataAux.editable = false;
      const ref = this.dialog.open(CalleEditorComponent, {
        width: '60%',
        closeOnNavigation: false,
        autoFocus: true,
        data: dataAux
      });
    } else {
      if (!Reflection.isNull(this.denunciaDetail) && !Reflection.empty(this.denunciaDetail.secure_id_carrer) && this.isReadOnly) {
        this.toastr.error(this.i18n('Carrer no trobat!'));
      } else {
        const data: ComponentDialog = Builder.newComponentDialog(true, this.isReadOnly, current_id);
        const ref = this.dialog.open(CarrersComponent, {
          width: '90%',
          closeOnNavigation: false,
          autoFocus: true,
          data: data
        });

        ref.afterClosed().subscribe(res => {

          if (res.nomCarrer !== undefined && res.nomCarrer !== null && res.nomCarrer !== '') {
            this.denunciaDetail.carrer = res.nomCarrer;
            this.denunciaDetail.secure_id_carrer = res.secure_id;
            this.denunciaDetail.secure_id_zona = null;
            this.denunciaDetail.descZona = null;
            this.setDefaultValueCalle();
            this.loadZonesCarrer(this.denunciaDetail.secure_id_carrer);
          }
        });
      }
    }
  }

  cancelDenuncia() {
    this.isReadOnly = true;
    this.denunciaDetail = this.denunciaDetailOld;
  }

  seleccionarAgent(current_id = null) {
    if (current_id !== null) {
      const data: ComponentDialog = Builder.newComponentDialog(true, this.isReadOnly, current_id);
      const dataAux = <any>data;
      dataAux.editable = false;
      const ref = this.dialog.open(AgentsEditorComponent, {
        width: '30%',
        closeOnNavigation: false,
        autoFocus: true,
        data: dataAux
      });
    } else {
      if (!Reflection.isNull(this.denunciaDetail) && !Reflection.empty(this.denunciaDetail.codiAgent) && this.isReadOnly) {
        this.toastr.error(this.i18n('Agent no trobat!'));
      } else {
        const data: ComponentDialog = Builder.newComponentDialog(true, this.isReadOnly, current_id);
        const ref = this.dialog.open(AgentsComponent, {
          width: '90%',
          closeOnNavigation: false,
          autoFocus: true,
          data: data
        });

        ref.afterClosed().subscribe(res => {
          if (res.codi !== undefined && res.codi !== null && res.codi !== '') {
            this.denunciaDetail.codiAgent = res.codi;
            this.denunciaDetail.secure_id_agent = res.secure_id;
          }
        });
      }
    }
  }
  seleccionarAgent2(current_id = null) {
    if (current_id !== null) {
      const data: ComponentDialog = Builder.newComponentDialog(true, this.isReadOnly, current_id);
      const dataAux = <any>data;
      dataAux.editable = false;
      const ref = this.dialog.open(AgentsEditorComponent, {
        width: '30%',
        closeOnNavigation: false,
        autoFocus: true,
        data: dataAux
      });
    } else {
      if (!Reflection.isNull(this.denunciaDetail) && !Reflection.empty(this.denunciaDetail.codiAgent) && this.isReadOnly) {
        this.toastr.error(this.i18n('Agent no trobat!'));
      } else {
        const data: ComponentDialog = Builder.newComponentDialog(true, this.isReadOnly, current_id);
        const ref = this.dialog.open(AgentsComponent, {
          width: '90%',
          closeOnNavigation: false,
          autoFocus: true,
          data: data
        });

        ref.afterClosed().subscribe(res => {
          if (res.codi !== undefined && res.codi !== null && res.codi !== '') {
            this.denunciaDetail.codiAgent2 = res.codi;
            this.denunciaDetail.secure_id_agent2 = res.secure_id;
          }
        });
      }
    }
  }

  seleccionarLegislacio(current_id = null) {
    if (current_id !== null) {
      const data: ComponentDialog = Builder.newComponentDialog(true, this.isReadOnly, current_id);
      const dataAux = <any>data;
      dataAux.editable = false;
      const ref = this.dialog.open(InfraccionsEditorComponent, {
        width: '90%',
        closeOnNavigation: false,
        autoFocus: true,
        data: dataAux
      });
      ref.afterClosed().subscribe(res => {
        this.refrescar();
      });
    } else {
      if (!Reflection.isNull(this.denunciaDetail) && !Reflection.empty(this.denunciaDetail.llei) && this.isReadOnly) {
        this.toastr.error(this.i18n('Legislacio no trobada!'));
      } else {
        const data: ComponentDialog = Builder.newComponentDialog(true, this.isReadOnly, current_id);
        const ref = this.dialog.open(InfraccioComponent, {
          width: '90%',
          closeOnNavigation: false,
          autoFocus: true,
          data: data
        });
        ref.afterClosed().subscribe(res => {
          if (res !== undefined && res !== null) {
            // Les infraccions de tipus velocitat no les podem denúnciar des d'aquí
            if (res.infraccio.tipusId === 'V') {
              this.toastr.warning(this.i18n('No es poden carregar infraccions de velocitat!'));
            } else {
              this.denunciaDetail.llei = res.infraccio.legislacio;
              this.denunciaDetail.article = res.infraccio.article;
              this.denunciaDetail.descripcioInfraccio = res.infraccio.descCurta;
              this.denunciaDetail.secure_id_infraccio = res.infraccio.secureId;
              this.denunciaDetail.import = res.infraccio.import;
              this.denunciaDetail.importDescompte = res.infraccio.importDescompte;
              this.denunciaDetail.lleiDesc = res.infraccio.lleiDesc;
              this.denunciaDetail.tipusIdInfraccio = res.infraccio.tipusId;
              this.denunciaDetail.porcentajeDescuento = res.infraccio.descompte;
              this.denunciaDetail.punts = res.infraccio.punts;
              this.denunciaDetail.qualificacio = res.infraccio.gravetat;

              this.denunciaDetail.tipusDenuncia = res.infraccio.tipusDenunciaId; // combo tipus denuncies
              this.comboTipusDenuncia.setSelect(this.denunciaDetail.tipusDenuncia);
              this.comboTipusDenuncia.markAsTouched();
              this.denunciaDetail.qualificacio = res.infraccio.gravetat; // combo tipus gravetats
              this.comboTipusGravetat.setSelect(this.denunciaDetail.qualificacio);
              this.comboTipusGravetat.markAsTouched();

              this.calcularDataDescompte();
              this.setDefaultValueInfraccion();
            }
          }
        });
      }
    }
  }

  infraccioSeleccionado(secureIdInfraccion: string) {
    const res = this.infracciones.find(x => x.secureId === secureIdInfraccion);
    if (res !== undefined && res !== null) {
      // Les infraccions de tipus velocitat no les podem denúnciar des d'aquí
      if (res.tipusId === 'V') {
        this.toastr.warning(this.i18n('No es poden carregar infraccions de velocitat!'));
      } else {
        this.denunciaDetail.llei = res.legislacio;
        this.denunciaDetail.article = res.article;
        this.denunciaDetail.descripcioInfraccio = res.descCurta;
        this.denunciaDetail.secure_id_infraccio = res.secureId;
        this.denunciaDetail.import = res.import;
        this.denunciaDetail.importDescompte = res.importDescompte;
        this.denunciaDetail.lleiDesc = res.lleiDesc;
        this.denunciaDetail.tipusIdInfraccio = res.tipusId;
        this.denunciaDetail.porcentajeDescuento = res.descompte;
        this.denunciaDetail.punts = Number(res.punts);
        this.denunciaDetail.judicial = res.judicial;

        this.denunciaDetail.tipusDenuncia = res.tipusDenunciaId; // combo tipus denuncies
        this.comboTipusDenuncia.setSelect(this.denunciaDetail.tipusDenuncia);
        this.comboTipusDenuncia.markAsTouched();
        this.denunciaDetail.qualificacio = res.gravetat; // combo tipus gravetats
        this.comboTipusGravetat.setSelect(this.denunciaDetail.qualificacio);
        this.comboTipusGravetat.markAsTouched();

        this.calcularDataDescompte();
      }
    }
  }

  cancel() {
    this.thisDialogRef.close(false);
  }

  seleccionarPropietari(secureId = null) {
    if (secureId !== null) {
      const data: ComponentDialog = Builder.newComponentDialog(true, this.isReadOnly, secureId);
      const ref = this.dialog.open(PersonesEditorComponent, {
        width: '80%',
        height: '90%',
        closeOnNavigation: false,
        autoFocus: true,
        data: data,
        disableClose: true
      });
      ref.afterClosed().subscribe(async dat => {
        if (this.denunciaDetail.secure_id_propietari !== undefined && this.denunciaDetail.secure_id_propietari !== null && this.denunciaDetail.secure_id_propietari !== '') {
          this.getDomicilis(this.denunciaDetail.secure_id_propietari, 'Propietari', this.denunciaDetail.secure_id_domiciliPropietari);
        }
      });
    } else {
      if (!Reflection.isNull(this.denunciaDetail) && !Reflection.empty(this.denunciaDetail.secure_id_propietari) && this.isReadOnly) {
        this.toastr.error(this.i18n('Persona no trobada!'));
      } else {
        const data: ComponentDialog = Builder.newComponentDialog(true, this.isReadOnly, secureId);
        const ref = this.dialog.open(PersonesComponent, {
          width: '80%',
          height: '90%',
          closeOnNavigation: false,
          autoFocus: true,
          data: data,
          disableClose: true
        });

        ref.afterClosed().subscribe(res => {

          if (res.codi !== undefined && res.codi !== null && res.codi !== '') {
            this.denunciaDetail.secure_id_propietari = res.secure_id;
            this.denunciaDetail.nom_propietari = res.nomComplet;
            this.denunciaDetail.dni_propietari = res.dni_pasp;
            this.denunciaDetail.secure_id_domiciliPropietari = res.secureIdDom;
            this.getDomicilis(this.denunciaDetail.secure_id_propietari, 'Propietari', res.secureIdDom);
          }
        });
      }
    }
  }


  seleccionarTutorLegal(secureId = null) {
    if (secureId !== null) {
      const data: ComponentDialog = Builder.newComponentDialog(true, this.isReadOnly, secureId);
      const ref = this.dialog.open(PersonesEditorComponent, {
        width: '80%',
        height: '90%',
        closeOnNavigation: false,
        autoFocus: true,
        data: data,
        disableClose: true
      });
      ref.afterClosed().subscribe(async dat => {
        if (this.denunciaDetail.secure_id_tutorLegal !== undefined && this.denunciaDetail.secure_id_tutorLegal !== null && this.denunciaDetail.secure_id_tutorLegal !== '') {
          this.getDomicilis(this.denunciaDetail.secure_id_tutorLegal, 'TutorLegal', this.denunciaDetail.secure_id_domiciliTutorLegal);
        }
      });
    } else {
      if (!Reflection.isNull(this.denunciaDetail) && !Reflection.empty(this.denunciaDetail.secure_id_tutorLegal) && this.isReadOnly) {
        this.toastr.error(this.i18n('Persona no trobada!'));
      } else {
        const data: ComponentDialog = Builder.newComponentDialog(true, this.isReadOnly, secureId);
        const ref = this.dialog.open(PersonesComponent, {
          width: '80%',
          height: '90%',
          closeOnNavigation: false,
          autoFocus: true,
          data: data,
          disableClose: true
        });

        ref.afterClosed().subscribe(res => {
          if (res.codi !== undefined && res.codi !== null && res.codi !== '') {
            this.denunciaDetail.secure_id_tutorLegal = res.secure_id;
            this.denunciaDetail.nom_tutorLegal = res.nomComplet;
            this.denunciaDetail.dni_tutorLegal = res.dni_pasp;
            this.denunciaDetail.secure_id_domiciliTutorLegal = res.secureIdDom;
            this.getDomicilis(this.denunciaDetail.secure_id_tutorLegal, 'TutorLegal', res.secureIdDom);
          }
        });
      }
    }
  }

  seleccionarConductor(secureId = null) {
    if (secureId !== null) {
      const data: ComponentDialog = Builder.newComponentDialog(true, this.isReadOnly, secureId);
      const ref = this.dialog.open(PersonesEditorComponent, {
        width: '80%',
        height: '90%',
        closeOnNavigation: false,
        autoFocus: true,
        data: data,
        disableClose: true
      });

      ref.afterClosed().subscribe(async dat => {
        if (this.denunciaDetail.secure_id_conductor !== undefined && this.denunciaDetail.secure_id_conductor !== null && this.denunciaDetail.secure_id_conductor !== '') {
          this.getDomicilis(this.denunciaDetail.secure_id_conductor, 'Infractor', this.denunciaDetail.secure_id_domiciliConductor);
        }
      });
      /*const ref = this.dialog.open(PersonesEditorComponent, {
      width: '90%',
      closeOnNavigation: false,
      autoFocus: true,
      data:  {
        'secureId': secure_id
      }
    });*/

    } else {
      if (!Reflection.isNull(this.denunciaDetail) && !Reflection.empty(this.denunciaDetail.secure_id_conductor) && this.isReadOnly) {
        this.toastr.error(this.i18n('Persona no trobada!'));
      } else {
        const data: ComponentDialog = Builder.newComponentDialog(true, this.isReadOnly, secureId);
        const ref = this.dialog.open(PersonesComponent, {
          width: '80%',
          height: '90%',
          closeOnNavigation: false,
          autoFocus: true,
          data: data,
          disableClose: true
        });

        ref.afterClosed().subscribe(async resp => {
          if (resp.codi !== undefined && resp.codi !== null && resp.codi !== '') {
            this.denunciaDetail.secure_id_conductor = resp.codi;
            this.denunciaDetail.secure_id_conductor = resp.secure_id;
            this.denunciaDetail.nom_conductor = resp.nomComplet;
            this.denunciaDetail.dni_conductor = resp.dni_pasp;
            this.denunciaDetail.secure_id_domiciliConductor = resp.secureIdDom;
            // : nomComplet, dni_pasp: dni_pasp
            this.getDomicilis(this.denunciaDetail.secure_id_conductor, 'Infractor', resp.secureIdDom);
            // this.denunciaDetail.secure_id_domiciliConductor = resp.secureIdDom;

          }
        });
      }
    }
  }

  getDomicilis(secureIdPersona, persona: string, secureIdDom) {
    this.personesService.getDomicilis(secureIdPersona).subscribe(dom => {
      if (persona === 'Propietari') {
        if (this.comboDomPropietari !== null && this.comboDomPropietari !== undefined) {
          this.comboDomPropietari.resetShowingOptions();
        }

        if (dom === undefined || dom === null || dom.length === 0) {
          this.domicilisPropietariDictionary = new BehaviorSubject(new Array<Dictionary>());
        } else {
          // this.domicilisConductor = dom;
          // this.comboDomConductor.resetCombo();

          this.domicilisPropietariDictionary = new BehaviorSubject(new Array<Dictionary>());
          const dict = new Array<Dictionary>();
          dom.forEach(element => {
            dict.push(new Dictionary(element.secure_id, element.domicili + ' (' + element.codiPostal + ' - ' + element.localitat + ')'));
          });
          this.domicilisPropietariDictionary.next(dict);
          if (secureIdDom !== undefined) {
            if (this.comboDomPropietari !== null && this.comboDomPropietari !== undefined) {
              this.comboDomPropietari.markAsTouched();
              this.comboDomPropietari.setSelect(secureIdDom);
              // this.comboDomPropietari.currentoption = dict.find(x => x.key === this.denunciaDetail.secure_id_domiciliConductor);
            }
          }
        }
        /*if (dom === undefined || dom === null || dom.length === 0){
          this.domicilisPropietari = [];
          this.comboDomPropietari.resetCombo();
        } else {
          this.domicilisPropietari = dom;
        }
        if ( secureIdDom !== undefined && secureIdDom !== null) {
          this.denunciaDetail.secure_id_domiciliPropietari = secureIdDom;
          this.comboDomPropietari.setSelect(dom.find(x => x.secure_id === secureIdDom));
        } else {
          this.denunciaDetail.secure_id_domiciliPropietari = '';
        }*/
      } else if (persona === 'Infractor') {
        this.comboDomConductor.resetShowingOptions();
        if (dom === undefined || dom === null || dom.length === 0) {
          this.domicilisConductorDictionary = new BehaviorSubject(new Array<Dictionary>());
        } else {
          // this.domicilisConductor = dom;
          // this.comboDomConductor.resetCombo();

          this.domicilisConductorDictionary = new BehaviorSubject(new Array<Dictionary>());
          const dict = new Array<Dictionary>();
          dom.forEach(element => {
            dict.push(new Dictionary(element.secure_id, element.domicili + ' (' + element.codiPostal + ' - ' + element.localitat + ')'));
          });
          this.domicilisConductorDictionary.next(dict);
          if (secureIdDom !== undefined) {
            this.comboDomConductor.markAsTouched();
            this.comboDomConductor.setSelect(secureIdDom);
            // this.comboDomConductor.currentoption = dict.find(x => x.key === this.denunciaDetail.secure_id_domiciliConductor);
          }
        }

      } else if (persona === 'TutorLegal') {
        this.comboDomTutorLegal.resetShowingOptions();
        if (dom === undefined || dom === null || dom.length === 0) {
          this.domicilisTutorLegalDictionary = new BehaviorSubject(new Array<Dictionary>());
        } else {
          // this.domicilisConductor = dom;
          // this.comboDomConductor.resetCombo();

          this.domicilisTutorLegalDictionary = new BehaviorSubject(new Array<Dictionary>());
          const dict = new Array<Dictionary>();
          dom.forEach(element => {
            dict.push(new Dictionary(element.secure_id, element.domicili + ' (' + element.codiPostal + ' - ' + element.localitat + ')'));
          });
          this.domicilisTutorLegalDictionary.next(dict);
          if (secureIdDom !== undefined) {
            this.comboDomTutorLegal.markAsTouched();
            this.comboDomTutorLegal.setSelect(secureIdDom);
            // this.comboDomConductor.currentoption = dict.find(x => x.key === this.denunciaDetail.secure_id_domiciliConductor);
          }
        }
      }
      /* if ( secureIdDom !== undefined && secureIdDom !== null) {
         this.denunciaDetail.secure_id_domiciliConductor = secureIdDom;
         this.comboDomConductor.setSelect(dom.find(x => x.secure_id === secureIdDom));
         }else{
           this.denunciaDetail.secure_id_domiciliConductor = '';
         }*/
    });
  }

  consultarConductor() {
    if (this.denunciaDetail.dni_conductor !== undefined
      && this.denunciaDetail.dni_conductor !== null
      && this.denunciaDetail.dni_conductor !== '') {
      this.personesService.findPersonaByDniNif(this.denunciaDetail.dni_conductor).subscribe((x: Persona[]) => {
        if (x !== undefined && x !== null && x.length > 0) {
          if (x.length === 1) {
            this.denunciaDetail.secure_id_conductor = x[0].secure_id;
            this.denunciaDetail.nom_conductor = x[0].nomComplet;
            // TODO: Domicili
            this.getDomicilis(x[0].secure_id, 'Infractor', x[0].SECURE_ID_DOMICILI_ACTUAL);
          } else if (x.length > 1) {
            const filter: PersonaFilter = new PersonaFilter('id', 'asc');
            filter.dni_pasp = this.denunciaDetail.dni_conductor;
            // this.filterService.saveFilter('persona-filter', filter);
            // Carraguem la taula filtrada per dni
            const data: ComponentDialog = Builder.newComponentDialog(true, this.isReadOnly, '', filter);
            const ref = this.dialog.open(PersonesComponent, {
              width: '80%',
              height: '90%',
              closeOnNavigation: false,
              autoFocus: true,
              data: data
            });

            ref.afterClosed().subscribe(async resp => {
              if (resp.codi !== undefined && resp.codi !== null && resp.codi !== '') {
                this.denunciaDetail.secure_id_conductor = resp.codi;
                this.denunciaDetail.secure_id_conductor = resp.secure_id;
                this.denunciaDetail.nom_conductor = resp.nomComplet;
                this.denunciaDetail.dni_conductor = resp.dni_pasp;
                this.denunciaDetail.secure_id_domiciliConductor = resp.secureIdDom;
                // : nomComplet, dni_pasp: dni_pasp
                this.getDomicilis(this.denunciaDetail.secure_id_conductor, 'Infractor', resp.secureIdDom);
                this.denunciaDetail.secure_id_domiciliConductor = resp.secureIdDom;

              }
            });

          }

        } else {
          // Obrim dialeg per crear persona
          this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('No s\ha trobat cap persona amb aquest DNI o Passaport. Vols crear-la ara?'), this.i18n('Si'), this.i18n('No'))
            .then((confirmed) => {
              if (confirmed) {
                const data: ComponentDialog = Builder.newComponentDialog(true, this.isReadOnly, null);
                const ref = this.dialog.open(PersonesEditorComponent, {
                  width: '80%',
                  height: '90%',
                  closeOnNavigation: false,
                  autoFocus: true,
                  data: {
                    isDialog: true,
                    isReadOnly: this.isReadOnly,
                    id: null,
                    filter: null,
                    dni: this.denunciaDetail.dni_conductor
                  }
                });

                ref.afterClosed().subscribe(async resp => {
                  if (resp.codi !== undefined && resp.codi !== null && resp.codi !== '') {
                    this.denunciaDetail.secure_id_conductor = resp.codi;
                    this.denunciaDetail.secure_id_conductor = resp.secure_id;
                    this.denunciaDetail.nom_conductor = resp.nomComplet;
                    this.denunciaDetail.dni_conductor = resp.dni_pasp;
                    this.denunciaDetail.secure_id_domiciliConductor = resp.secureIdDom;
                    // : nomComplet, dni_pasp: dni_pasp
                    this.getDomicilis(this.denunciaDetail.secure_id_conductor, 'Infractor', resp.secureIdDom);
                    this.denunciaDetail.secure_id_domiciliConductor = resp.secureIdDom;

                  }
                });

              } else {
                this.denunciaDetail.nom_conductor = '';
                this.domicilisConductorDictionary = new BehaviorSubject(new Array<Dictionary>());
              }
            });
        }
      });
    } else {
      // Obrim dialeg per crear persona
      this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('No s\ha trobat cap persona amb aquest DNI o Passaport. Vols crear-la ara?'), this.i18n('Si'), this.i18n('No'))
        .then((confirmed) => {
          if (confirmed) {
            const data: ComponentDialog = Builder.newComponentDialog(true, this.isReadOnly, null);
            const ref = this.dialog.open(PersonesEditorComponent, {
              width: '80%',
              height: '90%',
              closeOnNavigation: false,
              autoFocus: true,
              data: {
                isDialog: true,
                isReadOnly: this.isReadOnly,
                id: null,
                filter: null,
                dni: this.denunciaDetail.dni_conductor
              }
            });
            ref.afterClosed().subscribe(async resp => {
              if (resp.codi !== undefined && resp.codi !== null && resp.codi !== '') {
                this.denunciaDetail.secure_id_conductor = resp.codi;
                this.denunciaDetail.secure_id_conductor = resp.secure_id;
                this.denunciaDetail.nom_conductor = resp.nomComplet;
                this.denunciaDetail.dni_conductor = resp.dni_pasp;
                this.denunciaDetail.secure_id_domiciliConductor = resp.secureIdDom;
                // : nomComplet, dni_pasp: dni_pasp
                this.getDomicilis(this.denunciaDetail.secure_id_conductor, 'Infractor', resp.secureIdDom);
                this.denunciaDetail.secure_id_domiciliConductor = resp.secureIdDom;
              }
            });
          } else {
            this.denunciaDetail.nom_conductor = '';
            this.domicilisConductorDictionary = new BehaviorSubject(new Array<Dictionary>());
          }
        });
    }

  }

  consultarPropietari() {
    if (this.denunciaDetail.dni_propietari !== undefined
      && this.denunciaDetail.dni_propietari !== null
      && this.denunciaDetail.dni_propietari !== '') {
      this.personesService.findPersonaByDniNif(this.denunciaDetail.dni_propietari).subscribe((x: Persona[]) => {
        if (x !== undefined && x !== null && x.length > 0) {
          if (x.length === 1) {
            this.denunciaDetail.secure_id_propietari = x[0].secure_id;
            this.denunciaDetail.nom_propietari = x[0].nomComplet;
            this.getDomicilis(x[0].secure_id, 'Propietari', x[0].SECURE_ID_DOMICILI_ACTUAL);
          } else if (x.length > 1) {
            const filter: PersonaFilter = new PersonaFilter('id', 'asc');
            filter.dni_pasp = this.denunciaDetail.dni_propietari;
            // Carraguem la taula filtrada per dni
            const data: ComponentDialog = Builder.newComponentDialog(true, this.isReadOnly, '', filter);
            const ref = this.dialog.open(PersonesComponent, {
              width: '80%',
              height: '90%',
              closeOnNavigation: false,
              autoFocus: true,
              data: data
            });

            ref.afterClosed().subscribe(async resp => {
              if (resp.codi !== undefined && resp.codi !== null && resp.codi !== '') {
                this.denunciaDetail.secure_id_propietari = resp.codi;
                this.denunciaDetail.secure_id_propietari = resp.secure_id;
                this.denunciaDetail.nom_propietari = resp.nomComplet;
                this.denunciaDetail.dni_propietari = resp.dni_pasp;
                this.denunciaDetail.secure_id_domiciliPropietari = resp.secureIdDom;
                // : nomComplet, dni_pasp: dni_pasp
                this.getDomicilis(this.denunciaDetail.secure_id_conductor, 'Propietari', resp.secureIdDom);
                this.denunciaDetail.secure_id_domiciliPropietari = resp.secureIdDom;

              }
            });

          }
        } else {
          // Obrim dialeg per crear persona
          this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('No s\ha trobat cap persona amb aquest DNI o Passaport. Vols crear-la ara?'), this.i18n('Si'), this.i18n('No'))
            .then((confirmed) => {
              if (confirmed) {
                const data: ComponentDialog = Builder.newComponentDialog(true, this.isReadOnly, null);
                const ref = this.dialog.open(PersonesEditorComponent, {
                  width: '80%',
                  height: '90%',
                  closeOnNavigation: false,
                  autoFocus: true,
                  data: {
                    isDialog: true,
                    isReadOnly: this.isReadOnly,
                    id: null,
                    filter: null,
                    dni: this.denunciaDetail.dni_propietari
                  }
                });

                ref.afterClosed().subscribe(async resp => {
                  if (resp.codi !== undefined && resp.codi !== null && resp.codi !== '') {
                    this.denunciaDetail.secure_id_propietari = resp.codi;
                    this.denunciaDetail.secure_id_propietari = resp.secure_id;
                    this.denunciaDetail.nom_propietari = resp.nomComplet;
                    this.denunciaDetail.dni_propietari = resp.dni_pasp;
                    this.denunciaDetail.secure_id_domiciliPropietari = resp.secureIdDom;
                    // : nomComplet, dni_pasp: dni_pasp
                    this.getDomicilis(this.denunciaDetail.secure_id_domiciliPropietari, 'Propietari', resp.secureIdDom);
                    this.denunciaDetail.secure_id_domiciliPropietari = resp.secureIdDom;
                  }
                });
              } else {
                this.denunciaDetail.nom_propietari = '';
                this.domicilisPropietariDictionary = new BehaviorSubject(new Array<Dictionary>());
              }
            });
        }
      });
    } else {
      // Obrim dialeg per crear persona
      this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('No s\ha trobat cap persona amb aquest DNI o Passaport. Vols crear-la ara?'), this.i18n('Si'), this.i18n('No'))
        .then((confirmed) => {
          if (confirmed) {
            // const data: ComponentDialog = Builder.newComponentDialog(true, this.isReadOnly, null);
            const ref = this.dialog.open(PersonesEditorComponent, {
              width: '80%',
              height: '90%',
              closeOnNavigation: false,
              autoFocus: true,
              data: {
                isDialog: true,
                isReadOnly: this.isReadOnly,
                id: null,
                filter: null,
                dni: this.denunciaDetail.dni_propietari
              }
            });
            ref.afterClosed().subscribe(async data => {
              if (data.codi !== undefined && data.codi !== null && data.codi !== '') {
                this.denunciaDetail.secure_id_propietari = data.codi;
                this.denunciaDetail.secure_id_propietari = data.secure_id;
                this.denunciaDetail.nom_propietari = data.nomComplet;
                this.denunciaDetail.dni_propietari = data.dni_pasp;
                this.denunciaDetail.secure_id_domiciliPropietari = data.secureIdDom;
                // : nomComplet, dni_pasp: dni_pasp
                this.getDomicilis(this.denunciaDetail.secure_id_propietari, 'Propietari', data.secureIdDom);
                this.denunciaDetail.secure_id_domiciliPropietari = data.secureIdDom;
              }
            });

          } else {
            this.denunciaDetail.nom_propietari = '';
            this.domicilisPropietariDictionary = new BehaviorSubject(new Array<Dictionary>());
          }
        });
    }

  }


  loadDomiciliosActuales() {
    if (this.denunciaDetail.secure_id_propietari !== undefined && this.denunciaDetail.secure_id_propietari !== null) {
      let sIdDom = null;
      if (this.denunciaDetail.secure_id_domiciliPropietari !== undefined && this.denunciaDetail.secure_id_domiciliPropietari !== null) {
        sIdDom = this.denunciaDetail.secure_id_domiciliPropietari;
      }
      this.getDomicilis(this.denunciaDetail.secure_id_propietari, 'Propietari', sIdDom);
    }

    if (this.denunciaDetail.secure_id_conductor !== undefined && this.denunciaDetail.secure_id_conductor !== null) {
      let sIdDom = null;
      if (this.denunciaDetail.secure_id_domiciliConductor !== undefined && this.denunciaDetail.secure_id_domiciliConductor !== null) {
        sIdDom = this.denunciaDetail.secure_id_domiciliConductor;
      }
      this.getDomicilis(this.denunciaDetail.secure_id_conductor, 'Infractor', sIdDom);
    }

    if (this.denunciaDetail.secure_id_tutorLegal !== undefined && this.denunciaDetail.secure_id_tutorLegal !== null) {
      let sIdDom = null;
      if (this.denunciaDetail.secure_id_domiciliTutorLegal !== undefined && this.denunciaDetail.secure_id_domiciliTutorLegal !== null) {
        sIdDom = this.denunciaDetail.secure_id_domiciliTutorLegal;
      }
      this.getDomicilis(this.denunciaDetail.secure_id_tutorLegal, 'TutorLegal', sIdDom);
    }
  }


  domicilioConductorSeleccionado(secure_id: string) {
    this.denunciaDetail.secure_id_domiciliConductor = secure_id;
  }

  domicilioPropietarioSeleccionado(secure_id: string) {
    this.denunciaDetail.secure_id_domiciliPropietari = secure_id;
  }

  domicilioTutorLegalSeleccionado(secure_id: string) {
    this.denunciaDetail.secure_id_domiciliTutorLegal = secure_id;
  }

  zonaCarrerSeleccionado(secure_id: string) {
    this.denunciaDetail.secure_id_zona = secure_id;
  }

  tipusDenunciaSeleccionado(tipusDenunciaId: string) {
    this.denunciaDetail.tipusDenuncia = tipusDenunciaId;
  }

  tipusGravetatSeleccionado(gravetatId: string) {
    this.denunciaDetail.qualificacio = gravetatId;
  }

  seleccionarVehicle(matricula = null) {
    const modeLectura: boolean = this.isReadOnly;
    if (matricula !== null) {
      const data: ComponentDialog = Builder.newComponentDialog(true, this.isReadOnly, matricula);
      const dataAux = <any>data;
      dataAux.editable = false;
      const ref = this.dialog.open(VehiclesEditorComponent, {
        width: '40%',
        // height: '90%',
        closeOnNavigation: false,
        autoFocus: true,
        data: dataAux,
        disableClose: true
      });
      ref.afterClosed().subscribe(resp => {

        if (!modeLectura && resp.matricula !== undefined && resp.matricula !== null && resp.matricula !== '') {
          this.denunciaDetail.secure_id_vehicle = resp.secure_id;
          this.denunciaDetail.matricula = resp.matricula;
          this.denunciaDetail.modelo = resp.model;
          this.denunciaDetail.marca = resp.marca;
          this.denunciaDetail.pais = resp.pais;
          this.denunciaDetail.paisDesc = resp.paisDesc;
          this.saveDenuncia();
        }
      });
    } else {
      if (!Reflection.isNull(this.denunciaDetail) && !Reflection.empty(this.denunciaDetail.matricula) && this.isReadOnly) {
        this.toastr.error(this.i18n('Vehicle no trobat!'));
      } else {
        const data: ComponentDialog = Builder.newComponentDialog(true, this.isReadOnly, this.denunciaDetail.matricula);
        const ref = this.dialog.open(VehiclesComponent, {
          width: '80%',
          // height: '90%',
          closeOnNavigation: false,
          autoFocus: true,
          data: data
        });

        ref.afterClosed().subscribe(resp => {

          if (!modeLectura && resp.matricula !== undefined && resp.matricula !== null && resp.matricula !== '') {
            this.denunciaDetail.secure_id_vehicle = resp.secure_id;
            this.denunciaDetail.matricula = resp.matricula;
            this.denunciaDetail.modelo = resp.model;
            this.denunciaDetail.marca = resp.marca;
            this.denunciaDetail.pais = resp.pais;
            this.denunciaDetail.paisDesc = resp.paisDesc;
          }
        });
      }
    }
  }

  OpenDgt() {
    if (this.isReadOnly) {
      const ref = this.dialog.open(XmlTreeViewerComponent, {
        width: '75%',
        height: '75%',
        closeOnNavigation: true,
        autoFocus: true,
        data: {
          'isDialog': true,
          'isReadOnly': this.isReadOnly,
          'matricula': '',
          'persona': '',
          'propietario': '',
          'incorporaData': false,
          'consulta': true
        }
      });
    } else {
      if (this.denunciaDetail.matricula !== undefined && this.denunciaDetail.matricula !== null && this.denunciaDetail.matricula !== '') {
        const conductor: boolean = this.denunciaDetail.dni_conductor !== undefined && this.denunciaDetail.dni_conductor !== null && this.denunciaDetail.dni_conductor !== '';
        const propietari: boolean = this.denunciaDetail.dni_propietari !== undefined && this.denunciaDetail.dni_propietari !== null && this.denunciaDetail.dni_propietari !== '';
        const ref = this.dialog.open(DgtFilterComponent, {
          width: '30%',
          height: '20%',
          closeOnNavigation: true,
          autoFocus: true,
          data: {
            'conductor': conductor,
            'propietari': propietari
          }
        });
        ref.afterClosed().subscribe(data => {
          if (!data.filtre !== undefined && data.filtre !== null && data.filtre !== '') {
            if (data.filtre === '1') {
              this.openXmlMatricula();
            } else if (data.filtre === '2') {
              this.openXmlPropietari();              
            } else if (data.filtre === '3') {              
              this.openXmlPersona();
            }
          }
        });
      }
    }
  }
  openXmlMatricula() {
    if (this.denunciaDetail.matricula !== undefined && this.denunciaDetail.matricula !== null && this.denunciaDetail.matricula !== '') {
      const ref = this.dialog.open(XmlTreeViewerComponent, {
        width: '75%',
        height: '75%',
        closeOnNavigation: true,
        autoFocus: true,
        data: {
          'isDialog': true,
          'isReadOnly': this.isReadOnly,
          'matricula': this.denunciaDetail.matricula,
          'persona': '',
          'propietario': '',
          'incorporaData': true,
          'consulta': false,
          'secureIdDenuncia': this.denunciaDetail.secure_id_denuncia,
          'fechaDenuncia': this.denunciaDetail.data
        }
      });

      ref.afterClosed().subscribe(data => {
        if (data !== undefined && data !== null && data !== '') {
          this.refrescar();
          this.toastr.success(this.i18n('Denuncia actualitzada correctament'));

        }
      });
        // if (data !== undefined && data !== null &&
        //   data.marca !== undefined &&  data.marca !== null &&  data.marca !== '' &&
        //   data.model !== undefined && data.model !== null && data.model !== '') {
        //     this.denunciaDetail.marca = data.marca;
        //     this.denunciaDetail.modelo = data.model;
        //     this.operaciones.addOperation(data.operacio);
        //     this.toastr.success(this.i18n('Denuncia actualitzada correctament'));
        //   }
        //   if (data !== undefined && data !== null &&
        //     data.persona !== undefined &&  data.persona !== null ) {
        //       this.denunciaDetail.secure_id_propietari =  data.persona.secure_id;
        //       this.denunciaDetail.nom_propietari =  data.persona.nomComplet;
        //       this.denunciaDetail.dni_propietari = data.persona.dni_pasp;
        //       this.getDomicilis (data.persona.secure_id, 'Propietari',  data.persona.SECURE_ID_DOMICILI_ACTUAL);
        //     }
      
    }
  }

  openXmlPersona() {
    if (this.denunciaDetail.matricula !== undefined && this.denunciaDetail.matricula !== null && this.denunciaDetail.matricula !== '') {
      const ref = this.dialog.open(XmlTreeViewerComponent, {
        width: '75%',
        height: '75%',
        closeOnNavigation: true,
        autoFocus: true,
        data: {
          'isDialog': true,
          'isReadOnly': this.isReadOnly,
          'matricula': '',
          'persona': this.denunciaDetail.dni_conductor,
          'propietario': '',
          'incorporaData': true,
          'consulta': false,
          'secureIdDenuncia': this.denunciaDetail.secure_id_denuncia,
          'fechaDenuncia': this.denunciaDetail.data
        }
      });
      ref.afterClosed().subscribe(data => {
        if (data !== undefined && data !== null && data !== '') {
          this.refrescar();
          this.toastr.success(this.i18n('Denuncia actualitzada correctament'));

        }
      });
    }
  }
  openXmlPropietari() {
    if (this.denunciaDetail.matricula !== undefined && this.denunciaDetail.matricula !== null && this.denunciaDetail.matricula !== '') {
      const ref = this.dialog.open(XmlTreeViewerComponent, {
        width: '75%',
        height: '75%',
        closeOnNavigation: true,
        autoFocus: true,
        data: {
          'isDialog': true,
          'isReadOnly': this.isReadOnly,
          'matricula': '',
          'persona': this.denunciaDetail.dni_propietari,
          'propietario': '',
          'incorporaData': true,
          'consulta': false,
          'secureIdDenuncia': this.denunciaDetail.secure_id_denuncia,
          'fechaDenuncia': this.denunciaDetail.data
        }
      });
      ref.afterClosed().subscribe(data => {
        if (data !== undefined && data !== null && data !== '') {
          this.refrescar();
          this.toastr.success(this.i18n('Denuncia actualitzada correctament'));

        }
      });
    }
  }

  OpenNotifications() {
    const ref = this.dialog.open(NotificacionsComponent, {
      width: '90%',
      height: '90%',
      data: { 'element': this.denunciaDetail.expedient }

    });
  }
  // Calculem la data de descompte de la denuncia
  calcularDataDescompte() {
    if (typeof (this.denunciaDetail.data) === 'object' && this.denunciaDetail.data !== null && this.denunciaDetail.data !== undefined) {
      this.denunciaDetail.dataDescompte = new Date();
      this.denunciaDetail.dataDescompte.setDate(this.denunciaDetail.data.getDate() + this.diesDescompte);
    }
  }

  calcularImportDescompte() {
    if (this.denunciaDetail.import < this.denunciaDetail.importMinim || this.denunciaDetail.import > this.denunciaDetail.importMaxim) {
      setTimeout(() => {
        this.denunciaDetail.import = this.denunciaDetailOld.import;
      }, 10);      
      if(this.denunciaDetail.importMinim !== this.denunciaDetail.importMaxim ){
        this.toastr.warning(this.i18n(`El valor del importe debe estar entre ${this.denunciaDetail.importMinim} € y ${this.denunciaDetail.importMaxim} €`));
      }      
      return;
    }
    if (this.denunciaDetail.import !== undefined && this.denunciaDetail.import !== null
      && this.denunciaDetail.porcentajeDescuento !== undefined && this.denunciaDetail.porcentajeDescuento !== null) {
      this.denunciaDetail.importDescompte = Number((this.denunciaDetail.import * (1 - (this.denunciaDetail.porcentajeDescuento / 100))).toFixed(2));
    }
  }
  solicitarRetiradaGrua() {
    this.denunciesService.solicitarRetiradaGrua(this.denunciaDetail.secure_id_denuncia).subscribe(x => {
      this.denunciaDetail.tieneRetiradaGrua = true;
      this.toastr.success(this.i18n('Solicitud generada correctamente'));
    });
  }

  report() {
    const somIdDenuncia = this.denunciaDetail.somId;
    const idPropietari = this.denunciaDetail.secure_id_propietari;
    const idConductor = this.denunciaDetail.secure_id_conductor;
    const ref = this.dialog.open(ReportsComponent, {
      width: '30%',
      height: '30%',
      closeOnNavigation: false,
      autoFocus: true,
      data: this.reports
    }).afterClosed().subscribe(data => {
        const reportInfo = {
          'id': somIdDenuncia,
          'tipusReport' : data.tipusReport,
          'isComuna' : true,
          'nomFitxer' : data.nomFitxer,
          'nom' : data.nom
        };
        let usableDataStructure = Object.assign({}, reportInfo);
        for (let [key, value] of Object.entries(reportInfo)) {
            usableDataStructure[key] = reportInfo[key];
        }
        if (data.nom != 'CARTA DE PAGO' || (data.nom == 'CARTA DE PAGO' && ((idPropietari != '' && idPropietari != null) || (idConductor != '' && idConductor != null)))) {
          this.reportService.generateReport(usableDataStructure, this.URL_REPORT).subscribe(result => {
            if (result) {
              const objectUrl=URL.createObjectURL(result.body);
              window.open(objectUrl);
            }
            else {
              const contentType = result.headers.get('Content-Type');
              if (contentType !== null && contentType !== undefined) {
                  FileDownloader.downLoadFile([result.body],  contentType + ';charset=utf-8', "CARTA");
              } 
              else {
              this.toastr.error(this.i18n('No s\'ha pogut generar el fitxer'));
              }
            }
          });
        }
        else {
          this.toastr.warning('Falta informar propietario o conductor');
        }
    });
  }

  openMap() {
    const ref = this.dialog.open(MapComponent, {
      width: '50%',
      height: '50%'
    });
    ref.afterOpen().subscribe(() => {

      let customDataMarker = this.crearMarker('1', 'DENUNCIA', null, +this.denunciaDetail.latitud, +this.denunciaDetail.longitud, '#fc0303', '\uf3c5');

      let arrayCustomDataMarker = new Array<CustomDataMarker>();
      arrayCustomDataMarker.push(customDataMarker);
      ref.componentInstance.appendNewMarkers(arrayCustomDataMarker);
    })
    ref.componentInstance.currentLocation.latitude = +this.denunciaDetail.latitud;
    ref.componentInstance.currentLocation.longitude = +this.denunciaDetail.longitud;
    ref.componentInstance.zoom = 17;

  }

  crearMarker(associatedId: any, featureType: string, text: String, latitude: number, longitude: number, color: String, icon: String): CustomDataMarker {
    return new CustomDataMarker(
      associatedId,
      featureType,
      text,
      latitude,
      longitude,
      'google.maps.SymbolPath.CIRCLE',
      color,
      0.15,
      icon,
      false,
      false,
      false);
  }
  /** Abre un diálogo con la tabla de denuncias filtrada por el infractor reincidente */
  openReincidencias() {
    const filter: DenunciaFilter = new DenunciaFilter('', '');
    filter.convivencia = true;
    filter.dni = this.denunciaDetail.dni_conductor;
    const data: ComponentDialog = Builder.newComponentDialog(true, false, '', filter);
    const dlg = this.dialog.open(DenunciesTableComponent, {
      closeOnNavigation: false,
      autoFocus: true,
      width: '90%',
      data: data,
    });


    if (this.dialogData === undefined) {
      if (close) {
        this.router.navigate(['denuncies']);
      }}
  }
  
  getImatgeEtiqueta(nomEtiqueta: string): string {

    let linea:string="assets/images/"
    linea = linea+nomEtiqueta
    return linea
    
  }
}
