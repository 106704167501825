import { Component, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Acuse } from 'app/sections/tramitar/models/acus-de-rebut.model';
import { DenunciesFilterService } from 'path-denuncies-services/denuncies-filter.service';
import { Builder } from 'path-shared/models/builder';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { PaginationFilter } from 'path-shared/models/pagination.filter';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { AcuseFilter } from '../../models/acus-de-rebut.filter';
import { AcusosFilterService } from '../../services/acusos-filter.service';
import { AcusosService } from '../../services/acusos.service';
import { ExpedientsComponent } from 'app/sections/expedients/expedients.component';
import { DenunciesService } from 'path-denuncies-services/denuncies.service';
import { DenunciesEditorComponent } from 'app/sections/denuncies/components/denuncies-editor/denuncies-editor.component';

@Component({
  selector: 'app-acusos-table',
  templateUrl: './acusos-table.component.html',
  styleUrls: ['./acusos-table.component.scss']
})
export class AcusosTableComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataDialog: ComponentDialog = Builder.newComponentDialog();
  expedientsDialog: MatDialogRef<ExpedientsComponent>;
    
  data: Acuse[];
  dataSource: CustomDataSource<Acuse, AcuseFilter>;

  filter: AcuseFilter = new AcuseFilter('expedient', 'asc');
  filterPage: PaginationFilter;

  @Input() updatedFilterData: AcuseFilter;
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();

  displayedColumns = ['check','expedient', 'numDenuncia', 'tipus', 'resultat', 'dataNotificacio', 'nomComplet'];
  displayedItems = [];

  constructor(
    private filterService: FilterService,
    private denunciesService: DenunciesService,
    private dialog: MatDialog,
    private acusosService: AcusosService,
    @Inject(MAT_DIALOG_DATA) public dataOfDialog: ComponentDialog
    ) {
      if (dataOfDialog !== undefined && dataOfDialog.filter !==  undefined) {
        this.dataDialog = dataOfDialog;
      }
    }

  ngOnInit() {
    this.dataSource = CustomDataSource.create<Acuse, AcuseFilter>(this.paginator, new AcusosFilterService(), 'urlGetAcusos', 'urlAcusosPaginationCount');
    this.dataSource.subject.subscribe((data) => {
      this.acusosService.displayedItems = [];
      this.acusosService.displayedItems['checkedValues'] = 0;
      data.forEach(item => {
        this.displayedItems[item.expedient] = {
          expedient: item.expedient,
          checked: false,
          tipus: item.tipus,
          resultat: item.resultat,
          dataNotificacio: item.dataNotificacio,
          nomComplet: item.nomComplet,
          numDenuncia: item.numDenuncia
        };
      });
    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.paginator.pageIndex = 0;
        this.filterService.saveFilter('acusos-filter', this.filter);
        this.loadAcusesPage();
      }
    });

    this.filter.filterCleared.subscribe(updated => {
      if (updated) {
        this.paginator.pageIndex = 0;
        this.filterService.saveFilter('acusos-filter', this.filter);
        this.loadAcusesPage();
      }
    });
    if (this.dataDialog !== undefined && this.dataDialog.filter !== null) {
      this.filter.set(this.dataDialog.filter);
      this.displayedColumns.splice(0, 1);
    } 
    else {
       this.filterService.loadFilter('acusos-filter', this.filter);
    }
    this.filter.paginator.init(this.sort, this.paginator);
    console.log(this.filter);
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    } );

    this.paginator.page.subscribe(data => {
      this.paginator.pageIndex = data.pageIndex;
      this.paginator.pageSize = data.pageSize;
      this.loadAcusesPage();
      console.log('paginator size: ' + data.pageSize);
    });

    this.loadAcusesPage();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      this.updateAcusesPage();
    }
  }

  updateAcusesPage() {
    this.updatedFilterData.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.updatedFilterData);
  }

  loadAcusesPage() {
    if (this.dataSource !== undefined) {
      this.filter.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.filter);
    }
  }

  load() {
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }

  toggleAllCheckbox() {
    for (const item in this.displayedItems) {
      if (item !== 'checkedValues') {
        this.toggleItem(item);
      }
    }

    // this.getAllSelectedItems();
  }

  toggleItem(id) {
    const itemStatus = this.displayedItems[id].checked = !this.displayedItems[id].checked;

    if (itemStatus) {
      this.displayedItems['checkedValues'] = this.getCheckedValues() + 1;
    } else {
      this.displayedItems['checkedValues'] = this.getCheckedValues() - 1;
    }
  }

  getCheckedValues() {
    return this.displayedItems['checkedValues'];
  }

  getButlleti(row) {
    const data: ComponentDialog = Builder.newComponentDialog(true, true, row.somIdDenuncia);
    const ref = this.dialog.open(DenunciesEditorComponent, {
      width: '99%',
      height: '99%',
      maxHeight: '100vh',
      closeOnNavigation: false,
      autoFocus: true,
      data: data
    });
  }

  getExpedients(expedient: string, numeroDenuncia: string) {
    this.denunciesService.getExpedient(expedient.toString()).subscribe( expedientsData => {
      this.denunciesService.getDenunciaImages(numeroDenuncia).subscribe( images => {
        this.expedientsDialog = this.dialog.open(ExpedientsComponent,
        {
          height: '80%',
          width: '52%',
          closeOnNavigation: false,
          autoFocus: true,
          data: {'expedient': expedientsData, 'numDenuncia': numeroDenuncia, 'imatges': images}

        });
      });
    });
  }
}
