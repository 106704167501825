import { SortDirection } from "@angular/material";
import { FilterData } from "path-shared/models/filter-data";

export class ErrorsMatriculaFilter extends FilterData {
    nomesNoves: boolean;
    nomesVelles: boolean;
    exp: string;
    matricula: string;

    constructor(sortField: string, sortDir: SortDirection) {
        super(sortField, sortDir);
        this.nomesNoves = null;
        this.nomesVelles = null;
        this.exp = '';
        this.matricula = '';

        this.labels['nomesNoves'] = this.i18n('No mostrar matrículas con errores');
        this.labels['nomesVelles'] = this.i18n('Mostrar solo matrículas con errores');
        this.labels['exp'] = this.i18n('Expedient');
        this.labels['matricula'] = this.i18n('Matrícula');
    }
}