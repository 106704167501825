import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatTable, MatTableDataSource } from '@angular/material';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { ColorsEditorComponent } from 'app/sections/configuracio/colors/components/colors-editor/colors-editor.component';
import { FormService } from 'path-shared/services/form/form.service';
import { Builder } from 'path-shared/models/builder';
import { ZonaAparcare } from '../../models/zona-aparcare.model';
import { ZonesAparcareService } from '../../services/zones-aparcare.service';
import { TarifesService } from 'app/sections/tiquets/tarifes/services/tarifes.service';

@Component({
  selector: 'app-zones-aparcare-editor',
  templateUrl: './zones-aparcare-editor.component.html',
  styleUrls: ['./zones-aparcare-editor.component.scss']
})
export class ZonesAparcareEditorComponent implements OnInit {

  secure_id: string;
  zonaAparcare: ZonaAparcare;
  dialogData: ComponentDialog;
  closeButtonText: string;
  publicarAvailable = false;
  tarifes = [];
  @ViewChild('mainForm') private mainForm: ElementRef;

  constructor(
    private dialog: MatDialog,
    private confirmationDialogService: ConfirmationDialogService,
    public auth: AuthorizationService,
    private zonesAparcareService: ZonesAparcareService,
    private i18n: I18n,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    public thisDialogRef: MatDialogRef<ZonesAparcareEditorComponent>,
    private formService: FormService,
    private tarifesService: TarifesService,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
    ) {

      this.zonaAparcare = new ZonaAparcare();
      this.dialogData = data;
      this.secure_id = this.dialogData.id;
      if (this.dialogData.isReadOnly) {
        this.closeButtonText = this.i18n('Tancar');
      } else {
        this.closeButtonText = this.i18n('Llista');
        this.publicarAvailable = true;
      }
      this.tarifesService.getTarifesCombo().subscribe(resp => {
        this.tarifes = resp;
      });
   }

  ngOnInit() {
    if (this.secure_id !== null) {
      this.zonesAparcareService.getZona(this.secure_id).subscribe(data => {
        this.zonaAparcare = data;
      });

    }
  }

  save() {
    if (this.formService.allFieldsValid(this.mainForm)) {
      this.zonesAparcareService.setZona(this.zonaAparcare).subscribe(result => {
        this.toastr.info(this.i18n('Zona guardada correctament'));
        this.thisDialogRef.close(true);
      });
    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
    }
  }


  cancel() {
    this.thisDialogRef.close(false);
  }

  // remove() {
  //   this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol esborrar la tarifa?'))
  //   .then((confirmed) => {
  //       if (confirmed) {
  //         this.tarifesService.removeTarifa(this.secure_id).subscribe(result => {
  //           this.toastr.success(this.i18n('Tarifa esborrada correctament'));
  //           this.dialog.closeAll();
  //           this.thisDialogRef.close(true);
  //         });
  //       }
  //     });
  // }

}
