import { Component, OnInit, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { PermissionDialogComponent } from 'path-shared/components/permission-dialog/permission-dialog.component';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { ObservacionsPdaFilter } from '../../models/observacions-pda-filter';
import { ObservacionsPdaTableComponent } from '../../components/observacions-pda-table/observacions-pda-table.component';
import { ObservacionsPdaFilterComponent } from '../../components/observacions-pda-filter/observacions-pda-filter.component';
import { ObservacionsPdaEditorComponent } from '../../components/observacions-pda-editor/observacions-pda-editor.component';


@Component({
  selector: 'app-observacions-pda',
  templateUrl: './observacions-pda.component.html',
  styleUrls: ['./observacions-pda.component.scss']
})
export class ObservacionsPdaComponent implements OnInit, AfterViewInit {

  permissionDialogRef: MatDialogRef<PermissionDialogComponent>;
  activeFilters: string[] = [];
  filterData: ObservacionsPdaFilter;
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  @ViewChild(ObservacionsPdaTableComponent) observacionsPdaTable: ObservacionsPdaTableComponent;

  constructor(
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private i18n: I18n,
    private not: NotificationService,
    public thisDialogRef: MatDialogRef<ObservacionsPdaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
    ) {
      this.filterData = new ObservacionsPdaFilter('codi', 'asc');
      if (data !== undefined) {
        this.dataDialog = data;
      }
    }

  ngOnInit() {
    this.auth.UserCanSeePage(this.auth.Pages.ObservacionsPDA);
    this.observacionsPdaTable.itemClicked.subscribe(secure_id => {
      this.update(secure_id);
    });
    this.observacionsPdaTable.itemSelected.subscribe(codi => {
      this.thisDialogRef.close(codi);
    });
  }

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Observaciones PDA'));
    }
  }

  edit() {
    const idSeleccionat: string = this.observacionsPdaTable.displayedItems['checkedItems'][0];
    this.update(idSeleccionat);
  }

  create() {
    this.update(null);
  }

  update(secure_id: string) {
    const data: ComponentDialog = Builder.newComponentDialog(true, false, secure_id);

    const ref = this.dialog.open(ObservacionsPdaEditorComponent, {
      width: '60%',
      closeOnNavigation: false,
      autoFocus: true,
      data: data
    });

    ref.afterClosed().subscribe(updated => {
      if (updated) { this.observacionsPdaTable.load(); }
    });
  }

  refresh() {
    this.observacionsPdaTable.load();
  }

  onFilterClicked() {
    const ref = this.dialog.open(ObservacionsPdaFilterComponent, {
      width: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.observacionsPdaTable.filter
      }
    });

    ref.componentInstance.notify.subscribe((filter: ObservacionsPdaFilter) => {
      this.observacionsPdaTable.filter.set(filter);
      this.observacionsPdaTable.load();
    });
  }

  onRowSelected(somid) {
    this.update(somid);
  }

  generateExcel() {
    this.observacionsPdaTable.generateExcel();
  }

}
