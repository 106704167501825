import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { Component, OnInit, AfterViewInit, OnChanges, ViewChild, SimpleChanges, Input, EventEmitter, Output } from '@angular/core';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CarrersFilter } from '../../models/carrers-filter';
import { Carrer } from '../../models/carrer.model';
import { CarrersFilterService } from '../../services/carrers-filter-service';
import { CarrersService } from '../../services/carrers-service.service';
import { AppComponent } from 'app/app.component';

@Component({
  selector: 'app-carrers-table',
  templateUrl: './carrers-table.component.html',
  styleUrls: ['./carrers-table.component.scss']
})
export class CarrersTableComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  data: Carrer[];
  dataSource: CustomDataSource<Carrer, CarrersFilter>;
  highlightedRows = [];
  filter: CarrersFilter = new CarrersFilter('id', 'asc');
  filterPage: CarrersFilter;
  displayedItems = [];

  @Input() selectButton: boolean;
  @Input() updatedFilterData: CarrersFilter;
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();
  @Output() itemClicked: EventEmitter<number> = new EventEmitter<number>();
  @Output() itemSelected: EventEmitter<any> = new EventEmitter<any>();

  displayedColumns = ['id', 'sigles', 'nomCarrer', 'codigoExterno', 'nomMunicipi'];

  constructor(
    private carrerService: CarrersService,
    private filterService: FilterService,
    private dialog: MatDialog,
    private i18n: I18n
  ) {
    this.selectButton = false;

    // Apliquem al filtre el municipi de la institucio
    this.filter.idMunicipi = AppComponent.getInstitution().Poblacion;
    this.filterService.saveFilter('carrers-filter', this.filter);
    this.filter.set(this.filter);
    this.filter.updated();
   }

  ngOnInit() {
    if (this.selectButton) {
      this.displayedColumns = ['incorpora'].concat(this.displayedColumns);
    }
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<Carrer, CarrersFilter>(this.paginator, new CarrersFilterService, 'urlGetCarrers', 'urlGetCarrersPaginationCount');
    this.dataSource.subject.subscribe((data) => {
      data.forEach(item => {
        this.carrerService.displayedItems[item.SECURE_ID] = {
          id: item.SECURE_ID,
          checked: false
        };
      });
      this.displayedItems = this.carrerService.displayedItems;
    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
        if (!this.selectButton) { this.filterService.saveFilter('carrers-filter', this.filter); }
      }
    });

    if (!this.selectButton) { this.filterService.loadFilter('carrers-filter', this.filter); }
    this.filter.paginator.init(this.sort, this.paginator);
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(tap(() => this.filter.updated()))
    .subscribe();

    this.load();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      this.update();
    }
  }

  update() {
    if (this.dataSource !== undefined) {
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
    }
  }

  load() {
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }

  onRowClicked(row) {
    this.itemClicked.next(row.SECURE_ID);
  }

  selectCurrent(secure_id, sigles, nomCarrer) {
    this.itemSelected.next({nomCarrer: sigles + ' ' + nomCarrer, secure_id: secure_id});
  }

}
