   export class Sector {
      id: number;
      nombre: string;
      pda:number;
      calles: any[];
      idAparato: string;

      constructor(idAparato: string) {
         this.id = null;
         this.nombre = null;
         this.pda = null;
         this.idAparato = idAparato;
      }
   }
