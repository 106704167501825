<div class="row justify-content-between">
  <div class="col">
    <app-filter-selector [filter]="this.filter"></app-filter-selector>
</div>
  <div class="col">
    <mat-paginator #paginator [pageIndex]="0" [pageSize]="this.filter.paginator.pageSize"
      [pageSizeOptions]="[20, 40, 60, 100]">
    </mat-paginator>
  </div>
</div>

<div #table class="table-sdw is-fixed" style="max-height: 70vh;">

  <mat-table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.filter.paginator.sortField"
    [matSortDirection]="this.filter.paginator.sortDir" class="is-header-sticky">

    <ng-container matColumnDef="incorpora" *ngIf="this.selectButton"> 
      <mat-header-cell fxFlex="10%" *matHeaderCellDef title="Incorporar" i18n-title class="text-center"><mat-checkbox #checkbox [(ngModel)]="this.allSelected" (change)="seleccionarTotes()" ></mat-checkbox></mat-header-cell>
      <mat-cell fxFlex="10%" *matCellDef="let row"  ><mat-checkbox  (click)="clickCheckbox(row)" [(ngModel)]="this.displayedItems[rowIndex(row)].checked"></mat-checkbox></mat-cell>
    </ng-container>

    <ng-container matColumnDef="matricula">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Matrícula</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.matricula}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="fecha">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.fecha | longDate}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="calle">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Calle</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.calle}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="numero">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Número</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.numero}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="zona">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Zona</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.zona}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="estado">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Estado</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.estado}}</mat-cell>
    </ng-container>



    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onRowClicked(row)"
      [ngClass]="{'is-active': row.markerClicked }"></mat-row>
  </mat-table>
</div>