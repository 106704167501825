import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-usuaris-page',
  templateUrl: './usuaris-page.component.html'
})
export class UsuarisPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
