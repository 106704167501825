import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { AparellFilter } from '../models/aparell-filter';
import { HttpParams } from '@angular/common/http';
import { Reflection } from 'path-shared/services/reflection/reflection';

const defaultSortParam = 'codi';
const defaultSortDirection = 'asc';

@Injectable()
export class AparellsFilterService extends CustomFilterService {
  public createFilterParams(filter: AparellFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {

    return new HttpParams()
    .append('id', filter.id)
    .append('descripcion', filter.descripcion)
    .append('tipoRadar', filter.tipoRadar)
    .append('fechaRevisionInicio', Reflection.empty(filter.fechaRevisionInicio) ? null : filter.fechaRevisionInicio.toDateString())
    .append('fechaRevisionFin', Reflection.empty(filter.fechaRevisionFin) ? null : filter.fechaRevisionFin.toDateString())
    .append('fechaCaducidadInicio', Reflection.empty(filter.fechaCaducidadInicio) ? null : filter.fechaCaducidadInicio.toDateString())
    .append('fechaCaducidadFin', Reflection.empty(filter.fechaCaducidadFin) ? null : filter.fechaCaducidadFin.toDateString())
    .append('margenErrorInicio', Reflection.empty(filter.margenErrorInicio) ? null : filter.margenErrorInicio.toString())
    .append('margenErrorFin', Reflection.empty(filter.margenErrorFin) ? null : filter.margenErrorFin.toString())
    .append('modelo', filter.modelo)
    .append('marca', filter.marca)
    .append('numeroSerie', filter.numeroSerie)
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
