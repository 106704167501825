<app-dialog-header label="Seleccionar data cobrament" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Acceptar" i18n-text (click)="this.save()" [disabled]="this.optionSelected === undefined || this.optionSelected === null || (this.optionSelected === 2 && (this.fecha=== undefined || this.fecha=== null)) "color="has-color-green" icon="fa-save"></app-tool-button>

  <app-tool-button text="Cancelar" i18n-text (click)="this.cancel()" color="has-color-yellow" icon="fa-th-list"></app-tool-button>
</app-toolbar>
<div class="card" style ="align-items: center;">
  <mat-radio-group 
  aria-labelledby="example-radio-group-label"
  class="example-radio-group"
  [(ngModel)]="optionSelected">
  <mat-radio-button class="example-radio-button" [value]="1">
    <span i18n>Utilitzar data del fitxer</span>
  </mat-radio-button>
  <mat-radio-button class="example-radio-button" [value]="2">
    <div class="row">
      <span i18n class="col" style="margin-top: 2.5vh;">Seleccionar data</span>
      <app-form-date-edit id="c60-fecha-cobro-data" style="display: inline-block;" class="col" i18n-label label="Data"  [(value)]="this.fecha" type="calendar"  [disabled]="this.optionSelected!==1" [initialValue]="null"></app-form-date-edit>
    </div>
  
  </mat-radio-button>
</mat-radio-group>
</div>
