import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class TipusEnviamentsFilter extends FilterData {
  tipoEnvio: string;
  descripcion: string;
  bop: boolean;
  producto: string;
  cliente: string;
  codigoAdmision: string;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.tipoEnvio = '';
    this.descripcion = '';
    this.bop = null;
    this.producto = '';
    this.cliente = '';
    this.codigoAdmision = '';

    this.labels['tipoEnvio'] = this.i18n('Tipo envío');
    this.labels['descripcion'] = this.i18n('Descripción');
    this.labels['bop'] = this.i18n('BOP');
    this.labels['producto'] = this.i18n('Producto');
    this.labels['cliente'] = this.i18n('Cliente');
    this.labels['codigoAdmision'] = this.i18n('Codigo Admisión');
  }
}
