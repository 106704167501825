import { ToastrService } from 'ngx-toastr';
import { Component, ElementRef, Inject, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatPaginator, MatSort } from '@angular/material';
import { Builder } from 'path-shared/models/builder';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { DenunciesEditorComponent } from '../../../denuncies-editor/denuncies-editor.component';
import { ExpedientesSinConductorNiTitular } from '../../models/cargaMatricula.model';
import { CargaMatriculaInfo } from '../../models/CargaMatriculaInfo.model';
import { CargaMatriculesEnum } from '../../models/cargaMatriculasEnum.model';
import { CargaMatriculasService } from '../../services/carga-matriculas.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { BehaviorSubject, Observable, Subscription } from 'rxjs-compat';
import { FormService } from 'path-shared/services/form/form.service';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { CargaMatriculasFilterComponent } from '../carga-matriculas-filter/carga-matriculas-filter.component';
import { ErrorsMatriculaFilter } from '../../models/errorsMatriculaFilter';
import { ErrorsMatricula } from '../../models/errorsMatricula.model';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { CargaMatriculasFilterService } from '../../services/carga-matriculas-filter.service';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { LoaderService } from 'path-shared/components/loader/loader.service';

@Component({
  selector: 'app-carga-matriculas',
  templateUrl: './carga-matriculas.component.html',
  styleUrls: ['./carga-matriculas.component.scss']
})
export class CargaMatriculasComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataDialog: ComponentDialog = Builder.newComponentDialog();
  cargaMatriculasFilterDialogRef : MatDialogRef<CargaMatriculasFilterComponent>;
  filter: ErrorsMatriculaFilter = new ErrorsMatriculaFilter('exp', 'desc');

  totalMatricules: number;
  dataSource: CustomDataSource<ExpedientesSinConductorNiTitular, ErrorsMatriculaFilter>;
  data: any;
  subscription: Subscription;
  displayedColumns = ['Check','exp', 'matricula', 'DGT_ERROR_MATRICULA_DATA', 'infTras'];
  displayedItems: ExpedientesSinConductorNiTitular[] = [];
  errorsMatricules: ErrorsMatricula[];
  consultaEnded: boolean = false;

  expedienteFilter: string;
  private loaderService: LoaderService = LoaderService.getInstance();
  @Input() updatedFilterData: ErrorsMatriculaFilter;
  @ViewChild('mainForm') private mainForm: ElementRef;
  constructor(
    private cargaMatriculasSevice: CargaMatriculasService,
    private confirmationDialogService: ConfirmationDialogService,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private i18n: I18n,
    private formService: FormService,
    private filterService: FilterService,
    public auth: AuthorizationService,
    @Inject(MAT_DIALOG_DATA) public dataOfDialog: ComponentDialog
  ) { 
    if (dataOfDialog !== undefined && dataOfDialog.filter !==  undefined) {
      this.dataDialog = dataOfDialog;
    }
    this.consultaEnded = false;
    this.loaderService.show(true);
    this.data = new BehaviorSubject(new Array <ExpedientesSinConductorNiTitular>());
    this.errorsMatricules = []
  }

  ngOnInit() { 
    this.loadPage();
    
    this.dataSource.loading$.subscribe(res =>{
      this.consultaEnded = res;
    });

    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.checkExpMatriculaFilter();
      }
    });
  }

  ngAfterViewInit() {
    this.filterService.loadFilter('cargar-matriculas-filter', this.filter);
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    } );
    this.paginator.page.subscribe(data => {
      this.paginator.pageIndex = data.pageIndex;
      this.paginator.pageSize = data.pageSize;

      this.consultar();
    });
    
  }
  
  ngOnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
    }
  }

  loadPage() {
    this.dataSource = CustomDataSource.create<ExpedientesSinConductorNiTitular, ErrorsMatriculaFilter>(this.paginator, new CargaMatriculasFilterService(), 'urlGetExpedientesMatriculaSinProp', 'urlGetExpedientesMatriculaSinPropCount');
    
    this.dataSource.subject.subscribe((data) => {
      this.cargaMatriculasSevice.displayedItems = [];
      this.cargaMatriculasSevice.displayedItems['checkedValues'] = 0;

      data.forEach(item => {
        this.cargaMatriculasSevice.displayedItems[item.exp] = {
          exp: item.exp,
          isChecked: false,
          DGT_ERROR_MATRICULA_DATA: item.DGT_ERROR_MATRICULA_DATA,
          DGT_ERROR_MATRICULA_TEXT: item.DGT_ERROR_MATRICULA_TEXT,
          matricula: item.matricula,
          infoTraspaso: item.infoTraspaso,
          numDenuncia: item.numDenuncia,    
          secureIdDenuncia: item.secureIdDenuncia,
          somIdDenuncia: item.somIdDenuncia
        };
      });

      this.displayedItems = this.cargaMatriculasSevice.displayedItems;
    });
    
    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.paginator.pageIndex = 0;
        this.filterService.saveFilter('cargar-matriculas-filter', this.filter);
        this.consultar();
      }
    });
    this.filter.filterCleared.subscribe(updated => {
      if (updated) {
        this.paginator.pageIndex = 0;
        this.filterService.saveFilter('cargar-matriculas-filter', this.filter);
        this.consultar();
      }
    });
    if (this.dataDialog !== undefined && this.dataDialog.filter !== null) {
      this.filter.set(this.dataDialog.filter);
      this.displayedColumns.splice(0, 1);
    } else {
       this.filterService.loadFilter('cargar-matriculas-filter', this.filter);
    }
    this.filter.paginator.init(this.sort, this.paginator);
    this.checkExpMatriculaFilter();
    // this.cargaMatriculasSevice.getExpedientesSinMatricula(this.numRegistrosConsultar, this.filter.noves, this.filter.expediente, this.filter.matricula).subscribe(results => {
    //   this.numDenunciasSinConductorNiTitularEnBBDD = results.numeroTotalRegistros;
    //   this.data =  Object.assign(this.data,  new BehaviorSubject(results.expedientesSinConductorNiTitular));
    //   this.data.value.forEach(x => x.isChecked = false);
    //   this.consultaEnded = true;
    // });
  }

  consultar() {
    if (this.dataSource !== undefined) {
      if (this.filter.nomesVelles) {
        if (!this.displayedColumns.includes('DGT_ERROR_MATRICULA_TEXT'))
        this.displayedColumns.push('DGT_ERROR_MATRICULA_TEXT');
      }
      else {
        this.displayedColumns = this.displayedColumns.filter(x => x != 'DGT_ERROR_MATRICULA_TEXT');
      }

      this.filter.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.filter);
      
    }
  }
  
  onRowClicked(row: ExpedientesSinConductorNiTitular) {
      if (row !== undefined && row !== null) {
          const data: ComponentDialog = Builder.newComponentDialog(true, true, row.somIdDenuncia);
          const ref = this.dialog.open(DenunciesEditorComponent, {
            width: '99%',
            height: '99%',
            maxHeight: '100vh',
            closeOnNavigation: false,
            autoFocus: true,
            data: data
          });
      }
  }

  cargarDgt() {
    if (this.displayedItems !== undefined && this.displayedItems !== null && this.displayedItems.length > 0 ) {
      this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Està segur que vol carregar ' + this.displayedItems.filter(x => x.isChecked).length + ' matrícules?'))
      .then((confirmed) => {
        if (confirmed) {
          const carga: CargaMatriculaInfo = {
            tipoExportacion: CargaMatriculesEnum.CargaDgt,
            expedientes: this.displayedItems.filter(x => x.isChecked),
            filtre: null
        };
        this.cargaMatriculasSevice.cargaExpedientesDgt(carga).subscribe(
          (results : ExpedientesSinConductorNiTitular[]) => {
            let counterTrobats: number = 0;
            let counterNoTrobats: number = 0;
            for (const exp of results) {
              if (exp.infoTraspaso == 'no trobat!' || exp.infoTraspaso == 'no encontrado!') {
                counterNoTrobats++;
              }
              else {
                counterTrobats++
                
              }
              this.dataSource.set('exp', exp.exp, 'infoTraspaso',exp.infoTraspaso)
              this.dataSource.set('exp', exp.exp, 'DGT_ERROR_MATRICULA_TEXT',exp.DGT_ERROR_MATRICULA_TEXT)
            }
            if (counterTrobats > 0) this.toastr.success(this.i18n('Se han cargado '+ counterTrobats +' matrículas correctamente'))
            if (counterNoTrobats > 0) this.toastr.warning(this.i18n('No se han podido cargar ' + counterNoTrobats + ' matrículas correctamente'))
          },
          error => {
            this.toastr.error(this.i18n('No se han podido cargar las matrículas correctamente'));
          });
        }
      })
        .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')); {
        }
    }
  }

  cargarDgtTotes() {
    if (this.displayedItems !== undefined && this.displayedItems !== null && this.displayedItems.length > 0 ) {
      this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Està segur que vol carregar totes les ' + this.paginator.length +' matrícules?'))
      .then((confirmed) => {
        const that = this;
        if (confirmed) {
          const carga = {
            tipoExportacion: CargaMatriculesEnum.CargaDgtTotal,
            expedientes: null,
            filtre: {
              nomesNoves: that.filter.nomesNoves,
              nomesVelles: that.filter.nomesVelles,
              exp: that.filter.exp == '' ? null : that.filter.exp,
              matricula: that.filter.matricula == '' ? null : that.filter.matricula
            }
        };
        this.cargaMatriculasSevice.cargaExpedientesDgt(carga).subscribe((results : ExpedientesSinConductorNiTitular[]) => {
          for (const exp of results) {
            this.dataSource.set('exp', exp.exp, 'infoTraspaso',exp.infoTraspaso)
            this.dataSource.set('exp', exp.exp, 'DGT_ERROR_MATRICULA_TEXT',exp.DGT_ERROR_MATRICULA_TEXT)
          }
          this.toastr.success(this.i18n('Se han cargado todas las matrículas, revise posibles errores en caso que no se hayan cargado correctamente'));
        },
        error => {
          this.toastr.error(this.i18n('No se han podido cargar las matrículas correctamente'));
        });
        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    }
  }

  cargarGestTributaria(){
    this.toastr.warning(this.i18n('Módulo aún no disponible.'));

  }
  cargarXaloc(){
    this.toastr.warning(this.i18n('Módulo aún no disponible.'));
  }
  exportXaloc(){
    this.toastr.warning(this.i18n('Módulo aún no disponible.'));

  }

  onFilterClicked() {
    this.cargaMatriculasFilterDialogRef = this.dialog.open(CargaMatriculasFilterComponent, {
      width: '90%',
      height: 'auto',
      closeOnNavigation: false,
      autoFocus: true,
      data: this.filter
    });

    this.cargaMatriculasFilterDialogRef.componentInstance.notify.subscribe((filter: ErrorsMatriculaFilter) => {
      this.checkExpMatriculaFilter();
      this.filter.set(filter);
      this.consultar();
    });
  }
  
  toggleAllCheckbox() {
    for (const item in this.displayedItems) {
      if (item !== 'checkedValues') {
        this.toggleItem(item);
      }
    }
  }

  toggleItem(id) {
    const itemStatus = this.displayedItems[id].isChecked = !this.displayedItems[id].isChecked;

    if (itemStatus) {
      this.displayedItems['checkedValues'] = this.getCheckedValues() + 1;
    } else {
      this.displayedItems['checkedValues'] = this.getCheckedValues() - 1;
    }
  }

  getCheckedValues() {
    return this.displayedItems['checkedValues'];
  }


  checkExpMatriculaFilter() {
    const regex: RegExp = /[a-zA-Z]/g;
    if (this.expedienteFilter !== undefined && this.expedienteFilter !== null && this.expedienteFilter !== '' ) {
      if (this.expedienteFilter.match(regex)) {
        this.filter.matricula = this.expedienteFilter;
        this.filter.updated();
      }
      else {
        this.filter.exp = this.expedienteFilter;
        this.filter.updated();
      }
    } 
    else {
      this.filter.exp = '';
      this.filter.matricula = '';
      this.filter.updated();
    }
  }
}
