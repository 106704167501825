import { MatSort, MatPaginator, SortDirection, Sort } from '@angular/material';
import { CookieService } from 'ngx-cookie-service';
import { AppInjectorModule } from 'app/app-injector/app-injector.module';

export class PaginationFilter {
  sortDir: SortDirection;
  sortField: string;
  pageNumber: number;
  pageSize: number;
  private defaultPageSize = 20;
  private cookie: CookieService;

  constructor() {
    this.cookie =  AppInjectorModule.injector.get(CookieService);
    this.sortDir = 'asc';
    this.sortField = '';
    this.pageNumber = 0;
    this.pageSize = 20;
    this.setDefaultPaginatorSize();
  }

  set(sort: MatSort, paginator: MatPaginator) {
    this.sortField = sort.active;
    if (sort.direction === 'asc' || sort.direction === 'desc') {
      this.sortDir = sort.direction;
    }
    this.pageNumber = paginator.pageIndex * paginator.pageSize;
    this.pageSize = paginator.pageSize;
    this.savePaginatorSize(this.pageSize);
  }

  setData(data, init = false) {
    if (data !== undefined && data !== null) {
      this.sortField = data.sortField;
      if (data.sortDir === 'asc' || data.sortDir === 'desc') {
        this.sortDir = data.sortDir;
      }
      if (data.pageNumber !== undefined) { this.pageNumber = data.pageNumber; }
      if (data.pageSize !== undefined) {
        this.pageSize = data.pageSize;
        if (!init) {
          this.savePaginatorSize(this.pageSize);
        }
      }
    }
  }

  public init(sort: MatSort, paginator: MatPaginator) {
    this.setDefaultPaginatorSize();
    sort.active = this.sortField;
    sort.direction = this.sortDir;
    paginator.pageSize = this.pageSize;
    paginator.pageIndex = this.pageNumber;
    sort.disableClear = true;
  }

  private savePaginatorSize(newPageSize: number) {
    if (newPageSize !== this.defaultPageSize) {
      this.defaultPageSize = newPageSize;
      this.cookie.set('PageSize', newPageSize.toString());
      console.log('DefaultPageSize: ' + newPageSize);
    }
  }

  private setDefaultPaginatorSize() {
    const pageSize = this.cookie.get('PageSize');
    if ( pageSize !== '') {
      this.defaultPageSize = parseInt(pageSize, 10);
    }
    this.pageSize = this.defaultPageSize;
  }

}
