<app-filter-header title="Filtrar matricules" i18n-title  (apply)="this.apply()" (reset)="this.reset()" (dismiss)="this.dismiss()"></app-filter-header>
<div class="modal-body">
  <div class="row">
    <app-form-combo-edit label="Tipus" i18n-label class="col-sm-4" [items]="this.tipusMatricules" [(value)]="filter.tipus" valueField="codi" textField="descripcio"></app-form-combo-edit>
    <app-filter-text-edit id="matricules-historic-filter-matricula" autofocus class="col-sm-4" i18n-label label="Matrícula" [(value)]="filter.matricula"></app-filter-text-edit>
  </div>
  <div class="row">
    <app-filter-text-edit id="matricules-historic-filter-marca" class="col-sm-4" i18n-label label="Marca" [(value)]="filter.marca"></app-filter-text-edit>
    <app-filter-text-edit id="matricules-historic-filter-model" class="col-sm-4" i18n-label label="Model" [(value)]="filter.model"></app-filter-text-edit>
  </div>
  <div class="row">
    <app-filter-text-edit id="matricules-historic-filter-observacions"class="col-sm" i18n-label label="Observacions" [(value)]="filter.observacions"></app-filter-text-edit>
  </div>
  <div class="row">
    <app-form-date-edit id="matricules-historic-filter-dataIni" class="col-sm-4" i18n-label label="Data Inici" [(value)]="filter.dataInici" type="calendar"></app-form-date-edit>
    <app-form-date-edit id="matricules-historic-filter-dataFi"class="col-sm-4" i18n-label label="Data Fi"  [(value)]="filter.dataFi" type="calendar"></app-form-date-edit>
    <app-form-date-edit id="matricules-historic-filter-dataCreacio" class="col-sm-4" i18n-label label="Data Creació"  [(value)]="filter.dataCreacio" type="calendar"></app-form-date-edit>
  </div>
</div>
