import { Component, OnInit, Optional, Inject } from '@angular/core';
import { ModelsMarques } from '../../models/models-marques.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-models-vehicles',
  templateUrl: './models-vehicles.component.html',
  styleUrls: ['./models-vehicles.component.scss']
})
export class ModelsVehiclesComponent implements OnInit {

  action: string;
  local_data: any;

  constructor(
    public dialogRef: MatDialogRef<ModelsVehiclesComponent>,
    //@Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: ModelsMarques) {
    console.log(data);
    this.local_data = {...data};
    this.action = this.local_data.action;}

  ngOnInit() {
  }

  doAction(){
    this.dialogRef.close({event:this.action,data:this.local_data});
  }
 
  closeDialog(){
    this.dialogRef.close({event:'Cancel'});
  }

}
