<div class="row justify-content-between">
  <div class="col"><app-filter-selector [filter]="this.filter"></app-filter-selector></div>
  <div class="col">
    <mat-paginator #paginator
      [pageIndex]="0"
      [pageSize]="this.filter.paginator.pageSize"
      [pageSizeOptions]="[20, 40, 60, 100]">
    </mat-paginator>
  </div>
</div>

<div class="table-sdw is-fixed">

  <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.filter.paginator.sortField" [matSortDirection]="this.filter.paginator.sortDir" class="is-header-sticky">

    <!--<ng-container matColumnDef="incorpora" *ngIf="this.selectButton">
      <mat-header-cell *matHeaderCellDef title="Incorporar" i18n-title class="text-center"><i class="fa fa-plus-square has-color-grey"></i></mat-header-cell>
      <mat-cell *matCellDef="let row" class="text-center"><a (click)="this.selectCurrent(row.codi)" title="Incorporar" i18n-title><i class="fa fa-plus-square has-color-aqua"></i></a></mat-cell>
    </ng-container>-->
    
    <ng-container matColumnDef="incorpora" >
      <mat-header-cell  *matHeaderCellDef  i18n-title class="text-center"></mat-header-cell>
      <mat-cell  *matCellDef="let row"  ><mat-checkbox #checkbox [value]="row.secureId === this.selectedSecureId" (click)="ClickCheckbox(row)"></mat-checkbox></mat-cell>
    </ng-container>


      <ng-container matColumnDef="Som_id">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Codi</mat-header-cell>
          <mat-cell *matCellDef="let row"><a (click)="onRowClicked(row)" class="is-link cursor detalle">{{row.Som_id}}</a></mat-cell>
        </ng-container>

      <ng-container matColumnDef="descripcion">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Descripció</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.descripcion}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="ANO_MES_DIA_INICIO">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data inici</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.ANO_MES_DIA_INICIO}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="ANO_MES_DIA_FIN">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data fi</mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.ANO_MES_DIA_FIN}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="ACTIVO">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Activa</mat-header-cell>
        <mat-cell  *matCellDef="let row"  >
          <mat-checkbox [checked]="row.ACTIVO" [disabled]='true'></mat-checkbox></mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
    </mat-table>
</div>

