import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DashContadoresDuplicadosComponent } from '../dash/dash-contadores-duplicados/dash-contadores-duplicados.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dash-input-files-status',
  templateUrl: './dash-input-files-status.component.html',
  styleUrls: ['./dash-input-files-status.component.scss']
})
export class DashInputFilesStatusComponent implements OnInit {
  @Input() pendingFiles: number;
  @Input() errorFiles: number;
  @Input() contadoresDuplicados: number;
  @Input() pendingFilesImg: number;
  @Input() label: string;
  @Input() nom: string;
  @Input() icon: string;

  constructor(
    private router: Router) {
    this.pendingFiles = 0;
    this.errorFiles = 0;
  }

  ngOnInit() {
  }
  openDuplicados() {
    this.router.navigate(['contadoresDuplicados']);
      /*const ref =  this.dialog.open( DashContadoresDuplicadosComponent, {
        closeOnNavigation: false,
        autoFocus: true
    });*/
  }

  openErroneos() {
    //if(this.errorFiles > 0) {
      this.router.navigate(['ficherosErroneos']);
    //}
     
  }

  openPendentsImatge() {
    this.router.navigate(['ficherosPendienteImagen']);
  }
}
