<app-toolbar>  
  <app-tool-button text="Actualizar"  i18n-text (click)="buscar();" color="has-color-blue" icon="fa-sync"></app-tool-button>
  <app-tool-button text="Filtre" i18n-text (click)="onFilterClicked();" color="has-color-orange" icon="fa-filter"></app-tool-button>
  <app-tool-button text="Excel" i18n-text (click)="generateExcel();" color="has-color-darkgreen" icon="fa-file-excel"></app-tool-button>
  <app-tool-button-permissions [permission]="this.auth.Pages.Reincidents"></app-tool-button-permissions>
</app-toolbar>

<div>
  <div class="row">
    <div class="col-9">
      <div *ngIf="this.init || this.dataSource.data.length === 0" >
        <div class="empty-case">
          <span class="empty-case__icon"><i class="fa fa-search"></i></span>
          <h4 *ngIf="init" class="empty-case__title" i18n>Definid el filtro</h4>
          <h4 *ngIf="this.dataSource !== null && this.dataSource.data.length === 0" class="empty-case__title" i18n>Sin resultados</h4>
          <p i18n>Indique los parámetros en el filtro y haga click en "Actualizar".</p>
        </div>
      </div>
      <div [hidden]="(this.init || this.dataSource.data.length === 0) " class="table-sdw is-fixed">

        <mat-table #table [dataSource]="dataSource" matSort #sort="matSort" aria-label="Elements" class="is-header-sticky">
    
    
            <ng-container matColumnDef="FechaCobro">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Fecha cobro</mat-header-cell>
                <mat-cell *matCellDef="let row">{{row.FechaCobro | shortDate}} </mat-cell>
            </ng-container>
    
            <ng-container matColumnDef="ImporteCobro">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Importe cobro</mat-header-cell>
                <mat-cell *matCellDef="let row">{{row.ImporteCobro}} €</mat-cell>
            </ng-container>

            <ng-container matColumnDef="Origen">
              <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Origen</mat-header-cell>
              <mat-cell *matCellDef="let row">{{row.Origen}}</mat-cell>
            </ng-container>            
    
            <ng-container matColumnDef="EXP">
              <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Expediente</mat-header-cell>
              <mat-cell *matCellDef="let row">{{row.EXP}}</mat-cell>
            </ng-container>  

            <ng-container matColumnDef="Aparato">
              <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Origen de la denuncia</mat-header-cell>
              <mat-cell *matCellDef="let row">{{row.Aparato}}</mat-cell>
            </ng-container>  

            <ng-container matColumnDef="Màquina">
              <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Màquina</mat-header-cell>
              <mat-cell *matCellDef="let row">{{row.DescripcionMaquina}}</mat-cell>
            </ng-container>  

            <ng-container matColumnDef="TipoCobro">
              <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Tipo de cobro</mat-header-cell>
              <mat-cell *matCellDef="let row">{{this.getTipoCobroById(row.TIPO_COBRO)}}</mat-cell>
            </ng-container>  
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <!-- <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset"></mat-row> -->
            <mat-row *matRowDef="let row; columns: displayedColumns;"  [ngClass]="{'is-active': row.clicked }"></mat-row>

        </mat-table>
      </div>
    </div>
  </div>
  
</div>