import { MarquesVehicles } from './../models/marques-vehicles.model';
import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { MarquesVehiclesFilterService } from './marques-vehicles-filter-service.service';
import { MarquesVehiclesFilter } from '../models/marques-vehicles-filter';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { ModelsMarques } from '../models/models-marques.model';

const defaultSortParam = 'codi';
const defaultSortDirection = 'desc';


@Injectable()
export class MarquesVehiclesService {

  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {}


  setMarca(marca: MarquesVehicles ): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetMarca'], marca);
    }
  }

  removeMarca(id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteMarca'].replace('{secure_id}', id), null);
    }
  }

  getMarca(id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetMarca'].replace('{secure_id}', id), null);
    }
  }

  getMarques(): Observable<any> {
    if (this.authGuard.canActivate()) {
      const params = new MarquesVehiclesFilterService().createFilterParams(new MarquesVehiclesFilter('descripcio', 'asc'), 0, 99999, 'descripcio', 'asc');
      return this.http.get( config.urls['apiUrl'] + api.endpoints['urlGetMarques'], params);
    }
  }
  getAllMarques(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get( config.urls['apiUrl'] + api.endpoints['urlGetAllMarques'], null);
    }
  }

  /*getAllMarques(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get( config.urls['apiUrl'] + api.endpoints['urlGetAllMarques'], null);
    }
  }*/

  generateExcel(filter: MarquesVehiclesFilter, sortDir: string, sortField: string): Observable<any> {
    const start = 0;
    const pageSize = 1000;

    sortField = sortField === undefined ? defaultSortParam : sortField;
    sortDir = sortDir === '' ? defaultSortDirection : sortDir;
    const params = new MarquesVehiclesFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);
    if (this.authGuard.canActivate()) {
      return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlMarcaExcel'],  params );
    }
  }

  setModelo(modelo: ModelsMarques): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetModelo'], modelo);
    }
  }
  GetModelosMarca(secure_id: string): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetModelos'].replace('{secure_id}', secure_id), null);
    }
  }

  deleteModelo(modelo: ModelsMarques): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteModelo'].replace('{secure_id}', modelo.secureId), null);
    }
  }
}
