import { TemporadaDto } from './../../models/temporadaDto';
import { TemporadaService } from './../../services/temporada.service';
import { Component, OnInit, ViewChild, ElementRef, Inject, ViewContainerRef } from '@angular/core';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { FormService } from 'path-shared/services/form/form.service';
import { Builder } from 'path-shared/models/builder';
import { Dictionary } from 'path-shared/models/dictionary';
import { ComboBoxInputAutocompleteComponent } from 'path-shared/components/form/combo-box-input-autocomplete/combo-box-input-autocomplete.component';
import { GenericFileEventsService } from 'path-shared/components/generic-file-displayer/services/generic-file-events.service'; 
import { GenericFileDisplayerPageComponent } from 'path-shared/components/generic-file-displayer/components/generic-file-displayer-page/generic-file-displayer-page.component';
import { ParametersService } from 'path-shared/services/parameters/parameters.service';
import { FormMultiSelect } from 'path-shared/components/form/form-multi-select/model/form-multi-select.model';
import { ZonesService } from 'app/sections/ciutat/zones/services/zones.service';
import { ZonesModel } from 'app/sections/ciutat/zones/models/zones.model';

@Component({
  selector: 'app-temporadas-editor',
  templateUrl: './temporadas-editor.component.html',
  styleUrls: ['./temporadas-editor.component.scss']
})
export class TemporadasEditorComponent implements OnInit {

  secure_id: string;
  temporada: TemporadaDto;
  dialogData: ComponentDialog;
  closeButtonText: string;
  fechaInicio: Date;
  fechaFin: Date;
  diaInicio: number;
  diaFin: number;
  mesInicio: number;
  mesFin: number;
  public afectacionCalculadaActiva: boolean;
  map: any;
  markerPosition: any;

  zones = Array<FormMultiSelect>();
  dictMarcas: Dictionary[];
  valorAutoCompleteMarcas;
  marcasList = [];
  @ViewChild('comboMarca') comboMarca: ComboBoxInputAutocompleteComponent;

  @ViewChild('mainForm') private mainForm: ElementRef;
  genericFilesPageComponent: MatDialogRef<GenericFileDisplayerPageComponent>;
  fileEvents: GenericFileEventsService;
  constructor(
    private dialog: MatDialog,
    private confirmationDialogService: ConfirmationDialogService,
    public auth: AuthorizationService,
    private temporadaService: TemporadaService,
    private i18n: I18n,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    public thisDialogRef: MatDialogRef<TemporadasEditorComponent>,
    private formService: FormService,
    public vcRef: ViewContainerRef,
    private parametersService: ParametersService,
    private zonesService: ZonesService,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
  ) {
    const inst = sessionStorage.getItem('currentInst');
   
    this.temporada = Builder.newTemporada();
    this.dialogData = data;
    this.secure_id = this.dialogData.id;
    if (this.dialogData.isReadOnly) {
      this.closeButtonText = this.i18n('Tancar');
    } else {
      this.closeButtonText = this.i18n('Llista');
    }
    this.valorAutoCompleteMarcas = '';
    this.afectacionCalculadaActiva = false;
  }

  ngOnInit() {
    if (this.secure_id !== null) {
      this.temporadaService.getTemporada(this.secure_id).subscribe(data => {
        this.temporada = data;
        this.diaInicio = Number.parseInt(this.temporada.ANO_MES_DIA_INICIO.toString().substr(6, 2));
        this.mesInicio = Number.parseInt(this.temporada.ANO_MES_DIA_INICIO.toString().substr(4, 2));
        this.diaFin = Number.parseInt(this.temporada.ANO_MES_DIA_FIN.toString().substr(6, 2));
        this.mesFin = Number.parseInt(this.temporada.ANO_MES_DIA_FIN.toString().substr(4, 2));
        if (this.temporada.REPITICION_PERIODICA !== undefined && this.temporada.REPITICION_PERIODICA !== null && !this.temporada.REPITICION_PERIODICA) {
          const yearIn = Number.parseInt(this.temporada.ANO_MES_DIA_INICIO.toString().substr(0, 4));
          const yearFin = Number.parseInt(this.temporada.ANO_MES_DIA_FIN.toString().substr(0, 4));
          this.fechaInicio = new Date(yearIn, this.mesInicio, this.diaInicio);
          this.fechaFin = new Date(yearFin, this.mesFin, this.diaFin);
        }
        this.getZones();
      });
    }else{
      this.getZones();
    }
  }

  getZones() {
    this.zonesService.getZones().subscribe(result => {
      const zones: Array<ZonesModel> = result;
      this.zones = [];
      zones.forEach(zona => {

        if (this.temporada.zones !== undefined && this.temporada.zones !== null && this.temporada.zones.find(x => x.item_id === zona.SOM_ID)) {
          this.zones.unshift(new FormMultiSelect(zona.SOM_ID, zona.descripcio));
        } else {
          this.zones.push(new FormMultiSelect(zona.SOM_ID, zona.descripcio));
        }
      });
    });
  }

  save() {
    if (this.formService.allFieldsValid(this.mainForm)) {
      if ( this.temporada.REPITICION_PERIODICA) {
        const strMesIni = ( this.mesInicio < 10) ?   '0' + this.mesInicio :  this.mesInicio;
        const strDiaIni = ( this.diaInicio < 10) ?   '0' + this.diaInicio :  this.diaInicio;

        const strMesFin = ( this.mesFin < 10) ?   '0' + this.mesFin :  this.mesFin;
        const strDiaFin =  ( this.diaFin < 10) ?   '0' + this.diaFin :  this.diaFin;
        if (this.mesFin < this.mesInicio || (this.mesInicio === this.mesFin && this.diaFin < this.diaInicio)) {
          this.temporada.ANO_MES_DIA_INICIO = Number('9998' + strMesIni + strDiaIni);
          this.temporada.ANO_MES_DIA_FIN = Number( '9999' + strMesFin + strDiaFin);
        } else {
          this.temporada.ANO_MES_DIA_INICIO = Number('9998' + strMesIni + strDiaIni);
          this.temporada.ANO_MES_DIA_FIN = Number('9998' + strMesFin + strDiaFin);
        }
      } else {
        const strMesIni = ( this.fechaInicio.getMonth() < 10) ?   '0' + this.fechaInicio.getMonth() :  this.fechaInicio.getMonth();
        const strDiaIni = ( this.fechaInicio.getDate() < 10) ?   '0' + this.fechaInicio.getDate() :  this.fechaInicio.getDate();

        const strMesFin = ( this.fechaFin.getMonth() < 10) ?   '0' + this.fechaFin.getMonth() :  this.fechaFin.getMonth();
        const strDiaFin = ( this.fechaFin.getDate() < 10) ?   '0' + this.fechaFin.getDate() :  this.fechaFin.getDate();

        this.temporada.ANO_MES_DIA_INICIO = Number(this.fechaInicio.getFullYear().toString() + strMesIni + strDiaIni);
          this.temporada.ANO_MES_DIA_FIN = Number(this.fechaFin.getFullYear().toString() + strMesFin + strDiaFin );
      }
      this.temporadaService.setTemporada(this.temporada).subscribe(result => {
        this.toastr.info(this.i18n('Temporada guardada correctament'));
        this.thisDialogRef.close(true);
      });
    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
    }
  }

  cancel() {
    this.thisDialogRef.close(false);
  }

  remove() {
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol esborrar la temporada?'))
    .then((confirmed) => {
        if (confirmed) {
          this.temporadaService.deleteTemporada(this.secure_id).subscribe(result => {
            this.toastr.success(this.i18n('Temporada esborrada correctament'));
            this.dialog.closeAll();
            this.thisDialogRef.close(true);
          });
        }
      });
  }

}
