import { IFilterService } from '../../../../../shared/services/datasource/filter-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { PaginationFilter } from 'path-shared/models/pagination.filter';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PropostaFilter } from 'app/sections/propostes/models/proposta.filter';
import { PropostesService } from 'path-propostes-services/propostes.service';
import { PropostesFilterService } from 'path-propostes-services/propostes-filter.service';
import { Proposta } from 'app/sections/propostes/models/proposta.model';
import { PropostaEstats } from 'app/sections/propostes/models/proposta-estats.model';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { PropostaDialogComponent } from '../proposta-dialog/proposta-dialog.component';
import { Builder } from 'path-shared/models/builder';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';

@Component({
  selector: 'app-propostes-table',
  templateUrl: './propostes-table.component.html',
  styleUrls: ['./propostes-table.component.scss']
})
export class PropostesTableComponent implements OnInit {
  public Estats: PropostaEstats;
  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  @ViewChild(MatSort)
  sort: MatSort;

  data: Proposta[];
  dataSource: CustomDataSource<Proposta, PropostaFilter>;
  highlightedRows = [];
  public filter: PropostaFilter = new PropostaFilter('Data', 'desc');
  filterPage: PaginationFilter;
  public userReincidentsDataSource = true;

  // Emits
  @Output()
  notify: EventEmitter<number> = new EventEmitter<number>();
  @Input()
  updatedFilterData: PropostaFilter;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['Check', 'Estat', 'NUMDENUNCIA', 'MATRICULA', 'Data', 'imatges', 'Pais', 'LLOC', 'INFRACCIO', ];

  constructor(
    public propostesService: PropostesService,
    private i18n: I18n,
    private filterService: FilterService,
    private dialog: MatDialog,
    private auth: AuthorizationService
  ) {}

  ngOnInit() {
    if (this.userReincidentsDataSource) {
      this.dataSource = CustomDataSource.create<Proposta, PropostaFilter>(this.paginator, new PropostesFilterService(), 'urlpropostesquery', 'urlPropostesPaginationcount');
    } else {
      this.dataSource = CustomDataSource.create<Proposta, PropostaFilter>(this.paginator, new PropostesFilterService(), 'urlReincidentsQuery', 'urlReincidentsPaginationcount');
    }
    if (this.auth.UserCanSeePage(this.auth.Pages.Prepropostes) && !this.auth.IsSuperUser()) {
      this.filter.proposta = '4';
    }
    this.dataSource.subject.subscribe((data) => {
      if (!this.userReincidentsDataSource) {
        this.propostesService.displayedItems = [];
        this.propostesService.displayedItems['checkedValues'] = 0;
      }
      data.forEach(item => {
        this.propostesService.displayedItems[item.SECURE_ID] = {
          id: item.SECURE_ID,
          checked: false
        };
      });

    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.loadPropostesPage();
        if (this.userReincidentsDataSource) {
          this.filterService.saveFilter('propostes-filter', this.filter);
        }
      }
    });
    if (this.userReincidentsDataSource) {
      this.filterService.loadFilter('propostes-filter', this.filter);
    }
    this.filter.paginator.init(this.sort, this.paginator);

    if (!this.userReincidentsDataSource) {
      this.filter.updated(true);
    }
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    } );
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.filter.updated()))
      .subscribe();
    this.loadPropostesPage();
    }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      this.updatePropostesPage();
    }
  }

  updatePropostesPage() {
    this.dataSource.load(
      this.updatedFilterData,
      this.paginator.pageIndex,
      this.paginator.pageSize,
      this.sort.active,
      this.sort.direction
    );
  }

  loadPropostesPage(filterUpdated: boolean = false) {
    if (filterUpdated) {
      this.filter.paginator.pageNumber = 0;
      this.paginator.pageIndex = 0;
    }
    if (this.dataSource !== undefined) {
      this.filter.paginator.set(this.sort, this.paginator);
      if (this.filter.tipusproposta === '-1') {
        this.filter.tipusproposta = null;
      }
      this.dataSource.loadFilter(this.filter);


    }
  }

  onRowClicked(row) {
    const ref = this.dialog.open(PropostaDialogComponent, {
      width: '95%',
      height: '90%',
      closeOnNavigation: false,
      autoFocus: true,
      data: row
    });

    ref.afterClosed().subscribe(updated => {
      if (updated !== undefined && updated.relacions !== undefined) {
        this.ChangeLloc(updated);
      }
      // if (updated) {  this.loadPropostesPage(); }
    });
  }
  ChangeLloc(updated: Proposta) {
    updated.relacions.forEach(relacio => {
      if (this.dataSource.subject.value.find(x => x.NUMDENUNCIA.toString() === relacio) !== undefined) {
        this.dataSource.subject.value.find(x => x.NUMDENUNCIA.toString() === relacio).LLOC = updated.LLOC;
        this.dataSource.subject.value.find(x => x.NUMDENUNCIA.toString() === relacio).ID_LLOC = updated.ID_LLOC;
        this.dataSource.subject.value.find(x => x.NUMDENUNCIA.toString() === relacio).NUMERO = updated.NUMERO;
        this.dataSource.subject.value.find(x => x.NUMDENUNCIA.toString() === relacio).proposta = updated.proposta;

      }
    });
  }

  generateExcel() {
    let filter = this.filter;
    if (this.updatedFilterData != null) {
      filter = this.updatedFilterData;
    }
    this.dialog.open(DownloadExcelDialogComponent, {
      closeOnNavigation: false,
      autoFocus: true,
      data: Builder.newDownloadExcelDialogData (this.propostesService, filter, this.i18n('Propostes'), this.sort.direction, this.sort.active)
    });
  }

  getStatusText(status) {
    if (status === PropostaEstats.ACCEPTADA) {
      return this.i18n('Acceptada');
    } else if (status === PropostaEstats.PENDENT) {
      return this.i18n('Pendent');
    } else if (status === PropostaEstats.DESESTIMADA) {
      return this.i18n('Rebutjada');
    } else {
      return this.i18n('');
    }
  }

  toggleAllCheckbox() {
    for (const item in this.propostesService.displayedItems) {
      if (item !== 'checkedValues') {
        this.toggleItem(item);
      }
    }
  }

  getCheckedValues() {
    return this.propostesService.displayedItems['checkedValues'];
  }

  toggleItem(id) {
    const itemStatus = this.propostesService.displayedItems[id].checked = !this.propostesService.displayedItems[id].checked;

    if (itemStatus) {
      this.propostesService.displayedItems['checkedValues'] = this.getCheckedValues() + 1;
    } else {
      this.propostesService.displayedItems['checkedValues'] = this.getCheckedValues() - 1;
    }
  }
}
