import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-propostes-table-estat',
  templateUrl: './propostes-table-estat.component.html',
  styleUrls: ['./propostes-table-estat.component.scss']
})
export class PropostesTableEstatComponent implements OnInit {
  @Input() proposta: number;
  @Input() tipus: number;

  constructor() { }

  ngOnInit() {
  }

}
