import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class AparellTipusFilter extends FilterData {
  id: number;
  codiTipus: string;
  descTipus: string;
  numLocalitzacions: number;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.id = null;
    this.codiTipus = '';
    this.descTipus = '';
    this.numLocalitzacions = null;
  }
}
