import { CFG_PARAMETROS } from './../../../models/CFG_PARAMETROS';
import { GoogleMapsService } from './google-maps.service';
import { CFG_PAISES } from 'path-models/CFG_PAISES';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { HttpSecureClient } from '../../../shared/services/http/httpSecureClient';
import { CFG_TIPODENUNCIA } from 'path-models/CFG_TIPODENUNCIA';
import { CFG_TIPOPROPUESTA } from 'path-models/CFG_TIPOPROPUESTA';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AuthGuardService } from '../../../shared/services/authentication/auth-guard.service';
import { api } from '../../../shared/configuration/urls';
import { config } from 'path-shared/configuration/config';
import { Reflection } from '../reflection/reflection';
import { HttpParams } from '@angular/common/http';

import { Parametros } from 'path-shared/models/parametros';
import { SOM_DEN_INFRACCIONES_GRAVEDAD } from 'path-models/SOM_DEN_INFRACCIONES_GRAVEDAD';
import { CFG_TIPODENUNCIANTE } from 'path-models/CFG_TIPODENUNCIANTE';
import { SimpleCombo } from 'path-shared/models/simple-combo';


@Injectable()
export class StartupService {
  constructor(
    private googleMapsService: GoogleMapsService,
    private http: HttpSecureClient,
    private authGuard: AuthGuardService,
    private authorizationService: AuthorizationService,
  ) {
    this.Init();
  }

  Init() {
    this.GetTiposDenuncia();
    this.GetTiposDenunciante();
    this.GetPaisos();
    this.GetParametres();
    this.GetTipusVehicles();
    this.GetInfraccioTipusGravetat();
    this.GetInfraccioDiesDescompte();
    this.GetDenunciesOrigensForCombo();
  }

  GetTiposDenuncia(): CFG_TIPODENUNCIA[] {
    if (this.authGuard.canActivate()) {
      if (sessionStorage.getItem('tiposdenuncia')) {
        const json = sessionStorage.getItem('tiposdenuncia');
        const values: CFG_TIPODENUNCIA[] = JSON.parse(json.toString());
        return values;
      }

      this.http.get(config.urls['apiUrl'] + api.endpoints['tiposdenuncia'], null).subscribe(tiposdenuncia => {
        const json = JSON.stringify(tiposdenuncia);
        sessionStorage.setItem('tiposdenuncia', json);
        return tiposdenuncia;
      });
    }
    return null;
  }
  GetTiposDenunciante(): SimpleCombo[] {
    if (this.authGuard.canActivate()) {
      if (sessionStorage.getItem('tiposdenunciante')) {
        const json = sessionStorage.getItem('tiposdenunciante');
        const values: SimpleCombo[] = JSON.parse(json.toString());
        return values;
      }

      this.http.get(config.urls['apiUrl'] + api.endpoints['tiposdenunciante'], null).subscribe(tiposdenuncia => {
        const json = JSON.stringify(tiposdenuncia);
        sessionStorage.setItem('tiposdenunciante', json);
        return tiposdenuncia;
      });
    }
    return null;
  }

  GetTipusVehicles(): any[] {
    if (this.authGuard.canActivate()) {
      if (sessionStorage.getItem('vehicles')) {
        const json = sessionStorage.getItem('vehicles');
        const values: CFG_PAISES[] = JSON.parse(json.toString());
        return values;
      }

      this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetTipusVehiclesCombo'], null).subscribe(result => {
        const json = JSON.stringify(result);
        sessionStorage.setItem('vehicles', json);
        return result;
      });
    }
    return [];
  }

  GetPaisos(): CFG_PAISES[] {
    if (this.authGuard.canActivate()) {
      if (sessionStorage.getItem('paisos')) {
        const json = sessionStorage.getItem('paisos');
        const values: CFG_PAISES[] = JSON.parse(json.toString());
        return values;
      }

      this.http.get(config.urls['apiUrl'] + api.endpoints['urlConfigPaisos'], null).subscribe(result => {
        const json = JSON.stringify(result);
        sessionStorage.setItem('paisos', json);
        return result;
      });
    }
    return [];
  }

  GetInfraccioTipusGravetat(): SOM_DEN_INFRACCIONES_GRAVEDAD[] {
    if (this.authGuard.canActivate()) {
      if (sessionStorage.getItem('infraccioTipusGravetat')) {
        const json = sessionStorage.getItem('infraccioTipusGravetat');
        const values: SOM_DEN_INFRACCIONES_GRAVEDAD[] = JSON.parse(json.toString());
        return values;
      }

      this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetInfraccioTipusGravetat'], null).subscribe(infraccioTipusGravetat => {
        const json = JSON.stringify(infraccioTipusGravetat);
        sessionStorage.setItem('infraccioTipusGravetat', json);
        return infraccioTipusGravetat;
      });
    }
    return null;
  }

  GetDenunciesOrigensForCombo(): any[] {
    if (this.authGuard.canActivate()) {
      if (sessionStorage.getItem('denunciesOrigensForCombo')) {
        const json = sessionStorage.getItem('denunciesOrigensForCombo');
        const values: any[] = JSON.parse(json.toString());
        return values;
      }

      this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetOrigensDenuncia'], null).subscribe(denunciesOrigensForCombo => {
        const json = JSON.stringify(denunciesOrigensForCombo);
        sessionStorage.setItem('denunciesOrigensForCombo', json);
        return denunciesOrigensForCombo;
      });
    }
    return null;
  }

  GetInfraccioDiesDescompte(): number {
    if (this.authGuard.canActivate()) {
      if (sessionStorage.getItem('infraccioDiesDescompte')) {
        const diesDescompte = sessionStorage.getItem('infraccioDiesDescompte');
        return Number.parseInt(diesDescompte);
      }

      this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetInfraccioDiesDescompte'], null).subscribe(diesDescompte => {
        sessionStorage.setItem('infraccioDiesDescompte', diesDescompte.toString());
        return diesDescompte;
      });
    }
    return null;
  }

  GetParametres() {
    if (this.authGuard.canActivate()) {
      let json = sessionStorage.getItem('parameters');
      if (!Reflection.empty(json)) {
        const values: CFG_PARAMETROS[] = JSON.parse(json.toString());
        return values;
      }
      this.http.get(config.urls['apiUrl'] + api.endpoints['urlConfigParametres'], null).subscribe(result => {
        json = JSON.stringify(result);
        sessionStorage.setItem('parameters', json);
        return result;
      });
    }
    return [];
  }

  GetParametresObservable() {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlConfigParametres'], null);
    }
  }

}
