import { Injectable } from '@angular/core';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { Observable } from 'rxjs/Observable';
import { Acuse } from '../models/acus-de-rebut.model';
import { InformarAcus } from '../models/informar-acus.model';

@Injectable({
  providedIn: 'root'
})
export class AcusosService {
  displayedItems = [];

  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {
    this.displayedItems['checkedValues'] = 0;
   }

  getAcusesTable(somid: number): Observable<any> {
    if (this.authGuard.canActivate()) {
        return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAcusos'] + '/' + somid.toString(), null);
    }
  }

  informarAcusos(acusos: InformarAcus[]): Observable<any> {
    if (this.authGuard.canActivate()) {
      const dataJson = JSON.stringify(acusos);
      const url = config.urls['apiUrl'] + api.endpoints['urlInformarAcusos'];
      return this.http.postJSON(url, dataJson);
    }
  }
}