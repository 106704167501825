import { Component, OnInit, Input, ViewChild, ContentChild, Renderer, ElementRef } from '@angular/core';

@Component({
  selector: 'app-nav-dropdown-item',
  templateUrl: './nav-dropdown-item.component.html',
  styleUrls: ['./nav-dropdown-item.component.scss']
})
export class NavDropdownItemComponent implements OnInit {
  @Input() text: string;
  @Input() icon: string;
  @Input() src: string;
  @ViewChild('dd') dd;

  constructor() {
    this.src = undefined;
  }

  ngOnInit() {
  }

  click(event) {
    console.log(this.dd);
    [].forEach.call(
      document.querySelectorAll('.sidebar li.nav-item.nav-dropdown'),
      function (el) {
          console.log(el);
          if (el !== event.parentElement) {
            el.removeAttribute('open');
            el.className = 'nav-item nav-dropdown';
          }
      }
  );
  /*
    const elements = document.querySelectorAll('.sidebar li.nav-item.nav-dropdown');
    if (elements !== undefined) {
      elements.forEach(element => {
        if (element !== this.dd.nativeElement) {
          element.removeAttribute('open');
        }
      });
    }*/
  }

}
