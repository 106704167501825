import { ReservesResum } from './../../../tiquets/reserves/models/reserves-resum';
import { Dictionary } from './../../../../shared/models/dictionary';
import { EstadistiquesReservesAparcareFilter } from './../../models/estadistiques-reserves-aparcare-filter';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { EstadistiquesService } from '../../services/estadistiques.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-estadistiques-reserves-aparcare',
  templateUrl: './estadistiques-reserves-aparcare.component.html',
  styleUrls: ['./estadistiques-reserves-aparcare.component.scss']
})
export class EstadistiquesReservesAparcareComponent implements OnInit {

  public dateSelected: Date;
  public oldDateSelected: Date;
  public title: string;
  public filterFechas: EstadistiquesReservesAparcareFilter;

  // Chart
  barChartData: ChartDataSets[];
  barChartLabels: any;
  barChartOptions: ChartOptions;
  barChartLegend: any;
  barChartType: ChartType;

  @ViewChild(BaseChartDirective) chart: BaseChartDirective;

  constructor(
    private estadistiquesService: EstadistiquesService,
    private i18n: I18n,
    public auth: AuthorizationService,
    private not: NotificationService) {

    // Preseleccionamos el dia de hoy.
    this.dateSelected = new Date();
  }

  ngOnInit() {
    this.createChart();
  }

  ngAfterViewInit() { }

  createChart() {
    this.barChartType = 'bar';
    this.barChartLegend = true;
    this.barChartOptions = {
      responsive: true,
      scales: { xAxes: [{ ticks: { autoSkip: false } }], yAxes: [{}] },
      plugins: {
        datalabels: {
          anchor: 'end',
          align: 'end',
        }
      }
    };
  }

  onDateChange(): void {
    // Tenemos otro valor de fecha. Debemos volver a descargar la info de estadisticas.
    if (this.dateSelected !== this.oldDateSelected) {
      this.oldDateSelected = this.dateSelected;
      this.getEstadisticasReservas();
    }
  }

  getEstadisticasReservas() {
    if (this.dateSelected) {
      // Creamos el filtro con las fechas correspondientes.
      const fechaInicio = new Date(this.dateSelected);
      fechaInicio.setDate(fechaInicio.getDate() - fechaInicio.getDay() + 1);
      const fechaFin = new Date(this.dateSelected);
      fechaFin.setDate(fechaFin.getDate() - fechaFin.getDay() + 6 + 1);
      this.filterFechas = <EstadistiquesReservesAparcareFilter> {
        fechaInicioConsulta: fechaInicio,
        fechaFinConsulta: fechaFin
      };
      // Realizamos la llamada para obtener la informacion.
      this.estadistiquesService.getEstadistiquesAparcareReserves(this.filterFechas).subscribe(result => {
        const arrayLabels = [];
        const arrayCoches = [];
        const arrayMotos = [];
        const arrayPersonas = [];

        const data = eval(result);
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            arrayCoches.push(data[key].CochesOcupados);
            arrayMotos.push(data[key].MotosOcupados);
            arrayPersonas.push(data[key].PersonasOcupados);
            arrayLabels.push(new Date(key).toLocaleDateString('es-ES'));
          }
        }
        if (!this.barChartLabels) {
          this.barChartLabels = [];
        }
        this.barChartLabels.length = 0;
        this.barChartLabels.push(...Object.values(arrayLabels));
        this.barChartData = [];

        this.barChartData.push({
          data: arrayCoches, label: this.i18n('Cotxes')
        });
        this.barChartData.push({
          data: arrayMotos, label: this.i18n('Motos')
        });
        this.barChartData.push({
          data: arrayPersonas, label: this.i18n('Persones')
        });
      });
    }
  }

}
