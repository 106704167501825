<app-dialog-header label="Expediente/Anulación" i18n-label></app-dialog-header>

<div class="row">
  <div class="col-6">

    <table class="table table-striped table-bordered">
      <thead>
        <tr>
            <th i18n colspan='2'>Datos expediente</th>
          </tr>
      </thead>
      <tbody>
        <tr>
          <td>  <label i18n>Expediente</label> </td>
          <td><span >{{anulacion.Expedient}}</span></td>
        </tr>
        <tr>
          <td><label i18n>Fecha</label></td>
          <td> <span >{{anulacion.DataExp }}</span></td>
        </tr>
        <tr>
          <td><label i18n>Hora</label> </td>
          <td><span >{{anulacion.HoraExp }}</span></td>
        </tr>
        <tr>
          <td> <label i18n>Matricula</label> </td>
          <td><span >{{anulacion.Matricula }}</span></td>
        </tr>
        <tr>
          <td><label i18n>Infracción</label></td>
          <td> <span >{{anulacion.infraccion }}</span></td>
        </tr>
        <tr>
          <td> <label i18n>Importe anulación</label></td>
          <td> <span >{{anulacion.importeZbAnulacion }}</span> </td>
        </tr>
        <tr>
          <td><label i18n>Periodo  anul(min)</label> </td>
          <td> <span >{{anulacion.zb_tiempo_anul }}</span></td>
        </tr>
      </tbody>
</table>
</div>
<div class="col-6">
  <table class="table table-striped table-bordered">
    <thead>
      <tr>
          <th i18n colspan='2'>Datos anulación</th>
        </tr>
    </thead>
    <tbody>
      <tr>
        <td>   <label i18n>nº Anulacion</label> </td>
        <td><span >{{anulacion.CodAnul}}</span></td>
      </tr>
      <tr>
        <td><label i18n>Fecha</label></td>
        <td> <span >{{anulacion.DataAnul }}</span></td>
      </tr>
      <tr>
        <td><label i18n>Hora</label> </td>
        <td><span >{{anulacion.HoraAnul }}</span></td>
      </tr>
      <tr>
        <td><label i18n>Importe pagado</label>  </td>
        <td><span >{{anulacion.Import }}</span></td>
      </tr>
      <tr>
        <td><label i18n>Diferencia  minutos</label>  </td>
        <td><span >{{anulacion.diferenciaMinutos }}</span></td>
      </tr>
      
     
    </tbody>
</table>
</div>
</div>
<div class="row">
  <div class="col-6">

    <table class="table table-striped table-bordered">
      <tbody>
        <tr>
          <td colspan="2">
                <span i18n>{{anulacion.Resultat}}</span>  
          </td>
        </tr>
      </tbody>
      </table>
  </div>
</div>
<div class="row">
  <div class="col-6">

    <table class="table table-striped table-bordered">
      <tbody>
        <tr>
          <td colspan="2">
            <div class="text-center">
              <button 
                class="btn btn-danger button-not-allowed btn-lg" type="button" (click)="this.descartar()">           
                <span i18n>Descartar anulación</span>  
              </button>
            </div>
          </td>
        </tr>
      </tbody>
      </table>
  </div>
  <div class="col-6">

    <table class="table table-striped table-bordered">
      <tbody>
        <tr>
          <td colspan="2">
            <div class="text-center">
              <button 
                class="btn btn-success button-not-allowed btn-lg" type="button" (click)="this.aceptar()" [disabled]="this.acceptDisabled()">           
                <span i18n>Aceptar anulación</span>  
              </button>
            </div>            
          </td>
        </tr>
        <tr><td colspan="2">
          <app-form-text-area    class="col-xl-12" i18n-label label="Motivo" [(value)]="this.motivo" maxLength="5000" ></app-form-text-area>
        </td></tr>
      </tbody>
      </table>
  </div>
</div>