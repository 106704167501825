<div class="row justify-content-between">
    <div class="col">
        <mat-paginator #paginator [pageIndex]="0" [pageSize]="this.filter.paginator.pageSize" [pageSizeOptions]="[20, 40, 60, 100]">
        </mat-paginator>
    </div>
</div>

<div class="table-sdw is-fixed">
      
    <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" matSortActive="{{this.sort.active}}" matSortDirection="{{this.sort.direction}}" class="is-header-sticky">

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset"></mat-row>

        <!-- Check Column -->
        <ng-container matColumnDef="check">
            <mat-header-cell *matHeaderCellDef class="is-checkbox">
                <mat-checkbox (change)="toggleAllCheckbox()"></mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
                <mat-checkbox [(ngModel)]="displayedItems[row.expedient].checked"></mat-checkbox>
            </mat-cell>
        </ng-container>

        <!-- Expedient Column -->
        <ng-container matColumnDef="expedient">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Expedient</mat-header-cell>
            <mat-cell *matCellDef="let row"><a (click)="getExpedients(row.expedient, row.numDenuncia)" class="is-link">{{row.expedient}}</a></mat-cell>
        </ng-container>

        <!-- NumDenuncia Column -->
        <ng-container matColumnDef="numDenuncia">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Número de denúncia</mat-header-cell>
            <mat-cell *matCellDef="let row"><a (click)="getButlleti(row)" class="is-link">{{row.numDenuncia}}</a></mat-cell>
        </ng-container>

        <!-- Tipus Column -->
        <ng-container matColumnDef="tipus">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Tipus</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.tipus}}</mat-cell>
        </ng-container>

        <!-- Resultat Column -->
        <ng-container matColumnDef="resultat">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Resultat</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.resultat}}</mat-cell>
        </ng-container>

        <!-- DataNotif Column -->
        <ng-container matColumnDef="dataNotificacio">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data Notif.</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.dataNotificacio | date:'dd/MM/yyyy'}}</mat-cell>
        </ng-container>

        <!-- Nom Column -->
        <ng-container matColumnDef="nomComplet">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Nom Complet</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.nomComplet}}</mat-cell>
        </ng-container>

    </mat-table>
</div>
