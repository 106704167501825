<div class="table-sdw">
  <table class="table table-striped table-bordered">
    <thead>
        <tr>
            <th i18n>Ref C60</th>
            <th i18n>Import 1</th>
            <th i18n>Data lim. pagament</th>
            <th i18n>Anul·lada</th>
            <th *ngIf="!this.singleImport" i18n>Import 2</th>
            <th *ngIf="!this.singleImport" i18n>Data Lim. Pagament</th>
            <th i18n>C60</th>
            <th i18n>Data creació</th>
        </tr>
    </thead>

    <tbody>
        <tr *ngFor="let row of data">
            <td>{{row.SOMREFC60}}</td>
            <td>{{row.SOMC60IMP1}} €</td>
            <td>{{row.SOMFECLIM1 | shortDate}}</td>
            <!-- <td>{{row.SOMANULAD}}</td> -->
            <td><app-form-check-edit [(value)]="row.SOMANULAD" disabled></app-form-check-edit></td>
            <td *ngIf="!this.singleImport">{{row.SOMC60IMP2}} €</td>
            <td *ngIf="!this.singleImport">{{row.SOMFECLIM2 | longDate}}</td>
            <td>{{row.SOMC60FINAL}}</td>
            <td>{{row.FECHA_CREACION | longDate}}</td>
        </tr>
    </tbody>
  </table>
</div>
