import { Component, Input, OnInit } from '@angular/core';
import { Builder } from 'path-shared/models/builder';
import { EscalaOcupacion } from '../../model/escala-ocupacion';

@Component({
  selector: 'app-escala-ocupacion-editor',
  templateUrl: './escala-ocupacion-editor.component.html',
  styleUrls: ['./escala-ocupacion-editor.component.scss']
})
export class EscalaOcupacionEditorComponent implements OnInit {

  _currentEditing: boolean;
  @Input() get currentEditing() {
    return this._currentEditing;
  } set currentEditing(val: any) {
    this._currentEditing = val;
  }

  _currentEscala: EscalaOcupacion;
  @Input() get currentEscala() {
    return this._currentEscala;
  } set currentEscala(val: any) {
    if (val !== undefined && val !== null) {
      this._currentEscala = val;
    } else {
      this._currentEscala = Builder.newEscalaOcupacion();
    }
  }

  _coloresBase: Array<string>;
  @Input() get coloresBase() {
    return this._coloresBase;
  } set coloresBase(val) {
    this._coloresBase = val;
  }

  isError: boolean = false;

  constructor() {
    this.currentEscala = Builder.newEscalaOcupacion();
    this.currentEditing = false;
    this.coloresBase = new Array();
  }

  ngOnInit(): void {
  }


  getColor(color: string) {
    if (color !== this.currentEscala.color) {
      return color + '59';
    } else {
      return color;
    }
  }

  changeColor(color: string) {
    if (this.currentEscala !== undefined && this.currentEscala.color !== color) {
      this.currentEscala.color = color;
    }
  }

  showFillFieldsMessage() {
    this.isError = true;
  }

}
