import { config } from './../../../../shared/configuration/config';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { Observable } from 'rxjs';
import { api } from 'path-shared/configuration/urls';



const defaultSortParam = 'exp';
const defaultSortDirection = 'desc';


@Injectable()
export class TramitarPredefinidasService {

    displayedItems = [];

    constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {
        this.displayedItems['checkedValues'] = 0;
        this.displayedItems['checkedItems'] = Array();
    }

    procesarTramitacionMasiva(som_ids: string[]): Observable<any> {
        if (this.authGuard.canActivate()) {
            return this.http.put(config.urls['apiUrl'] + api.endpoints['urlTramitarMasivo'], som_ids);
        }
    }

    procesarTramitacionMasivaSingle(id: string): Observable<any> {
        if (this.authGuard.canActivate()) {
            return this.http.put(config.urls['apiUrl'] + api.endpoints['urlTramitarMasivoSingle'], id);
        }
    }
}
