import { AutocompleteComboComponent } from 'path-shared/components/form/autocomplete-combo/autocomplete-combo.component';
import { LoaderService } from 'path-shared/components/loader/loader.service';
import { Component, OnInit, AfterViewInit, Optional, Inject, ViewChild } from '@angular/core';
import { Domicili } from '../../models/domiciliDto';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { Dictionary } from 'path-shared/models/dictionary';
import { PaisosService } from 'app/sections/configuracio/paisos/services/paisos.service.ts.service';
import { ComboBoxInputAutocompleteComponent } from 'path-shared/components/form/combo-box-input-autocomplete/combo-box-input-autocomplete.component';
import { debug } from 'util';
import { ToastrService } from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ElementRef } from '@angular/core';
import { FormService } from 'path-shared/services/form/form.service';

@Component({
  selector: 'app-domicilis-editor',
  templateUrl: './domicilis-editor.component.html',
  styleUrls: ['./domicilis-editor.component.scss']
})
export class DomicilisEditorComponent implements OnInit {
  action: string;
  local_data = new Domicili();
  direccioCompleta: BehaviorSubject<string>;
  paisosDictionary: Dictionary[];
  provinciasDictionary: Dictionary[];
  municipiosDictionary: Dictionary[];
  codigosPostales = [];
  valorAutoCompletePais = '';
  valorAutoCompleteProvincia = '';
  valorAutoCompleteMunicipio = '';
  private loaderService: LoaderService = LoaderService.getInstance();
  @ViewChild('comboPaisos') comboPaisos: AutocompleteComboComponent;
  @ViewChild('comboProvincia') comboProvincia: AutocompleteComboComponent;
  @ViewChild('combMunicipio') comboMunicipio: AutocompleteComboComponent;
  @ViewChild('mainForm') private mainForm: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<DomicilisEditorComponent>,
    private paisosService: PaisosService,
    private toastr: ToastrService,
    private i18n: I18n,
    private formService: FormService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.action = this.data.action;
    this.loaderService.show();
    this.direccioCompleta = new BehaviorSubject<string>('');
    this.getPaisos();
    this.getPronvicias();
    this.getMunicipios();
    this.getCodigosPostales();
    setTimeout(() => {

      this.local_data = {...data.domicili};
      if (this.action === 'Add') {
        this.local_data.actual = true;
      }
      // this.valorAutoCompletePais = '';
      // this.valorAutoCompleteProvincia = '';
      // this.valorAutoCompleteMunicipio = '';
      this.setDireccioCompleta();
      this.setCombosWithInfo();
    }, 1500);
  }

  ngOnInit() {
    this.setDireccioCompleta();
  }
  getPaisos() {
    this.paisosDictionary = new Array<Dictionary>();
      this.paisosService.getPaisosCombo().subscribe(newValue => {
      this.loaderService.show();
      newValue.forEach(element => {
        this.paisosDictionary.push(new Dictionary(element.secureId.toString(), element.descripcio));
      });
      // this.valorAutoCompletePais = this.local_data.pais;
      this.loaderService.hide();
    });
  }

  getPronvicias() {
    this.provinciasDictionary = new Array<Dictionary>();
    this.paisosService.getProvinciasCombo().subscribe(newValue => {
      this.loaderService.show();
     newValue.forEach(element => {
       this.provinciasDictionary.push(new Dictionary(element.secureId.toString(), element.nom));
     });
    //  this.valorAutoCompleteProvincia = this.local_data.provincia;
     this.loaderService.hide();
   });
  }

  getCodigosPostales() {
    this.paisosService.getCodigosPostales().subscribe(res => {
      this.codigosPostales = res;
    });
  }

  getPronviciasByPais(secureIdPais: string, reloadProv: boolean = false) {
    this.provinciasDictionary = new Array<Dictionary>();
    this.paisosService.getProvinciasComboByPais(secureIdPais).subscribe(newValue => {
      this.loaderService.show();
     newValue.forEach(element => {
       this.provinciasDictionary.push(new Dictionary(element.secureId.toString(), element.nom));
     });
     if ( reloadProv ) {
       this.resetComboProvinicia();
       this.resetComboMunicipio();
     }
     this.loaderService.hide();
   });
  }

  getMunicipios() {
    this.municipiosDictionary = new Array<Dictionary>();
    this.paisosService.getMunicipiosCombo().subscribe(newValue => {
      this.loaderService.show();
     newValue.forEach(element => {
       this.municipiosDictionary.push(new Dictionary(element.secureId.toString(), element.nom));
     });
    //  this.valorAutoCompleteMunicipio = this.local_data.localitat;
     this.loaderService.hide();
   });
  }

  getMunicipiosByProv(secureIdProv: string, reloadMunicipio: boolean = false) {
    this.municipiosDictionary = new Array<Dictionary>();
    this.paisosService.GetMunicipiosComboByProv(secureIdProv).subscribe(newValue => {
      this.loaderService.show();
     newValue.forEach(element => {
       this.municipiosDictionary.push(new Dictionary(element.secureId.toString(), element.nom));
     });
     if ( reloadMunicipio ) {
      this.resetComboMunicipio();
    }
     this.loaderService.hide();
   });
  }

  doAction() {

    if (this.formService.allFieldsValid(this.mainForm)) {
      if ( this.AreValidValuesCombos() ) {
        this.setDireccioCompleta();
        this.local_data.domicili = this.direccioCompleta.value;
        this.dialogRef.close({event: this.action, data: this.local_data});
      } else {
        this.toastr.warning(   this.i18n('Error en la selecció.'));
      }
    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
    }
  }

  AreValidValuesCombos(): boolean {
    if (  ( this.paisosDictionary !== undefined && this.paisosDictionary === null && this.local_data.pais !== undefined && this.local_data.pais !== null &&
      this.local_data.pais !== '' && this.paisosDictionary.filter(x => x.value.toLowerCase().includes(this.local_data.pais)).length !== 1 ) ) {
        return false;
      } else if (  this.provinciasDictionary !== undefined && this.provinciasDictionary === null && this.local_data.provincia !== undefined
         && this.local_data.provincia !== null && this.local_data.provincia !== '' &&
        this.provinciasDictionary.filter(x => x.value.toLowerCase().includes(this.local_data.provincia)).length !== 1 )  {
        return false;
      } else if (  this.municipiosDictionary !== undefined && this.municipiosDictionary === null && this.local_data.localitat !== undefined
        && this.local_data.localitat !== null && this.local_data.localitat !== '' &&
       this.municipiosDictionary.filter(x => x.value.toLowerCase().includes(this.local_data.localitat)).length !== 1 )  {
       return false;
     }
    return true;
  }

  closeDialog() {
    this.dialogRef.close({event: 'Cancel'});
  }

  setDireccioCompleta() {
    if (this.direccioCompleta === undefined || this.direccioCompleta === null) {
      this.direccioCompleta = new BehaviorSubject<string>('');
    }
    let dir = '';
    if (this.local_data !== undefined  && this.local_data !== null ) {
      if (this.local_data !== undefined  && this.local_data !== null &&  this.local_data.dirSimple !== undefined && this.local_data.dirSimple !== null && this.local_data.dirSimple !== '') {
        dir = this.local_data.dirSimple;
      }
      if (this.local_data !== undefined && this.local_data.numero !== undefined && this.local_data.numero !== null && this.local_data.numero !== '') {
        dir = dir + ' Nº ' + this.local_data.numero;
      }

      if (this.local_data !== undefined && this.local_data.escala !== undefined && this.local_data.escala !== null && this.local_data.escala !== '') {
        dir = dir + ' ESC ' + this.local_data.escala;
      }
      if (this.local_data !== undefined && this.local_data.pis !== undefined && this.local_data.pis !== null && this.local_data.pis !== '') {
        dir = dir + ' ' + this.local_data.pis;
      }
      if (this.local_data !== undefined && this.local_data.porta !== undefined && this.local_data.porta !== null && this.local_data.porta !== '') {
        dir = dir + ' ' + this.local_data.porta;
      }
      if (dir === '' && this.local_data !== undefined &&  this.local_data.domicili !== '' && this.local_data.domicili !== undefined) {
        dir = this.local_data.domicili;
      }
    }

    this.direccioCompleta.next(dir);
  }

  paisSeleccionado(idPais: string, reloadProv: boolean = true) {
    this.local_data.pais = this.paisosDictionary.find(x => x.key === idPais).value;
    this.getPronviciasByPais(idPais, reloadProv);
  }
  provinciaSeleccionado(idProvincia: string, reloadProv: boolean = true ) {
    this.local_data.provincia = this.provinciasDictionary.find(x => x.key === idProvincia).value;
    this.getMunicipiosByProv(idProvincia, reloadProv);
  }
  municipioSeleccionado(idMunicipio: string ) {
    this.local_data.localitat = this.municipiosDictionary.find(x => x.key === idMunicipio).value;
  }
  setCombosWithInfo() {
    this.loaderService.show();
    if (this.local_data.pais !== undefined && this.local_data.pais !== null && this.local_data.pais !== '') {
      this.comboPaisos.setValueComb(this.local_data.pais);
    }

    if (this.local_data.provincia !== undefined && this.local_data.provincia !== null && this.local_data.provincia !== '') {
      this.comboProvincia.setValueComb(this.local_data.provincia);
    }

    if (this.local_data.localitat !== undefined && this.local_data.localitat !== null && this.local_data.localitat !== '') {
      this.comboMunicipio.setValueComb(this.local_data.localitat);
    }

    this.loaderService.hide();
  }

  resetComboProvinicia() {
    if (  ( this.provinciasDictionary === undefined || this.provinciasDictionary === null ) || ( this.local_data.provincia !== undefined && this.local_data.provincia !== null &&
      this.local_data.provincia !== '' && this.provinciasDictionary.filter(x => x.value.toLowerCase().includes(this.local_data.provincia)).length !== 1 ) ) {
        this.local_data.provincia = '';
        this.comboProvincia.setForcedValueComb(this.local_data.provincia);
      }
  }
  resetComboMunicipio() {
    if ( ( this.municipiosDictionary === undefined || this.municipiosDictionary === null ) || ( this.local_data.localitat !== undefined && this.local_data.localitat !== null &&
      this.local_data.localitat !== '' && this.municipiosDictionary.filter(x => x.value.toLowerCase().includes(this.local_data.localitat)).length !== 1 ) ) {
        this.local_data.localitat = '';
        this.comboMunicipio.setForcedValueComb(this.local_data.localitat);
      }
  }

  resetComboPais() {
    if ( ( this.paisosDictionary === undefined || this.paisosDictionary === null ) || ( this.local_data.pais !== undefined && this.local_data.pais !== null &&
      this.local_data.pais !== '' && this.paisosDictionary.filter(x => x.value.toLowerCase().includes(this.local_data.pais)).length !== 1 ) ) {
        this.local_data.pais = '';
        this.comboPaisos.setForcedValueComb(this.local_data.pais);
      }
  }

  autofillByCP() {
    if (this.local_data.codiPostal !== undefined && this.local_data.codiPostal.length === 5) {
      const cp = this.codigosPostales.find(x => x.codiPostal === this.local_data.codiPostal);
      if (cp !== undefined) {
        this.municipioSeleccionado(cp.secureId);
        this.provinciaSeleccionado(cp.SECURE_ID_Provincia, false);
        this.paisSeleccionado('102000000000002600001|138B8D0D213530F7298780F6CA89ABA8', false);
        this.setCombosWithInfo();
      }
    } else {
      this.resetComboProvinicia();
      this.resetComboMunicipio();
      this.resetComboPais();
      // this.setCombosWithInfo();
    }
  }
}
