<div class="actions-bar">
  <div class="actions-bar__wrapper">
    <button class="actions-bar__button">
      <span class="actions-bar__button-icon has-color-orange"><i class="fas fa-edit"></i></span>
      <span class="actions-bar__button-label">Editar</span>
    </button>
    <button class="actions-bar__button">
      <span class="actions-bar__button-icon has-color-green"><i class="fas fa-save"></i></span>
      <span class="actions-bar__button-label">Nou</span>
    </button>
  </div>
</div>

<div class="row">
  <div class="col-md-4">

  </div>

  <div class="col-md-8">

  </div>
</div>
