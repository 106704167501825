<app-filter-header title="Filtrar sense retirada" i18n-title (apply)="this.apply()" (reset)="this.reset()"
  (dismiss)="this.dismiss()"></app-filter-header>
<div class="modal-body">
  <div class="row">

    <app-form-text-edit class="col-4" id="sense-retirada-filter-matricula" [(value)]="this.filter.matricula"
      label="Matrícula" i18n-label></app-form-text-edit>
    <app-form-text-edit class="col-4" id="sense-retirada-filter-marca" [(value)]="this.filter.marca" label="Marca"
      i18n-label></app-form-text-edit>
    <app-form-text-edit class="col-4" id="sense-retirada-filter-modelo" [(value)]="this.filter.modelo" label="Model"
      i18n-label></app-form-text-edit>
    

  </div>

  <div class="row">
    <app-form-date-edit class="col-4" [(value)]="this.filter.dataInici" label="Data inici" i18n-label>
    </app-form-date-edit>
    <app-form-date-edit class="col-4" [(value)]="this.filter.dataFi" label="Data fi" i18n-label>
      </app-form-date-edit>
      <app-form-combo-edit class="col-4" [items]="this.motiusNoRetirada" i18n-label label="Motius no retirada"
      [(value)]="this.filter.idNoRetirada" textField="descripcio" valueField="codi"></app-form-combo-edit>
  </div>
</div>