<div class="row justify-content-between" [hidden]="true">
  <div class="col"><app-filter-selector [filter]="this.filter"></app-filter-selector></div>
  <div class="col">
    <mat-paginator #paginator
      [pageIndex]="0"
      [pageSize]="this.filter.paginator.pageSize"
      [pageSizeOptions]="[20, 40, 60, 100]">
    </mat-paginator>
  </div>
</div>
<app-dialog-header label="Pendientes" i18n-label></app-dialog-header>

<app-dialog-body>
  <app-toolbar>    
    <app-tool-button text="Archivar" i18n-text (click)="Save();" color="has-color-green" icon="fa-file-export"></app-tool-button>    
  </app-toolbar>
<div class="center">
  <!-- <button class="btn btn-sm btn-primary ml-2" mat-button (click)="Save()">Archivar</button> -->
  <label class="left">Núm. Registros {{this.getNumRegistrosSelecionados()}}/{{this.dataSource.getData().length}}</label>
</div>

<div class="table-sdw is-fixed">
  <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" matSortActive="{{this.sort.active}}" matSortDirection="{{this.sort.direction}}" class="is-header-sticky">

    <ng-container matColumnDef="Check">
      <mat-header-cell *matHeaderCellDef class="is-checkbox"> <mat-checkbox [(ngModel)]="this.allSelected" (change)="toggleAllCheckbox()"></mat-checkbox> </mat-header-cell>
      <mat-cell *matCellDef="let row">  <mat-checkbox  #checkbox [(ngModel)]="row.Check"></mat-checkbox> </mat-cell>
    </ng-container>

      <!-- Matricula Column -->
      <ng-container matColumnDef="EXP">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Expediente</mat-header-cell>
          <mat-cell *matCellDef="let row"><a *ngIf="row.SomIdDenuncia!==''" (click)="onRowClicked(row)" class="is-link">{{row.EXP}}</a> <span *ngIf="row.SomIdDenuncia===''">{{row.EXP}}</span> </mat-cell>
      </ng-container>

      <!-- Expedient Column -->
      <ng-container matColumnDef="NumDenuncia">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Núm. Denuncia</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.NumDenuncia}}</mat-cell>
      </ng-container>

      <!-- Bulleti Column -->
      <ng-container matColumnDef="Data">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Fecha</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.Data | shortDate}}</mat-cell>
      </ng-container>
     
      <!-- Data Column -->
      <ng-container matColumnDef="Matricula">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Matrícula</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.Matricula}}</mat-cell>
      </ng-container>

       <!-- Data Column -->
       <ng-container matColumnDef="ImportEuros">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Importe</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.ImportEuros}}</mat-cell>
    </ng-container>

     <!-- Data Column -->
     <ng-container matColumnDef="Fecha_Prescripcion">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Fecha Prescripción</mat-header-cell>
      <mat-cell *matCellDef="let row" [ngClass]="{'deadline' : isOldDataPrescripcio(row)}">{{row.Fecha_Prescripcion | shortDate}}</mat-cell>
  </ng-container>

   <!-- Data Column -->
   <ng-container matColumnDef="Fecha_Caducidad">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Fecha Caducidad</mat-header-cell>
    <mat-cell *matCellDef="let row" [ngClass]="{'deadline' : isOldDataCaducitat(row)}">{{row.Fecha_Caducidad | shortDate}}</mat-cell>
</ng-container>

 <!-- Data Column -->
 <ng-container matColumnDef="Resposta">
  <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Respuesta</mat-header-cell>
  <mat-cell *matCellDef="let row">{{row.Resposta}}</mat-cell>
</ng-container>
     

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>        
      <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset"></mat-row>
  </mat-table>
</div>
</app-dialog-body>
