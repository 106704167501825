import { Maquina } from './../models/maquina.model';
import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { MaquinasFilter } from '../models/maquina-filter';
import { MaquinasFilterService } from './maquinas-filter.service';
import { GenericFile } from 'path-shared/components/generic-file-displayer/model/generic-file';
import { GenericFileFilter } from 'path-shared/components/generic-file-displayer/model/generic-file.filter';
import { GenericFileFilterService } from 'path-shared/components/generic-file-displayer/services/generic-file-filter.service';

const defaultSortParam = 'id';
const defaultSortDirection = 'desc';

@Injectable()
export class MaquinasService {
  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {}

  setMaquina(tv: Maquina): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetMaquina'], tv);
    }
  }

  removeMaquina(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteMaquina'].replace('{secure_id}', secure_id), null);
    }
  }

  getMaquina(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetMaquina'].replace('{secure_id}', secure_id), null);
    }
  }

  generateExcel(filter: MaquinasFilter, sortDir: string, sortField: string): Observable<any> {
    const start = 0;
    const pageSize = 1000;

    sortField = sortField === undefined ? defaultSortParam : sortField;
    sortDir = sortDir === '' ? defaultSortDirection : sortDir;
    const params = new MaquinasFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

    if (this.authGuard.canActivate()) {
      return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlMaquinaExcel'],  params );
    }
  }

  getAllMarcasMaquinas(): Observable<any> {
    if ( this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAllMarcaMaquina'], null);
    }
  }

  getFicherosMaquina(id: number): Observable<any> {

      const sortField = 'id';
      const sortdir = 'desc';
      const start = 0;
      const pageSize = 1000;

      const filter = new GenericFileFilter(sortField, sortdir);
      filter.idRegistroAsociado = id.toString();
      filter.eliminado = false.toString();
      const params = new GenericFileFilterService().createFilterParams(filter, start, pageSize, sortField, sortdir);

      if (this.authGuard.canActivate()) {
          return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetFicherosMaquina'], params);
      }
  }
  uploadMaquinaFichero(file: GenericFile): Observable<any> {
    if (this.authGuard.canActivate()) {
        return this.http.post(config.urls['apiUrl'] + api.endpoints['urlPostFileMaquina'], file);
    }
  }
  deleteFicheros(ids: string[]): Observable<any> {
    // const httpParams = new SenalesVerticalesFilterService().createFilesFilterParams(ids);
    if (this.authGuard.canActivate()) {
        return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteFilesMaquina']
        .replace('{ids}', JSON.stringify(ids)), null);
    }
  }

  getIncidenciesMaquina(id: number) : Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetIncidenciesMaquinaById'].replace('{id}', id.toString()), null);
    }
  }

  getActuacionsIncidencies(id: number) : Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetActuacionsById'].replace('{id}', id.toString()), null);
    }
  }
  newActuacioIncidencia(request: any) : Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlNewActuacio'], request);
    }
  }
  resoldreIncidenciesMaquina(id: number, resolta: boolean) {
    if (this.authGuard.canActivate()) {
      let data = {
        'somId': id,
        'resolta': resolta
      };
      
      return this.http.postJSON(config.urls['apiUrl'] + api.endpoints['urlResoldreIncidenciesMaquina'] , JSON.stringify(data));
    }
  }
}
