import { Component, OnInit, Input } from '@angular/core';
import { inject } from '@angular/core/testing';

@Component({
  selector: 'app-dash-square',
  templateUrl: './dash-square.component.html',
  styleUrls: ['./dash-square.component.scss']
})
export class DashSquareComponent implements OnInit {
  @Input() title: string;
  @Input() text: string;
  @Input() link: string;
  @Input() linkLabel: string;
  @Input() number: number;
  @Input() iconClass: string;
  @Input() icon: string;

  constructor() { }

  ngOnInit() {
  }

}
