<div class="row justify-content-between">
    <div class="col">
        <app-filter-selector [filter]="this.filter"></app-filter-selector>
    </div>
    <div class="col">
        <mat-paginator #paginator [pageIndex]="0" [pageSize]="this.filter.paginator.pageSize" [pageSizeOptions]="[20, 40, 60, 100]">
        </mat-paginator>
    </div>
</div>

<div class="table-sdw is-fixed">

    <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.filter.paginator.sortField" [matSortDirection]="this.filter.paginator.sortDir" class="is-header-sticky">

        <ng-container matColumnDef="incorpora" *ngIf="this.selectButton">
            <mat-header-cell *matHeaderCellDef title="Incorporar" i18n-title class="text-center"><i class="fa fa-plus-square has-color-grey"></i></mat-header-cell>
            <mat-cell *matCellDef="let row" class="text-center"><a (click)="this.selectCurrent(row)" title="Incorporar" i18n-title><i class="fa fa-plus-square has-color-aqua"></i></a></mat-cell>
        </ng-container>

        <!-- Tipus Column -->
        <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Codi</mat-header-cell>
            <mat-cell *matCellDef="let row"><a (click)="onRowClicked(row)" class="is-link cursor detalle">{{row.id}}</a></mat-cell>
        </ng-container>

        <!-- Legislacio Column -->
        <ng-container matColumnDef="legislacio">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Legislació</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.legislacio}}</mat-cell>
        </ng-container>

        <!-- Article Column -->
        <ng-container matColumnDef="article">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Article</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.article}}</mat-cell>
        </ng-container>

        <!-- Apartat Column -->
        <ng-container matColumnDef="apartat">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Apartat</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.apartat}}</mat-cell>
        </ng-container>

        <!-- Opcio Column -->
        <ng-container matColumnDef="opcio">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Opció</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.opcio}}</mat-cell>
        </ng-container>

        <!-- descCurta Column -->
        <ng-container matColumnDef="descCurta">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Descripció curta</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.descCurta}}</mat-cell>
        </ng-container>

        <!-- import Column -->
        <ng-container matColumnDef="import">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Import</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.import}}</mat-cell>
        </ng-container>

        <!-- fins Column -->
        <ng-container matColumnDef="fins">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Fins</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.fins}}</mat-cell>
        </ng-container>

        <!-- punts Column -->
        <ng-container matColumnDef="punts">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Punts</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.punts}}</mat-cell>
        </ng-container>

        <!-- obsoleta Column -->
        <ng-container matColumnDef="obsoleta">
            <!--<mat-header-cell *matHeaderCellDef mat-sort-header i18n>obsoleta</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.obsoleta}}</mat-cell>-->
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Obsoleta</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <mat-checkbox [checked]="row.obsoleta" [disabled]='true'></mat-checkbox>
            </mat-cell>
        </ng-container>

        <!-- inici Column -->
        <ng-container matColumnDef="inici">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Inici</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.inici | shortDate }}</mat-cell>
        </ng-container>

        <!-- final Column -->
        <ng-container matColumnDef="final">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Final</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.final | shortDate }}</mat-cell>
        </ng-container>

        <!-- numFotosPda column -->
        <ng-container matColumnDef="numFotosPda">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Núm fotos pda</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.numFotosPda}}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
    </mat-table>
</div>
