import { FormService } from '../../../../../shared/services/form/form.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { Proposta } from '../../../models/proposta.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { PropostesService } from 'path-propostes-services/propostes.service';
import { Reflection } from 'path-shared/services/reflection/reflection';
import { CarrersService } from 'app/sections/configuracio/carrers/services/carrers-service.service';

@Component({
  selector: 'app-propostes-lloc-editor',
  templateUrl: './propostes-lloc-editor.component.html',
  styleUrls: ['./propostes-lloc-editor.component.scss']
})
export class PropostesLlocEditorComponent implements OnInit {
  carrers = Array();
  copia: Proposta;
  ultimesAdreces: any[];
  displayedColumns = ['carrer', 'numero', 'seleccionar'];

  @ViewChild('mainForm') private mainForm: ElementRef;

  constructor(
    private i18n: I18n,
    private toastr: ToastrService,
    private propostesService: PropostesService,
    private carrersService: CarrersService,
    public thisDialogRef: MatDialogRef<PropostesLlocEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Proposta,
    private formService: FormService  ) {
    this.copia = Reflection.deepCopy<Proposta>(this.data);
    // detectar ESC
    this.thisDialogRef.afterClosed().subscribe(val => {
      if (val === undefined || val === '') {
        Reflection.copy(this.copia, this.data);

      }
    });
  }

  ngOnInit() {
    // this.carrers.push({'id': '', 'nomCarrer': ''});
    this.ultimesAdreces = JSON.parse(sessionStorage.getItem('lasAdrressesUsed')) || [];
    this.loadCalles();
  }

  disabledSaveButton() {
    return this.copia.ID_LLOC === this.data.ID_LLOC && this.copia.NUMERO === this.data.NUMERO;
  }

  save() {
    if (this.formService.allFieldsValid(this.mainForm)) {
        this.confirm();
    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
    }
  }

  private confirm() {
    this.saveUltimesAdreces();
    this.propostesService.saveProposta(this.data).subscribe((upatedValue: Proposta) => {
      this.toastr.info(this.i18n('Proposta modificada correctament'));
      // this.thisDialogRef.close(`${upatedValue.LLOC}`);
      this.thisDialogRef.close({lloc: `${upatedValue.LLOC}`, relacions: upatedValue.relacions});
    });
  }

  cancel() {
    Reflection.copy(this.copia, this.data);
    this.thisDialogRef.close(``);
  }

  getTitle() {
    return this.i18n('Editar lloc proposta') + ' ' + this.data.NUMDENUNCIA;
  }

  saveUltimesAdreces(): void {
    const newAddrress: any = {
      carrer: this.carrers.find(e => e.id === this.data.ID_LLOC),
      numero: this.data.NUMERO
    };

    this.ultimesAdreces = this.ultimesAdreces.filter(e => !(e.carrer.id === this.data.ID_LLOC && e.numero === this.data.NUMERO));
    this.ultimesAdreces.unshift(newAddrress);

    sessionStorage.setItem('lasAdrressesUsed', JSON.stringify(this.ultimesAdreces));
  }

  seleccionarAdreca(row: any): void {
    this.data.ID_LLOC = row.carrer.id;
    this.data.NUMERO = row.numero;
  }

  loadCalles() {
    let json: string;
    let carrers: any[];

    if (sessionStorage.getItem('carrersForCombo')) {
      json = sessionStorage.getItem('carrersForCombo');
      carrers = JSON.parse(json.toString());
      this.setCalles(carrers);
    } else {
      this.carrersService.getAllForCombo().subscribe(carrersForCombo => {
        json = JSON.stringify(carrersForCombo);
        sessionStorage.setItem('carrersForCombo', json);
        this.setCalles(carrersForCombo);
      });
    }
  }

  setCalles(result: any[]) {
    if (result !== null) {
      result.forEach(element => {
        this.carrers.push({'id': element.somId, 'nomCarrer': (element.sigles !== null ? element.sigles : '') + ' ' +  element.nomCarrer});
      });
    }
  }

}
