import { PropostaGruaDto } from './../../Models/proposta-grua.model';
import { GruaPendentsService } from './../../services/grua-pendents.service';
import { Component, OnInit, ViewChild, SimpleChanges, Input, AfterViewInit, OnChanges, Output, EventEmitter } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { SomPolGruaFilterService } from '../../services/som-pol-grua-filter-service';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { Builder } from 'path-shared/models/builder';
import { SomPolGruaFilter } from '../../Models/som-pol-grua-filter';
import { PendentsComponent } from '../pendents/pendents.component';


@Component({
  selector: 'app-tramitades-table',
  templateUrl: './tramitades-table.component.html',
  styleUrls: ['./tramitades-table.component.scss']
})
export class TramitadesTableComponent implements OnInit, AfterViewInit, OnChanges {


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() selectButton: boolean;
  @Output() itemSelected: EventEmitter<any> = new EventEmitter<any>();
  filter: SomPolGruaFilter = new SomPolGruaFilter('codi', 'desc');
  dataSource: CustomDataSource<PropostaGruaDto, SomPolGruaFilter>;
  highlightedRows = [];
  displayedItems = [];

  @Input() updatedFilterData: SomPolGruaFilter;
  // @Output() notify: EventEmitter<string> = new EventEmitter<string>();
  displayedColumns = ['codi', 'implicaDenuncia', 'valida', 'motiuFinalitzacio', 'dataPropostaPda', 'fechaAcepDeneg', 'idAgentFk', 'idVehiclesFK', 'nombreCalle', 'numCarrer', 'idInfraccioFK', 'observacions', 'numFotos', 'numPDA', 'origen', 'data', 'dataFinalitzacio'];
  constructor(
    private gruaService: GruaPendentsService,
    private filterService: FilterService,
    private dialog: MatDialog,
    private i18n: I18n
  ) {
    this.selectButton = false;
  }

  ngOnInit() {
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<PropostaGruaDto, SomPolGruaFilter>(this.paginator, new SomPolGruaFilterService(), 'urlGetSomPolGruaTramitades', 'urlGetSomPolGruaTramitadesCount');
    this.dataSource.subject.subscribe((data) => {
      data.forEach(item => {
        this.displayedItems[item.secureId] = {
          id: item.secureId
        };
      });
    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
      }
    });

    this.filter.paginator.init(this.sort, this.paginator);
    if (this.selectButton) {
      this.displayedColumns = ['incorpora'].concat(this.displayedColumns);
    }
  }

  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(tap(() => this.filter.updated()))
    .subscribe();

    this.load();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      this.update();
    }
  }

  update() {
    if (this.dataSource !== undefined) {
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
    }
  }

  load() {
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }

  selectCurrent(row) {
    this.itemSelected.next(row);
}

  // ngOnInit() {
  //   this.sort.disableClear = true;
  //   this.dataSource = CustomDataSource.create<PropostaGruaDto, SomPolGruaFilter>(this.paginator, new SomPolGruaFilterService(), 'urlGetSomPolGruaTramitades', 'urlGetSomPolGruaTramitadesCount');

  //   this.dataSource.subject.subscribe((data) => {
  //     data.forEach(item => {
  //       this.displayedItems[item.secureId] = {
  //         id: item.secureId
  //       };
  //     });
  //   });
  //   this.filter.filterUpdated.subscribe(updated => {
  //     if (updated) {
  //       if (!this.dataSource.loading) {
  //        // this.getTiquetLeyenda(this.filter);
  //       }

  //       this.load();
  //       this.filterService.saveFilter('som-pol-grua-filter', this.filter);
  //     }
  //   });

  //   this.filterService.loadFilter('som-pol-grua-filter', this.filter);
  //   this.filter.paginator.init(this.sort, this.paginator);
  //   //this.getTiquetLeyenda(this.filter);
  // }

  // ngAfterViewInit() {
  //   // reset the paginator after sorting
  //   this.sort.sortChange.subscribe(() => {
  //     this.paginator.pageIndex = 0;
  //     this.filter.paginator.set(this.sort, this.paginator);
  //     this.filter.updated();
  //   });

  //   merge(this.sort.sortChange, this.paginator.page)
  //   .pipe(tap(() => this.filter.updated()))
  //   .subscribe();

  //   this.load();
  // }

  // ngOnChanges(changes: SimpleChanges) {
  //   if (this.updatedFilterData != null) {
  //     this.update();
  //   }
  // }

  // update() {
  //   if (this.dataSource !== undefined) {
  //     this.updatedFilterData.paginator.set(this.sort, this.paginator);
  //     this.dataSource.loadFilter(this.updatedFilterData);
  //   }
  // }

  // load() {
  //   this.filter.paginator.set(this.sort, this.paginator);
  //   this.dataSource.loadFilter(this.filter);
  // }


  generateExcel(): void {
     const filter = this.filter;
     /*if (this.updatedFilterData != null) {
       filter = this.updatedFilterData;
     }*/
     this.dialog.open(DownloadExcelDialogComponent, {
         closeOnNavigation: false,
         autoFocus: true,
         data: Builder.newDownloadExcelDialogData (this.gruaService, filter, this.i18n('Grua'), this.sort.direction, this.sort.active)
    });
  }

  onRowClicked(row) {
    if (row !== undefined && row !== null) {
      const ref = this.dialog.open(PendentsComponent, {
        width: '90%',
        height: '98%',
        closeOnNavigation: true,
        autoFocus: true,
        data: {
          'isDialog': true,
          'isReadOnly': false,
          'propostaGrua': row,
          'isProposta': false
        }
      });
      ref.afterClosed().subscribe(data => {
        this.load();
      });
    }
  }
}
