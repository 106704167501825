<div class="row justify-content-between">
  <!-- <div class="col"><app-filter-selector [filter]="this.filter"></app-filter-selector></div> -->
  <div class="col">
    <mat-paginator #paginator
      [pageIndex]="0"
      [pageSize]="this.filter.paginator.pageSize"
      [pageSizeOptions]="[20, 40, 60, 100]">
    </mat-paginator>
  </div>
</div>

<div class="row">
  <div class="col-sm-7 table-sdw is-fixed">
    <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.filter.paginator.sortField" [matSortDirection]="this.filter.paginator.sortDir" class="is-header-sticky">
      <!--<ng-container matColumnDef="incorpora" *ngIf="this.selectButton">
        <mat-header-cell *matHeaderCellDef title="Incorporar" i18n-title class="text-center"><i class="fa fa-plus-square has-color-grey"></i></mat-header-cell>
        <mat-cell *matCellDef="let row" class="text-center"><a (click)="this.selectCurrent(row.codi)" title="Incorporar" i18n-title><i class="fa fa-plus-square has-color-aqua"></i></a></mat-cell>
      </ng-container>-->
      <ng-container matColumnDef="mapa">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Mapa</mat-header-cell>
        <mat-cell *matCellDef="let row"><button class="btn btn-outline btn-xs" style="color: blue" i18n-data-content i18n-title (click)="this.loadMarkersMaquina(row.position)"><i class="fas fa-map-pin"></i></button></mat-cell>
      </ng-container>
      <ng-container matColumnDef="idZBMaquinaParkeon">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Codi</mat-header-cell>
        <mat-cell *matCellDef="let row"><a (click)="onRowClicked(row)" class="is-link cursor detalle">{{row.codi}}</a></mat-cell>
      </ng-container>
      <ng-container matColumnDef="CODI_MAQUINA">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Codi Màquina</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.codiMaquina}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="descripcion">
        <mat-header-cell *matHeaderCellDef i18n>Descripció</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.descripcion}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="Marca">
        <mat-header-cell *matHeaderCellDef i18n>Marca</mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.marcaMaquina ? row.marcaMaquina.descripcion : ''}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="Model">
        <mat-header-cell *matHeaderCellDef i18n>Model</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.modelo}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="temporada">
        <mat-header-cell *matHeaderCellDef i18n>Temporada</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.temporada ? row.temporada.descripcion: ''}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="incidencies">
        <mat-header-cell *matHeaderCellDef i18n>Incidències</mat-header-cell>
        <mat-cell *matCellDef="let row" class="text-center">
          <ng-container *ngIf="isIncidenciesTooltip(row);else second">
            <button class="btn btn-outline-success btn-xs" i18n-data-content i18n-title (click)="openIncidenciesPage(row.codi)"><i class="fas fa-check"></i></button>
          </ng-container>
          <ng-template #second>
            <button #toolTip class="btn btn-outline-warning btn-xs" i18n-data-content [matTooltip]="showIncidencies(row.incidencies)" matTooltipClass="line-broken-tooltip" i18n-title (click)="openIncidenciesPage(row.codi)"><i class="fas fa-exclamation"></i></button>
          </ng-template>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="comprovarEstat">
        <mat-header-cell *matHeaderCellDef i18n>Comprovar estat</mat-header-cell>
        <mat-cell *matCellDef="let row"><button class="btn btn-primary" (click)="comprovarIncidenciesMaquina(row.codi)">Comprovar</button></mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
    </mat-table>
  </div>

  <div class="col-sm-5" style="height: 70vh;width:100%;">
    <agm-map (mapReady)="this.mapReady($event)"  [latitude]="currentLocation.latitude" [longitude]="currentLocation.longitude"  [zoom]="this.zoomMapa" [styles]="this.styleMap"></agm-map>
  </div>
</div>
