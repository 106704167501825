import { Injectable } from '@angular/core';
import { AnulacionZonaAzulAutoFilter } from '../models/anulacion-zona-azul-auto-filter';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Observable } from 'rxjs';
import { AnulacionZonaAzulAutoFilterService } from './anulacion-zona-azul-auto-filter.service';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { Anulacions } from '../models/anulacions.model';

@Injectable({
  providedIn: 'root'
})
export class AnulacionZonaAzulAutoService {

  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) { }

  processar<T>(anulacionsInfo: Anulacions): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlAnulacionZonaAzulAutoProcessar'], anulacionsInfo);
    }
  }
}
