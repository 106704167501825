import { NotificationService } from 'path-shared/services/notification/notification.service';
import { ExpedienteFilterService } from './../../services/expediente-filter.service';
import { DashInputFilesStatusComponent } from './../../../dash/components/dash-input-files-status/dash-input-files-status.component';
import { CustomDataSource } from './../../../../shared/services/datasource/custom.datasource';
import { Component, OnInit, ViewChild, EventEmitter, AfterViewInit, OnChanges, SimpleChanges, Output } from '@angular/core';
import { MatSort, MatPaginator, MatPaginatorBase } from '@angular/material';
import { Router } from '@angular/router';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TramitadorHistoric } from '../../models/tramitador-historic.model';
import { TramitadorHistoricFilter } from '../../models/tramitador-historic.filter';
import { TramitadorHistoricService } from 'path-denuncies-services/tramitador-historic.service';
import { TramitadorHistoricFilterService } from 'path-denuncies-services/tramitador-historic-filter.service';
import { Expediente } from '../../models/expediente.model';
import { ExpedienteFilter } from '../../models/expediente.filter';
import { ExpedienteService } from 'path-denuncies-services/expediente.service';
import { api } from 'path-shared/configuration/urls';
import { config } from 'path-shared/configuration/config';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { FileDownloader } from 'path-shared/services/http/fileDownloader';
import { FilesService } from 'path-shared/services/files/files.service';
import { LoaderService } from 'path-shared/components/loader/loader.service';
import { LoaderInterceptorService } from 'path-shared/components/loader/loader-interceptor.service';

@Component({
  selector: 'app-tramitador-historic',
  templateUrl: './tramitador-historic.component.html',
  styleUrls: ['./tramitador-historic.component.scss']
})
export class TramitadorHistoricComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild ('paginator') paginator: MatPaginator;
  @ViewChild ('paginatorDetail') paginatorDetail: MatPaginator;
  @ViewChild ('table') sort: MatSort;
  @ViewChild ('tableDetail') sortDetail: MatSort;

  // Atributos lista principal
  dataSource: CustomDataSource<TramitadorHistoric, TramitadorHistoricFilter>;
  filter: TramitadorHistoricFilter = new TramitadorHistoricFilter('Fecha', 'desc');
  updatedFilterData: TramitadorHistoricFilter = new TramitadorHistoricFilter('', '');
  filterPage: TramitadorHistoricFilter;
  displayedItems = [];
  highlightedRows = [];
  selectedItem = -1;

  // Atributos lista detalle
  detailedDataSource: CustomDataSource<Expediente, ExpedienteFilter>;
  detallFilter: ExpedienteFilter = new ExpedienteFilter('Expedient', 'asc');
  displayedDetailItems = [];


  // Emits
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();

  displayedColumns = ['Operacion', 'Fecha', 'FechaInicio', 'FechaFin', 'Usuario', 'NumeroExpedientes', 'NumeroFotos', 'Descarregar', 'Botons', 'UsuarioAnulador', 'FechaAnulacion' ];
  displayedColumnsDetail = ['Expediente', 'NumeroDenuncia', 'Fecha', 'Hora', 'Importe', 'Matricula', 'Resultado'];

  constructor(
    private i18n: I18n,
    private toastr: ToastrService,
    private tramitadorHistoricService: TramitadorHistoricService,
    private expedienteService: ExpedienteService,
    private expedienteFilterService: ExpedienteFilterService,
    private router: Router,
    private filterService: FilterService,
    private not: NotificationService,
    public auth: AuthorizationService,
    private confirmationDialogService: ConfirmationDialogService,
    private loaderService: LoaderInterceptorService,
    public filesService: FilesService
  ) {
  }

// Region COMPONENT LIFECYCLE

  ngOnInit() {
    this.sort.disableClear = true;
    this.sortDetail.disableClear = true;
    this.not.titleChange(this.i18n('Tramitador Històric'));
    this.inicializarPrincipal();
    this.inicializarDetalle();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });

    this.sortDetail.sortChange.subscribe(() => {
      this.paginatorDetail.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });

    merge(this.sort.sortChange, this.paginator.page).pipe(tap(() => this.loadTramitadorHistoricPage()))
      .subscribe();

      merge(this.sortDetail.sortChange, this.paginatorDetail.page).pipe(tap(() => this.loadDetallPage(this.selectedItem)))
      .subscribe();

    this.loadTramitadorHistoricPage();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      this.loadTramitadorHistoricPage();
    }
  }

// Endregion

// Region INICIALIZACION

  inicializarPrincipal() {
    this.filter.paginator.init(this.sort, this.paginator);
    this.filterService.loadFilter('tramitador-historic-filter', this.filter);
    this.sort.active = this.filter.paginator.sortField;

    this.dataSource = CustomDataSource.create<TramitadorHistoric, TramitadorHistoricFilter>(this.paginator, new TramitadorHistoricFilterService(), 'urlTramitadorHistoric', 'urlTramitadorHistoricCount');
    this.dataSource.subject.subscribe((data) => {
      data.forEach(item => {
        this.displayedItems[item.Som_Id] = {
          id: item.Som_Id
        };
      });
    });
    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.loadTramitadorHistoricPage();
        this.filterService.saveFilter('tramitador-historic-filter', this.filter);
      }
    });
  }

  inicializarDetalle() {
    this.detailedDataSource = new CustomDataSource(this.paginatorDetail, new ExpedienteFilterService(), '', '');
    this.detallFilter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.loadDetallPage(this.selectedItem);
        this.filterService.saveFilter('tramitador-historic-detail-filter', this.detallFilter);
      }
    });
  }

// Endregion

// Region EVENTOS

loadTramitadorHistoricPage() {
  this.filter.paginator.set(this.sort, this.paginator);
  this.dataSource.loadFilter(this.filter);
}

goTramitador(): void {
  this.router.navigate(['denuncies/tramitador']);
}

loadDetallPage(som_id: number) {
  if (this.selectedItem !== som_id) {
    this.selectedItem = som_id;
    this.paginatorDetail.pageIndex = 0;
  }
  const urlData = config.urls['apiUrl'] + api.endpoints['urlTramitadorHistoricDetall'].replace('{id}', som_id.toString());
  const urlCount = config.urls['apiUrl'] + api.endpoints['urlTramitadorHistoricDetallCount'].replace('{id}', som_id.toString());
  this.detailedDataSource.setUrlData(urlData);
  this.detailedDataSource.setUrlCount(urlCount);
  this.detallFilter.paginator.set(this.sortDetail, this.paginatorDetail);
  this.detailedDataSource.loadFilter(this.detallFilter);
}

// Endregion

//#region VALIDACIONES

anularDisabled(row: TramitadorHistoric) {
  return row.Anulat;
}

anularTramit(row: TramitadorHistoric) {
  this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Està segur que vol anul·lar la tramitació de ') + row.NumeroExpedientes + this.i18n(' expedients?'))
  .then((confirmed) => {
    if (confirmed) {
      this.tramitadorHistoricService.anularTramitadorHistoric(row.Som_Id).subscribe(result => {
        if (result) {
          this.toastr.success(this.i18n('Tramitació de les denuncies anul·lat.'));
          this.loadTramitadorHistoricPage();
        } else {
          this.toastr.error(this.i18n('No s\'ha pogut anul·lar la tramitació de les denuncies!'));
        }
      });
    }
  });
}

descarregarTramit(row: TramitadorHistoric) {
  event.stopPropagation();
  this.filesService.getFile(row.RutaFichero).subscribe(file => {
    // const name: number = Date.now();

    const name = row.RutaFichero.split('\\').pop();
    const contentType = file.headers.get('Content-Type');
    if (contentType !== null && contentType !== undefined) {

      FileDownloader.downLoadFile([file.body],  contentType + ';charset=utf-8', name);

    } else {
      this.toastr.error(this.i18n('No s\'ha trobat el fitxer'));
    }
  });

}

//#endregion

}
