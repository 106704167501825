import { DenunciaDetailModel } from './../../../../models/denuncia-detail.model';
import { Component, OnInit, Input } from '@angular/core';
import { XDENUNCIES } from 'path-models/XDENUNCIES';

@Component({
  selector: 'app-recaptacio',
  templateUrl: './recaptacio.component.html'
})
export class RecaptacioComponent implements OnInit {

  @Input() data: DenunciaDetailModel;
  @Input() isReadOnly: boolean;

  constructor() { }

  ngOnInit() {
  }

}
