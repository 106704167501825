import { TemporadaFilter } from './../../models/temporada.filter';
import { Component, OnInit, EventEmitter, Output, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-temporadas-filter',
  templateUrl: './temporadas-filter.component.html',
  styleUrls: ['./temporadas-filter.component.scss']
})
export class TemporadasFilterComponent implements OnInit {
  subscription: Subscription;
  filter: TemporadaFilter;

  @Output() notify: EventEmitter<TemporadaFilter> = new EventEmitter<TemporadaFilter>();

  constructor( public thisDialogRef: MatDialogRef<TemporadasFilterComponent>,
    private i18n: I18n,
    @Inject(MAT_DIALOG_DATA) public data) {
    this.filter = data.filter;
  }

  ngOnInit() {
    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.apply();
      }
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated();
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }

}
