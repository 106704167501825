<app-dialog-header *ngIf="this.dataDialog.isDialog" label="Automatización" i18n-label></app-dialog-header>

<app-toolbar>
  <app-tool-button text="Editar" i18n-text (click)="this.isReadOnly = !this.isReadOnly" color="has-color-green" icon="fa-edit" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Automatitzacio) || !this.isReadOnly"></app-tool-button>
  <app-tool-button text="Guardar" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Automatitzacio) || this.isReadOnly"></app-tool-button>
  <app-tool-button text="Cancel·lar" i18n-text (click)="this.isReadOnly = !this.isReadOnly" color="has-color-red" icon="fa-times"  [disabled]="this.isReadOnly || !this.auth.UserCanWrite(this.auth.Pages.Denuncies)"></app-tool-button>
  <app-tool-button-permissions [permission]="this.auth.Pages.Automatitzacio"></app-tool-button-permissions>
</app-toolbar>

<mat-accordion multi *ngIf="automatitzacions!==undefined">  
  <mat-expansion-panel *ngFor="let automatitzacio of automatitzacions">
    <mat-expansion-panel-header>
      <mat-panel-title i18n>
        {{automatitzacio.label}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="row">
      <mat-form-field class="col-1" style="margin-left: 4vh">
        <input type="time"  matInput i18n-placeholder placeholder="Hora de ejecución" [value]="automatitzacio.horaEjecucion" (input)="automatitzacio.horaEjecucion=$event.target.value" required [disabled]="isReadOnly">
      </mat-form-field>
      <app-form-text-edit id='automatitzacio-frecuencia' class="col-1" label="Frecuencia de horas" i18n-label type='number' [(value)]="automatitzacio.frecuenciaHoras" [isReadOnly]="isReadOnly"></app-form-text-edit>
      <app-form-text-edit *ngIf="automatitzacio.label!=='Despertar SOM_PROPOSTES'" id='automatitzacio-desde' class="col-1" label="Desde (días atras)" i18n-label type='number' [(value)]="automatitzacio.parametres.diesEnrere" required [isReadOnly]="isReadOnly"></app-form-text-edit>
      <app-form-text-edit *ngIf="automatitzacio.label!=='Despertar SOM_PROPOSTES'" id='automatitzacio-hasta' class="col-1" label="Hasta (número de dias)" i18n-label type='number' [(value)]="automatitzacio.parametres.numeroDies" required [isReadOnly]="isReadOnly"></app-form-text-edit>
      <app-form-text-edit *ngIf="automatitzacio.label==='Despertar SOM_PROPOSTES'" id='automatitzacio-url' class="col-5" label="Url" i18n-label [(value)]="automatitzacio.parametres.url" required [isReadOnly]="isReadOnly"></app-form-text-edit>
      <app-form-text-edit *ngIf="automatitzacio.label!=='Despertar SOM_PROPOSTES'"id='automatitzacio-emails' class="col-5" label="Emails (separados por comas)" i18n-label [(value)]="automatitzacio.emails" [isReadOnly]="isReadOnly"></app-form-text-edit>

      <app-form-check-edit id='automatitzacio-activo' label="Activo" [(value)]="automatitzacio.activo" [disabled]="isReadOnly"></app-form-check-edit>
    </div>
  </mat-expansion-panel>

</mat-accordion>
