<app-toolbar>
  <app-tool-button text="Actualitzar"  i18n-text (click)="updateAcusos();" color="has-color-blue" icon="fa-sync"></app-tool-button>
  <app-tool-button text="Filtre" i18n-text (click)="onFilterClicked();" color="has-color-orange" icon="fa-filter"></app-tool-button>
  <app-tool-button text="Permisos" i18n-text (click)="onPermissionClick();" color="has-color-orange" icon="fa-lock"></app-tool-button>
  <app-tool-dropdown-button text="Importar acusos de rebut" i18n-text (click1)="descarregaAcusosClick();" (click2)="carregaAcusosClick();" 
      numeroSubmenus="2" i18n-label1 label1="Descarregar plantilla" i18n-label2 label2="Carregar plantilla" color="has-color-orange" icon="fa-tasks">
  </app-tool-dropdown-button>
  <app-tool-button text="Informar" i18n-text (click)="this.informarAcusos(true)" color="has-color-green" icon="fa-save"></app-tool-button>
  <app-tool-button text="Rebutjar" i18n-text (click)="this.informarAcusos(false)" color="has-color-red" icon="fa-save"></app-tool-button>
  
  <input type="file" class="form-control-file" onclick="this.value = null" (change)="this.onFileChange($event)" #uploadFileInput hidden style="width: 0px"/>
</app-toolbar>

<app-dialog-header label="Acuses de recibo" [isCloseButton]="false" i18n-label></app-dialog-header>

<app-acusos-table [updatedFilterData]="filterData"></app-acusos-table>
