import { FilterData } from './../../../shared/models/filter-data';
import {SimpleBoolean} from '../../../shared/models/simpleBoolean';
import {CustomListFill} from '../../../shared/models/customlistfill';
import { SortDirection } from '@angular/material';


export class DenunciaFilter extends FilterData {

  somId: string;
    // Support objects
    simpleBooleanJuridica: SimpleBoolean;
    simpleBooleanSCT: SimpleBoolean;
    simpleBooleanJPT: SimpleBoolean;
    simpleBooleanJudicial: SimpleBoolean;
    simpleBooleanCerrado: SimpleBoolean;
    articuloTextListHelper: CustomListFill;
    origenTextListHelper: CustomListFill;


    // Basic info
    expediente: string;
    expExtern: string;
    matricula: string;
    agente: string;
    dni: string;
    dniSearchType: String;

    // Section 1
    numeroDenuncia: string;
    codigoAnulacion: string;
    calle: string;
    nombreConductor: string;
    nombreSearchType: string;
    esJuridica: Boolean;

    // Section 2
    fechaCobroInicio: Date;
    fechaCobroFin: Date;
    fechaInfraccionInicio: Date;
    fechaInfraccionFin: Date;

    // Section 3
    esJudicial: string;
    esJPT: string;
    esSCT: string;
    pda: string;
    aparato: string;
    c60: string;
    JPT: string;
    SCT: string;
    alegacion: string;
    resolucion: string;
    reposicion: string;

    // Section 4
    ley: string;
    articulo: string;

    // Section 5
    fechaEnvioInicio: Date;
    fechaEnvioFin: Date;
    esConfirmadas: Boolean;
    esNoConfirmadas: Boolean;

    // Section 6
    fechaAnulacionInicio: Date;
    fechaAnulacionFin: Date;
    tipusAnul: string;
    usuariAnul: string;
    motiuAnul: string;
    origen: string;

    // Section 7
    fechaTraspasoInicio: Date;
    fechaTraspasoFin: Date;
    esCerradoOficina: Boolean;
    fechaCierreInicio: Date;
    fechaCierreFin: Date;

    // Section 8
    registroEntAlegacion: String;
    registroEntResolucion: String;
    registroEntReposicion: String;

    // Section 9
    fechaCaducidadInicio: Date;
    fechaCaducidadFin: Date;
    fechaPrescripcionInicio: Date;
    fechaPrescripcionFin; Date;
    regEntAlegacio: string;

    // Section 10
    zonaBlava: string;
    zonaAzul: Boolean;
    policia: Boolean;
    convivencia: Boolean;

    tancat: string;

    notificadaConductor: string;
    fechaNotiConInicio: Date;
    fechaNotiConFin: Date;

    notificadaPropietario: string;
    fechaNotiPropInicio: Date;
    fechaNotiPropFin: Date;

    puntos: string;

    estatGrua: string;

    fechaPagadaInicio: Date;
    fechaPagadaFin: Date;
    // filter table
    filterTable: string;
    constructor(sortField: string, sortDir: SortDirection) {
      // activamos notificacion evento clear para resetear el filtro con los datos correctos
      super(sortField, sortDir);
      this.notifyClear = true;
      this.somId = '';
      this.expediente = '';
      this.expExtern = '';
      this.regEntAlegacio = '';
      this.alegacion = '';
      this.resolucion = '';
      this.reposicion = '';
      this.matricula = '';
      this.agente = '';
      this.dni = '';
      this.dniSearchType = '';
      this.numeroDenuncia = '';
      this.codigoAnulacion = '';
      this.calle = '';
      this.nombreConductor = '';
      this.nombreSearchType = '';
      this.esJuridica = null;
      this.fechaCobroInicio = null;
      this.fechaCobroFin = null;
      this.fechaInfraccionInicio = null;
      this.fechaInfraccionFin = null;
      this.esJudicial = null;
      this.esJPT = null;
      this.esSCT = null;
      this.pda = '';
      this.c60 = '';
      this.JPT = '';
      this.SCT = '';
      // this.ley = '';
      this.articulo = '';
      this.fechaEnvioInicio = null;
      this.fechaEnvioFin = null;
      this.esConfirmadas = null;
      this.esNoConfirmadas = null;
      this.fechaAnulacionInicio = null;
      this.fechaAnulacionFin = null;
      this.tipusAnul = null;
      this.usuariAnul = null;
      this.motiuAnul = null;
      this.origen = '';
      this.esCerradoOficina = null;
      this.fechaCierreInicio = null;
      this.fechaCierreFin = null;
      this.registroEntAlegacion = '';
      this.registroEntResolucion = '';
      this.registroEntReposicion = '';
      this.fechaCaducidadInicio = null;
      this.fechaCaducidadFin = null;
      this.fechaPrescripcionInicio = null;
      this.fechaPrescripcionFin = null;
      this.zonaBlava = '';
      this.zonaAzul = null;
      this.policia = null;
      this.convivencia = null;
      this.tancat = '';
      this.notificadaConductor = '';
      this.notificadaPropietario = '';
      this.fechaNotiConFin = null;
      this.fechaNotiConInicio = null;
      this.fechaNotiPropFin = null;
      this.fechaNotiPropInicio = null;
      this.puntos = '';
      this.filterTable = '';
      this.aparato = '';
      this.estatGrua ='';
      this.fechaPagadaInicio = null;
      this.fechaPagadaFin = null;

      this.labels['simpleBooleanJuridica'] = this.i18n('Jurídica');

      this.labels['simpleBooleanSCT'] = this.i18n('SCT');
      this.labels['simpleBooleanJPT'] = this.i18n('JPT');
      this.labels['simpleBooleanJudicial'] = this.i18n('Judicial');
      this.labels['simpleBooleanCerrado'] = this.i18n('Tancat');
      this.labels['leyTextListHelper'] = this.i18n('Llei');
      this.labels['articuloTextListHelper'] = this.i18n('Article');
      this.labels['origenTextListHelper'] = this.i18n('Origen');

      this.labels['expediente'] = this.i18n('Expedient');
      this.labels['expExtern'] = this.i18n('Exp. Extern');
      this.labels['matricula'] = this.i18n('Matrícula');
      this.labels['agente'] = this.i18n('Agent');
      this.labels['dni'] = this.i18n('DNI');
      this.labels['dniSearchType'] = this.i18n('DNI Tipus');

      this.labels['numeroDenuncia'] = this.i18n('Número Denuncia');
      this.labels['codigoAnulacion'] = this.i18n('Codi Anul·lació');
      this.labels['calle'] = this.i18n('Carrer');
      this.labels['nombreConductor'] = this.i18n('Conductor');
      this.labels['nombreSearchType'] = this.i18n('Tipus Cerca');
      this.labels['esJuridica'] = this.i18n('És Jurídica');

      this.labels['fechaCobroInicio'] = this.i18n('Fecha Cobro Inicial');
      this.labels['fechaCobroFin'] = this.i18n('Fecha Cobro Final');
      this.labels['fechaInfraccionInicio'] = this.i18n('Data Infracció Inicial');
      this.labels['fechaInfraccionFin'] = this.i18n('Data Infracció Final');

      this.labels['esJudicial'] = this.i18n('És Judicial');
      this.labels['esJPT'] = this.i18n('JPT');
      this.labels['esSCT'] = this.i18n('SCT');
      this.labels['pda'] = this.i18n('PDA');
      this.labels['c60'] = this.i18n('C60');
      this.labels['JPT'] = this.i18n('JPT');
      this.labels['SCT'] = this.i18n('SCT');

      this.labels['ley'] = this.i18n('Llei');
      this.labels['articulo'] = this.i18n('Article');

      this.labels['fechaEnvioInicio'] = this.i18n('Data Enviament Inicial');
      this.labels['fechaEnvioFin'] = this.i18n('Data Enviament Final');
      this.labels['esConfirmadas'] = this.i18n('Confirmada');
      this.labels['esNoConfirmadas'] = this.i18n('No confirmada');

      this.labels['fechaAnulacionInicio'] = this.i18n('Data Anul·lació Inicial');
      this.labels['fechaAnulacionFin'] = this.i18n('Data Anul·lació Final');
      this.labels['tipusAnul'] = this.i18n('Tipus anul·lació');
      this.labels['usuariAnul'] = this.i18n('Usuari anul·lació');
      this.labels['motiuAnul'] = this.i18n('Motiu anul·lació');
      this.labels['origen'] = this.i18n('Origen');

      this.labels['fechaCierreInicio'] = this.i18n('Data Tancament Inicial');
      this.labels['fechaCierreFin'] = this.i18n('Data Tancament Final');
      this.labels['esCerradoOficina'] = this.i18n('Tancat oficina');

      this.labels['registroEntAlegacion'] = this.i18n('Registre Entrada Alegació');
      this.labels['registroEntResolucion'] = this.i18n('Registre Entrada Resolució');
      this.labels['registroEntReposicion'] = this.i18n('Registre Entrada Reposició');

      this.labels['fechaCaducidadInicio'] = this.i18n('Data Caducitat Inicial');
      this.labels['fechaCaducidadFin'] = this.i18n('Data Caducitat Final');
      this.labels['fechaPrescripcionInicio'] = this.i18n('Data Prescripció Inicial');
      this.labels['fechaPrescripcionFin'] = this.i18n('Data Prescripció Final');
      this.labels['zonaBlava'] = this.i18n('Zona Blava');
      this.labels['zonaAzul'] = this.i18n('Zona Blava');
      this.labels['policia'] = this.i18n('Policia');
      this.labels['convivencia'] = this.i18n('Convivencia');
      this.labels['tancat'] = this.i18n('Tancat');
      this.labels['notificadaConductor'] = this.i18n('Notificada conductor');
      this.labels['notificadaPropietario'] = this.i18n('Notificada propietario');
      this.labels['fechaNotiConFin'] = this.i18n('Fecha notificación conductor final');
      this.labels['fechaNotiConInicio'] = this.i18n('Fecha notificación conductor inicial');
      this.labels['fechaNotiPropFin'] = this.i18n('Fecha notificación propietario final');
      this.labels['fechaNotiPropInicio'] = this.i18n('Fecha notificación propietario inicial');
      this.labels['puntos'] = this.i18n('Punts');
      this.labels['aparato'] = this.i18n('Aparato');
      this.labels['estatGrua'] = this.i18n('Grua');
      this.labels['filterTable'] = this.i18n('Filtro rápido');
      this.labels['fechaPagadaInicio'] = this.i18n('Data Pagada Inicial');
      this.labels['fechaPagadaFin'] = this.i18n('Data Pagada Final');

      this.formatOutput['fechaInfraccionInicio'] = 'shortDate';
      this.formatOutput['fechaInfraccionFin'] = 'shortDate';
    }
}
