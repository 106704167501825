import { Component, OnInit, Inject, EventEmitter, Output, ViewChild, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { TipoOperacionsModel } from '../../../../models/tipo-operacions.model';
import { OperacionesModel } from '../../../../models/operaciones.model';
import { DenunciesService } from '../../../../services/denuncies.service';
import { MotiuAnulacioModel } from '../../../../models/motiu-anulacio.model';
import { OperacionGenericaDto } from '../../../../models/operacionGenericaDto.model';
import { OperacionAnularExpedienteDto } from '../../../../models/operacionAnularExpedienteDto.model';
import { DenunciaDetailModel } from '../../../../models/denuncia-detail.model';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { DenunciaCobrarComponent } from '../denuncia-cobrar/denuncia-cobrar.component';
import { ExpedientsModel } from 'app/sections/expedients/models/expedients.model';
import { DenunciaExpedientModel } from 'app/sections/denuncies/models/denuncia-expedient.model';
import { DenunciesCobrarService } from 'path-denuncies-services/denuncies-cobrar.service';
import { CartaPagoFechasComponent } from './components/carta-pago-fechas/carta-pago-fechas.component';
import { FormAlegacionesComponent } from './components/form-alegaciones/form-alegaciones.component';
import { IntroAlegacionesComponent } from './components/intro-alegaciones/intro-alegaciones.component';
import { SegundasAlegacionesComponent } from './components/segundas-alegaciones/segundas-alegaciones.component';

@Component({
  selector: 'app-nova-operacio',
  templateUrl: './nova-operacio.component.html'
})
export class NovaOperacioComponent implements OnInit {

  @Output() newOperation: EventEmitter<OperacionesModel> = new EventEmitter<OperacionesModel>();

  currentRow: number;
  displayedColumns = ['Codi', 'Desc', 'btn'];
  highlightedRows = [];
  cod = '';
  motiv = '';
  desc = '';
  obs = '';
  exp = '';
  xprocediment: ExpedientsModel;
  operacio: OperacionesModel;
  motivosAnulacion: MotiuAnulacioModel[];
  tipoOps: TipoOperacionsModel[];
  denunciesDetail: DenunciaDetailModel[];
  showMotivosAnulacion: boolean;
  showDescripcion: boolean;
  selectedMotivoAnulacion: string;
  fetching = true;
  isMassiva: boolean;
  @ViewChild('inforInfract') inforInfract;
  @ViewChild('inforTitular') inforTitular;
  @ViewChild('cambioMatricula') cambioMatricula;
  @ViewChild('alegacion') alegacion: FormAlegacionesComponent;
  @ViewChild('introAlegacion') introAlegacion: IntroAlegacionesComponent;
  @ViewChild('segundaAlegacion') segundaAlegacion: SegundasAlegacionesComponent;

  denunciesCobrarRef: MatDialogRef<DenunciaCobrarComponent>;

  constructor(
    public thisDialogRef: MatDialogRef<NovaOperacioComponent>,
    @Inject(MAT_DIALOG_DATA) public tOps: any,
    private denunciesService: DenunciesService,
    private denunciesCobrarService: DenunciesCobrarService,
    private toastr: ToastrService,
    private i18n: I18n,
    private confirmationDialogService: ConfirmationDialogService,
    private dialog: MatDialog) 
  { 
    this.isMassiva = tOps.isMassiva;
    if (!this.isMassiva) {
      this.denunciesService.getExpedient(tOps.data.expedient)
      .subscribe((res: ExpedientsModel) => {
        this.xprocediment = res
        this.fetching = false;
      });
    }
    else
      this.fetching = false;
    
    this.tipoOps = tOps.operacions.TipoOperaciones;
    this.tipoOps.map((obj) => {
      obj.BUTTON = 'button';
      return obj;
    })
    this.motivosAnulacion = tOps.operacions.MotivoAnulacion;
    if (!Array.isArray(tOps.data)) {
      this.denunciesDetail = [tOps.data];
    } else {
      this.denunciesDetail = tOps.data;
    }
    this.showMotivosAnulacion = false;
    this.showDescripcion = true;
  }

  ngOnInit() {
  }

  onRowClicked(row: TipoOperacionsModel, index: number) {
    this.currentRow = index;
    this.cod = row.COD_OPE;
    this.desc = row.DESC_OPE;
    this.obs = '';

    this.showMotivosAnulacion = row.COD_OPE === 'ANUL_EXP' || row.COD_OPE === 'ANUL_ZB' ? true : false;
    this.showDescripcion = !this.showMotivosAnulacion;
  }

  isButtonDisabled(row: TipoOperacionsModel) {
    if (!this.isMassiva) {
      if (row.COD_OPE === 'PRIMERAS_ALEGACIONES') {
        if (this.tOps.operaciones.data.some(x => x.COD_OPE == 'PRIMERAS_ALEGACIONES') || 
            (this.xprocediment.acusPropietari == null && this.xprocediment.acusConductor == null && this.xprocediment.edicteNotificacio == null && this.denunciesDetail[0].notificadoEnMano == null) ||
            (this.xprocediment.edicteResolucio != null || this.xprocediment.dataNotificacioResolucio != null)) return true
      }
      if (row.COD_OPE === 'SEGUNDAS_ALEGACIONES') {
        if ((this.xprocediment.acusProposta == null && this.xprocediment.edicteProposta == null)  ||
            (this.xprocediment.edicteResolucio != null || this.xprocediment.dataNotificacioResolucio != null)) return true
      }
    }
    return false
  }

  executeOperation() {
    let message = this.i18n('S\'executarà l\'operació:') + ' ' + this.desc + this.i18n('. Continuar?');
    if (this.denunciesDetail.length >= 1) {
      message = this.i18n('S\'executarà l\'operació:') + ' ' + this.desc + ' a ' + this.denunciesDetail.length + ' ' + this.i18n('Expedients.\nContinuar?');
    }
    this.confirmationDialogService.confirm(this.i18n('Avís'), message)
      .then((confirmed) => {
        if (confirmed) {
          if (this.cod === 'ANUL_EXP' || this.cod === 'ANUL_ZB') {
            if (this.selectedMotivoAnulacion === undefined || this.selectedMotivoAnulacion === '0') {
              this.toastr.error(this.i18n('Has de seleccionar un motiu d\'anul·lació'));
            } else {
              this.OperacionAnularExpediente();
            }
          } else if (this.cod === 'ANULAR_TRAMITADOR') {
            this.OperacionAnularTramitacion();
          } else if (this.cod === 'ALEGACIONES') {
            this.OperationAlegacion();
          } else if (this.cod === 'RECURSOS') {
            this.OperacionRecurso();
          } else if (this.cod === 'JUDICIAL') {
            this.OperacionJudicial();
          } else if (this.cod === 'ANULAR_ENVIO') {
            this.OperacionGenerica();
          } else if (this.cod === 'EXP_11_1_A') {
            this.OperacionGenerica();
          } else if (this.cod === 'TANC_OFI') {
            this.OperacionGenerica();
          } else if (this.cod === 'COBRA_EXP') {
            // Si només tenim una denuncia, engeguem tot el fluxe de validacions i pantalla modal
            // Si tenim una llista de denuncies, cobrarà el 100%
            if (this.denunciesDetail.length === 1) {
              this.CobrarDenuncia(this.denunciesDetail[0]);
            } else {
              this.OperacionCobrar();
            }
          } else if (this.cod === 'INFOR_INFRACTOR') {
            this.OperacionInforInfractor();
          } else if (this.cod === 'INFOR_TITULAR') {
            this.OperacionInforTitular();
          } else if (this.cod === 'CAMBIO_MATRICULA') {
            this.OperacionCambioMatricula();
          } else if (this.cod === 'NUEVO_CONDUCTOR') {
          } else if (this.cod === 'CARTA_PAGO') {
            this.OperacionCartaPago();
          } else if (this.cod === 'SUBS_DEF') {
            this.OperacionGenerica();
          } else if (this.cod === 'SANC_CONV') {

          } else if (this.cod === 'EXP_MODIFICADO') {
            this.OperacionGenerica();
          }
          else if (this.cod === 'PRIMERAS_ALEGACIONES') {
            this.OperacionPrimerasAlegaciones(true);
          }
          else if (this.cod === 'SEGUNDAS_ALEGACIONES') {
            this.OperacionSegundasAlegaciones(false);
          }
        }
      });

  }

  OperacionCobrar() {
    // this.confirmationDialogService.confirm(this.i18n('Avis'), + this.i18n('Es cobrarÀ') + this.denunciesDetail.length.toString() + this.i18n(' expedients. Desitja continuar?'))
    // .then((confirmed) => {
    //     if (confirmed) {
    this.sendOperationCobrar();
    //     }
    //   })
    // .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));

  }

  sendOperationCobrar() {
    const dto = new OperacionGenericaDto;
    dto.Observacion = this.obs;
    this.denunciesService.createOpearcioMasiva(this.denunciesDetail.map(x => x.secure_id_denuncia), this.cod, dto).subscribe((result: OperacionesModel) => {
      this.newOperation.emit(result);
      this.denunciesDetail[0].fechaCobro = new Date();
      this.toastr.success(this.i18n('Operacio realitzada correctament'));
      this.thisDialogRef.close(true);
    });
  }

  // Operacion algeacion

  OperationAlegacion() {
    if (!this.alegacion.correcte()) {
      return;
    }

    if (this.denunciesDetail.filter(x => x.dataPlec != null && x.dataPlecProposta == null).length > 0) {
      this.confirmationDialogService.confirm(this.i18n('Avis'), this.i18n('S\'introduiràn unes segones al·legacions a ')
        + this.denunciesDetail.filter(x => x.dataPlec != null).length + ' ' + this.i18n('expedients. Desitja continuar?'))
        .then((confirmed) => {
          if (confirmed) {
            this.sendOperationAlegacion();
          }
        })
        .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    } else if (this.denunciesDetail.filter(x => x.dataPlec != null && x.dataPlecProposta != null).length > 0) {
      this.confirmationDialogService.confirm(this.i18n('Avis'), this.i18n('Se ha encontrado') + ' ' +
        + this.denunciesDetail.filter(x => x.dataPlecProposta != null).length + ' ' +
        this.i18n('expediente/s con el límite de alegaciones introducidas, los cuales no serán modificados. Desea continuar?'))
        .then((confirmed) => {
          if (confirmed) {
            this.sendOperationAlegacion();
          }
        })
        .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    } else {
      this.sendOperationAlegacion();
    }
  }

  sendOperationAlegacion() {
    const dto = this.alegacion.dto;

    this.denunciesService.createOpearcioMasiva(this.denunciesDetail.map(x => x.secure_id_denuncia), this.cod, dto).subscribe((result: OperacionesModel) => {
      this.newOperation.emit(result);
      this.denunciesDetail[0].dataPlec = new Date();
      this.toastr.success(this.i18n('Operacio realitzada correctament'));
      this.thisDialogRef.close(true);
    });
  }

  // Operacion anular tramitación

  OperacionAnularTramitacion() {
    if (this.denunciesDetail.filter(x => x.traspasado === true).length > 0) {
      this.confirmationDialogService.confirm(this.i18n('Avis'), this.i18n('Se anulará la tramitación a ') + this.denunciesDetail.filter(x => x.traspasado === true).length + this.i18n(' expedientes. ¿Desea continuar?'))
        .then((confirmed) => {
          if (confirmed) {
            this.sendOperationAnularTramitacion();
          }
        })
        .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    } else {
      this.sendOperationAnularTramitacion();
    }
  }

  sendOperationAnularTramitacion() {
    const dto = new OperacionGenericaDto;
    dto.Observacion = this.obs;
    this.denunciesService.createOpearcioMasiva(this.denunciesDetail.map(x => x.secure_id_denuncia), this.cod, dto).subscribe((result: OperacionesModel) => {
      this.toastr.success(this.i18n('Operación realizada correctamente'));
      this.newOperation.emit(result);
      this.thisDialogRef.close(true);
    });
  }

  // Operacion anular expediente

  OperacionAnularExpediente() {
    if (this.denunciesDetail.filter(x => x.traspasado != null && x.traspasado).length > 0) {
      this.confirmationDialogService.confirm(this.i18n('Avís'), this.denunciesDetail.filter(x => x.traspasado != null && x.traspasado).length + this.i18n(' expedients ja han sigut enviats al seu tramitador. Desitja continuar?'))
        .then((confirmed) => {
          if (confirmed) {
            this.sendOperacionAnularExpediente();
          }
        })
        .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    } else {
      this.sendOperacionAnularExpediente();
    }
  }

  sendOperacionAnularExpediente() {
    const dto = new OperacionAnularExpedienteDto;
    dto.Observacion = this.obs;
    dto.Motivo = +this.selectedMotivoAnulacion;
    if (this.cod === 'ANUL_ZB') {
      dto.esZonaAzul = true;
    } else {
      dto.esZonaAzul = false;
    }

    this.denunciesService.createOpearcioMasiva(this.denunciesDetail.map(x => x.secure_id_denuncia), this.cod, dto).subscribe((result: OperacionesModel) => {
      this.newOperation.emit(result);
      this.toastr.success(this.i18n('Operació realitzada correctament'));
      this.thisDialogRef.close(true);
    });

  }

  // Operacion recurso

  OperacionRecurso() {
    if (this.denunciesDetail.filter(x => x.dataPlecResolucio != null).length > 0) {
      this.confirmationDialogService.confirm(this.i18n('Avis'), this.i18n('S\'introduirà un segon recurs a ') + this.denunciesDetail.filter(x => x.dataPlecResolucio != null).length + this.i18n(' expedients. Desitja Continuar?'))
        .then((confirmed) => {
          if (confirmed) {
            this.sendOperacionRecurso();
          }
        })
        .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    } else {
      this.sendOperacionRecurso();
    }
  }

  sendOperacionRecurso() {
    const dto = new OperacionGenericaDto;
    dto.Observacion = this.obs;
    this.denunciesService.createOpearcioMasiva(this.denunciesDetail.map(x => x.secure_id_denuncia), this.cod, dto).subscribe((result: OperacionesModel) => {
      this.newOperation.emit(result);
      this.denunciesDetail[0].dataPlecResolucio = new Date();
      this.toastr.success(this.i18n('Operacio realitzada correctament'));
      this.thisDialogRef.close(true);
    });
  }

  // Operacion judicial

  OperacionJudicial() {
    if (this.denunciesDetail.filter(x => x.judicial === true).length > 0) {
      this.confirmationDialogService.confirm(this.i18n('Avis'), this.denunciesDetail.filter(x => x.judicial === true).length + this.i18n(' expedients ja es troben en estat judicial. Desitja continuar?'))
        .then((confirmed) => {
          if (confirmed) {
            this.sendOperacionJudicial();
          }
        })
        .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    } else {
      this.sendOperacionJudicial();
    }
  }

  sendOperacionJudicial() {
    const dto = new OperacionGenericaDto;
    dto.Observacion = this.obs;
    this.denunciesService.createOpearcioMasiva(this.denunciesDetail.map(x => x.secure_id_denuncia), this.cod, dto).subscribe((result: OperacionesModel) => {
      this.newOperation.emit(result);
      this.denunciesDetail[0].judicial = true;
      this.toastr.success(this.i18n('Operacio realitzada correctament'));
      this.thisDialogRef.close(true);
    });
  }

  OperacionInforInfractor() {
    if (this.inforInfract.correcte()) {
      this.sendOperacionInforInfractor();
    }
  }
  OperacionInforTitular() {

    if (this.inforTitular.correcte()) {
      if (this.denunciesDetail.find(x => x.secure_id_conductor !== null) !== undefined) {
        this.confirmationDialogService.confirm('Confirme', 'Desea eliminar el infractor informado?').then(confirmed => {
          if (confirmed) {
            this.sendOperacionInforTitular(true);
          }
        });

      } else {
        this.sendOperacionInforTitular(false);

      }
    }
  }
  sendOperacionInforTitular(eliminarInfractor: boolean) {
    const dto = this.inforTitular.dto;
    dto.Observacions = this.obs;
    dto.eliminarInfractor = eliminarInfractor;
    this.denunciesService.createOpearcioMasiva(this.denunciesDetail.map(x => x.secure_id_denuncia), this.cod, dto).subscribe((result: OperacionesModel) => {
      this.newOperation.emit(result);
      this.toastr.success(this.i18n('Operacio realitzada correctament'));
      this.thisDialogRef.close(true);
    });
  }
  sendOperacionInforInfractor() {
    const dto = this.inforInfract.dto;
    dto.Observacions = this.obs;
    this.denunciesService.createOpearcioMasiva(this.denunciesDetail.map(x => x.secure_id_denuncia), this.cod, dto).subscribe((result: OperacionesModel) => {
      this.newOperation.emit(result);
      this.toastr.success(this.i18n('Operacio realitzada correctament'));
      this.thisDialogRef.close(true);
    });
  }

  // Operacion generica

  OperacionGenerica() {
    const dto = new OperacionGenericaDto;
    dto.Observacion = this.obs;
    this.denunciesService.createOpearcio(this.denunciesDetail.map(x => x.secure_id_denuncia), this.cod, dto).subscribe((result: OperacionesModel) => {
      this.newOperation.emit(result);
      this.toastr.success(this.i18n('Operacio realitzada correctament'));
      this.thisDialogRef.close(true);
    });
  }

  OperacionGenericaMasiva() {
    const dto = new OperacionGenericaDto;
    dto.Observacion = this.obs;
    this.denunciesService.createOpearcioMasivaBySomIds(this.denunciesDetail.map(x => x.somId), this.cod, dto).subscribe((result: OperacionesModel) => {
      this.newOperation.emit(result);
      this.toastr.success(this.i18n('Operacio realitzada correctament'));
      this.thisDialogRef.close(true);
    });
  }

  OperacionCartaPago() {
    if (this.denunciesDetail.map(x => x.somId).length > 1) {
      this.toastr.warning(this.i18n('No es posible ejecutar esta operación para más de un expediente a la vez.'), this.i18n('Atención'));
      return;
    }
    const dto = new OperacionGenericaDto;
    dto.Observacion = this.obs;
    this.denunciesService.createOpearcioMasivaBySomIds(this.denunciesDetail.map(x => x.somId), this.cod, dto).subscribe((result: OperacionesModel) => {
      if (result.estadoOperacion === 'OPCION_CONTINUAR') {
        this.confirmationDialogService.confirm(this.i18n('Atención'), this.i18n('Ya existe una C60 activa, ¿Seguro que quiere generar una nueva?')).then(res => {
          if (res) {
            dto.estadoOperacion = result.estadoOperacion;
            this.denunciesService.OperacionGenerarC60(this.denunciesDetail.map(x => x.somId), dto).subscribe((res2: OperacionesModel) => {
              if (res2.estadoOperacion === 'OPCION_FECHAS') {
                this.PasoCartaPagoFechas(result, dto);
              } else {
                this.FinishOperation(result);
              }
            });
          }
        });
      } else if (result.estadoOperacion === 'OPCION_FECHAS') {
        this.PasoCartaPagoFechas(result, dto);
      } else {
        this.FinishOperation(result);
      }
    });
  }

  PasoCartaPagoFechas(result, dto) {
    this.dialog.open(CartaPagoFechasComponent, {
      width: '15%',
      closeOnNavigation: false,
      autoFocus: true,
    }).afterClosed().subscribe((res: OperacionesModel) => {
      if (res !== null) {
        dto.estadoOperacion = result.estadoOperacion;
        dto.fecha = res;
        this.denunciesService.OperacionGenerarC60(this.denunciesDetail.map(x => x.somId), dto).subscribe(res4 => {
          this.FinishOperation(result);
        });
      }
    });
  }

  FinishOperation(result) {
    this.newOperation.emit(result);
    this.toastr.success(this.i18n('Operacio realitzada correctament'));
    this.thisDialogRef.close(true);
  }
  // Cobrar Denúncia
  CobrarDenuncia(denunciaDetail: DenunciaDetailModel) {
    this.denunciesService.getExpedient(denunciaDetail.expedient).subscribe((expedientsData: ExpedientsModel) => {
      this.denunciesService.getSomExpedient(denunciaDetail.expedient).subscribe((denunciaExpedient: DenunciaExpedientModel) => {
        this.denunciesCobrarService.cobrarDenuncia(denunciaDetail, expedientsData, denunciaExpedient);
      });
    });
  }

  OperacionCambioMatricula() {
    if (this.cambioMatricula.correcte()) {
      this.sendOperacionCambioMatricula();
    }
  }
  sendOperacionCambioMatricula() {
    const dto = this.cambioMatricula.dto;
    dto.Observacions = this.obs;
    this.denunciesService.createOpearcioMasivaBySomIds(this.denunciesDetail.map(x => x.somId), this.cod, dto).subscribe((result: OperacionesModel) => {
      this.newOperation.emit(result);
      this.toastr.success(this.i18n('Operacio realitzada correctament'));
      this.thisDialogRef.close(true);
    });
  }

  OperacionPrimerasAlegaciones(isPrimeras: boolean) {
    this.introAlegacion.subirFichero(isPrimeras);
    this.introAlegacion.operationResult.subscribe(result => {
      if (result) {
        this.newOperation.emit(result);
        this.toastr.success(this.i18n('Operacio realitzada correctament'));
        this.thisDialogRef.close(true);
      }
      else {
        this.toastr.warning(this.i18n('No se ha podido introducir la alegación'), this.i18n('Atención'));
      }
    });
  }

  OperacionSegundasAlegaciones(isPrimeras: boolean) {
    this.segundaAlegacion.subirFichero(isPrimeras);
    this.segundaAlegacion.operationResult.subscribe(result => {
      if (result) {
        this.newOperation.emit(result);
        this.toastr.success(this.i18n('Operacio realitzada correctament'));
        this.thisDialogRef.close(true);
      }
      else {
        this.toastr.warning(this.i18n('No se ha podido introducir la alegación'), this.i18n('Atención'));
      }
    });
  }
  
}
