import { ComponentDialog } from '../../../../shared/models/component-dialog.model';
import { NotificationService } from '../../../../shared/services/notification/notification.service';
import { AppComponent } from '../../../../app.component';
import { Component, OnInit, ViewChild, Input, Inject, AfterViewInit } from '@angular/core';
import { PermissionDialogComponent } from 'path-shared/components/permission-dialog/permission-dialog.component';
import { MatDialogRef, MatDialog } from '@angular/material';
import {  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { Router } from '@angular/router';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { AutomatitzacioService } from '../services/automatitzacio-service.service';
import { Builder } from 'path-shared/models/builder';
import { Automatitzacio } from '../models/automatitzacio.model';
export class Automatitzacions {
  denuncies = new Automatitzacio();
  anulacions = new Automatitzacio();
}
@Component({
  selector: 'app-automatitzacio',
  templateUrl: './automatitzacio.component.html',
  styleUrls: ['./automatitzacio.component.scss']
})

export class AutomatitzacioComponent implements OnInit, AfterViewInit {
  permissionDialogRef: MatDialogRef<PermissionDialogComponent>;
  activeFilters: string[] = [];
  somIdFoo: number;
  dataDialog: ComponentDialog = Builder.newComponentDialog();
  denunciasPanelOpenState;
  anulacionesPanelOpenState;
  automatitzacions;

  isReadOnly = true;

  constructor(
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private router: Router,
    private automatitzacioService: AutomatitzacioService,
    private i18n: I18n,
    private toastr: ToastrService,
    private not: NotificationService,
    public thisDialogRef: MatDialogRef<AutomatitzacioComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
    ) {
      if (data !== undefined) {
        this.dataDialog = data;
      }
    }

  ngOnInit() {
    this.auth.UserCanSeePage(this.auth.Pages.Automatitzacio);
    this.automatitzacioService.getAutomatitzacions().subscribe(res => {
      this.automatitzacions = res;
    });
  }

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Automatización'));
    }
  }


  save() {
    this.automatitzacioService.setAutomatitzacions(this.automatitzacions).subscribe(() => {
      this.toastr.success(this.i18n('Automatizaciones guardadas correctamente'));
      this.isReadOnly = true;
    });
  }

}
