<app-loader></app-loader>

<app-header *ngIf="this.userLogged()" [pageTitle]="this.title"></app-header>

<div class="app-body">
  <app-navbar *ngIf="this.userLogged()" ></app-navbar>

  <main class="main h-100" [ngClass]="[!this.userLogged() ? 'is-hidden-header' : '']">
    <app-public-header *ngIf="!this.userLogged()"></app-public-header>
    <div class="container-fluid h-100">
      <router-outlet class="h-100"></router-outlet>
    </div>
  </main>
</div>

<!---TEST CLONE TO LOCAL BRANCH -->