import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { PaginationFilter } from 'path-shared/models/pagination.filter';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { Reflection } from 'path-shared/services/reflection/reflection';
import { PersonaFilter } from '../models/persona-filter';

const defaultSortParam = 'descripcio';
const defaultSortDirection = 'asc';

@Injectable()
export class PersonesFilterService extends CustomFilterService {
  public createFilterParams(filter: PersonaFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {

    return new HttpParams()
    .append('id', Reflection.hasValue(filter.id) ? filter.id.toString() : '')
    .append('nomComplert', filter.nomComplet ? filter.nomComplet : '')
    .append('dni_pasp', filter.dni_pasp ? filter.dni_pasp : '')
    .append('nom_pare', filter.nom_pare ? filter.nom_pare : '')
    .append('nom_mare', filter.nom_mare ? filter.nom_mare : '')
    .append('lloc_naixament', filter.lloc_naixament ? filter.lloc_naixament : '')
    .append('provincia', filter.provincia ? filter.provincia : '')
    .append('nacionalitat', filter.nacionalitat ? filter.nacionalitat : '')
    .append('telefon1', filter.telefon1 ? filter.telefon1 : '')
    .append('telefon2', filter.telefon2 ? filter.telefon2 : '')
    .append('id_extern', filter.id_extern ? filter.id_extern : '')
    .append('buscat', this.getTristateValue(filter.buscat))
    .append('antecedents', this.getTristateValue(filter.antecedents))
    .append('juridica', this.getTristateValue(filter.juridica))
    .append('data_naixament', Reflection.empty(filter.data_naixament) ? null : typeof(filter.data_naixament) === 'string' ?
    filter.data_naixament : filter.data_naixament.toDateString())
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }

  getTristateValue(value) {
    if (value === null) {
      return '';
    } else {
      return value.toString();
    }
  }
}
