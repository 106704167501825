import { DenunciesService } from './../../../../denuncies/services/denuncies.service';
import { PropostesAnulacioEditorComponent } from './../propostes-anulacio-editor/propostes-anulacio-editor.component';
import { PropostesAnulacioService } from './../../services/propostes-anulacio.service';
import { PropostaAnulacioDto } from './../../models/proposta-anulacio.model';
import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { PropostesAnulacioFilter } from '../../models/propostes-anulacio-filter';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { PropostesAnulacioFilterService } from '../../services/propostes-anulacio-filter.service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-propostes-anulacio-table',
  templateUrl: './propostes-anulacio-table.component.html',
  styleUrls: ['./propostes-anulacio-table.component.scss']
})
export class PropostesAnulacioTableComponent implements  OnInit, AfterViewInit, OnChanges {
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  filter: PropostesAnulacioFilter = new PropostesAnulacioFilter('fechaPropuesta', 'desc');
  dataSource: CustomDataSource<PropostaAnulacioDto, PropostesAnulacioFilter>;
  highlightedRows = [];
  displayedItems = [];
  matriculaFilter: string;
  displayedColumns = ['exp', 'matricula','fechaPropuesta','fechaDenuncia', 'textoAnulacion', 'fechaAcceptacionDenegacion', 'aceptada', 'usuarioAcceptacionDenegacion', 'comentarioUsuarioBackoffice'];
  @Input() updatedFilterData: PropostesAnulacioFilter;
  constructor(
    private dialog: MatDialog,
    private i18n: I18n,
    private propostesAnulacioService: PropostesAnulacioService,
    private deunciesService:DenunciesService
  ) { }

  ngOnInit() {
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<PropostaAnulacioDto, PropostesAnulacioFilter>(this.paginator, new PropostesAnulacioFilterService(), 
    'urlGetPropostesAnulacioZB', 'urlGetPropostesAnulacioZBCount');
    this.dataSource.subject.subscribe((data) => {
      data.forEach(item => {
        this.displayedItems[item.secure_id] = {
          id: item.secure_id
        };
      });
    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
      }
    });

    this.filter.paginator.init(this.sort, this.paginator);
  }
  matriculaFilterChange(event: any) {
    if (this.matriculaFilter !== undefined && this.matriculaFilter !== null && this.matriculaFilter !== '' ) {
      this.filter.matricula = this.matriculaFilter;
      this.load();
    } else if (  this.matriculaFilter === '' && this.filter.matricula !== this.matriculaFilter) {
      this.filter.matricula = '';
      this.load();
    }
  }
  load() {
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }
  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(tap(() => this.filter.updated()))
    .subscribe();

    this.load();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      this.update();
    }
  }

  update() {
    if (this.dataSource !== undefined) {
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
    }
  }
  onRowClicked(row) {
    if (row !== undefined && row !== null) {
      if(row.fechaAcceptacionDenegacion !== undefined &&  row.fechaAcceptacionDenegacion !== null && row.aceptada ){
        this.deunciesService.getDenunciaImagesNumDen(row.numDenuncia).subscribe( images => {
          row.IMATGES = images;
          const ref = this.dialog.open(PropostesAnulacioEditorComponent, {
            width: '90%',
            height: '90%',
            closeOnNavigation: true,
            autoFocus: true,
            data: {
              'isDialog': true,
              'isReadOnly': false,
              'propuestaAnulacion': row
            }
          });
          ref.afterClosed().subscribe(data => {
            this.load();
          });
        });
      }else{
        this.propostesAnulacioService.getImagesPropostaAnul(row.secure_id).subscribe( images => {
          row.IMATGES = images;
          const ref = this.dialog.open(PropostesAnulacioEditorComponent, {
            width: '90%',
            height: '90%',
            closeOnNavigation: true,
            autoFocus: true,
            data: {
              'isDialog': true,
              'isReadOnly': false,
              'propuestaAnulacion': row
            }
          });
          ref.afterClosed().subscribe(data => {
            this.load();
          });
        });
      }
        
      }
  }

  
 

 

}
