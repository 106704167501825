import { NgModule, Injector } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: []
})
export class AppInjectorModule {
  static injector: Injector;
  constructor(private injector: Injector) {
    AppInjectorModule.injector = injector;
  }

}
