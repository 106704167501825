<app-dialog-header label="Incorporar" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Tancar" i18n-text (click)="this.close()" color="has-color-yellow" icon="fa-times"></app-tool-button>
</app-toolbar>

<mat-list>
  <!-- <mat-list-item *ngFor ="let user of usuarios">
    <label> </label>
    <button mat-icon-button (click)= "incorporar(user)">
      {{  user }} 
      <i class="fa fa-plus-square"></i>
     </button>
  <mat-list-item> -->

    <mat-list-item class="row card" style="margin-bottom: 0rem !important;" *ngFor ="let user of usuarios"> 
     
        <button class="btn btn-primary col-12" (click)="this.incorporar(user)">
          {{ user }}
        </button>
     </mat-list-item>
<mat-list>

