<app-filter-header title="Filtrar" i18n-title (apply)="this.apply()" (reset)="this.reset()" (dismiss)="this.dismiss()"></app-filter-header>
<div class="col-5">
  <form #mainForm class="row filter">
    <h5 class="col-12" i18n>Filtro</h5>
    <div class="col-10">
      <app-form-date-range-edit label1="Fecha inicio" i18n-label1 label2="Fecha fin" [(value)]="this.filter.fechas"></app-form-date-range-edit>
    </div>
    <div class="col-12" style="padding: inherit;">
      <app-form-multi-select titulo="Equipos" [data]="aparatos" [(selectedValues)]="this.filter.aparatos" i18n-titulo tituloMultiSelect="Selecciona uno o más" i18n-tituloMultiSelect></app-form-multi-select>
    </div>
  </form>
</div>