import { BehaviorSubject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { UsuarisService } from 'app/sections/usuaris/services/usuaris.service';
import { AuthService } from 'path-shared/services/authentication/auth.service';
import { MatDialogRef } from '@angular/material'; 
import { Usuari } from 'app/sections/usuaris/models/usuari.model';
import { EstadistiquesService } from '../../services/estadistiques.service';
import { FiltreAgentsEstadistiques } from '../../models/filtreAgents';
import { FiltroTipoEstadisticasAgentes } from '../../models/filtro-tipoEstadisticasAgentes.model';
import { FileDownloader } from 'path-shared/services/http/fileDownloader';
import { FormMultiSelect } from 'path-shared/components/form/form-multi-select/model/form-multi-select.model';
import { AgentsService } from 'app/sections/configuracio/agents/services/agents-service.service';
import { DomSanitizer } from '@angular/platform-browser';

declare let pdfMake: any ;
@Component({
  selector: 'app-estadisitcas-agente',
  templateUrl: './estadisitcas-agente.component.html',
  styleUrls: ['./estadisitcas-agente.component.scss']
})
export class EstadisitcasAgenteComponent implements OnInit {
  
public tiposEstadisticasAgentes = [ {label:FiltroTipoEstadisticasAgentes.Tabla}, {label:FiltroTipoEstadisticasAgentes.Agente}, {label:FiltroTipoEstadisticasAgentes.Indicador}];
public tipoEstadisticaAgenteSelected:string =FiltroTipoEstadisticasAgentes.Tabla;
  usuari: Usuari;
  pdfActual: BehaviorSubject<string> = new BehaviorSubject('');
  pdfb64: any;
  zoom = 1;
  page=1;
  filtreAgentsEstadistiques: FiltreAgentsEstadistiques;
  agents = new Array<FormMultiSelect>();
  image;
  imagePath;
  imgData: any;
  public agentesSelected = new Array<FormMultiSelect>();
    constructor(private estadisitquesService: EstadistiquesService,
      private usuarisService: UsuarisService, 
      private authService: AuthService,
      private agentsService: AgentsService,
      public thisDialogRef: MatDialogRef<EstadisitcasAgenteComponent>,
      private sanitizer: DomSanitizer) { 
       // Carreguem els agents
      this.agentsService.getAllCombo().subscribe(result => {
        this.agents = result.map(s => new FormMultiSelect(s.codi, s.codi));
      });
    }
  
    ngOnInit() {
      this.filtreAgentsEstadistiques={mensual:false,pdf:true, dataInici: new Date(), dataFi: new Date(), tipo:FiltroTipoEstadisticasAgentes.Tabla, agentes: null};
      // this.usuari = this.usuarisService.getCurrentUser();
      // if (this.idRegistroTurno === undefined || this.idRegistroTurno === null || this.idRegistroTurno === 0 ){
      //   this.usuari
      //   this.estadisitquesService.getLastTurnActive( this.usuari.UserID).subscribe(x => {
      //     this.registroTurno = x;
      //     this.turnosService.getReportTurnoUsuarioByID(this.registroTurno.secureId).subscribe(report => {
      //       this.pdfb64 = report;
      //      this.pdfActual.next( this.buildPdf(report));
      //   });
      //   });
      // } else {
      //   this.turnosService.getRegistroTurnoUsuarioByID(this.idRegistroTurno).subscribe(x => {
      //     this.registroTurno = x;
      //     this.turnosService.getReportTurnoUsuarioByID(this.registroTurno.secureId).subscribe(report => {
      //       this.pdfb64 = report;
      //       this.pdfActual.next( this.buildPdf(report));
      //     });
      //   });
      // }
    }
  
    getDatos() {
      //this.filtreAgentsEstadistiques.tipo=this.tipoEstadisticaAgenteSelected;
      this.filtreAgentsEstadistiques.pdf= true;
      this.filtreAgentsEstadistiques.agentes=this.agentesSelected;
      this.estadisitquesService.getDatosAgentes(this.filtreAgentsEstadistiques).subscribe(x => {
        
        // this.page=1;
        // this.pdfb64 = x;
        // this.pdfActual.next( this.buildJpg(x));
        // let objectURL = 'data:image/jpg;base64,' + x;
        // this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        // this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl(this.buildJpg(x));
        

        this.imgData = this.buildImage(x);
      });
    }
    buildImage(detailsdata: any): any {
      return 'data:image/png;base64,' + btoa(new Uint8Array(detailsdata).reduce(function (datas, byte) {
        return datas + String.fromCharCode(byte);
      }, '')) ;
    }
    buildPdf(detailsdata: any): any {
      return 'data:image/pdf;base64,' + btoa(new Uint8Array(detailsdata).reduce(function (data, byte) {
        return data + String.fromCharCode(byte);
        }, '')) ;
    }
  
    downloadPDF() {
      const linkSource = this.pdfActual.value;
      const downloadLink = document.createElement("a");
      const fileName = "Agentes"+new Date().getFullYear().toString()+new Date().getMonth().toString()+new Date().getDay().toString() +new Date().getHours().toString()+new Date().getMinutes().toString()+new Date().getSeconds().toString()+".pdf";
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
  }
  generateExcel() {
    this.filtreAgentsEstadistiques.pdf= false;
    this.filtreAgentsEstadistiques.agentes=this.agentesSelected;
    this.estadisitquesService.getDatosAgentes(this.filtreAgentsEstadistiques).subscribe(x => {
      const fileName = "Agentes"+new Date().getFullYear().toString()+new Date().getMonth().toString()+new Date().getDay().toString() +new Date().getHours().toString()+new Date().getMinutes().toString()+new Date().getSeconds().toString()+".xls";
      FileDownloader.downLoadFile([x], 'application/vnd.ms-excel;charset=utf-8', fileName);
    });
  
  }
  printPDF() {
   
    pdfMake.createPdf(this.pdfb64).print();
     
  }
  
  b64toBlob(content, contentType){
    contentType = contentType || '';
    const sliceSize = 512;
     // method which converts base64 to binary
    const byteCharacters = window.atob(content); 
  
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, {
        type: contentType
    }); // statement which creates the blob
    return blob;
  }
  cancel() {
    this.thisDialogRef.close(false);
  }
  
  incrementZoom(amount: number) {
    this.zoom += amount;
  }

  }
  