import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
import { TramosFilter } from '../models/tramo-filter';


const defaultSortParam = 'descripcion';
const defaultSortDirection = 'asc';

@Injectable()
export class TramosFilterService extends CustomFilterService {
  public createFilterParams(filter: TramosFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {
    return new HttpParams()
    .append('SomId', (filter.SomId ? filter.SomId.toString() : ''))
    .append('descripcion', filter.descripcio)
    .append('numPlazas', (filter.numPlazas ? filter.numPlazas.toString() : ''))
    .append('temporadaSomId', (filter.temporadaSomId ? filter.temporadaSomId.toString() : ''))
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
