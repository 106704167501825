import { Injectable } from '@angular/core';
import { DenunciaFilter } from '../models/denuncia.filter';
import { HttpParams } from '@angular/common/http';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { Reflection } from 'path-shared/services/reflection/reflection';

const defaultSortParam = 'data';
const defaultSortDirection = 'desc';


@Injectable()
export class DenunciesFilterService  extends CustomFilterService {
  public createFilterParams(filter: DenunciaFilter, start: number, pageSize: number, sortField: string, sortDir: string ): HttpParams {
    return new HttpParams()
    .append('agent', filter.agente ? filter.agente : '')
    .append('article', filter.articulo ? filter.articulo : '')
    .append('carrer', filter.calle ? filter.calle : '')
    .append('codanulacio', filter.codigoAnulacion ? filter.codigoAnulacion : '')
    .append('dni', filter.dni ? filter.dni : '')
    .append('expedient', filter.expediente ? filter.expediente : '')
    .append('expExtern', filter.expExtern ? filter.expExtern : '')
    .append('matricula', filter.matricula ? filter.matricula : '')
    .append('numdenuncia', filter.numeroDenuncia ? filter.numeroDenuncia : '')
    .append('nombreConductor', filter.nombreConductor ? filter.nombreConductor : '')
    // tslint:disable-next-line:triple-equals
    .append('FechaCobroInicio', Reflection.empty(filter.fechaCobroInicio) ? '' : typeof(filter.fechaCobroInicio) === 'string' ?
    filter.fechaCobroInicio : filter.fechaCobroInicio.toDateString())
    // tslint:disable-next-line:triple-equals
    .append('fechaCobroFin', Reflection.empty(filter.fechaCobroFin) ? '' : typeof(filter.fechaCobroFin) === 'string' ?
    filter.fechaCobroFin : filter.fechaCobroFin.toDateString())
    // tslint:disable-next-line:triple-equals
    .append('fechaInfraccionInicio', Reflection.empty(filter.fechaInfraccionInicio)  ? '' : typeof(filter.fechaInfraccionInicio) === 'string' ?
    filter.fechaInfraccionInicio : filter.fechaInfraccionInicio.toDateString())
    // tslint:disable-next-line:triple-equals
    .append('fechaInfraccionFin', Reflection.empty(filter.fechaInfraccionFin)   ? '' : typeof(filter.fechaInfraccionFin) === 'string' ?
    filter.fechaInfraccionFin : filter.fechaInfraccionFin.toDateString())
    .append('fechaCierreInicio', Reflection.empty(filter.fechaCierreInicio)  ? '' : typeof(filter.fechaCierreInicio) === 'string' ?
    filter.fechaCierreInicio : filter.fechaCierreInicio.toDateString())
    .append('fechaCierreFin', Reflection.empty(filter.fechaCierreFin)   ? '' : typeof(filter.fechaCierreFin) === 'string' ?
    filter.fechaCierreFin : filter.fechaCierreFin.toDateString())
    .append('fechaTraspasoInicio', Reflection.empty(filter.fechaTraspasoInicio)  ? '' : typeof(filter.fechaTraspasoInicio) === 'string' ?
    filter.fechaTraspasoInicio : filter.fechaTraspasoInicio.toDateString())
    .append('fechaTraspasoFin', Reflection.empty(filter.fechaTraspasoFin)   ? '' : typeof(filter.fechaTraspasoFin) === 'string' ?
    filter.fechaTraspasoFin : filter.fechaTraspasoFin.toDateString())
    .append('fechaCaducidadInicio', Reflection.empty(filter.fechaCaducidadInicio)  ? '' : typeof(filter.fechaCaducidadInicio) === 'string' ?
    filter.fechaCaducidadInicio : filter.fechaCaducidadInicio.toDateString())
    // tslint:disable-next-line:triple-equals
    .append('fechaCaducidadFin', Reflection.empty(filter.fechaCaducidadFin)   ? '' : typeof(filter.fechaCaducidadFin) === 'string' ?
    filter.fechaCaducidadFin : filter.fechaCaducidadFin.toDateString())
    .append('fechaPrescripcionInicio', Reflection.empty(filter.fechaPrescripcionInicio)  ? '' : typeof(filter.fechaPrescripcionInicio) === 'string' ?
    filter.fechaPrescripcionInicio : filter.fechaPrescripcionInicio.toDateString())
    // tslint:disable-next-line:triple-equals
    .append('fechaPrescripcionFin', Reflection.empty(filter.fechaPrescripcionFin)   ? '' : typeof(filter.fechaPrescripcionFin) === 'string' ?
    filter.fechaPrescripcionFin : filter.fechaPrescripcionFin.toDateString())
    .append('esJudicial', !filter.esJudicial ? '' : filter.esJudicial === 'NO' ? 'false' : 'true')
    .append('esJPT', !filter.esJPT ? '' : filter.esJPT === 'NO' ? 'false' : 'true')
    .append('esSCT', !filter.esSCT ? '' : filter.esSCT === 'NO' ? 'false' : 'true')
    .append('numPDA', filter.pda ? filter.pda : '')
    .append('ley', filter.ley ? filter.ley : '')
    .append('zona', filter.zonaBlava ? filter.zonaBlava : '')
    // tslint:disable-next-line:triple-equals
    .append('fechaEnvioInicio', filter.fechaEnvioInicio == undefined ? '' : typeof(filter.fechaEnvioInicio) === 'string' ?
    filter.fechaEnvioInicio : filter.fechaEnvioInicio.toDateString())
    // tslint:disable-next-line:triple-equals
    .append('fechaEnvioFin', filter.fechaEnvioFin == undefined ? '' : typeof(filter.fechaEnvioFin) === 'string' ?
    filter.fechaEnvioFin : filter.fechaEnvioFin.toDateString())
    // tslint:disable-next-line:triple-equals
    .append('fechaAnulacionInicio', filter.fechaAnulacionInicio == undefined ? '' : typeof(filter.fechaAnulacionInicio) === 'string' ?
    filter.fechaAnulacionInicio : filter.fechaAnulacionInicio.toDateString())
    // tslint:disable-next-line:triple-equals
    .append('fechaAnulacionFin', filter.fechaAnulacionFin == undefined ? '' : typeof(filter.fechaAnulacionFin) === 'string' ?
    filter.fechaAnulacionFin : filter.fechaAnulacionFin.toDateString())
    .append('tipusAnul', filter.tipusAnul ? filter.tipusAnul : '')
    .append('usuariAnul', filter.usuariAnul ? filter.usuariAnul : '')
    .append('motiuAnul', filter.motiuAnul ? filter.motiuAnul : '')
    .append('zonaAzul', !filter.zonaAzul ? '' :  filter.zonaAzul ? 'true' : 'false')
    .append('convivencia', !filter.convivencia ? '' :  filter.convivencia ? 'true' : 'false')
    .append('policia', !filter.policia ? '' :  filter.policia ? 'true' : 'false')
    .append('tancat', !filter.tancat ? '' : filter.tancat === 'NO' ? 'false' : 'true')
    .append('notificadaConductor', !filter.notificadaConductor ? '' : filter.notificadaConductor === 'NO' ? 'false' : 'true')
    .append('notificadaPropietario', !filter.notificadaPropietario ? '' : filter.notificadaPropietario === 'NO' ? 'false' : 'true')
    .append('fechaNotiConInicio', filter.fechaNotiConInicio == undefined ? '' : typeof(filter.fechaNotiConInicio) === 'string' ?
    filter.fechaNotiConInicio : filter.fechaNotiConInicio.toDateString())
    .append('fechaNotiConFin', filter.fechaNotiConFin == undefined ? '' : typeof(filter.fechaNotiConFin) === 'string' ?
    filter.fechaNotiConFin : filter.fechaNotiConFin.toDateString())
    .append('fechaNotiPropInicio', filter.fechaNotiPropInicio == undefined ? '' : typeof(filter.fechaNotiPropInicio) === 'string' ?
    filter.fechaNotiPropInicio : filter.fechaNotiPropInicio.toDateString())
    .append('fechaNotiPropFin', filter.fechaNotiPropFin == undefined ? '' : typeof(filter.fechaNotiPropFin) === 'string' ?
    filter.fechaNotiPropFin : filter.fechaNotiPropFin.toDateString())
    .append('puntos', filter.puntos ? filter.puntos : '')
    .append('filterTable', filter.filterTable)
    .append('origen', filter.origen ? filter.origen : '')
    .append('aparato', filter.aparato)
    .append('estatGrua', filter.estatGrua)
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
