<form #mainForm>
  <div class ="border border-secondary fondo" style="padding:10px;">
    <div class="row">
      <div class="col-10">
        <app-form-text-area [(value)]="this.dto.alegacion" id="form-alegaciones-alegacion" i18n-label label="Alegación" required></app-form-text-area>
        <app-view-list-icon (listClick)="this.buscarAlegacions()" [hideView]="true" ></app-view-list-icon>
      </div>
      <app-form-date-edit label="Fecha" i18n-label [(value)]="this.dto.fechaAlegacion" required class="col-2"></app-form-date-edit>
    </div>
  </div>
</form>
