<app-dialog-header *ngIf="this.dataDialog.isDialog" label="Detecció Matrícules" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Consultar" i18n-text (click)="refresh();" color="has-color-blue" icon="fa-sync"></app-tool-button>
  <app-tool-button text="Excel" i18n-text (click)="generateExcel();" color="has-color-darkgreen" icon="fa-file-excel"></app-tool-button>
  <app-tool-button-permissions [permission]="this.auth.Pages.Matricules"></app-tool-button-permissions>
</app-toolbar>

<div class="row">
  <span i18n style="padding: 1vh;">Tipo de bono</span>
  <app-form-tri-state class="col-2" style="display: contents;" (valueChange)="GetTipoValueFromTriState($event)" [value]="bTipo" [labelSi]="this.i18n('Normal')" [labelNo]="this.i18n('Temporal')" [labelTodos]="this.i18n('Tots')"></app-form-tri-state>
  <app-form-text-edit id="bonos-matricula" class="col-1" style="min-width: fit-content !important;" i18n-label label="Matrícula" [(value)]="filterData.matricula"></app-form-text-edit>
  <app-form-date-edit id="bonos-dataIni" class="col-1" style="min-width: fit-content !important;" i18n-label label="Data Inicial" [(value)]="filterData.fechaInicio"></app-form-date-edit>
  <app-form-date-edit id="bonos-dataFi" class="col-1"  style="min-width: fit-content !important;" i18n-label label="Data Final" [(value)]="filterData.fechaFin"></app-form-date-edit>
  <!-- <i class="fas fa-search lupa" (click)="this.refresh()"></i> -->

</div>
<app-bonos-table></app-bonos-table>


