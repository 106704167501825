<app-dialog-header label="Auditoria" i18n-label style="margin-bottom:2vh;"></app-dialog-header >
<app-dialog-body style="padding:2vh;">
  <div *ngIf="this.tablasAsociadas.length>1" class="alert"> 
    <span i18n >Hi ha més d'una taula associada a aquesta pantalla, seleccioni quina vol consultar</span>
    <div class="row">
      <app-form-combo-edit class="col-6" [items]="this.tablasAsociadas" label="Taules associades" i18n-label textField="descripcio" valueField="codi" (valueChange)="getTablaAuditoria($event)" [(value)]="this.taulaSeleccionada"></app-form-combo-edit>
    </div>
  </div>
  <div *ngIf="this.tablaAuditoria!=null" class="table-sdw is-fixed">
    <div class="row">
      <mat-form-field class="col-8">
        <mat-label i18n>Filtre</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
      </mat-form-field>
      <div class="col">
        <mat-paginator #paginator [length]="this.length" [pageIndex]="0" [pageSize]="this.paginator.pageSize" [pageSizeOptions]="[250, 500, 750, 1000, 9999]" (page)="Page($event)">
        </mat-paginator>
      </div>
    </div>
    
    <mat-table #table [dataSource]="dataSource" matSort (matSortChange)="Sort($event)" aria-label="Elements"  class="is-header-sticky">
  
      <div *ngFor="let column of tablaAuditoria.Columns">
        <ng-container  matColumnDef={{column}}>
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>{{column}}</mat-header-cell>
          <mat-cell *matCellDef="let row">{{getValue(row,column)}}</mat-cell>
        </ng-container>
      </div>      
  
      <mat-header-row *matHeaderRowDef="tablaAuditoria.Columns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: tablaAuditoria.Columns;"></mat-row>
    </mat-table>
  </div>
  <div *ngIf="this.tablaAuditoria==null && taulaSeleccionada!=null" class="alert alert-danger"> 
    <span i18n>  No hi ha auditories per mostrar</span>
  </div>
  <div *ngIf="taulaSeleccionada==null && this.tablasAsociadas.length == 0" class="alert alert-danger"> 
    <span i18n>  No hi ha taules associades a aquesta pantalla. Contacti amb informàtica</span>
  </div>
</app-dialog-body>
