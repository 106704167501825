<app-toolbar> 
  <app-tool-button-permissions [permission]="this.auth.Pages.GestioResidents"></app-tool-button-permissions>
</app-toolbar>
<form #mainForm>
  <div class="row">
    <app-form-text-edit id="xml-tree-viewer-matricula" class="col-xl-2 col-md-2 col-sm-2" i18n-label label="Matricula:" [(value)]="this.matricula" maxLength="15" required></app-form-text-edit>  
    <app-form-text-edit id="xml-tree-viewer-persona" class="col-xl-2 col-md-2  col-sm-2" i18n-label label="DNI:" [(value)]="this.dni" maxLength="15" required></app-form-text-edit>  
    <div class="p-1" ><button class="btn btn-sq-lg btn-primary" i18n (click)="consultarPadro()">Consultar</button></div>
  </div>
</form>

<div class="row" *ngIf="this.resultat==='OK'">
  <div class="alert alert-success col-sm-5 text-center">
    <i class="far fa-thumbs-up"></i>
    &nbsp;<span i18n>Empadronado</span> 
  </div>
</div>

<div class="row" *ngIf="this.resultat==='NO_OK'">
   <div class="alert alert-danger col-sm-5 text-center">
    <i class="far fa-thumbs-down"></i>
    &nbsp;<span i18n>No empadronado</span> 
   </div>
</div>
