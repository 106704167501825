<app-dialog-header icon="fa-key" label="Canviar contrasenya" i18n-label></app-dialog-header>

<!-- <app-toolbar>
  <app-tool-button text="Guardar" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save"></app-tool-button>
</app-toolbar> -->
<app-dialog-body>
  <form #mainForm>

    <div class="form-group card" style="margin-top: 1vh;padding-bottom: 1vh; padding-top: 1vh;">

      <div *ngIf="message!==null" style="margin:1vh; text-align:center">
        <p>{{message}}</p>
        <hr style="width: 90%;">
      </div>
      <div>
        <app-form-text-edit type="password" id="change-password-antiga" name="Contrasenya antiga"  i18n-label label="Contrasenya antiga" [(value)]="this.oldPwd"  required></app-form-text-edit>
      </div>
      <hr style="width: 90%;">
      <div>
        <app-form-text-edit type="password" id="change-password-nova" name="Contrasenya nova"  i18n-label label="Contrasenya nova" [(value)]="this.newPwd"  required ></app-form-text-edit>
        <div class="row">
          <password-strength-meter class="col-11" [password]="newPwd"></password-strength-meter>
          <mat-icon class="col-1 info-icon" i18n-matTooltip matTooltip="Una contrasenya segura inclou lletres, números, i caràcters especials" matTooltipClass="info-tooltip">info</mat-icon>
        </div>
        
      </div> 
      <div>
        <app-form-text-edit type="password" id="change-password-repetir" name="Repetir contrasenya"  i18n-label label="Repetir contrasenya" [(value)]="this.repeatPwd" required></app-form-text-edit>
      </div>


      <div style="text-align: -webkit-center;">
        <button class="btn btn-primary" (click)="save()" i18n>Guardar</button>
      </div>
    </div>
    
  </form>
</app-dialog-body>
