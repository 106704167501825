import { TurnosService } from './../../../sections/configuracio/grua/turnos/services/turnos-service';
import { AlarmesService } from './../../../sections/alarmes/services/alarmes.service';
import { Component, OnInit, Input } from '@angular/core';
import {AuthService} from 'path-shared/services/authentication/auth.service';
import { UsuarisService } from 'app/sections/usuaris/services/usuaris.service';
import { Usuari } from 'app/sections/usuaris/models/usuari.model';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { AlarmGroup } from 'path-shared/models/alarm-group';
import { AngularFontAwesomeService } from 'angular-font-awesome';
import { Terminal } from 'app/sections/comunicacio-pda/models/terminal.model';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ChangePasswordComponent } from 'app/sections/usuaris/components/change-password/change-password.component';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { InstitucionsService } from 'path-shared/services/institucions/institucions.service';
import { Instituciones } from 'path-shared/models/instituciones';
import { LoginService } from 'app/sections/login/services/login.service';
import { RegistroTurno } from 'app/sections/configuracio/grua/turnos/models/registro-turno';
import { EventEmitterService } from 'path-shared/services/eventEmitter/event-emitter-service';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { SeguretatUsuarisService } from 'app/sections/seguretat/seguretat-usuaris/services/seguretat-usuaris.service';
import { SeguretatUsuaris } from 'app/sections/seguretat/seguretat-usuaris/models/seguretat-usuaris.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { IniciarTurnoComponent } from 'app/sections/configuracio/grua/turnos/components/iniciar-turno/iniciar-turno.component';
import { TwoFactorAuthenticationComponent } from 'app/sections/usuaris/components/2FA/2fa.component';
import { AlarmaGps } from 'app/sections/alarmes/models/alarma-gps.model';
import { AlarmaDetallComponent } from 'app/sections/alarmes/components/alarma-detall/alarma-detall.component';
import { AlarmesGpsService } from 'app/sections/alarmes/services/alarmesGps.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  @Input() pageTitle = '';
  public alarmesPendents: number;
  public pdasPendentsLlegir: number;
  public alarmesGpsPendentsLlegir: boolean;
  public version: string;
  private idInstit: string;

  public registroTurno: RegistroTurno;
  public toggleClass = false;
  alarms: AlarmGroup[];
  // segUser: SeguretatUsuaris;
  isUserGrua: boolean;
  userLastCon = null;
  gtwinSync = false;
  idsAlarmaShow: string[] = [];
  alarmaRef: MatDialogRef<AlarmaDetallComponent>;
  constructor(
    public auth: AuthorizationService,
    private authService: AuthService,
    public usuariService: UsuarisService,
    public seguretatUsuarisService: SeguretatUsuarisService,
    private not: NotificationService,
    private alarmsService: AlarmesService,
    private dialog: MatDialog,
    public i18n: I18n,
    private loginService: LoginService,
    private turnosService: TurnosService,
    private eventEmitterService: EventEmitterService,
    private alarmesGpsService: AlarmesGpsService

    ) {
      this.alarmesPendents = 0;
      this.pdasPendentsLlegir = 0;
      this.isUserGrua = false;
    }

  ngOnInit() {
    setTimeout(() => {
      const inst = sessionStorage.getItem('currentInst');
      if (inst !== null && inst !== '' && inst !== undefined && inst !== 'undefined') {
        const institution: Instituciones = JSON.parse(inst);
        this.version =  institution.version;
        this.idInstit = institution.IdInstitucion;
      }
    }, 2000);



    this.not.onTitleChange.subscribe(title => {
      setTimeout(() => {
        this.pageTitle = title;
      }, 10);
    });
    this.not.onAlarmesPendentsChange.subscribe(alarmesPendents => {
      if (this.alarmesPendents !== alarmesPendents) {
        this.alarmesPendents = alarmesPendents;
        this.alarmsService.getGroups().subscribe(data => {
          this.alarms = data;
        });
      }
    });

    this.not.onPdasPendentsLlegirChange.subscribe(result => {
      const pdasPendentsLlegir: Array<Terminal> = JSON.parse(result.data);
      if (this.pdasPendentsLlegir !== pdasPendentsLlegir.length) {
        this.pdasPendentsLlegir = pdasPendentsLlegir.length;
      }
    });

    this.not.onAlarmesGpsPendentsChange.subscribe(result => {
      if (result.data !== 'null') {
        this.alarmesGpsPendentsLlegir = true;
      } else {
        this.alarmesGpsPendentsLlegir = false;
      }
      this.HandleAlarm(result);

    });

    const user: Usuari = this.usuariService.getCurrentUser();
    if (user !== undefined && user !== null && user.Roles  !== undefined && user.Roles  !== null ) {
      // this.seguretatUsuarisService.getSeguretatUsuaris(user.SECURE_ID).subscribe( seg =>{
        // this.segUser = seg;
        if (this.auth.CheckUserRole(this.auth.Roles.Grua) && user.Name !== 'SOMINTEC') {
          this.isUserGrua = true;
          this.getTurno();
        }
     // });
      const lastCon = sessionStorage.getItem('lastCon');
      if (lastCon !== '' && lastCon !== undefined && lastCon !== 'undefined') {
        this.userLastCon = JSON.parse(lastCon);
      }
    }

    this.not.onGtwinSyncChange.subscribe((result) => {
      this.gtwinSync = result;
    });

    if (this.eventEmitterService.subsVar === undefined) {
      this.eventEmitterService.subsVar = this.eventEmitterService.
      invokeFirstComponentFunction.subscribe((x) => {
        this.getTurno();
      });
    }
  }

  toggleHeader(evt) {
    this.toggleClass = !this.toggleClass;

    document.getElementsByTagName('body')[0].classList.toggle('is-nav-open');
  }

  ChangePwd() {
    this.dialog.open(ChangePasswordComponent, {
      closeOnNavigation: false,
      autoFocus: true,
      width: '15%',
      data: {idInstit: this.idInstit}

    }).afterClosed().subscribe( res => {


    });
  }


  Open2FA() {
    this.dialog.open(TwoFactorAuthenticationComponent, {
      closeOnNavigation: false,
      autoFocus: true,
      width: '20%',
    }).afterClosed().subscribe( res => {
    });
  }
  logout() {
    this.authService.logOut();
  }

  getUsername() {
    const user: Usuari = this.usuariService.getCurrentUser();
    return user.UserName;
  }
  getFullName() {
    const user: Usuari = this.usuariService.getCurrentUser();
    return user.Name;
  }

  getAlarms() {

  }

  public getTurno() {
    if (this.isUserGrua !== undefined && this.isUserGrua !== null  && this.isUserGrua) {
      const reg = sessionStorage.getItem('idRegistroTurno');
      const usuari = this.usuariService.getCurrentUser();
      if (reg === undefined || reg === null  ) {
        this.turnosService.getLastTurnActive( usuari.UserID).subscribe(x => {
          this.registroTurno = x;
        }, (e: any) =>  {
          this.gestionErrorTurno(e);
        });
      } else {
        this.turnosService.getRegistroTurnoUsuarioByID(Number.parseInt(reg)).subscribe(x => {
          this.registroTurno = x;
        }, (e: any) =>  {
          this.gestionErrorTurno(e);
        });
      }
    }

  }

  HandleAlarm(result) {
    console.log('consulta Alarmes ' + new Date().toString() + ' : ' + result);
        if (result.data !== undefined) {
          const alar: AlarmaGps = JSON.parse(result.data);
          if ( alar !== undefined && alar !== null) {
            if (this.idsAlarmaShow.find(g => g === alar.Id) === undefined) {
              this.alarmesGpsService.getLeidoAlarma(alar.Id).subscribe(leido => {
                if (leido === false) {
                  const data: ComponentDialog = Builder.newComponentDialog(true, false);
                  this.alarmaRef = this.dialog.open(AlarmaDetallComponent, {
                    width: '25%',
                    height:  '30%',
                    closeOnNavigation: false,
                    autoFocus: true,
                    data: alar
                  });

                  this.alarmaRef.afterClosed().subscribe(updated => {
                    // Sergio 21/09/2021: ahora solo marcamos leida desde acción en alarmes/visor
                    // this.alarmesGpsService.setAlarmaVista( alar).subscribe(res => {
                    //   console.log(res);

                    // });
                  });
                }
                this.idsAlarmaShow.push(alar.Id);
              });
            }
          } else {
            if (this.alarmaRef !== undefined && this.alarmaRef.componentInstance !== null) {
              this.alarmaRef.componentInstance.close();
              this.alarmaRef = undefined;

            }
          }
        }
  }


  private gestionErrorTurno(error: Response| any) {
    if (error !== undefined && error !== null && error.status === 404 && this.isUserGrua !== undefined && this.isUserGrua !== null  && this.isUserGrua) {
      const data: ComponentDialog = Builder.newComponentDialog(true);
      data.title = this.i18n('No hi ha torn de grua actiu. S\'ha de seleccionar algún torn per a continuar!');
      const ref = this.dialog.open(IniciarTurnoComponent, {
        width: '30%',
        closeOnNavigation: false,
        autoFocus: true,
        disableClose: true,
        data: data
      });
        ref.afterClosed().subscribe(idRegistroTurno => {
          sessionStorage.setItem('idRegistroTurno', idRegistroTurno);
        });
    }
  }
}
