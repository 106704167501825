import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Observable } from 'rxjs';
import { Siglas } from '../models/siglas.model';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { SiglasFilter } from '../models/siglas-filter';
import { SiglasFilterService } from './siglas-filter.service';

const defaultSortParam = 'id';
const defaultSortDirection = 'desc';

@Injectable()
export class SiglasService {
  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {}

  getAll(): Observable<any> {
    if (this.authGuard.canActivate()) {
      const params = new SiglasFilterService().createFilterParams(new SiglasFilter('id', 'asc'), 0, 99999, 'id', 'asc');
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetSiglas'],  params);
    }
  }

  setSigla(sigla: Siglas): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetSigla'], sigla);
    }
  }

  removeSigla(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteSigla'].replace('{secure_id}', secure_id), null);
    }
  }

  getSigla(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetSigla'].replace('{secure_id}', secure_id), null);
    }
  }

  generateExcel(filter: SiglasFilter, sortDir: string, sortField: string): Observable<any> {
    const start = 0;
    const pageSize = 1000;

    sortField = sortField === undefined ? defaultSortParam : sortField;
    sortDir = sortDir === '' ? defaultSortDirection : sortDir;
    const params = new SiglasFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

    if (this.authGuard.canActivate()) {
      return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlSiglasExcel'],  params );
    }
  }
}
