<div class="row justify-content-between">
  <div class="col"><app-filter-selector [filter]="this.filter"></app-filter-selector></div>
  <div class="col">
    <mat-paginator #paginator
      [pageIndex]="0"
      [pageSize]="this.filter.paginator.pageSize"
      [pageSizeOptions]="[20, 40, 60, 100]">
    </mat-paginator>
  </div>
</div>
<div class="row">
  <div class="col-sm-12">
    <app-chips-list-input #tipoPropuestaCombo style="display:inline-block; padding-left:10px; vertical-align: top;" i18n-label label="Temporada" 
    [valuesList]="this.temporadasSource.value" (valueChange)="this.temporadaSeleccionado($event)">
    </app-chips-list-input>
  </div>
  <div class="col-sm-6">
    <div class="table-sdw is-fixed mh-50 d-inline-block">

      <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.filter.paginator.sortField" [matSortDirection]="this.filter.paginator.sortDir" class="is-header-sticky">

        <!--<ng-container matColumnDef="incorpora" *ngIf="this.selectButton">
          <mat-header-cell *matHeaderCellDef title="Incorporar" i18n-title class="text-center"><i class="fa fa-plus-square has-color-grey"></i></mat-header-cell>
          <mat-cell *matCellDef="let row" class="text-center"><a (click)="this.selectCurrent(row.codi)" title="Incorporar" i18n-title><i class="fa fa-plus-square has-color-aqua"></i></a></mat-cell>
        </ng-container>-->
        <ng-container matColumnDef="incorpora" >
          <mat-header-cell *matHeaderCellDef title="Incorporar" i18n-title class="text-center"><i class="fa fa-plus-square has-color-grey"></i></mat-header-cell>
          <mat-cell *matCellDef="let row"  ><mat-checkbox #checkbox [(ngModel)]="row.Selected" (click)="selectRow(row)"></mat-checkbox></mat-cell><!--(change)="this.Checked(row)"-->
        </ng-container>

          <ng-container matColumnDef="Som_id">
              <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Codi</mat-header-cell>
              <mat-cell *matCellDef="let row"><a (click)="onRowClicked(row)" class="is-link cursor detalle">{{row.Som_id}}</a></mat-cell>
            </ng-container>

          <ng-container matColumnDef="descripcio">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Descripcio</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.descripcion}}</mat-cell>
          </ng-container>
          
          <ng-container matColumnDef="numPlazas">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Número places</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.numPlazas}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="temporada">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Temporada</mat-header-cell>
            <mat-cell *matCellDef="let row"><span  *ngIf="row.temporada !== undefined && row.temporada !== null">{{row.temporada.descripcion}}</span></mat-cell>
          </ng-container>
          
          <ng-container matColumnDef="zona">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Zona</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.zona.descripcio}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="colorZona">
            <mat-header-cell *matHeaderCellDef i18n>Color</mat-header-cell>
              <mat-cell *matCellDef="let row" [style.background-color]="row.zona.color"></mat-cell>
          </ng-container>

          <ng-container matColumnDef="qr">
            <mat-header-cell *matHeaderCellDef><i class="fa fa-qrcode" text="QR" i18n-text></i></mat-header-cell>
            <mat-cell *matCellDef="let row">
                <div >
                  <a (click)="this.viewQR(row.Som_id)" class="is-link">
                    <i class="fa fa-qrcode"></i>
                  </a>
                </div>
            </mat-cell>
        </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
        </mat-table>
    </div>
  </div>
  <div class="col-sm-6">
    <div   style="height: 70vh;width:100%;">
      <agm-map [latitude]="currentLocation.latitude" [longitude]="currentLocation.longitude" [zoom]="this.zoomMapa">
        <agm-polygon *ngFor="let polygon of dataSource.subject.value" [paths]="polygon.poligon"
        [fillColor]="polygon.zona.color "
        [strokeColor]="polygon.strokeColor !== undefined && polygon.strokeColor !== null  ? polygon.strokeColor :  polygon.zona.color"  (polyClick)="selectPoligono(polygon)">
      </agm-polygon>
      </agm-map>
    </div>
  </div>
</div>

