import {Component, OnInit, ViewChild} from '@angular/core';
import { MatDialog, MatDialogRef, MatPaginator, MatSort } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ExpedientsComponent } from 'app/sections/expedients/expedients.component';
import { ToastrService } from 'ngx-toastr';
import { DenunciesService } from 'path-denuncies-services/denuncies.service';
import { Builder } from 'path-shared/models/builder';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { BancIncidenciesDetallDto } from '../models/banc-incidenices-detallDto';
import { BancIncidenciesRequestDto } from '../models/banc-inicidencies-requestDto';
import { BancCarreguesIncidenciesFilterService } from '../services/banc-carregues-incidencies-filter.service';
import { BancCarreguesIncidenciesService } from '../services/banc-carregues-incidencies.service';

@Component({
  selector: 'app-banc-incidencies',
  templateUrl: './banc-incidencies.component.html',
  styleUrls: ['./banc-incidencies.component.scss']
})
export class BancIncidenciesComponent implements OnInit {
  requestConsulta: BancIncidenciesRequestDto;
  dataSource: BancIncidenciesDetallDto[]; 
  tipusTramitatSelected:number =1;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private i18n: I18n,
    private toastr: ToastrService,
    private bancsIncidenciesService: BancCarreguesIncidenciesService,
    private not: NotificationService

  ) {

    this.requestConsulta = Builder.newBancIncidenciesRequestDto();;
    }

  ngOnInit() {
    this.resetTable();
  }

  ngAfterViewInit() {     
    this.not.titleChange(this.i18n('Incidencias'));    
  }

  consultar(){
    if(this.requestConsulta.tramitados === undefined || this.requestConsulta.tramitados === null){
        this.toastr.warning(this.i18n('Debe seleccionar el estado de los registros a consultar.'));
        return;
      }else{
        this.resetTable();
       if(this.tipusTramitatSelected === 1 ){
        this.requestConsulta.tramitados = true;
        this.requestConsulta.noTramitados = false;
       }else{
        this.requestConsulta.tramitados = false;
        this.requestConsulta.noTramitados = true;
       }
        //let filter = new BancCarreguesIncidenciesFilterService().createFilterParams(this.requestConsulta, 0,1000, 'fecha','desc' );
        this.bancsIncidenciesService.loadData(this.requestConsulta, 'desc','data').subscribe(x =>{
          if( x !== undefined && x !== null){
            this.dataSource=x;
          }
        });


        // this.dataSource = CustomDataSource.create<BancIncidenciesDetallDto, BancIncidenciesRequestDto>(this.paginator, new BancCarreguesIncidenciesFilterService(), 'url', 'urlmatriculapaginationcount');

      }

  }
  resetTable(){
    this.dataSource= [];
  }
  exportar(){

    

  }
  
 

}
