import { GalleryComponent } from './../../../../../shared/components/gallery/gallery/gallery.component';
import { Component, OnInit, AfterViewInit, OnChanges, ViewChild, Input, EventEmitter, Output, SimpleChanges, ElementRef } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { ExcelDownloadService } from 'path-shared/services/excel/excel-download.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { merge, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Builder } from 'path-shared/models/builder';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { Reflection } from 'path-shared/services/reflection/reflection';
import { SingleEditDialogData } from 'path-shared/components/dialogs/single-edit-dialog/single-edit-dialog-data.model';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { DisplayTextDialogComponent } from 'path-shared/components/dialogs/display-text-dialog/display-text-dialog.component';
import { XmlTreeViewerComponent } from 'app/sections/utilitats/dgt/components/xml-tree-viewer/xml-tree-viewer.component';
import { DeltacarHistorico } from '../../models/deltacar-historico.model';
import { DeltacarHistoricoFilter } from '../../models/deltacar-historico-filter';
import { DeltacarHistoricoService } from '../../services/deltacar-historico.service';
import { DeteccioMatricula } from 'app/sections/ciutat/deteccio-matricules/models/deteccio-matricula.model';
import { DeltacarHistoricoFilterService } from '../../services/deltacar-historico-filter.service';

@Component({
  selector: 'app-deltacar-historico-table',
  templateUrl: './deltacar-historico-table.component.html',
  styleUrls: ['./deltacar-historico-table.component.scss']
})
export class DeltacarHistoricoTableComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('table') table: ElementRef;

  public data: Subject<DeltacarHistorico[]>;
  dataSource: CustomDataSource<DeltacarHistorico, DeltacarHistoricoFilter>;
  highlightedRows = [];
  filter: DeltacarHistoricoFilter = new DeltacarHistoricoFilter('fecha', 'desc');
  filterPage: DeltacarHistoricoFilter;
  displayedItems = [];
  allSelected = false;

  @Input() updatedFilterData: DeltacarHistoricoFilter;
  @Input() selectButton: boolean;
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();
  @Output() itemClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() itemSelected: EventEmitter<string> = new EventEmitter<string>();

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['incorpora','matricula', 'fecha', 'calle', 'numero','zona', 'estado'];

  constructor(
    private deltacarHistoricoService: DeltacarHistoricoService,
    private filterService: FilterService,
    private excel: ExcelDownloadService,
    private dialog: MatDialog,
    private i18n: I18n
    ) {
        this.data = new Subject<DeltacarHistorico[]>();
        this.selectButton = true;
    }

  ngOnInit() {

    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<DeltacarHistorico, DeltacarHistoricoFilter>(this.paginator, new DeltacarHistoricoFilterService(),
      'urlGetDeltacarHistorico', 'urlDeltacarHistoricoPaginationCount');
    this.dataSource.subject.subscribe((data) => {

      let i = 0;
        data.forEach(item => {
          item.markerClicked = false;
          this.deltacarHistoricoService.displayedItems[i] = {
            id: item.id,
            checked: false,
            latitud: item.latitud,
            longitud: item.longitud
          };
          i++;
        });

      this.displayedItems = this.deltacarHistoricoService.displayedItems;
      this.data.next(data);
    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
        if (!this.selectButton) { this.filterService.saveFilter('deltacar-historico-filter', this.filter); }
      }
    });
    this.filter.paginator.init(this.sort, this.paginator);
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    } );
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.filter.updated()))
      .subscribe();
      this.load();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      this.update();
    }
  }

  update() {
    if (this.dataSource !== undefined) {
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
    }
  }

  load() {
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }
  

  onRowClicked(row) {
    this.itemClicked.next(row.id);
  }

  
  selectCurrent(codi) {
    this.itemSelected.next(codi);
  }

  
  generateExcel(): void {
    let filter = this.filter;
    if (this.updatedFilterData != null) {
      filter = this.updatedFilterData;
    }
    this.dialog.open(DownloadExcelDialogComponent, {
        closeOnNavigation: false,
        autoFocus: true,
        data: Builder.newDownloadExcelDialogData (this.deltacarHistoricoService, filter, this.i18n('DeltacarHistorico'), this.sort.direction, this.sort.active)
    });
  }

  rowIndex(row) {
    return this.displayedItems.findIndex(x => x.id === row.id);
  }
  
  clickCheckbox(row) {
    if (this.deltacarHistoricoService.displayedItems.find(x => x.id === row.id).checked === false) {
      this.dataSource.getData().find(x => x.id === row.id).markerClicked = false;
    }
  }

  seleccionarTotes() {
    this.deltacarHistoricoService.displayedItems.forEach(row => {
      if (this.allSelected === true) {
        row.checked = true;
      } else {
        row.checked = false;
      }
    });
  }
}
