import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notificacions-page',
  templateUrl: './notificacions-page.component.html'
})
export class NotificacionsPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
