import { Component, OnInit, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { PermissionDialogComponent } from 'path-shared/components/permission-dialog/permission-dialog.component';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { PersonaFilter } from '../../models/persona-filter';
import { PersonesEditorComponent } from '../../components/persones-editor/persones-editor.component';
import { PersonesTableComponent } from '../../components/persones-table/persones-table.component';
import { PersonesFilterComponent } from '../../components/persones-filter/persones-filter.component';

@Component({
  selector: 'app-persones',
  templateUrl: './persones.component.html',
  styleUrls: ['./persones.component.scss']
})
export class PersonesComponent implements OnInit, AfterViewInit {

  permissionDialogRef: MatDialogRef<PermissionDialogComponent>;
  activeFilters: string[] = [];
  filterData: PersonaFilter;
  somIdFoo: number;
  tipusLegislacions = Array();
  aliesGrues = Array();
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  @ViewChild(PersonesTableComponent) personesTable: PersonesTableComponent;

  constructor(
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private i18n: I18n,
    private not: NotificationService,
    public thisDialogRef: MatDialogRef<PersonesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
  ) {
    this.filterData = new PersonaFilter('', '');
      if (data !== undefined) {
        this.dataDialog = data;
      }
  }

  ngOnInit() {
    /*this.personesTable.itemClicked.subscribe(secure_id => {
      this.update(secure_id);
    });*/
    this.personesTable.itemSelected.subscribe(codi => {
      this.thisDialogRef.close(codi);
    });

  }

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Persones'));
    }
  }
  create() {
    this.update(null);
  }

  update(secure_id: string) {
    const data: ComponentDialog = Builder.newComponentDialog(true, false, secure_id);
    const ref = this.dialog.open(PersonesEditorComponent, {
      width: '90%',
      closeOnNavigation: false,
      autoFocus: true,
      data: data
    });


    ref.afterClosed().subscribe(updated => {
      if (updated) { this.personesTable.load(); }
    });
  }

  refresh() {
    this.personesTable.load();
  }

  onFilterClicked() {
    const ref = this.dialog.open(PersonesFilterComponent, {
    width: '50%',
    closeOnNavigation: false,
    autoFocus: true,
    data: {
      'filter': this.personesTable.filter
    }
  });

  ref.componentInstance.notify.subscribe((filter: PersonaFilter) => {
    this.personesTable.filter.set(filter);
    this.personesTable.load();
  });
}

onRowSelected(somid) {
  this.update(somid);
}

generateExcel() {
  this.personesTable.generateExcel();
}

}
