import { Component, OnInit, Input, Output, EventEmitter, ViewContainerRef, ChangeDetectionStrategy } from '@angular/core';
import { TimePickerIntl } from 'path-shared/services/form/timepicker-intl';
import { OwlDateTimeIntl } from 'ng-pick-datetime';

export const MY_CUSTOM_FORMATS = {
  parseInput: 'DD/MM/YYYY HH:mm:ss',
  fullPickerInput: 'DD/MM/YYYY HH:mm:ss',
  datePickerInput: 'dd/MM/yyyy',
  timePickerInput: 'HH:mm:ss',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

@Component({
  selector: 'app-form-date-range-edit',
  templateUrl: './form-date-range-edit.component.html',
  styleUrls: ['./form-date-range-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {provide: OwlDateTimeIntl, useClass: TimePickerIntl}
  ]
})
export class DateRangeInputComponent implements OnInit {
  val: Date[];

  @Input() title: string;
  @Input() label1: string;
  @Input() label2: string;

  validVal = false;

  @Input() get value() {
    return this.val;
  } set value(val) {
    this.val = val;
    this.errorRequired();
    setTimeout(() => {
      this.valueChange.emit(this.val);
    }, 10);
  }
  @Output() valueChange = new EventEmitter();
  @Input() class: string;
  type: string;
  @Input() id: string;
  @Input() disabled: boolean;
  @Input() initialValue = undefined;
  @Input() isReadOnly = false;
  @Input() required: boolean;

  constructor(public cr: ViewContainerRef) {
    this.val = new Array<Date>();
    this.type = 'calendar';
    this.disabled = false;
    this.required = false;
  }

  ngOnInit() {
    if (this.initialValue !== undefined) {
      this.value = this.initialValue;
    }
  }

  errorRequired() {
    if ((this.required || <any> this.required === '') && ((this.value === null || this.value === undefined) || (this.value[0] === undefined || this.value[1] === undefined))) {
      this.validVal = false;
    } else {
      this.validVal = true;
    }
  }

}
