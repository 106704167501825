<app-toolbar>  
  <app-tool-button-permissions [permission]="this.auth.Pages.Estadistiques"></app-tool-button-permissions>
</app-toolbar>

<div class="row justify-content-around">
  <app-form-date-edit id="estadistiques-setmanals-data" (valueChange)="onDateChange()" class="col-4" i18n-label label="Data" [(value)]="dateSelected"></app-form-date-edit>
  <app-form-combo-edit (valueChange)="reload($event)" class="col-4" i18n-label label="Dispositiu" [(value)]="aparellSelected"
    [items]="aparells" valueField="value" textField="label"></app-form-combo-edit>
</div>

<div class="text-center">
  <h5>{{title}}</h5>
</div>

<div class="row" *ngIf="barChartLabels && barChartData">
  <div class="col-10">
    <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
      [legend]="barChartLegend" [chartType]="barChartType">
    </canvas>
  </div>
</div>