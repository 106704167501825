import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class PropostesEstatCarregatsFilter extends FilterData {

  public estat: string;
  public fechaInicio: string;
  public fechaInicioDisplay: Date;
  public fechaFin: string;
  public fechaFinDisplay: Date;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    
    this.estat = null;
    this.fechaInicio = null;
    this.fechaFin = null;

    this.labels['estat'] = this.i18n('Estat');
    this.labels['fechaInicio'] = this.i18n('Data inici');
    this.labels['fechaFin'] = this.i18n('Data fi');
  }
}
