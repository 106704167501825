import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Builder } from 'path-shared/models/builder';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { FormControl } from '@angular/forms';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { SeguretatGrupsTableComponent } from '../components/seguretat-grups-table/seguretat-grups-table.component';
import { SeguretatGrupsEditorComponent } from '../components/seguretat-grups-editor/seguretat-grups-editor.component';

@Component({
  selector: 'app-seguretat-grups-page',
  templateUrl: './seguretat-grups-page.component.html',
  styleUrls: ['./seguretat-grups-page.component.scss']
})
export class SeguretatGrupsPageComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  selected = new FormControl(0);
  // filter: TiquetsFilter = new TiquetsFilter('id', 'desc');
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  @ViewChild('table') table: SeguretatGrupsTableComponent;

  constructor(
    public authService: AuthorizationService,
    private not: NotificationService,
    private dialog: MatDialog,
    private i18n: I18n
  ) { }

  ngOnInit() {
    this.authService.CheckUserPermission(this.authService.Pages.SeguretatGrups);
  }

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Seguretat Grups'));
    }
  }

  itemClicked(secureId) {
    const ref = this.dialog.open(SeguretatGrupsEditorComponent, {
      width: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      data: secureId
    });

    ref.afterClosed().subscribe(updated => {
      if (updated) { this.table.load(); }
    });
  }

  create() {
    const ref = this.dialog.open(SeguretatGrupsEditorComponent, {
      width: '60%',
      closeOnNavigation: false,
      autoFocus: true,
      data: null
    });

    ref.afterClosed().subscribe(updated => {
      if (updated) { this.table.load(); }
    });
  }
  refresh() {

  }

  onFilterClicked() {

    }

  generateExcel() {

  }

}
