import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class BonosFilter extends FilterData {
  fechaInicio: Date;
  fechaFin: Date;
  matricula: string;
  tipo: string;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.matricula = '';
    this.fechaInicio = null;
    this.fechaFin = null;
    this.tipo = '';

    this.labels['matricula'] = this.i18n('Matrícula');
    this.labels['fechaInicio'] = this.i18n('Fecha Inicio');
    this.labels['fechaFin'] = this.i18n('Fecha fin');
    this.labels['tipo'] = this.i18n('Tipo');


    this.formatOutput['fechaInicio'] = 'shortDate';
    this.formatOutput['fechaFin'] = 'shortDate';

  }
}
