export enum NotificationType {
  PropostesPendents = 'PropostesPendents',
  EstatTramitacio = 'EstatTramitacio',
  EstatFitxers = 'EstatFitxers',
  Alarmes = 'Alarmes',
  AlarmesPendents = 'AlarmesPendents',
  AlarmesSistemaPendentsMoreInfo = 'AlarmesSistemaPendentsMoreInfo',
  AlarmesSmartphonesPendentsMoreInfo = 'AlarmesSmartphonesPendentsMoreInfo',
  AlarmesGpsPendents = 'AlarmesGpsPendents',
  TramitacioPendents = 'TramitacioPendents',
  PdasPendentsLlegir = 'PdasPendentsLlegir',
  NovesDadesGps = 'NovesDadesGps',
  TrackingPda = 'TrackingPda',
  TrackingGrua = 'TrackingGrua',
  PropostesGruaPendents = 'PropostesGruaPendents',
  EstatReserves = 'EstatReserves',
  DenunciasPolicia = 'DenunciasPolicia',
  DenunciasZonaAzul = 'DenunciasZonaAzul',
  PropuestasGruaAceptadas = 'PropuestasGruaAceptadas',
  PropuestasGruaPendientes = 'PropuestasGruaPendientes',
  PropuestasGruaRechazadas = 'PropuestasGruaRechazadas',
  PropuestasGruaRetiradas = 'PropuestasGruaRetiradas',
  TramitarTancament = 'TramitarTancament',
  ErrorConsultaParkeon = 'ErrorConsultaParkeon',
  LoadG5 = 'LoadG5',
  EjecutivaResultats = 'EjecutivaResultats',
  ProgressBar = 'ProgressBar',
  EstatAnulacioProcessada = 'EstatAnulacioProcessada',
  BOEC60Status = 'BOEC60Status',
  TramitacioC60Status = 'TramitacioC60Status',
  TramitacioNotificacionStatus= 'TramitacioNotificacionStatus',
  GtwinSync= 'GtwinSync',
  AlegacionsPendents = 'AlegacionsPendents'

}
