import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class AlarmaFilter extends FilterData {
  id: number | undefined;
  text: string;
  data: Date;
  idTipusAlarma: string;
  usuari: string;
  IdUsuariACK: string;
  matricula: string;
  tradesegur_id: string;
  data_ack: Date;
  dades: string;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.id = undefined;
    this.text = '';
    this.data = null;
    this.IdUsuariACK = null;
    this.idTipusAlarma = null;
    this.usuari = '';
    this.matricula = '';
    this.tradesegur_id = '';
    this.data_ack = null;
    this.dades = null;

    this.labels['id'] = this.i18n('Id');
    this.labels['text'] = this.i18n('Text');
    this.labels['data'] = this.i18n('Data');
    this.labels['idTipusAlarma'] = this.i18n('Tipus');
    this.labels['IdUsuariACK'] = this.i18n('Usuari');
    this.labels['usuari'] = this.i18n('Usuari');
    this.labels['matricula'] = this.i18n('Matricula');
    this.labels['tradesegur_id'] = this.i18n('TradesegurId');
    this.labels['data_ack'] = this.i18n('data_ack');
    this.labels['dades'] = this.i18n('Datos');

    this.formatOutput['data'] = 'shortDate';

  }
}
