import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class VehicleFilter extends FilterData {
    id_vehicles: string;
    matricula: string;
    marca: string;
    model: string;
    color: string;
    secure_id_persona: string;
    asseguranca: string;
    poliza: string;
    proposta: Date;
    requisitoria: boolean;
    observacions: string;
    origen: string;
    tipus_vehicle: string;
    SECURE_ID: string;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.id_vehicles = '';
    this.matricula = '';
    this.marca = '';
    this.model = '';
    this.color = '';
    this.secure_id_persona = '';
    this.proposta = null;
    this.asseguranca = '';
    this.poliza = '';
    this.observacions = '';
    this.origen = '';
    this.tipus_vehicle = '';
    this.requisitoria = null;
    this.SECURE_ID = '';

    this.labels['id'] = this.i18n('Id');
    this.labels['matricula'] = this.i18n('Matricula');
    this.labels['marca'] = this.i18n('Marca');
    this.labels['model'] = this.i18n('Model');
    this.labels['color'] = this.i18n('Color');
    this.labels['proposta'] = this.i18n('Proposta');
    this.labels['asseguranca'] = this.i18n('ASsegurança');
    this.labels['poliza'] = this.i18n('Polissa');
    this.labels['observacions'] = this.i18n('Observacions');
    this.labels['origen'] = this.i18n('Origen');
    this.labels['tipus_vehicle'] = this.i18n('Tipus vehicle');
    this.labels['requisitoria'] = this.i18n('Requisitoria');

  }
}
