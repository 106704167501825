<app-dialog-header label="Zona ocupación" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Editar" i18n-text (click)="this.dialogData.isReadOnly = false" color="has-color-green" icon="fa-edit" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Tramos) ||!this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Guardar" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Tramos)||this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Eliminar" i18n-text (click)="this.remove();" color="has-color-red" icon="fa-trash-alt" [disabled]="!this.auth.UserCanDelete(this.auth.Pages.Tramos) || this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="QR" i18n-text (click)="this.openQr()" color="has-color-blue" icon="fa-qrcode" 
  [disabled]="this.tramo=== undefined || this.tramo === null || this.tramo.Som_id === -1 "></app-tool-button>
  <app-tool-button [text]="this.closeButtonText" i18n-text (click)="this.cancel()" color="has-color-yellow" icon="fa-th-list"></app-tool-button>
</app-toolbar>
<app-dialog-body>
  <form #mainForm>
    <div class="row">
      <!--<app-form-text-edit class="col-sm-3" i18n-label label="Codi" [(value)]="this.tramoTipo.Som_id" required maxLength="3" number></app-form-text-edit>-->
      <!--<agm-drawing-manager class="col-sm-6"  ></agm-drawing-manager> -->

      <app-form-text-edit id="tramos-editor-descripcio" class="col-sm-4" i18n-label label="Descripcio" [(value)]="this.tramo.descripcion" required maxLength="30"></app-form-text-edit>
      <app-form-text-edit id="tramos-editor-numeroPlaces" class="col-sm-2" i18n-label label="Número places" [(value)]="this.tramo.numPlazas" number ></app-form-text-edit>
      <div class="col-sm-3">

        <app-autocomplete-combo #comboTipoTramo  i18n-label label="Zona" [options]="this.tiposTramosDictionary"
        (valorSeleccionado)="this.tipoTramoSeleccionado($event)" [valorAutocomplete]="this.valorAutoCompleteTiposTramos" [showColor]="true" [colorsKey]="this.dictColors" required="true" >
        </app-autocomplete-combo>
        <app-view-list-icon  (viewClick)="this.viewZona()" [hideList]="true"></app-view-list-icon>
      </div>
      
      <div class="col-sm-3">
        <app-autocomplete-combo #comboTemporada  i18n-label label="Temporada" [options]="this.temporadasDictionary"
        (valorSeleccionado)="this.temporadaSeleccionado($event)" [valorAutocomplete]="this.valorAutoCompleteTemporadas"  required="true" >
        </app-autocomplete-combo>
      </div>
    </div>
    <div class="row " style="height: 70vh;width:80%;padding-left: 15px;padding-right: 15px;">
      <div class="alert alert-secondary" role="alert">
      <span i18n>Faci click al mapa per a dibuixar i arrastrar per a moure'l.</span>
      </div>
      <div class="alert " role="alert"  *ngIf="this.selectedShape!== undefined && this.selectedShape !== null"><i  *ngIf="this.selectedShape!== undefined && this.selectedShape !== null" (click)="this.deleteSelectedShape()" class="cursor fa fa-trash-alt btn btn-outline-danger btn-sm"></i></div>
      <agm-map [latitude]="currentLocation.latitude" [longitude]="currentLocation.longitude" [styles]="styleMap" [zoom]="18" (mapReady)="onMapReady($event)">
        <agm-polygon *ngFor="let polygonFons of allTramos" [paths]="polygonFons.poligon"  [strokeColor]="polygonFons.strokeColor"> 
      </agm-polygon>
        
        <!--<agm-polygon  
        [editable]="true" 
        
        (polyDblClick)="polyDblClick($event)" (polyDragStart)="polyDragStart($event)" 
        (polyDragEnd)="polyDragEnd($event,this.tramo.poligon)" (polyDrag)="polyDrag($event)" 
        [polyDraggable]="false" [paths]="this.tramo.poligon" (polyClick)="polyclick($event)">
      </agm-polygon>-->
       <!-- <agm-drawing-manager  [drawingMode]="'circle'" [circleOptions]="{fillColor:'red', radius: 150}"></agm-drawing-manager> -->
      </agm-map>
      
    </div>
    
  </form>
</app-dialog-body>
