import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InstitucionsService } from 'path-shared/services/institucions/institucions.service';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-image-displayer',
  templateUrl: './image-displayer.component.html',
  styleUrls: ['./image-displayer.component.scss']
})
export class ImageDisplayerComponent implements OnInit {
  IdImage:String;
  urlInstit:String;
  institutionService: InstitucionsService;
  source;

  constructor(private route: ActivatedRoute,private sanitizer:DomSanitizer,private http: HttpClient) { 
  }

  ngOnInit() {
    this.route.params.subscribe(params=> {
      console.log(params['id']);
      this.IdImage=params['id'];
      this.loadImage()
    });
  
  }

  loadImage(){
    fetch(`${config.urls['apiUrl'].replace('{hostname}', window.location.hostname)}${api.endpoints['urlGetImageByRefInteg']}/${this.IdImage}`)
    .then((response)=>response.blob())
    .then((myBlob)=>{
        const objectUrl= URL.createObjectURL(myBlob);
        this.source=this.sanitizer.bypassSecurityTrustUrl(objectUrl);
    }).catch((error)=>{
      console.log(error);
    });
  }
}
