import { Component, OnInit, ViewChild, Input, SimpleChanges, AfterViewInit, OnChanges, EventEmitter, Output } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { TiquetsFilter } from '../../models/tiquets-filter';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { Tiquets } from '../../models/tiquets.model';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { Builder } from 'path-shared/models/builder';
import { TiquetsAnulacionesService } from '../../services/tiquets-anulaciones.service';
import { TiquetsFilterService } from '../../services/tiquets-filter.service';

@Component({
  selector: 'app-tiquets-anulaciones-table',
  templateUrl: './tiquets-anulaciones-table.component.html',
  styleUrls: ['./tiquets-anulaciones-table.component.scss']
})
export class TiquetsAnulacionesTableComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  currentFilter: TiquetsFilter = new TiquetsFilter('id', 'desc'); // Filtro concreto para esta tabla.
  _sharedFilter: TiquetsFilter; // Filtro compartido por todas las tablas (mismos paramertos de filtrado en todas).
  @Input() get sharedFilter() {
    return this._sharedFilter;
  } set sharedFilter(val) {
    if (val !== undefined && val !== null) {
      this._sharedFilter = val;

      this.currentFilter.id = val.id;
      this.currentFilter.matricula = val.matricula;
      this.currentFilter.email = val.email;
      this.currentFilter.fechaTicketInicio = val.fechaTicketInicio;
      this.currentFilter.fechaTicketFin = val.fechaTicketFin;
      this.currentFilter.fechaBancoInicio = val.fechaBancoInicio;
      this.currentFilter.fechaBancoFin = val.fechaBancoFin;
      this.currentFilter.zonaSecureId = val.zonaSecureId;
    }
  }

  dataSource: CustomDataSource<Tiquets, TiquetsFilter>;
  highlightedRows = [];

  @Output() currentCount: EventEmitter<number>;

  displayedColumns = ['tiquet', 'data', 'client', 'email', 'matricula', 'zona', 'importTiquet'];

  constructor(
    private tiquetsAnulacionesService: TiquetsAnulacionesService,
    private dialog: MatDialog,
    private i18n: I18n
  ) {
    this.currentCount = new EventEmitter<number>();
  }

  //#region CICLO DE VIDA

  ngOnInit() {
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<Tiquets, TiquetsFilter>(this.paginator, new TiquetsFilterService(), 'urlGetAnulacions', 'urlGetAnulacionsCount');

    this.dataSource.countSubject.subscribe(newCount => {
      this.currentCount.emit(newCount);
    });
  }

  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.load();
    });

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(tap(() => this.currentFilter.updated()))
    .subscribe();

    this.load();
  }
  //#endregion

  //#region EVENTOS
  paginatorChanged(value: any) {
    // Actualizamos la informacion al detectar un cambio en la paginacion.
    this.load();
  }

  updateFilterFromParent(val) {
    // Cuando el componente parent nos pasa un nuevo filtro compartido, actualizamos.
    this.sharedFilter = val;
    this.load();
  }

  //#endregion

  //#region OTHERS
  load() {
    this.currentFilter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.currentFilter, this.i18n('No s\'han trobat registres per anul·lacions'));
  }

  generateExcel(): void {
    let filter = this.currentFilter;

    this.dialog.open(DownloadExcelDialogComponent, {
        closeOnNavigation: false,
        autoFocus: true,
        data: Builder.newDownloadExcelDialogData (this.tiquetsAnulacionesService, filter, this.i18n('Anulacions'), this.sort.direction, this.sort.active)
    });
  }
  //#endregion

}
