import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { PaginationFilter } from 'path-shared/models/pagination.filter';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { Reflection } from 'path-shared/services/reflection/reflection';
import { TramitarExOlotFilter } from '../models/TramitarExOlot-filter.model';

const defaultSortParam = 'EXP';
const defaultSortDirection = 'asc';

@Injectable()
export class TramitarExOlotFilterService extends CustomFilterService {
  public createFilterParams(filter: TramitarExOlotFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {

    return new HttpParams()
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }

  getTristateValue(value) {
    if (value === null) {
      return '';
    } else {
      return value.toString();
    }
  }
}


