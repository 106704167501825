<app-dialog-header label="Proposta grua" *ngIf="this.isDialog"i18n-label></app-dialog-header>
<app-toolbar *ngIf="!this.isProposta && (this.auth.UserCanWrite(this.auth.Pages.TramitadesGrua)  || this.auth.UserCanDelete(this.auth.Pages.TramitadesGrua))">
  <app-tool-button text="Editar" i18n-text (click)="this.isEditMode = true" color="has-color-green" icon="fa-edit" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.TramitadesGrua) || this.isEditMode"></app-tool-button>
  <app-tool-button text="Guardar" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.TramitadesGrua) || !this.isEditMode"></app-tool-button>
  <app-tool-button [text]="this.closeButtonText" i18n-text (click)="this.cancel()" color="has-color-yellow" icon="fa-th-list"></app-tool-button>
  <app-tool-button text="Eliminar" i18n-text (click)="this.remove();" color="has-color-red" icon="fa-trash-alt" [disabled]="!this.auth.UserCanDelete(this.auth.Pages.TramitadesGrua)"></app-tool-button>
</app-toolbar>
<div class="row">
  <div class="col-sm-6" style="min-height: 80vh; padding-left: 5px;">
    <div class="row  mb-3" style="height:50%; width: 100%;">
      <div class="col-xl-12">
        <app-photo-box-gallery 
        #pbg  style="width: 100%; z-index: 5;" 
        [numDenuncia]="this.proposta.codi" 
        [imatges]="this.proposta?.IMATGES" 
        height="auto" 
        [botoMatricula]="false" 
        [allowSelect]="false"
        [magnifierRight]="-50" [magnifierTop]="50"></app-photo-box-gallery>
      </div>
    </div>
    <div class="row " style="height:50%; width: 100%;padding-left: 15px;padding-right: 15px;">
      <agm-map [latitude]="proposta.latitud" [longitude]="proposta.longitud" [zoom]="18">
        <agm-marker 
        [latitude]="proposta.latitud"
        [longitude]="proposta.longitud"
        >        
          <agm-info-window>
            <strong>{{proposta.idVehiclesFK}}</strong>
          </agm-info-window> 
        </agm-marker> 
      </agm-map>
    </div>
  </div>
  <div class="col-xl-6">
    <div class="row">
      <!--<app-form-combo-edit class="col-xl-3" i18n-label label="Legislació" [items]="this.tipusLegislacions" [(value)]="this.infraccio.legislacio" valueField="codi" textField="descripcio" [isReadOnly]="this.dialogData.isReadOnly" required></app-form-combo-edit>-->
     
      <div class="col-xl-6" >
        <app-form-text-edit id="pendents-id" i18n-label label="Id retirada" [(value)]="this.proposta.codi"  isReadOnly="true" ></app-form-text-edit>
        <app-view-list-icon *ngIf="!this.isProposta && this.proposta.implicaDenuncia" (viewClick)="veureDenuncia()" hideView="false" hideList="true"></app-view-list-icon>
      </div> 
      
      <app-form-text-edit id="pendents-data" class="col-xl-6" i18n-label label="Data" [(value)]="this.sData"  isReadOnly="true" ></app-form-text-edit>

      <app-form-text-edit id="pendents-tecnic" class="col-xl-8" i18n-label label="Tècnic" [(value)]="this.proposta.tecnicGrua"  isReadOnly="true" ></app-form-text-edit>
      <app-form-text-edit id="pendents-numPDA" class="col-xl-4" i18n-label label="PDA" [(value)]="this.proposta.numPDA"  isReadOnly="true" ></app-form-text-edit>
      
      <div class="col-xl-6" >
        <app-form-text-edit id="pendents-matricula" i18n-label label="Matrícula" [(value)]="this.proposta.idVehiclesFK" (change)="this.consultarVehicle()" [isReadOnly]="!this.isProposta  && (!this.isProposta && !this.isEditMode)" required></app-form-text-edit>
        <app-view-list-icon (listClick)="seleccionarVehicle()" [hideView]="true" [hideList]="!this.isProposta  && (!this.isProposta && !this.isEditMode)" ></app-view-list-icon>
      </div> 
      <!-- <app-form-text-edit class="col-xl-6" i18n-label label="Matricula" [(value)]="this.proposta.idVehiclesFK"  [isReadOnly]="true" ></app-form-text-edit> -->
      <app-form-text-edit id="pendents-marca" class="col-xl-6" i18n-label label="Marca"  [(value)]="this.proposta.marca" [isReadOnly]="!this.isProposta && (!this.isProposta && !this.isEditMode) " ></app-form-text-edit>

      <app-form-text-edit id="pendents-modelo" class="col-xl-6" i18n-label label="Modelo"   [(value)]="this.proposta.modelo" [isReadOnly]="!this.isProposta  && (!this.isProposta && !this.isEditMode)" ></app-form-text-edit>
      <app-form-text-edit id="pendents-color" class="col-xl-6" i18n-label label="Color" [(value)]="this.proposta.color"   [isReadOnly]="!this.isProposta  && (!this.isProposta && !this.isEditMode)" ></app-form-text-edit>

      <app-form-text-edit id="pendents-carrer" class="col-xl-6" i18n-label label="Carrer"   [(value)]="this.proposta.nombreCalle" [isReadOnly]="!this.isProposta  && (!this.isProposta && !this.isEditMode)" ></app-form-text-edit>
      <app-form-text-edit id="pendents-numero" class="col-xl-6" i18n-label label="Número" [(value)]="this.proposta.numCarrer"   [isReadOnly]="!this.isProposta  && (!this.isProposta && !this.isEditMode)" ></app-form-text-edit>
      
      <div class="col-xl-12" >
        <!-- <app-combo-box-input-autocomplete #comboAliasGrua class="center-horizontal" i18n-label label="Alias Grua" [options]="this.aliasGruaDictionary"
        (valorSeleccionado)="this.aliasGruaSeleccionado($event)" [valorAutocomplete]="this.valorAutoCompleteAliasGrua"  required="false" [disableComponent]="!this.isProposta  && (!this.isProposta && !this.isEditMode)">
        </app-combo-box-input-autocomplete> -->
        <app-form-combo-edit required class="center-horizontal" i18n-label label="Alias Grua" [items]="this.aliasGruaDictionary" valueField="key" 
        [(value)]="this.proposta.aliasGrua" [isReadOnly]="!this.isProposta  && (!this.isProposta && !this.isEditMode)" textField="value" 
        (valueChange)="aliasGruaSeleccionado($event)"></app-form-combo-edit>
    </div>  
      <!--<app-form-combo-edit class="col-xl-12" i18n-label label="Alias Grua" [items]="this.aliasGruaList" [(value)]="this.proposta.secureId" valueField="secureId" textField="descripcio"  required></app-form-combo-edit>-->
       
      <div class="col-xl-12" >
        <!-- <app-combo-box-input-autocomplete #comboInfraccions class="center-horizontal" i18n-label label="Infracció" [options]="this.infraccionsDictionary" [disableComponent]="!this.isProposta  && (!this.isProposta && !this.isEditMode)"
        (valorSeleccionado)="this.infraccionsSeleccionado($event)" [valorAutocomplete]="this.valorAutoCompleteInfraccions"  required="false" >
        </app-combo-box-input-autocomplete> -->
        <app-form-combo-edit class="center-horizontal" i18n-label label="Infracció" [items]="this.infraccionsDictionary" [(value)]="this.proposta.idInfraccioFK" 
        valueField="key" textField="value" (valueChange)="setAliasGrua()" [isReadOnly]="!this.isProposta  && (!this.isProposta && !this.isEditMode)"></app-form-combo-edit>
    </div> 
      <!--<app-form-combo-edit class="col-xl-12" i18n-label label="Infracció" [items]="this.infraccionsList" [(value)]="this.proposta.secureId" valueField="secureId" textField="descCurta"  required></app-form-combo-edit>-->
    <div class="col-xl-6" >
      <!-- <app-combo-box-input-autocomplete #comboAgent class="center-horizontal" i18n-label label="Agent" [options]="this.agentsDictionary" [disableComponent]="!this.isProposta  && (!this.isProposta && !this.isEditMode)"
      (valorSeleccionado)="this.agentSeleccionado($event)" [valorAutocomplete]="this.valorAutoCompleteAgent"  required="false" >
      </app-combo-box-input-autocomplete> -->
      <app-form-combo-edit class="center-horizontal" i18n-label label="Agent" [items]="this.agentsDictionary" [(value)]="this.proposta.idAgentFk" valueField="key"
      textField="value" [isReadOnly]="!this.isProposta  && (!this.isProposta && !this.isEditMode)"></app-form-combo-edit>
    </div> 
      <!--<app-form-combo-edit class="col-xl-12" i18n-label label="Agent" [items]="this.aliasGruaList" [(value)]="this.proposta.secureId" valueField="codi" textField="descripcio"  ></app-form-combo-edit>-->
      <div class="col-xl-6" >
        <!-- <app-combo-box-input-autocomplete #comboMotiuDesestimar class="center-horizontal" i18n-label label="Motiu desestimar" [options]="this.motiusDesestimarDictionary" [disableComponent]="!this.isProposta  && (!this.isProposta && !this.isEditMode)"
        (valorSeleccionado)="this.motiuDesestimarSeleccionado($event)" [valorAutocomplete]="this.valorAutoCompleteMotiusDesestimar"  required="false" >
        </app-combo-box-input-autocomplete> -->
        <app-form-combo-edit class="center-horizontal" i18n-label label="Motiu desestimar" [items]="this.motiusDesestimarDictionary" 
        [(value)]="this.proposta.idMotiuDesestimada_fk" valueField="key" textField="value" [isReadOnly]="!this.isProposta  && (!this.isProposta && !this.isEditMode)"></app-form-combo-edit>
      </div> 
      <!--<app-form-combo-edit class="col-xl-12" i18n-label label="Motiu desestimar" [items]="this.aliasGruaList" [(value)]="this.proposta.secureId" valueField="codi" textField="descripcio"  ></app-form-combo-edit>-->
      
      <mat-checkbox class="col-xl-6" [(ngModel)]="this.proposta.implicaDenuncia" [checked]="this.proposta.implicaDenuncia" i18n  [disabled]="!this.isProposta  && (!this.isProposta && !this.isEditMode)">Crear denúncia &nbsp;</mat-checkbox>
      <mat-checkbox class="col-xl-6" [(ngModel)]="this.proposta.sinCargo" [checked]="this.proposta.sinCargo" i18n [disabled]="!this.isProposta  && (!this.isProposta && !this.isEditMode)">Sense càrrec &nbsp;</mat-checkbox>
     
      <!--<textarea cols="12"  matInput [(ngModel)]="this.proposta.observacions" ></textarea>-->
      <app-form-text-area class="col-xl-12" i18n-label label="Observacions" [(value)]="this.proposta.observacions" maxLength="5000" [disabled]="!this.isProposta  && (!this.isProposta && !this.isEditMode)"></app-form-text-area>
      <!--<textarea matInput ng-model="this.proposta.observacions" ></textarea>-->
      <div class="row proposta-buttons" *ngIf="this.isProposta">
        <div class="col-md-8 offset-md-2 d-flex">
          <span >
            <button [disabled]="disabled()" 
              class="btn btn-danger button-not-allowed" type="button" (click)="this.rejectProposta()">           
            <i class="fa fa-times-circle"></i>&nbsp;<span i18n>Denegar</span>
            </button>
          </span>            
          <span >            
            <button [disabled]="disabledButtonAccept()" 
              class="btn btn-success offset-md-1 button-not-allowed" type="button" (click)="this.acceptProposta()">
            <i class="fa fa-check-circle"></i>&nbsp;<span i18n>Acceptar</span>
            </button>
          </span>
        </div>
      </div>
      <div class="row proposta-buttons" *ngIf="!this.isProposta">
        <div class="col-md-8 offset-md-2 d-flex">
          <i *ngIf="this.proposta.valida" [class]="'fas fa-check-circle has-color-green fa-3x'" ></i>
          <i *ngIf="!this.proposta.valida" [class]="'far fa-times-circle has-color-red fa-3x'" ></i>
        </div>
      </div>
    </div>
  </div>
</div>