import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatTable, MatTableDataSource } from '@angular/material';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { ColorsService } from 'app/sections/configuracio/colors/services/colors.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { ColorsEditorComponent } from 'app/sections/configuracio/colors/components/colors-editor/colors-editor.component';
import { FormService } from 'path-shared/services/form/form.service';
import { TarifesService } from '../../services/tarifes.service';
import { Builder } from 'path-shared/models/builder';
import { Tarifa } from '../../models/tarifa.model';
import { TarifaTimeFrame } from '../../models/tarifaTimeFrame.model';

@Component({
  selector: 'app-tarifes-editor',
  templateUrl: './tarifes-editor.component.html',
  styleUrls: ['./tarifes-editor.component.scss']
})
export class TarifesEditorComponent implements OnInit {

  secure_id: string;
  tarifa: Tarifa;
  dialogData: ComponentDialog;
  closeButtonText: string;
  zones: null;
  residents: null;
  tipusTiquets: null;
  dies: {'DILLUNS', 'DIMARTS', 'DIMECRES', 'DIJOUS', 'DIVENDRES', 'DISSABTE', 'DIUMENGE'};
  tipus = [{'id': 0, 'descripcio': this.i18n('Fixe')}, {'id': 1 , 'descripcio': this.i18n('Variable')}, {'id': 2, 'descripcio': this.i18n('Tar. Plana')}];

  displayedColumns = ['tipus', 'minInici', 'minFi', 'preuMaxFixe', 'incrementMin', 'preuPerIncrement'];
  publicarAvailable = false;
  dataSource = new MatTableDataSource<TarifaTimeFrame>();
  @ViewChild('mainForm') private mainForm: ElementRef;

  constructor(
    private dialog: MatDialog,
    private confirmationDialogService: ConfirmationDialogService,
    public auth: AuthorizationService,
    private tarifesService: TarifesService,
    private i18n: I18n,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    public thisDialogRef: MatDialogRef<TarifesEditorComponent>,
    private formService: FormService,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
    ) {

      this.tarifa = Builder.newTarifa();
      this.dialogData = data;
      this.secure_id = this.dialogData.id;
      if (this.dialogData.isReadOnly) {
        this.closeButtonText = this.i18n('Tancar');

      } else {
        this.closeButtonText = this.i18n('Llista');
        this.publicarAvailable = true;
      }
   }

  ngOnInit() {

    this.tarifesService.GetData().subscribe(res => {
      this.zones = res.zones;
      this.residents = res.residents;
      this.tipusTiquets = res.tipusTiquets;

    });

    if (this.secure_id !== null) {
      this.tarifesService.getTarifa(this.secure_id).subscribe(data => {
        this.tarifa = data;
        this.dataSource = new MatTableDataSource(this.tarifa.tarifaTimeFrames);
        if (this.tarifa.tarifaTimeFrames.length === 0 ) {
          this.novaFila(0);
        }
      });
    } else {
      this.novaFila(0);
    }

  }

  save() {
    if (this.formService.allFieldsValid(this.mainForm)) {
      if (this.ValidarHoras() === false) {
        this.toastr.warning(this.i18n('Revisa els horaris!'));
        return;
      }
      this.tarifesService.setTarifa(this.tarifa).subscribe(result => {
        this.toastr.info(this.i18n('Tarifa guardada correctament'));
        this.thisDialogRef.close(true);
      });
    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
    }
  }

  ValidarHoras() {
    // Dilluns
    if ((this.tarifa.desde1 > this.tarifa.iniciPausa1 && this.tarifa.iniciPausa1 !== '00:00') || (this.tarifa.desde1 > this.tarifa.fiPausa1 && this.tarifa.fiPausa1 !== '00:00') ||
     this.tarifa.desde1 > this.tarifa.hasta1 || (this.tarifa.iniciPausa1 > this.tarifa.fiPausa1 && this.tarifa.fiPausa1 !== '00:00' && this.tarifa.iniciPausa1 !== '00:00') ||
     (this.tarifa.iniciPausa1 > this.tarifa.hasta1 && this.tarifa.iniciPausa1 !== '00:00') || (this.tarifa.fiPausa1 > this.tarifa.hasta1 && this.tarifa.fiPausa1 !== '00:00')) {
        return false;
      }

      // Dimarts
    if ((this.tarifa.desde2 > this.tarifa.iniciPausa2 && this.tarifa.iniciPausa2 !== '00:00') || (this.tarifa.desde2 > this.tarifa.fiPausa2 && this.tarifa.fiPausa2 !== '00:00') ||
    this.tarifa.desde2 > this.tarifa.hasta2 || (this.tarifa.iniciPausa2 > this.tarifa.fiPausa2 && this.tarifa.fiPausa2 !== '00:00' && this.tarifa.iniciPausa2 !== '00:00') ||
    (this.tarifa.iniciPausa2 > this.tarifa.hasta2 && this.tarifa.iniciPausa2 !== '00:00') || (this.tarifa.fiPausa2 > this.tarifa.hasta2 && this.tarifa.fiPausa2 !== '00:00')) {
       return false;
     }

     // Dimecres
    if ((this.tarifa.desde3 > this.tarifa.iniciPausa3 && this.tarifa.iniciPausa3 !== '00:00') || (this.tarifa.desde3 > this.tarifa.fiPausa3 && this.tarifa.fiPausa3 !== '00:00') ||
    this.tarifa.desde3 > this.tarifa.hasta3 || (this.tarifa.iniciPausa3 > this.tarifa.fiPausa3 && this.tarifa.fiPausa3 !== '00:00' && this.tarifa.iniciPausa3 !== '00:00') ||
    (this.tarifa.iniciPausa3 > this.tarifa.hasta3 && this.tarifa.iniciPausa3 !== '00:00') || (this.tarifa.fiPausa3 > this.tarifa.hasta3 && this.tarifa.fiPausa3 !== '00:00')) {
       return false;
     }

     // Dijous
    if ((this.tarifa.desde4 > this.tarifa.iniciPausa4 && this.tarifa.iniciPausa4 !== '00:00') || (this.tarifa.desde4 > this.tarifa.fiPausa4 && this.tarifa.fiPausa4 !== '00:00') ||
    this.tarifa.desde4 > this.tarifa.hasta4 || (this.tarifa.iniciPausa4 > this.tarifa.fiPausa4 && this.tarifa.fiPausa4 !== '00:00' && this.tarifa.iniciPausa4 !== '00:00') ||
    (this.tarifa.iniciPausa4 > this.tarifa.hasta4 && this.tarifa.iniciPausa4 !== '00:00') || (this.tarifa.fiPausa4 > this.tarifa.hasta4 && this.tarifa.fiPausa4 !== '00:00')) {
       return false;
     }

     // Divendres
    if ((this.tarifa.desde5 > this.tarifa.iniciPausa5 && this.tarifa.iniciPausa5 !== '00:00') || (this.tarifa.desde5 > this.tarifa.fiPausa5 && this.tarifa.fiPausa5 !== '00:00') ||
    this.tarifa.desde5 > this.tarifa.hasta5 || (this.tarifa.iniciPausa5 > this.tarifa.fiPausa5 && this.tarifa.fiPausa5 !== '00:00' && this.tarifa.iniciPausa5 !== '00:00') ||
    (this.tarifa.iniciPausa5 > this.tarifa.hasta5 && this.tarifa.iniciPausa5 !== '00:00') || (this.tarifa.fiPausa5 > this.tarifa.hasta5 && this.tarifa.fiPausa5 !== '00:00')) {
       return false;
     }

     // Dissabte
    if ((this.tarifa.desde6 > this.tarifa.iniciPausa6 && this.tarifa.iniciPausa6 !== '00:00') || (this.tarifa.desde6 > this.tarifa.fiPausa6 && this.tarifa.fiPausa6 !== '00:00') ||
    this.tarifa.desde6 > this.tarifa.hasta6 || (this.tarifa.iniciPausa6 > this.tarifa.fiPausa6 && this.tarifa.fiPausa6 !== '00:00' && this.tarifa.iniciPausa6 !== '00:00') ||
    (this.tarifa.iniciPausa6 > this.tarifa.hasta6 && this.tarifa.iniciPausa6 !== '00:00') || (this.tarifa.fiPausa6 > this.tarifa.hasta6 && this.tarifa.fiPausa6 !== '00:00')) {
       return false;
     }

     // Diumenge
    if ((this.tarifa.desde7 > this.tarifa.iniciPausa7 && this.tarifa.iniciPausa7 !== '00:00') || (this.tarifa.desde7 > this.tarifa.fiPausa7 && this.tarifa.fiPausa7 !== '00:00') ||
    this.tarifa.desde7 > this.tarifa.hasta7 || (this.tarifa.iniciPausa7 > this.tarifa.fiPausa7 && this.tarifa.fiPausa7 !== '00:00' && this.tarifa.iniciPausa7 !== '00:00') ||
    (this.tarifa.iniciPausa7 > this.tarifa.hasta7 && this.tarifa.iniciPausa7 !== '00:00') || (this.tarifa.fiPausa7 > this.tarifa.hasta7 && this.tarifa.fiPausa7 !== '00:00')) {
       return false;
     }

  }

  cancel() {
    this.thisDialogRef.close(false);
  }

  remove() {
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol esborrar la tarifa?'))
    .then((confirmed) => {
        if (confirmed) {
          this.tarifesService.removeTarifa(this.secure_id).subscribe(result => {
            this.toastr.success(this.i18n('Tarifa esborrada correctament'));
            this.dialog.closeAll();
            this.thisDialogRef.close(true);
          });
        }
      });
  }

  novaFila(event) {
    if (this.tarifa.tarifaTimeFrames.filter(x => x.somId === null && x.tipus === null).length === 0 ||
    (this.tarifa.tarifaTimeFrames.filter(x => x.somId === null && x.tipus !== null).length === 1 && event !== null)) {
      // no existen rows vacías aún
      this.tarifa.tarifaTimeFrames.push(Builder.newTarifaTimeFrame());
      this.dataSource = new MatTableDataSource(this.tarifa.tarifaTimeFrames);
    }

  }

  removeTimeFrame(row: TarifaTimeFrame) {

    const index = this.tarifa.tarifaTimeFrames.indexOf(row);
    if (index > -1) {
      this.tarifa.tarifaTimeFrames.splice(index, 1);
      this.dataSource = new MatTableDataSource(this.tarifa.tarifaTimeFrames);
    }
    if (row.secureID !== undefined) {
      this.tarifesService.removeTimeFrame(row.secureID).subscribe();
    }


  }


}
