import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { MotiusNoNotificacioFilter } from '../models/motius-notificacio-filter';
import { MotiusNoNotificacioFilterService } from './motius-notificacio-filter.service';
import { MotiuNoNotificacio } from '../models/motius-notificacio.model';

const defaultSortParam = 'id';
const defaultSortDirection = 'desc';

@Injectable()
export class MotiusNoNotificacioService {
  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {}

  setMotiuNoNotificacio(motiuNoNotificacio: MotiuNoNotificacio): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetMotiuNoNotificacio'], motiuNoNotificacio);
    }
  }

  removeMotiuNoNotificacio(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteMotiuNoNotificacio'].replace('{secure_id}', secure_id), null);
    }
  }

  getMotiuNoNotificacio(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetMotiuNoNotificacio'].replace('{secure_id}', secure_id), null);
    }
  }

  getAllForCombo(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAllMotiuNoNotificacio'], null);
    }
  }

  generateExcel(filter: MotiusNoNotificacioFilter, sortDir: string, sortField: string): Observable<any> {
    const start = 0;
    const pageSize = 1000;

    sortField = sortField === undefined ? defaultSortParam : sortField;
    sortDir = sortDir === '' ? defaultSortDirection : sortDir;
    const params = new MotiusNoNotificacioFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

    if (this.authGuard.canActivate()) {
      return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlMotiusNoNotificacioExcel'],  params );
    }
  }
}
