<app-dialog-header [label]="this.getTitle()"></app-dialog-header>
<app-dialog-body>
  <app-toolbar>
    <app-tool-button text="Guardar" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save" [disabled]="this.disabledSaveButton()"></app-tool-button>
    <app-tool-button text="Tancar" i18n-text (click)="this.cancel()" color="has-color-red" icon="fa-times"></app-tool-button>
  </app-toolbar>
  <form #mainForm>
    <div class="row">
      <app-form-combo-edit class="col-sm-4" i18n-label label="Carrer" [items]="this.carrers" [(value)]="this.data.ID_LLOC" valueField="id" textField="nomCarrer" required></app-form-combo-edit>
      <app-form-text-edit id="propostes-lloc-editor-numero" class="col-sm-4 offset-sm-1" i18n-label label="Número" [(value)]="this.data.NUMERO" maxLength="5" required></app-form-text-edit>
    </div>
  </form>

  <div class="table-sdw is-fixed" *ngIf="ultimesAdreces && ultimesAdreces.length > 0">

    <h4 i18n>Últims carrers seleccionats</h4>

    <mat-table #table matSort aria-label="Elements" [dataSource]="ultimesAdreces" matSortActive="Exp" matSortDirection="asc" class="is-header-sticky">
  
      <ng-container matColumnDef="carrer">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Carrer</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.carrer.nomCarrer}}</mat-cell>
      </ng-container>
  
      <ng-container matColumnDef="numero">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Número</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.numero}}</mat-cell>
      </ng-container>
  
      <ng-container matColumnDef="seleccionar">
          <mat-header-cell *matHeaderCellDef [style.width]="'50px'" i18n>Seleccionar</mat-header-cell>
          <mat-cell *matCellDef="let row; let i = index" class="text-center"><a (click)="seleccionarAdreca(row)" title="Editar">
              <i class="fa fa-edit">
          </i></a></mat-cell>
        </ng-container>
  
  
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  </div>
</app-dialog-body>
