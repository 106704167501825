import { TramosFilterService } from './tramos-filter.service';
import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { Tramo } from '../models/tramo.model';
import { TramosFilter } from '../models/tramo-filter';

const defaultSortParam = 'id';
const defaultSortDirection = 'desc';

@Injectable()
export class TramosService {
  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {}

  setTramo(tv: Tramo): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetTramo'], tv);
    }
  }

  removeTramo(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteTramo'].replace('{secure_id}', secure_id), null);
    }
  }

  getTramo(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetTramo'].replace('{secure_id}', secure_id), null);
    }
  }

  getAllTramos():Observable<any>{
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAllTramos'], null);
    }
  }
  getAllTramosByTemporada(secure_id):Observable<any>{
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAllTramosByTemporada'].replace('{secure_id}', secure_id), null);
    }
  }

  generateExcel(filter: TramosFilter, sortDir: string, sortField: string): Observable<any> {
    const start = 0;
    const pageSize = 1000;

    sortField = sortField === undefined ? defaultSortParam : sortField;
    sortDir = sortDir === '' ? defaultSortDirection : sortDir;
    const params = new TramosFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

    if (this.authGuard.canActivate()) {
      return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlTramosExcel'],  params );
    }
  }

  publicarAparcare():Observable<any>{
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetPublicarTramos'], null);
    }
  }
}
