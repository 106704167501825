import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { Ajuda } from '../models/ajuda.model';

@Injectable()
export class AjudaService {
  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {}

  // getAllTerminals(): Observable<any> {
  //   if (this.authGuard.canActivate()) {
  //     return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetTerminals'],  null);
  //   }
  // }

  // getTerminalsChat(terminals: string[], ultimIdChat?: string): Observable<any> {
  //   if (this.authGuard.canActivate()) {
  //     return this.http.post(config.urls['apiUrl'] + api.endpoints['urlGetTerminalsChat'].replace('{ultimIdChat}', ultimIdChat), terminals);
  //   }
  // }

  sendHelpEmail(emailInfo: Ajuda): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSendHelpEmail'], emailInfo);
    }
  }
}
