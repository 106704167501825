<app-dialog-header label="{{ this.i18n('Permisos') + '&nbsp;' + this.permission.Name }}" i18n-label icon="fa fa-lock" iconClass="has-color-orange"></app-dialog-header>
  <app-toolbar>
      <app-tool-button text="Afegir" i18n-text (click)="addPermission()" color="has-color-aqua" icon="fa-plus-circle" [disabled]="this.disabled()"></app-tool-button>
      <app-tool-button text="Tancar" i18n-text (click)="dismiss()" color="has-color-orange" icon="fa-sign-out-alt"></app-tool-button>
      <app-tool-button text="Auditoria" i18n-text (click)="openAuditoriaDialog()" color="has-color-green" icon="fa-sign-out-alt"></app-tool-button>

      <app-form-check-edit class="auditoriaCheck" [disabled]="this.auditoriaActivada" label="Activar auditoria" i18n-label (click)="ToggleAuditoriaActivada()" [(value)]="this.auditoriaActivada"></app-form-check-edit>
    </app-toolbar>

<app-dialog-body>
  <table class="table table-sdw">
    <thead>
    <tr>
      <th align="center" scope="col" i18n>Assignat</th>
      <th align="center" scope="col" i18n>Llegir</th>
      <th align="center" scope="col" i18n>Crear</th>
      <th align="center" scope="col" i18n>Modificar</th>
      <th align="center" scope="col" i18n>Esborrar</th>
      <th align="center" scope="col" i18n></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let permis of this.permissionItems" class="has-iconset">
      <td align="center" >{{ permis.RoleName }}</td>
      <td align="center" ><app-permission-status [status]="permis.Read"></app-permission-status></td>
      <td align="center" ><app-permission-status [status]="permis.Create"></app-permission-status></td>
      <td align="center" ><app-permission-status [status]="permis.Update"></app-permission-status></td>
      <td align="center" ><app-permission-status [status]="permis.Delete"></app-permission-status></td>
      <td align="right">
        <span class="table-sdw__iconset" *ngIf="!disabled()">
          <a (click)="updatePermission(permis.SECURE_ID)" class="fa-stack fa-1x">
            <i class="fas fa-circle fa-stack-2x"></i>
            <i class="fas fa-edit fa-stack-1x fa-inverse table-sdw__iconset__icon" title="Modificar" i18n-title></i>
          </a>
          <a (click)="deletePermission(permis.SECURE_ID)" class="fa-stack fa-1x">
            <i class="fas fa-circle fa-stack-2x"></i>
            <i class="fas fa-trash-alt fa-stack-1x fa-inverse table-sdw__iconset__icon" title="Esborrar" i18n-title></i>
          </a>
        </span>
        <span class="table-sdw__iconset" *ngIf="disabled()">
          <i class="fa-stack fa-1x" style="color: darkgray;">
            <i class="fas fa-circle fa-stack-2x"></i>
            <i class="fas fa-edit fa-stack-1x fa-inverse" title="Modificar" i18n-title></i>
          </i>
          <i class="fa-stack fa-1x" style="color: darkgray;">
            <i class="fas fa-circle fa-stack-2x"></i>
            <i class="fas fa-trash-alt fa-stack-1x fa-inverse" title="Esborrar" i18n-title></i>
          </i>
        </span>
      </td>
    </tr>
    </tbody>
  </table>

  <span *ngIf="this.permissionItems.length === 0" i18n>No s'han assignat permisos per aquesta pantalla. Afegeix un permís amb el botó Afegir.</span>
</app-dialog-body>

