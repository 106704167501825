<app-dialog-header label="Propuesta anulacion" i18n-label></app-dialog-header>

<div class="row">
  <div class="col-12">
    <app-photo-box-gallery  #photoBoxGallery  [imatges]="this.propuestaAnulacion.IMATGES" [allowSelect]="false"></app-photo-box-gallery>
  </div>
</div>
<div class="row">
  <app-form-text-edit class="col-4" i18n-label label="Exp" [(value)]="this.propuestaAnulacion.exp"  [isReadOnly]="true" ></app-form-text-edit>
  <app-form-text-edit class="col-4" i18n-label label="Matricula" [(value)]="this.propuestaAnulacion.matricula"  [isReadOnly]="true" ></app-form-text-edit>
  <app-form-date-edit  i18n-label label="Data entrada" class="col-4" [(value)]="this.propuestaAnulacion.fechaDenuncia" [disabled]="true"></app-form-date-edit>  
</div>
<div class="row">
  <span i18n class="col-6">Comentario PDA</span>
  <span i18n class="col-6">Comentario Backoffice</span>
</div>
<div class="row">
  <app-form-text-area class="col-6" [value]="this.propuestaAnulacion.textoAnulacion" [disabled]="true"></app-form-text-area>
  <app-form-text-area class="col-6" [(value)]="this.propuestaAnulacion.comentarioUsuarioBackoffice" [disabled]="!isEditMode()"></app-form-text-area>
</div>
<div class="row" *ngIf="isEditMode()">
  
    <div class="col-6" >
      <button  
        class="btn btn-danger button-not-allowed btn-block" type="button" (click)="this.rejectProposta()" 
        [disabled]="!isEditMode()">           
      <i class="fa fa-times-circle"></i>&nbsp;<span i18n>Denegar</span>
      </button>
    </div>            
    <div class="col-6" >            
      <button 
        class="btn btn-success  button-not-allowed  btn-block"  type="button" (click)="this.acceptProposta()" 
        [disabled]="!isEditMode()">
      <i class="fa fa-check-circle"></i>&nbsp;<span i18n>Acceptar</span>
      </button>
    </div>
</div>
<div class="row " *ngIf="!isEditMode()">
  <div class="col-6 "></div>
  <div class="col-1 ">
    <div class="justify-content-center">
      <i *ngIf="this.propuestaAnulacion.aceptada" [class]="'fas fa-check-circle has-color-green fa-3x'" ></i>
      <i *ngIf="!this.propuestaAnulacion.aceptada" [class]="'far fa-times-circle has-color-red fa-3x'" ></i>
    </div>
  </div>
  <div class="col-5 "></div>
</div>
<!-- <div class="row" *ngIf="isEditMode()">
  
  <div class="col-12" *ngIf="!this.propuestaAnulacion.aceptada" >
    <button  
      class="btn btn-danger button-not-allowed btn-block" type="button" [disabled]="true">           
    <i class="fa fa-times-circle"></i>&nbsp;<span i18n>Denegada</span>
    </button>
  </div>            
  <div class="col-6"   class="col-12" *ngIf="this.propuestaAnulacion.aceptada" >            
    <button 
      class="btn btn-success  button-not-allowed  btn-block"  type="button" [disabled]="true">
    <i class="fa fa-check-circle"></i>&nbsp;<span i18n>Aceptada</span>
    </button>
  </div>
</div> -->