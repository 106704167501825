import { PermissionItem } from './models/permissionItem.model';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { Injectable } from '@angular/core';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { api } from '../../../shared/configuration/urls';
import { config } from 'path-shared/configuration/config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(private authGuard: AuthGuardService, private authService: AuthorizationService, private http: HttpSecureClient) {
  }

  getPermission(code: number): Observable<any> {
    if (this.authGuard.canActivate()) {
      const url = config.urls['apiUrl'] + api.endpoints['urlGetPermissionItems'].replace('{code}', code.toString());
      return this.http.get(url, null);
    }
  }

  setPermission(item: PermissionItem): Observable<any> {
    if (this.authGuard.canActivate()) {
      const url = config.urls['apiUrl'] + api.endpoints['urlSetPermissionItems'];
      return this.http.put(url, JSON.stringify(item));
    }
  }

  deletePermission(item: PermissionItem): Observable<any> {
    if (this.authGuard.canActivate()) {
      const url = config.urls['apiUrl'] + api.endpoints['urlDeletePermissionItems'].replace('{secure_id}', item.SECURE_ID);
      return this.http.delete(url, null);
    }
  }

  getGroups(): Observable<any> {
    if (this.authGuard.canActivate()) {
      const url = config.urls['apiUrl'] + api.endpoints['urlGetGroups'];
      return this.http.get(url, null);
    }
  }

  getUsers(): Observable<any> {
    if (this.authGuard.canActivate()) {
      const url = config.urls['apiUrl'] + api.endpoints['urlGetUsers'];
      return this.http.get(url, null);
    }
  }
}
