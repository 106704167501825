<div class="row justify-content-center">
  <mat-radio-group   [(ngModel)]="filtreSeleccionat">
    <mat-radio-button value="1">Vehicle</mat-radio-button>
    <mat-radio-button *ngIf="this.propietari" value="2" style="margin: 10px"><span i18n>Propietari</span></mat-radio-button>
    <mat-radio-button *ngIf="this.conductor" value="3" style="margin: 10px"><span i18n>Conductor</span></mat-radio-button>
  </mat-radio-group>
</div>
<div class="text-center">
  <button class=" btn btn-sq-lg btn-primary" i18n (click)="filtrar()" >Acceptar</button>
  <button class=" btn btn-sq-lg btn-danger" style="margin: 10px" i18n (click)="cancelar()" >Cancelar</button>
</div>
