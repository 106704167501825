import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class NotificacionsFilter extends FilterData {
  element: number;
  notificacio: number;
  enviament: number;
  tipus: string;
  acceptacio: boolean;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.element = null;
    this.tipus = null;
    this.notificacio = null;
    this.enviament = null;
    this.acceptacio = null;

    this.labels['element'] = this.i18n('Expedient');
    this.labels['tipus'] = this.i18n('Tipus');
    this.labels['notificacio'] = this.i18n('Notificació');
    this.labels['enviament'] = this.i18n('Enviament');
    this.labels['acceptacio'] = this.i18n('Acceptació');
  }
}
