import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { config } from 'path-shared/configuration/config';
import { Observable } from 'rxjs';
import { api } from 'path-shared/configuration/urls';
import { Tancaments } from '../models/tancaments.models';
import { EjecutivasOlot } from '../models/EjecutivasOlot.models';
import { HttpParams } from '@angular/common/http';
import { Ejecutivas } from '../models/Ejecutivas.models';
import { EjecutivaReport } from '../models/EjecutivaReport';
import { EjecutivaFilterImpressio } from '../models/Ejecutiva-filter-impressio';

@Injectable({
  providedIn: 'root'
})
export class TramitarService {  
  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {    
   }

  getInfoTancament(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlInfoTancaments'], null);
    }
  }

  getInfoNotificar(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlInfoNotificar'], null);
    }
  }

  getInfoPendents(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlInfoPendents'], null);
    }
  }

  getPendents(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlPendents'], null);
    }
  }

  getTancaments(): Observable<any> {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlTancaments'], null);
  }

  SetTancaments(data: Tancaments): Observable<any> {
    return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetTancaments'], data);
  }

  getLimitarExecutiva(): Observable<any> {
    return this.http.post(config.urls['apiUrl'] + api.endpoints['urlgetLimitarExecutiva'], null);
  }

  getComptadorsAZero(): Observable<any> {
    return this.http.post(config.urls['apiUrl'] + api.endpoints['urlgetComptadorsExecutiva'], null);
  }

  LoadG5(): Observable<any> {
    return this.http.post(config.urls['apiUrl'] + api.endpoints['urlLoadG5'], null);
  }

  urlEnviarEjecutivesOlot(data: EjecutivasOlot[]): Observable<any> {
    return this.http.post(config.urls['apiUrl'] + api.endpoints['TraspassarExecutivaOlot'], data);
  }

  urlEnviarEjecutivasInforme(data: Ejecutivas[]): Observable<any> {
    return this.http.post(config.urls['apiUrl'] + api.endpoints['urlTraspassarExecutivaInforme'], data);
  }

  urlEnviarEjecutivasExcel(data: Ejecutivas[]): Observable<any> {
    return this.http.post(config.urls['apiUrl'] + api.endpoints['urlTraspassarExecutivaExcel'], data);
  }

  urlEnviarEjecutivasRecaptacio(data: Ejecutivas[]): Observable<any> {
    return this.http.post(config.urls['apiUrl'] + api.endpoints['urlTraspassarExecutivaRecaptacio'], data);
  }

  urlGetEjecutivas(): Observable<any> {
    return this.http.post(config.urls['apiUrl'] + api.endpoints['urlGetExecutives'], null);
  }

  urlGetNumberEjecutivas(): Observable<any> {
    return this.http.post(config.urls['apiUrl'] + api.endpoints['urlGetAllNumberExecutives'], null);
  }

  getTipusEnvio(): Observable<any> {
    return this.http.post(config.urls['apiUrl'] + api.endpoints['urlTipusEnvioTramitar'], null);
  }

  //#region REPORTS

  getReports(page: string): Observable<any> {
    if (this.authGuard.canActivate()) {         
      page = page.replace(/\./g, 'xXx');
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlReports'].replace('{page}', page), null);
    }
  }

  generateReport(filter: EjecutivaFilterImpressio, reportId: string): Observable<any> {
    const reportInfo = new EjecutivaReport();
    reportInfo.reportId = reportId;
    reportInfo.fechaInicio = filter.fechaInicio;
    reportInfo.fechaFin = filter.fechaFin;

    if (this.authGuard.canActivate()) {
      return this.http.postFileResponse(config.urls['apiUrl'] + api.endpoints['urlGenerateReportEjecutivas'], reportInfo, new HttpParams());
    }
  }

  //#endregion

  
}
