import { Component, OnInit, ViewChild, Input, SimpleChanges, AfterViewInit, OnChanges } from '@angular/core';
import { MatSort, MatPaginator, MatDialog, MatDialogRef } from '@angular/material';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';

import { DenunciesService } from 'path-denuncies-services/denuncies.service';
import { DenunciesImagesComponent } from 'app/sections/denuncies/components/denuncies-editor/components/denuncies-images/denuncies-images.component';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { Builder } from 'path-shared/models/builder';
import { FormMultiSelect } from 'path-shared/components/form/form-multi-select/model/form-multi-select.model';
import { DesplacatsGruaFilter } from 'app/sections/grua/Models/desplacats-filter';
import { PhotoBoxGruaComponent } from 'app/sections/grua/components/photo-box-grua/photo-box-grua.component';
import { DesplacatsGruaDto } from 'app/sections/grua/Models/desplacats-grua.model';
import { DesplacatsGruaService } from 'app/sections/grua/services/desplacats-grua-service';
import { DesplacatsGruaFilterService } from 'app/sections/grua/services/desplacats-grua-filter-service';
import { SenseRetiradaGruaFilter } from 'app/sections/grua/Models/sense-retirada-filter';
import { SenseRetiradaGruaDto } from 'app/sections/grua/Models/sense-retirada-grua.model';
import { SenseRetiradaGruaService } from 'app/sections/grua/services/sense-retirada-grua-service';
import { SenseRetiradaGruaFilterService } from 'app/sections/grua/services/sense-retirada-grua-filter-service';

@Component({
  selector: 'app-sense-retirada-table',
  templateUrl: './sense-retirada-table.component.html',
  styleUrls: ['./sense-retirada-table.component.scss']
})
export class SenseRetiradaGruaTableComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  filter: SenseRetiradaGruaFilter = new SenseRetiradaGruaFilter('id', 'desc');
  dataSource: CustomDataSource<SenseRetiradaGruaDto, SenseRetiradaGruaFilter>;
  highlightedRows = [];
  displayedItems = [];
  denunciesImages: any = [];
  denunciesImageDialogRef: MatDialogRef<DenunciesImagesComponent>;

  @Input() updatedFilterData: SenseRetiradaGruaFilter;
  // @Output() notify: EventEmitter<string> = new EventEmitter<string>();
  displayedColumns = ['id', 'matricula', 'dataFinalitzacio','marca', 'modelo', 'descMotiuDesestimada', 'textoLibre', 'imatges'];
  constructor(
    private senseRetiradaGruaService: SenseRetiradaGruaService,
    private filterService: FilterService,
    private dialog: MatDialog,
    private i18n: I18n,
    private denunciesService: DenunciesService,
  ) { }

  ngOnInit() {
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<SenseRetiradaGruaDto, SenseRetiradaGruaFilter>(this.paginator, new SenseRetiradaGruaFilterService(),
      'urlGetSomPolSenseRetiradaGrua', 'urlGetSomPolSenseRetiradaGruaCount');
    this.dataSource.subject.subscribe((data) => {
      data.forEach(item => {
        this.displayedItems[item.secureId] = {
          id: item.secureId
        };
        // item.IMATGES = this.loadImatges(item);
      });
    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
      }
    });

    this.filter.paginator.init(this.sort, this.paginator);
  }


  loadImatges(vehicleDiposit) {
    if ( vehicleDiposit !== undefined && vehicleDiposit !== null) {
      this.denunciesService.getDenunciaImagesNumDen(vehicleDiposit.iddenuncia).subscribe( images => {
        return images;
      });
    }
  }

  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(tap(() => this.filter.updated()))
    .subscribe();

    this.load();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      this.update();
    }
  }

  update() {
    if (this.dataSource !== undefined) {
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
    }
  }

  load() {
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }

  generateExcel(): void {

      const filter = this.filter;
      /*if (this.updatedFilterData != null) {
        filter = this.updatedFilterData;
      }*/
      this.dialog.open(DownloadExcelDialogComponent, {
          closeOnNavigation: false,
          autoFocus: true,
          data: Builder.newDownloadExcelDialogData (this.senseRetiradaGruaService, filter, this.i18n('Sense retirada'), this.sort.direction, this.sort.active)
      });
  }


  viewImages(numDenuncia, id) {

    this.denunciesService.getDenunciaImagesNumDen(numDenuncia).subscribe( images => {
      this.denunciesImages = images;
      this.denunciesImages.forEach(element => {
        element.NUM_DENUNCIA = numDenuncia;
      });
      //        this.denunciesImageDialogRef = this.dialog.open(PhotoBoxGruaComponent,
      const dialogPhoto = this.dialog.open(PhotoBoxGruaComponent,
      {
        minWidth: '50%',
        maxWidth: '90%',
        maxHeight: '70%',
        closeOnNavigation: false,
        autoFocus: true,
        data: {'ficheros': images, 'numDenuncia': numDenuncia, 'isDialog': true},
      });
      this.denunciesImageDialogRef.componentInstance.somId = id;

      dialogPhoto.afterClosed().subscribe(() => {
        this.denunciesService.getDenunciaImages(numDenuncia).subscribe( newImages => {
          this.dataSource.getData().find(x => x.idDenuncia === numDenuncia).numImatges = newImages.length;
          this.denunciesImages = newImages;
          this.denunciesImages.forEach(element => {
            element.NUM_DENUNCIA = numDenuncia;
          });
        });
      });
    });
  }


}
