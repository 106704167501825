<mat-form-field >
  <mat-label  i18n-label>{{this.label}}</mat-label>
  <!--<input matInput   [(value)]="this.valorAutocomplete.value"  disabled="true">-->
  
  <input type="text" [formControl]="control" [value]="this.valorAutocomplete.value" name="matAutoComleteInput" 
    matInput [matAutocomplete]="auto" [required]="this.isRequired()"    (keydown.Tab)="checkSelected()" ><!--(focusout)="checkSelected()"-->
  <mat-icon class="dropdownButton" matSuffix>arrow_drop_down</mat-icon>
  <mat-placeholder>{{this.label}}</mat-placeholder>
  <mat-autocomplete class="valueSelect" #matAutoComplete #auto="matAutocomplete" [displayWith]="this.valueMapper"  
  >
    <mat-option *ngFor="let i = index; let option of filteredOptions | async " [disabled]="this.isDisabled(option.key)" style="height: auto; white-space: break-spaces !important;"

    (onSelectionChange)="selectedOption(option.key)"  [ngClass]="{'disabledClass': this.isDisabled(option.key),'alternate': i % 2 == 0 }"  [value]="option.key" >
    <div class="row box" *ngIf="this.showColor !== undefined  && this.showColor !== null && this.showColor"> 
      <canvas class="col-xl-2" [ngStyle]="{'background-color': this.getColor(option.key)}"></canvas> 
      <span class="col-xl-10" >{{ option.value }}</span>
    </div>

    <div class="row box" *ngIf="this.showColor === undefined  || this.showColor === null || !this.showColor"> 
      <span class="col-xl-12" >{{ option.value }}</span>
    </div>
      
    </mat-option>
  
  </mat-autocomplete>
</mat-form-field>