import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { ExcelGenerator } from 'path-shared/interfaces/excel-generator';
import { HttpClient, HttpRequest, HttpEventType, HttpResponse, HttpParams  } from '@angular/common/http';
import { SOM_FICHEROS } from 'path-models/SOM_FICHEROS';
import { ImagesService } from '../../../shared/services/images/images.service';

const defaultSortParam = 'codi';
const defaultSortDirection = 'desc';


@Injectable()
export class FileBoxService {

  displayedItems = [];

  constructor(
    private http: HttpSecureClient,
    private authGuard: AuthGuardService,
    private imagesService: ImagesService
    ) {
    this.displayedItems['checkedValues'] = 0;
    this.displayedItems['checkedItems'] = Array();
  }

  getImage(somId, suffix): Observable<any> {
    if (this.authGuard.canActivate()) {
     // return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetLegislacio'], fitxer);

     let url = config.urls['apiUrl'] + '/image/' + somId;
     if (suffix != null && suffix !== '') {
       url = url + '/' + suffix;
     }
     // return this.http.get(config.urls['apiUrl'] + api.endpoints['urlSetInfraccio'], infraccio);
     return this.http.getFile(url, new HttpParams());

     // return this.http.getFile(url, null);
    }
  }
  /*getUrl(somId, suffix) {
    if (this.authGuard.canActivate()) {
     // return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetLegislacio'], fitxer);

     const url = this.imagesService.getImageUrls( somId, suffix);
     return url;
    }
  }*/

  getPdf(somId): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.getFile(config.urls['apiUrl'] + '/file/' + somId ,  new HttpParams());
    }
  }

  setFile(url: string, somId, file, nomFitxer): Observable<any>  {
    if (this.authGuard.canActivate()) {
      return this.http.postFile(url.replace('{somId}', somId), file
      ).catch(error => Observable.throw(error));
      /*.subscribe(
      data => console.log('success'),
      error => console.log(error)*/
      // );
    }
  }

  getFiles(contenedor: string): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + '/file/getFiles/' + contenedor ,  new HttpParams());
    }
  }

  removeFile(somId: string) {
    if (this.authGuard.canActivate()) {

      return this.http.delete(config.urls['apiUrl'] + '/file/' + somId, null);
    }
  }

}
