<div class="row justify-content-between" [hidden]="true">
  <div class="col">
    <app-filter-selector [filter]="this.filter"></app-filter-selector>
  </div>
  <div class="col">
    <mat-paginator #paginator [pageIndex]="0" [pageSize]="this.filter.paginator.pageSize" [pageSizeOptions]="[20, 40, 60, 100]"></mat-paginator>
  </div>
</div>

<div class="table-sdw is-fixed">

  <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.filter.paginator.sortField" 
    [matSortDirection]="this.filter.paginator.sortDir" class="is-header-sticky">
  
    <ng-container matColumnDef="select" >
      <mat-header-cell  *matHeaderCellDef class="text-center" style="padding-left:0px;" i18n>Seleccionar</mat-header-cell>
      <mat-cell  *matCellDef="let row" ><mat-checkbox #checkbox (click)="selectRow(row)" [(ngModel)]="row.selected"></mat-checkbox></mat-cell>
    </ng-container>

    <!-- Rango inferior -->
    <ng-container matColumnDef="rangoInferior">
      <mat-header-cell *matHeaderCellDef i18n>Rang Inferior (Des de...)</mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.rangoInferior }}</mat-cell>
    </ng-container>

    <!-- Rango superior -->
    <ng-container matColumnDef="rangoSuperior">
      <mat-header-cell *matHeaderCellDef i18n>Rang Superior (Fins a...)</mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.rangoSuperior }}</mat-cell>
    </ng-container>
    
    <!-- Color -->
    <ng-container matColumnDef="color">
      <mat-header-cell *matHeaderCellDef i18n>Color</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <input class="form-check-input" style="color: transparent; position:inherit; margin-left: 0px;" [style.background]="row.color" 
          [(colorPicker)]="row.color" [disabled]="true">  
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset"></mat-row>
  </mat-table>
</div>