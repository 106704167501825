import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class TarifesFilter extends FilterData {

  codi: number;
  descripcio: string;
  dataInici: Date;
  dataFi: Date;
  tipusCalcul: number;
  valor: number;
  valorMinim: number;
  valorMaxim: number;
  carenciaDenuncia: number;
  increment: number;
  anulSenseTiquet: number;
  tempsSenseTiquet: number;
  anulExhaurit: number;
  tempsExhaurit: number;
  desde1: string;
  iniciPausa1: string;
  fiPausa1: string;
  hasta1: string;
  desde2: string;
  iniciPausa2: string;
  fiPausa2: string;
  hasta2: string;
  desde3: string;
  iniciPausa3: string;
  fiPausa3: string;
  hasta3: string;
  desde4: string;
  iniciPausa4: string;
  fiPausa4: string;
  hasta4: string;
  desde5: string;
  iniciPausa5: string;
  fiPausa5: string;
  hasta5: string;
  desde6: string;
  iniciPausa6: string;
  fiPausa6: string;
  hasta6: string;
  desde7: string;
  iniciPausa7: string;
  fiPausa7: string;
  hasta7: string;
  idZona: number;
  idResident: number;
  idTiquet: number;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.codi = null;
    this.descripcio = '';
    this.dataInici = null;
    this.dataFi = null;
    this.tipusCalcul = null;
    this.valor = null;
    this.valorMinim = null;
    this.valorMaxim = null;
    this.carenciaDenuncia = null;
    this.increment = null;
    this.anulSenseTiquet = null;
    this.tempsSenseTiquet = null;
    this.anulExhaurit = null;
    this.tempsExhaurit = null;
    this.desde1 = '';
    this.iniciPausa1 = '';
    this.fiPausa1 = '';
    this.hasta1 = '';
    this.desde2 = '';
    this.iniciPausa2 = '';
    this.fiPausa2 = '';
    this.hasta2 = '';
    this.desde3 = '';
    this.iniciPausa3 = '';
    this.fiPausa3 = '';
    this.hasta3 = '';
    this.desde4 = '';
    this.iniciPausa4 = '';
    this.fiPausa4 = '';
    this.hasta4 = '';
    this.desde5 = '';
    this.iniciPausa5 = '';
    this.fiPausa5 = '';
    this.hasta5 = '';
    this.desde6 = '';
    this.iniciPausa6 = '';
    this.fiPausa6 = '';
    this.hasta6 = '';
    this.desde7 = '';
    this.iniciPausa7 = '';
    this.fiPausa7 = '';
    this.hasta7 = '';
    this.idZona = null;
    this.idResident = null;
    this.idTiquet = null;


    this.labels['codi'] = this.i18n('Codi');
    this.labels['descripcio'] = this.i18n('Descripció');
    this.labels['dataInici'] = this.i18n('Data inici');
    this.labels['dataFi'] = this.i18n('Data fi');
    this.labels['tipusCalcul'] = this.i18n('Tipus Càlcul');
    this.labels['valor'] = this.i18n('Valor');
    this.labels['valorMinim'] = this.i18n('Valor mínim');
    this.labels['valorMaxim'] = this.i18n('Valor màxim');
    this.labels['carenciaDenuncia'] = this.i18n('Carencia denúncia');
    this.labels['increment'] = this.i18n('increment');
    this.labels['anulSenseTiquet'] = this.i18n('Preu sense tiquet');
    this.labels['tempsSenseTiquet'] = this.i18n('Temps sense tiquet');
    this.labels['anulExhaurit'] = this.i18n('Preu exhaurit');
    this.labels['tempsExhaurit'] = this.i18n('Temps exhaurit');
    this.labels['desde1'] = this.i18n('Dilluns des de');
    this.labels['iniciPausa1'] = this.i18n('Dilluns inici pausa');
    this.labels['fiPausa1'] = this.i18n('Dilluns fi pausa');
    this.labels['hasta1'] = this.i18n('Dilluns fins');
    this.labels['desde2'] = this.i18n('Dimarts des de');
    this.labels['iniciPausa2'] = this.i18n('Dimarts inici pausa');
    this.labels['fiPausa2'] = this.i18n('Dimarts fi pausa');
    this.labels['hasta2'] = this.i18n('Dimarts fins');
    this.labels['desde3'] = this.i18n('Dimecres des de');
    this.labels['iniciPausa3'] = this.i18n('Dimecres inici pausa');
    this.labels['fiPausa3'] = this.i18n('Dimecres fi pausa');
    this.labels['hasta3'] = this.i18n('Dimecres fins');
    this.labels['desde4'] = this.i18n('Dijous des de');
    this.labels['iniciPausa4'] = this.i18n('Dijous inici pausa');
    this.labels['fiPausa4'] = this.i18n('Dijous fi pausa');
    this.labels['hasta4'] = this.i18n('Dijous fins');
    this.labels['desde5'] = this.i18n('Divendres des de');
    this.labels['iniciPausa5'] = this.i18n('Divendres inici pausa');
    this.labels['fiPausa5'] = this.i18n('Divendres fi pausa');
    this.labels['hasta5'] = this.i18n('Divendres fins');
    this.labels['desde6'] = this.i18n('Dissabte des de');
    this.labels['iniciPausa6'] = this.i18n('Dissabte inici pausa');
    this.labels['fiPausa6'] = this.i18n('Dissabte fi pausa');
    this.labels['hasta6'] = this.i18n('Dissabte fins');
    this.labels['desde7'] = this.i18n('Diumenge des de');
    this.labels['iniciPausa7'] = this.i18n('Diumenge inici pausa');
    this.labels['fiPausa7'] = this.i18n('Diumenge fi pausa');
    this.labels['hasta7'] = this.i18n('Diumenge fins');
    this.labels['idZona'] = this.i18n('Zona');
    this.labels['idResident'] = this.i18n('Resident');
    this.labels['idTiquet'] = this.i18n('Tiquet');

  }
}
