import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'size'
})
export class SizePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (args === null || args === undefined) {
      return this.formatBytesAuto(value);
    } else {
      return this.formatBytes(value, args);
    }
  }

  private formatBytes(a, b) {
    if (0 === a) {
      return '0 Bytes';
    } else {
      const c = 1024;
      const d = b || 2;
      const e = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const f = Math.floor(Math.log(a) / Math.log(c));
      return parseFloat((a / Math.pow(c, f)).toFixed(d)) + ' ' + e[f];
    }
  }
  private formatBytesAuto(value: any): any {
    if (value < 1024) {
      return this.formatBytes(value, 'Bytes');
    } else if (value < 1024 * 1024) {
      return this.formatBytes(value, 'KB');
    } else if (value < 1024 * 1024 * 1024) {
      return this.formatBytes(value, 'MB');
    } else if (value < 1024 * 1024 * 1024 * 1024) {
      return this.formatBytes(value, 'GB');
    } else if (value < 1024 * 1024 * 1024 * 1024 * 1024) {
      return this.formatBytes(value, 'TB');
    } else if (value < 1024 * 1024 * 1024 * 1024 * 1024 * 1024) {
      return this.formatBytes(value, 'PB');
    } else if (value < 1024 * 1024 * 1024 * 1024 * 1024 * 1024 * 1024) {
      return this.formatBytes(value, 'EB');
    } else if (value < 1024 * 1024 * 1024 * 1024 * 1024 * 1024 * 1024 * 1024) {
      return this.formatBytes(value, 'ZB');
    } else {
      return this.formatBytes(value, 'YB');
    }
  }
}
