import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { Resposta } from '../models/resposta.model';
import { RespostesFilter } from '../models/respostes-filter';
import { RespostesFilterService } from './respostes-filter.service';

const defaultSortParam = 'codi';
const defaultSortDirection = 'asc';

@Injectable()
export class RespostesService {
  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {}

  setResposta(resposta: Resposta): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetResposta'], resposta);
    }
  }

  removeResposta(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteResposta'].replace('{secure_id}', secure_id), null);
    }
  }

  getResposta(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetResposta'].replace('{secure_id}', secure_id), null);
    }
  }

  generateExcel(filter: RespostesFilter, sortDir: string, sortField: string): Observable<any> {
    const start = 0;
    const pageSize = 1000;

    sortField = sortField === undefined ? defaultSortParam : sortField;
    sortDir = sortDir === '' ? defaultSortDirection : sortDir;
    const params = new RespostesFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

    if (this.authGuard.canActivate()) {
      return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlRespostesExcel'],  params );
    }
  }
}
