import { Component, OnInit, AfterViewInit, OnChanges, ViewChild, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { Builder } from 'path-shared/models/builder';
import { BancCarreguesC60 } from '../../models/banc-carregues-c60.model';
import { BancCarreguesC60Filter } from '../../models/banc-carregues-c60-filter';
import { BancCarreguesC60Service } from '../../services/banc-carregues-c60.service';
import { BancCarreguesC60FilterService } from '../../services/banc-carregues-c60-filter.service';

@Component({
  selector: 'app-banc-carregues-c60-table',
  templateUrl: './banc-carregues-c60-table.component.html',
  styleUrls: ['./banc-carregues-c60-table.component.scss']
})
export class BancCarreguesC60TableComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  data: BancCarreguesC60[];
  dataSource: CustomDataSource<BancCarreguesC60, BancCarreguesC60Filter>;
  filter: BancCarreguesC60Filter = new BancCarreguesC60Filter('id', 'asc');
  displayedItems = [];
  highlightedRows = [];

  @Input() selectButton: boolean;
  @Input() updatedFilterData: BancCarreguesC60Filter;
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();
  @Output() itemClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() itemSelected: EventEmitter<string> = new EventEmitter<string>();

  displayedColumns = ['id', 'codiCarrega', 'descripcioCarrega', 'nomFitxer', 'directoriFitxer', 'situacioClass'];

  constructor(
    private bancCarreguesC60Service: BancCarreguesC60Service,
    private filterService: FilterService,
    private dialog: MatDialog,
    private i18n: I18n
  ) {
  }

  ngOnInit() {
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<BancCarreguesC60, BancCarreguesC60Filter>(this.paginator, new BancCarreguesC60FilterService(), 'urlGetBancCarreguesC60', 'urlGetBancCarreguesC60Count');
    this.dataSource.subject.subscribe((data) => {
      data.forEach(item => {
        this.displayedItems[item.secureId] = {
          id: item.secureId
        };
      });
    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
        if (!this.selectButton) { this.filterService.saveFilter('banc-carregues-c60-filter', this.filter); }
      }
    });

    if (!this.selectButton) { this.filterService.loadFilter('banc-carregues-c60-filter', this.filter); }
    this.filter.paginator.init(this.sort, this.paginator);
  }

  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(tap(() => this.filter.updated()))
    .subscribe();

    this.load();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      this.update();
    }
  }

  update() {
    if (this.dataSource !== undefined) {
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
    }
  }

  load() {
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }

  onRowClicked(row) {
    this.itemClicked.next(row);
  }

  generateExcel(): void {
    let filter = this.filter;
    if (this.updatedFilterData != null) {
      filter = this.updatedFilterData;
    }
    this.dialog.open(DownloadExcelDialogComponent, {
        closeOnNavigation: false,
        autoFocus: true,
        data: Builder.newDownloadExcelDialogData(this.bancCarreguesC60Service, filter, this.i18n('BancCarreguesC60'), this.sort.direction, this.sort.active)
    });
  }

}
