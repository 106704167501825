<app-filter-header title="Filtrar tarifes grua" i18n-title (apply)="this.apply()" (reset)="this.reset()" (dismiss)="this.dismiss()"></app-filter-header>
<div class="modal-body">
    <!-- TODO  Primera columna. -->


    <div class="row border border-secondary fondo ">

        <app-filter-text-edit id="grua-torns-filter-codi" autofocus class="col-sm-3" i18n-label label="Codi" number="true" [(value)]="filter.somId" maxLenght="5"></app-filter-text-edit>
        <app-filter-text-edit id="grua-torns-filter-descripcio" class="col-sm-6" i18n-label label="Descripció" [(value)]="filter.descripcion" maxLenght="50"></app-filter-text-edit>
        <app-filter-text-edit id="grua-torns-filter-tipus" class="col-sm-3" i18n-label label="Tipus tarifa" [(value)]="filter.tipoTarifa"></app-filter-text-edit>
    </div>

    <!-- TODO  Segunda columna. -->

    <div class="row border border-secondary fondo  ">
        <div class="col-md-1"></div>
        <div class="col-sm-1 data" i18n> <span class="badge badge-secondary text-mute labelCobrament ">Data desde/fins</span> </div>
        <div class="col-md-2">
            <app-form-date-edit id="grua-torns-filter-dataIni" [(value)]="filter.fechaDesde" type="calendar"></app-form-date-edit>
        </div>
        <div class="col-md-2 like">
            <app-form-date-edit id="grua-torns-filter-dataFi" [(value)]="filter.fechaHasta" type="calendar"></app-form-date-edit>
        </div>
    </div>

   

    <div class="row border border-secondary fondo  ">
        <app-filter-text-edit id="grua-torns-filter-tipusVeh" class="col-sm-4" i18n-label label="Tipus vehicle" [(value)]="filter.tipoVehiculo" maxLenght="30"></app-filter-text-edit>
        <app-filter-text-edit id="grua-torns-filter-import" class="col-sm-4" i18n-label label="Import" [(value)]="filter.importeTarifa" maxLenght="30"></app-filter-text-edit>
        <app-filter-text-edit id="grua-torns-filter-tipusEstancia" class="col-sm-4" i18n-label label="Tipus estància" [(value)]="filter.tipoEstancia" maxLenght="10"></app-filter-text-edit>
    </div>

</div>