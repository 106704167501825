<app-dialog-header label="Tipus de matrícula" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Editar" i18n-text (click)="this.dialogData.isReadOnly = false" color="has-color-green" icon="fa-edit" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.TipusMatricules) || !this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Guardar" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.TipusMatricules) || this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Eliminar" i18n-text (click)="this.remove();" color="has-color-red" icon="fa-trash-alt" [disabled]="!this.auth.UserCanDelete(this.auth.Pages.TipusMatricules) || this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button [text]="this.closeButtonText" i18n-text (click)="this.cancel()" color="has-color-yellow" icon="fa-th-list"></app-tool-button>
</app-toolbar>
<app-dialog-body>
  <form #mainForm>
    <div class="row">
      <div class="col-sm-6">
        <div class="row">
          <app-form-text-edit id="tipus-matricules-editor-descripcio" class="col-md-6" i18n-label label="Descripció" [(value)]="this.tipusMatricula.descripcio" required maxLength="50"></app-form-text-edit>
        </div>
        <div class="row">
          <!-- <app-form-combo-edit class="col-sm-6" i18n-label label="Zona"  [items]="this.zones" [(value)]="this.tipusMatricula.zona" valueField="SOM_ID" textField="descripcio"></app-form-combo-edit> -->
          <app-form-multi-select class="col-xl-6" titulo="Zona" [data]="allZonas" [(selectedValues)]="this.tipusMatricula.tiposMatriculas" i18n-titulo tituloMultiSelect="Selecciona una o més" i18n-tituloMultiSelect ></app-form-multi-select>
        </div>
        <div class="row">
          <app-form-check-edit class="col-sm-4" i18n-label label="Denunciable en zona blava" [(value)]="this.tipusMatricula.denunciaZB" (valueChange)="null" required></app-form-check-edit>
        </div>
        <div class="row">
          <app-form-check-edit class="col-sm-4" i18n-label label="Denunciable policia" [(value)]="this.tipusMatricula.denunciaPOL" (valueChange)="null" required></app-form-check-edit>
        </div>
        <!-- <div class="row">
          <app-form-check-edit class="col-sm-4" i18n-label label="Guardar log" [(value)]="this.tipusMatricula.guardaLOG" (valueChange)="null" required></app-form-check-edit>
        </div>
        <div class="row">
            <app-form-check-edit class="col-sm-4" i18n-label label="Reincident" [(value)]="this.tipusMatricula.reincidents" (valueChange)="null" required></app-form-check-edit>
        </div> -->
        <div class="row">
            <app-form-check-edit class="col-sm-4" i18n-label label="Generar alarma en detectar matrícula" [(value)]="this.tipusMatricula.generarAlarma" (valueChange)="null" required></app-form-check-edit>
        </div>

        <div class=row>
          <span class="col-2" i18n>Alarmas</span>
          <app-form-check-edit class="col-2" label='Policia' i18n-label [(value)]="this.tipusMatricula.alarmas.pol"></app-form-check-edit>
          <app-form-check-edit class="col-2" label='Zona Azul' i18n-label [(value)]="this.tipusMatricula.alarmas.zb"></app-form-check-edit>
          <app-form-check-edit class="col-2" label='Grua' i18n-label [(value)]="this.tipusMatricula.alarmas.gr"></app-form-check-edit>
        </div>
        <div class="row">
          <mat-form-field class="col-2">
            <input type="time"  matInput i18n-placeholder placeholder="Hora Inici" [value]="this.tipusMatricula.horaInicio" (input)="this.tipusMatricula.horaInicio=$event.target.value">
          </mat-form-field>        
          <mat-form-field class="col-2">
            <input type="time"  matInput i18n-placeholder placeholder="Hora Fi" [value]="this.tipusMatricula.horaFin" (input)="this.tipusMatricula.horaFin=$event.target.value">
          </mat-form-field>
        </div>
      </div>
      <div class="col-sm-6 card">
        <div class="card-body">
          <div class="row small" i18n>Enviar un correu electrònic a les següents adreçes de correu en detectar una matrícula de la llista (en blanc per no enviar)</div>
          <div class="row ">
            <app-form-text-edit id="persones-editor-emails"class="col" i18n-label label="Correus separats per coma" [(value)]="this.tipusMatricula.emails" maxLength="1000"></app-form-text-edit>
          </div>
          <div class="row small" i18n>Es poden utiltizar les etiquetes [MAT] per la matrícula, [DATE] per la data, [DEV] per el dispositiu i [LOC] per la ubicació. Es pot utilitzar HTML. </div>
          <div class="row">
            <app-form-text-area class="col" i18n-label label="Cos del format" [(value)]="this.tipusMatricula.emailBody" maxLength="5000"></app-form-text-area>
          </div>
        </div>
      </div>
    </div>
  </form>
</app-dialog-body>
