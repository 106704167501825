<form #mainForm>
    <app-dialog-header label="Zones" i18n-label></app-dialog-header>
    <app-toolbar>
        <app-tool-button text="Editar" i18n-text (click)="this.dialogData.isReadOnly = false" color="has-color-green" icon="fa-edit" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Zonas) || !this.dialogData.isReadOnly"></app-tool-button>
        <app-tool-button text="Guardar" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Zonas) || this.dialogData.isReadOnly"></app-tool-button>
        <app-tool-button text="Eliminar" i18n-text (click)="this.remove();" color="has-color-red" icon="fa-trash-alt" [disabled]="!this.auth.UserCanDelete(this.auth.Pages.Zonas) || this.dialogData.isReadOnly"></app-tool-button>
        <app-tool-button [text]="this.closeButtonText" i18n-text (click)="this.cancel()" color="has-color-yellow" icon="fa-th-list"></app-tool-button>
    </app-toolbar>
    <app-dialog-body>
        <div class="row">
            <!-- <app-form-combo-edit class="col-sm-4" i18n-label label="Tipus de matricules" [items]="this.tipusMatricules" [(value)]="this.zona.IDTIPOMATFK" valueField="codi" textField="descripcio" required> required></app-form-combo-edit> -->
            <app-form-multi-select class="col-xl-4" titulo="Tipus de matricules" [data]="allTipusMatricules" [(selectedValues)]="this.zona.tiposMatriculas" i18n-titulo tituloMultiSelect="Selecciona una o més" i18n-tituloMultiSelect ></app-form-multi-select>
            <app-form-combo-edit class="col-sm-4" i18n-label label="Tipus de tiquet" [items]="this.tickets" [(value)]="this.zona.tipo_ticket" valueField="codi" textField="descripcio" required></app-form-combo-edit>
            <app-form-text-edit id="zones-editor-idZona" *ngIf="this.zona.IdZonaTickets_Ext !== null" class="col-sm-4" i18n-label label="Id zona tickets" [value]="this.zona.IdZonaTickets_Ext" disabled></app-form-text-edit>
        </div>
        <div class="row">
            <app-form-text-edit id="zones-editor-descripcio" class="col-sm-4" i18n-label label="Descripció" [(value)]="this.zona.descripcio" maxLength="50" required></app-form-text-edit>
            <!-- <app-form-text-edit class="col-sm-4" i18n-label label="Tarifa" [(value)]="this.zona.id_tarifa" maxLength="50"></app-form-text-edit> -->
            <app-form-combo-edit class="col-sm-4" i18n-label label="Tipus de tarifa" [items]="this.tarifes" [(value)]="this.zona.id_tarifa" valueField="codi" textField="descripcio" required></app-form-combo-edit>
            <!-- <app-combo-box-input-autocomplete #comboTemporada [disableComponent]="this.dialogData.isReadOnly" class="center-horizontal" i18n-label label="Temporada" [options]="this.temporadasDictionary"
            (valorSeleccionado)="this.temporadaSeleccionado($event)" [valorAutocomplete]="this.valorAutoCompleteTemporada" [required]="true"  ></app-combo-box-input-autocomplete> -->
        </div>
        <div class="row">
            <app-form-date-edit id="zones-editor-dataIni" class="col-sm-4" i18n-label label="Data Inici" [(value)]="this.zona.fechaInici"></app-form-date-edit>
            <app-form-date-edit id="zones-editor-dataFi" class="col-sm-4" i18n-label label="Data Fi" [(value)]="this.zona.fechaFin"></app-form-date-edit>
            <!-- <app-form-check-edit class="col-sm-4" i18n-label label="Sense data final" [(value)]="this.noFinalDate" (valueChange)="noFinalCheckChangd($event)"></app-form-check-edit> -->
            <div class="col-sm-2">
                <!-- <div class="input-group-prepend">
                  <div class="input-group-text"> -->
                      <div class="row" style=" padding-left: 15px;">
                        <input  class="form-check-input col-sm-2" style="color: transparent" [style.background]="color1" [cpOutputFormat]="'rgba'" [(colorPicker)]="color1"  (colorPickerClose)="onChangeColor($event)">  
                    <app-form-check-edit class="col-xl-2" i18n-label label="Denúncies per area" [(value)]="this.zona.DENUNCIAS_POR_AREA" [disabled]="this.dialogData.isReadOnly"></app-form-check-edit>
                      </div>
                    
                  <!-- </div>
                </div> -->
                </div>
        </div>
        <!-- <div class="row">
            <div class="col-sm-2">
                <input  class="form-check-input col-sm-2" style="color: transparent" [style.background]="color1" [(colorPicker)]="color1" (colorPickerChange)="onChangeColor($event)">  
            </div>
        </div> -->
    </app-dialog-body>
</form>