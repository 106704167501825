<app-toolbar> 
  <app-tool-button text="Guardar" i18n-text (click)="save();" color="has-color-green" icon="fa-save"></app-tool-button>
  <app-tool-button-permissions [permission]="this.auth.Pages.Tramitar"></app-tool-button-permissions>
</app-toolbar>
<div class="row">
  <mat-tab-group class="col-xl-12">
    <mat-tab i18n-label label="Configuración general" >
      <app-boe-general #general [(value)]="this.configuracion"></app-boe-general>
    </mat-tab>
    <mat-tab i18n-label label="Texto denuncia">
      <div class="row" style="padding-top: 2%;padding-left: 15px;">
        <div class="alert alert-secondary" role="alert">
          <span i18n>Escriba en la pantalla el texto para enviar al TEU. <br/>
                      Para mostrar los datos generados de la denúncia, escriba %DATOS%</span>
         </div>
      </div>
      <textarea style="padding-left: 15px;"  cols="80"matInput [value]="this.configuracion.textoLegalDen"></textarea>
      
    </mat-tab>
    <mat-tab i18n-label label="Texto alegación">
      <div class="row" style="padding-top: 2%;padding-left: 15px;">
        <div class="alert alert-secondary" role="alert">
          <span i18n>Escriba en la pantalla el texto para enviar al TEU. <br/>
                      Para mostrar los datos generados de la denúncia, escriba %DATOS%</span>
         </div>
      </div>
      <textarea style="padding-left: 15px;"  cols="80" matInput [value]="this.configuracion.textoLegalAlegacion"></textarea>
    </mat-tab>
    <mat-tab i18n-label label="Texto sanción firme">
      <div class="row" style="padding-top: 2%;padding-left: 15px;">
        <div class="alert alert-secondary" role="alert">
          <span i18n>Escriba en la pantalla el texto para enviar al TEU. <br/>
                      Para mostrar los datos generados de la denúncia, escriba %DATOS%</span>
         </div>
      </div>
      <textarea style="padding-left: 15px;"  cols="80" matInput [value]="this.configuracion.textoLegalSancionFirme"></textarea>
    </mat-tab>
    <mat-tab i18n-label label="Texto resolución">
      <div class="row" style="padding-top: 2%;padding-left: 15px;">
        <div class="alert alert-secondary" role="alert">
          <span i18n>Escriba en la pantalla el texto para enviar al TEU. <br/>
                      Para mostrar los datos generados de la denúncia, escriba %DATOS%</span>
         </div>
      </div>
      <textarea style="padding-left: 15px;"  cols="80" matInput [value]="this.configuracion.textoLegalResolucion"></textarea>
    </mat-tab>
</mat-tab-group>