import { Component, OnInit, Inject, ElementRef, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Persona } from '../../models/persona.model';
import { Builder } from 'path-shared/models/builder';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { PersonesService } from '../../services/persones.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { FormService } from 'path-shared/services/form/form.service';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Domicili } from '../../models/domiciliDto';
import { DomicilisEditorComponent } from '../domicilis-editor/domicilis-editor.component';
import { VehicleResumen } from '../../../vehicles/models/vehicleDto';
import { Dictionary } from 'path-shared/models/dictionary';
import { PaisosService } from 'app/sections/configuracio/paisos/services/paisos.service.ts.service';

@Component({
  selector: 'app-persones-editor',
  templateUrl: './persones-editor.component.html',
  styleUrls: ['./persones-editor.component.scss']
})
export class PersonesEditorComponent implements OnInit, OnDestroy {

  secureIdPersona: string;
  dniFromInput: string;
  juridicaFromInput : boolean;
  dialogData: ComponentDialog;
  persona: Persona;
  nomComplet: BehaviorSubject<string>;
  public domicilis: BehaviorSubject<Domicili[]>;
  public vehicles: BehaviorSubject<VehicleResumen[]>;

  paisosDictionary = new Array<Dictionary>();
  provinciasDictionary = new Array<Dictionary>();
  municipiosDictionary = new Array<Dictionary>();
  codigosPostales = [];

  displayedColumns: string[] = ['dataDomicili', 'dirSimple', 'numero', 'escala', 'pis', 'porta', 'domicili', 'codiPostal', 'localitat', 'provincia', 'pais', 'actual', /*'idPersones',*/ 'origen', 'id_extern', 'action'];

  @ViewChild('mainForm') private mainForm: ElementRef;
  @ViewChild('domicilisForm') private domicilisForm: ElementRef;

  @Output() itemSelected: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private dialog: MatDialog,
    private i18n: I18n,
    private confirmationDialogService: ConfirmationDialogService,
    public toastr: ToastrService,
    private personesService: PersonesService,
    private formService: FormService,
    public thisDialogRef: MatDialogRef<PersonesEditorComponent>,
    private paisosService: PaisosService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.secureIdPersona = data.id;
    if (data.dni !== undefined && data.dni !== null && data.dni !== '') {
      this.dniFromInput = data.dni;
    } else {
      this.dniFromInput = undefined;
    }
    if (data.juridica !== undefined && data.juridica !== null && data.juridica !== '') {
      this.juridicaFromInput = data.juridica;
    } else {
      this.juridicaFromInput = undefined;
    }
    this.dialogData = data;
    this.persona = Builder.newPersona();
    this.nomComplet = new BehaviorSubject<string>('');
    this.domicilis = new BehaviorSubject(new Array<Domicili>());
    this.getPaisos();
    this.getPronvicias();
    this.getMunicipios();
  }

  ngOnInit() {
    if (this.secureIdPersona !== undefined && this.secureIdPersona !== null) {
      this.loadDataPersona();
    } 
    else if (this.dniFromInput !== undefined) {
      this.persona.dni_pasp = this.dniFromInput;
      if (this.juridicaFromInput !== undefined) { 
        this.persona.juridica = this.juridicaFromInput;
      }
      this.NewRowDomicili();
    } 
    else {
      this.NewRowDomicili();
    }
  }
  loadDataPersona() {
    this.personesService.getPersona(this.secureIdPersona).subscribe(result => {
      this.persona = result;
      this.getDomicilis();
      this.canviarNombreCompleto();
      // this.getVehiclesPropietat();
    });
  }
  canviarNombreCompleto() {
    if (this.persona.juridica) {
      this.nomComplet.next(this.persona.nom);
    } else {
      if (((this.persona.cog1 + this.persona.cog2 + this.persona.nom) === '' || (this.persona.cog1 === null && this.persona.cog2 === null && this.persona.nom === null))
        && this.persona.nomComplet !== '') {
        this.nomComplet.next(this.persona.nomComplet);
      } else {
        let nomFinal = '';
        if (this.persona.cog1 !== null && this.persona.cog1 !== '') {
          nomFinal = this.persona.cog1;
        }
        if (this.persona.cog2 !== null && this.persona.cog2 !== '') {
          nomFinal = nomFinal + ' ' + this.persona.cog2;
        }
        if (this.persona.nom !== null && this.persona.nom !== '') {
          nomFinal = nomFinal + ', ' + this.persona.nom;
        }
        this.nomComplet.next(nomFinal);
      }
    }

  }

  save(fromDomicili = false): Observable<Boolean> {
    const saved = new Subject<boolean>();
    if (this.formService.allFieldsValid(this.mainForm)) {
      this.persona.nomComplet = this.nomComplet.value;
      this.personesService.setPersona(this.persona).subscribe((x: Persona) => {
        this.toastr.success(this.i18n('Persona guardada correctament'));
        this.secureIdPersona = x.secure_id;
        this.persona.id = x.id;
        if (fromDomicili) {
          saved.next(true);
          return;
        }
        this.domicilis.value.forEach(domicili => {
          this.addDomicili(domicili);
        });

        this.loadDataPersona();
        // this.dialogData.isReadOnly =true;
        // this.thisDialogRef.close({codi: x.secure_id, secure_id: x.secure_id, nomComplet: x.nomComplet, dni_pasp: x.dni_pasp, secureIdDom: x.SECURE_ID_DOMICILI_ACTUAL});
      });
    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
      saved.next(false);
    }

    return saved.asObservable();
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    if (this.persona.secure_id !== undefined && this.persona.secure_id !== null && this.persona.secure_id !== '') {
      this.thisDialogRef.close({ codi: this.persona.id, secure_id: this.persona.secure_id, nomComplet: this.persona.nomComplet, dni_pasp: this.persona.dni_pasp, secureIdDom: this.persona.SECURE_ID_DOMICILI_ACTUAL });

    } else {
      this.thisDialogRef.close(false);
    }
  }
  cancel() {
    if (this.persona.secure_id !== undefined && this.persona.secure_id !== null && this.persona.secure_id !== '') {
      this.personesService.getPersona(this.secureIdPersona).subscribe(result => {
        // this.persona = result;
        this.thisDialogRef.close({ codi: result.id, secure_id: result.secure_id, nomComplet: result.nomComplet, dni_pasp: result.dni_pasp, secureIdDom: result.SECURE_ID_DOMICILI_ACTUAL });
      });
    } else {
      this.thisDialogRef.close(false);
    }

    // this.thisDialogRef.close({codi: x.secure_id, secure_id: x.secure_id, nomComplet: x.nomComplet, dni_pasp: x.dni_pasp, secureIdDom: x.SECURE_ID_DOMICILI_ACTUAL});this.thisDialogRef.close(false);
  }

  close() {
    if (this.persona.secure_id !== undefined && this.persona.secure_id !== null) {
      this.personesService.getPersona(this.secureIdPersona).subscribe(result => {
        // this.persona = result;
        this.thisDialogRef.close({ codi: result.id, secure_id: result.secure_id, nomComplet: result.nomComplet, dni_pasp: result.dni_pasp, secureIdDom: result.SECURE_ID_DOMICILI_ACTUAL });
      });
    } else {
      this.thisDialogRef.close(false);
    }

    // this.thisDialogRef.close({codi: x.secure_id, secure_id: x.secure_id, nomComplet: x.nomComplet, dni_pasp: x.dni_pasp, secureIdDom: x.SECURE_ID_DOMICILI_ACTUAL});this.thisDialogRef.close(false);
  }

  getDomicilis() {
    this.personesService.getDomicilis(this.secureIdPersona).subscribe(dom => {
      const domicilisAct: Domicili[] = Object.assign([], dom);
      this.domicilis.next(domicilisAct);
    }); 
  }

  getDomicilisWithNewRow() {
    this.personesService.getDomicilis(this.secureIdPersona).subscribe(dom => {
      const domicilisAct: Domicili[] = Object.assign([], dom);
      this.NewRowDomiciliActualFalse(domicilisAct);
      this.domicilis.next(domicilisAct);
    }); 
  }

  NewRowDomicili(domicilisAct = new Array<Domicili>()) {
    const newDom = new Domicili();
    newDom.dataDomicli = new Date();
    newDom.actual = true;
    newDom.idPersones = this.persona.id;
    newDom.id = -1;
    domicilisAct.push(newDom);
    this.domicilis.next(domicilisAct);
  }

  NewRowDomiciliActualFalse(domicilisAct) {
    const newDom = new Domicili();
    newDom.dataDomicli = new Date();
    
    newDom.idPersones = this.persona.id;
    newDom.id = -1;
    newDom.actual = true;

    for(let x of domicilisAct) {
      x.actual = false;
    }
    domicilisAct.push(newDom)
    this.domicilis.next(domicilisAct);
  }

  getVehiclesPropietat() {
    this.personesService.getVehiclesPropietat(this.secureIdPersona).subscribe(veh => {
      const vehiclesAct: VehicleResumen[] = Object.assign([], veh);
      this.vehicles.next(vehiclesAct);
    });
  }

  noFinalCheckChangd(event) {
    // if (!event) {
    //   this.matricula.dataFi = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    // }
  }

  remove() {
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol esborrar la persona?'))
      .then((confirmed) => {
        if (confirmed) {
          // const saved = this.denunciesService.saveDenuncia(dataJson).subscribe();
          this.personesService.deletePersona(this.secureIdPersona).subscribe(result => {
            this.toastr.success(this.i18n('Persona esborrada correctament'));
            this.dialog.closeAll();
            this.thisDialogRef.close(true);
          });
        }
      });
  }

  showWarning() {
    this.toastr.warning(this.i18n('No se puede añadir un domicilio sin haber guardardo la persona con antelación!'));
  }

  openDialog(action, obj) {
    if (action === 'Add' && this.persona.id === -1) {
      this.toastr.warning(this.i18n('No se puede añadir un domicilio sin haber guardardo la persona con antelación!'));
      return;
    } else if (action === 'Delete') {
      this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Seguro que desea eliminar el domicilio?'))
        .then((confirmed) => {
          if (confirmed) {
            this.personesService.deleteDomicili(obj).subscribe(res => {
              this.toastr.success(this.i18n('Domicilio eliminado correctamente'));
              this.getDomicilis();
            });
          }
        });
      return;
    }
    obj.action = action;
    const dialogRef = this.dialog.open(DomicilisEditorComponent, {
      width: '50%',
      height: '55%',
      closeOnNavigation: false,
      autoFocus: true,
      data: { 'action': action, 'domicili': obj }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'Add') {
        this.addDomicili(result.data);
      } else if (result.event === 'Update') {
        this.updateDomicili(result.data);
      } else if (result.event === 'Delete') {
        this.deleteDomicili(result.data);
      }
    });
  }

  addDomicili(row_obj: Domicili) {
    if (row_obj.dirSimple === undefined || row_obj.dirSimple === '') {
      row_obj.dirSimple = undefined;
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.domicilisForm.nativeElement.classList.contains('displayErrors')) {
        this.domicilisForm.nativeElement.classList.add('displayErrors');
      }
    } else {
      row_obj.origen = 'SDW';

      if (row_obj.idPersones === -1) {
        this.save(true).subscribe(saved => {
          if (!saved) {
            return;
          } else if (saved) {
            row_obj.idPersones = this.persona.id;
            this.personesService.setDomicili(row_obj).subscribe(res => {
              this.toastr.success(this.i18n('Domicilio añadido correctamente'));
              // this.getDomicilis();
              this.loadDataPersona();
              if (this.domicilisForm.nativeElement.classList.contains('displayErrors')) {
                this.domicilisForm.nativeElement.classList.remove('displayErrors');
              }
            });
          }
        });
      } else {
        this.personesService.setDomicili(row_obj).subscribe(res => {
          this.toastr.success(this.i18n('Domicilio añadido correctamente'));
          // this.getDomicilis();
          this.loadDataPersona();
          if (this.domicilisForm.nativeElement.classList.contains('displayErrors')) {
            this.domicilisForm.nativeElement.classList.remove('displayErrors');
          }
        });
      }
    }


  }
  updateDomicili(row_obj: Domicili) {
    row_obj.idPersones = this.persona.id;
    this.personesService.setDomicili(row_obj).subscribe(res => {
      this.getDomicilis();
    });
  }

  deleteDomicili(row_obj) {
    this.personesService.deleteDomicili(row_obj).subscribe(res => {
      this.toastr.success(this.i18n('Domicilio eliminado correctamente'));
      this.getDomicilis();
    });
  }

  setDireccioCompleta(element: Domicili) {
    if (element.domicili === undefined || element.domicili === null) {
      element.domicili = '';
    }
    let dir = '';
    if (element !== undefined && element !== null) {
      if (element !== undefined && element !== null && element.dirSimple !== undefined && element.dirSimple !== null && element.dirSimple !== '') {
        dir = element.dirSimple;
      }
      if (element !== undefined && element.numero !== undefined && element.numero !== null && element.numero !== '') {
        dir = dir + ' Nº ' + element.numero;
      }

      if (element !== undefined && element.escala !== undefined && element.escala !== null && element.escala !== '') {
        dir = dir + ' ESC ' + element.escala;
      }
      if (element !== undefined && element.pis !== undefined && element.pis !== null && element.pis !== '') {
        dir = dir + ' ' + element.pis;
      }
      if (element !== undefined && element.porta !== undefined && element.porta !== null && element.porta !== '') {
        dir = dir + ' ' + element.porta;
      }
      if (dir === '' && element !== undefined && element.domicili !== '' && element.domicili !== undefined) {
        dir = element.domicili;
      }
    }

    element.domicili = dir;
  }

  getPaisos() {
    this.paisosDictionary = new Array<Dictionary>();
    this.paisosService.getPaisosCombo().subscribe(newValue => {
      newValue.forEach(element => {
        this.paisosDictionary.push(new Dictionary(element.secureId.toString(), element.descripcio));
      });
    });
  }

  getPronvicias() {
    this.provinciasDictionary = new Array<Dictionary>();
    this.paisosService.getProvinciasCombo().subscribe(newValue => {
      newValue.forEach(element => {
        this.provinciasDictionary.push(new Dictionary(element.secureId.toString(), element.nom));
      });

    });
  }

  getMunicipios() {
    this.municipiosDictionary = new Array<Dictionary>();
    this.paisosService.getCodigosPostales().subscribe(newValue => {
      this.codigosPostales = newValue;
      newValue.forEach(element => {
        this.municipiosDictionary.push(new Dictionary(element.secureId.toString(), element.nom));
      });
    });
  }

  autofillByCP(element: Domicili) {
    if (element.codiPostal !== undefined && element.codiPostal.length === 5) {
      const cps = this.codigosPostales.filter(x => x.codiPostal === element.codiPostal);
      const cp = this.codigosPostales.find(x => x.codiPostal === element.codiPostal);
      if (cp !== undefined) {
        if (cps !== undefined && cps.length > 1) {
          this.municipiosDictionary = new Array<Dictionary>();
          cps.forEach(el => {
            this.municipiosDictionary.push(new Dictionary(el.secureId.toString(), el.nom));
          });
          element.localitat = '';
          element.provincia = this.provinciasDictionary.find(x => x.key === cp.SECURE_ID_Provincia).value;
          element.pais = this.paisosDictionary.find(x => x.key === '102000000000002600001|138B8D0D213530F7298780F6CA89ABA8').value;
        } else {
          element.localitat = cp.nom;
          element.provincia = this.provinciasDictionary.find(x => x.key === cp.SECURE_ID_Provincia).value;
          element.pais = this.paisosDictionary.find(x => x.key === '102000000000002600001|138B8D0D213530F7298780F6CA89ABA8').value;
        }
      }
    } else {
      this.municipiosDictionary = new Array<Dictionary>();
      this.codigosPostales.forEach(el => {
        this.municipiosDictionary.push(new Dictionary(el.secureId.toString(), el.nom));
      });
      element.localitat = '';
      element.provincia = '';
      element.pais = '';
    }
  }
}
