import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ZbHorariosFilter } from "./zb-horarios-filter";
import { CustomFilterService } from "path-shared/services/datasource/custom-filter-service";


@Injectable()
export class ZbHorariosFilterService extends CustomFilterService{
  public createFilterParams(filter: ZbHorariosFilter , start: number, pageSize: number, sortField: string, sortDir: string ) {
    return new HttpParams()
    .append('somId',filter.somId ? filter.somId.toString() : '')
    .append('name',filter.nombre ? filter.nombre.toString() : '')
    .append('description', filter.descripcion ? filter.descripcion.toString() : '')
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField)
    .append('sortdir', sortDir);
  }
}