import { Component, OnInit, Inject, AfterViewInit, ViewChild } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { SincronitzacioService } from '../../services/sincronitzacio.service';
import { ToastrService } from 'ngx-toastr';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';

@Component({
  selector: 'app-sincronitzacio',
  templateUrl: './sincronitzacio.component.html',
  styleUrls: ['./sincronitzacio.component.scss']
})
export class SincronitzacioComponent implements OnInit {

  completa: boolean;
  lastSyncDatePdas: Date;
  lastSyncDateDiba: Date;
  attention = false;

  constructor(
    private i18n: I18n,
    private not: NotificationService,
    private sincronitzacioService: SincronitzacioService,
    private toastr: ToastrService,
    public auth: AuthorizationService
  ) {
   }

  ngOnInit() {
    if (document.getElementById('syncGtwinRequired') !== null) {
      this.attention = true;
    }
    this.not.titleChange(this.i18n('Sincronització'));
    this.GetLastSyncDates();
  }

  actualitzar() {

    this.sincronitzacioService.actualitzar(this.completa).subscribe(result => {
      if (result === -1) {
        this.toastr.error(this.i18n('Error sincronitzant PDA '));
      } else {
        this.toastr.success(this.i18n('Sincronització dels SmartPhones realitzada!'));

      }
      this.GetLastSyncDates();
    });
  }
  /**Ejecuta el proceso de sincronizar la BBDD con los datos de DIBA */
  sincronitzarDiba() {

    this.sincronitzacioService.syncDyba().subscribe(() => {
      this.toastr.success(this.i18n('Sincronització amb DIBA realitzada!'));
      this.GetLastSyncDates();
    });
  }

  /**Ejecuta el proceso de sincronizar la BBDD con los datos de GTWIN */
  sincronitzarGtwin() {

    this.sincronitzacioService.syncGtwin().subscribe(() => {
      this.toastr.success(this.i18n('Sincronització amb GTWIN realitzada!'));
      this.GetLastSyncDates();
      this.not.onGtwinSyncChange.emit(false);
      this.attention = false;
    });
  }

  /**Obtiene las fechas de última sincronización de PDAs y DIBA */
  private GetLastSyncDates() {
    this.sincronitzacioService.getLastSyncDate().subscribe(res => {
      this.lastSyncDatePdas = res[0];
      this.lastSyncDateDiba = res[1];
    });
  }
}
