import { EnviosTableComponent } from './../../../components/envios-table/envios-table.component';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { Builder } from 'path-shared/models/builder';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { EnviosPagedRequestDto } from '../../../models/envios-filter';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { EnviosFilterComponent } from '../../../components/envios-filter/envios-filter.component';
import { NotificationService } from 'path-shared/services/notification/notification.service';

@Component({
  selector: 'app-envios',
  templateUrl: './envios.component.html',
  styleUrls: ['./envios.component.scss']
})
export class EnviosComponent implements OnInit, AfterViewInit  {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  selected = new FormControl(0);
  filter: EnviosPagedRequestDto = new EnviosPagedRequestDto('', '');
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  @ViewChild('table') EnviosTable: EnviosTableComponent;

  constructor( public authService: AuthorizationService,
    private not: NotificationService,
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private i18n: I18n) {
      this.filter = new EnviosPagedRequestDto('', '');
    }

  ngOnInit() {
    this.authService.CheckUserPermission(this.authService.Pages.Tramitar);
  }

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Envios'));
    }
  }

  onFilterClicked() {
    const ref = this.dialog.open(EnviosFilterComponent, {
      width: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.EnviosTable.filter
      }
    });
  }

  
  refresh() {
    this.EnviosTable.load();
  }
generateExcel() {
    //this.EnviosTable.generateExcel();
  }


}
