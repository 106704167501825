import { BoeService } from './../../services/boe.service';
import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { EnvioBOEFilterDto } from '../../models/envio-boe-filter.model';
import { DenunciaBOPDto } from '../../models/envio-boe-denuncia.model';
import { GenericFile } from 'path-shared/components/generic-file-displayer/model/generic-file';
import { saveAs } from 'file-saver';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { SelectorFechasNotificarComponent } from 'app/sections/tramitar/expedients/components/notificar/components/selector-fechas-notificar/selector-fechas-notificar.component';
import { FechasNotificacionDto } from 'app/sections/tramitar/expedients/models/notificar/fechas-notificacion.dto';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { filter } from 'rxjs/operators';
import { ProgressBarTramitacionDto } from 'app/sections/tramitar/expedients/models/ProgressBarTramitacionDto';
import { NotificarRequestDto } from 'app/sections/tramitar/expedients/models/notificar/notificar-request.dto';
import { NotificarInicioRequestDto } from 'app/sections/tramitar/expedients/models/notificar/notificar-inicio-request.dto';
import { NotificarService } from 'app/sections/tramitar/expedients/services/notificar/notificar.service';
import { ToastrService } from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';
@Component({
  selector: 'app-envio-bop',
  templateUrl: './envio-bop.component.html',
  styleUrls: ['./envio-bop.component.scss']
})
export class EnvioBopComponent implements OnInit {
  public filter: EnvioBOEFilterDto;
  public denuncias: DenunciaBOPDto[];
  public resumen: any;
  public autoInit: boolean=false;
  public autoInitDenuncias: boolean=false;
  public autoInitAlegaciones: boolean=false;
  public autoInitRecursoAlegaciones: boolean=false;
  public autoInitResoluciones: boolean=false;
  public autoInitSancionesFimres: boolean=false;
  
  porcentajeC60: number;
  mensajePorcentajeC60: string='';
  @ViewChild('myDialogC60') myDialogC60: TemplateRef<any>;

  constructor(
    private notificarService: NotificarService,
    private boeService: BoeService,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private not: NotificationService,
    private i18n: I18n,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    if (data !== undefined && data.autoInit !== undefined && data.autoInit === true) {
      this.autoInit = true;
      if(data.autoInitDenuncias !== undefined && data.autoInitDenuncias === true){
        this.autoInitDenuncias = true;
      }
      if(data.autoInitAlegaciones !== undefined && data.autoInitAlegaciones === true){
        this.autoInitAlegaciones = true;
      }
      if(data.autoInitRecursoAlegaciones !== undefined && data.autoInitRecursoAlegaciones === true){
        this.autoInitRecursoAlegaciones = true;
      }
      if(data.autoInitResoluciones !== undefined && data.autoInitResoluciones === true){
        this.autoInitResoluciones = true;
      }
      if(data.autoInitSancionesFimres !== undefined && data.autoInitSancionesFimres === true){
        this.autoInitSancionesFimres = true;
      }
    }
    this.initFilter();
    this.initiDataSource();
    if(this.autoInit){
      this.filter.envioDenuncias = this.autoInitDenuncias;
      this.filter.envioAlegaciones = this.autoInitAlegaciones;
      this.filter.envioRecursoAlegaciones = this.autoInitRecursoAlegaciones;
      this.filter.envioResoluciones = this.autoInitResoluciones;
      this.filter.envioSancionesFirmes = this.autoInitSancionesFimres;
      this.filtrar();
    }
  }

  ngOnInit() {
  }
  initFilter(){
    this.filter= {
      envioDenuncias: false,
      envioAlegaciones: false,
      envioRecursoAlegaciones: false,
      envioSancionesFirmes:  false,
      envioResoluciones:  false,
      tipusExportacion:1,
      fechaPub: new Date(),
      fechaEdicto: new Date(),
    };
  }
  initiDataSource(){
    this.denuncias=[];
  }
  filtrar() {
    if (this.anyTipoEnvio()) {
      this.boeService.getDenuncias(this.filter).subscribe(x => {
        if(x !== undefined && x !== null) {
          this.denuncias = x.denuncias;
          this.resumen = {
            numDenuncias: x.numDenuncias,
            numAlegaciones:x.numAlegaciones,
            numRecursosAlegaciones: x.numRecursosAlegaciones,
            numSancionesFirmes: x.numSancionesFirmes,
            numResoluciones:x.numResoluciones 
          };
        }
      });
    }
    else {
      this.toastr.warning(this.i18n('Seleccione un tipo de envío'))
    }
  }
  historico() {
    alert('historico');
  }
  publish() {      
    this.initProgressBar();
    this.boeService.generateFileBoe(this.filter).subscribe((file:GenericFile) => {
      this.downloadFile(file);
      this.toastr.success(this.i18n('Fichero generado correctamente'));
      //this.refresh();
    });
  }

  downloadFile(file: GenericFile): void {
    const bytearray: Uint8Array = this.getDisplayable(file.fichero);
    const blob = new Blob([bytearray]);
    saveAs(blob, file.nombre);
  
  }
  getDisplayable(dataURI: string): Uint8Array {
    const raw = window.atob(dataURI);
    const rawLength = raw.length;
    const array = new Uint8Array(new ArrayBuffer(rawLength));

    let i = 0;
    for (i; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }

  initProgressBar(){
    // region Progress Bar
    this.not.onBOEC60Change.pipe(
      filter(
        (ProgressBar:ProgressBarTramitacionDto)=>
        ProgressBar.token===sessionStorage.getItem('access_token')
      )
    )
    .subscribe((progressBar:ProgressBarTramitacionDto) => {
        if (this.porcentajeC60 === undefined || this.porcentajeC60 === null) {
          this.dialog.open(this.myDialogC60, { disableClose: true, width: '25%', height: '12%', id: 'porcentajeC60', position: { bottom: '15%' }});
        }
        this.mensajePorcentajeC60 = progressBar.mensaje;
        this.porcentajeC60 = progressBar.porcentaje;
        if (this.porcentajeC60 === 100) {
          this.porcentajeC60 = null;
          this.toastr.success(this.i18n('C60s generadas correctamente'));
          this.dialog.getDialogById('porcentajeC60').close();
        }
      });
  }

  anyTipoEnvio() {
    if (this.filter.envioDenuncias ||
        this.filter.envioAlegaciones ||
        this.filter.envioRecursoAlegaciones ||
        this.filter.envioResoluciones ||
        this.filter.envioSancionesFirmes) 
      return true;
    else 
      return false
  }
}


