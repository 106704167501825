<div class="row">
  
  <!-- Componente de mapa -->
  <app-map-component #map class="col-9 map" [zoom]="this.zoomMapa" (markerRouteEvent)="this.navigateToDetail($event)"></app-map-component>  

  <!-- Componente de leyenda -->
  <app-leyenda-map-component class="col-3" (featureChanges)="this.updateMap($event)" (hoverVisivilityChange)="this.changeHoversVisibility($event)"></app-leyenda-map-component>
</div>




