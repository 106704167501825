<app-dialog-header label="Textos predefinidos" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Editar" i18n-text (click)="this.dialogData.isReadOnly = false" color="has-color-green" icon="fa-edit" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Siglas) || !this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Guardar" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Siglas) || this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Eliminar" i18n-text (click)="this.remove();" color="has-color-red" icon="fa-trash-alt" [disabled]="!this.auth.UserCanDelete(this.auth.Pages.Siglas) || this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button [text]="this.closeButtonText" i18n-text (click)="this.cancel()" color="has-color-yellow" icon="fa-th-list"></app-tool-button>
</app-toolbar>
<app-dialog-body>
  <form #mainForm>
    <div class="row">
      <app-form-text-edit id="textPredefinit-editor-codi" class="col-sm-4" i18n-label label="Codi" [(value)]="this.cfgTexto.SOM_ID" disabled="true"></app-form-text-edit>
      <app-form-text-edit id="textPredefinit-editor-descripcio" class="col-sm-4" i18n-label label="Codi" [(value)]="this.cfgTexto.CODI" required maxLenght="50"></app-form-text-edit>
      <app-form-text-edit id="textPredefinit-editor-descripcio" class="col-sm-4" i18n-label label="Alias" [(value)]="this.cfgTexto.ALIAS" required maxLenght="250"></app-form-text-edit>
      <!-- <app-form-text-edit id="textPredefinit-editor-descripcio" class="col-sm-9" i18n-label label="Descripcio" [(value)]="this.cfgTexto.DESCRIPCION" required maxLenght="50"></app-form-text-edit> -->
      <app-form-text-area [(value)]="this.cfgTexto.DESCRIPCION" id="form-alegaciones-alegacion" class="col-12" i18n-label label="Descripcio" required></app-form-text-area>
    </div>
  </form>
</app-dialog-body>
