import { Component, OnInit, Inject, AfterViewInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Builder } from 'path-shared/models/builder';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { PermissionDialogComponent } from 'path-shared/components/permission-dialog/permission-dialog.component';
import { RecaudacioEditorComponent } from '../components/recaudacio-editor/recaudacio-editor.component';
import { RecaudacioFilter } from '../models/recaudacio-filter';
import { RecaudacioTableComponent } from '../components/recaudacio-table/recaudacio-table.component';
import { RecaudacioService } from '../services/recaudacio.service';
import { RecaudacioFilterComponent } from '../components/recaudacio-filter/recaudacio-filter.component';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { ZB_MAQUINES_PARKEON } from 'path-models/ZB_MAQUINES_PARKEON';

@Component({
  selector: 'app-recaudacio-page',
  templateUrl: './recaudacio-page.component.html',
  styleUrls: ['./recaudacio-page.component.scss']
})
export class RecaudacioPageComponent implements OnInit, AfterViewInit {

  permissionDialogRef: MatDialogRef<PermissionDialogComponent>;
  activeFilters: string[] = [];
  filterData: RecaudacioFilter;
  somIdFoo: number;
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  zones: any[];
  maquinesParkeon: ZB_MAQUINES_PARKEON[];

  @ViewChild(RecaudacioTableComponent) recaudacioTable: RecaudacioTableComponent;

  constructor(
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private i18n: I18n,
    private not: NotificationService,
    private confirmationDialogService: ConfirmationDialogService,
    private recaudacioService: RecaudacioService,
    public thisDialogRef: MatDialogRef<RecaudacioPageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
    ) {
      this.filterData = new RecaudacioFilter('id', 'desc');
      if (data !== undefined) {
        this.dataDialog = data;
      }
    }

  ngOnInit(): void {
    this.auth.UserCanSeePage(this.auth.Pages.Recaudacio);

    this.loadZones();
    this.loadMaquinesParkeon();
  }

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Recaptació'));
    }
  }

  create() {
    this.update(null);
  }

  itemClicked(secure_id: string) {
    this.update(secure_id);
  }

  update(secure_id: string) {
    const ref = this.dialog.open(RecaudacioEditorComponent, {
      width: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      disableClose: true,
      data: {
        'isDialog': true,
        'isReadOnly': false,
        'id': secure_id,
        'zones': this.zones,
        'maquinesParkeon': this.maquinesParkeon
      }
    });

    ref.backdropClick().subscribe(backDrop => {
      this.confirmBeforeClose(ref);
    });

    ref.afterClosed().subscribe(updated => {
      if (updated) { this.recaudacioTable.load(); }
    });
  }

  refresh() {
    this.loadZones();
    this.loadMaquinesParkeon();
    this.recaudacioTable.load();
  }

  onFilterClicked() {
    const ref = this.dialog.open(RecaudacioFilterComponent, {
      width: '60%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.recaudacioTable.filter,
        'zones': this.zones,
        'maquinesParkeon': this.maquinesParkeon
      }
    });

    ref.componentInstance.notify.subscribe((filter: RecaudacioFilter) => {
      this.recaudacioTable.filter.set(filter);
      this.recaudacioTable.load();
    });
  }

  generateExcel() {
    this.recaudacioTable.generateExcel();
  }

  loadZones() {
    this.zones = [];
    this.recaudacioService.getZones().subscribe(zones => {
      zones.forEach(zona => {
        this.zones.push({'id': zona.id, 'descripcio': zona.descripcio});
      });
    });
  }

  loadMaquinesParkeon() {
    this.maquinesParkeon = [];
    this.recaudacioService.getMaquinesParkeon().subscribe((maquinesParkeon: ZB_MAQUINES_PARKEON[]) => {
      this.maquinesParkeon = maquinesParkeon;
    });
  }

  confirmBeforeClose(ref: any) {
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol tancar el formulari? Perdrà els canvis si no els guarda.'))
      .then((confirmed) => {
        if (confirmed) {
          ref.close();
        }
      });
  }

}
