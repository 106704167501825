import { ResolucionesResponseDto } from './../../models/notificar/resolucionesResponseDto';
import { NotificarFilterService } from './notificar-filter.service';
import { NotificarInicioRequestDto } from '../../models/notificar/notificar-inicio-request.dto';
import { NotificarRequestDto } from '../../models/notificar/notificar-request.dto';
import { FechasNotificacionDto } from '../../models/notificar/fechas-notificacion.dto';
import { Injectable } from '@angular/core';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { Observable } from 'rxjs';
import { NotificacionTramitarResponseDto } from '../../models/notificar/notificacion-tramitar-response.dto';
import { NotificacionsDenunciaFilter } from 'app/sections/notificacions/models/NotificacionDenuncia/notificacions-denuncia-filter';
import { TramitarNotificacionsDenuncies } from 'app/sections/notificacions/models/NotificacionDenuncia/tramitar-notificacions-denuncia';
import { NotificacionsDenunciesFilterService } from 'app/sections/notificacions/services/notificacions-denuncies-filter.services';
import { ResultadoNotificacionDenunciasCurrent } from '../../models/notificar/resultado-notificacion-denuncias-current.dto';
import { ResolucionFilter } from 'app/sections/notificacions/models/NotificacionDenuncia/resolucion-filter';
import { ResolucionsFilterService } from 'app/sections/notificacions/services/resolucions-filter.service';


@Injectable({
    providedIn: 'root'
})
export class NotificarService {

    constructor(
        private notificarFilterService: NotificarFilterService,
        private http: HttpSecureClient,
        private authGuard: AuthGuardService
        ) { }


    inicioProcesoNotificacion(inicioNotificarInfo: NotificarInicioRequestDto): Observable<any> {
        if (this.authGuard.canActivate()) {
            const filter = this.notificarFilterService.createFilterParams(inicioNotificarInfo);
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlInicioProcesoNotificar'], filter);
        }
    }

    iniciaProcesoNotificarDenuncias(fechas: NotificarRequestDto,filtro:NotificacionsDenunciaFilter): Observable<any> {
        const filterService= new NotificacionsDenunciesFilterService();
        const params = filterService.createFilterParams(filtro, 0, 20,'exp','asc');
        if (this.authGuard.canActivate()) {
            return this.http.postWithParams(config.urls['apiUrl'] + api.endpoints['urlContinuaProcesoNotificarDenuncias'], fechas, params);
        }
    }

    finalizaProcesoNotificarDenuncias(notificaciones: Array<NotificacionTramitarResponseDto>): Observable<any> {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlFinalizaProcesoNotificarDenuncias'], notificaciones);
        }
    }
    iniciaProcesoNotificarPropuestasResolucion(fechas: NotificarRequestDto): Observable<any> {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlContinuaProcesoNotificarPropuestasResolucion'], fechas);
        }
    }
    finalizaProcesoNotificarPropuestasResolucion(notificaciones: Array<NotificacionTramitarResponseDto>): Observable<any> {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlFinalizaProcesoNotificarPropuestasResolucion'], notificaciones);
        }
    }

    procesarResoluciones(filtro:ResolucionFilter): Observable<any> {
        if (this.authGuard.canActivate()) {
            const filterService= new ResolucionsFilterService();
            const params= filterService.createFilterParams(filtro,0,20,'exp','asc');
            return this.http.postWithParams(config.urls['apiUrl'] + api.endpoints['urlProcesaResoluciones'], null,params);
        }
    }
    finalizaResoluciones(res: Array<NotificacionTramitarResponseDto>): Observable<any> {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlProcesaFinalizaResoluciones'], res);
        }
        return null;
    }
    iniciaProcesoNotificarNotificacionesResoluciones(fechas: NotificarRequestDto): Observable<any> {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlContinuaProcesoNotificarNotificaciconesResoluciones'], fechas);
        }
    }
    finalizaProcesoNotificarNotificacionesResoluciones(notificaciones: Array<NotificacionTramitarResponseDto>): Observable<any> {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlFinalizaProcesoNotificarNotificacionesResoluciones'], notificaciones);
        }
    }
    iniciaProcesoNotifcarRecursosReposicion(fechas: NotificarRequestDto): Observable<any> {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlContinuaProcesoNotificarRecursosReposicion'], fechas);
        }
    }
    finalizaProcesoNotificarRecursosReposicion(notificaciones: Array<NotificacionTramitarResponseDto>): Observable<any> {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlFinalizaProcesoNotificarRecursosReposicion'], notificaciones);
        }
    }

    procesarRecursosReposiciones(): Observable<any> {
        if (this.authGuard.canActivate()) {
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlProcesaNotificacionRecusosReposiciones'], null);
        }
    }

    finalizaProcesoNotificarPropuestasReposicion(): Observable<any> {
        if (this.authGuard.canActivate()) {
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlFinalizaProcesoNotificarPropuestasReposicion'], null);
        }
    }
    undoEnvio(secureId: string){
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlUndoEnvio'].replace('{secureId}', secureId), null);
        }
    }
    updateNotificaciones(notificaciones: Array<NotificacionTramitarResponseDto>):Observable<any>{
        if(this.authGuard.canActivate()){
            try{
                console.log(config.urls['apiUrl']+ api.endpoints['urlUpdateNotificaciones']);
            let result= this.http.post(config.urls['apiUrl']+ api.endpoints['urlUpdateNotificaciones'],notificaciones);
            return result;
            }catch(e){
                console.log(e);
            }
            return null;
        }
    }
    
}
