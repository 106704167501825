import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-usuaris',
  templateUrl: './usuaris.component.html'
})
export class UsuarisComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
