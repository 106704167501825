<app-filter-header title="Filtrar Tiquets" i18n-title  (apply)="this.apply()" (reset)="this.reset()" (dismiss)="this.dismiss()"></app-filter-header>
<div class="modal-body">
  <div class="row">
    <div class="col-5">
      <app-form-radio-group i18n-title title="Tipus de data" [labels]="this.typeLabels" [values]="this.typeValues" [(value)]="this.tipusData" (click)="resetDates(this.tipusData)"></app-form-radio-group>
    </div>
    <div class="col-7">
      <div class="row">
        <app-form-date-edit id="tiquets-filter-dataIni" class="col-sm-4" i18n-label label="Data tiquet inici" id="fechaTicketInicio" 
          [(value)]="filter.fechaTicketInicio" type="calendar" [maxDate]="this.fechaHoraActual" [isReadOnly]="!this.tipusData"></app-form-date-edit>
        <app-form-date-edit id="tiquets-filter-dataFi" class="col-sm-4" i18n-label label="Data tiquet fi" id="fechaTicketFin" 
          [(value)]="filter.fechaTicketFin" type="calendar" [maxDate]="this.fechaHoraActual" [isReadOnly]="!this.tipusData"></app-form-date-edit>
      </div>
      <div class="row">
        <app-form-date-edit id="tiquets-filter-dataCobrIni" class="col-sm-4" i18n-label label="Data banc inici" id="fechaBancoInicio" 
          [(value)]="filter.fechaBancoInicio" type="calendar" [maxDate]="this.fechaHoraActual" [isReadOnly]="this.tipusData"></app-form-date-edit>
        <app-form-date-edit id="tiquets-filter-dataCobrFi" class="col-sm-4" i18n-label label="Data banc fi" id="fechaBancoFin"
          [(value)]="filter.fechaBancoFin" type="calendar" [maxDate]="this.fechaHoraActual" [isReadOnly]="this.tipusData"></app-form-date-edit>
      </div>
    </div>
  </div>

  <div class="row">
    <app-form-text-edit id="tiquets-filter-email" class="col-4" i18n-label label="Email usuari" [(value)]="filter.email"></app-form-text-edit>
    <app-form-text-edit id="tiquets-filter-matricula" class="col-4" i18n-label label="Matrícula" [(value)]="filter.matricula"></app-form-text-edit>
    <app-form-combo-edit class="col-2" i18n-label label="Zona"  [(value)]="secureIdZona"
    [items]="zonas" valueField="secureID" textField="descripcio"  (valueChange)="onZonaChange()"></app-form-combo-edit>
  </div>
</div>

