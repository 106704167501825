import { Component, OnInit, Inject } from '@angular/core';
import { NotificacionsService } from '../../services/notificacions.service';
import { Resposta } from '../../models/resposta.model';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-respostes',
    templateUrl: 'respostes.component.html',
    styleUrls: ['respostes.component.scss']
})
export class RespostesComponent implements OnInit {

    dataSource: Resposta[];
    displayedColumns = ['dataResultat', 'tipus', 'resultat', 'descResultat', 'acceptada', 'refExt', 'observacions'];
    notificacioId: number;
    element: number;
    notificacionesFromProceso: any;
    fixedDataSource:boolean = false;
    constructor (
        private notificacionsService: NotificacionsService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        ) {
            this.notificacioId = data.notificacioId;
           
    if(data !== undefined && data !== null){
      if(data.element !== undefined && data.element !== null){
        this.element = data.element;
      }
      if(data.noficiaciones !== undefined && data.noficiaciones !== null && data.noficiaciones.length>0){
        this.notificacionesFromProceso = data.noficiaciones;
        this.fixedDataSource = true;
      }
    }
        }
    ngOnInit() {
        if(this.fixedDataSource){
            this.dataSource = this.notificacionesFromProceso;
        }
        else{
            this.notificacionsService.GetRespostes(this.notificacioId, this.element).subscribe(res => {
                this.dataSource = res;
            });
        }
    }
}
