<app-dialog-header label="Notificacions" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Filtre" i18n-text (click)="onFilterClicked();" color="has-color-orange" icon="fa-filter"></app-tool-button>
  <app-tool-button-permissions [permission]="this.auth.Pages.NotificacionsExpedient"></app-tool-button-permissions>
</app-toolbar>
<app-dialog-body>
  

  <div class="row justify-content-between" >
    <div class="col">
      <app-filter-selector [filter]="filter"></app-filter-selector>
    </div>
    <div class="col" style="margin-top:10px">
      <app-form-tri-state [labelSi]="'SICER'" [labelNo]="'DEV'" [labelTodos]="'TOTS'" (valueChange)="setFilter($event)"></app-form-tri-state>
    </div>    
    <div class="col">
      <mat-paginator #paginator
        [pageIndex]="0"
        [pageSize]="this.filter.paginator.pageSize"
        [pageSizeOptions]="[20, 40, 60, 100]">
      </mat-paginator>
    </div>
  </div>
  
  <div class="table-sdw is-fixed">
    <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.filter.paginator.sortField" [matSortDirection]="this.filter.paginator.sortDir" class="is-header-sticky">
        <ng-container matColumnDef="element">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Element</mat-header-cell>
          <mat-cell *matCellDef="let row"><a (click)="onRowClicked(row)" class="is-link cursor detalle">{{row.element}}</a></mat-cell>
          </ng-container>
  
        <ng-container matColumnDef="nom">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Nom</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.nom}}</mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="acceptacio">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Acceptació</mat-header-cell>
          <mat-cell *matCellDef="let row"><mat-checkbox [checked]="row.acceptacio" [disabled]='true'></mat-checkbox></mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="poblacio">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Població</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.poblacio}}</mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="cp">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>C. Postal</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.cp}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="dataResp">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data Resp.</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.dataResp | shortDate}}</mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="resultat">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Resultat</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.resultat}}</mat-cell>
        </ng-container>
        
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset"></mat-row>
      </mat-table>
  </div>
</app-dialog-body>