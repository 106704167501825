<!-- Content (Visor HTML) -->
<quill-editor #editorView [placeholder]="this.placeHolderText" [ngModel]="this.textoFormatado" (onContentChanged)="this.contentChanged($event)" [disabled]="this.isReadOnly">
  <!-- Toolbar -->
  <div quill-editor-toolbar>
    <!-- Panel formato texto -->
    <span class="ql-formats">
      <button class="ql-bold" [title]="'Negreta'"></button>
      <button class="ql-italic" [title]="'Cursiva'"></button>
      <button class="ql-underline" [title]="'Subratllat'"></button>
      <button class="ql-strike" [title]="'Tatxat'"></button>
    </span>
    <!-- Panel tamaño texto -->
    <span class="ql-formats">
      <select class="ql-size" [title]="'Mida Text'">
        <option selected></option>
        <option value="small" [title]="'Petita'"></option>
        <option value="medium">Mitjana</option>
        <option value="large">Gran</option>
        <option value="huge">Gegant</option>
      </select>
    </span>
    <!-- Panel color texto -->
    <span class="ql-formats">
      <select class="ql-color" [title]="'Color Text'">
        <option selected></option>
        <option value="#CB4335"></option>
        <option value="#9B59B6"></option>
        <option value="#8E44AD"></option>
        <option value="#2980B9"></option>
        <option value="#3498DB"></option>
        <option value="#1ABC9C"></option>
        <option value="#16A085"></option>
        <option value="#27AE60"></option>
        <option value="#2ECC71"></option>
        <option value="#F1C40F"></option>
        <option value="#F39C12"></option>
        <option value="#E67E22"></option>
        <option value="#ECF0F1"></option>
        <option value="#BDC3C7"></option>
        <option value="#95A5A6"></option>
        <option value="#99A3A4"></option>
        <option value="#34495E"></option>
        <option value="#2C3E50"></option>
        <option value="#FFFFFF"></option>
      </select>
    </span>
    <!-- Panel formato párrafo -->
    <span class="ql-formats">
      <select class="ql-align" [title]="'Alineament'">
        <option selected></option>
        <option value="center"></option>
        <option value="right"></option>
        <option value="justify"></option>
      </select>
    </span>
    <!-- Panel remover formatos -->
    <span class="ql-formats">
      <button class="ql-clean" [title]="'Eliminar format'"></button>
    </span>
    <!-- Panel adjuntar -->
    <span class="ql-formats">
      <button class="ql-link" [title]="'Enllaç'"></button>
      <button class="ql-image" [title]="'Imatge'"></button>
      <button class="ql-video" [title]="'Vídeo'"></button>
    </span>
  </div>
</quill-editor>