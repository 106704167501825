import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { AlarmesFilterService } from './alarmes-filter.service';
import { AlarmaFilter } from '../models/alarma-filter';
import { Alarma } from '../models/alarma.model';
import { Reflection } from 'path-shared/services/reflection/reflection';

const defaultSortParam = 'data';
const defaultSortDirection = 'desc';


@Injectable({
  providedIn: 'root'
})
export class AlarmesService {

  displayedItems = [];

  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {
    this.displayedItems['checkedValues'] = 0;
    this.displayedItems['checkedItems'] = Array();
  }

  getAll(): Observable<any> {
    if (this.authGuard.canActivate()) {
      const params = new AlarmesFilterService().createFilterParams(new AlarmaFilter('data', 'desc'), 0, 99999, 'data', 'desc');
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAlarmes'],  params);
    }
  }

  getByAparell(secureId: string): Observable<any> {
    if (this.authGuard.canActivate()) {
      secureId = secureId.replace('/', '$');
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAlarmesByAparell'].replace('{secureIdAparell}', secureId),  null);
    }
  }

  getGroups(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAlarmsGroup'], null, true);
    }
  }

  getTipus(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAlarmsTipus'], null, true);
    }
  }


  ackAlarma(secure_id: string): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlAckAlarma'].replace('{secure_id}', secure_id) , null);
    }
  }

  generateExcel(filter: AlarmaFilter, sortDir: string, sortField: string): Observable<any> {
    const start = 0;
    const pageSize = 1000;

    sortField = sortField === undefined ? defaultSortParam : sortField;
    sortDir = sortDir === '' ? defaultSortDirection : sortDir;
    const params = new AlarmesFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

    if (this.authGuard.canActivate()) {
      return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlAlarmesExcel'],  params );
    }
  }

  ackAlarms(alarms: Alarma[]): Observable<any> {
    if (this.authGuard.canActivate()) {
      const idList: string[] = [];
      alarms.forEach(alarm => {
        if (Reflection.isNull(alarm.usuari)) {
          idList.push(alarm.secure_id);
        }
      });
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlAckAlarms'], idList);
    }
  }
}
