import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
import { BancCarreguesC60Filter } from '../models/banc-carregues-c60-filter';

const defaultSortParam = 'descripcio';
const defaultSortDirection = 'asc';

@Injectable()
export class BancCarreguesC60FilterService extends CustomFilterService {
  public createFilterParams(filter: BancCarreguesC60Filter, start: number, pageSize: number, sortField: string, sortDir: string ) {

    return new HttpParams()
    .append('id', (filter.id ? filter.id.toString() : ''))
    .append('codiCarrega', filter.codiCarrega)
    .append('descripcioCarrega', filter.descripcioCarrega)
    .append('nomFitxer', filter.nomFitxer)
    .append('directoriFitxer', filter.directoriFitxer)
    .append('situacio', filter.situacio)
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
