import { OperacionesModel } from '../../../../models/operaciones.model';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { DenunciesService } from 'path-denuncies-services/denuncies.service';
import { FilesService } from 'path-shared/services/files/files.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-operacions',
  templateUrl: './operacions.component.html',
  styleUrls: ['./_operacions.component.scss']
})

export class OperacionsComponent implements OnInit {

  @Input() data: OperacionesModel[];
  @Input() isReadOnly: boolean;
  @Input() idDenuncia: string;
  @Input() somIdDenuncia: string;
  @Output() updated: EventEmitter<any> = new EventEmitter<any>();

  dataSource: MatTableDataSource<OperacionesModel>;
  displayedColumns = ['Operacio', 'Data', 'Usuari', 'Descripcio', 'Obs.','Anular', 'Fichero'];

  constructor(
    private i18n: I18n,
    private confirmationDialogService: ConfirmationDialogService,
    private toastr: ToastrService,
    private denunciesService: DenunciesService,
    private filesService: FilesService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.data);
  }

  addOperation(operation) {
    this.data.unshift(operation);
    this.dataSource.data = this.data;
  }

  anularOperacio() {
    if (this.data.length === 0) {
      this.toastr.warning(this.i18n('No hi ha operacions per anular'));
    } 
    else {
      this.confirmationDialogService.confirm(this.i18n('Anul·lar operació'), this.i18n('S\'anularà l\'última operació ') +
      this.data[this.data.length - 1].COD_OPE + this.i18n(' del dia ') + 
      this.data[this.data.length - 1].FEC_OPE.toString().substr(0, 10))
        .then((confirmed) => {
          if (confirmed) {
            this.denunciesService.anularOperacio(this.idDenuncia).subscribe(result => {
              if (result !== -1) {
                this.toastr.success(this.i18n('Operació anul·lada correctament!'));
                this.updated.emit();
                this.data.pop();
                this.dataSource.data = this.data;
              }
            });
            this.dialog.closeAll();
          }
        });
    }

  }
  anularSingleOperacio(operacio:OperacionesModel) {
    if (this.data.length === 0) {
      this.toastr.warning(this.i18n('No hi ha operacions per anular'));
    } else {

      this.confirmationDialogService.confirm(this.i18n('Anul·lar operació'), this.i18n('S\'anulará la operación ') +
      operacio.COD_OPE + this.i18n(' del dia ') + operacio.FEC_OPE.toString().substr(0, 10))
        .then((confirmed) => {
          if (confirmed) {

            this.denunciesService.anularSingleOperacio(this.idDenuncia, operacio.SOM_ID, this.somIdDenuncia).subscribe(result => {
              if (result !== -1) {
                this.toastr.success(this.i18n('Operació anul·lada correctament!'));
                this.updated.emit();
                this.data.pop();
                this.dataSource.data = this.data;
              }
            });
            this.dialog.closeAll();
          }
        });
    }
  }

  getFilePath(ficheroSomId) {
    this.filesService.getFileSomId(ficheroSomId).subscribe(
      result=>{ 
        const objectUrl=URL.createObjectURL(result);
        window.open(objectUrl);},
      error => console.log(error),
      ()=> console.log('completed'));
  }
}
