<app-filter-header title="Filtrar Tipus matrícules" i18n-title  (apply)="this.apply()" (reset)="this.reset()" (dismiss)="this.dismiss()"></app-filter-header>
<div class="modal-body">

  <div class="row">
    <app-form-text-edit id="tipus-matricules-filter-descripcio" autofocus class="col-sm-12" i18n-label label="Descripció" [(value)]="filter.descripcio"></app-form-text-edit>
  </div>
  <div class="row">
    <div class="col-6">
      <app-form-tri-state label="Denunciable en zona blava" i18n-label labelSi="Si" i18n-label labelNo = "No" i18n-labelTodos labelTodos="Tots"  [(value)]="filter.denunciaZB"></app-form-tri-state>
      <app-form-tri-state label="Denunciable policia" i18n-label labelSi="Si" i18n-label labelNo = "No" i18n-labelTodos labelTodos="Tots" [(value)]="filter.denunciaPOL"></app-form-tri-state>
      <app-form-tri-state label="És reincident" i18n-label labelSi="Si" i18n-label labelNo = "No" i18n-labelTodos labelTodos="Tots" [(value)]="filter.reincidents"></app-form-tri-state>
      <app-form-tri-state label="Guarda LOG" i18n-label labelSi="Si" i18n-label labelNo = "No" i18n-labelTodos labelTodos="Tots" [(value)]="filter.guardarLOG"></app-form-tri-state>
      <app-form-tri-state label="Generar Alarma" i18n-label labelSi="Si" i18n-label labelNo = "No" i18n-labelTodos labelTodos="Tots" [(value)]="filter.generarAlarma"></app-form-tri-state>
    </div>
  </div>
</div>
