import { Injectable } from "@angular/core";
import { SortDirection } from "@angular/material";
import { FilterData } from "path-shared/models/filter-data";

export class PlanificacioFilter extends FilterData{
    somId:number;
    Dia: Date;
    constructor(sortField: string, sortDir: SortDirection){
        super(sortField, sortDir);
        this.somId = null;
        this.Dia = null;

        this.labels['somId']=this.i18n('somId');
        this.labels['Dia']=this.i18n('Dia');
    }
}