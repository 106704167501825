import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
import { MaquinasFilter } from '../models/maquina-filter';


const defaultSortParam = 'descripcion';
const defaultSortDirection = 'asc';

@Injectable()
export class MaquinasFilterService extends CustomFilterService {
  public createFilterParams(filter: MaquinasFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {
    return new HttpParams()
    .append('idZBMaquinaParkeon', (filter.SomId ? filter.SomId.toString() : ''))
    .append('descripcion', filter.descripcio)
    .append('numPlazas', (filter.numPlazas ? filter.numPlazas.toString() : ''))
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
