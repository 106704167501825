import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';


export class PendentsFilter extends FilterData {
  
    constructor(sortField: string, sortDir: SortDirection) {
      super(sortField, sortDir);
    }
  }
  