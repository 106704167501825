import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { DenunciesEditorComponent } from 'app/sections/denuncies/components/denuncies-editor/denuncies-editor.component';
import { ToastrService } from 'ngx-toastr';
import { Builder } from 'path-shared/models/builder';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Pendents } from '../../../../models/pendents.models';
import { TancamentsFilter } from '../../../../models/tancaments-filter.model';
import { Tancaments } from '../../../../models/tancaments.models';
import { TancamentsFilterService } from '../../../../services/tancaments-Filter.service';
import { TramitarService } from '../../../../services/tramitar.service';

@Component({
  selector: 'app-tabletancaments',
  templateUrl: './tabletancaments.component.html',
  styleUrls: ['./tabletancaments.component.scss']
})
export class TabletancamentsComponent implements OnInit, AfterViewInit {  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
    data: Tancaments[];
    dataSource: CustomDataSource<Tancaments, TancamentsFilter>;
    allSelected: boolean;
    displayedItems = [];
    highlightedRows = [];
    Seleccionats = 0;
    Total = 0;
    
    filter: TancamentsFilter = new TancamentsFilter('EXP', 'asc');
    @Output() notify: EventEmitter<number> = new EventEmitter<number>();
    /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
    displayedColumns = ['Check', 'EXP', 'NumDenuncia', 'Data', 'Matricula', 'ImportEuros', 'Fecha_Prescripcion', 'Fecha_Caducidad', 'Resposta'];

  constructor(private dialog: MatDialog,
              private tramitarService: TramitarService,
              private filterService: FilterService,
              private not: NotificationService,
              private i18n: I18n,
              private toastr: ToastrService) {

               }

  ngOnInit() {    
    this.loadInici();
    this.allSelected = false;
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(tap(() => this.filter.updated()))
    .subscribe();

    this.load();
  }

  load() {
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }

  loadInici() {
    this.dataSource = CustomDataSource.create<Tancaments, TancamentsFilter>(this.paginator, new TancamentsFilterService(), 'urlTancaments', 'urlInfoTancaments');
    
    this.dataSource.subject.subscribe((data) => {
      data.forEach(item => {        
          item.Check = false;
      });
    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
      }
    });

    this.not.onTramitarTancamentChange.subscribe(value => {      
      const item = this.dataSource.get('EXP', value.EXP);      
      if (item !== null) {
        item.Resposta = value.Response;
      }      
    });

  }


isOldDataPrescripcio(date): boolean {
    if (date === undefined || date === null) { return false; }
    const today = new Date();
    const fecha = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();
    return date < fecha;
}

isOldDataCaducitat(date): boolean {
  if (date === undefined || date === null) { return false; }
  const today = new Date();
  const fecha = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();
  return date < fecha;
}

  onRowClicked(row: Tancaments) {
    const data: ComponentDialog = Builder.newComponentDialog(true, true, row.SomIdDenuncia);
      const ref = this.dialog.open(DenunciesEditorComponent, {
        width: '80%',
        height: '80%',
        maxHeight: '100vh',
        closeOnNavigation: false,
        autoFocus: true,
        data: data
      });
}

toggleAllCheckbox() {
    this.dataSource.getData().forEach(item => {    
      item.Check = this.allSelected;      
  });
  this.Seleccionats = this.dataSource.getData().filter(x => x.Check === true).length;
}

SelectedRow(row: Tancaments) {
  if (row.Check === undefined || row.Check === null) {
    row.Check = true;
  } else {
    row.Check = !row.Check;
  }
  this.Seleccionats = this.dataSource.getData().filter(x => x.Check === true).length;
  }
  getNumRegistrosSelecionados(): Number{
    let regSel = 0;
    if ( this.dataSource !== undefined && this.dataSource !== null && this.dataSource.getData().length > 0 ){
      regSel =  this.dataSource.getData().filter(x => x.Check === true).length;
    }
    return regSel;
  }

Save() {
    let taula: Tancaments[];
    taula = [];
    if (this.dataSource.getData().filter(x => x.Check === true).length > 0) {

      this.dataSource.getData().filter(x => x.Check === true).forEach(item => {
        const x = new Tancaments();
        x.EXP = item.EXP;
        x.DataExecut = item.DataExecut;
        x.Ofici = item.Ofici;
        x.Tancat = item.Tancat;
        x.Fecha_Prescripcion = item.Fecha_Prescripcion;
        x.Fecha_Caducidad = item.Fecha_Caducidad;
        x.DataCobra = item.DataCobra;
        x.DataAnul = item.DataAnul;
        x.NumDenuncia = item.NumDenuncia;
        x.Data = item.Data;
        x.Matricula = item.Matricula;
        x.ImportEuros = item.ImportEuros;
        x.Id_Instit = item.Id_Instit;
        x.SomIdDenuncia = item.SomIdDenuncia;

        this.tramitarService.SetTancaments(x).subscribe(y => {
          if (y === undefined || y === null || y !== true) {                        
            this.toastr.error(this.i18n('Algo ha fallado'));            
          } else {
            //pare actualitza el contador            
            this.notify.emit();
          }        
        });
      });    
    } else {
      this.toastr.info(this.i18n('No se ha seleccionado ningun expediente'));      
    }


  }

}
