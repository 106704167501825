<app-dialog-header label="Mapa" i18n-label></app-dialog-header>
<app-toolbar>

</app-toolbar>
<app-dialog-body> 
<agm-map
  (mapReady)="mapReady($event)"
  [latitude]="currentLocation.latitude"
  [longitude]="currentLocation.longitude"
  [styles]="styleMap"
  style="height: 700px; width: 100%;"
>
  <agm-marker
    *ngFor="let m of markersArray; let i = index"
    [latitude]="m.lat"
    [longitude]="m.lng"
    [iconUrl]="{ url: m.iconPath }"
  >
    <agm-info-window>
      <div style="font-weight: bold;">{{ m.label }}</div>
    </agm-info-window>
  </agm-marker>
</agm-map>
</app-dialog-body>

