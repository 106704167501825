<div class="form-group">
    <!--
    <div class="row">
        <span class="col-12" i18n>
            {{this.title}}
        </span>
    </div>
    -->
    <div class="row">
        <mat-form-field class="col-6">
            <input matInput [owlDateTimeTrigger]="dt12" [owlDateTime]="dt12" [ngModel]= "this.value" (ngModelChange)="this.value=$event" 
            [selectMode]="'rangeFrom'" [disabled]="this.disabled" [required]="this.required">
            <mat-placeholder class="calendar-placeholder" i18n>{{this.label1}}</mat-placeholder>
            <mat-icon matSuffix [owlDateTimeTrigger]="dt12" class="date-picker-trigger"><i class="fas fa-calendar-day"></i></mat-icon>
            <owl-date-time #dt12 [pickerType]="this.type"></owl-date-time>
        </mat-form-field>
    
        <mat-form-field class="col-6">
            <input matInput [owlDateTimeTrigger]="dt13" [owlDateTime]="dt13" [ngModel]= "this.value" (ngModelChange)="this.value=$event" 
            [selectMode]="'rangeTo'" [disabled]="this.disabled" [required]="this.required">
            <mat-placeholder class="calendar-placeholder" i18n>{{this.label2}}</mat-placeholder>
            <mat-icon matSuffix [owlDateTimeTrigger]="dt13" class="date-picker-trigger"><i class="fas fa-calendar-day"></i></mat-icon>
            <owl-date-time #dt13 [pickerType]="this.type"></owl-date-time>
        </mat-form-field>
    </div>
    <div [hidden]="this.validVal" class="text-danger alert-field-errors">
        <div [hidden]="this.errorRequired()" style="text-align: center;" i18n>Aquest camp és obligatori!</div>
    </div>
    
</div>
