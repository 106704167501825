<app-filter-header title="Filtrar envios" i18n-title  (apply)="this.apply()" (reset)="this.reset()" (dismiss)="this.dismiss()"></app-filter-header>
<div class="modal-body">
  <div class="row">
  <div class="row">
    <app-form-text-edit class="col-sm-3" i18n-label label="ID Envio" [(value)]="filter.idEnvio" maxLenght="50" number="true"></app-form-text-edit>
    <app-form-text-edit class="col-sm-3" i18n-label label="Usuario creación" [(value)]="filter.usuarioCreacion" maxLenght="50" number="false"></app-form-text-edit>
    <app-form-date-edit id="envio-filter-dataIni" class="col-sm-3" i18n-label label="Fecha inici creacion" [(value)]="filter.fechaCreacionInicio" type="calendar"></app-form-date-edit>
    <app-form-date-edit id="envio-filter-dataFin" class="col-sm-3" i18n-label label="Fecha fin creacion" [(value)]="filter.fechaCreacionFin" type="calendar"></app-form-date-edit>
      
    <app-form-text-edit class="col-sm-3" i18n-label label="Tipo envio" [(value)]="filter.tipoEnvio" maxLenght="50" number="false"></app-form-text-edit>
    <app-form-text-edit class="col-sm-3" i18n-label label="Usuario envio" [(value)]="filter.usuarioEnvio" maxLenght="50" number="false"></app-form-text-edit>
    <app-form-date-edit id="envio-filter-dataIniEnvio" class="col-sm-3" i18n-label label="Fecha inici envio" [(value)]="filter.fechaEnvioInicio" type="calendar"></app-form-date-edit>
    <app-form-date-edit id="envio-filter-dataFinEnvio" class="col-sm-3" i18n-label label="Fecha fin envio" [(value)]="filter.fechaEnvioFin" type="calendar"></app-form-date-edit>
     
    <app-form-text-edit class="col-sm-3" i18n-label label="Número BOE" [(value)]="filter.numeroPublicacionBOE" maxLenght="50" number="false"></app-form-text-edit>
    <app-form-text-edit class="col-sm-3" i18n-label label="Usuario envio BOE" [(value)]="filter.usuarioBOE" maxLenght="50" number="false"></app-form-text-edit>
    <app-form-date-edit id="envio-filter-dataIniBOE" class="col-sm-3" i18n-label label="Fecha inici BOE" [(value)]="filter.fechaNotificacionBOEInicio" type="calendar"></app-form-date-edit>
    <app-form-date-edit id="envio-filter-dataFinBOE" class="col-sm-3" i18n-label label="Fecha fin BOE" [(value)]="filter.fechaNotificacionBOEFin" type="calendar"></app-form-date-edit>
     
      
    </div>
  </div>
</div>
