<app-dialog-header [label]="this.options.title" ></app-dialog-header>
<app-dialog-body>
  <div class="row">
    <div class="col-1">
      <i class="{{this.options.icon}} fa-2x"></i>
    </div>
      <div class="col-1" *ngIf="this.options.isSpinner">
        <i class="fa fa-spinner fa-spin"></i>
      </div>
      <div class="col">
        <!-- {{this.options.text}} -->
        <div [innerHTML]="this.options.text"></div>
    </div>
  </div>
</app-dialog-body>
<app-dialog-footer>
    <button type="button" class="btn btn-secondary" mat-dialog-close i18n>{{this.options.buttonText}}</button>
</app-dialog-footer>
