import { SortDirection } from '@angular/material';
import { FilterData } from 'path-shared/models/filter-data';

export class ExpedientePredefinidasFilter extends FilterData {
    exp: string;

    constructor(sortField: string, sortDir: SortDirection) {
        super(sortField, sortDir);

        this.exp = '';

        this.labels['exp'] = this.i18n('Expedient');
    }
}
