import { BehaviorSubject, Observable , Subscription} from 'rxjs';
import { TipoSenalVertical } from './../../../tipos-senales-verticales/models/tipo-senal-vertical';
import { SenalesVerticalesFilter } from './../../models/senales-verticales.filter';
import { Component, OnInit, EventEmitter, Output, Input, Inject, ViewChild, OnDestroy } from '@angular/core';
import { Dictionary } from 'path-shared/models/dictionary';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ComboBoxInputComponent } from 'path-shared/components/form/combo-box-input/combo-box-input.component';

@Component({
  selector: 'app-senales-verticales-filter',
  templateUrl: './senales-verticales-filter.component.html',
  styleUrls: ['./senales-verticales-filter.component.scss']
})
export class SenalesVerticalesFilterComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  @ViewChild ('comboTipoSenal') comboTipoSenal: ComboBoxInputComponent;

  filter: SenalesVerticalesFilter;

  @Output() notify: EventEmitter<SenalesVerticalesFilter> = new EventEmitter<SenalesVerticalesFilter>();
  _tiposSenalesVerticales: TipoSenalVertical[];

  tipoSenalesDictionary: BehaviorSubject<TipoSenalVertical[]>;

  constructor(
    public thisDialogRef: MatDialogRef<SenalesVerticalesFilter>,
    private i18n: I18n,
    @Inject(MAT_DIALOG_DATA) public data
    ) {
      this.tipoSenalesDictionary = new BehaviorSubject<TipoSenalVertical[]>(undefined);
      if (data.tiposSenalesVerticales !== undefined && data.tiposSenalesVerticales !== null) {
        this._tiposSenalesVerticales = data.tiposSenalesVerticales;
      }
      this.filter = data.filter;
  }

  ngOnInit() {
    if (this._tiposSenalesVerticales !== undefined && this._tiposSenalesVerticales !== null) {
      this.comboTipoSenal.resetShowingOptions();
      const tipos = new Array();
      this._tiposSenalesVerticales.forEach(element => {
        tipos.push(new Dictionary(element.id, element.descripcion));
      });
      this.tipoSenalesDictionary.next(tipos);
    }

    if (this.filter !== undefined && this.filter !== null && this.filter.tipoFk) {
      this.comboTipoSenal.markAsTouched();
      this.comboTipoSenal.setSelect(this.filter.tipoFk);
    }
    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.apply();
      }
    });
  }
  
 ngOnDestroy(): void {
  this.subscription.unsubscribe();
}

  reset(): void {
    this.filter.clear();
    this.apply();
  }

  apply(): void {
    this.filter.updated();
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss(): void {
    this.thisDialogRef.close();
  }

  senalSeleccionada(tipoSenal: number): void {
    this.filter.tipoFk = tipoSenal.toString();
  }
}
