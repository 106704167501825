import { Injectable } from '@angular/core';
import {api} from 'path-shared/configuration/urls';
import {HttpSecureClient} from 'path-shared/services/http/httpSecureClient';
import {AuthGuardService} from 'path-shared/services/authentication/auth-guard.service';
import {Observable} from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { CookieService } from 'ngx-cookie-service';
import { StartupService } from 'path-shared/services/startup/startup.service';

@Injectable({
  providedIn: 'root'
})
export class DropdownInstitucionsService {

  constructor(
    private http: HttpSecureClient,
    private cookieService: CookieService,
    private authGuard: AuthGuardService,
    private startupService: StartupService
    ) { }

  getInstitucions(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlInstitucionesByUserAuthenticated'], null);
    }
  }

  setNewIdInstitution(id) {
    sessionStorage.setItem('IdInstitucion', id);
    localStorage.setItem('IdInstitucion', id);
    this.cookieService.set('InstitucioSeleccionada', id);
    this.startupService.GetParametresObservable().subscribe(result => {
      sessionStorage.setItem('parameters', JSON.stringify(result));
      location.reload();
    });
  }

}
