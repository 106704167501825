import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';

import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { HttpParams } from '@angular/common/http';

const defaultSortParam = 'codi';
const defaultSortDirection = 'desc';


@Injectable()
export class EnviosService {
  

  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {}

   getRowsEnvio(secureId: string): Observable<any> {
     if (this.authGuard.canActivate()) {
       return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetRowsEnvio'].replace('{secureId}', secureId), null);
     }
   }

  // calculatePrice(dipositVehicle: any): Observable<any> {
  //   if (this.authGuard.canActivate()) {
  //     return this.http.post(config.urls['apiUrl'] + api.endpoints['calculatePriceDeposito'], dipositVehicle);
  //   }
  // }

  // getAllMotiusEntradaGrua(): Observable<any> {
  //   if (this.authGuard.canActivate()) {
  //     return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAllGruaMotiusEntrada'], null);
  //   }
  // }

  // setDipositVehicle(vehiculoDeposito: VehicleDipositDto){
  //   if (this.authGuard.canActivate()) {
  //     return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetVehiculoDepositoDto'], vehiculoDeposito);
  //   }
  // }

  // cobrarDepositoVehiculo(vehiculoDeposito: VehicleDipositDto): Observable<any> {
  //   if (this.authGuard.canActivate()) {
  //     return this.http.post(config.urls['apiUrl'] + api.endpoints['urlCobrarVehiculoDepositoDto'], vehiculoDeposito);
  //   }
  // }

  // getDipositsCombo(): Observable<any> {
  //   if (this.authGuard.canActivate()) {
  //     return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetDiposits'], null);
  //   }
  // }

  // getAllTiposCobro(): Observable<any> {
  //   if (this.authGuard.canActivate()) {
  //     return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetTiposCobro'], null);
  //   }
  // }

  // getReports(page: string): Observable<any> {
  //   if (this.authGuard.canActivate()) {
  //     do {
  //       page = page.replace('.', 'xXx');
  //     } while (page.includes('.'));

  //     return this.http.get(config.urls['apiUrl'] + api.endpoints['urlReports'].replace('{page}', page), null);
  //   }
  // }

  // generateReport(secureId: string, reportId: string): Observable<any> {

  //   if (this.authGuard.canActivate()) {
  //     return this.http.postFileResponse(config.urls['apiUrl'] + api.endpoints['urlGenerateReportDeposito'].replace('{secureId}', secureId).replace('{reportId}', reportId), null, new HttpParams());
  //   }
  // }

  // getDesperfectos(secureId: string): Observable<any> {
  //   if (this.authGuard.canActivate()) {
  //     return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetDesperfectos'].replace('{secureId}', secureId), null);
  //   }
  // }

  // setDesperfectos(obj: DesperfectosDto): Observable<any> {
  //   if (this.authGuard.canActivate()) {
  //     return this.http.post(config.urls['apiUrl'] + api.endpoints['urlsetDesperfectos'], obj);
  //   }
  // }

  // deleteDesperfecto(obj: DesperfectosDto): Observable<any> {
  //   if (this.authGuard.canActivate()) {
  //     return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeletetDesperfectos'].replace('{secureId}', obj.secureId), null);
  //   }
  // }
  // getImages(contenedor: string, numDenuncia: string): Observable<any> {
  //     if (this.authGuard.canActivate()) {
  //         const url = config.urls['apiUrl'] + api.endpoints['urlGetImages'].replace('{somId}', numDenuncia).replace('{contenedor}', contenedor);

  //         return this.http.get(url, null);
  //     }
  // }

  // generateExcel(filter: DipositGruaFilter, sortDir: string, sortField: string): Observable<any> {
  //   const start = 0;
  //   const pageSize = 1000;

  //   sortField = sortField === undefined ? defaultSortParam : sortField;
  //   sortDir = sortDir === '' ? defaultSortDirection : sortDir;
  //   const params = new DipositGruaFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

  //   if (this.authGuard.canActivate()) {
  //     return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlGruaDipositExcel'],  params );
  //   }
  // }
}
