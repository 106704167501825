import { I18n } from '@ngx-translate/i18n-polyfill';
import { ExcelGenerator } from './../../../../shared/interfaces/excel-generator';
import { Alarma } from './../../models/alarma.model';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, AfterViewInit, OnChanges, SimpleChanges, Inject } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { AlarmaFilter } from '../../models/alarma-filter';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { AlarmesService } from '../../services/alarmes.service';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { ExcelDownloadService } from 'path-shared/services/excel/excel-download.service';
import { AlarmesFilterService } from '../../services/alarmes-filter.service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { Reflection } from 'path-shared/services/reflection/reflection';
import { Builder } from 'path-shared/models/builder';
import { Router } from '@angular/router';
import { Instituciones } from 'path-shared/models/instituciones';
import { Parametros } from 'path-shared/models/parametros';
import { ParametersService } from 'path-shared/services/parameters/parameters.service';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { TramitadorHistoricComponent } from 'app/sections/denuncies/components/tramitador-historic/tramitador-historic.component';

declare var require: any;
@Component({
  selector: 'app-alarmes-table',
  templateUrl: './alarmes-table.component.html',
  styleUrls: ['./alarmes-table.component.scss']
})
export class AlarmesTableComponent implements OnInit, AfterViewInit, OnChanges, ExcelGenerator {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  data: Alarma[];
  dataSource: CustomDataSource<Alarma, AlarmaFilter>;
  highlightedRows = [];
  displayedItems = [];

  public currentLocation: {
    latitude: number,
    longitude: number
  };
  private idInst: number;
  public zoom: number;
  private map: any;
  public allSelected: boolean;

  public colors  = [ require('./resources/red-circle-lv.png'),
                    require('./resources/purple-circle-lv.png'),
                    require('./resources/grn-circle-lv.png'),
                    require('./resources/wht-circle-lv.png'),
                    require('./resources/blu-circle-lv.png')];
  public icons  = [ require('./resources/red-blank.png'),
                    require('./resources/purple-blank.png'),
                    require('./resources/grn-blank.png'),
                    require('./resources/wht-blank.png'),
                    require('./resources/blu-blank.png')];
  dataDialog: ComponentDialog = Builder.newComponentDialog();
  @Input() updatedFilterData: AlarmaFilter;
  @Input() selectButton: boolean;
  @Input() isHistoric: boolean;
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();
  @Output() itemClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() itemSelected: EventEmitter<string> = new EventEmitter<string>();

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['incorpora', 'estat', 'text', 'data', 'tipusAlarma', 'matricula', 'usuari', 'botons'];

  constructor(
    private alarmesService: AlarmesService,
    private filterService: FilterService,
    private excel: ExcelDownloadService,
    private dialog: MatDialog,
    private i18n: I18n,
    private router: Router,
    private parametersService: ParametersService,
    @Inject(MAT_DIALOG_DATA) public dataComp: ComponentDialog
    ) {
      if (dataComp !== undefined) {
        this.dataDialog = dataComp;
      }
      this.selectButton = false;

      const inst = sessionStorage.getItem('currentInst');
      if (inst !== '' && inst !== undefined && inst !== 'undefined') {
        const institution: Instituciones = JSON.parse(inst);
        this.currentLocation = {latitude: institution.Latitud, longitude: institution.Longitud};

        this.idInst =  Number.parseInt(institution.IdInstitucion);
    }
  }

  ngOnInit() {
    if (this.selectButton) {
      this.displayedColumns = ['incorpora'].concat(this.displayedColumns);
    }
    this.sort.disableClear = true;
    if (this.isHistoric) {
      this.dataSource = CustomDataSource.create<Alarma, AlarmaFilter>(this.paginator, new AlarmesFilterService(), 'urlGetAlarmesHistoric', 'urlAlarmesHistoricPaginationCount');
    }
    else {
      this.dataSource = CustomDataSource.create<Alarma, AlarmaFilter>(this.paginator, new AlarmesFilterService(), 'urlGetAlarmes', 'urlAlarmesPaginationCount');
    }
    
    let pos = 0;
    this.dataSource.subject.subscribe((data) => {
      data.forEach(item => {
        this.alarmesService.displayedItems[item.secure_id] = {
          id: item.secure_id,

        };
        item.selected = false;
        if (pos < 5 ) {
          item.ColorHexa =  this.colors[pos];
          item.IconMarker = this.icons[pos];
          // element.IconMarker= "{url: '"+this.icons[pos]+"',scaledSize: {width: 50,height: 30}}";
        } else {
          pos = pos - 5;
        item.ColorHexa = this.colors[ pos];
        item.IconMarker = this.icons[pos];
        // element.IconMarker= "{url: '"+this.icons[pos]+"',scaledSize: {width: 50,height: 30}}";
        }
        pos++;
      });

      this.data = data;

      this.displayedItems = this.alarmesService.displayedItems;
    });

    this.updatedFilterData.filterUpdated.subscribe(updated => {
      if (updated) {
        this.paginator.pageIndex = 0;
        this.filterService.saveFilter('alarmes-filter', this.updatedFilterData);
        this.update();
      }
    });

    if (this.dataDialog !== undefined && this.dataDialog.filter !== null && this.dataDialog.filter !== undefined) {
      this.updatedFilterData.set(this.dataDialog.filter);
    } else {
       this.filterService.loadFilter('alarmes-filter', this.updatedFilterData);
    }
    /* if (!this.selectButton) { this.filterService.loadFilter('alarmes-filter', this.filter); } */
    this.updatedFilterData.paginator.init(this.sort, this.paginator);

    this.zoom = Number.parseInt(this.parametersService.getParameter(Parametros.GPSZOMINICIAL));
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.updatedFilterData.updated();
    } );

    this.paginator.page.subscribe(data => {
      this.paginator.pageIndex = data.pageIndex;
      this.paginator.pageSize = data.pageSize;
      this.update()
      console.log('paginator size: ' + data.pageSize);
    });
    
    // merge(this.sort.sortChange, this.paginator.page)
    //   .pipe(tap(() => this.filter.updated()))
    //   .subscribe();
    this.load();

  }

 

  load() {

    this.updatedFilterData.paginator.set(this.sort, this.paginator);

    this.dataSource.loadFilter(this.updatedFilterData);

  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      this.update();
    }
  }

  public update() {
    if (this.dataSource !== undefined) {
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
    }
  }

  SeleccionarTotes() {
    this.data.forEach(element => {
      element.selected = this.allSelected;
    });

  }

  ClickCheckbox(row: Alarma) {
    row.selected = !row.selected;
  }

  SeleccionarFila(row: Alarma) {
    this.currentLocation.latitude = row.latitud;
    this.currentLocation.longitude = row.longitud;

    const numChecked = this.data.filter(x => x.selected === true).length;

    if (numChecked === this.data.length - 1) {
      this.allSelected = true;
    } else {
      this.allSelected = false;
    }
  }

  SeleccionarFilaAux(row: Alarma) {

    row.selected = !row.selected;
    this.SeleccionarFila(row);
  }

  onMapReady(map: any) {
    // console.log(map);
    this.map = map;
  }

  onRowClicked(row) {
    this.itemClicked.next(row.SECURE_ID);
  }

  selectCurrent(codi) {
    this.itemSelected.next(codi);
  }

  generateExcel(): any {
    let filter = this.updatedFilterData;
    if (this.updatedFilterData != null) {
      filter = this.updatedFilterData;
    }
    this.dialog.open(DownloadExcelDialogComponent, {
        closeOnNavigation: false,
        autoFocus: true,
        data: Builder.newDownloadExcelDialogData (this.alarmesService, filter, this.i18n('Alarmes'), this.sort.direction, this.sort.active)
    });
  }

  acceptada(alarma: Alarma) {
    return !Reflection.empty(alarma.usuari);
  }

  checkAlarm(secure_id) {
    this.alarmesService.ackAlarma(secure_id).subscribe(result => {
      this.dataSource.loadFilter(this.updatedFilterData);
    });
  }

  hasPantalla(alarma: Alarma) {
    return !Reflection.isNull(alarma.idPantalla);
  }

  onClick(alarma: Alarma) {
    const route = this.router.config.find(x => x.data !== undefined && x.data.pantalla === alarma.idPantalla);
    if (route !== undefined) {
      let link = route.path;
      if (!Reflection.empty(link)) {
        let dades = '';
        if (!Reflection.empty(alarma.dades)) {
           dades = alarma.dades;
        }
        link = '/' + link.replace(':matricula', dades);
        if (Reflection.empty(alarma.usuari)) {
          this.checkAlarm(alarma.secure_id);
        }
        this.router.navigateByUrl(link);
      }
    }
  }

  automatitzacio(alarma: Alarma) {
    return alarma.dades.includes('AUTOMATITZACIO');
  }

  openHistorico(row) {
    this.dialog.open(TramitadorHistoricComponent, {
      closeOnNavigation: false,
      autoFocus: true,
      data: ''
  });
  }
}
