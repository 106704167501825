import { AnulacionZonaAzulAutoFilter } from './../models/anulacion-zona-azul-auto-filter';
import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';

const defaultSortParam = '';
const defaultSortDirection = '';

@Injectable({
  providedIn: 'root'
})
export class AnulacionZonaAzulAutoFilterService extends CustomFilterService {

  public createFilterParams(filter: AnulacionZonaAzulAutoFilter, start: number, pageSize: number, sortField: string, sortDir: string): HttpParams {
    return new HttpParams()
    .append('fechaInicio', filter.fechaInicio.toJSON())
    .append('fechaFin', filter.fechaFin.toJSON())
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir)
    ;
  }

  public createParamsFromSelectedItems<T>(selectedItems: T[]): HttpParams {
    return new HttpParams().append('listaExpedients', JSON.stringify(selectedItems));
  }
}
