<app-dialog-header *ngIf="this.dataDialog.isDialog" label="Envio de Notificación" i18n-label></app-dialog-header>

<app-toolbar>
  <app-tool-button text="Informes" i18n-text (click)="this.mostrarInformes();" color="has-color-orange" icon="fa-edit" [disabled]="!this.enabledGenerarInforme"></app-tool-button>
  <app-tool-button text="Finalizar" i18n-text (click)="this.finalizarEnvio();" color="has-color-green" icon="fa-plus-square"></app-tool-button>
  <app-tool-button text="Excel" i18n-text (click)="this.generateCsv()" color="has-color-darkgreen" icon="fa-file-excel" ></app-tool-button>
</app-toolbar>

<mat-card>
  <mat-card-content>
    <div class="row">
      <app-form-text-edit id="listado-notificaciones-campo-envio" [isReadOnly]="true" i18n-label label="Envio" [value]="this.resumen.idEnvio" [disabled]="true">
      </app-form-text-edit>
      <app-form-text-edit id="listado-notificaciones-campo-tipo-envio" [isReadOnly]="true" i18n-label label="Tipo" [value]="this.resumen.tipoEnvio" [disabled]="true">
      </app-form-text-edit>
      <app-form-date-edit id="listado-notificaciones-campo-fecha-creacion" i18n-label label="Fecha Creación" [disabled]="true" [value]="this.resumen.fechaEnvio"></app-form-date-edit>
    </div>
  </mat-card-content>
</mat-card>

<div class="table-sdw is-fixed">
      
  <mat-table class="adjust" #table [dataSource]="this.dataSource" matSort aria-label="Elements" class="is-header-sticky">
    <ng-container matColumnDef="Elemento">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Elemento</mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.entidad }}</mat-cell>
    </ng-container>
    
    <ng-container matColumnDef="Nombre y Apellidos">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Nombre y Apellidos</mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.usuario }}</mat-cell>
    </ng-container>
    
    <ng-container matColumnDef="Número Notificación">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Número Notificación</mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.idNotificacion }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="direccion">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Dirección</mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.direccion }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="poblacion">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Población</mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.poblacion }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="cp">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>CP</mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.cp }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="provincia">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>CP</mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.provincia }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="juridica">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Juridica</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <mat-checkbox [checked]="row.juridica" [disabled]='true'></mat-checkbox>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset"></mat-row>
  </mat-table>
</div>