import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
import { ObservacionsPdaFilter } from '../models/observacions-pda-filter';

const defaultSortParam = 'descripcio';
const defaultSortDirection = 'asc';

@Injectable()
export class ObservacionsPdaFilterService extends CustomFilterService {
  public createFilterParams(filter: ObservacionsPdaFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {

    return new HttpParams()
    .append('codi', (filter.codi !== null ? filter.codi.toString() : ''))
    .append('descripcio', filter.descripcio)
    .append('tipus', filter.tipus)
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
