import { SortDirection } from "@angular/material";
import { FilterData } from "path-shared/models/filter-data";

export class MaquinaIncidenciaFilter extends FilterData {
    somId: number;
    codiMaquina: string;
    dataCreacioInici: Date;
    dataCreacioFi: Date;
    dataResolucioInici: Date;
    dataResolucioFi: Date;
  
    constructor(sortField: string, sortDir: SortDirection) {
      super(sortField, sortDir);
      this.somId = null;
      this.codiMaquina = '';
      this.dataCreacioInici = null;
      this.dataCreacioFi = null;
      this.dataResolucioInici = null;
      this.dataResolucioFi = null;
  
      this.labels['codiMaquina'] = this.i18n('Codi màquina');
      this.labels['somId'] = this.i18n('Codi');
      this.labels['dataCreacioInici'] = this.i18n('Inici data creació');
      this.labels['dataCreacioFi'] = this.i18n('Fi data creació');
      this.labels['dataResolucioInici'] = this.i18n('Inici data resolució');
      this.labels['dataResolucioFi'] = this.i18n('Fi data resolució');
    }
  }