import { CarrersFilter } from './../models/carrers-filter';
import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';


const defaultSortParam = 'codi';
const defaultSortDirection = 'asc';

@Injectable()
export class CarrersFilterService extends CustomFilterService {

  public createFilterParams(filter: CarrersFilter, start: number, pageSize: number, sortField: string, sortDir: string) {
    return new HttpParams()
    .append('id', filter.id === null ? '' : filter.id.toString())
    .append('sigles', filter.sigles)
    .append('nomCarrer', filter.nomCarrer)
    .append('idMunicipi', filter.idMunicipi === null ? '' : filter.idMunicipi.toString())
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
