import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef, Inject, OnDestroy } from '@angular/core';
import { CarrersFilter } from '../../models/carrers-filter';
import { OuterSubscriber } from 'rxjs/internal-compatibility';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-carrers-filter',
  templateUrl: './carrers-filter.component.html',
  styleUrls: ['./carrers-filter.component.scss']
})
export class CarrersFilterComponent implements OnInit, OnDestroy {
  
 subscription: Subscription;
  filter: CarrersFilter;
  sigles = Array();

  @Output() notify: EventEmitter<CarrersFilter> = new EventEmitter<CarrersFilter>();

  @ViewChild('mainForm') private mainForm: ElementRef;

  constructor(
    public thisDialogRef: MatDialogRef<CarrersFilterComponent>,
    private i18n: I18n,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    this.filter = data.filter;
    this.sigles = data.sigles;
  }

  ngOnInit() {
    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.apply();
      }
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated();
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }

}
