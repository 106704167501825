<app-filter-header title="Filtrar tarifes" i18n-title  (apply)="this.apply()" (reset)="this.reset()" (dismiss)="this.dismiss()"></app-filter-header>
<div class="modal-body">
  <div class="row">
      
          <div class="col-3">
            <app-filter-text-edit id="tarifes-filter-codi" i18n-label label="Codi" [(value)]="this.filter.codi"></app-filter-text-edit>
            <app-filter-text-edit id="tarifes-filter-descripcio" i18n-label label="Descripció" [(value)]="this.filter.descripcio"></app-filter-text-edit>
            <app-form-date-edit id="tarifes-filter-dataIni" i18n-label label="Data inici" [(value)]="this.filter.dataInici" ></app-form-date-edit>
            <app-form-date-edit id="tarifes-filter-dataFi" i18n-label label="Data fi" [(value)]="this.filter.dataFi"></app-form-date-edit>
    
            <div class="border border-secundary" style="padding:5px">
              <h5 i18n>CÀLCUL</h5>
              <app-filter-text-edit id="tarifes-filter-tipusCalcul" i18n-label label="Tipus de càlcul" [(value)]="this.filter.tipusCalcul" [number]="true"></app-filter-text-edit>
              <app-filter-text-edit id="tarifes-filter-valor" i18n-label label="Valor" [(value)]="this.filter.valor" [number]="true"></app-filter-text-edit>
              <app-filter-text-edit id="tarifes-filter-valorMinim" i18n-label label="Valor mínim" [(value)]="this.filter.valorMinim" [number]="true"></app-filter-text-edit>
              <app-filter-text-edit id="tarifes-filter-valorMaxim" i18n-label label="Valor màxim" [(value)]="this.filter.valorMaxim" [number]="true"></app-filter-text-edit>
              <app-filter-text-edit id="tarifes-filter-carenciaDenuncia" i18n-label label="Carència de denúncia" [(value)]="this.filter.carenciaDenuncia" [number]="true"></app-filter-text-edit>
              <app-filter-text-edit id="tarifes-filter-increment" i18n-label label="Increment" [(value)]="this.filter.increment" [number]="true"></app-filter-text-edit> 
            </div>
     
            <div class="border border-secundary" style="padding:5px; margin-top: 5px;">
              <h5 i18n>DADES</h5>
              <app-form-combo-edit i18n-label label="Codi zona" [(value)]="this.filter.idZona" [items]="this.zones" valueField="codi" textField="descripcio"></app-form-combo-edit>
              <app-form-combo-edit i18n-label label="Resident" [(value)]="this.filter.idResident" [items]="this.residents" valueField="codi" textField="descripcio"></app-form-combo-edit>
              <app-form-combo-edit i18n-label label="Tipus tiquet" [(value)]="this.filter.idTiquet" [items]="this.tipusTiquets" valueField="codi" textField="descripcio"></app-form-combo-edit>
            </div>
      
            <div class="border border-secundary" style="padding:5px; margin-top: 5px;">
              <h5 i18n>ANUL·LACIÓ</h5>
              <div class="row" style="margin:auto">
                <div class="col border border-secundary" style="padding:5px; margin-top: 5px;">
                  <h6 i18n>SENSE TIQUET</h6>
                  <app-filter-text-edit id="tarifes-filter-anulSenseTiquet" i18n-label label="Preu" [(value)]="this.filter.anulSenseTiquet" [number]="true"></app-filter-text-edit>
                  <app-filter-text-edit id="tarifes-filter-tempsSenseTiquet" i18n-label label="Temps" [(value)]="this.filter.tempsSenseTiquet" [number]="true"></app-filter-text-edit>
                </div>
                <div class="col border border-secundary" style="padding:5px; margin-top: 5px;">
                  <h6 i18n>TIQUET EXHAURIT</h6>
                  <app-filter-text-edit id="tarifes-filter-anulExhaurit" i18n-label label="Preu" [(value)]="this.filter.anulExhaurit" [number]="true"></app-filter-text-edit>
                  <app-filter-text-edit id="tarifes-filter-tempsExhaurit" i18n-label label="temps" [(value)]="this.filter.tempsExhaurit" [number]="true"></app-filter-text-edit>
                </div>
              </div>  
            </div>    
    
          </div>
          <div class="col">
            <div class="row">
              <div class="col-1">
    
              </div>
              <div class="col-2" i18n>
                INICI TARIFA
              </div>
              <div class="col-2" i18n>
                INICI PAUSA
              </div>
              <div class="col-2" i18n>
                FI PAUSA
              </div>
              <div class="col-2" i18n>
                FI TARIFA
              </div>
            </div>
            
            <div class="row" >
              <div class="col-1" i18n>
                DILLUNS
              </div>
              <div class="col-2">
                <mat-form-field>
                  <input type="time" matInput  [value]="this.filter.desde1" (input)="this.filter.desde1=$event.target.value">
                </mat-form-field>
              </div>
              <div class="col-2">
                <mat-form-field>
                  <input type="time" matInput  [value]="this.filter.iniciPausa1" (input)="this.filter.iniciPausa1=$event.target.value" >
                </mat-form-field>
              </div>
              <div class="col-2">
                <mat-form-field>
                  <input type="time" matInput  [value]="this.filter.fiPausa1" (input)="this.filter.fiPausa1=$event.target.value">
                </mat-form-field>
              </div>
              <div class="col-2">
                <mat-form-field>
                  <input type="time" matInput  [value]="this.filter.hasta1" (input)="this.filter.hasta1=$event.target.value">
                </mat-form-field>
              </div>
            </div>
    
            <div class="row" >
              <div class="col-1" i18n>
                DIMARTS
              </div>
              <div class="col-2">
                <mat-form-field>
                  <input type="time" matInput  [value]="this.filter.desde2" (input)="this.filter.desde2=$event.target.value" >
                </mat-form-field>
              </div>
              <div class="col-2">
                <mat-form-field>
                  <input type="time" matInput  [value]="this.filter.iniciPausa2" (input)="this.filter.iniciPausa2=$event.target.value" >
                </mat-form-field>
              </div>
              <div class="col-2">
                <mat-form-field>
                  <input type="time" matInput  [value]="this.filter.fiPausa2" (input)="this.filter.fiPausa2=$event.target.value" >
                </mat-form-field>
              </div>
              <div class="col-2">
                <mat-form-field>
                  <input type="time" matInput  [value]="this.filter.hasta2" (input)="this.filter.hasta2=$event.target.value" >
                </mat-form-field>
              </div>
            </div>
    
            <div class="row" >
              <div class="col-1" i18n>
                DIMECRES
              </div>
              <div class="col-2">
                <mat-form-field>
                  <input type="time" matInput  [value]="this.filter.desde3" (input)="this.filter.desde3=$event.target.value" >
                </mat-form-field>
              </div>
              <div class="col-2">
                <mat-form-field>
                  <input type="time" matInput  [value]="this.filter.iniciPausa3" (input)="this.filter.iniciPausa3=$event.target.value" >
                </mat-form-field>
              </div>
              <div class="col-2">
                <mat-form-field>
                  <input type="time" matInput  [value]="this.filter.fiPausa3" (input)="this.filter.fiPausa3=$event.target.value" >
                </mat-form-field>
              </div>
              <div class="col-2">
                <mat-form-field>
                  <input type="time" matInput  [value]="this.filter.hasta3" (input)="this.filter.hasta3=$event.target.value" >
                </mat-form-field>
              </div>
            </div>
    
            <div class="row" >
              <div class="col-1" i18n>
                DIJOUS
              </div>
              <div class="col-2">
                <mat-form-field>
                  <input type="time" matInput  [value]="this.filter.desde4" (input)="this.filter.desde4=$event.target.value" >
                </mat-form-field>
              </div>
              <div class="col-2">
                <mat-form-field>
                  <input type="time" matInput  [value]="this.filter.iniciPausa4" (input)="this.filter.iniciPausa4=$event.target.value" >
                </mat-form-field>
              </div>
              <div class="col-2">
                <mat-form-field>
                  <input type="time" matInput  [value]="this.filter.fiPausa4" (input)="this.filter.fiPausa4=$event.target.value" >
                </mat-form-field>
              </div>
              <div class="col-2">
                <mat-form-field>
                  <input type="time" matInput  [value]="this.filter.hasta4" (input)="this.filter.hasta4=$event.target.value" >
                </mat-form-field>
              </div>
            </div>
    
            <div class="row" >
              <div class="col-1" i18n>
                DIVENDRES
              </div>
              <div class="col-2">
                <mat-form-field>
                  <input type="time" matInput  [value]="this.filter.desde5" (input)="this.filter.desde5=$event.target.value" >
                </mat-form-field>
              </div>
              <div class="col-2">
                <mat-form-field>
                  <input type="time" matInput  [value]="this.filter.iniciPausa5" (input)="this.filter.iniciPausa5=$event.target.value" >
                </mat-form-field>
              </div>
              <div class="col-2">
                <mat-form-field>
                  <input type="time" matInput  [value]="this.filter.fiPausa5" (input)="this.filter.fiPausa5=$event.target.value" >
                </mat-form-field>
              </div>
              <div class="col-2">
                <mat-form-field>
                  <input type="time" matInput  [value]="this.filter.hasta5" (input)="this.filter.hasta5=$event.target.value" >
                </mat-form-field>
              </div>
            </div>
    
            <div class="row" >
              <div class="col-1" i18n>
                DISSABTE
              </div>
              <div class="col-2">
                <mat-form-field>
                  <input type="time" matInput  [value]="this.filter.desde6" (input)="this.filter.desde6=$event.target.value" >
                </mat-form-field>
              </div>
              <div class="col-2">
                <mat-form-field>
                  <input type="time" matInput  [value]="this.filter.iniciPausa6" (input)="this.filter.iniciPausa6=$event.target.value" >
                </mat-form-field>
              </div>
              <div class="col-2">
                <mat-form-field>
                  <input type="time" matInput  [value]="this.filter.fiPausa6" (input)="this.filter.fiPausa6=$event.target.value" >
                </mat-form-field>
              </div>
              <div class="col-2">
                <mat-form-field>
                  <input type="time" matInput  [value]="this.filter.hasta6" (input)="this.filter.hasta6=$event.target.value" >
                </mat-form-field>
              </div>
            </div>
    
            <div class="row" >
              <div class="col-1" i18n>
                DIUMENGE
              </div>
              <div class="col-2">
                <mat-form-field>
                  <input type="time" matInput  [value]="this.filter.desde7" (input)="this.filter.desde7=$event.target.value" >
                </mat-form-field>
              </div>
              <div class="col-2">
                <mat-form-field>
                  <input type="time" matInput  [value]="this.filter.iniciPausa7" (input)="this.filter.iniciPausa7=$event.target.value" >
                </mat-form-field>
              </div>
              <div class="col-2">
                <mat-form-field>
                  <input type="time" matInput  [value]="this.filter.fiPausa7" (input)="this.filter.fiPausa7=$event.target.value" >
                </mat-form-field>
              </div>
              <div class="col-2">
                <mat-form-field>
                  <input type="time" matInput  [value]="this.filter.hasta7" (input)="this.filter.hasta7=$event.target.value" >
                </mat-form-field>
              </div>
            </div>
    
            
          </div>    
  </div>
</div>
