import { I18n } from '@ngx-translate/i18n-polyfill';
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'longDate'
})
export class LongDatePipe extends DatePipe implements PipeTransform {
  constructor(private i18n: I18n) {
    super('ca');
  }
  transform(value: any, args?: any): any {
    if (value === null) {
      return '';
    } else {
      const date = new Date(value);
      if (date.getFullYear() >= 2100) {
        return this.i18n('sense final');
      } else {
        return super.transform(value, 'dd/MM/yyyy HH:mm:ss');
      }
    }

  }
}
