<app-dialog-header label="Persona" i18n-label ></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Editar" i18n-text (click)="this.dialogData.isReadOnly = false" color="has-color-green" [disabled]="!this.dialogData.isReadOnly" icon="fa-edit" *ngIf="this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Guardar" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save" [disabled]="this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Eliminar" i18n-text (click)="this.remove();" color="has-color-red" icon="fa-trash-alt" [disabled]="this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Tancar" i18n-text (click)="this.cancel()" color="has-color-yellow" icon="fa-times"></app-tool-button>
  
</app-toolbar>
<app-dialog-body>
  <form #mainForm>
    <div class="row">
      <app-form-text-edit id="persones-editor-nom" class="col-sm-8" i18n-label label="Nom pila:" [(value)]="this.persona.nom" (valueChange)="this.canviarNombreCompleto()" required  [disabled]="this.dialogData.isReadOnly"   maxLength="80"></app-form-text-edit>
      <app-form-text-edit id="persones-editor-telefons" class="col-sm-4" i18n-label label="Telèfons:" [(value)]="this.persona.telefon1"  maxLength="15"  [disabled]="this.dialogData.isReadOnly" ></app-form-text-edit>
    </div>
    <div class="row">
      <app-form-text-edit id="persones-editor-cognom1" class="col-sm-4" i18n-label label="Cognom 1:" [(value)]="this.persona.cog1" (valueChange)="this.canviarNombreCompleto()" [required]="(this.persona.juridica ===undefined  || this.persona.juridica ===null  ||!this.persona.juridica)" maxLength="50"  [disabled]="this.dialogData.isReadOnly" ></app-form-text-edit>
      <app-form-text-edit id="persones-editor-cognom2" class="col-sm-4" i18n-label label="Cognom 2:" [(value)]="this.persona.cog2" (valueChange)="this.canviarNombreCompleto()" [required]="(this.persona.juridica ===undefined  || this.persona.juridica ===null  ||!this.persona.juridica)" maxLength="50"  [disabled]="this.dialogData.isReadOnly" ></app-form-text-edit>
      <app-form-text-edit id="persones-editor-telefon2" class="col-sm-4" i18n-label  [(value)]="this.persona.telefon2"   maxLength="15"  [disabled]="this.dialogData.isReadOnly" ></app-form-text-edit>
    </div>
    <div class="row">
      <div class="form-group col-sm-4">
        <mat-form-field >
          <mat-label  i18n-label>Nom complet</mat-label>
          <input matInput   [value]="this.nomComplet.value"  disabled="true">
        </mat-form-field>
      </div> 
      <app-form-text-edit id="persones-editor-dni" class="col-sm-4" i18n-label label="DNI/PASP:" [(value)]="this.persona.dni_pasp" required maxLength="15"  [disabled]="this.dialogData.isReadOnly" ></app-form-text-edit>
      <app-form-check-edit class="col-sm-2" i18n-label label="Jurídica" [(value)]="this.persona.juridica" (valueChange)="canviarNombreCompleto()" required  [disabled]="this.dialogData.isReadOnly" ></app-form-check-edit>
      <app-form-text-edit id="persones-editor-idExt" class="col-sm-2" i18n-label label="ID EXTERNO:" [(value)]="this.persona.id_extern"   maxLength="15"  [disabled]="this.dialogData.isReadOnly" ></app-form-text-edit>
    </div>
    <div class="row">
      <app-form-date-edit id="persones-editor-dataNaix" class="col-sm-3" i18n-label label="Data naix:" [(value)]="this.persona.data_naixament" type="calendar"  [isReadOnly]="this.dialogData.isReadOnly" ></app-form-date-edit>
      <app-form-text-edit  id="persones-editor-llocNaix" class="col-sm-3" i18n-label label="Lloc naixament:" [(value)]="this.persona.lloc_naixament"   maxLength="15"  [disabled]="this.dialogData.isReadOnly" ></app-form-text-edit>
      <app-form-check-edit class="col-sm-2" i18n-label label="Buscat" [(value)]="this.persona.buscat" (valueChange)="null" required  [disabled]="this.dialogData.isReadOnly" ></app-form-check-edit>
      <app-form-check-edit class="col-sm-2" i18n-label label="Antecedents" [(value)]="this.persona.antecedents" (valueChange)="null" required  [disabled]="this.dialogData.isReadOnly" ></app-form-check-edit>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <app-form-text-edit  id="persones-editor-nacionalitat"  class="col-sm-6" i18n-label label="Nacionalitat:" [(value)]="this.persona.nacionalitat"   maxLength="15"  [disabled]="this.dialogData.isReadOnly" ></app-form-text-edit>  
          <app-form-text-edit   id="persones-editor-provincia" class="col-sm-6" i18n-label label="Provinicia:" [(value)]="this.persona.provincia"   maxLength="15"  [disabled]="this.dialogData.isReadOnly" ></app-form-text-edit>  
        </div>
        <div class="row">
          <app-form-text-edit id="persones-editor-nomPare"   class="col-sm-6" i18n-label label="Nom pare:" [(value)]="this.persona.nom_pare"   maxLength="15"  [disabled]="this.dialogData.isReadOnly" ></app-form-text-edit>  
          <app-form-text-edit id="persones-editor-nomMare"    class="col-sm-6"i18n-label label="Nom mare:" [(value)]="this.persona.nom_mare"   maxLength="15"  [disabled]="this.dialogData.isReadOnly" ></app-form-text-edit>
        </div>
      </div>
      <div class="col-md-6">
        <app-form-text-area    class="col-xl-12" i18n-label label="Observacions" [(value)]="this.persona.observacions" maxLength="5000"  [disabled]="this.dialogData.isReadOnly" ></app-form-text-area>
      </div>
    </div>
  </form>
  <div class="row">    
    <div class="col">
    <form #domicilisForm>
      <mat-tab-group class="has-frame">
        <mat-tab i18n-label label="Domicilis">
          <div mat-dialog-content>
              <mat-icon *ngIf="!this.dialogData.isReadOnly" (click)="this.getDomicilisWithNewRow()" 
                class="cursor fa" 
                data-toggle="tooltip" i18n-title title="Crear nuevo">library_add</mat-icon>
              <mat-icon *ngIf="!this.dialogData.isReadOnly" (click)="this.openDialog('Add',{})" 
              class="cursor fa" 
              data-toggle="tooltip" i18n-title title="Crear nuevo desde el editor">note_add</mat-icon>
          
            <!--<app-tool-button text="Nou" i18n-text [disabled]="this.persona.secure_id === '' || this.dialogData.isReadOnly"  (click)="this.openDialog('Add',{})" style="height: 10vmax" color="has-color-green" icon="fa-plus-square" ></app-tool-button>-->
            <div class="data" style="width: auto;">
              <mat-table #table [dataSource]="this.domicilis.value" matSort aria-label="Elements" class="is-header-sticky table" style="max-height: 200;">
                <ng-container matColumnDef="id">
                  <mat-header-cell *matHeaderCellDef>ID</mat-header-cell>                  
                  <mat-cell class="cCell" *matCellDef="let element"> {{element.som_id}} </mat-cell>
                </ng-container>
            
                <!-- Name Column -->
                <ng-container matColumnDef="name">
                  <mat-header-cell *matHeaderCellDef>Nom</mat-header-cell>
                  <mat-cell class="cCell" *matCellDef="let element"> {{element.nom}} </mat-cell>
                </ng-container>
            
                <ng-container matColumnDef="dataDomicili">
                  <mat-header-cell *matHeaderCellDef style="width:7%" i18n>Data</mat-header-cell> 
                  <mat-cell class="cCell" *matCellDef="let element">
                    <app-form-date-edit [(value)]="element.dataDomicili" label="Fecha" i18n-label></app-form-date-edit>
                  </mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="dirSimple">
                  <mat-header-cell *matHeaderCellDef i18n>Direcció</mat-header-cell>                    
                  <mat-cell class="cCell" *matCellDef="let element"> 
                    <app-form-text-edit  required label="Dirección" i18n-label [(value)]="element.dirSimple" (valueChange)="this.setDireccioCompleta(element)" required></app-form-text-edit>
                  </mat-cell>
                </ng-container>
      
                <ng-container matColumnDef="numero" >
                  <mat-header-cell *matHeaderCellDef style="width:1%" i18n>Número</mat-header-cell>
                  <mat-cell class="cCell" *matCellDef="let element"> 
                    <app-form-text-edit label="Número" i18n-label [(value)]="element.numero" (valueChange)="this.setDireccioCompleta(element)"></app-form-text-edit>
                  </mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="escala" >
                  <mat-header-cell *matHeaderCellDef style="width:4%" i18n>Escala</mat-header-cell>
                  <mat-cell class="cCell" *matCellDef="let element"> 
                    <app-form-text-edit  label="Escalera" i18n-label [(value)]="element.escala" (valueChange)="this.setDireccioCompleta(element)"></app-form-text-edit>
                  </mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="pis">
                  <mat-header-cell *matHeaderCellDef style="width:3%" i18n>Pis</mat-header-cell>
                  <mat-cell class="cCell" *matCellDef="let element"> 
                    <app-form-text-edit label="Piso" i18n-label [(value)]="element.pis" (valueChange)="this.setDireccioCompleta(element)"></app-form-text-edit>
                  </mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="porta">
                  <mat-header-cell *matHeaderCellDef style="width:1%" i18n>Porta</mat-header-cell>
                  <mat-cell class="cCell" *matCellDef="let element"> 
                    <app-form-text-edit label="Puerta" i18n-label [(value)]="element.porta" (valueChange)="this.setDireccioCompleta(element)"></app-form-text-edit>
                  </mat-cell>
                </ng-container>
      
                <ng-container matColumnDef="domicili">
                  <mat-header-cell *matHeaderCellDef style="width:12%" i18n>Domilici</mat-header-cell>
                  <mat-cell class="cCell" *matCellDef="let element"> 
                    <app-form-text-edit label="Domicilio" i18n-label disabled [(value)]="element.domicili"></app-form-text-edit>
                  </mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="codiPostal">
                  <mat-header-cell *matHeaderCellDef style="width:1%" i18n>Codi postal</mat-header-cell>
                  <mat-cell class="cCell" *matCellDef="let element"> 
                    <app-form-text-edit label="C. Postal" [(value)]="element.codiPostal" i18n-label (valueChange)="autofillByCP(element)"></app-form-text-edit>
                  </mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="localitat">
                  <mat-header-cell *matHeaderCellDef i18n>Localitat</mat-header-cell>
                  <mat-cell class="cCell" *matCellDef="let element"> 
                    <app-form-combo-edit label="Localitat" i18n-label [(value)]="element.localitat" [items]="this.municipiosDictionary" textField="value" valueField="value"></app-form-combo-edit>
                  </mat-cell>
                </ng-container>                
      
                <ng-container matColumnDef="provincia">
                  <mat-header-cell *matHeaderCellDef i18n>Provincia </mat-header-cell>
                  <mat-cell class="cCell" *matCellDef="let element"> 
                    <app-form-combo-edit label="Provincia" i18n-label [(value)]="element.provincia" [items]="this.provinciasDictionary" textField="value" valueField="value"></app-form-combo-edit>
                  </mat-cell>
                </ng-container>
      
                <ng-container matColumnDef="pais">
                  <mat-header-cell *matHeaderCellDef i18n>Pais</mat-header-cell>
                  <mat-cell class="cCell" *matCellDef="let element">
                    <app-form-combo-edit label="País" i18n-label [(value)]="element.pais" [items]="this.paisosDictionary" textField="value" valueField="value"></app-form-combo-edit>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actual">
                  <mat-header-cell *matHeaderCellDef i18n>Actual</mat-header-cell>
                  <mat-cell class="cCell" *matCellDef="let element">
                    <app-form-check-edit [(value)]="element.actual"></app-form-check-edit>
                  </mat-cell>
                </ng-container>
      
                <ng-container matColumnDef="origen">
                  <mat-header-cell *matHeaderCellDef style="width:1%" i18n>Origen</mat-header-cell>
                  <mat-cell class="cCell" *matCellDef="let element"> 
                    <app-form-text-edit value='SDW' disabled></app-form-text-edit>
                  </mat-cell>
                </ng-container>
      
                <ng-container matColumnDef="id_extern">
                  <mat-header-cell *matHeaderCellDef i18n>Id. extern</mat-header-cell>
                  <mat-cell class="cCell" *matCellDef="let element"> 
                    <app-form-text-edit  label="Id. Externo" i18n-label [(value)]="element.id_extern"></app-form-text-edit>
                  </mat-cell>
                </ng-container>
                
                <!-- Action Column -->
                <ng-container matColumnDef="action">
                  <mat-header-cell *matHeaderCellDef i18n>Acciones</mat-header-cell>
                  <mat-cell class="cCell" *matCellDef="let element" class="action-link"> 
                    <i *ngIf="element.secure_id!==undefined && this.dialogData.isReadOnly"  
                    class="cursor fa fa-edit btn btn-sm has-color-gray btn-outline-secondary" data-toggle="tooltip" i18n-title title="Modificar desde editor"></i>
                    <i *ngIf="element.secure_id!==undefined && !this.dialogData.isReadOnly" (click)="this.openDialog('Update',element)" 
                    class="cursor fa fa-edit btn btn-outline-warning btn-sm" data-toggle="tooltip" i18n-title title="Modificar desde editor"></i>
                    <i *ngIf="element.secure_id!==undefined && this.dialogData.isReadOnly" 
                    class="cursor fa fa-trash-alt btn btn-sm has-color-gray  btn-outline-secondary" data-toggle="tooltip" i18n-title title="Eliminar"></i>
                    <i *ngIf="element.secure_id!==undefined && !this.dialogData.isReadOnly" (click)="this.openDialog('Delete',element)" 
                    class="cursor fa fa-trash-alt btn btn-outline-danger btn-sm" data-toggle="tooltip" i18n-title title="Eliminar"></i>
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </mat-table>
            </div>              
          </div>
        </mat-tab>      
      </mat-tab-group>
    </form>
    </div>    
  </div>
</app-dialog-body>
