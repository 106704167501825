import { AppComponent } from './../../../app.component';
import { LoginPageComponent } from './../../../sections/login/pages/login-page/login-page.component';
import { AuthGuardService } from './../../services/authentication/auth-guard.service';
import { Component, OnInit } from '@angular/core';
import { DropdownInstitucionsService } from './dropdown-institucions.service';
import { Instituciones } from 'path-shared/models/instituciones';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'app/sections/login/services/login.service';

@Component({
  selector: 'app-dropdown-institucions',
  templateUrl: './dropdown-institucions.component.html'
})
export class DropdownInstitucionsComponent implements OnInit {
  institucions: Instituciones[];
  currentInstitutionName: string;

  constructor(
    private dropdownInstitucionsService: DropdownInstitucionsService,
    private cookieService: CookieService,
    private authGuard: AuthGuardService,
    private loginService: LoginService,
  ) {}

  ngOnInit() {
    if (this.authGuard.userLoginCompleted()) {
      this.getInstitucions();
    } else {
      this.loginService.loginFinished.subscribe(completed => {
        if (completed) {
          this.getInstitucions();
        }
      });
    }
  }

  getInstitucions() {
    this.dropdownInstitucionsService.getInstitucions().subscribe(result => {
      this.institucions = result;
      this.getCurrentInstitutionName();
    });
  }

  changeInstitucio(id) {
    const inst = this.institucions.find(x => x.IdInstitucion === id);
    AppComponent.setInstitution(inst);

    this.dropdownInstitucionsService.setNewIdInstitution(id);
  }

  getCurrentInstitutionName() {
    let id = sessionStorage.getItem('IdInstitucion');
    if (!id) {
      id = this.institucions[0].NombreInstitucion;
      sessionStorage.setItem('IdInstitucion', id);
      localStorage.setItem('IdInstitucion', id);
      this.cookieService.set('InstitucioSeleccionada', id);
    }
    const inst = this.institucions.find(x => x.IdInstitucion === id);
    AppComponent.setInstitution(inst);
    this.currentInstitutionName = inst.NombreInstitucion;
  }
}
