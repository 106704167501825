import { DesperfectosEditorComponent } from '../desperfectos-editor/desperfectos-editor.component';
import { VehicleDipositDto } from '../../Models/diposit-vehicle.model';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { DipositGruaService } from '../../services/diposit-grua-service';
import { DesperfectosDto } from '../../Models/desperfectosDto';
import { MatDialog, MatDialogRef } from '@angular/material';
import { PhotoBoxGruaComponent } from '../photo-box-grua/photo-box-grua.component';
import { DenunciesImagesComponent } from 'app/sections/denuncies/components/denuncies-editor/components/denuncies-images/denuncies-images.component';
import { DipositGruaFilterService } from '../../services/diposit-grua-filter-service';
import { DesperfectosV2Dto } from '../../Models/desperfectosV2Dto';
import { of } from 'rxjs';
import { ImagesService } from 'path-shared/services/images/images.service';
import { ToastrService } from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { DenunciesService } from 'path-denuncies-services/denuncies.service';

@Component({
  selector: 'app-diposit-grua-desperfectos-v2',
  templateUrl: './diposit-grua-desperfectos-v2.component.html',
  styleUrls: ['./diposit-grua-desperfectos-v2.component.scss']
})
export class DipositGruaDesperfectosV2Component implements OnInit {


  desperfectos = new DesperfectosV2Dto();

  @Input() codiSomPolGrua: string;

  comentarioAntes = 'comentarioAntes';
  comentarioDespues = 'comentarioDespues';
  comentarioOtros = 'comentarioOtros';
  editComentarioAntes = false;
  editComentarioDespues = false;
  editComentarioOtros = false;

  // Añadir ficheros
  files = [];
  @ViewChild('fileInput') fileInput: any;
  categoria: string;
  constructor(
    private gruaService: DipositGruaService,
    private toastr: ToastrService,
    private i18n: I18n,
    private denunciesService: DenunciesService
  ) { }

  ngOnInit() {
    this.loadDesperfectos();
  }

  loadDesperfectos() {
    this.gruaService.getDesperfectosV2(this.codiSomPolGrua).subscribe((res: DesperfectosV2Dto) => {
      this.desperfectos = res;
      this.comentarioAntes = res.ComentarioAntes;
      this.comentarioDespues = res.ComentarioDespues;
      this.comentarioOtros = res.ComentarioOtros;
    })
  }

  saveComentario(categoria: string) {
    let comentario='';
    switch (categoria) {
      case 'BEFORE': {
        this.desperfectos.ComentarioAntes = this.comentarioAntes;
        comentario=this.desperfectos.ComentarioAntes;
        this.editComentarioAntes = false;
        break;
      }
      case 'AFTER': {
        this.desperfectos.ComentarioDespues = this.comentarioDespues;
        comentario=this.desperfectos.ComentarioDespues;
        this.editComentarioDespues = false;
        break;
      }
      case 'OPTIONAL': {
        this.desperfectos.ComentarioOtros = this.comentarioOtros;
        comentario=this.desperfectos.ComentarioOtros;
        this.editComentarioOtros = false;
        break;
      }
    }
    this.gruaService.saveComentarioDesperfecto(this.codiSomPolGrua, categoria, comentario).subscribe();
  }
  cancelComentario(categoria: string) {
    switch (categoria) {
      case 'BEFORE': {
        this.comentarioAntes = this.desperfectos.ComentarioAntes;
        this.editComentarioAntes = false;
        break;
      }
      case 'AFTER': {
        this.comentarioDespues = this.desperfectos.ComentarioDespues;
        this.editComentarioDespues = false;
        break;
      }
      case 'OPTIONAL': {
        this.comentarioOtros = this.desperfectos.ComentarioOtros;
        this.editComentarioOtros = false;
        break;
      }
    }
  }

  afegir(categoria: string) {
    const event = new MouseEvent('click', { bubbles: false });
    this.fileInput.file.nativeElement.dispatchEvent(event);
    this.categoria = categoria;
  }

  fileReady(files) {
    this.files = files;
    this.processar();
  }

  processar() {
    if (this.files === undefined || this.files === null) {
      this.toastr.warning(this.i18n('Cal seleccionar un fitxer per carregar!'));
    } else {

      const formData: FormData = new FormData();

      this.files.forEach(file => {
        let fileNameNoExtension = file.name.substr(0, file.name.lastIndexOf('.'));
        let extension = file.name.substr(file.name.lastIndexOf('.'), file.name.lentgh);
        let fileName = fileNameNoExtension + '_' + this.categoria + extension;
        formData.append('file', file, fileName);
      });
      this.denunciesService.afegirImatgesGrua(formData, this.codiSomPolGrua).subscribe(() => {
        this.toastr.info(this.i18n('Fitxers carregats correctament'));
        this.loadDesperfectos();
      });

    }

  }

}
