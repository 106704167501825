import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { AlarmaFilter } from '../models/alarma-filter';
import { HttpParams } from '@angular/common/http';
import { Reflection } from 'path-shared/services/reflection/reflection';

const defaultSortParam = 'data';
const defaultSortDirection = 'desc';

@Injectable({
  providedIn: 'root'
})
export class AlarmesFilterService extends CustomFilterService {
  public createFilterParams(filter: AlarmaFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {
    let datafi: Date = null;
    if ( filter.data !== null ) {
      datafi = new Date();
      const copy: Date =  new Date(filter.data);
      copy.setHours(23);
      copy.setMinutes(59);
      copy.setSeconds(59);
      datafi = copy;
  }
    return new HttpParams()
    .append('id', filter.id ? filter.id.toString() : '')
    .append('text', filter.text ? filter.text.toString() : '')
    .append('dades', filter.dades ? filter.dades.toString() : '')
    .append('dataFi', filter.data === null ? null : datafi.toJSON())
    .append('dataInici', filter.data === null ? null : filter.data.toJSON())
    .append('idUsuariACK', Reflection.hasValue(filter.IdUsuariACK) ? filter.IdUsuariACK : '')
    .append('idTipusAlarma', Reflection.hasValue(filter.idTipusAlarma) ? filter.idTipusAlarma : '')
    .append('usuari', filter.usuari ? filter.usuari : '')
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('matricula', filter.matricula  ? filter.matricula.toString() : '')
    .append('tradesegur_id', filter.tradesegur_id  ? filter.tradesegur_id.toString() : '')
    .append('data_ack',  filter.data_ack === null ? null : filter.data_ack.toJSON())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }

  getTristateValue(value) {
    if (value === null) {
      return '';
    } else {
      return value.toString();
    }
  }
}
