import { Component, OnInit, AfterViewInit, OnChanges, ViewChild, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { Builder } from 'path-shared/models/builder';
import { ObservacioPda } from '../../models/observacions-pda.model';
import { ObservacionsPdaFilter } from '../../models/observacions-pda-filter';
import { ObservacionsPdaService } from '../../services/observacions-pda.service.ts.service';
import { ObservacionsPdaFilterService } from '../../services/observacions-pda-filter.service.ts.service';
import { StartupService } from 'path-shared/services/startup/startup.service';
import { CFG_TIPODENUNCIA } from 'path-models/CFG_TIPODENUNCIA';
import { Dictionary } from 'path-shared/models/dictionary';

@Component({
  selector: 'app-observacions-pda-table',
  templateUrl: './observacions-pda-table.component.html',
  styleUrls: ['./observacions-pda-table.component.scss']
})
export class ObservacionsPdaTableComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  data: ObservacioPda[];
  dataSource: CustomDataSource<ObservacioPda, ObservacionsPdaFilter>;
  filter: ObservacionsPdaFilter = new ObservacionsPdaFilter('codi', 'asc');
  displayedItems = [];
  highlightedRows = [];
  tipusDenuncia = [];
  @Input() selectButton: boolean;
  @Input() updatedFilterData: ObservacionsPdaFilter;
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();
  @Output() itemClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() itemSelected: EventEmitter<string> = new EventEmitter<string>();

  displayedColumns = ['codi', 'descripcio', 'tipus'];

  constructor(
    private observacionsPdaService: ObservacionsPdaService,
    private filterService: FilterService,
    private dialog: MatDialog,
    private i18n: I18n,
    private startupService: StartupService,
  ) {
    this.startupService.GetTiposDenuncia().forEach((tipusDenuncia: CFG_TIPODENUNCIA) => {
      this.tipusDenuncia.push(new Dictionary(tipusDenuncia.CODI, tipusDenuncia.DESCRIPCIO));
    });
  }

  ngOnInit() {
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<ObservacioPda, ObservacionsPdaFilter>(this.paginator, new ObservacionsPdaFilterService(), 'urlGetObservacionsPda', 'urlGetObservacionsPdaCount');
    this.dataSource.subject.subscribe((data) => {
      data.forEach(item => {
        this.displayedItems[item.secureId] = {
          id: item.secureId
        };
      });
    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
        if (!this.selectButton) { this.filterService.saveFilter('observacions-pda-filter', this.filter); }
      }
    });

    if (!this.selectButton) { this.filterService.loadFilter('observacions-pda-filter', this.filter); }
    this.filter.paginator.init(this.sort, this.paginator);
  }

  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(tap(() => this.filter.updated()))
    .subscribe();

    this.load();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      this.update();
    }
  }

  update() {
    if (this.dataSource !== undefined) {
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
    }
  }

  load() {
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }

  onRowClicked(row) {
    this.itemClicked.next(row.secureId);
  }

  generateExcel(): void {
    let filter = this.filter;
    if (this.updatedFilterData != null) {
      filter = this.updatedFilterData;
    }
    this.dialog.open(DownloadExcelDialogComponent, {
        closeOnNavigation: false,
        autoFocus: true,
        data: Builder.newDownloadExcelDialogData (this.observacionsPdaService, filter, this.i18n('Observaciones PDA'), this.sort.direction, this.sort.active)
    });
  }

  GetTipusDenuncia(tipus) {
    return this.tipusDenuncia.find(x => x.key === tipus).value;
  }
}
