import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { GruaMotiusDesestimarFilter } from './models/grua-motius-desestimar-filter';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { GruaMotiusDesestimarTableComponent } from './components/grua-motius-desestimar-table/grua-motius-desestimar-table.component';
import { GruaMotiusDesestimarEditorComponent } from './components/grua-motius-desestimar-editor/grua-motius-desestimar-editor.component';
import { GruaMotiusDesestimarFilterComponent } from './components/grua-motius-desestimar-filter/grua-motius-desestimar-filter.component';

@Component({
    selector: 'app-grua-motius-desestimar',
    templateUrl: 'grua-motius-desestimar.component.html',
    styleUrls: ['grua-motius-desestimar.component.scss']
})
export class GruaMotiusDesestimarComponent implements OnInit, AfterViewInit {
    filterData: GruaMotiusDesestimarFilter;
    @ViewChild(GruaMotiusDesestimarTableComponent) gruaMotiusDesestimarTable: GruaMotiusDesestimarTableComponent;

    constructor(
      private dialog: MatDialog,
      public auth: AuthorizationService,
      private i18n: I18n,
      private not: NotificationService
      ) {
        this.filterData = new GruaMotiusDesestimarFilter('motiu', 'asc');
      }

    ngOnInit(): void {
      this.auth.UserCanSeePage(this.auth.Pages.GruaMotiusDesestimar);
      this.gruaMotiusDesestimarTable.itemClicked.subscribe(secure_id => {
        this.update(secure_id);
      });
    }

    ngAfterViewInit() {
      this.not.titleChange(this.i18n('Motius desestimació'));
    }

    edit() {
      const idSeleccionat: string = this.gruaMotiusDesestimarTable.displayedItems['checkedItems'][0];
      this.update(idSeleccionat);
    }

    create() {
      this.update(null);
    }

    update(secure_id: string) {
      const data: ComponentDialog = Builder.newComponentDialog(true, false, secure_id);

      const ref = this.dialog.open(GruaMotiusDesestimarEditorComponent, {
        width: '40%',
        closeOnNavigation: false,
        autoFocus: true,
        data: data
      });

      ref.afterClosed().subscribe(updated => {
        if (updated) { this.gruaMotiusDesestimarTable.load(); }
      });
    }

    refresh() {
      this.gruaMotiusDesestimarTable.load();
    }

    onFilterClicked() {
      const ref = this.dialog.open(GruaMotiusDesestimarFilterComponent, {
        width: '40%',
        closeOnNavigation: false,
        autoFocus: true,
        data: {
          'filter': this.gruaMotiusDesestimarTable.filter
        }
      });

      ref.componentInstance.notify.subscribe((filter: GruaMotiusDesestimarFilter) => {
        this.gruaMotiusDesestimarTable.filter.set(filter);
        this.gruaMotiusDesestimarTable.load();
      });
    }

    onRowSelected(somid) {
      this.update(somid);
    }

    generateExcel() {
      this.gruaMotiusDesestimarTable.generateExcel();
    }
}
