import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { map } from 'rxjs/operators';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { Observable } from 'rxjs';
import { AnulacionZonaAzulAuto } from 'app/sections/anulacion-zona-azul-auto/models/anulacion-zona-azul-auto.model';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { ValorarAnulacionModel } from 'app/sections/anulacion-zona-azul-auto/models/valorarAnulacion.model';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AnulacionZonaServiceManual {

  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) { }



  getDenuncia(nDenuncia: number): Observable<any> {
    return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAnulacio'].replace('{nDenuncia}', nDenuncia.toString()), null)
      .pipe(
        map((e => {
          return e;
        }))
      );
  }

  getDenunciasFromFile(fileString: Array<string>): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlGetAnulacionesFromFile'], fileString);
    }
  }

  processarAnulacio(anul: AnulacionZonaAzulAuto): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetAnulacio'], anul);
    }
  }

  processarAnulacioValorada(valorarAnulacion: ValorarAnulacionModel): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlAnulacionValorada'], valorarAnulacion);
    }
  }

  recalculateAmount(numDenuncia: string, cancelationDate: Date): Observable<any> {
    if (this.authGuard.canActivate()) {
      let httpParams = new HttpParams()
        .append('numDenuncia', numDenuncia)
        .append('cancelationDate',cancelationDate.toISOString())
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlRecalculateAmount'], httpParams);
    }
  }

}
