import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { Injectable } from '@angular/core';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Tracking } from '../models/tracking.model';
import { TrackingDetail } from '../models/trackingDetail.model';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { ParameterDto } from 'path-shared/services/parameters/model/ParametroDto';


const defaultSortParam = 'codi';
const defaultSortDirection = 'desc';


@Injectable()
export class TrackingService {
  displayedItems = [];


  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {
    this.displayedItems['checkedValues'] = 0;
    this.displayedItems['checkedItems'] = Array();
  }

  getAll(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetTracking'], null);
    }
  }

  getAllByDay(data: string ): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetTrackingByDay'].replace('{data}', data), null);
    }
  }

  GetPdaNotTracked(data: string ): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetPdaNotTracked'].replace('{data}', data), null);
    }
  }

  GetPdaConfig(data: string): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetPdaConfig'].replace('{data}', data), null);
    }
  }

  SetEstatPda(pda) {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetEstatPda'], pda);
    }
  }

  TrackPdaManual(pda: any): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlTrackPdaManual'], pda);
    }
  }
  TrackPdaAlways(pda: any) : Observable<any>{
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlTrackPdaAlways'], pda);
    }
  }
  UnTrackPdaAlways(pda: any): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlUnTrackPdaAlways'], pda);
    }
  }

  UnTrackPdaManual(pda: any): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlUnTrackPdaManual'], pda);
    }
  }
  GetInfoByPoint(point: any): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlGetInfoByPoint'], point);
    }
  }
 
  /*setCarrer(carrer: CarrerDetail): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetCarrer'], carrer);
    }
  }

  removeCarrer(id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteCarrer'].replace('{secure_id}', id), null);
    }
  }

  getCarrer(id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetCarrer'].replace('{secure_id}', id), null);
    }
  }*/
}
