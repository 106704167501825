<app-toolbar>  
  <app-tool-button-permissions [permission]="this.auth.Pages.Sincronitzacio"></app-tool-button-permissions>
</app-toolbar>

<app-dialog-body>

  <div class="row" style="max-width: 85%; margin-top: 3.5vh;">
    <div *ngIf="lastSyncDatePdas !== undefined" class="col-6">
      <div class="card h-100">
        <div class="card-header card-header-sdw">
          <h3 style="display: inline-block;">PDA</h3>
          <i class="fas fa-mobile-alt card-header-icon"></i>
        </div>
        <div class="card-body">
          <h5 class="card-title" i18n>Sincronització PDA</h5>
          <p class="card-text"i18n>Prem el botó "Actualitzar" per a què les PDAs rebin la configuració més actualitzada.</p>          
          <app-form-check-edit style="margin:auto; display: inline-block;" i18n-label label="Sincronització Completa?" [(value)]="this.completa"></app-form-check-edit>
          &nbsp;
          <i class="fas fa-info-circle" style="color: cornflowerblue; display: inline-block;"></i>
          &nbsp;
          <p class="card-text info" i18n >Actualitza també les dades de configuració de Municipis</p>          
          
          <button  class="btn btn-sq-lg btn-primary btn-sync" (click)="actualitzar()"><span i18n>Actualitzar</span></button>
        </div>
        <div class="card-footer" style="text-align: end;">
          <small class="text-muted" i18n>Última actualització: {{lastSyncDatePdas | longDate}}</small>
        </div>
      </div>
    </div>
    <!-- AÚN NO ESTÁ LISTO -->
    <!-- <div *ngIf="lastSyncDatePdas !== undefined && lastSyncDateDiba !== null" class="col-6">
      <div class="card h-100">
        <div class="card-header card-header-sdw">
          <h3 style="display: inline-block;">DIBA</h3>
          <i class="fas fa-download card-header-icon"></i>
        </div>
        <div class="card-body">
          <h5 class="card-title" i18n>Sincronització DIBA</h5>
          <p class="card-text">Prem el botó "Sincronitzar" per a descarregar la configuració més actualitzada desde DIBA.</p>
          <button style="margin-top: 3.5vh;" class="btn btn-sq-lg btn-primary btn-sync" (click)="sincronitzarDiba()"><span i18n>Sincronitzar</span></button>

        </div>
        <div class="card-footer" style="text-align: end;">
          <small class="text-muted">Última actualització: {{lastSyncDateDiba | longDate}}</small>
        </div>
      </div>
    </div> -->

    <div *ngIf="attention && lastSyncDateDiba !== null" class="col-6">
      <span *ngIf="attention" class="badge badge-pill badge-primary badge-sync-attention scale-animation" i18n-title title="Sincronització necessària!">!</span>
      <div class="card h-100">
        <div class="card-header card-header-sdw">
          <h3 style="display: inline-block;">GTWIN</h3>
          <i class="fas fa-download card-header-icon"></i> 
        </div>
        <div class="card-body">
          <h5 class="card-title" i18n>Sincronització GTWIN</h5>
          <p class="card-text" i18n>Prem el botó "Sincronitzar" per a descarregar la configuració més actualitzada desde GTWIN. 
            En acabar la sincronització amb GTWIN s'actualitzaràn les PDAs automàticament.
          </p>

          <button style="margin-top: 1.5vh;" class="btn btn-sq-lg btn-primary btn-sync" (click)="sincronitzarGtwin()"><span i18n>Sincronitzar</span></button>

        </div>
        <div class="card-footer" style="text-align: end;">
          <small class="text-muted">Última actualització: {{lastSyncDateDiba | longDate}}</small>
        </div>
      </div>
    </div>
  </div>
</app-dialog-body>