import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
import { GruaTecnicsFilter } from '../models/grua-tecnics-filter';

const defaultSortParam = 'codi';
const defaultSortDirection = 'asc';

@Injectable()
export class GruaTecnicsFilterService extends CustomFilterService {
  public createFilterParams(filter: GruaTecnicsFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {

    return new HttpParams()
    .append('codi', filter.codi ? filter.codi.toString() : '' )
    .append('nom', filter.nom)
    .append('DNI', filter.DNI)
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
