import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class PaisosFilter extends FilterData {
  codi: number;
  descripcio: string;
  paisActiu: boolean;
  provCodificada: boolean;
  sigles: string;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.codi = null;
    this.descripcio = '';
    this.paisActiu = null;
    this.provCodificada = null;
    this.sigles = '';

    this.labels['codi'] = this.i18n('Codi');
    this.labels['descripcio'] = this.i18n('Descripció');
    this.labels['paisActiu'] = this.i18n('Pais Actiu');
    this.labels['provCodificada'] = this.i18n('Provincia Codificada');
    this.labels['sigles'] = this.i18n('Sigles');
  }
}
