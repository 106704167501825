<div class="row justify-content-between">
  <div class="col"><app-filter-selector [filter]="this.filter"></app-filter-selector></div>
  <div class="col">
    <mat-paginator #paginator
      [pageIndex]="0"
      [pageSize]="this.filter.paginator.pageSize"
      [pageSizeOptions]="[20, 40, 60, 100]">
    </mat-paginator>
  </div>
</div>
<div class="table-sdw is-fixed">
  <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.filter.paginator.sortField" [matSortDirection]="this.filter.paginator.sortDir" class="is-header-sticky">

    <ng-container matColumnDef="EXP">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Expedient</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.EXP}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="NUMDENUNCIA">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Numdenuncia</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.NUMDENUNCIA}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="DATA">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Fecha</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.DATA | shortDate }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="IPRPUNTOS">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Punts</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.IPRPUNTOS}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="IPRDESCRI">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Infracció</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.IPRDESCRI}}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" (click)="seleccionarFila(row)" [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
  </mat-table>
</div>
