<div class="table-sdw is-fixed">
<mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.filter.paginator.sortField" [matSortDirection]="this.filter.paginator.sortDir" class="is-header-sticky"  multiTemplateDataRows>
    <ng-container matColumnDef="somId">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Id</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.somId}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="DataInici">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data inici</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.dataInici}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="DataFi">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data fi</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.dataFi}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="Agent">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Agent</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.agent}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="Observacions">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Observacions</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.observacions}}</mat-cell>
      </ng-container>
      <!-- Imatges Column -->
      <ng-container matColumnDef="imatges">
        <mat-header-cell *matHeaderCellDef mat-sort-header><i class="fa fa-images" text="Imatges" i18n-text></i></mat-header-cell>
        <mat-cell *matCellDef="let row">
          <a (click)="this.viewImages(row.somId)" class="is-link">
              <i class="imatges-n fa fa-images"></i> {{row.imatges}}
          </a>
        </mat-cell>
      </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row 
      *matRowDef="let row; columns: displayedColumns;" 
      class="has-iconset" 
      style="border-bottom-width: 0;">
    </mat-row>
</mat-table>
</div>