import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
import { TramosTiposFilter } from '../models/tramos-tipos-filter';


const defaultSortParam = 'descripcion';
const defaultSortDirection = 'asc';

@Injectable()
export class TramosTiposFilterService extends CustomFilterService {
  public createFilterParams(filter: TramosTiposFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {

    return new HttpParams()
    .append('SomId', (filter.SomId ? filter.SomId.toString() : ''))
    .append('descripcion', filter.descripcio)
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
