<div *ngIf="this.pantalla===this.type">
    <div *ngIf="this.value !== null && this.label !== null" > {{label}} </div>
    <section *ngIf="this.value !== null" class="progress-bar-section">
        <div [class]="class">
            <mat-progress-bar mode="determinate" value={{value}}></mat-progress-bar>
        </div>
        <div style="position: absolute; padding: 10px; color: white;"> {{value}}% </div>    
    </section>
    <div class="progress-bar-section" style="height: auto" *ngIf="this.value !== null && this.text !== null"> {{text}} </div>
    
</div>

