<div class="row justify-content-between">
  <div class="col">
      <app-filter-selector [filter]="this.filter"></app-filter-selector>
  </div>
  <div class="col">
      <mat-paginator #paginator
      [pageIndex]="0"
      [pageSize]="this.filter.paginator.pageSize"
      [pageSizeOptions]="[20, 40, 60, 100]">
    </mat-paginator>
  </div>
</div>

<div class="table-sdw is-fixed">

  <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.filter.paginator.sortField" [matSortDirection]="this.filter.paginator.sortDir" class="is-header-sticky">

    <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>ID</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.id}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="nombre">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Nombre</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.nombre}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="apellidos">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Apellidos</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.apellidos}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="matricula">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Matrícula</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.matricula}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="fecha">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Fecha</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.fecha | longDate}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="importe">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Importe</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.importe}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="dni">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>DNI</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.DNI}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="resultado">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Observaciones</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.resultado}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="descZona">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Zona</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.descZona}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="tipoBono">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Bono</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.tipoBono}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="tipo_resultado">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Válido para pago</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <i *ngIf="row.tipo_resultado === 1" [class]="'fas fa-check-circle has-color-green'" ></i>
      <i *ngIf="row.tipo_resultado !== 1" [class]="'far fa-times-circle has-color-red'" ></i>
        <!--<mat-checkbox [checked]="row.valida" [disabled]='true'></mat-checkbox>-->
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="resultadoPago">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Resultado pago</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.resultadoPago}}</mat-cell> 
  </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
  </mat-table>
</div> 