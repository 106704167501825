
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Component, OnInit, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { PermissionDialogComponent } from 'path-shared/components/permission-dialog/permission-dialog.component';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Builder } from 'path-shared/models/builder';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { NotificationService } from 'path-shared/services/notification/notification.service';


@Component({
  selector: 'app-avisosconf',
  templateUrl: './avisos.component.html',
  styleUrls: ['./avisos.component.scss']
})
export class AvisosConfComponent implements OnInit, AfterViewInit {  
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  //@ViewChild(AvisosTableComponent) avisosTable: AvisosTableComponent;

  constructor(
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private i18n: I18n,
    private not: NotificationService,
    public thisDialogRef: MatDialogRef<AvisosConfComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
    ) {
     

    }

  ngOnInit(): void {
     this.auth.UserCanSeePage(this.auth.Pages.Avisos);

  }

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Avisos'));
    }
  }
 
}
