<div class="container" style="display: flex; flex-direction: column; width: 100%;margin-bottom: 30px;">
  <div style="display: flex; flex-direction: row;">
    <app-form-text-edit *ngIf="!this.dataDialog.isDialog" class="col-xl-2" i18n-label label="Matricula" [(value)]="this.filter.matricula" ></app-form-text-edit>
      <mat-paginator #paginator [pageIndex]="0" [pageSize]="this.filter.paginator.pageSize" [pageSizeOptions]="[20, 40, 60, 100]"></mat-paginator>
      <app-form-date-edit *ngIf="!this.dataDialog.isDialog" i18n-label label="Desde" [(value)]="this.filter.datainici" type="calendar"></app-form-date-edit>
      <app-form-date-edit *ngIf="!this.dataDialog.isDialog" i18n-label label="Fins" [(value)]="this.filter.datafi" type="calendar"></app-form-date-edit>
    <button class="btn btn-primary" style="vertical-align: middle; height: 30px;" (click)="load()" i18n>Consultar</button>

  </div>
    <div style="display: flex; flex-direction: row; height: 100%;">
      <div class="table-container" style="flex-grow: 1; width: 50%; overflow: auto;">
        <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.filter.paginator.sortField" [matSortDirection]="this.filter.paginator.sortDir" class="is-header-sticky">
          <ng-container matColumnDef="SOM_ID">
            <mat-header-cell *matHeaderCellDef i18n>Id</mat-header-cell>
            <mat-cell *matCellDef="let row"><a>{{row.SOM_ID}}</a></mat-cell>
          </ng-container>
          <ng-container matColumnDef="matricula">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Matrícula</mat-header-cell>
            <mat-cell *matCellDef="let row"><a (click)="onRowClicked(row)" class="is-link">{{row.MATRICULA}}</a></mat-cell>
          </ng-container>
          <ng-container matColumnDef="Latitud">
            <mat-header-cell *matHeaderCellDef  i18n>Latitud</mat-header-cell>
            <mat-cell *matCellDef="let row"><a>{{row.LATITUD}}</a></mat-cell>
          </ng-container>
          <ng-container matColumnDef="Longitud">
            <mat-header-cell *matHeaderCellDef  i18n>Longitud</mat-header-cell>
            <mat-cell *matCellDef="let row"><a>{{row.LONGITUD}}</a></mat-cell>
          </ng-container>
          <ng-container matColumnDef="Data">
            <mat-header-cell *matHeaderCellDef i18n>Data</mat-header-cell>
            <mat-cell *matCellDef="let row"><a>{{row.DATA}}</a></mat-cell>
          </ng-container>
          <ng-container matColumnDef="Tipus_Actuacio">
            <mat-header-cell *matHeaderCellDef i18n>Tipus d'actuació</mat-header-cell>
            <mat-cell *matCellDef="let row"><a>{{row.TIPUS_ACTUACIO.DESCRIPCIO}}</a></mat-cell>
          </ng-container>
          <ng-container matColumnDef="ID_INSTIT">
            <mat-header-cell *matHeaderCellDef i18n>Institución</mat-header-cell>
            <mat-cell *matCellDef="let row"><a>{{ row.ID_INSTIT }}</a></mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset"></mat-row>
          </mat-table>
    </div>
</div>