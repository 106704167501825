import { Injectable } from '@angular/core';
import { XDENUNCIES } from '../../../models/XDENUNCIES';


@Injectable()
export class CurrentService {
    public currentDetails: XDENUNCIES;

    constructor() {
    }

}
