import { DataSource } from '@angular/cdk/table';
import { DeteccioMatriculesService } from 'app/sections/ciutat/deteccio-matricules/services/deteccio-matricules.service';
import { DeteccioMatriculaFilter } from 'app/sections/ciutat/deteccio-matricules/models/deteccio-matricula-filter';
import { Component, OnInit, AfterViewInit, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { DeteccioMatriculesTableComponent } from 'app/sections/ciutat/deteccio-matricules/components/deteccio-matricules-table/deteccio-matricules-table.component';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { ActivatedRoute } from '@angular/router';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { Reflection } from 'path-shared/services/reflection/reflection';
import { DeteccioMatricula } from 'app/sections/ciutat/deteccio-matricules/models/deteccio-matricula.model';
import { TipusMatriculesService } from 'app/sections/ciutat/tipus-matricules/services/tipus-matricules.service';
import { AparellService } from 'app/sections/configuracio/aparells/services/aparells-service.service';
import { ParametersService } from 'path-shared/services/parameters/parameters.service';
import { AgentsService } from 'app/sections/configuracio/agents/services/agents-service.service';

@Component({
  selector: 'app-deteccio-matricules',
  templateUrl: './deteccio-matricules.component.html',
  styleUrls: ['./deteccio-matricules.component.scss']
})
export class DeteccioMatriculesComponent implements OnInit, AfterViewInit {
  activeFilters: string[] = [];
  filterData: DeteccioMatriculaFilter;
  somIdFoo: number;
  dataDialog: ComponentDialog = Builder.newComponentDialog();
  rows: DeteccioMatricula[];
  tipusMatricules = [];
  zoom: number;
  northeast: any;
  southwest: any;
  zoomIng = false;
  bounds: any;
  markerIcons = [];
  agents = [];
  aparells = [];
  PDAs = [];
  comprovacioDenuncies = [];
  selectedRow: DeteccioMatricula;

  @ViewChild(DeteccioMatriculesTableComponent) deteccioMatriculesTable: DeteccioMatriculesTableComponent;


  constructor(
    private route: ActivatedRoute,
    public auth: AuthorizationService,
    private deteccioMatriculesService: DeteccioMatriculesService,
    private i18n: I18n,
    private not: NotificationService,
    private agentsService: AgentsService,
    private aparellsService: AparellService,
    private parametersService: ParametersService,
    public thisDialogRef: MatDialogRef<DeteccioMatriculesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private tipusMatriculesService: TipusMatriculesService
    ) {
      this.rows = [];
      this.filterData = new DeteccioMatriculaFilter('data', 'desc');
      if (data !== undefined) {
        if (data.filter !== undefined) {
          this.dataDialog = data;
          this.filterData = data.filter;
        }
      }
      this.comprovacioDenuncies = [{'id': ''}, {'id': 'Sense tiquet'}, {'id': 'Tiquet caducat'}, {'id': 'Amb tiquet'}]
      this.tipusMatricules.push({'codi': '', 'descripcio': ''});
      this.tipusMatriculesService.getAll().subscribe(result => {
        if (result !== null) {
          result.forEach(element => {
            this.tipusMatricules.push({'codi': element.codi, 'descripcio': element.descripcio});
          });
        }
      });
    }

  ngOnInit() {
    // Carreguem els agents
    this.agentsService.getAllCombo().subscribe(result => {
      this.agents = result;
      this.agents.unshift({'codi': ''});
    });

    // Carreguem els aparells
    this.aparellsService.getAllCombo().subscribe(result => {
      this.aparells = result;
      this.aparells.unshift({'id': '', 'descripcion' : ''});
    });
    // Carreguem PDAs
    this.parametersService.getPDAs().subscribe(result => {
        let i = 0;
        result.forEach(element => {
          this.PDAs[i] = {id: element};
          i++;
        });
      this.PDAs.unshift({'id': ''});
    });

    this.deteccioMatriculesTable.filter = this.filterData;

    this.deteccioMatriculesTable.data.subscribe((data) => {

      this.rows = data;

      // if (this.rows.length === 1 && this.dataDialog.filter !== undefined) {
      //   this.deteccioMatriculesTable.onRowClicked(this.rows[0]);
      //   this.deteccioMatriculesTable.markerClick(this.rows[0]);
      //   this.dataDialog.filter = undefined;
      // }
      // if (this.rows.length > 0) {
      //   this.markerIcons = [];
      //   this.bounds = this.generateBounds();
      //   this.zoom = 19;
      //   if (this.bounds.northeast !== undefined && this.bounds.southwest !== undefined) {

      //     this.latitud = (this.bounds.northeast.latitude + this.bounds.southwest.latitude) / 2;
      //     this.longitud = (this.bounds.northeast.longitude + this.bounds.southwest.longitude) / 2;
      //     this.northeast = this.bounds.northeast;
      //     this.southwest = this.bounds.southwest;
      //     this.zoomIng = true;
      //     // this.loaderService.show();
      //   } else  {
      //     this.setDefaultLatLon();
      //   }
      // } else  {
      //   this.setDefaultLatLon();
      // }
    });

    // this.agmMap.boundsChange.subscribe(bounds => {
    //   if (this.zoomIng) {
    //     let change = false;
    //     if (bounds.getNorthEast().lat() < this.northeast.latitude || bounds.getNorthEast().lng() < this.northeast.longitude ) {
    //       change = true;
    //     }
    //     if (bounds.getSouthWest().lat() > this.southwest.latitude || bounds.getSouthWest().lng() > this.southwest.longitude ) {
    //       change = true;
    //     }
    //     if (change) {
    //       // setTimeout(() => {
    //         if (this.zoom > 0) {
    //           this.zoom--;
    //         }
    //       // }, 1);
    //     } else {
    //       this.zoomIng = false;
    //       this.loaderService.hide();
    //     }
    //   }
    // });
  }

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Detecció matrícules'));
    }
    this.route.params.subscribe(params => {
      if (!Reflection.empty(params.matricula)) {
        setTimeout(() => {
          this.filterData.matricula = params.matricula;
          this.refresh();
        }, 50);
      }
    });

  }



  hasMatricula() {
    return !Reflection.empty(this.filterData.matricula);
  }

  disabled() {
    // return this.filterData.matricula === '' && this.filterData.dataInici === null && this.filterData.dataFi === null && this.filterData.idTipusMatricula === null;
  }

  refresh() {
    this.rows = [];
    this.deteccioMatriculesTable.filter.set(this.filterData);
    this.deteccioMatriculesTable.load();

  }

  generateExcel() {
    this.deteccioMatriculesTable.generateExcel();
  }

  close() {
    this.thisDialogRef.close();
  }

  // generateBounds(): any {
  //     if (this.rows && this.rows.length > 0) {
  //       const bounds = new window['google'].maps.LatLngBounds();

  //         this.rows.forEach((row: any) => {
  //           if (row.latitud !== 0 && row.longitud !== 0) {
  //             bounds.extend(new window['google'].maps.LatLng(row.latitud, row.longitud));

  //           }
  //         });
  //         if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
  //             const extendPoint = new window['google'].maps.LatLng(bounds.getNorthEast().lat() + 0.01, bounds.getNorthEast().lng() + 0.01);
  //             bounds.extend(extendPoint);
  //         }

  //         return {
  //             northeast: {
  //                 latitude: bounds.getNorthEast().lat(),
  //                 longitude: bounds.getNorthEast().lng()
  //             },
  //             southwest: {
  //                 latitude: bounds.getSouthWest().lat(),
  //                 longitude: bounds.getSouthWest().lng()
  //             }
  //         };
  //     }
  //     return {};
  // }

  // viewPDAs(): boolean {
  //   const pda = this.aparells.find(x => x.descripcion === 'PDA');
  //   if (pda === undefined) {
  //     return false;
  //   }
  //   return this.filterData.aparell === pda.id;
  // }

  selectRow($event: string) {

    const index = this.rows.findIndex(x => x.secure_id === $event);
    this.selectedRow = this.rows[index];
    if (this.deteccioMatriculesService.displayedItems[index].checked === true) {
      this.deteccioMatriculesTable.latitud = this.selectedRow.latitud;
      this.deteccioMatriculesTable.longitud = this.selectedRow.longitud;
    }

  }
}
