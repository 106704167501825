import { TableFieldLabelComponent } from './../../../../../shared/components/table-fields/table-field-label/table-field-label.component';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Component, OnInit, AfterViewInit, EventEmitter, Input, Output, ViewChild, OnChanges } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ExpedientePredefinidasFilter } from '../../model/expediente-predefinidas.filter';
import { ExpedientePredefinidas } from '../../model/expediente.predefinidas.model';
import { ExpedientePredefinidasFilterService } from '../../services/expediente-predefinidas.filter.service';
import { TramitarPredefinidasService } from '../../services/tramitar-predefinidas.service';

@Component({
  selector: 'app-pedefinides-table',
  templateUrl: './pedefinides-table.component.html',
  styleUrls: ['./pedefinides-table.component.scss']
})
export class PedefinidesTableComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  data: ExpedientePredefinidas[];
  dataSource: CustomDataSource<ExpedientePredefinidas, ExpedientePredefinidasFilter>;
  highlightedRows = [];
  filter: ExpedientePredefinidasFilter = new ExpedientePredefinidasFilter('exp', 'asc');
  displayedItems = [];

  @Input() updatedFilterData: ExpedientePredefinidasFilter;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  @Output() itemClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() itemSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() ejecutarOperacionEvent: EventEmitter<Array<string>>;


  displayedColumns = ['select', 'expediente', 'pj', 'titular', 'matricula', 'boletin', 'fechaNotificacion', 'diasNotificacion'];

  constructor(
    private tramitarPredefinidasService: TramitarPredefinidasService,
    private filterService: FilterService,
    private dialog: MatDialog,
    private i18n: I18n
  ) {
    this.ejecutarOperacionEvent = new EventEmitter<string[]>(undefined);
  }

  ngOnInit(): void {
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<ExpedientePredefinidas, ExpedientePredefinidasFilter>(
      this.paginator,
      new ExpedientePredefinidasFilterService(),
      'urlGetExpedientesPredefinidas',
      'urlGetExpedientesPredefinidasCount');
    this.dataSource.subject.subscribe(data => {
      this.displayedItems = this.tramitarPredefinidasService.displayedItems;
      this.data = data;
    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
        this.filterService.saveFilter('expedientes-predefinidas-filter', this.filter);
      }
    });
    this.filterService.loadFilter('expedientes-predefinidas-filter', this.filter);
    this.filter.paginator.init(this.sort, this.paginator);
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.filter.updated()))
      .subscribe();
    this.load();
  }

  ngOnChanges(): void {
    if (this.updatedFilterData !== null) {
      this.update();
    }
  }

  update() {
    if (this.dataSource !== undefined) {
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
    }
  }

  load() {
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }

  ejecutarOperacion() {
    const ids = [];
    this.data.forEach(element => {
      if (element.selected) {
        ids.push(element.id);
      }
    });
    this.ejecutarOperacionEvent.emit(ids);
  }
  public getSomIDs(): any[]{
    const ids = [];
    this.data.forEach(element => {
      if (element.selected) {
        ids.push(element.id);
      }
    });
    return ids;
  }
  selectAll() {
    if (this.allSelected()) {
      this.data.forEach(element => {
        element.selected = false;
      });
    } else {
      this.data.forEach(element => {
        element.selected = true;
      });
    }
  }

  allSelected(): boolean {
    const data = this.dataSource.getData();
    if (data !== undefined) {
      let allSelected = true;
      let i = 0;

      while (i < this.data.length && allSelected) {
        allSelected = this.data[i].selected !== undefined ? this.data[i].selected : false;
        i++;
      }

      return allSelected;
    } else {
      return false;
    }
  }

  selectRow(index: number): void {
    if (this.data[index].selected) {
      this.data[index].selected = false;
    } else {
      this.data[index].selected = true;
    }
  }

  rowSelected(index: number) {
    return this.data[index].selected !== undefined ? this.data[index].selected : false;
  }

}
