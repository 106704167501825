<app-dialog-header *ngIf="this.dataDialog.isDialog" label="Temporades" i18n-label></app-dialog-header>


<app-toolbar>
  <app-tool-button text="Nou" i18n-text (click)="create();" color="has-color-green" icon="fa-plus-square" [disabled]="!this.auth.UserCanCreate(this.auth.Pages.Temporada)"></app-tool-button>
  <app-tool-button text="Actualitzar" i18n-text (click)="refresh();" color="has-color-blue" icon="fa-sync"></app-tool-button>
  <app-tool-button text="Filtre" i18n-text (click)="onFilterClicked();" color="has-color-orange" icon="fa-filter"></app-tool-button>
  <app-tool-button text="Excel" i18n-text (click)="generateExcel();" color="has-color-darkgreen" icon="fa-file-excel"></app-tool-button>
  <app-tool-button text="Clonar" i18n-text (click)="clonarTemporada();" color="has-color-green" icon="fa-clone"
  [disabled]="!this.auth.UserCanCreate(this.auth.Pages.Temporada) || !this.auth.UserCanCreate(this.auth.Pages.Maquinas) 
  || !this.auth.UserCanCreate(this.auth.Pages.Tramos) || !this.auth.UserCanCreate(this.auth.Pages.Zonas)
  || this.secureIdTemporada === ''"></app-tool-button>
  <app-tool-button-permissions [permission]="this.auth.Pages.TipusVehicles"></app-tool-button-permissions>
</app-toolbar>
<app-temporadas-table [selectButton]="this.dataDialog.isDialog && !this.dataDialog.isReadOnly" [updatedFilterData]="filterData" (notify)="onRowSelected($event)" (selectedTemporada)="onSelectedTemporada($event)"></app-temporadas-table>