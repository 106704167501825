import { AuthService } from 'path-shared/services/authentication/auth.service';
import { AppComponent } from './../../../../app.component';
import { Observable } from 'rxjs/Observable';
import { UsuarisService } from 'app/sections/usuaris/services/usuaris.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { Instituciones } from 'path-shared/models/instituciones';
import { LoginModel } from '../../models/login.model';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { StartupService } from 'path-shared/services/startup/startup.service';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Usuari } from 'app/sections/usuaris/models/usuari.model';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { ChangePasswordComponent } from 'app/sections/usuaris/components/change-password/change-password.component';
import { ParametersService } from 'path-shared/services/parameters/parameters.service';
import { MatDialog, MatStepper } from '@angular/material';


@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',

})
export class LoginPageComponent implements OnInit {
  login: LoginModel;
  institucion: Instituciones;
  idInstitucio = '';
  wrongInstitution = false;

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;

  @ViewChild('stepper') stepper: MatStepper;

  enter = true;

  constructor(
    private apiService: LoginService,
    private router: Router,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private usuarisService: UsuarisService,
    private app: AppComponent,
    private auth: AuthService,
    private startupService: StartupService,
    private toastrService: ToastrService,
    private i18n: I18n,
    private confirmationDialogService: ConfirmationDialogService,
    private dialog: MatDialog,
    private parameterService: ParametersService

  ) {
    if (this.auth.isAuthenticated()) {
      this.router.navigate(['/']);
    }
    this.institucion = { IdInstitucion: '', NombreInstitucion: '', Latitud: 0, Longitud: 0 , ValidarFotosProposta: false, EnviarFotosDenuncia: false, version: '            ', Poblacion: null, xaloc: false, tipoCobro: null};
    this.login = new LoginModel();
    this.apiService.wongInstitutionDetected.subscribe(wrong => {
      this.wrongInstitution = true;
    });
    Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter' && this.enter) {
        document.getElementById('login_button').click();
      }
    });
  }

  ngOnInit() {

    if (this.router.url === '/logout') {
      this.auth.logOut(false);
    }
    this.app.pageTitle = '';
    this.toggleLoginItems();
    this.idInstitucio = this.route.snapshot.paramMap.get('id');
    if (this.idInstitucio !== null && this.idInstitucio !== undefined) {
    } else {
      if (this.cookieService.check('InstitucioSeleccionada')) {
        this.login.institution = this.cookieService.get('InstitucioSeleccionada');
        this.idInstitucio = this.login.institution;
      }
    }
    this.wrongInstitution = false;
    if (this.validInstitituion()) {
      this.apiService.getInstitucio(this.idInstitucio).subscribe(result => {
        this.institucion = result;
        this.cookieService.set('InstitucioSeleccionada', this.institucion.IdInstitucion);
      });
    }
  }

  toggleLoginItems() {
    // document.querySelector('#nav-wrapper').classList.toggle('d-none');
    // document.querySelector('#header').classList.toggle('d-none');
    // document
    //   .querySelector('#content-wrapper')
    //   .classList.toggle('is-full-wrapper');
  }

  public doLogin(): void {
    if (this.login.username === '' || this.login.password === '') {
      return;
    }
    if (this.idInstitucio) {
      this.login.institution = this.idInstitucio;
    }

    this.getCurrentUserLite().subscribe((usuari: Usuari) => {
      if (usuari.lastConnection === null) {
        this.firstTimeProcedure(usuari, this.idInstitucio);
      } else {
        this.parameterService.getDiasCaducidadPassword(this.idInstitucio).subscribe(diasCaducidad => {
          if (diasCaducidad !== 'N') {
            const datePasswordChanged = new Date(usuari.datePasswordChanged);
            let auxDate = datePasswordChanged;
            auxDate.setDate(datePasswordChanged.getDate() + diasCaducidad);
            if (usuari.datePasswordChanged === null || auxDate < new Date()) { // contraseña caducada
              this.dialog.open(ChangePasswordComponent, {
                data: {message: this.i18n('Benvingut, $usuari.\nLa vostra contrasenya ha superat els $dies dies de caducitat. Si us plau, creeu-ne una de nova.').
                replace('$usuari', this.login.username).replace('$dies', diasCaducidad.toString()),
                      userSecureId: usuari.SECURE_ID, idInstit: this.idInstitucio},
                closeOnNavigation: false,
                autoFocus: true,
                width: '20%',
              }).afterClosed().subscribe( (res: string) => {
                if (res !== '') {
                  this.login.password = res;
                  this.loginAux();
                }
              });
              return;
            }
          }
          this.StoreUserLastConnection(usuari.lastConnection);
          this.otpProcedure(usuari);
        });

      }

    });
  }


  private firstTimeProcedure(usuari, idInstit) {
    this.confirmationDialogService.confirm(
      this.i18n('Mensaje de Bienvenida y Mensaje de aceptación de las credenciales (texto de aceptación de obligaciones, buen uso y custodia)'),
       this.i18n('<li>Bienvenido al sistema de información de SomDenWeb, recuerde cumplir con las políticas de seguridad.</li><br><li>Recuerde custodiar sus claves, las cuales son personales e intransferibles. No anote sus claves en ningún soporte ni las utilice en otras cuentas.</li>'),
       this.i18n('Aceptar'), this.i18n('Rechazar'), true).then(accepted => {
      if (accepted) {
        this.enter = false;
        this.dialog.open(ChangePasswordComponent, {
          data: {message: this.i18n('Benvingut, $usuari.\nEl primer cop que accediu a SomDenWeb heu de canviar la contrasenya.').replace('$usuari', this.login.username),
                userSecureId: usuari.SECURE_ID, idInstit: idInstit},
          closeOnNavigation: false,
          autoFocus: true,
          width: '20%',
        }).afterClosed().subscribe( (res: string) => {
          if (res !== '') {
            this.login.password = res;
            this.loginAux();
          }
        });
      }
    });
  }

  private otpProcedure(usuari) {
    if (usuari.twoFactorAuthentication) {
      if (this.login.otpPassword !== '') {
        this.usuarisService.VerifyOtp(usuari.SECURE_ID, this.login.otpPassword).subscribe(res => {
          if (res) {
            this.loginAux();
          } else {
            this.toastrService.error(this.i18n('La contraseña de un uso (OTP) introducida es incorrecta'));
          }
        });
      }
    } else {
      this.loginAux();
    }
  }
  private loginAux() {
    this.apiService.login(this.login).subscribe((result: boolean) => {
      if (result) {
          this.toggleLoginItems();
          this.apiService.getInstitucions().subscribe(institucions => {
            const institFound = institucions.find(x => x.IdInstitucion === this.login.institution);
            if (institFound === undefined) {
              sessionStorage.setItem('IdInstitucion', institucions[0].IdInstitucion);
              localStorage.setItem('IdInstitucion', institucions[0].IdInstitucion);
              sessionStorage.setItem('currentInst', JSON.stringify(institucions[0]));
              this.cookieService.set('InstitucioSeleccionada', institucions[0].IdInstitucion);
              this.login.institution = institucions[0].IdInstitucion;
            } else {
              sessionStorage.setItem('IdInstitucion', this.login.institution);
              localStorage.setItem('IdInstitucion', this.login.institution);
              sessionStorage.setItem('currentInst', JSON.stringify(institFound));
              this.cookieService.set('InstitucioSeleccionada', this.login.institution);
            }

            this.deleteFilterCookies();
            this.getCurrentUser();
            this.startupService.Init();
          });
      }
    });
  }

  // devuelve el usuario y avanza al otp si tiene el twoFactorAuthentication activado
  private getCurrentUserLite(): Observable<any> {
    const res = new Subject<Usuari>();
    this.usuarisService.getUsuariLite(this.login.username, this.login.password, this.login.institution).subscribe(usuari => {
      if (usuari.twoFactorAuthentication) {
        this.stepper.next();
        res.next(usuari);
      } else {
        res.next(usuari);
      }
    });
    return res.asObservable();
  }

  private getCurrentUser() {
    this.usuarisService.getUsuari().subscribe(usuari => {
      sessionStorage.setItem('usuari', JSON.stringify(usuari));
      this.apiService.loginComplete();
      // this.router.navigateByUrl('');
      document.location.href = '/';
      // this.router.navigate(['/']);
    });
  }

  private StoreUserLastConnection(lastCon) {
    sessionStorage.setItem('lastCon', JSON.stringify(lastCon));
  }

  /*
    Mètode per esborrar les cookies que es guardan a l'aplicar un filtre
  */
  private deleteFilterCookies() {
    const filterPattern = '-filter';
    const allCookies = this.cookieService.getAll();
    const propertyNames = Object.getOwnPropertyNames(allCookies);

    const filterCookiesToDelete = propertyNames.filter(function (str) { return str.includes(filterPattern); });
    filterCookiesToDelete.forEach(filterCookie => {
      this.cookieService.delete(filterCookie, '/', window.location.hostname);
    });
  }

  validInstitituion() {
    return (this.idInstitucio !== null && this.idInstitucio !== undefined && this.idInstitucio !== '') && !this.wrongInstitution;
  }


}
