import { EnvioPuntosDgtTableComponent } from '../../components/envio-puntos-dgt-table/envio-puntos-dgt-table.component';
import { PuntosDgtFilter } from './../../models/puntos-dt-filter';
import { GenericFile } from './../../../../../shared/components/generic-file-displayer/model/generic-file';
import { EnvioPuntosDGTService } from './../../services/envio-puntos-dgt.service';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FileDownloader } from 'path-shared/services/http/fileDownloader';
import { ToastrService } from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { saveAs } from 'file-saver';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { MatDialog } from '@angular/material';
import { EnvioPuntosDgtFilterComponent } from '../../components/envio-puntos-dgt-filter/envio-puntos-dgt-filter.component';
import { Router } from '@angular/router';
@Component({
  selector: 'app-envio-puntos-dgt',
  templateUrl: './envio-puntos-dgt.component.html',
  styleUrls: ['./envio-puntos-dgt.component.scss']
})
export class EnvioPuntosDgtComponent implements OnInit, AfterViewInit  {

  filterData: PuntosDgtFilter;
  expedientsSeleccionats: string[];
  @ViewChild(EnvioPuntosDgtTableComponent) puntosDGTTable: EnvioPuntosDgtTableComponent;
  constructor(
    private dialog: MatDialog,
    private envioDgtService: EnvioPuntosDGTService, 
    private i18n: I18n,
    private toastr: ToastrService,
    private router: Router,
    private not: NotificationService,
  ) { 
    this.filterData = new PuntosDgtFilter('DATA', 'asc');
  }

  ngOnInit() {
  }
  ngAfterViewInit() {
      this.not.titleChange(this.i18n('Envio puntos DGT'));
  }

  refresh() {
    this.puntosDGTTable.load();
  }

  onFilterClicked() {
    const ref = this.dialog.open(EnvioPuntosDgtFilterComponent, {
      width: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.puntosDGTTable.filter
      }
    });
      
    ref.componentInstance.notify.subscribe((filter: PuntosDgtFilter) => {
      this.filterData = filter;
      this.filterData.updated();
      this.puntosDGTTable.filter.set(this.filterData);
      this.puntosDGTTable.load();
    });
  }

  enviarPuntos(){
    const filter = {
      exp: this.filterData.EXP,
      fechaInicio: this.filterData.fechaInicio,
      fechaFin: this.filterData.fechaFin
    }
    this.envioDgtService.getEnvioPuntos(filter).subscribe((file:GenericFile) => {
      this.downloadFile(file);
      this.refresh();
    //   
    //   const contentType = file.headers.get('Content-Type');
    //  // const contentDisp =file.headers.get('Content-Disposition');
    //  var headers = file.headers;
    //  console.log(headers); 
    //   if (contentType !== null && contentType !== undefined) {
    //       FileDownloader.downLoadFile([file.body],  contentType + ';charset=utf-8', 'test.xml');
    //   } else {
    //     this.toastr.error(this.i18n('No s\'ha pogut generar el fitxer'));
    //   }
    });
  }

  downloadFile(file: GenericFile): void {

      const bytearray: Uint8Array = this.getDisplayable(file.fichero);
      const blob = new Blob([bytearray]);
      saveAs(blob, file.nombre);
    
  }
  getDisplayable(dataURI: string): Uint8Array {
    const raw = window.atob(dataURI);
    const rawLength = raw.length;
    const array = new Uint8Array(new ArrayBuffer(rawLength));

    let i = 0;
    for (i; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }

  irHistorico(): void {
    this.router.navigate(['tramitar/envio-puntos-dgt-historico']);
  }
}
