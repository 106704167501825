<!-- dialog progress bar-->


<ng-template #myDialog class="col-12">
  <!-- <app-input-progress-bar [(value)]="this.porcentajeTramitados"  class="col-12" i18n-label label="Progrés"></app-input-progress-bar> -->
  <div *ngIf="this.porcentajeTramitados !== null"> Progrés </div>
  <section *ngIf="this.porcentajeTramitados !== null" class="progress-bar-section">
    <div class="col-12">
      <mat-progress-bar mode="determinate" value={{this.porcentajeTramitados}}></mat-progress-bar>
    </div>
    <div style="position: absolute; padding: 10px; color: white;"> {{this.porcentajeTramitados | number:'1.2-2'}}%
    </div>
  </section>
</ng-template>

<ng-template #progressBarDialog class="col-12">
  <app-progress-bar pantalla="CargaC60" i18n-label label="Progreso" class="col-12"></app-progress-bar>
</ng-template>


<app-dialog-header label="Detall càrrega C60" isCloseButton="false" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Processar" i18n-text (click)="this.ProcessarV2()" color="has-color-blue" icon="fa-euro-sign"
    [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Banc) || this.dialogData.isReadOnly || this.YaProcesado ">
  </app-tool-button>
  <app-tool-button text="Recargar C60" i18n-text (click)="this.recargarC60()" color="has-color-green"
    icon="fa-share-square"
    [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Banc) || this.dialogData.isReadOnly || !this.YaProcesado">
  </app-tool-button>
  <app-tool-button text="Cargar exp cerrados" i18n-text (click)="this.cargarC60ExpCerrados()" color="has-color-green"
    icon="fa-share-square"
    [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Banc) || this.dialogData.isReadOnly || !this.YaProcesado || this.ningunCerrado">
  </app-tool-button>
  <app-tool-button text="Finalitzar" i18n-text (click)="this.finalitzar()" color="has-color-yellow" icon="fa-lock"
    [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Banc) || this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Tornar Capçalera" i18n-text (click)="this.cancel()" color="has-color-yellow" icon="fa-undo">
  </app-tool-button>
</app-toolbar>

<div class="row">
  <div class="col">
    <div class="row"><span class="ml-3 font-weight-bold" i18n>Num. registres: </span><span
        class="col-2">{{this.bancCarreguesC60DetallResum!==undefined ? this.bancCarreguesC60DetallResum.numRegistros : 'Cargando información'}}</span>
    </div>
    <div class="row"><span class="ml-3 font-weight-bold" i18n>Import total: </span><span
        class="col-2">{{this.bancCarreguesC60DetallResum===undefined ? 'Cargando información' : this.bancCarreguesC60DetallResum.importeTotal | currency:'EUR': 'symbol'}}</span></div>
  </div>
  <div class="col">
    <mat-paginator #paginator [pageIndex]="0" [pageSize]="this.filter.paginator.pageSize"
      [pageSizeOptions]="[20, 40, 60, 100]">
    </mat-paginator>
  </div>
  
</div>



<div class="table-sdw is-fixed" style="MAX-HEIGHT: 73%;">

  <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements"
    [matSortActive]="this.filter.paginator.sortField" [matSortDirection]="this.filter.paginator.sortDir"
    class="is-header-sticky">

    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Codi</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.id}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="codiCarrega">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Codi Càrrega</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.codiCarrega}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="expediente">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Expedient</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.expediente}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="situacion">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Cobrado</mat-header-cell>
      <mat-cell *matCellDef="let row"><i *ngIf="row.situacion==='P'" [class]="'fas fa-check-circle has-color-green'" ></i></mat-cell>
    </ng-container>
    
    <ng-container matColumnDef="recibo">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Rebut</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.recibo}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="fechaCobro">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data cobrament</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.fechaCobro}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="referenciaC60">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Ref. C60</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.referenciaC60}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="entidad">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Entitat</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.entidad}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="oficina">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Oficina</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.oficina}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="importe">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Import</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.importe | currency:'EUR': 'symbol'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="missatgeError">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Missatge</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.missatgeError}}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset"
      [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
  </mat-table>
</div>