import { I18n } from '@ngx-translate/i18n-polyfill';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { MarquesVehicles } from '../../models/marques-vehicles.model';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { MarquesVehiclesService } from '../../services/marques-vehicles-service.service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { Builder } from 'path-shared/models/builder';
import { MarquesVehiclesFilter } from '../../models/marques-vehicles-filter';
import { MarquesVehiclesFilterService } from '../../services/marques-vehicles-filter-service.service';


@Component({
  selector: 'app-marques-vehicles-table',
  templateUrl: './marques-vehicles-table.component.html',
  styleUrls: ['./marques-vehicles-table.component.scss']
})
export class MarquesVehiclesTableComponent implements OnInit, AfterViewInit, OnChanges  {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  data: MarquesVehicles[];
  dataSource: CustomDataSource<MarquesVehicles, MarquesVehiclesFilter>;
  filter: MarquesVehiclesFilter = new MarquesVehiclesFilter('id', 'asc');
  displayedItems = [];
  highlightedRows = [];

  @Input() selectButton: boolean;
  @Input() updatedFilterData: MarquesVehiclesFilter;
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();
  @Output() itemClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() itemSelected: EventEmitter<string> = new EventEmitter<string>();

  displayedColumns = ['id', 'descripcio', 'referenciaIntegracion'];

  constructor(
    private marquesVehiclesService: MarquesVehiclesService,
    private filterService: FilterService,
    private dialog: MatDialog,
    private i18n: I18n) {
  }

  ngOnInit() {

    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<MarquesVehicles, MarquesVehiclesFilter >(this.paginator, new MarquesVehiclesFilterService(), 'urlGetMarques', 'urlGetCountMarques');
    this.dataSource.subject.subscribe((data) => {
      data.forEach(item => {
        this.displayedItems[item.id] = {
          id: item.id
        };
      });
    });

    this.filter.filterUpdated.subscribe(updated => {

      if (updated) {
        this.paginator.pageIndex = 0;
        this.filter.paginator.set(this.sort, this.paginator);
        this.load();
        if (!this.selectButton) { this.filterService.saveFilter('marques-vehicles-filter', this.filter); }
      }
    });



    if (!this.selectButton) { this.filterService.loadFilter('marques-vehicles-filter', this.filter); }
    this.filter.paginator.init(this.sort, this.paginator);
  }

  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });

    this.paginator.page.subscribe(data => {
      this.paginator.pageIndex = data.pageIndex;
      this.paginator.pageSize = data.pageSize;
      this.load();
    });

    /*merge(this.sort.sortChange, this.paginator.page)
    .pipe(tap(() => this.filter.updated()))
    .subscribe();
*/
    this.load();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      if ( this.filter.activeFilters.size !== 0) {
        this.paginator.pageIndex = 1;
        this.updatedFilterData.paginator.set(this.sort, this.paginator);
      }
      this.update();
      if ( this.filter.activeFilters.size !== 0) {
        this.paginator.pageIndex = 1;
        this.updatedFilterData.paginator.set(this.sort, this.paginator);
      }
    }
  }

  update() {
    if (this.dataSource !== undefined) {
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
    }
  }

  load() {

    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }


  onRowClicked(row) {

    this.itemClicked.next(row.secureId);
  }

  generateExcel(): void {
    let filter = this.filter;
    if (this.updatedFilterData != null) {
      filter = this.updatedFilterData;
    }
    this.dialog.open(DownloadExcelDialogComponent, {
        closeOnNavigation: false,
        autoFocus: true,
        data: Builder.newDownloadExcelDialogData (this.marquesVehiclesService, filter, this.i18n('Marques vehicles'), this.sort.direction, this.sort.active)
    });
  }

}
