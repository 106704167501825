
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationFilter } from 'path-shared/models/pagination.filter';
import { IFilterService } from 'path-shared/services/datasource/filter-service';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';

import { Reflection } from 'path-shared/services/reflection/reflection';
import { TiketFilter } from '../models/tiket-filters';

const defaultSortParam = 'tipus';
const defaultSortDirection = 'desc';

@Injectable()
export class TiketFilterService extends CustomFilterService {
  public createFilterParams(filter: TiketFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {
    return new HttpParams()
    .append('COD_TIPO', Reflection.hasValue(filter.COD_TIPO) ? filter.COD_TIPO : '')
    .append('DESC_TIPO', filter.DESC_TIPO ? filter.DESC_TIPO : '')
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }

  public createLoadParams(content: string) {
    return new HttpParams()
    .append('content', content ? content : '');
  }


}
