import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { AcuseFilter } from '../models/acus-de-rebut.filter';

const defaultSortParam = 'expedient';
const defaultSortDirection = 'asc';

@Injectable()
export class AcusosFilterService  extends CustomFilterService {
  public createFilterParams(filter: AcuseFilter, start: number, pageSize: number, sortField: string, sortDir: string ): HttpParams {
    return new HttpParams()
    .append('expedient', filter.expedient ? filter.expedient.toString() : '')
    .append('tipus', filter.tipus)
    .append('resultat', filter.resultat ? filter.resultat.toString() : '')
    .append('dataInici', filter.dataInici ? filter.dataInici.toLocaleString('dd/MM/yyyy') : '')
    .append('dataFinal', filter.dataFinal ? filter.dataFinal.toLocaleString('dd/MM/yyyy') : '')
    .append('nomComplet', filter.nomComplet)
    .append('numDenuncia', filter.numDenuncia ? filter.numDenuncia.toString() : '')

    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir)
    ;
  }
}
