import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { AlegacioFilter } from 'app/sections/tramitar/models/alegaciones.filter';

const defaultSortParam = 'EXP';
const defaultSortDirection = 'asc';

@Injectable()
export class AlegacionesFilterService  extends CustomFilterService {
  public createFilterParams(filter: AlegacioFilter, start: number, pageSize: number, sortField: string, sortDir: string ): HttpParams {
    return new HttpParams()
    .append('EXP', filter.EXP ? filter.EXP : '')
    .append('NUMDENUNCIA', filter.NUMDENUNCIA ? filter.NUMDENUNCIA : '')
    .append('INFRACCIO', filter.INFRACCIO ? filter.INFRACCIO: '')
    .append('DATAPLEC', filter.DATAPLEC == undefined ? '' : typeof(filter.DATAPLEC) === 'string' ?
    filter.DATAPLEC : filter.DATAPLEC.toDateString())
    .append('AGENTE', filter.AGENTE ? filter.AGENTE: '')
    .append('TIPO', filter.TIPO ? filter.TIPO : '')
    .append('codUser', filter.codUser ? filter.codUser.toString() : '-1')
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir)
  }
}