<app-dialog-header label="Editar grup" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Guardar" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save"></app-tool-button>
  <app-tool-button text="Eliminar" i18n-text (click)="this.remove();" color="has-color-red" icon="fa-trash-alt"></app-tool-button>
</app-toolbar>
<app-dialog-body>
  <form #mainForm>
    
    <div class="row" style="margin: auto">
      <app-form-text-edit id="seguretat-grups-editor-codi" class="col-sm-2" i18n-label label="Codi Grup" [(value)]="this.grup.codiGrup" [maxLength]="4" [type]="'number'" required ></app-form-text-edit>
      <app-form-text-edit id="seguretat-grups-editor-descripcio" class="col-sm-4" i18n-label label="Descripcio" [(value)]="this.grup.descripcio" [maxLength]="50" required ></app-form-text-edit>
      <app-form-date-edit id="seguretat-grups-editor-inici" class="col-sm-2" i18n-label label="Inici" [(value)]="this.grup.inici" (valueChange)="comprovarData()" required></app-form-date-edit>
      <app-form-date-edit id="seguretat-grups-editor-fi" class="col-sm-2" i18n-label label="Fi" [(value)]="this.grup.fi" (valueChange)="comprovarData()" required></app-form-date-edit>
      <app-form-check-edit class="col-sm-1" i18n-label label="Actiu" [(value)]="this.grup.actiu" (click)="setActiu()" required></app-form-check-edit>        
    </div>
    
    <mat-tab-group>
      <mat-tab label="Permisos"> 
        <div class="row" style="margin: auto">
          <div class="table-sdw is-fixed" style="max-height: 55vh">
            <mat-table [dataSource]="dataSourcePantallas" matSort #sortPantallas="matSort" class="is-header-sticky">
                <ng-container matColumnDef="pantalla">
                  <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Pantalla</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <span *ngIf="row.pantalla!==null">{{row.pantalla}}</span>
                    <app-form-combo-edit *ngIf="row.pantalla===null" (valueChange)="novaFila($event, row)" [items]="segPantallas" [(value)]="row.idSegPantallas" valueField="id" textField="descripcio" ></app-form-combo-edit>
                  </mat-cell>
                </ng-container>
          
                <ng-container matColumnDef="lectura">
                  <mat-header-cell *matHeaderCellDef i18n>Lectura</mat-header-cell>
                  <mat-cell *matCellDef="let row;"><app-form-check-edit *ngIf="row.idSegPantallas!==null" [(value)]="row.lectura"></app-form-check-edit></mat-cell>
                </ng-container>
          
                <ng-container matColumnDef="alta">
                  <mat-header-cell *matHeaderCellDef i18n>Alta</mat-header-cell>
                  <mat-cell *matCellDef="let row;"><app-form-check-edit *ngIf="row.idSegPantallas!==null" [(value)]="row.alta"></app-form-check-edit></mat-cell>
                </ng-container>
          
                <ng-container matColumnDef="modif">
                  <mat-header-cell *matHeaderCellDef i18n>Modif</mat-header-cell>
                  <mat-cell *matCellDef="let row;"><app-form-check-edit *ngIf="row.idSegPantallas!==null" [(value)]="row.modif"></app-form-check-edit></mat-cell>
                </ng-container>
          
                <ng-container matColumnDef="borrar">
                  <mat-header-cell *matHeaderCellDef i18n>Borrar</mat-header-cell>
                  <mat-cell *matCellDef="let row;"><app-form-check-edit *ngIf="row.idSegPantallas!==null" [(value)]="row.borrar"></app-form-check-edit></mat-cell>
                </ng-container>
    
                <ng-container matColumnDef="eliminar">
                  <mat-header-cell *matHeaderCellDef i18n>Eliminar</mat-header-cell>
                  <mat-cell *matCellDef="let row;">
                    <!-- <i (click)="updateLocalizacion(row)" class="cursor fa fa-save btn btn-outline-danger btn-sm"></i> -->
                    <i *ngIf="row.idSegPantallas!==null" (click)="removePantalla(row)" class="cursor fa fa-trash-alt btn btn-outline-danger btn-sm"></i>
                  </mat-cell>
                </ng-container>
          
                <mat-header-row *matHeaderRowDef="displayedColumnsPantallas"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnsPantallas;"></mat-row>
              </mat-table>
          </div>
        </div>
      </mat-tab>
      <mat-tab i18n-label label="Usuarios">
        <div class="row" style="margin: auto">
          <div class="table-sdw is-fixed" style="max-height: 55vh">
            <mat-table [dataSource]="dataSourceUsuaris" matSort #sortUsuaris="matSort" class="is-header-sticky">
              <!-- Nom Usuari -->
              <ng-container matColumnDef="nomUsuari">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Usuari</mat-header-cell>
                <mat-cell *matCellDef="let row">
                  <span *ngIf="row.nomUsuari!==''">{{row.nomUsuari}}</span>
                  <app-form-combo-edit *ngIf="row.nomUsuari===''" (valueChange)="novaFilaUsuaris($event)" [items]="segUsuarios" [(value)]="row.codiUser" valueField="id" textField="descripcio" ></app-form-combo-edit>
                </mat-cell>
              </ng-container>

              <!-- Descripcio -->
              <!-- <ng-container matColumnDef="descripcio">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Descripció</mat-header-cell>
                <mat-cell *matCellDef="let row"><span *ngIf="row.codiUser!==null"> {{row.descUser}}</span></mat-cell>
              </ng-container> -->

              <!-- Email -->
              <!-- <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Email</mat-header-cell>
                <mat-cell *matCellDef="let row"><span *ngIf="row.codiUser!==null">{{row.email}}</span> </mat-cell>
              </ng-container> -->

              <ng-container matColumnDef="eliminar">
                <mat-header-cell *matHeaderCellDef i18n>Eliminar</mat-header-cell>
                <mat-cell *matCellDef="let row;">
                  <i *ngIf="row.codiUser!==null" (click)="removeUser(row)" class="cursor fa fa-trash-alt btn btn-outline-danger btn-sm"></i>
                </mat-cell>
              </ng-container>
          
              <mat-header-row *matHeaderRowDef="displayedColumnsUsuaris"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumnsUsuaris;"></mat-row>
            </mat-table>
          </div>
        </div>
      </mat-tab>

    </mat-tab-group>

    
  </form>
</app-dialog-body>
