<app-dialog-header label="Procediment administratiu" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Guardar" i18n-text (click)="save();" color="has-color-green" icon="fa-save"></app-tool-button>
  <app-tool-button text="Cancel·lar" i18n-text (click)="cancel();" color="has-color-red" icon="fa-times" ></app-tool-button>  
  <app-tool-button text="Imatges" i18n-text color="has-color-darkblue" icon="fa-images" (click)="onImagesClick()" [badge]="this.imatgesCount" [badgeActive]="this.imatgesCount > 0" ></app-tool-button>
  <app-tool-button text="Eliminar" i18n-text (click)="this.remove();" color="has-color-red" icon="fa-trash-alt" ></app-tool-button>

</app-toolbar>
<app-dialog-body> 
  
  <div style="margin:5px">
    <h5>Expedient</h5>

    <div style="margin:20px" class="row">

      <div class="col-md-6">
        <div class="form-group row">
          <div class="col-sm-6" i18n>Expedient</div>

          <div class="col-sm-5">
            <mat-form-field>
              <input disabled matInput i18n-placeholder [value]="this.expedient.expedient">
            </mat-form-field>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-6" i18n>Data prescripció</div>

          <div class="col-sm-5">
            <mat-form-field>
              <input matInput i18n-placeholder [matDatepicker]="prescripcioDatepicker" [value]="this.expedient.dataPre">
              <mat-datepicker-toggle [for]="prescripcioDatepicker"></mat-datepicker-toggle>
              <mat-datepicker #prescripcioDatepicker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-6" i18n>Data caducitat</div>

          <div class="col-sm-5">
            <mat-form-field>
              <input matInput i18n-placeholder [matDatepicker]="caducitatDatepicker" [value]="this.expedient.dataCad">
              <mat-datepicker-toggle [for]="caducitatDatepicker"></mat-datepicker-toggle>
              <mat-datepicker #caducitatDatepicker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>

      </div>

      <div class="col-md-6">

        <div class="form-group row">
          <div class="col-sm-3" i18n>Nom conductor</div>

          <div class="col-sm-5">
            <mat-form-field>
              <input matInput disabled i18n-placeholder placeholder="" [value]="this.expedient.nomCond">
            </mat-form-field>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-3" i18n>Nom propietari</div>

          <div class="col-sm-5">
            <mat-form-field>
              <input matInput disabled i18n-placeholder placeholder="" [value]="this.expedient.nomProp">
            </mat-form-field>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-3" i18n>Ref. Expedient</div>

          <div class="col-sm-5">
            <mat-form-field>
              <input matInput i18n-placeholder placeholder="" [value]="this.expedient.refExp">
            </mat-form-field>
          </div>
        </div>

      </div>

    </div>

    <h5>Motivació</h5>

    <div style="margin: 20px" >
      
      <mat-tab-group style="max-width: 90vh;">
        <mat-tab i18n-label label="Motivació al·legacions denúncia">
          <div style="margin-top: 0;" class="border border-secondary fondo">
            <div class="row" style="margin: 0.5vh;">
              <div class="col-9">
                <textarea cols="20" matInput [value]="this.expedient.situacio" (input)="this.expedient.situacio=$event.target.value"></textarea>
              </div>
              <div class="col-3">
                <app-form-text-edit id="expedients-registreEntrada" i18n-label label="Registre entrada" [(value)]="this.expedient.registreEntradaAlegacions"></app-form-text-edit>
              </div>
            </div>

          </div>
        </mat-tab>
        <mat-tab i18n-label label="Motivació proposta resolució">
          <div style="margin-top: 0;" class="border border-secondary fondo">
            <div class="row" style="margin: 0.5vh;">
              <div class="col-9">
                <textarea cols="20" matInput [value]="this.expedient.situacioProposta" (input)="this.expedient.situacioProposta=$event.target.value"></textarea>
              </div>
              <div class="col-3">
                <app-form-text-edit  id="expedients-registreEntradaProposta" i18n-label label="Registre entrada" [(value)]="this.expedient.registreEntradaProposta"></app-form-text-edit>
              </div>
            </div>            
          </div>
        </mat-tab>
        <mat-tab i18n-label label="Motivació reposició">
          <div style="margin-top: 0;" class="border border-secondary fondo">
            <div class="row" style="margin: 0.5vh;">
              <div class="col-9">
                <textarea cols="20" matInput [value]="this.expedient.situacioReposicio" (input)="this.expedient.situacioReposicio=$event.target.value"></textarea>
              </div>
              <div class="col-2">
                <app-form-text-edit id="expedients-registreEntradaReposicio" i18n-label label="Registre entrada" [(value)]="this.expedient.registreEntradaReposicio"></app-form-text-edit>
              </div>
            </div>

          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
   

    <h5>Informació</h5>

    <div style="margin: 20px" class="row">
     
        <div class="col-sm-6">
          <div align="right"class="border">
            <div class="form-group row">
              <div class="col-sm-6" i18n>Data notificació conductor</div>
              <app-form-date-edit id="expedients-dataNotificacioConductor" class="col-sm-5" [(value)]="this.expedient.dataNotificacioConductor" type="calendar"></app-form-date-edit>
            </div>
    
            <div class="form-group row">
              <div class="col-sm-6" i18n>Acús conductor</div>
              <app-form-date-edit id="expedients-acusConductor" class="col-sm-5" [(value)]="this.expedient.acusConductor" type="calendar"></app-form-date-edit>
            </div>
    
            <div class="form-group row">
              <div class="col-sm-6" i18n>Rebuig conductor</div>
              <app-form-date-edit id="expedients-rebuigConductor" class="col-sm-5" [(value)]="this.expedient.rebuigConductor" type="calendar"></app-form-date-edit>
            </div>
        </div>
      
        <div align="right"class="border">
          <div class="form-group row">
            <div class="col-sm-6" i18n>Data notificació propietari</div>
            <app-form-date-edit id="expedients-dataNotiProp" class="col-sm-5" [(value)]="this.expedient.dataNotificacioPropietari" type="calendar"></app-form-date-edit>
          </div>

          <div class="form-group row">
            <div class="col-sm-6" i18n>Acús propietari</div>
            <app-form-date-edit id="expedients-acusProp" class="col-sm-5" [(value)]="this.expedient.acusPropietari" type="calendar"></app-form-date-edit>
          </div>

          <div class="form-group row">
            <div class="col-sm-6" i18n>Rebuig propietari</div>
            <app-form-date-edit id="expedients-rebuigProp" class="col-sm-5" [(value)]="this.expedient.rebuigPropietari" type="calendar"></app-form-date-edit>
          </div>
        </div>

        <div align="right"class="border">

        <div class="form-group row">
          <div class="col-sm-6" i18n>Edicte Notificació</div>
          <app-form-date-edit id="expedients-edicteNotificacio" class="col-sm-5" [(value)]="this.expedient.edicteNotificacio" type="calendar"></app-form-date-edit>
        </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-6" i18n>Data al·legacions denúncia</div>
          <app-form-date-edit id="expedients-dataAlegacionsDenuncia" class="col-sm-5" [(value)]="this.expedient.dataAlegacionsDenuncia" type="calendar"></app-form-date-edit>
        </div>

        <div class="form-group row">
          <div class="col-sm-6" i18n>Data informe policia</div>
          <app-form-date-edit id="expedients-dataInformePolicia" class="col-sm-5" [(value)]="this.expedient.dataInformePolicia" type="calendar"></app-form-date-edit>
        </div>

        <div class="form-group row">
          <div class="col-sm-6" i18n>Informe instructor</div>
          <app-form-date-edit id="expedients-informeInstructor" class="col-sm-5" [(value)]="this.expedient.informeInstructor" type="calendar"></app-form-date-edit>
        </div>

        <div class="form-group row">
          <div class="col-sm-6" i18n>Al·legació estimada</div>
            <app-form-check-edit id="expedients-alegacioEstimada" class="col-sm-5" [(value)]="this.expedient.alegacioEstimada"></app-form-check-edit>
        </div>

        <div class="form-group row">
          <div class="col-sm-6" i18n>Notificar proposta</div>
            <app-form-check-edit id="expedients-notificarProposta" class="col-sm-5"[(value)]="this.expedient.notificarProposta"></app-form-check-edit>
        </div>

        <div class="form-group row">
          <div class="col-sm-6" i18n>Data proposta</div>
          <app-form-date-edit id="expedients-dataProposta" class="col-sm-5" [(value)]="this.expedient.dataProposta" type="calendar"></app-form-date-edit>
        </div>

        <div align="right" class="border">
          <div class="form-group row">
            <div class="col-sm-6" i18n>Notificació proposta</div>
            <app-form-date-edit id="expedients-notificacioProposta" class="col-sm-5" [(value)]="this.expedient.notificacioProposta" type="calendar"></app-form-date-edit>
          </div>

          <div class="form-group row">
            <div class="col-sm-6" i18n>Acús proposta</div>
            <app-form-date-edit id="expedients-acusProposta" class="col-sm-5" [(value)]="this.expedient.acusProposta" type="calendar"></app-form-date-edit>
          </div>

          <div class="form-group row">
            <div class="col-sm-6" i18n>Rebuig proposta</div>
            <app-form-date-edit id="expedients-rebuigProposta" class="col-sm-5" [(value)]="this.expedient.rebuigProposta" type="calendar"></app-form-date-edit>
          </div>
        </div>  
        <div class="form-group row">
          <div class="col-sm-6" i18n>Edicte proposta</div>
          <app-form-date-edit id="expedients-edicteProposta" class="col-sm-5" [(value)]="this.expedient.edicteProposta" type="calendar"></app-form-date-edit>
        </div>

      </div>

      <div class="col-sm-6">

        <div class="form-group row">
          <div class="col-sm-6" i18n>Data al·legacions proposta</div>
          <app-form-date-edit id="expedients-dataAlegacionsProposta" class="col-sm-5" [(value)]="this.expedient.dataAlegacionsProposta" type="calendar"></app-form-date-edit>

        </div>
        <div class="form-group row">
          <div class="col-sm-6" i18n>Informe instructor proposta</div>
          <app-form-date-edit id="expedients-informeInstructorProposta" class="col-sm-5" [(value)]="this.expedient.informeInstructorProposta" type="calendar"></app-form-date-edit>

        </div>

        <div class="form-group row">
          <div class="col-sm-6" i18n>Al·legacions estimades</div>
            <app-form-check-edit class="col-sm-5" [(value)]="this.expedient.alegacionsEstimades"></app-form-check-edit>
        </div>

        <div class="form-group row">
          <div class="col-sm-6" i18n>Data Resolució sancionadora</div>
          <app-form-date-edit id="expedients-dataResolucioSancionadora" class="col-sm-5" [(value)]="this.expedient.dataResolucioSancionadora" type="calendar"></app-form-date-edit>
        </div>

        <div align="right" class="border">
          <div class="form-group row">
            <div class="col-sm-6" i18n>Data notificació resolució</div>
            <app-form-date-edit id="expedients-dataNotificacioResolucio" class="col-sm-5" [(value)]="this.expedient.dataNotificacioResolucio" type="calendar"></app-form-date-edit>
          </div>

          <div class="form-group row">
            <div class="col-sm-6" i18n>Acús resolució</div>
            <app-form-date-edit id="expedients-acusResolucio" class="col-sm-5" [(value)]="this.expedient.acusResolucio" type="calendar"></app-form-date-edit>
          </div>

          <div class="form-group row">
            <div class="col-sm-6" i18n>Rebuig resolució</div>
            <app-form-date-edit id="expedients-rebuigResolucio" class="col-sm-5" [(value)]="this.expedient.rebuigResolucio" type="calendar"></app-form-date-edit>
          </div>

          <div class="form-group row">
            <div class="col-sm-6" i18n>Edicte resolució</div>
            <app-form-date-edit id="expedients-edicteResolucio" class="col-sm-5" [(value)]="this.expedient.edicteResolucio" type="calendar"></app-form-date-edit>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-6" i18n>Data recurs resolució</div>
          <app-form-date-edit id="expedients-dataRecursResolucio" class="col-sm-5" [(value)]="this.expedient.dataRecursResolucio" type="calendar"></app-form-date-edit>
        </div>

        <div class="form-group row" i18n>
          <div class="col-sm-6">Informe instructor resolució</div>
          <app-form-date-edit id="expedients-informeInstructorResolucio" class="col-sm-5" [(value)]="this.expedient.informeInstructorResolucio" type="calendar"></app-form-date-edit>
        </div>

        <div class="form-group row">
          <div class="col-sm-6" i18n>Resolució reposició</div>
          <app-form-date-edit id="expedients-resolucioReposicio" class="col-sm-5" [(value)]="this.expedient.resolucioReposicio" type="calendar"></app-form-date-edit>

        </div>

        <div class="form-group row">
          <div class="col-sm-6" i18n>Recurs estimat</div>
            <app-form-check-edit class="col-sm-5" [(value)]="this.expedient.recursEstimat"></app-form-check-edit>
        </div>
        <div class="form-group row">
          <div class="col-sm-6" i18n>Notificar reposición</div>
            <app-form-check-edit id="expedients-notificarReposicion" class="col-sm-5"[(value)]="this.expedient.notificarReposicion"></app-form-check-edit>
        </div>
        <div align="right" class="border">
          <div class="form-group row">
            <div class="col-sm-6" i18n>Notificació resolució reposició</div>
            <app-form-date-edit id="expedients-notificacioResolucioReposicio" class="col-sm-5" [(value)]="this.expedient.notificacioResolucioReposicio" type="calendar"></app-form-date-edit>
          </div>

          <div class="form-group row">
            <div class="col-sm-6" i18n>Acús resolució reposició</div>
            <app-form-date-edit id="expedients-acusResolucioReposicio" class="col-sm-5" [(value)]="this.expedient.acusResolucioReposicio" type="calendar"></app-form-date-edit>
          </div>

          <div class="form-group row">
            <div class="col-sm-6" i18n>Rebuig resolució reposició</div>
            <app-form-date-edit id="expedients-rebuigResolucioReposicio" class="col-sm-5" [(value)]="this.expedient.rebuigResolucioReposicio" type="calendar"></app-form-date-edit>
          </div>
          <div class="form-group row">
            <div class="col-sm-6" i18n>Fecha edicto reposición</div>
            <app-form-date-edit id="expedients-fechaEdictoReposicion" class="col-sm-5" [(value)]="this.expedient.fechaEdictoReposicion" type="calendar"></app-form-date-edit>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-6" i18n>Tancat d'ofici</div>
          <app-form-date-edit id="expedients-tancatOfici" class="col-sm-5" [(value)]="this.expedient.tancatOfici" type="calendar"></app-form-date-edit>
        </div>

        <div class="form-group row">
          <div class="col-sm-6" i18n>Data traspàs executiva</div>
          <app-form-date-edit id="expedients-dataTraspasExecutiva" class="col-sm-5" [(value)]="this.expedient.dataTraspasExecutiva" type="calendar"></app-form-date-edit>
        </div>

        <div class="form-group row">
          <div class="col-sm-6" i18n>Data comunicació transit</div>
          <app-form-date-edit id="expedients-dataComunicacioTransit" class="col-sm-5" [(value)]="this.expedient.dataComunicacioTransit" type="calendar"></app-form-date-edit>
        </div>

      </div>
    </div>
  </div>
</app-dialog-body>
