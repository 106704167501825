import { HttpParams } from '@angular/common/http';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { ExpedientePredefinidasFilter } from './../model/expediente-predefinidas.filter';

const defaultSortParam = 'id';
const defaultSortDirection = 'asc';

export class ExpedientePredefinidasFilterService extends CustomFilterService {

    public createFilterParams(filter: ExpedientePredefinidasFilter, start: number, pageSize: number, sortField: string, sortDir: string) {
        return new HttpParams()
        .append('exp', filter.exp ? filter.exp : '')
        .append('start', start.toString())
        .append('size', pageSize.toString())
        .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
        .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    }
}
