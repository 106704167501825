<app-dialog-header [label]="this.title" i18n-label icon="fa fa-question" iconClass="has-color-blue"></app-dialog-header>
<app-dialog-body>
  <div *ngIf="!html" class="row">
      <div class="col">
        {{ message }}
      </div>
  </div>

  <div *ngIf="html" class="row">
    <div class="col" [innerHTML]="message"></div>
</div>
</app-dialog-body>
<app-dialog-footer>
  <button type="button" class="btn btn-primary" (click)="accept()">{{ btnOkText }}</button>
  <button type="button" class="btn btn-danger" (click)="decline()">{{ btnCancelText }}</button>
</app-dialog-footer>
