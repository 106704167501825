import { Component, OnInit, AfterViewInit, ViewChild, Input, Output, EventEmitter, SimpleChanges, Inject } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { ExcelDownloadService } from 'path-shared/services/excel/excel-download.service';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { Builder } from 'path-shared/models/builder';
import { VehiclesService } from '../../services/vehicles.service';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { VehicleResumen } from '../../models/vehicleDto';
import { VehicleFilter } from '../../models/vehicle-filter';
import { VehiclesFilterService } from '../../services/vehicles-filter.service';
import { MatDialogRef } from '@angular/material';


@Component({
  selector: 'app-vehicles-table',
  templateUrl: './vehicles-table.component.html',
  styleUrls: ['./vehicles-table.component.scss']
})
export class VehiclesTableComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  data: VehicleResumen[];
  dataSource: CustomDataSource<VehicleResumen, VehicleFilter>;
  highlightedRows = [];
  filter: VehicleFilter = new VehicleFilter('id_vehicles', 'asc');
  filterPage: VehicleFilter;
  displayedItems = [];
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  @Input() updatedFilterData: VehicleFilter;
  @Input() selectButton: boolean;
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();
  @Output() itemClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() itemSelected: EventEmitter<any> = new EventEmitter<any>();

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['id', 'matricula', 'marca', 'model', 'color', 'asseguranca', 'poliza', 'proposta', 'requisitoria', 'observacions', 'origen', 'tipus_vehicle'];

  constructor(
    private vehiclesService: VehiclesService,
    private filterService: FilterService,
    private excel: ExcelDownloadService,
    private dialog: MatDialog,
    private i18n: I18n,
    public dialogRef: MatDialogRef<VehiclesTableComponent>,

    @Inject(MAT_DIALOG_DATA) public dataComp: ComponentDialog
    ) {
      if (dataComp !== undefined && dataComp.filter !==  undefined) {
        this.dataDialog = dataComp;
        this.selectButton = true;

      } else {
        this.selectButton = false;
      }
    }

  ngOnInit() {

    if (this.selectButton) {
      this.displayedColumns = ['incorpora'].concat(this.displayedColumns);
    }
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<VehicleResumen, VehicleFilter>(this.paginator, new VehiclesFilterService(), 'urlGetVehicles', 'urlVehiclesPaginationCount');
    this.dataSource.subject.subscribe((data) => {
        data.forEach(item => {
          this.vehiclesService.displayedItems[item.SECURE_ID] = {
            id: item.SECURE_ID,
            checked: false
          };
        });

      this.displayedItems = this.vehiclesService.displayedItems;
    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
        if (!this.selectButton) { this.filterService.saveFilter('vehicle-filter', this.filter); }
      }
    });
    if (this.dataDialog.filter !== null && this.dataDialog.filter !== undefined) {
      // this.propostesTable.userReincidentsDataSource = false;
      this.filter.set(this.dataDialog.filter);
    }
    if (!this.selectButton) { this.filterService.loadFilter('vehicle-filter', this.filter); }
    this.filter.paginator.init(this.sort, this.paginator);
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });


    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.filter.updated()))
      .subscribe();

    this.loadVehiclesPage();
  }

  OnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      this.update();
    }
  }

  update() {
    if (this.dataSource !== undefined) {
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
    }
  }

  updatePersonesPage() {
    this.dataSource.load(
      this.updatedFilterData,
      this.paginator.pageIndex,
      this.paginator.pageSize,
      this.sort.active,
      this.sort.direction
    );
  }

  load() {
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }

  loadVehiclesPage() {
    if (this.dataSource !== undefined) {
      this.filter.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.filter);
    }
  }

  onRowClicked(row) {
    this.itemClicked.next(row.matricula);
  }

  selectCurrent(matricula: string, secure_id: string, marca: string, model: string, pais: string, paisDesc: string, color: string) {
    this.itemSelected.next({secure_id: secure_id, matricula: matricula, marca: marca, model: model, pais: pais, paisDesc: paisDesc});
    if (this.dataDialog.isDialog) {
      this.dialogRef.close({secure_id: secure_id, matricula: matricula, marca: marca, model: model, pais: pais, paisDesc: paisDesc, color: color});
    }
  }

  generateExcel(): void {
    let filter = this.filter;
    if (this.updatedFilterData != null) {
      filter = this.updatedFilterData;
    }
    this.dialog.open(DownloadExcelDialogComponent, {
        closeOnNavigation: false,
        autoFocus: true,
        data: Builder.newDownloadExcelDialogData (this.vehiclesService, filter, this.i18n('Vehicles'), this.sort.direction, this.sort.active)
    });
  }

}
