import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { SeguretatGrups } from '../../models/seguretat-grups.model';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatTable, MatTableDataSource, MatSort, Sort } from '@angular/material';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { SeguretatGrupsService } from '../../services/seguretat-grups.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { FormService } from 'path-shared/services/form/form.service';
import { Builder } from 'path-shared/models/builder';
import { SeguretatPantallaUsuario } from '../../models/seguretat-pantalla-usuario.model';
import { SeguretatUsuaris } from 'app/sections/seguretat/seguretat-usuaris/models/seguretat-usuaris.model';
import { LoadedRouterConfig } from '@angular/router/src/config';
import { SeguretatUsuarisService } from 'app/sections/seguretat/seguretat-usuaris/services/seguretat-usuaris.service';
import { typeofExpr } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-seguretat-grups-editor',
  templateUrl: './seguretat-grups-editor.component.html',
  styleUrls: ['./seguretat-grups-editor.component.scss']
})
export class SeguretatGrupsEditorComponent implements OnInit {

  secureId = null;
  grup: SeguretatGrups;
  dialogData: ComponentDialog;
  closeButtonText: string;

  displayedColumnsPantallas = ['pantalla', 'lectura', 'alta', 'modif', 'borrar', 'eliminar'];
  displayedColumnsUsuaris = ['nomUsuari', /*'descripcio', 'email',*/ 'eliminar'];

  segPantallas = [];
  segUsuarios = [];
  dataSourcePantallas =  new MatTableDataSource<SeguretatPantallaUsuario>();
  dataSourceUsuaris = new MatTableDataSource<SeguretatUsuaris>();

  @ViewChild('mainForm') private mainForm: ElementRef;
  @ViewChild('sortUsuaris') sortUsuaris: MatSort;
  @ViewChild('sortPantallas') sortPantallas: MatSort;

  constructor(
    private dialog: MatDialog,
    private confirmationDialogService: ConfirmationDialogService,
    private grupsService: SeguretatGrupsService,
    private usuarisService: SeguretatUsuarisService,
    private i18n: I18n,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    public thisDialogRef: MatDialogRef<SeguretatGrupsEditorComponent>,
    private formService: FormService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
      this.grup = Builder.newGrup();
      if (data !== null) {
        this.secureId = data;
      }
   }


  ngOnInit() {
    if (this.secureId !== null) {
      this.grupsService.getSeguretatGrup(this.secureId).subscribe(res => {
        this.grup = res;
        const emptyGrup = Builder.newSeguretatPantallaUsuario();
        this.grup.pantallas.push(emptyGrup);
        this.dataSourcePantallas = new MatTableDataSource(this.grup.pantallas );
        this.dataSourcePantallas.sort = this.sortPantallas;


        const emptyUsuari = Builder.newSeguretatUsuari();
        this.grup.usuarios.push(emptyUsuari);
        this.dataSourceUsuaris = new MatTableDataSource(this.grup.usuarios);
        this.dataSourceUsuaris.sort = this.sortUsuaris;

        this.LoadSegPantallas();
        this.LoadSegUsuarios();
      });
    }
  }

  LoadSegPantallas() {
    this.grupsService.getSeguretatPantallas().subscribe(res => {
      this.segPantallas = res;
      if (this.grup.pantallas !== undefined) {
        this.grup.pantallas.forEach(pantalla => {
          const index = this.segPantallas.findIndex(x => x.descripcio === pantalla.pantalla);
          if (index > -1) {
            this.segPantallas.splice(index, 1);
          }
        });
      }
      this.segPantallas.unshift({'id': 0, 'descripcio': ''});
    });
  }

  LoadSegUsuarios() {
    this.usuarisService.getSegUsuaris().subscribe(res => {
      res.forEach(element => {
        this.segUsuarios.unshift({'id': element.codiUser, 'descripcio': element.descUser});
      });

      if (this.grup.usuarios !== undefined) {
        this.grup.usuarios.forEach(usuario => {
          const index = this.segUsuarios.findIndex(x => x.codiUser === usuario.codiUser);
          if (index > -1) {
            this.segUsuarios.splice(index, 1);
          }
        });
      }


      this.segUsuarios.unshift({'id': 0, 'descripcio': ''});
    });
  }

  save() {
    if (this.formService.allFieldsValid(this.mainForm)) {
      this.grupsService.setSeguretatGrup(this.grup).subscribe(result => {
        this.toastr.info(this.i18n('Grup guardat correctament'));
        this.thisDialogRef.close(true);
      });
    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
    }
  }

  setActiu() {
    if (!this.grup.actiu) {
      this.grup.actiu = true;
      const data = new Date();
      this.grup.inici = new Date();
      data.setFullYear(2100);
      this.grup.fi = data;
    } else {
      this.grup.actiu = false;
      const data = new Date();
      data.setDate(data.getDate() - 1);
      this.grup.inici = data;
      this.grup.fi = data;
    }
  }

  comprovarData() {
    let inicio: Date;
    let fin: Date;
    if (this.grup !== undefined  && this.grup !== null
      && this.grup.inici !== undefined  && this.grup.inici !== null
      && this.grup.fi !== undefined  && this.grup.fi !== null  ) {
        if (typeof(this.grup.inici) === 'string') {
          inicio = new Date(this.grup.inici);
        } else {
          inicio = this.grup.inici;
        }

        if ( typeof(this.grup.fi) === 'string') {
          fin = new Date(this.grup.fi);
        } else {
          fin = this.grup.fi;
        }

      if ( inicio <= new Date() && new Date() <= fin) {
        this.grup.actiu = true;
      } else {
        this.grup.actiu = false;
      }
    }
  }
  cancel() {
    this.thisDialogRef.close(false);
  }

  remove() {
    let message;
    if (this.grup.usuarios.length > 1) { // más grande que uno porqué hay uno "virtual/falso"
      message = this.i18n('ATENCIÓ: Actualment aquest grup conté usuaris.\nSegur que voleu esborrar el grup?');
    } else {
      message = this.i18n('Segur que voleu esborrar el grup?');
    }
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), message)
    .then((confirmed) => {
        if (confirmed) {
          this.grupsService.removeSeguretatGrup(this.secureId).subscribe(result => {
            this.toastr.success(this.i18n('Grup esborrat correctament'));
            this.dialog.closeAll();
            this.thisDialogRef.close(true);
          });
        }
    });
  }

  removePantalla(row: SeguretatPantallaUsuario) {
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol esborrar la pantalla?'))
    .then((confirmed) => {
        if (confirmed) {
          const index = this.grup.pantallas.findIndex(x => x.som_id === row.som_id);
          if (index > -1) {
            this.grup.pantallas.splice(index, 1);
            this.dataSourcePantallas = new MatTableDataSource(this.grup.pantallas);
          }

          if (row.pantalla !== null) {
            this.grupsService.removeSeguretatPantallaUsuario(row.secureId).subscribe(result => {
              this.toastr.success(this.i18n('Pantalla esborrada correctament'));
            });
          }
        }
    });
  }

  removeUser(row: SeguretatUsuaris) {

    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol esborrar l\'usuari del grup?'))
    .then((confirmed) => {
        if (confirmed) {
          const index = this.grup.usuarios.findIndex(x => x.codiUser === row.codiUser);
          if (index > -1) {
            this.grup.usuarios.splice(index, 1);
            this.dataSourceUsuaris = new MatTableDataSource(this.grup.usuarios);
          }

          if (row.nomUsuari !== null) {
            this.usuarisService.removeSeguretatGrupUsuari(row.codiUser, this.grup.secureId).subscribe(result => {
              this.toastr.success(this.i18n('Usuari esborrat correctament'));
            });
          }
        }
    });
  }


  novaFila(id, row) {
    if (id !== null && id !== 0) {
      const emptyRow = Builder.newSeguretatPantallaUsuario();
      this.grup.pantallas.push(emptyRow);
      this.dataSourcePantallas = new MatTableDataSource(this.grup.pantallas);
      this.dataSourcePantallas.sort = this.sortPantallas;
      row.idSegPantallas = id;
      const index = this.segPantallas.findIndex(x => x.id === id);
      row.pantalla = this.segPantallas[index].descripcio;
      if (index > -1) {
        this.segPantallas.splice(index, 1);
      }
    }
  }

  novaFilaUsuaris(id) {
    if (id !== null && id !== 0) {
      const emptyUsuari = Builder.newSeguretatUsuari();
      this.grup.usuarios.push(emptyUsuari);
      this.dataSourceUsuaris = new MatTableDataSource(this.grup.usuarios);
      this.dataSourceUsuaris.sort = this.sortUsuaris;
    }
  }
}
