import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { Injectable } from '@angular/core';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { ParameterDto } from 'path-shared/services/parameters/model/ParametroDto';
import { VehicleDtoDgt } from '../models/updateVehicleDenuncia.model';
import { HttpErrorResponse } from '@angular/common/http';


const defaultSortParam = 'codi';
const defaultSortDirection = 'desc';


@Injectable()
export class DgtService {
  displayedItems = [];


  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {
    this.displayedItems['checkedValues'] = 0;
    this.displayedItems['checkedItems'] = Array();
  }

  goDgt(): Observable<any> {
    return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetDgt'], null);
  }
  goDgtMatricula(matricula: string): Observable<any> {
    return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetDgtMatricula'].replace('{matricula}', matricula) , null );
  }
  goDgtPersona(persona: string): Observable<any> {
    return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetDgtPersona'].replace('{persona}', persona) , null );
  }
  goDgtEucaris(eucaris: string): Observable<any> {
    return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetDgtEucaris'].replace('{eucaris}', eucaris) , null );
  }
  goDgtPropietario(propietario: string): Observable<any> {
    return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetDgtPropietario'].replace('{propietario}', propietario) , null );
  }
  xmlMatricula(matricula: string): Observable<any> {
    return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlGetDgtXMLMatricula'].replace('{matricula}', matricula),  null );
  }
  xmlPersona(persona: string): Observable<any> {
    return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlGetDgtXMLPersona'].replace('{persona}', persona),  null );
  }
  xmlEucaris(eucaris: string): Observable<any> {
    return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlGetDgtXMLEucaris'].replace('{eucaris}', eucaris),  null );
  }
  xmlPropietario(propietario: string): Observable<any> {
    return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlGetDgtXMLPropietario'].replace('{propietario}', propietario),  null );
  }

  UpdateVehicle(vehicle: VehicleDtoDgt, errorFunction: (any)): Observable<any> {
    return this.http.postWithError(config.urls['apiUrl'] + api.endpoints['urlUpdateDgtPropietario'] , vehicle, errorFunction);
  }
  OverrideVehicle(vehicle: VehicleDtoDgt): Observable<any> {
    return this.http.post(config.urls['apiUrl'] + api.endpoints['urlUpdateDgtPropietario'] , vehicle);
  }

  updatePropietarioDenuncia(personaDgt, sidDenuncia): Observable<any> {
    return this.http.post(config.urls['apiUrl'] + api.endpoints['urlUpdatePropietarioDenuncia'] + '/' + sidDenuncia , personaDgt);
  }

}
