import { TramosService } from './../services/tramos.service';
import { TramosEditorComponent } from './../components/tramos-editor/tramos-editor.component';
import { TramosFilter } from './../models/tramo-filter';

import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { PermissionDialogComponent } from 'path-shared/components/permission-dialog/permission-dialog.component';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { TramosTableComponent } from '../components/tramos-table/tramos-table.component';
import { TramosFilterComponent } from '../components/tramos-filter/tramos-filter.component';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-tramos-tipos',
  templateUrl: './tramos.component.html',
  styleUrls: ['./tramos.component.scss']
})
export class TramosComponent implements OnInit {

  permissionDialogRef: MatDialogRef<PermissionDialogComponent>;
  activeFilters: string[] = [];
  filterData: TramosFilter;
  somIdFoo: number;
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  @ViewChild(TramosTableComponent) tramosTable: TramosTableComponent;
  constructor(
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private i18n: I18n,
    private toastr: ToastrService,
    private not: NotificationService,
    public thisDialogRef: MatDialogRef<TramosComponent>,
    public tramosService: TramosService,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
  ) {
    this.filterData = new TramosFilter('id', 'desc');
    if (data !== undefined) {
      this.dataDialog = data;
    }
  }

 

  ngOnInit() {
    this.auth.UserCanSeePage(this.auth.Pages.Tramos);
    this.tramosTable.itemClicked.subscribe(secure_id => {
      this.update(secure_id);
    });
    this.tramosTable.itemSelected.subscribe(codi => {
      this.thisDialogRef.close(codi);
    });
  }

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Ocupacions'));
    }
  }

  edit() {
    const idSeleccionat: string = this.tramosTable.displayedItems['checkedItems'][0];
    this.update(idSeleccionat);
  }

  create() {
    this.update(null);
  }

  update(secure_id: string) {
    const data: ComponentDialog = Builder.newComponentDialog(true, false, secure_id);

    const ref = this.dialog.open(TramosEditorComponent, {
      width: '70%',
      closeOnNavigation: false,
      autoFocus: true,
      data: data
    });

    ref.afterClosed().subscribe(updated => {

      if (updated) { this.tramosTable.load(); }
    });
  }

  refresh() {
    this.tramosTable.load();
  }

  onFilterClicked() {
    const ref = this.dialog.open(TramosFilterComponent, {
      width: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.tramosTable.filter
      }
    });

    ref.componentInstance.notify.subscribe((filter: TramosFilter) => {
      this.tramosTable.filter.set(filter);
      this.tramosTable.load();
    });
  }

  onRowSelected(somid) {
    this.update(somid);
  }

  generateExcel() {
    this.tramosTable.generateExcel();
  }

  publicar(){
    this.tramosService.publicarAparcare().subscribe(result =>{
      if(result === undefined || result === null ){
        this.toastr.info(this.i18n('Polígonos publicados correctamente'));
       
      }else{
        this.toastr.warning(this.i18n('Error al publicar los polígonos')); 
      }
    });
  }
}
