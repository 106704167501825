<div class="row justify-content-between">
  <div class="col">
    <mat-paginator #paginator
      [pageIndex]="0"
      [pageSize]="this.filter.paginator.pageSize"
      [pageSizeOptions]="[20, 40, 60, 100]">
    </mat-paginator>
  </div>
</div>

<div #table class="table-sdw is-fixed" style="max-height: 70vh;">

  <mat-table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.filter.paginator.sortField" [matSortDirection]="this.filter.paginator.sortDir" class="is-header-sticky">

    <ng-container matColumnDef="matricula">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Matrícula</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.matricula}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="fechaInicio">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Fecha inicio</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.fechaInicio | formatDate : true}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="fechaFin">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Fecha fin</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.fechaFin | formatDate : true}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="usuarioBonoAsignado">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Usuario bono asignado</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.usuarioBonoAsignado}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="usuarioCreacion">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Usuario creación</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.usuarioCreacion}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="comercio">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Comercio</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.comercio}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="tiempo">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Tiempo</mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{row.tiempo}}
        <span *ngIf="row.tiempo!==null"> min.</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="importe">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Importe</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.importe}} €</mat-cell>
    </ng-container>

    <ng-container matColumnDef="leido">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Leído</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <app-form-check-edit [value]="row.leido" disabled></app-form-check-edit>
        <!-- {{row.leido}} -->
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="tipoBono">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Tipo bono</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.tipoBono}}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"  [ngClass]="{'is-active': row.markerClicked }"></mat-row>
  </mat-table>
</div>
