<app-toolbar>  
  <app-tool-button text="Actualizar"  i18n-text (click)="buscar();" color="has-color-blue" icon="fa-sync"></app-tool-button>
  <app-tool-button-permissions [permission]="this.auth.Pages.Reincidents"></app-tool-button-permissions>
</app-toolbar>

<div>
  <div class="row">
    <div class="col-4">
      <form #mainForm class="row filter">
        <div class="col-3">
          <app-form-text-edit id="estadistiques-reincidents-num" label="Reincidencias" i18n-label type="number" required autofocus [(value)]="numReincidencias"></app-form-text-edit>
        </div>
        <div class="col-8">
          <app-form-date-range-edit label1="Fecha inicio" i18n-label1 label2="Fecha fin" [(value)]="this.fechas"></app-form-date-range-edit>
        </div>
        <i class="fas fa-search lupa" (click)="this.buscar()"></i>
        <app-form-combo-edit class="col" label="Infracción" i18n-label [(value)]="this.infraccion" [items]="this.infracciones" valueField="codi" textField="descripcio"></app-form-combo-edit>
        
      </form>
      <div class="row" >
        <agm-map *ngIf="mapVisible" #AgmMap [fullscreenControl]="true" [latitude]="this.latitud" [longitude]="this.longitud" [zoom]="this.zoom" style="height: 50vh;width: 50vh;">
          <agm-marker *ngFor="let row of this.selectedRow.coordenadas; let i = index" [latitude]="this.row.m_Item1" [longitude]="this.row.m_Item2" ></agm-marker>
        </agm-map>
      </div>
    </div>
 
    
    <div class="col-8">
      <div *ngIf="this.init || this.dataSource.data.length === 0" >
        <div class="empty-case">
          <span class="empty-case__icon"><i class="fa fa-search"></i></span>
          <h4 *ngIf="init" class="empty-case__title" i18n>Definid el filtro</h4>
          <h4 *ngIf="this.dataSource !== null && this.dataSource.data.length === 0" class="empty-case__title" i18n>Sin resultados</h4>
          <p i18n>Indicad los parámetros en el filtro izquierdo y haced click en "Actualizar" o en la lupa.</p>
        </div>
      </div>
      <div [hidden]="(this.init || this.dataSource.data.length === 0) " class="table-sdw is-fixed">

        <mat-table #table [dataSource]="dataSource" matSort #sort="matSort" aria-label="Elements" class="is-header-sticky">
    
    
            <ng-container matColumnDef="matricula">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Matrícula</mat-header-cell>
                <mat-cell *matCellDef="let row"><a (click)="FiltroDenuncias(row.matricula)" class="is-link">{{row.matricula}}</a></mat-cell>
            </ng-container>
    
            <ng-container matColumnDef="reincidencias">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Reincidencias</mat-header-cell>
                <mat-cell *matCellDef="let row">{{row.reincidencias}}</mat-cell>
            </ng-container>     
            
    
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <!-- <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset"></mat-row> -->
            <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onRowClicked(row)"  [ngClass]="{'is-active': row.clicked }"></mat-row>

        </mat-table>
    </div>
    </div>
  </div>
  
</div>