import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StartupService } from 'path-shared/services/startup/startup.service';

@Component({
  selector: 'app-matricula',
  templateUrl: './matricula.component.html',
  styleUrls: ['./matricula.component.scss']
})
export class MatriculaComponent implements OnInit {
  @Input() matricula: string;
  @Input() pais: string;
  @Input() vehicle: string;
  @Input() sugPais: string;
  @Input() sugMatricula: string;
  @Input() activarSugerencias: boolean;
  @Input() marca: string;
  @Input() model: string;
  @Output() canvioPais = new EventEmitter<string>();
  @Output() canvioMatricula = new EventEmitter<string>();

  listMatriculas: string[];
  listPaises: string[];
  vehicles: any[];

  constructor( private startupService: StartupService) {
    if (this.activarSugerencias == null) {
      this.activarSugerencias = true;
    }
  }

  ngOnInit() {
    this.vehicles = this.startupService.GetTipusVehicles();
    this.model = this.model;
  }

  setPais(pais) {
    this.canvioPais.emit(pais);
  }

  setMatricula(matricula) {
    this.canvioMatricula.emit(matricula);
  }

  validPais() {
    return this.pais !== null && this.pais !== undefined && this.pais.trim().length > 0;
  }

}
