<app-filter-header title="Filtrar notificacions" i18n-title  (apply)="this.apply()" (reset)="this.reset()" (dismiss)="this.dismiss()"></app-filter-header>
<div class="modal-body">
  <div class="row">
    <app-form-text-edit id="notificacions-filter-codi" class="col-4" i18n-label label="Notificació" [(value)]="filter.notificacio"></app-form-text-edit>
    <app-form-text-edit id="notificacions-filter-enviament" class="col-4" i18n-label label="Enviament" [(value)]="filter.enviament"></app-form-text-edit>
    <app-form-text-edit id="notificacions-filter-exp" class="col-4" i18n-label label="Expedient" [(value)]="filter.element"></app-form-text-edit>
  </div>
  <div class="row">
    <app-form-text-edit id="notificacions-filter-tipus" class="col-4" i18n-label label="Tipus" [(value)]="filter.tipus"></app-form-text-edit>
    <app-form-check-edit class="col-4" i18n-label label="Acceptació" [(value)]="filter.acceptacio"></app-form-check-edit>
  </div>
</div>