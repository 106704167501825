import { Component, OnInit, Output, EventEmitter, Inject, OnDestroy } from '@angular/core';
import { LegislacioFilter } from '../../models/legislacio-filter';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-legislacions-filter',
  templateUrl: './legislacions-filter.component.html',
  styleUrls: ['./legislacions-filter.component.scss']
})
export class LegislacionsFilterComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  filter: LegislacioFilter;

  @Output() notify: EventEmitter<LegislacioFilter> = new EventEmitter<LegislacioFilter>();

  constructor(
    public thisDialogRef: MatDialogRef<LegislacionsFilterComponent>,
    private i18n: I18n,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    this.filter = data.filter;
  }

  ngOnInit() {
    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.apply();
      }
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated();
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }
}
