import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aparcare',
  templateUrl: './aparcare.component.html'
})
export class AparcareComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
