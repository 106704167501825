import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomFilterService } from "path-shared/services/datasource/custom-filter-service";
import { ErrorsMatriculaFilter } from "../models/errorsMatriculaFilter";

const defaultSortParam = 'expedient';
const defaultSortDirection = 'desc';


@Injectable()
export class CargaMatriculasFilterService  extends CustomFilterService {
  public createFilterParams(filter: ErrorsMatriculaFilter, start: number, pageSize: number, sortField: string, sortDir: string ): HttpParams {
    return new HttpParams()
    .append('nomesNoves', filter.nomesNoves ? 'true': null)
    .append('nomesVelles', filter.nomesVelles ? 'true': null)
    .append('exp', filter.exp)
    .append('matricula', filter.matricula)
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}