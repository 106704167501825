<app-dialog-header label="Màquina" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Editar" i18n-text (click)="this.dialogData.isReadOnly = false" color="has-color-green" icon="fa-edit" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Maquinas) ||!this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Guardar" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Maquinas)||this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Eliminar" i18n-text (click)="this.remove();" color="has-color-red" icon="fa-trash-alt" [disabled]="!this.auth.UserCanDelete(this.auth.Pages.Maquinas) || this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Fitxers" i18n-text (click)="this.fitxers();" color="has-color-blue" icon="fas fa-file-archive"
  [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Maquinas) || this.dialogData.isReadOnly">
</app-tool-button>
  <app-tool-button [text]="this.closeButtonText" i18n-text (click)="this.cancel()" color="has-color-yellow" icon="fa-th-list"></app-tool-button>
</app-toolbar>
<app-dialog-body>
  <form #mainForm>
    <div class="row">
      <div class="col-sm-6">
        <div class="row">
          <app-form-text-edit class="col-sm-6" i18n-label label="Codi maquina" [(value)]="this.maquina.codiMaquina" required maxLength="30"></app-form-text-edit>
          <app-form-text-edit class="col-sm-6" i18n-label label="Descripció" [(value)]="this.maquina.descripcion" required maxLength="50"></app-form-text-edit>
          <app-form-text-edit class="col-sm-4" i18n-label label="Model" [(value)]="this.maquina.modelo"  maxLength="50"></app-form-text-edit>
          <app-autocomplete-combo #comboMarca class="center-horizontal col-sm-4" i18n-label label="Marca" [options]="this.dictMarcas"
          (valorSeleccionado)="this.marcaSeleccionado($event)" [valorAutocomplete]="this.valorAutoCompleteMarcas"  required="false" ></app-autocomplete-combo>
          <!-- <app-combo-box-input-autocomplete #comboTemporada class="center-horizontal col-sm-4" [disableComponent]="this.dialogData.isReadOnly" class="center-horizontal" i18n-label label="Temporada" [options]="this.temporadasDictionary"
          (valorSeleccionado)="this.temporadaSeleccionado($event)" [valorAutocomplete]="this.valorAutoCompleteTemporada" [required]="true"  ></app-combo-box-input-autocomplete> -->
          <app-form-multi-select class="col-xl-12" titulo="Temporades" [data]="temporadesList" [(selectedValues)]="this.maquina.temporadas" i18n-titulo tituloMultiSelect="Selecciona una o més" i18n-tituloMultiSelect
          (selectedValuesChange)="this.temporadaAddedOrDelted()" ></app-form-multi-select>
          <app-form-text-edit class="col-sm-6" *ngIf="maquina.position !== undefined" [(value)]="maquina.position.lat" label="Latitud" number="true"   [isReadOnly]="true" ></app-form-text-edit>
          <app-form-text-edit class="col-sm-6" *ngIf="maquina.position !== undefined" [(value)]="maquina.position.lng" label="Longitud"    [isReadOnly]="true" ></app-form-text-edit>
          <div *ngIf="this.afectacionCalculadaActiva"  class="alert alert-secondary" role="alert">
           <span i18n>Afectació automàtica activa, no es pot editar l'afectació manual.</span>
          </div>

          <mat-tab-group class="has-frame"  class="col-sm-12"  #tabGroup (selectedTabChange)="selectedTemporada()">
            <mat-tab *ngFor="let temporadaItemArray of this.maquina.temporadas let index = index"  [data]="temporadaItemArray.item_id" [label]="temporadaItemArray.item_text" >
            <mat-table #table [dataSource]="this.getSelectedTramos(temporadaItemArray.item_id)" matSort aria-label="Elements" class="is-header-sticky table" style="max-height: 200;">
              <!--[dataSource]="this.modelos.value" #mytable mdbTableScroll scrollY="true"   maxHeight="200"  class="is-header-sticky" >class="my-table mat-elevation-z8"-->
              <!-- Id Column -->
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> ID</th>
                <td mat-cell *matCellDef="let element"> {{element.tramo.Som_id}} </td>
              </ng-container>
              <ng-container matColumnDef="Descripcio">
                <th mat-header-cell *matHeaderCellDef> Descripció</th>
                <td mat-cell *matCellDef="let element"> {{element.tramo.descripcion}} </td>
              </ng-container>
              <ng-container matColumnDef="Afectacio">
                <th mat-header-cell *matHeaderCellDef>Afectació (%)</th>
                <td mat-cell *matCellDef="let element">
                  <app-form-text-edit [(value)]="element.afectacionManual" [disabled]="this.afectacionCalculadaActiva" type="number" number="true"  required="true"  maxNumberValue="100"></app-form-text-edit>
                  </td>
              </ng-container>
              <ng-container matColumnDef="AfectacioCalc">
                <th mat-header-cell *matHeaderCellDef>Afectació IA (%)</th>
                <td mat-cell *matCellDef="let element"> {{element.afectacionCalculada}} </td>
              </ng-container>
              <ng-container matColumnDef="ocupacion">
                <th mat-header-cell *matHeaderCellDef>Ocupació</th>
                <td mat-cell *matCellDef="let element">
                  <i *ngIf="!this.dialogData.isReadOnly" (click)="this.openTramo(element)" class="cursor fa fa-edit btn btn-outline-warning btn-sm"></i>  
                </td>
              </ng-container>
              
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </mat-table>
          </mat-tab>
          </mat-tab-group>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="alert alert-secondary" role="alert">
          <span i18n>Faci click al mapa per a posicionar la màquina i click sobre un tram per assginar-lo a la màquina.</span>
        </div>
        <div class="row " style="height: 70vh;width:100%;padding-left: 15px;padding-right: 15px;">
          <agm-map [latitude]="currentLocation.latitude" [styles]="styleMap" [longitude]="currentLocation.longitude" [zoom]="18" (mapClick)="placeMarker($event)" (mapReady)="this.mapReady($event)">
            <agm-polygon *ngFor="let polygon of allViewTramos" [paths]="polygon.poligon"  (polyClick)="polyClicked( polygon)"
            [fillColor]="polygon.zona.color "
            [strokeColor]="polygon.strokeColor !== undefined && polygon.strokeColor !== null  ? polygon.strokeColor :  polygon.zona.color">
          </agm-polygon>
          </agm-map>
        </div>
      </div>
      
    </div>
    
  </form>
</app-dialog-body>
