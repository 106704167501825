<app-filter-header title="Filtrar infraccions" i18n-title  (apply)="this.apply()" (reset)="this.reset()" (dismiss)="this.dismiss()"></app-filter-header>
<div class="modal-body">
  <div class="row">
    <app-filter-text-edit id="infraccions-filter-id" autofocus class="col-sm-3" i18n-label label="Id" [(value)]="filter.id" maxLenght="5" number="true"   ></app-filter-text-edit>
    <app-form-combo-edit class="col-sm-3" i18n-label label="Legislació" [items]="this.tipusLegislacions" [(value)]="filter.legislacio" valueField="codi" textField="descripcio"></app-form-combo-edit>
    <!--<app-filter-text-edit class="col-sm-3" i18n-label label="Legislació" [(value)]="filter.legislacio" maxLenght="50"></app-filter-text-edit>-->
    <app-filter-text-edit id="infraccions-filter-article" class="col-sm-3" i18n-label label="Article" [(value)]="filter.article" maxLenght="50" ></app-filter-text-edit>
    <app-filter-text-edit id="infraccions-filter-apartat" class="col-sm-3" i18n-label label="Apartat" [(value)]="filter.apartat"></app-filter-text-edit>
  </div>
  <div class="row">
    <app-form-text-edit id="infraccions-filter-opcio" class="col-sm-4" i18n-label label="Opció" [(value)]="filter.opcio" maxLenght="7"></app-form-text-edit>
    <app-form-text-edit id="infraccions-filter-gravetat" class="col-sm-4" i18n-label label="Gravetat" [(value)]="filter.gravetat" maxLenght="7"></app-form-text-edit>
    <app-filter-text-edit id="infraccions-filter-descCurta" class="col-sm-4" i18n-label label="Descripció curta" [(value)]="filter.descCurta" maxLenght="30"></app-filter-text-edit>
  </div>
  <div class="row">
      <app-filter-text-edit id="infraccions-filter-import" class="col-sm-4" i18n-label label="Import" [(value)]="filter.import" maxLenght="30"  number="true"></app-filter-text-edit>
      <app-filter-text-edit id="infraccions-filter-fins" class="col-sm-4" i18n-label label="Fins" [(value)]="filter.fins" maxLenght="30" number="true"></app-filter-text-edit>
      <app-filter-text-edit id="infraccions-filter-punts" class="col-sm-4" i18n-label label="Punts" [(value)]="filter.punts" maxLenght="10"></app-filter-text-edit>
  </div>
  <div class="row">
    <app-form-date-edit id="infraccions-filter-dataIni" class="col-sm-4" i18n-label label="Data Inici" [(value)]="filter.inici" type="calendar"></app-form-date-edit>
    <app-form-date-edit id="infraccions-filter-dataFi" class="col-sm-4" i18n-label label="Data Fi" [(value)]="filter.final" type="calendar"></app-form-date-edit>
    <app-filter-text-edit id="infraccions-filter-numFotos" class="col-sm-4" i18n-label label="Núm fotos PDA" [(value)]="filter.numFotosPda" maxLenght="50" number="true"></app-filter-text-edit>
  </div>
  <div class="row col">
      <app-form-tri-state label="Obsoleta" i18n-label labelSi="Si" i18n-label labelNo = "No" i18n-labelTodos labelTodos="Tots" [(value)]="this.filter.obsoleta"></app-form-tri-state>
    </div>
</div>
