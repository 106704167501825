import { Component, Inject, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MaquinaIncidencia } from '../../models/maquinaIncidencia';
import { MaquinasService } from '../../services/maquinas.service';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatPaginator, MatSort } from '@angular/material';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { MaquinaIncidenciaFilter } from '../../models/maquinaIncidencia-filter';
import { MaquinaIncidenciesFilterService } from '../../services/maquinaIncidencia-filter.service';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { IncidenciesFilterComponent } from '../incidencies-filter/incidencies-filter.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ActuacionsTableComponent } from '../actuacions-table/actuacions-table.component';
import { ActuacionsNewComponent } from '../actuacions-new/actuacions-new.component';
import { ImagesService } from 'path-shared/services/images/images.service';
import { IncidenciesImagesComponent } from '../incidencies-images/incidencies-images.component';

@Component({
  selector: 'app-incidencies',
  templateUrl: './incidencies.component.html',
  styleUrls: ['./incidencies.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class IncidenciesComponent implements OnInit {
  @ViewChildren(ActuacionsTableComponent) actuacionsTables: QueryList<ActuacionsTableComponent>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() updatedFilterData: MaquinaIncidenciaFilter;
  dataDialog: ComponentDialog = Builder.newComponentDialog();
  isDialog: boolean = false;
  incidenciesFilterDialogRef : MatDialogRef<IncidenciesFilterComponent>;
  incidenciesImagesDialogRef: MatDialogRef<IncidenciesImagesComponent>;
  newActuacioDialogRef: MatDialogRef<ActuacionsNewComponent>;

  dataSource: CustomDataSource<MaquinaIncidencia, MaquinaIncidenciaFilter>;
  filter: MaquinaIncidenciaFilter = new MaquinaIncidenciaFilter('CODI_MAQUINA', 'asc');

  images: any = [];
  expandedElement: MaquinaIncidencia | null;

  highlightedRows = [];
  displayedItems = [];
  displayedColumns = ['Afegir_Actuacio', 'SOM_ID', 'CODI_MAQUINA', 'INCIDENCIA', 'DATA_INCIDENCIA', 'RESOLTA', 'USUARI_RESOLUCIO' , 'DATA_RESOLUCIO', 'imatges', 'Resoldre', 'expandedDetailButton'];

  constructor(
    public thisDialogRef: MatDialogRef<IncidenciesComponent>,
    private maquinesService: MaquinasService,
    private imagesService: ImagesService,
    public auth: AuthorizationService,
    private dialog: MatDialog,
    private filterService: FilterService,
    private i18n: I18n,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
  ) { 
    if (data != undefined && Object.keys(data).length > 0) {
      this.isDialog = true;
      this.filterService.loadFilter('maquines-incidencies-filter', this.filter);
      this.filter.codiMaquina = data['codiMaquina'];
      this.maquinaFilterChange(null);
      this.filterService.saveFilter('maquines-incidencies-filter', this.filter);
    }
    
  }

  ngOnInit() {
    this.auth.UserCanSeePage(this.auth.Pages.Maquinas);
    this.dataSource = CustomDataSource.create<MaquinaIncidencia, MaquinaIncidenciaFilter>(this.paginator, new MaquinaIncidenciesFilterService(), 'urlGetIncidenciesMaquina', 'urlGetIncidenciesMaquinaCount');
    this.dataSource.subject.subscribe((data) => {
      data.forEach(item => {
        this.displayedItems[item.somId] = {
          id: item.somId
        };
      });
    });
    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        if (!this.dataDialog.isDialog) { 
          this.filterService.saveFilter('maquines-incidencies-filter', this.filter); 
          this.load();
        }
      }
    });

    if (!this.dataDialog.isDialog) { this.filterService.loadFilter('maquines-incidencies-filter', this.filter); }
    this.filter.paginator.init(this.sort, this.paginator);
  }

  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(tap(() => this.filter.updated()))
    .subscribe();
    this.load();
  }

  load() {
    if (this.dataSource !== undefined) {
      this.filter.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.filter);
    }
  }

  update() {
    if (this.dataSource !== undefined) {
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
    }
  }

  onFilterClicked() {
    this.incidenciesFilterDialogRef = this.dialog.open(IncidenciesFilterComponent, {
      width: '90%',
      height: 'auto',
      closeOnNavigation: false,
      autoFocus: true,
      data: this.filter
    });

    this.incidenciesFilterDialogRef.componentInstance.notify.subscribe((filter: MaquinaIncidenciaFilter) => {
      this.filter.set(filter);
      this.load();
    });
  }

  maquinaFilterChange(event: any) {
    if (this.filter.codiMaquina !== undefined && this.filter.codiMaquina !== null && this.filter.codiMaquina !== '' ) {
      this.filter.updated(true);
    } else if (this.filter.codiMaquina === '' && this.filter.codiMaquina !== this.filter.codiMaquina) {
      this.filter.codiMaquina = '';
      this.filter.updated(true);
    } else {
      this.filter.codiMaquina = '';
      this.filter.updated(false);
    }
  }

  resoldre(somId: number, resolta: boolean) {
    this.maquinesService.resoldreIncidenciesMaquina(somId, resolta).subscribe(() =>
      this.load()
    );
  }

  expandDetailButtonClick(row) {
    // this.actuacionsTables.forEach(x => x.buscarByRow(row))
    return this.expandedElement = this.expandedElement === row ? null : row;
  }
  newActuacioIncidencia

  afegirActuacio(row) {
    this.newActuacioDialogRef = this.dialog.open(ActuacionsNewComponent, {
      width: '90%',
      height: 'auto',
      closeOnNavigation: false,
      autoFocus: true,
      data: row
    });

    this.newActuacioDialogRef.afterClosed().subscribe((val) => {
      if (val) 
        this.load() 
    });
  }

  viewImages(contenedor) {
    // afegirImatgesWithContenedor
    this.imagesService.getImagesByContenedor(contenedor).subscribe(images => {
      let img=[];
      img.push(images[0]);
      this.images = images;
      this.images.forEach(element => {
        element.CONTENEDOR = contenedor;
      });

      this.incidenciesImagesDialogRef = this.dialog.open(IncidenciesImagesComponent,
      {
        minWidth: '50%',
        maxWidth: '90%',
        maxHeight: '70%',
        closeOnNavigation: false,
        autoFocus: true,
        data: {'ficheros': images, 'contenedor': contenedor, origen: 'INCIDENCIES'},
      });
      this.incidenciesImagesDialogRef.componentInstance.somId = contenedor;

      this.incidenciesImagesDialogRef.afterClosed().subscribe(() => {
        this.imagesService.getImagesByContenedor(contenedor).subscribe( newImages => {
          this.dataSource.getData().find(x => x.somId === contenedor).imatges = newImages.length;
          this.images = newImages;
          this.images.forEach(element => {
            element.CONTENEDOR = contenedor;
          });
        });
      });
    });
  }
}
