import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AnulacionZonaServiceManual } from 'app/sections/anulacion-zona-azul-manual/service/anulacion-zona.service';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { AnulacionZonaAzulAuto } from '../../models/anulacion-zona-azul-auto.model';
import { ValorarAnulacionModel } from '../../models/valorarAnulacion.model';

@Component({
  selector: 'app-valora-anulacion',
  templateUrl: './valora-anulacion.component.html',
  styleUrls: ['./valora-anulacion.component.scss']
})
export class ValoraAnulacionComponent implements OnInit {
  public anulacion: AnulacionZonaAzulAuto;
  public motivo: string;
  dialogData: ComponentDialog;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public thisDialogRef: MatDialogRef<ValoraAnulacionComponent>,
  private anulaManualService: AnulacionZonaServiceManual) { 

    if ( data.anulacion !== null ) {
      this.anulacion = data.anulacion;
    }
    this.motivo ='';
    this.dialogData = data;
  }

  ngOnInit() {
  }
  descartar(){
    this.thisDialogRef.close({update: false});
  }
  aceptar(){
    const valorarAnulacion:ValorarAnulacionModel= {motiu: this.motivo, anulacion:this.anulacion};
    this.anulaManualService.processarAnulacioValorada(valorarAnulacion).subscribe(x =>{
      this.thisDialogRef.close({update: true, result: x});
    });
  }
  acceptDisabled():boolean{

    return (this.motivo === '' || this.motivo.length===0); 
  }
}
