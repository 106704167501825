<app-dialog-header label="Tarifa grua" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Editar" i18n-text (click)="this.dialogData.isReadOnly = false" color="has-color-green" icon="fa-edit" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.TarifesGrua) || !this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Guardar" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.TarifesGrua) || this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Eliminar" i18n-text (click)="this.remove();" color="has-color-red" icon="fa-trash-alt" [disabled]="!this.auth.UserCanDelete(this.auth.Pages.TarifesGrua) || this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button [text]="this.closeButtonText" i18n-text (click)="this.cancel()" color="has-color-yellow" icon="fa-th-list"></app-tool-button>
</app-toolbar>
<app-dialog-body>
  <form #mainForm>
    <div class="row">
      <app-form-text-edit id="grua-tarifes-editor-descripcio" class="col-sm-6" i18n-label label="Descripció" [(value)]="this.tarifaGrua.descripcion" required></app-form-text-edit>
      <!--<app-form-text-edit class="col-sm-6" i18n-label label="Tipus estancia" [(value)]="this.tarifaGrua.tipoEstancia" required></app-form-text-edit>-->
      <app-combo-box-input #comboTipusEstancia   class="col-sm-6" [label]="'Tipus estància'" [showingOptions]="this.tipusEstanciaDictionary.value"  [disabled]="this.dialogData.isReadOnly"
        (optionChanged)="this.tipusEstanciaSeleccionado($event)">
      </app-combo-box-input>
      <app-combo-box-input #comboTipusTarifa   class="col-sm-6" [label]="'Tipus tarifa'" [showingOptions]="this.tipusTarifaDictionary.value"  [disabled]="this.dialogData.isReadOnly"
        (optionChanged)="this.tipusTarifaSeleccionado($event)">
      </app-combo-box-input>
      <!--<app-form-text-edit class="col-sm-6" i18n-label label="Tipus tarifa" [(value)]="this.tarifaGrua.tipoTarifa" required></app-form-text-edit>-->
      <!--<app-form-text-edit class="col-sm-6" i18n-label label="Tipus vehicle" [(value)]="this.tarifaGrua.tipoVehiculo" required></app-form-text-edit>-->
      <app-combo-box-input #comboTipusVehicle   class="col-sm-6" [label]="'Tipus vehicle'" [showingOptions]="this.tipusVehicleDictionary.value"  [disabled]="this.dialogData.isReadOnly"
        (optionChanged)="this.tipusVehicleSeleccionado($event)">
      </app-combo-box-input>
      <app-form-date-edit id="grua-torns-editor-dataIni" i18n-label label="Data inici" class="col-sm-6" [(value)]="this.tarifaGrua.fechaDesde"></app-form-date-edit>  
      <app-form-date-edit id="grua-torns-editor-dataFi" i18n-label label="Data fi" class="col-sm-6" [(value)]="this.tarifaGrua.fechaHasta"></app-form-date-edit>  
      <app-form-text-edit id="grua-torns-editor-import" class="col-sm-6" i18n-label type="number" label="Import" [(value)]="this.tarifaGrua.importeTarifa" required></app-form-text-edit>
    </div>
  </form>
</app-dialog-body>
