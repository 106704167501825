export class AvisosConf {
  id: number;
  nom: string;
  mail: string;
  pareId: number;
  isFolder: number;
  text: string;
  alarma: boolean;
  children: AvisosConf[];
  alarmas: {'zb': boolean, 'pol': boolean, 'gr': boolean};
  checked: boolean;

  constructor(id: number, nom: string, mail: string, pareId: number, isFolder: number, text: string, alarma: boolean) {
    this.id = id;
    this.nom = nom;
    this.mail = mail;
    this.pareId = pareId;
    this.isFolder = isFolder;
    this.text = text;
    this.alarma = alarma;
    this.alarmas = {'zb': true, 'pol': true, 'gr': true};
    this.checked = false;
  }
}
