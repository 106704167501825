import { Injectable } from "@angular/core";
import { CustomFilterService } from "path-shared/services/datasource/custom-filter-service";
import { ActuacioAgentFilter } from "../models/actuacioAgent-filter";
import { HttpParams } from "@angular/common/http";

const defaultSortParam = 'SOM_ID';
const defaultSortDirection = 'asc';

@Injectable()
export class ActuacioAgentFilterService extends CustomFilterService {
    public createFilterParams(filter: ActuacioAgentFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {
        return new HttpParams()
        .append('somId', (filter.somId ? filter.somId.toString() : ''))
        .append('agent', (filter.agent ? filter.agent : ''))

        .append('start', start.toString())
        .append('size', pageSize.toString())
        .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
        .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    }
}