<app-dialog-header label="Carga C60" i18n-label></app-dialog-header>

<app-dialog-body>

  <div class="row" style="align-items: center;padding: 4vh;">
    <div class="alert alert-warning" role="alert">
      <i class="fa fa-exclamation-triangle has-color-yellow"
        style="font-size: xx-large;padding-right: 1vh;padding-top: 1vh;"></i>
      <p class="text-justify" style="display: inline-block;"><strong>Resultat:</strong> {{this.resultCarga.msg }}</p>
    </div>
  </div>


  <div *ngIf=" this.resultCarga.listExpedientesSinConductor !== undefined 
        && this.resultCarga.listExpedientesSinConductor !== null 
        && this.resultCarga.listExpedientesSinConductor.length>0 " class="table-sdw is-fixed"
    style="max-height: 50vh;margin-top: -2vh;">


    <mat-table #table [dataSource]="this.resultCarga.listExpedientesSinConductor">

      <ng-container matColumnDef="exp">
        <mat-header-cell *matHeaderCellDef i18n>Expedients</mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.RefExterna}}</mat-cell>
        <!-- <th mat-header-cell *matHeaderCellDef> Expedients </th>
          <td mat-cell *matCellDef="let element"> {{ element.RefExterna }} </td> -->
      </ng-container>

      <ng-container matColumnDef="refC60">
        <mat-header-cell *matHeaderCellDef i18n>C60</mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.RefC60}}</mat-cell>
        <!-- <th mat-header-cell *matHeaderCellDef> C60 </th>
          <td mat-cell *matCellDef="let element"> {{ element.RefC60 }} </td> -->
      </ng-container>

      <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr> -->

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset"></mat-row>
    </mat-table>

  </div>
</app-dialog-body>