﻿// tslint:disable-next-line:cpath-models/SOM_REPORTS
export class SOM_CARAS {
  SOM_ID: number;
  ID_IMAGE: number;
  TOP_IMAGE: number;
  LEFT_IMAGE: number;
  WIDTH_IMAGE: number;
  HEIGHT_IMAGE: number;
  brightness: number;
  matricula: string;
}
