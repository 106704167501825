import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { Builder } from 'path-shared/models/builder';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';

@Component({
  selector: 'app-report-viewer',
  templateUrl: './report-viewer.component.html',
  styleUrls: ['./report-viewer.component.scss']
})
export class ReportViewerComponent implements OnInit {
  @Input() htmls: string[];
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  constructor(
    protected sanitizer: DomSanitizer,
    public thisDialogRef: MatDialogRef<ReportViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
  ) {
    this.dataDialog = data;
    this.htmls = data.tag as string[];}

  ngOnInit() {
  }

  close() {
    this.thisDialogRef.close(false);
  }

  sanitizeHTML(html: string) {
    html.replace("/<img src/g", "<img [src]")
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  public downloadPDF(html: string) {
 }
}
