import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { TipusEnviament } from '../models/tipus-enviament.model';
import { TipusEnviamentsFilter } from '../models/tipus-enviaments-filter';
import { TipusEnviamentsFilterService } from './tipus-enviaments-filter.service';

const defaultSortParam = 'id';
const defaultSortDirection = 'desc';

@Injectable()
export class TipusEnviamentsService {
  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {}

  setTipusEnviament(tipusEnviament: TipusEnviament): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetTipusEnviament'], tipusEnviament);
    }
  }

  removeTipusEnviament(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteTipusEnviament'].replace('{secure_id}', secure_id), null);
    }
  }

  getTipusEnviament(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetTipusEnviament'].replace('{secure_id}', secure_id), null);
    }
  }

  generateExcel(filter: TipusEnviamentsFilter, sortDir: string, sortField: string): Observable<any> {
    const start = 0;
    const pageSize = 1000;

    sortField = sortField === undefined ? defaultSortParam : sortField;
    sortDir = sortDir === '' ? defaultSortDirection : sortDir;
    const params = new TipusEnviamentsFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

    if (this.authGuard.canActivate()) {
      return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlTipusEnviamentsExcel'],  params );
    }
  }
}
