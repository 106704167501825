import { PersonaFilter } from '../../models/persona-filter';
import { Component, OnInit, Output, EventEmitter, Inject, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-persones-filter',
  templateUrl: './persones-filter.component.html',
  styleUrls: ['./persones-filter.component.scss']
})
export class PersonesFilterComponent implements OnInit, OnDestroy {
  subscription: Subscription;

  filter: PersonaFilter;
  tipusLegislacions = [];
  @Output() notify: EventEmitter<PersonaFilter> = new EventEmitter<PersonaFilter>();

  @ViewChild('mainForm') private mainForm: ElementRef;

  constructor(public thisDialogRef: MatDialogRef<PersonesFilterComponent>,
    private i18n: I18n,
    @Inject(MAT_DIALOG_DATA) public data) {
      this.filter = data.filter;
    this.tipusLegislacions = data.tipusLegislacions;
    }

  ngOnInit() {
    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.apply();
      }
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
      this.filter.updated();
      this.notify.emit(this.filter);
      this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }

}
