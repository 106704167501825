<div style="padding-top: 2%;padding-left: 15px;">
  <div class="row">
    <app-form-text-edit id="boe-general-ruta" class="col-xl-4" i18n-label label="Ruta"
    [(value)]="this.value.rutaP12"  [isReadOnly]="false" ></app-form-text-edit>

    <app-form-text-edit id="boe-general-dir3Code" class="col-xl-4" i18n-label label="Codigo DIR3"
    [(value)]="this.value.dir3Code"  [isReadOnly]="false" ></app-form-text-edit>
    
    <app-form-text-edit id="boe-general-dir3Nombre" class="col-xl-4" i18n-label label="Nombre DIR3"
    [(value)]="this.value.dir3Nombre"  [isReadOnly]="false" ></app-form-text-edit>
  </div>
  <div class="row">
    <mat-checkbox class="col-xl-3" [(ngModel)]="this.value.notificarDenuncia" [checked]="this.value.notificarDenuncia" i18n >Notificar denúncia &nbsp;</mat-checkbox>
    <mat-checkbox class="col-xl-3" [(ngModel)]="this.value.notificarAlegaciones" [checked]="this.value.notificarAlegaciones" i18n >Notificar alegaciones &nbsp;</mat-checkbox>
    <mat-checkbox class="col-xl-3" [(ngModel)]="this.value.notificarResolucion" [checked]="this.value.notificarResolucion" i18n >Notificar resolución &nbsp;</mat-checkbox>
    <mat-checkbox class="col-xl-3" [(ngModel)]="this.value.notificarSancionFirme" [checked]="this.value.notificarDenuncia" i18n >Notificar sanción firme &nbsp;</mat-checkbox>
  </div>
    
  <div class="row">
    <mat-checkbox class="col-xl-3" (change)="this.changeTipoExportacion(1)"
    [checked]="this.value.tipoExportacion !== undefined && this.value.tipoExportacion !== null && this.value.tipoExportacion === 1" 
    i18n >XML &nbsp;</mat-checkbox>
  <mat-checkbox class="col-xl-3" (change)="this.changeTipoExportacion(2)"
    [checked]="this.value.tipoExportacion !== undefined && this.value.tipoExportacion !== null && this.value.tipoExportacion === 2" 
    i18n >WEB &nbsp;</mat-checkbox>
  <app-form-text-edit id="boe-general-rutaExportacion" class="col-xl-6" i18n-label label="Ruta exportación"
  [(value)]="this.value.rutaExportacion"  [isReadOnly]="false" ></app-form-text-edit>

  </div>
  <div class="row">
    <app-form-text-edit id="boe-general-firmaLugar" class="col-xl-6" i18n-label label="Firma lugar"
    [(value)]="this.value.firmaLugar"  [isReadOnly]="false" ></app-form-text-edit>
    <app-form-text-edit id="boe-general-firmaFirmante" class="col-xl-6" i18n-label label="Firmante"
    [(value)]="this.value.firmaFirmante"  [isReadOnly]="false" ></app-form-text-edit>
  </div>
</div>
  