import { PermissionEditorComponent } from './components/permission-editor/permission-editor.component';
import { UsuarisService } from 'app/sections/usuaris/services/usuaris.service';
import { PermissionService } from './permission.service';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { Component, OnInit, Inject, HostBinding } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { PagesPermissions } from 'path-shared/models/pagesPermissions';
import { Usuari } from 'app/sections/usuaris/models/usuari.model';
import { PermissionItem } from './models/permissionItem.model';
import { SEG_GRUPOS } from 'path-models/SEG_GRUPOS';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { AuditoriaComponent } from '../auditoria/auditoria.component';
import { AuditoriaService } from 'path-shared/services/auditoria/auditoria.service';

@Component({
  selector: 'app-permission-dialog',
  templateUrl: './permission-dialog.component.html',
  styleUrls: ['./permission-dialog.component.scss']
})
export class PermissionDialogComponent implements OnInit {
  private permissionForm: string;
  public permissionItems: PermissionItem[] = [];
  public permission: PagesPermissions = {} as PagesPermissions;
  private groups: SEG_GRUPOS[];
  private users: Usuari[];
  public auditoriaActivada = false;
  @HostBinding('class.hidden') hidden = true;

  constructor(
    public thisDialogRef: MatDialogRef<PermissionDialogComponent>,
    private authService: AuthorizationService,
    private userService: UsuarisService,
    private toastr: ToastrService,
    public i18n: I18n,
    private auditoriaService: AuditoriaService,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private permissionService: PermissionService,
    public permissionEditorDialogRef: MatDialogRef<PermissionEditorComponent>,
    private dialog: MatDialog,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    this.permissionForm = data;
  }

  ngOnInit() {
    if (!this.authService.IsSuperUser()) {
      this.delayedClose(this.i18n('No tens autorització per modificar permisos!'));
    } else {
      const user: Usuari = this.userService.getCurrentUser();
      const perm = user.Permissions.find(x => x.Form === this.permissionForm);
      if (perm === undefined) {
        this.delayedClose(this.i18n('No tens autorització per gestionar aquest permís!'));
      } else {
        this.permission = perm;
        this.refreshPermissions();
        this.getActivaAuditoria();
      }
    }
  }

  getActivaAuditoria() {
    this.auditoriaService.GetActivaAuditoria(this.permission.Form).subscribe(res => {
      this.auditoriaActivada = res;
    });
  }

  ToggleAuditoriaActivada() {
    if (this.auditoriaActivada === false) {
      this.confirmationDialogService
      .confirm(
        this.i18n('Atenció'),
        this.i18n('Aquesta acció no es pot desfer, està segur que voleu activar la auditoria?')
      )
      .then(confirmed => {
        if (confirmed) {
          this.auditoriaService.SetActivaAuditoria(this.permission.Form).subscribe();
        } else {
          this.auditoriaActivada = false;
        }

        });
    } else {
      this.auditoriaActivada = true;
    }
  }

  refreshPermissions() {
    const refreshPermissions = this.permissionService.getPermission(this.permission.Code);
    refreshPermissions.subscribe(data => {
      this.permissionItems = data;
      this.permissionService.getGroups().subscribe(grupsData => {
        this.groups = grupsData;
        this.permissionService.getUsers().subscribe(userData => {
          this.users = userData;
        }, error => this.delayedClose(this.i18n('No tens els permisos necessaris per visualitzar aquesta pantalla')));
      }, error => this.delayedClose(this.i18n('No tens els permisos necessaris per visualitzar aquesta pantalla')));
    }, error => this.delayedClose(this.i18n('No tens els permisos necessaris per visualitzar aquesta pantalla')));

  }

  public decline() {
    this.thisDialogRef.close(false);
  }

  public accept() {
    this.thisDialogRef.close(true);
  }

  public dismiss() {
    this.thisDialogRef.close(false);
  }

  public addPermission() {
    const grups = this.getUnusedGroups(-1);
    const users = this.getUnusedUsers(-1);
    if (grups.length === 0 && users.length === 0) {
      this.toastr.warning(
        this.i18n('Ja has assignat permisos a tots els usuaris i grups!')
      );
    } else {
      this.permissionEditorDialogRef = this.dialog.open(
        PermissionEditorComponent,
        {
          closeOnNavigation: false,
          autoFocus: true,
          width: '40%',
          maxHeight: '80%',
          data: {
            permission: this.permission,
            groups: grups,
            users: users,
            item: null
          }
        }
      );
      this.permissionEditorDialogRef.afterClosed().subscribe(result => {
        this.savePermission(result);
      });
    }
  }

  public updatePermission(secure_id: string) {
    const item: PermissionItem = this.permissionItems.find(
      x => x.SECURE_ID === secure_id
    );
    const grups = this.getUnusedGroups(item.RoleCode);
    const users = this.getUnusedUsers(item.RoleCode);
    if (grups.length === 0 && users.length === 0) {
      this.toastr.warning(
        this.i18n('Ja has assignat permisos a tots els usuaris i grups!')
      );
    } else {
      this.permissionEditorDialogRef = this.dialog.open(
        PermissionEditorComponent,
        {
          closeOnNavigation: false,
          autoFocus: true,
          width: '40%',
          data: {
            permission: this.permission,
            groups: grups,
            users: users,
            item: item
          }
        }
      );
      this.permissionEditorDialogRef.afterClosed().subscribe(result => {
        this.savePermission(result);
      });
    }
  }

  public deletePermission(secure_id: string) {
    const item: PermissionItem = this.permissionItems.find(
      x => x.SECURE_ID === secure_id
    );
    this.confirmationDialogService
      .confirm(
        this.i18n('Confirma'),
        this.i18n('Segur que vols esborrar aquest permís?')
      )
      .then(confirmed => {
        console.log('User confirmed:', confirmed);
        if (confirmed) {
          this.permissionService.deletePermission(item).subscribe(result => {
            this.toastr.info(this.i18n('Permís esborrat correctament'));
            this.refreshPermissions();
          });
        }
      });
  }

  private getUnusedGroups(current_group: number): SEG_GRUPOS[] {
    const grups: SEG_GRUPOS[] = [];
    if (this.groups !== undefined) {
      this.groups.forEach(grup => {
        const item = this.permissionItems.find(
          perm => perm.RoleCode === grup.SOM_ID
        );
        if (item === undefined || grup.SOM_ID === current_group) {
          grups.push(grup);
        }
      });
    }
    return grups;
  }

  private getUnusedUsers(current_user: number): Usuari[] {
    const users: Usuari[] = [];
    if (this.users !== undefined) {
      this.users.forEach(user => {
        const item = this.permissionItems.find(
          perm => perm.RoleCode === user.UserID
        );
        if (item === undefined || user.UserID === current_user) {
          users.push(user);
        }
      });
    }
    return users;
  }

  private savePermission(item: PermissionItem) {
    if (item !== null && item !== undefined) {
      this.permissionService.setPermission(item).subscribe(result => {
        this.toastr.info(this.i18n('Permís guardat correctament'));
        this.refreshPermissions();
      });
    }
  }

  delayedClose(message: string) {
    return new Promise(resolve => {
      setTimeout(() => {
        this.toastr.error(message);
        this.dismiss();
      }, 200);
    });

  }
  disabled() {
    return !this.authService.CheckUserRole(this.authService.Roles.Administrator) && this.authService.CheckUserProfile(this.authService.Profiles.Superuser);
  }

  openAuditoriaDialog() {
    this.dialog.open(
      AuditoriaComponent,
      {
        closeOnNavigation: false,
        autoFocus: true,
        width: '80%',
        data: this.permission,
      }
    );
  }
}
