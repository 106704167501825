<div class="row">
  <mat-tab-group class="col-xl-12">
    <mat-tab i18n-label label="Notificar" >     
      <app-notificar></app-notificar>
    </mat-tab>
    <mat-tab i18n-label label="Ejecutiva">    
      <app-executiva></app-executiva>
    </mat-tab>
    <mat-tab i18n-label label="Cierres">
      <app-tancaments></app-tancaments>
    </mat-tab>
    <mat-tab i18n-label label="Pendientes">
      <app-pendentsexpedients></app-pendentsexpedients>
    </mat-tab>
    <mat-tab label="Translate Test" i18n-label>
      <app-tancaments></app-tancaments>
    </mat-tab>
</mat-tab-group>
</div>