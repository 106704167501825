import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { HttpParams } from '@angular/common/http';
import { PuntosDgtFilter } from '../models/puntos-dt-filter';

const defaultSortParam = 'codi';
const defaultSortDirection = 'asc';

@Injectable()
export class EnvioPuntosDGTService {
  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {}

  getEnvioPuntos(filter): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlEnvioPuntosDGT'], filter);
    }
  }
  getDetalleEnvio(secureId: string): Observable<any>{
    

    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlEnvioDetallePuntosDGT'].replace('{secureId}',secureId), null);
    }
  }
  sendResponseFileAndParse(file: any):Observable<any>{
    
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSendResponseFileDGT'],file);
    }
  }

  reenviarPuntos(remesa: any): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlreEnvioPuntosDGT'], remesa);
    }
  }

}
