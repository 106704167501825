import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class ColorFilter extends FilterData {
  id: number;
  descripcio: string;
  codiPropi: string;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.id = null;
    this.descripcio = '';
    this.codiPropi = '';

    this.labels['id'] = this.i18n('Id');
    this.labels['descripcio'] = this.i18n('Descripció');
    this.labels['codiPropi'] = this.i18n('Codi propi');
  }
}
