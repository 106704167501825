<app-filter-header title="Filtrar matricules" i18n-title (apply)="this.apply()" (reset)="this.reset()" (dismiss)="this.dismiss()"></app-filter-header>
<div class="modal-body">

    <div class="row">
        <div class="col-md-6">

            <app-filter-text-edit id="zones-filter-idZona" label="Id Zona" i18n-label class="col-sm-4" [(value)]="filter.idZona"></app-filter-text-edit>
        </div>
        <div class="col-md-6">
            <app-filter-text-edit id="zones-filter-descripcio" autofocus class="col-sm-4" i18n-label label="Descripcion" [(value)]="filter.descripcio"></app-filter-text-edit>

        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <app-form-date-edit id="zones-filter-dataIni"class="col-sm-4" i18n-label label="Data Inici" [(value)]="filter.fechaInici" type="calendar"></app-form-date-edit>

        </div>
        <div class="col-md-6">
            <app-form-date-edit id="zones-filter-dataFi"class="col-sm-4" i18n-label label="Data Fi" [(value)]="filter.fechaFin" type="calendar"></app-form-date-edit>

        </div>
    </div>
</div>