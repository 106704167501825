import { Component, OnInit, EventEmitter, Output, ElementRef, ViewChild, Inject, OnDestroy } from '@angular/core';
import { DipositGruaFilter } from '../../Models/diposit-filter';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Dictionary } from 'path-shared/models/dictionary';
import { ComboBoxInputAutocompleteComponent } from 'path-shared/components/form/combo-box-input-autocomplete/combo-box-input-autocomplete.component';
import { GruaPendentsService } from '../../services/grua-pendents.service';
import { Observable, Subscription } from 'rxjs';
import { FormMultiSelect } from 'path-shared/components/form/form-multi-select/model/form-multi-select.model';
import { DipositGruaService } from '../../services/diposit-grua-service';

@Component({
  selector: 'app-diposit-filter',
  templateUrl: './diposit-filter.component.html',
  styleUrls: ['./diposit-filter.component.scss']
})
export class DipositFilterComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  filter: DipositGruaFilter;
  @Output() notify: EventEmitter<DipositGruaFilter> = new EventEmitter<DipositGruaFilter>();


  tecnicsDictionary: Dictionary[];
  valorAutoCompleteTecnic;
  tecnicsList = [];
  @ViewChild('comboTecnics') comboTecnics: ComboBoxInputAutocompleteComponent;

  tipusVehiclesDictionary: Dictionary[];
  valorAutoCompleteTipusVehicle;
  tipusVehiclesList = [];
  @ViewChild('comboTipusVehicles') comboTipusVehicles: ComboBoxInputAutocompleteComponent;

  diposits = [];

  @ViewChild('mainForm') private mainForm: ElementRef;
  constructor(public thisDialogRef: MatDialogRef<DipositGruaFilter>,
    private gruaService: GruaPendentsService,
    private dipositGruaService: DipositGruaService,
    @Inject(MAT_DIALOG_DATA) public data, ) {
      this.filter = data.filter;
      this.valorAutoCompleteTecnic = '';
      this.valorAutoCompleteTipusVehicle = '';
    }

  ngOnInit() {
    this.loadDiposits();
    this.loadTecnics();
    this.loadTipusVehicles();
    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.apply();
      }
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated();
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }

  loadTecnics() {
    this.tecnicsDictionary = new Array<Dictionary>();
    this.gruaService.getAllTecnicsCombo().subscribe( x => {
      this.tecnicsList = x;
      x.forEach(element => {
        this.tecnicsDictionary.push(new Dictionary(element.secureId.toString(), element.codi));
      });
      this.setDefalutValuesCombosTecnic();
    });
  }

  loadDiposits() {
    this.dipositGruaService.getDipositsCombo().subscribe(result => {
      const diposits: Array<any> = Object.assign([], result);
      this.diposits = [];
      diposits.forEach(diposit => {
        this.diposits.push(new FormMultiSelect(diposit.idDiposit, diposit.carrer));

      });
    });
  }

  tecnicSeleccionado(secureIdTecnic: string) {
    const tecnicSelected = this.tecnicsList.find(x => x.secureId === secureIdTecnic);
    this.filter.idTecnicFK = tecnicSelected.somId;
  }

  setDefalutValuesCombosTecnic() {
    // agent
    if (this.filter.idTecnicFK !== undefined && this.filter.idTecnicFK !== null && this.filter.idTecnicFK !== ''
     && this.tecnicsDictionary.length > 0 ) {
      this.comboTecnics.setForcedValueComb(this.filter.idTecnicFK);
    }
  }

  loadTipusVehicles() {
    this.tipusVehiclesDictionary = new Array<Dictionary>();
    this.gruaService.getAllTipusVehiclesCombo().subscribe( x => {
      this.tipusVehiclesList = x;
      x.forEach(element => {
        this.tipusVehiclesDictionary.push(new Dictionary(element.secureId.toString(), element.descripcio));
      });
      this.setDefalutValuesCombosTipusVehicle();
    });
  }

  tipusVehiclSeleccionado(secureIdTipusVehicle: string) {
    const tipusVehicleSelected = this.tipusVehiclesList.find(x => x.secureId === secureIdTipusVehicle);
    this.filter.idTipusVehicleFK = tipusVehicleSelected.codi;
  }

  setDefalutValuesCombosTipusVehicle() {
    if (this.filter.idTipusVehicleFK !== undefined && this.filter.idTipusVehicleFK !== null && this.filter.idTipusVehicleFK !== ''
     && this.tipusVehiclesDictionary.length > 0 ) {
      const tipusVehicleSelected = this.tipusVehiclesList.find(x => x.secureId === this.filter.idTipusVehicleFK);
      this.comboTipusVehicles.setForcedValueComb( tipusVehicleSelected.descripcio);
    }
  }

}
