import { MatriculaFilter } from '../models/matricula-filter';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationFilter } from 'path-shared/models/pagination.filter';
import { IFilterService } from 'path-shared/services/datasource/filter-service';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { MatriculaHistoricFilter } from '../models/matricula-historic-filter';
import { Reflection } from 'path-shared/services/reflection/reflection';

const defaultSortParam = 'tipus';
const defaultSortDirection = 'desc';

@Injectable()
export class MatriculaHistoricFilterService extends CustomFilterService {
  public createFilterParams(filter: MatriculaHistoricFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {
    return new HttpParams()
    .append('tipus', Reflection.hasValue(filter.tipus) ? filter.tipus : '')
    .append('observacions', filter.observacions ? filter.observacions : '')
    .append('model', filter.model ? filter.model : '')
    .append('matricula', filter.matricula ? filter.matricula : '')
    .append('marca', filter.marca ? filter.marca : '')
    .append('dataInici', Reflection.empty(filter.dataInici) ? null : filter.dataInici.toJSON())
    .append('dataFi', Reflection.empty(filter.dataFi) ? null : filter.dataFi.toJSON())
    .append('dataCreacio', Reflection.empty(filter.dataCreacio) ? null : filter.dataCreacio.toJSON())
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir)
    ;
  }

  public createLoadParams(content: string) {
    return new HttpParams()
    .append('content', content ? content : '')
    ;
  }


}
