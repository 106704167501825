<app-dialog-header label="Carrer" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Editar" i18n-text (click)="this.dialogData.isReadOnly = false" color="has-color-green" icon="fa-edit" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Carrers) || !this.dialogData.isReadOnly" *ngIf="this.dialogData.isReadOnly && this.editable"></app-tool-button>
  <app-tool-button text="Guardar" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Carrers) || this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Eliminar" i18n-text (click)="this.remove();" color="has-color-red" icon="fa-trash-alt" [disabled]="!this.auth.UserCanDelete(this.auth.Pages.Carrers) || this.dialogData.isReadOnly || !this.dialogData.editable"></app-tool-button>
  <app-tool-button [text]="this.closeButtonText" i18n-text (click)="this.cancel()" color="has-color-yellow" icon="fa-th-list"></app-tool-button>
</app-toolbar>
<app-dialog-body>
  <form #mainForm>

    <div class="row">
     <app-form-combo-edit class="col-md-3" i18n-label label="Sigles" [items]="this.sigles" [(value)]="this.carrer.sigles" valueField="id" textField="id" [isReadOnly]="this.dialogData.isReadOnly"></app-form-combo-edit>

      <app-form-text-edit id="carrer-editor-nom" class="col-md-8" i18n-label label="Nom Carrer" [(value)]="this.carrer.nomCarrer" masmenght="250" [isReadOnly]="this.dialogData.isReadOnly"required ></app-form-text-edit>
    </div>

    <div class="row">
      <app-form-text-edit id="aparells-filter-ref" class="col-md-3" i18n-label label="Referència Integració" [(value)]="this.carrer.referenciaIntegracio" masmenght="50" [isReadOnly]="this.dialogData.isReadOnly"></app-form-text-edit>

      <app-form-multi-select class="col-md-8" titulo="Zones" [data]="zones" [isReadOnly]="this.dialogData.isReadOnly" [(selectedValues)]="this.carrer.zones" i18n-titulo tituloMultiSelect="Selecciona una o més" i18n-tituloMultiSelect [maxItems]="3" required></app-form-multi-select>
    </div>

    <div class="row">
      <div class="col-md-6">
        <app-combo-box-input-autocomplete #combMunicipio class="center-horizontal" i18n-label label="Municipi" [options]="this.municipiosDictionary"
        (valorSeleccionado)="this.municipioSeleccionado($event)" [valorAutocomplete]="this.valorAutoCompleteMunicipio"  required="false" [disableComponent]="this.dialogData.isReadOnly">
        </app-combo-box-input-autocomplete>
      </div>  

      <!-- <app-form-text-edit id="aparells-filter-codi" class="col-md-3" i18n-label label="Codi" [(value)]="this.carrer.id" [disabled]="this.secure_id != null" [type]="'number'" required number></app-form-text-edit> -->
    </div>

  </form>
</app-dialog-body>

