<app-dialog-header  icon="fa-shield-alt" label="Autentificació de doble factor" i18n-label></app-dialog-header>

<!-- <app-toolbar>
    <app-tool-button text="Guardar" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save"></app-tool-button>
</app-toolbar> -->
<app-dialog-body>
  <form #mainForm>

    <div *ngIf="!this.usuari.twoFactorAuthentication" class="content" style="text-align: -webkit-center;">
      <span i18n>Desitjeu activar la autentificació de doble factor?</span>
      <div style="margin-top: 2vh;">
        <button class="btn btn-primary" (click)="EnableTwoFactorAuthentication()" i18n>Activar</button>
      </div>    </div>

    <div *ngIf="this.usuari.twoFactorAuthentication" class="content">
      <p i18n style="display: inline;">Heu activat la opció</p>&nbsp;<b i18n>Autentificació de doble factor!</b>
      <p i18n>Ara, quan entreu a l'aplicació se us demanarà una contrasenya d'un sol us (OTP)</p>
      <p i18n>Entreu el seguent codi: </p>
      <b class="card" style="text-align: -webkit-center;">{{this.secret}}</b>
      <p i18n>O escanejeu la següent imatge:</p> 
      <div id="qrcode" style="text-align: -webkit-center;">
        <qrcode *ngIf="this.qr !== null" [qrdata]="qr" [size]="100" [errorCorrectionLevel]="'M'"></qrcode>
      </div>
      <p class="content" i18n>A la vostra aplicació d'autentificació per a obtenir la contrasenya.</p>
      <hr>
      <p i18n>Podeu desactivar aquesta opció fent clic al següent botó:</p>
      <div style="text-align: -webkit-center;">
        <button class="btn btn-primary" (click)="DisableTwoFactorAuthentication()" i18n>Desactivar</button>
      </div>

    </div>
  </form>
  
</app-dialog-body>
