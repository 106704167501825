import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class DeteccioMatriculaFilter extends FilterData {
  matricula: string;
  any: number;
  mes: number;
  dia: number;
  dataInici: Date;
  dataFi: Date;
  idTipusMatricula: number;
  agent: string;
  PDA: string;
  aparell: string;
  idDenuncia: number;
  comprovacio: string;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.matricula = '';
    this.dataFi = null;
    this.dataInici = null;
    this.any = null;
    this.mes = null;
    this.dia = null;
    this.idTipusMatricula = null;
    this.agent = '';
    this.PDA = '';
    this.aparell = '';
    this.comprovacio = '';


    this.labels['dataFi'] = this.i18n('Data Fi');
    this.labels['dataInici'] = this.i18n('Data Inici');
    this.labels['matricula'] = this.i18n('Matrícula');
    this.labels['any'] = this.i18n('Any');
    this.labels['mes'] = this.i18n('Mes');
    this.labels['dia'] = this.i18n('Dia');
    this.labels['idTipusMatricula'] = this.i18n('Tipus Matrícula');
    this.labels['agent'] = this.i18n('Agent');
    this.labels['PDA'] = this.i18n('PDA');
    this.labels['aparell'] = this.i18n('Aparell');
    this.labels['idDenuncia'] = this.i18n('Id Denúncia');
    this.labels['comprovacio'] = this.i18n('Comprovació denúncies');


    this.formatOutput['dataInici'] = 'shortDate';
    this.formatOutput['dataFi'] = 'shortDate';

  }
}
