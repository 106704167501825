<app-filter-header title="Filtrar vehicles" i18n-title  (apply)="this.apply()" (reset)="this.reset()" (dismiss)="this.dismiss()"></app-filter-header>
<div class="modal-body">
  <div class="row">
     <app-filter-text-edit id="vehicles-filter-id"  autofocus class="col-sm-6" i18n-label label="Id" [(value)]="filter.id_vehicles"></app-filter-text-edit>
     <app-filter-text-edit id="vehicles-filter-matricula" class="col-sm-6" i18n-label label="Matricula" [(value)]="filter.matricula"></app-filter-text-edit>
  </div>
  <div class="row">
    <app-filter-text-edit id="vehicles-filter-marca" class="col-sm-6" i18n-label label="Marca" [(value)]="filter.marca"></app-filter-text-edit>
    <app-filter-text-edit id="vehicles-filter-model" class="col-sm-6" i18n-label label="Model" [(value)]="filter.model"></app-filter-text-edit>
  </div>
  <div class="row">
    <app-filter-text-edit id="vehicles-filter-color" class="col-sm-6" i18n-label label="Color" [(value)]="filter.color"></app-filter-text-edit>
  </div>
  
  <div class="row">
    <app-filter-text-edit id="vehicles-filter-asseguranca" class="col-sm-6" i18n-label label="Assegurança" [(value)]="filter.asseguranca"></app-filter-text-edit>
    <app-filter-text-edit id="vehicles-filter-polissa" class="col-sm-6" i18n-label label="Polissa" [(value)]="filter.poliza"></app-filter-text-edit>
  </div>

  <div class="row">
    <app-filter-text-edit id="vehicles-filter-origen" class="col-sm-6" i18n-label label="Origen" [(value)]="filter.origen"></app-filter-text-edit>
    <app-filter-text-edit id="vehicles-filter-tipus" class="col-sm-6" i18n-label label="Tipus vehicle" [(value)]="filter.tipus_vehicle"></app-filter-text-edit>
  </div>


  <div class="row">
    <div class="col-6">
      <app-form-tri-state label="Requisitoria" i18n-label i18n-labelSi labelSi="Si" i18n-labelNo labelNo = "No" i18n-labelTodos labelTodos="Tots" [(value)]="filter.requisitoria"></app-form-tri-state>
    </div>
  </div>
</div>
