import { CFG_PAISES } from './../../models/CFG_PAISES';
import { StartupService } from './../services/startup/startup.service';
import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'nomPais'
})
export class NomPaisPipe implements PipeTransform {
  private paisos: CFG_PAISES[];
  constructor(private startupService: StartupService) {
    this.paisos = this.startupService.GetPaisos();
  }

  transform(value: any, args?: any): any {
    if (value !== null && value !== undefined && value !== '') {
      const val: CFG_PAISES = this.paisos.find(x => x.SIGLA === value);
      if (val !== null && val !== undefined) {
        return val.PAINOMPAI;
      }
      return '';
    }
    return '';
  }
}
