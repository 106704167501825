<app-toolbar>
  <app-tool-button *ngIf="action !== 'Delete'" text="Guardar" i18n-text (click)="doAction()" color="has-color-green" icon="fa-save" ></app-tool-button>
  <app-tool-button *ngIf="action === 'Delete'" text="Eliminar" i18n-text (click)="doAction();" color="has-color-red" icon="fa-trash-alt" ></app-tool-button>
  <app-tool-button  text="Cancelar" i18n-text (click)="closeDialog()" color="has-color-red" icon="fa fa-times "></app-tool-button>
</app-toolbar>
<div class="row">
  <app-form-date-edit id="desperfectos-editor-data" i18n-label label="Data" [(value)]="this.local_data.FechaHora"></app-form-date-edit>  
  <app-form-text-edit id="desperfectos-editor-hora" class="col-sm-1" type="time" i18n-label label="Hora " [(value)]="this.horaEntrada" [readonly]="false" ></app-form-text-edit>
  <app-form-text-edit id="desperfectos-editor-descripcio" class="col-xl-3" i18n-label label="Descripció" [(value)]="this.local_data.Descripcio"  [isReadOnly]="false" ></app-form-text-edit>
</div>
<div class="row" *ngIf="this.action !== 'Add'">
  <div class="col-xl-12">
    <app-photo-box-grua #pbg  [numDenuncia]="this.local_data.iddenuncia"  [imagesInput]="this.local_data?.IMATGES" [contenedor]="this.local_data.SomId" [isDialog]="false" height="auto" [botoMatricula]="false" [allowSelect]="false"></app-photo-box-grua>
    <!-- [numDenuncia]="this.local_data.iddenuncia"  [imagesInput]="this.local_data?.IMATGES" [contenedor]="this.local_data.SomId" [isDialog]="false" height="auto" [botoMatricula]="false" [allowSelect]="false"-->
  </div>
</div>
