import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ciutat-page',
  templateUrl: './ciutat-page.component.html'
})
export class CiutatPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
