import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { Injectable } from '@angular/core';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { TrackingDetail } from '../models/trackingDetail.model';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { ParameterDto } from 'path-shared/services/parameters/model/ParametroDto';
import { AvisosFilter } from '../models/avisos-filter';
import { AvisosFilterService } from '../models/avisos-filter-service';
import { ExcelGenerator } from 'path-shared/interfaces/excel-generator';
import { HttpResponse } from '@angular/common/http';


const defaultSortParam = 'data';
const defaultSortDirection = 'desc';


@Injectable()
export class AvisosService implements ExcelGenerator {
  displayedItems = [];


  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {
    this.displayedItems['checkedValues'] = 0;
    this.displayedItems['checkedItems'] = Array();
  }

  get(filter: AvisosFilter): Observable<any> {
    if (this.authGuard.canActivate()) {
      const params = new AvisosFilterService().createFilterParams(filter, filter.paginator.pageNumber, filter.paginator.pageSize, 'id', 'asc');
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlAvisos'], params);
    }
  }

  getFitxers(idAvis: number): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlNomFitxersAvis'].replace('{idAvis}', idAvis.toString()), null);
    }
  }

  getFitxer(idFitxer: number): Observable<HttpResponse<Blob>> {
    if (this.authGuard.canActivate()) {
      return this.http.getFileResponse(config.urls['apiUrl'] + api.endpoints['urlGetFitxerAvisos'].replace('{idFitxer}', idFitxer.toString()), null);
    }
  }

  getCarpetes(): Observable<any> {
    if (this.authGuard.canActivate()) {

      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlCarpetesAvisos'], null);
    }
}
  generateExcel(filter: AvisosFilter, sortDir: string, sortField: string): Observable<any> {
    const start = 0;
    const pageSize = 1000;

    sortField = sortField === undefined ? defaultSortParam : sortField;
    sortDir = sortDir === '' ? defaultSortDirection : sortDir;
    const params = new AvisosFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

    if (this.authGuard.canActivate()) {
      return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlAvisosExcel'],  params );
    }
  }

}
