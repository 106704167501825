import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { Builder } from 'path-shared/models/builder';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { InfoTancaments } from '../../models/info-tancaments.model';
import { TramitarService } from '../../services/tramitar.service';
import { TablependentsComponent } from '../pendents/tablependents/tablependents.component';
import { TabletancamentsComponent } from './tabletancaments/tabletancaments/tabletancaments.component';

@Component({
  selector: 'app-tancaments',
  templateUrl: './tancaments.component.html',
  styleUrls: ['./tancaments.component.scss']
})
export class TancamentsComponent implements OnInit {

  infoTancaments: InfoTancaments = new InfoTancaments();  
  constructor(
    private dialog: MatDialog,
    private tramitarService: TramitarService,
    private i18n: I18n,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.loadInici();
  }

  loadInici() {
    this.tramitarService.getInfoTancament().subscribe(x => {
      if (x !== undefined && x !== null) {
        this.infoTancaments = x;
      }
    });
  }

  OpenPopUp() {
    if (this.infoTancaments.Tancaments > 0) {
    const data: ComponentDialog = Builder.newComponentDialog(true, true);
      const ref = this.dialog.open(TabletancamentsComponent, {
        width: '70%',
        height: '50%',
        maxHeight: '50vh',
        closeOnNavigation: false,
        autoFocus: true,
        data: data
      });

      ref.componentInstance.notify.subscribe(() => {        
        this.loadInici();
      });
    } else {
      this.toastr.info(this.i18n('No se han encontrado expedientes para cerrar de oficio'));
    }
  }

}
